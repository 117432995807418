/**
 * Dashboard Reducer
 *Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerNew';

const BUY_STOPLIMIT_ORDER_FORM = 'BUY_STOPLIMIT_ORDER_FORM';
const BUY_STOPLIMIT_ORDER_RESPONSE = 'BUY_STOPLIMIT_ORDER_RESPONSE';
const HIDE_BUY_STOPLIMIT_MODAL = 'HIDE_BUY_STOPLIMIT_MODAL';

const SELL_STOPLIMIT_ORDER_FORM = 'SELL_STOPLIMIT_ORDER_FORM';
const SELL_STOPLIMIT_ORDER_RESPONSE = 'SELL_STOPLIMIT_ORDER_RESPONSE';
const HIDE_SELL_STOPLIMIT_MODAL = 'HIDE_SELL_STOPLIMIT_MODAL';

const STOPLIMIT_ORDER_FORM = 'STOPLIMIT_ORDER_FORM';

const LIMIT_FORM_HEIGHT = 'LIMIT_FORM_HEIGHT';

const initialState = {
  currencyBuy: 1,
  volumeStopBuy: '',
  totalStopBuy: '',
  stopBuy: '',
  limitBuy: '',
  operationBuy: 1,
  marketBuy: 0,

  currencySell: 1,
  limitSell: '',
  stopSell: '',
  operationSell: 0,
  marketSell: 0,
  volumeStopSell: '',
  totalStopSell: '',

  buyFormModal: false,
  response: {},

  limitFormHeight: 0
};

/**
 * Reducer
 * @event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case BUY_STOPLIMIT_ORDER_FORM:
      return {
        ...state,
        currencyBuy: action.currencyBuy,
        operationBuy: action.operationBuy,
        marketBuy: action.marketBuy,
        volumeStopBuy: action.volumeStopBuy,
        totalStopBuy: action.totalStopBuy,
        stopBuy: action.stopBuy,
        limitBuy: action.limitBuy
      };
    case BUY_STOPLIMIT_ORDER_RESPONSE:
      return {
        ...state,
        buyResponse: action.response,
        buyFormModal: action.buyFormModal
      };
    case HIDE_BUY_STOPLIMIT_MODAL:
      return {
        ...state,
        buyFormModal: action.buyFormModal
      };

    case SELL_STOPLIMIT_ORDER_FORM:
      return {
        ...state,
        currencySell: action.currencySell,
        operationSell: action.operationSell,
        marketSell: action.marketSell,
        limitSell: action.limitSell,
        stopSell: action.stopSell,
        volumeStopSell: action.volumeStopSell,
        totalStopSell: action.totalStopSell
      };
    case SELL_STOPLIMIT_ORDER_RESPONSE:
      return {
        ...state,
        sellResponse: action.response,
        sellFormModal: action.sellFormModal
      };
    case HIDE_SELL_STOPLIMIT_MODAL:
      return {
        ...state,
        sellFormModal: action.sellFormModal
      };

    case STOPLIMIT_ORDER_FORM:
      return {
        ...state,
        graphData: action.response
      };

    case LIMIT_FORM_HEIGHT:
      return {
        ...state,
        limitFormHeight: action.limitFormHeight
      };

    default:
      return state;
  }
};

/**
 * dashboard  Actions
 *@Actions
 */

/**
 * Calls the Api for buying Falcon currency
 * @event
 * @param {Object} formData - formData --> Email and Amount
 */

export const buyStopLimitInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: BUY_STOPLIMIT_ORDER_FORM,
    volumeStopBuy: formdata.volumeStopBuy,
    totalStopBuy: formdata.totalStopBuy,
    stopBuy: formdata.stopBuy,
    limitBuy: formdata.limitBuy,
    currencyBuy: formdata.currencyBuy,
    operationBuy: formdata.operationBuy,
    marketBuy: formdata.marketBuy
  });
};

export const addBuyOrder = formdata => dispatch => {
  apiCaller(
    'order/stoplimit/submit',
    'post',
    formdata,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: BUY_STOPLIMIT_ORDER_RESPONSE,
      response,
      buyFormModal: true
    });
  });
};

export const hideBuyModal = () => dispatch => {
  dispatch({
    type: HIDE_BUY_STOPLIMIT_MODAL,
    buyFormModal: false
  });
};

export const sellStopLimitInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: SELL_STOPLIMIT_ORDER_FORM,
    limitSell: formdata.limitSell,
    stopSell: formdata.stopSell,
    currencySell: formdata.currencySell,
    operationSell: formdata.operationSell,
    marketSell: formdata.marketSell,
    volumeStopSell: formdata.volumeStopSell,
    totalStopSell: formdata.totalStopSell
  });
};

export const addSellOrder = formdata => dispatch => {
  apiCaller(
    'order/stoplimit/submit',
    'post',
    formdata,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: SELL_STOPLIMIT_ORDER_RESPONSE,
      response,
      sellFormModal: true
    });
  });
};

export const hideSellModal = () => dispatch => {
  dispatch({
    type: HIDE_SELL_STOPLIMIT_MODAL,
    sellFormModal: false
  });
};

export const limitFormHeightFunc = formData => dispatch => {
  dispatch({
    type: LIMIT_FORM_HEIGHT,
    limitFormHeight: formData.limitFormHeight
  });
};
