import apiCallerSignin from 'Utils/apiCallerSignin';
import apiCaller from 'Utils/apiCallerNew';

const KYC_REQUESTED = 'KYC_REQUESTED';
const ADD_KYC_RESPONSE = 'ADD_KYC_RESPONSE';
const GENERATE_OTP = 'GENERATE_OTP';
const VERIFY_OTP = 'VERIFY_OTP';
const ADD_BASIC_INFO = 'ADD_BASIC_INFO';
const ADD_COPIES = 'ADD_COPIES';
const GET_KYC_DETAILS = 'GET_KYC_DETAILS';

const initialState = {
  isFormProcessing: false,
  kyc1Response: {},
  OTPSendResponse: {},
  OTPVerifyResponse: {},
  basicInfoResponse: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KYC_REQUESTED:
      return {
        ...state,
        isFormProcessing: true
      };

    case ADD_KYC_RESPONSE:
      return {
        ...state,
        isFormProcessing: false,
        kyc1Response: action.response
      };

    case ADD_BASIC_INFO:
      return {
        ...state,
        isFormProcessing: false,
        basicInfoResponse: action.response
      };

    case GENERATE_OTP:
      return {
        ...state,
        OTPSendResponse: action.response
      };

    case VERIFY_OTP:
      return {
        ...state,
        OTPVerifyResponse: action.response
      };

    case ADD_COPIES:
      return {
        ...state,
        copiesResponse: action.response
      };

    case GET_KYC_DETAILS:
      return {
        ...state,
        kycDetailsResponse: action.response
      };

    default:
      return state;
  }
};

export const kyc1 = formData => dispatch => {
  dispatch({
    type: KYC_REQUESTED
  });

  apiCallerSignin('kycform/up_details', 'post', formData).then(response =>
    dispatch({
      type: ADD_KYC_RESPONSE,
      response
    })
  );
};

/**
 *genrate OTP
 *@event
 */
export const generateOTPNumber = formData => dispatch => {
  apiCaller('kyc/otp/send', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: GENERATE_OTP,
        response
      })
  );
};

/**
 *verify OTP
 *@event
 */
export const verifyOTPNumber = formData => dispatch => {
  apiCaller('kyc/otp/verify', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: VERIFY_OTP,
        response
      })
  );
};

/**
 *Save Basic Information
 *@event
 */
export const saveBasicInformation = formData => dispatch => {
  apiCaller('kyc/savedetails', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: ADD_BASIC_INFO,
        response
      })
  );
};

/**
 *Save Copies
 *@event
 */
export const saveCopies = formData => dispatch => {
  apiCaller('kyc/savecopies', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: ADD_COPIES,
        response
      })
  );
};

export const getKycDetails = formData => dispatch => {
  apiCaller('kyc/details', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: GET_KYC_DETAILS,
        response
      })
  );
};
