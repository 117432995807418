import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const ORDER_BUY_SUCCESS = 'ORDER_BUY_SUCCESS';
const ORDER_BUY_REQUESTED = 'ORDER_BUY_REQUESTED';
const ORDER_SELL_SUCCESS = 'ORDER_SELL_SUCCESS';
const ORDER_SELL_REQUESTED = 'ORDER_SELL_REQUESTED';
const UPDATE_SELL_ORDERS = 'UPDATE_SELL_ORDERS';
const UPDATE_BUY_ORDERS = 'UPDATE_BUY_ORDERS';
const ALL_MARKET_COIN_24_HR_PRICE_SUCCESS =
  'ALL_MARKET_COIN_24_HR_PRICE_SUCCESS';
const ALL_MARKET_COIN_24_HR_PRICE_REQUESTED =
  'ALL_MARKET_COIN_24_HR_PRICE_REQUESTED';
const OPEN_ORDER_ACTIVE_TAB_UPDATE = 'OPEN_ORDER_ACTIVE_TAB_UPDATE';
const initialState = {
  getBuyOrdersList: { data: [] },
  getSellOrdersList: { data: [] },
  recentTradeUpdateObject: {},
  activeTab: '1'
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_MARKET_COIN_24_HR_PRICE_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case ALL_MARKET_COIN_24_HR_PRICE_SUCCESS:
      return {
        ...state,
        marketCoin24HrPriceList: action.marketCoinPrice,
        marketViewLoader: false
      };
    case ORDER_BUY_REQUESTED:
      return {
        ...state,
        buyOrderLoader: true
      };
    case ORDER_BUY_SUCCESS:
      return {
        ...state,
        buyOrderLoader: false,
        getBuyOrdersList: action.response
      };
    case ORDER_SELL_REQUESTED:
      return {
        ...state,
        sellOrderLoader: true
      };
    case ORDER_SELL_SUCCESS:
      return {
        ...state,
        sellOrderLoader: false,
        getSellOrdersList: action.response
      };
    case UPDATE_SELL_ORDERS:
      const sellOrdersList = state.getSellOrdersList.data;

      var temp = 0;
      var newSellOrderList = _.map(sellOrdersList, obj => {
        if (obj.price === action.jsonObj.sellOrderUpdateObject.price) {
          if (action.jsonObj.action === 'sum') {
            temp = 1;
            const volume = Math.abs(
              Number(
                (
                  obj.volume + action.jsonObj.sellOrderUpdateObject.volume
                ).toFixed(8)
              )
            );
            var className = 'green1';
            if (obj.className === 'green1') {
              className = 'green2';
            } else if (obj.className === 'green2') {
              className = 'green1';
            }
            return {
              price: action.jsonObj.sellOrderUpdateObject.price,
              volume: volume,
              classSelectedTime: new Date().getTime(),
              // className: 'priceChangeUp'
              className: className
            };
          } else if (action.jsonObj.action === 'substract') {
            temp = 1;
            const volume = Math.abs(
              Number(
                (
                  obj.volume - action.jsonObj.sellOrderUpdateObject.volume
                ).toFixed(8)
              )
            );
            className = 'red1';
            if (obj.className === 'red1') {
              className = 'red2';
            } else if (obj.className === 'red2') {
              className = 'red1';
            }
            return {
              price: action.jsonObj.sellOrderUpdateObject.price,
              volume: volume,
              classSelectedTime: new Date().getTime(),
              // className: 'priceChangeDown'
              className: className
            };
          }
        } else {
          if (
            obj.classSelectedTime &&
            obj.classSelectedTime + 2000 < new Date().getTime()
          ) {
            return { ...obj, className: '', classSelectedTime: null };
          } else {
            return obj;
          }
        }
      });
      if (temp === 0) {
        sellOrdersList.push({
          price: action.jsonObj.sellOrderUpdateObject.price,
          volume: action.jsonObj.sellOrderUpdateObject.volume,
          classSelectedTime: new Date().getTime(),
          // className: 'priceChangeUp'
          className: 'green3'
        });
        newSellOrderList = _.sortBy(sellOrdersList, obj => obj.price);
      }

      newSellOrderList = _.reject(newSellOrderList, obj => {
        return obj.volume === 0;
      });

      return {
        ...state,
        getSellOrdersList: {
          ...state.getSellOrdersList,
          data: newSellOrderList
        }
      };

    case UPDATE_BUY_ORDERS:
      const buyOrdersList = state.getBuyOrdersList.data;

      var temp = 0;
      var newBuyOrdersList = _.map(buyOrdersList, obj => {
        if (obj.price === action.jsonObj.buyOrderUpdateObject.price) {
          if (action.jsonObj.action === 'sum') {
            temp = 1;
            const volume = Math.abs(
              Number(
                (
                  obj.volume + action.jsonObj.buyOrderUpdateObject.volume
                ).toFixed(8)
              )
            );
            var className = 'green1';
            if (obj.className === 'green1') {
              className = 'green2';
            } else if (obj.className === 'green2') {
              className = 'green1';
            }
            return {
              price: action.jsonObj.buyOrderUpdateObject.price,
              volume: volume,
              classSelectedTime: new Date().getTime(),
              // className: 'priceChangeUp'
              className: className
            };
          } else if (action.jsonObj.action === 'substract') {
            temp = 1;
            const volume = Math.abs(
              Number(
                (
                  obj.volume - action.jsonObj.buyOrderUpdateObject.volume
                ).toFixed(8)
              )
            );
            className = 'red1';
            if (obj.className === 'red1') {
              className = 'red2';
            } else if (obj.className === 'red2') {
              className = 'red1';
            }
            return {
              price: action.jsonObj.buyOrderUpdateObject.price,
              volume: volume,
              classSelectedTime: new Date().getTime(),
              // className: 'priceChangeDown'
              className: className
            };
          }
        } else {
          if (
            obj.classSelectedTime &&
            obj.classSelectedTime + 2000 < new Date().getTime()
          ) {
            return { ...obj, className: '', classSelectedTime: null };
          } else {
            return obj;
          }
        }
      });
      newBuyOrdersList = _.sortBy(newBuyOrdersList, obj => obj.price * 1);
      if (temp === 0) {
        buyOrdersList.push({
          price: action.jsonObj.buyOrderUpdateObject.price,
          volume: action.jsonObj.buyOrderUpdateObject.volume,
          classSelectedTime: new Date().getTime(),
          // className: 'priceChangeUp'
          className: 'green3'
        });
        newBuyOrdersList = _.sortBy(buyOrdersList, obj => obj.price * 1);
      }

      const newBuyOrdersList1 = [];
      newBuyOrdersList.map(obj => {
        if (obj.volume !== 0) {
          newBuyOrdersList1.push(obj);
        }
      });

      // const newBuyOrdersList1 = _.reject(newBuyOrdersList, obj => {
      //   return obj.volume == 0;
      // });

      return {
        ...state,
        getBuyOrdersList: { ...state.getBuyOrdersList, data: newBuyOrdersList1 }
        // getBuyOrdersList: newBuyOrdersList
      };

    case OPEN_ORDER_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    default:
      return state;
  }
};

export const getBuyOrders = formData => dispatch => {
  dispatch({
    type: ORDER_BUY_REQUESTED
  });
  // apiCaller(`liveorders/1/1`, 'get', '', false, true, false).then(
  apiCaller(
    `order/live/${formData.market}/${formData.coin}/${formData.operation}/${formData.skip}/${formData.limit}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response => {
    dispatch({
      type: ORDER_BUY_SUCCESS,
      response
    });
  });
};

export const getSellOrders = formData => dispatch => {
  dispatch({
    type: ORDER_SELL_REQUESTED
  });
  // apiCaller(`liveorders/1/1`, 'get', '', false, true, false).then(
  apiCaller(
    `order/live/${formData.market}/${formData.coin}/${formData.operation}/${formData.skip}/${formData.limit}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response =>
    dispatch({
      type: ORDER_SELL_SUCCESS,
      response
    })
  );
};

export const updateSellOrders = jsonObj => dispatch => {
  dispatch({
    type: UPDATE_SELL_ORDERS,
    jsonObj
  });
};

export const updateBuyOrders = jsonObj => dispatch => {
  dispatch({
    type: UPDATE_BUY_ORDERS,
    jsonObj
  });
};

export const allMarketCoin24HrPriceList = formData => dispatch => {
  dispatch({
    type: ALL_MARKET_COIN_24_HR_PRICE_REQUESTED
  });
  apiCaller('list/price/', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        // var marketCoinPrice = {};
        // _.map(response, obj => {
        //   if (!marketCoinPrice[obj.marketId]) {
        //     marketCoinPrice[obj.marketId] = {};
        //   }
        // 	marketCoinPrice[obj.marketId][obj.coinId] = obj;
        // });

        dispatch({
          type: ALL_MARKET_COIN_24_HR_PRICE_SUCCESS,
          marketCoinPrice: response
        });
      }
    }
  );
};
export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};
