import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';
import App from 'App/app';

// import registerServiceWorker from './registerServiceWorker';
import 'font-awesome/css/font-awesome.min.css';

// import { unregister } from './registerServiceWorker';
// unregister();

/**
 * Main Root File
 */
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
);

// registerServiceWorker();
