import React from 'react';

const buySellStatusCheck = num => {
  if (num === false || num === 0) return 'Sell';
  else if (num === true || num === 1) return 'Buy';
  else return num;
};

const marketViewNameCheck = str => {
  if (str === 'eth') return 'Ether';
  else if (str === 'lsk') return 'Lisk';
  else return str;
};

export default { buySellStatusCheck, marketViewNameCheck };
