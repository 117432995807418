/**
 * Main DepositWithdraw Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
// import statusCheck from 'Utils/statusCheck';
import { getReferralHistory, selectSideBarToShow } from './referralReducer';
// import ReferralSideBar from './referalSideBar';
// import ErrorBoundary from 'Modules/ErrorBoundary';
import _ from 'underscore';
import helpers from 'Utils/sortingHelper';
import SVG from 'react-inlinesvg';

class ReferralBonus extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    // this.checkConditions = this.checkConditions.bind(this);
    this.renderSideBar = this.renderSideBar.bind(this);
    this.state = {
      referralHistoryLoader: true,
      getReferralHistoryList: [
        /*  {
          coin: 'btc',
          balance: '63.0000',
          onOrder: '63.0000',
          value: '63.0000'
        } */
      ],
      // getReferralHistoryList: [],
      searchData: [],
      coinSymbolsObj: {},
      marketCrptoArray: [],
      activeTab: 0,
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      referralError: null,
      message: null,
      txnid: '',
      code: '',
      currency: 'BTC',
      sortingCol: ''
    };
  }

  /*  checkConditions() {
    const { getReferralHistoryList } = this.state;
    if (getReferralHistoryList.code === -1) {
      this.setState({
        showMessage: true,
        message: getReferralHistoryList.message
      });
    } else if (getReferralHistoryList.code === 1) {
      this.setState({
        getReferralHistoryList: getReferralHistoryList.Data.balances,
        searchData: getReferralHistoryList.Data.balances
      });
    } else {
      this.setState({
        showMessage: true,
        message: getReferralHistoryList.message
      });
    }
  } */

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      // this.props.activeTabUpdate(tab);
    }
  }

  componentDidMount() {
    this.props.getReferralHistory();
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (this.props.referralHistoryLoader !== nextProps.referralHistoryLoader) {
      this.setState({ referralHistoryLoader: nextProps.referralHistoryLoader });
    }

    if (
      nextProps.getReferralHistoryList &&
      nextProps.getReferralHistoryList !== undefined &&
      this.props.getReferralHistoryList !== nextProps.getReferralHistoryList
    ) {
      if (nextProps.getReferralHistoryList.error == 0) {
        if (nextProps.getReferralHistoryList.data.balances.length != 0) {
          this.setState({
            refCode: nextProps.getReferralHistoryList.data.referalId,
            getReferralHistoryList:
              nextProps.getReferralHistoryList.data.balances,
            searchData: nextProps.getReferralHistoryList.data.balances,
            refEarnings: nextProps.getReferralHistoryList.referals,
            dataObject: nextProps.getReferralHistoryList.data
          });
        } else {
          this.setState({
            showMessage: true,
            message: nextProps.getReferralHistoryList.message,
            searchData: [],
            refCode: nextProps.getReferralHistoryList.data.referalId,
            dataObject: nextProps.getReferralHistoryList.data
          });
        }
      } else if (nextProps.getReferralHistoryList.error == 1) {
        this.setState({
          showMessage: true,
          message: nextProps.getReferralHistoryList.message,
          searchData: [],
          refCode: ''
        });
      } else {
        this.props.changePage('/login');
      }
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
  }

  componentWillUnmount() {
    this.props.selectSideBarToShow({
      loadInitial: true,
      currencyId: '',
      balance: ''
    });
    // if (this.props.isLoggedIn !== true) {
    //   this.props.changePage('/login');
    // }
  }

  handleChange(event) {
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }
  getBalance(currencyId) {
    var balance;
    this.state.searchData.map(o => {
      if (o.coinId == currencyId) {
        balance = o.balance;
      }
    });
    return parseFloat(balance).toFixed(8);
  }

  renderSideBar(currencyId, i) {
    var bal = this.getBalance(currencyId);
    this.setState({ selectedIndex: i });
    this.props.selectSideBarToShow({
      loadInitial: false,
      currencyId: currencyId,
      balance: bal
    });
  }
  sortColumn(type, columnName) {
    var refBonusSort = [];
    var { sortingCol, searchData, sortType } = this.state;
    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    if (sortingCol == 'total') {
      refBonusSort = _.sortBy(searchData, o =>
        parseFloat(o.amountByReferal + o.amountRandom)
      );
      if (sortType === 'desc') {
        refBonusSort = refBonusSort.reverse();
      }
    } else {
      refBonusSort = helpers.sortColumn(searchData, type, sortingCol, sortType);
    }
    this.setState({
      sortingCol,
      sortType,
      searchData: refBonusSort
    });
  }
  render() {
    const {
      // error,
      referralHistoryLoader,
      searchData,
      // referralError,
      // getReferralHistoryList,
      // coinSymbolsObj,
      message,
      sortingCol,
      sortType,
      selectedIndex
    } = this.state;
    // if (error) {
    //   return <div>{error.toString()}</div>;
    // }
    if (referralHistoryLoader) {
      return <div className="loading-o" />;
    }
    if (this.state.showMessage) {
      return (
        <div
          className="container-fluid card-box mt-1 referralBonus"
          style={{ height: window.innerHeight - 140 }}
        >
          No Referrals{' '}
        </div>
      );
      // return <div> {this.state.message}</div>;
    }
    if (!referralHistoryLoader && message !== null) {
      return <div> {this.state.message}</div>;
    }
    if (!referralHistoryLoader && message === null) {
      return (
        <div
          className="container-fluid card-box mt-1 referralBonus"
          style={{ height: window.innerHeight - 140 }}
        >
          {searchData && searchData.length !== 0 ? (
            <table className="table data-table table-fixed mb-0">
              <thead>
                <tr>
                  <th scope="col" className="faded">
                    Name
                  </th>
                  <th scope="col" className="faded">
                    Symbol
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'amountByReferal')}
                  >
                    Earnings by direct referral{' '}
                    {sortingCol === 'amountByReferal' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}{' '}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'amountRandom')}
                  >
                    {' '}
                    Earnings by random referral{' '}
                    {sortingCol === 'amountRandom' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}{' '}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'balance')}
                  >
                    {' '}
                    Total earnings{' '}
                    {sortingCol === 'balance' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}{' '}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'total')}
                  >
                    Available balance{' '}
                    {sortingCol === 'total' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}{' '}
                  </th>
                  {/* <th scope="col" className="faded" /> */}
                </tr>
              </thead>
              <tbody style={{ height: window.innerHeight - 180 }}>
                {_.keys(this.state.coinSymbolsObj).length > 0 &&
                  searchData.map((o, i) => (
                    <tr
                      key={i}
                      className={`crsrPntr ${
                        selectedIndex === i ? 'selected-table-row' : ''
                      }`}
                      onClick={() => this.renderSideBar(`${o.coinId}`, i)}
                    >
                      <td
                        className="coin-name coin-img-small"
                        style={{ textAlign: 'left' }}
                      >
                        <SVG
                          src={this.state.coinSymbolsObj[o.coinId].coinimage}
                          className="svg-15"
                        />
                        {`${this.state.coinSymbolsObj[o.coinId].coinname}`}
                      </td>
                      <td>
                        {`${this.state.coinSymbolsObj[
                          o.coinId
                        ].coinsymbol.toUpperCase()}`}
                      </td>
                      <td>
                        {parseFloat(o.amountByReferal).toFixed(8)}{' '}
                        {/* <span className="currency">
                          {` ${this.state.coinSymbolsObj[
                            o.coinId
                          ].coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {parseFloat(o.amountRandom).toFixed(8)}
                        {/* <span className="currency">
                          {` ${this.state.coinSymbolsObj[
                            o.coinId
                          ].coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {(
                          parseFloat(o.amountByReferal) +
                          parseFloat(o.amountRandom)
                        ).toFixed(8)}
                        {/* <span className="currency">
                          {` ${this.state.coinSymbolsObj[
                            o.coinId
                          ].coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {parseFloat(o.balance).toFixed(8)}
                        {/* <span className="currency">
                          {` ${this.state.coinSymbolsObj[
                            o.coinId
                          ].coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      {/* <td>
                        <div
                          className="button-cancel"
                          onClick={() => this.renderSideBar(`${o.coinId}`, i)}
                        >
                          Transfer
                        </div>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div className="noBal">No Referrals </div>
          )}
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getReferralHistory: formData => getReferralHistory(formData),
      selectSideBarToShow: formData => selectSideBarToShow(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    referralHistoryLoader: state.referral.referralHistoryLoader,
    getReferralHistoryList: state.referral.getReferralHistoryList,
    dummy: state.referral.dummy,
    coinSymbolsObj: state.app.coinSymbolsObj
  }),
  mapDispatchToProps
)(ReferralBonus);
