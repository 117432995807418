import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(changePageLocation) {
    this.props.changePage(changePageLocation);
  }
  render() {
    return (
      <nav id="navigation-main" className="navbar navbar-expand-lg">
        <a className="navbar-brand">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
            alt="Logo"
            style={{ height: '20px', cursor: 'pointer' }}
            // onClick={() => this.toggle('/')}
            onClick={() => {
              this.props.isLoggedIn
                ? this.toggle(
                    `/${this.props.activeMarketSymbol}-${this.props.activeCurrencySymbol}`
                  )
                : this.toggle(`/landingPage`);
              // this.toggle(`/markets`);
            }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i
            className="fa fa-navicon"
            style={{ fontSize: '35px', color: 'white' }}
          />
          {/* <span className="navbar-toggler-icon" /> */}
        </button>
      </nav>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    activeMarketSymbol: state.app.activeMarketSymbol,
    activeCurrencySymbol: state.app.activeCurrencySymbol,
    headerActiveMenu: state.app.headerActiveMenu,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile
  }),
  mapDispatchToProps
)(NavBar);
