/**
 * Main Withdraw Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

// import _ from 'underscore';
// import statusCheck from 'Utils/statusCheck';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  // PopoverHeader,
  PopoverBody
} from 'reactstrap';
import {
  initiateWithdraw,
  getWithdrawLimit,
  coinMarketPrice,
  getDepositWithdraw
  // getDepositWithdraw
} from './withdrawDepositReducer';
// import { getMarketView } from './marketViewReducer';

const cookies = new Cookies();
class Withdraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketViewLoader: true,
      getMarketViewList: {},
      searchData: {},
      marketCrptoArray: [],
      activeTab: 0,
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
      currency: '',
      address: '',
      amount: '',
      value: '',
      modal: false,
      popoverOpen: false,
      selectedCurrency: this.props.currencyId,
      withdrawalLoader: true,
      withdrawalResponse: {},
      withdrawalResponseMessage: '',
      withdrawalLimitLoader: true,
      withdrawalLimitResponse: {},
      coinMarketPriceData: this.props.coinMarketPriceData,
      marketCoinPriceValue: {},
      lastexecutedPrice: '',
      FAModal: false,
      avlBalance: this.props.avlBalance
    };
    this.withrawRequest = this.withrawRequest.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.toggle2FAModal = this.toggle2FAModal.bind(this);
    this.withrawRequestFor2FA = this.withrawRequestFor2FA.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    this.props.getWithdrawLimit({
      currency: this.props.currencyId
    });
    //  this.props.getDepositWithdraw({});
    if (this.props.currencyId != 0) {
      this.props.coinMarketPrice({
        marketId: 0,
        coinId: this.props.currencyId
      });
    } else {
      this.setState({
        value: this.state.amount
      });
    }
  }
  toggleModal() {
    this.props.getWithdrawLimit({
      currency: this.props.currencyId
    });
    //    this.props.getDepositWithdraw({});

    this.setState({
      modal: !this.state.modal,
      withdrawalResponseMessage: ''
    });
  }

  toggle2FAModal() {
    if (cookies.get('code_2fa') == 1) {
      this.setState(
        {
          FAModal: !this.state.FAModal,
          withdrawalResponseMessage: ''
        },
        () => {
          document.getElementById('first').focus();
          return true;
        }
      );
    } else {
      this.withrawRequest();
    }
  }

  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  withrawRequest() {
    const {
      selectedCurrency,
      amount,
      address,
      code1,
      code2,
      code3,
      code4,
      code5,
      code6
    } = this.state;
    this.props.initiateWithdraw({
      code: code1 + code2 + code3 + code4 + code5 + code6,
      coinId: selectedCurrency,
      amount: amount,
      address: address
    });

    if (
      this.state.code1 &&
      this.state.code2 &&
      this.state.code3 &&
      this.state.code4 &&
      this.state.code5 &&
      this.state.code6
    ) {
      this.toggle2FAModal();
    }

    this.toggleModal();
  }

  handle2FASubmit() {
    if (
      this.state.code1 &&
      this.state.code2 &&
      this.state.code3 &&
      this.state.code4 &&
      this.state.code5 &&
      this.state.code6
    ) {
      this.withrawRequest();
    }
  }

  withrawRequestFor2FA() {
    this.toggle2FAModal();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.withdrawalLimitLoader !== nextProps.withdrawalLimitLoader) {
      this.setState({
        withdrawalLimitLoader: nextProps.withdrawalLimitLoader
      });
    }
    if (this.props.currencyId !== nextProps.currencyId) {
      this.props.coinMarketPrice({ marketId: 0, coinId: nextProps.currencyId });
      this.setState({
        selectedCurrency: nextProps.currencyId,
        address: '',
        amount: '',
        value: ''
      });
      this.props.getWithdrawLimit({
        currency: nextProps.currencyId
      });
    }
    if (this.props.coinMarketPriceData !== nextProps.coinMarketPriceData) {
      this.setState(
        {
          coinMarketPriceData: nextProps.coinMarketPriceData
          // lastexecutedPrice: nextProps.coinMarketPriceData.data[0].lastexecutedPrice
        },
        this.coinPriceDataFunc
      );
    }
    if (
      nextProps.withdrawalResponse !== undefined &&
      this.props.withdrawalResponse !== nextProps.withdrawalResponse
    ) {
      // this.props.getWithdrawLimit({
      //   currency: this.props.currencyId
      // });
      if (nextProps.withdrawalResponse.error === 0) {
        this.setState({
          withdrawalLoader: false,
          withdrawalResponseMessage: nextProps.withdrawalResponse.message,
          amount: '',
          address: '',
          value: ''
        });
      } else {
        this.setState({
          withdrawalLoader: true,
          withdrawalResponseMessage: nextProps.withdrawalResponse.message
        });
      }
    }

    if (
      nextProps.withdrawalLimitResponse !== undefined &&
      this.props.withdrawalLimitResponse !== nextProps.withdrawalLimitResponse
    ) {
      this.setState({
        withdrawalLimitResponse: nextProps.withdrawalLimitResponse
      });
    }

    if (this.props.avlBalance !== nextProps.avlBalance) {
      this.setState({
        avlBalance: nextProps.avlBalance
      });
    }
  }
  coinPriceDataFunc() {
    const { coinMarketPriceData } = this.state;
    if (coinMarketPriceData.error === 0) {
      const lastexecutedPrice = coinMarketPriceData.data[0].lastexecutedPrice;
      this.setState({
        lastexecutedPrice: lastexecutedPrice
        // value: e.target.value * lastexecutedPrice
      });
    }
  }
  handleAmountChange = e => {
    const value = e.target.validity.valid ? e.target.value : this.state.amount;
    this.setState({ amount: value });
    // this.props.coinMarketPrice({ marketId:0, coinId:this.props.currencyId });
    //this.coinPriceDataFunc();
    if (this.props.currencyId != 0) {
      this.setState({
        value: e.target.value * this.state.lastexecutedPrice
      });
    } else {
      this.setState({
        value: e.target.value
      });
    }
  };
  handleAddressChange = e => {
    this.setState({ address: e.target.value });
  };
  handleValueChange = e => {};
  render() {
    const { style, currency } = this.props;
    const { avlBalance } = this.state;
    const {
      // address,
      // selectedCurrency,
      // withdrawalLoader,
      // withdrawalResponse,
      withdrawalResponseMessage,
      // amount,
      // withdrawalLimitLoader,
      withdrawalLimitResponse
    } = this.state;
    const avlToWithdraw =
      withdrawalLimitResponse.withdrawlimit -
      withdrawalLimitResponse.withdrawSum;
    const styleWidth =
      (withdrawalLimitResponse.withdrawSum /
        withdrawalLimitResponse.withdrawlimit) *
      100;
    return (
      <div className="col-lg-3 mt-1">
        <div
          id="sidebar-main"
          style={{ height: window.innerHeight - 80 }}
          className="row no-gutters sidebar-content"
        >
          <div className="col-lg-12">
            <div className="currency-info">
              <h1 className="title pt-2 pb-2">
                Daily withdrawal limit ({this.props.currency})
              </h1>
              <div className="mt-5 mb-5">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${styleWidth}%` }}
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
              <div className="sidebar-form pl-3 pr-3">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <div className="row">
                      <div className="col-md-12">
                        <label>Balance</label>
                      </div>
                      <div className="col-md-12">
                        {parseFloat(avlBalance).toFixed(8)}{' '}
                        <span className="currency">{currency}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 text-right">
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <label>Limit available</label>
                      </div>
                      <div className="col-md-12">
                        {parseFloat(avlToWithdraw).toFixed(8)}{' '}
                        <span className="currency">{currency}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="1NDKRg1LA4tBorjjx1HGtybq774pEqZ2de"
                    value={this.state.address}
                    onChange={this.handleAddressChange}
                  />
                </div>
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    pattern="(\d{1,20})?(\.{1}\d{0,8})?"
                    // pattern="(\d{1,4})?(\.{1}\d{0,8})?"
                    autoComplete="off"
                    type="text"
                    id="amt"
                    // step=".00000001"
                    value={this.state.amount}
                    onChange={this.handleAmountChange}
                    className="form-control"
                    placeholder={'2.8756 ' + this.props.currency}
                    required
                  />
                  {/* <input
                    pattern="(\d{1,20})?(\.{1}\d{0,8})?"
              // pattern="(\d{1,4})?(\.{1}\d{0,8})?"
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    placeholder={'2.8756 ' + this.props.currency}
                    value={this.state.amount}
                    onChange={this.handleAmountChange}
                  /> */}
                </div>
                <div className="form-group">
                  <label>Value</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    placeholder="2.8756 BTC"
                    value={this.state.value}
                    // onChange={this.handleValueChange}
                  />
                </div>
              </div>
              {(this.state.address === '' ||
                this.state.amount === '' ||
                this.state.value >= avlBalance) && (
                <div>
                  <button
                    className="btn btn-blue"
                    // style={{ background: '#6c757d' }}
                    id="Popover1"
                    onClick={this.togglePopOver}
                  >
                    Request To Withdraw
                  </button>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target="Popover1"
                    toggle={this.togglePopOver}
                  >
                    <PopoverBody>
                      Please enter Amount and Address value.
                    </PopoverBody>
                  </Popover>
                </div>
              )}

              {this.state.address !== '' && this.state.amount !== '' && (
                <button
                  type="submit"
                  className="btn btn-blue"
                  onClick={this.withrawRequestFor2FA}
                >
                  Withdraw
                </button>
              )}
              {withdrawalResponseMessage !== '' && (
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggleModal}
                  className={this.props.className}
                  centered
                >
                  <ModalBody style={{ textAlign: 'center' }}>
                    {withdrawalResponseMessage}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      style={{ width: '100px' }}
                      color="primary"
                      onClick={this.toggleModal}
                    >
                      Ok
                    </Button>
                  </ModalFooter>
                </Modal>
              )}

              <Modal
                isOpen={this.state.FAModal}
                toggle={this.toggle2FAModal}
                className={this.props.className + ' top-30'}
              >
                <ModalHeader className="headerleft">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.toggle2FAModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </ModalHeader>
                <ModalBody style={{ textAlign: 'center' }}>
                  <h2>Two factor authentication</h2>
                  <p className="pb-3">
                    Enter the code generated by your authenticator app
                  </p>
                  <div className="container container-popup mb-3">
                    <div className="row">
                      <div className="col-md-2 two-fa-box">
                        <input
                          type="text"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          id="first"
                          onChange={e => {
                            this.setState({ code1: e.target.value });
                            document.getElementById('sec').focus();
                          }}
                        />
                      </div>
                      <div className="col-md-2 two-fa-box">
                        <input
                          type="text"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          id="sec"
                          onChange={e => {
                            this.setState({ code2: e.target.value });
                            document.getElementById('third').focus();
                          }}
                        />
                      </div>
                      <div className="col-md-2 two-fa-box">
                        <input
                          type="text"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          id="third"
                          onChange={e => {
                            this.setState({ code3: e.target.value });
                            document.getElementById('fourth').focus();
                          }}
                        />
                      </div>
                      <div className="col-md-2 two-fa-box">
                        <input
                          type="text"
                          id="fourth"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          onChange={e => {
                            this.setState({ code4: e.target.value });
                            document.getElementById('fifth').focus();
                          }}
                        />
                      </div>
                      <div className="col-md-2 two-fa-box">
                        <input
                          type="text"
                          id="fifth"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          onChange={e => {
                            this.setState({ code5: e.target.value });
                            document.getElementById('sixth').focus();
                          }}
                        />
                      </div>
                      <div className="col-md-2 two-fa-box">
                        <input
                          id="sixth"
                          type="text"
                          className="w-75 two-factor-input"
                          maxLength="1"
                          onChange={e => {
                            this.setState({ code6: e.target.value }, () =>
                              this.handle2FASubmit(e)
                            );
                            document.getElementById('button').focus();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-fixed"
                    id="button"
                    onClick={this.withrawRequest}
                  >
                    Submit
                  </button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initiateWithdraw: formData => initiateWithdraw(formData),
      getWithdrawLimit: formData => getWithdrawLimit(formData),
      coinMarketPrice: formData => coinMarketPrice(formData),
      getDepositWithdraw: formData => getDepositWithdraw(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    currencyId: state.transactions.currencyId,
    withdrawalResponse: state.transactions.withdrawalResponse,
    avlBalance: state.transactions.avlBalance,
    currency: state.transactions.currency,
    withdrawalLimitResponse: state.transactions.withdrawalLimitResponse,
    withdrawalLimitLoader: state.transactions.withdrawalLimitLoader,
    coinMarketPriceData: state.transactions.coinMarketPriceData
  }),
  mapDispatchToProps
)(Withdraw);
