import apiCaller from 'Utils/apiCallerNew';

const REFERRAL_HISTORY_SUCCESS = 'REFERRAL_HISTORY_SUCCESS';
const REFERRAL_HISTORY_REQUESTED = 'REFERRAL_HISTORY_REQUESTED';
//const DEPOSIT_WITHDRAW_FORM_VIEW = 'DEPOSIT_WITHDRAW_FORM_VIEW';
const SEARCH_VALUE_UPDATE = 'SEARCH_VALUE_UPDATE';
const REFERRAL_ACTIVE_TAB_UPDATE = 'REFERRAL_ACTIVE_TAB_UPDATE';
const REFERRAL_RANK_HISTORY_SUCCESS = 'REFERRAL_RANK_HISTORY_SUCCESS';
const REFERRAL_RANK_HISTORY_REQUESTED = 'REFERRAL_RANK_HISTORY_REQUESTED';
const REFERRAL_TRANSFER_REQUESTED = 'REFERRAL_TRANSFER_REQUESTED';
const REFERRAL_TRANSFER_SUCCESS = 'REFERRAL_TRANSFER_SUCCESS';
const CLICKED_ON_TRANSFER = 'CLICKED_ON_TRANSFER';

const initialState = {
  getReferralHistoryList: [],
  referralHistoryLoader: true,
  rankLoader: true,
  getRefRankHistoryList: [],
  searchValue: '',
  searchData: [],
  activeTab: '1',
  dummy: true,
  loadInitial: true,
  currencyId: '',
  balance: '',
  refTransferLoader: true,
  refTransferResponse: {}
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_HISTORY_REQUESTED:
      return {
        ...state,
        referralHistoryLoader: true
      };
    case REFERRAL_HISTORY_SUCCESS:
      return {
        ...state,
        referralHistoryLoader: false,
        getReferralHistoryList: action.response,
        dummy: true
      };

    case SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case REFERRAL_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case REFERRAL_RANK_HISTORY_REQUESTED:
      return {
        ...state,
        rankLoader: true
      };
    case REFERRAL_RANK_HISTORY_SUCCESS:
      return {
        ...state,
        rankLoader: false,
        getRefRankHistoryList: action.response
      };
    case CLICKED_ON_TRANSFER:
      return {
        ...state,
        loadInitial: action.loadInitial,
        currencyId: action.currencyId,
        balance: action.balance
      };
    case REFERRAL_TRANSFER_REQUESTED:
      return {
        ...state,
        refTransferLoader: true
      };
    case REFERRAL_TRANSFER_SUCCESS:
      return {
        ...state,
        refTransferLoader: false,
        refTransferResponse: action.response
      };
    default:
      return state;
  }
};

export const getReferralHistory = formData => dispatch => {
  dispatch({
    type: REFERRAL_HISTORY_REQUESTED
  });

  apiCaller('referalbalance', 'get', formData, false, true, false).then(
    response => {
      dispatch({
        type: REFERRAL_HISTORY_SUCCESS,
        response
      });
    }
  );
};

export const searchValueUpdate = formData => dispatch => {
  dispatch({
    type: SEARCH_VALUE_UPDATE,
    searchValue: formData.searchValue
  });
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: REFERRAL_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};

export const getRefRankHistory = formData => dispatch => {
  dispatch({
    type: REFERRAL_RANK_HISTORY_REQUESTED
  });

  apiCaller('referalrank', 'get', formData, false, true, false).then(
    response => {
      dispatch({
        type: REFERRAL_RANK_HISTORY_SUCCESS,
        response
      });
    }
  );
};
export const referalTransfer = formData => dispatch => {
  dispatch({
    type: REFERRAL_TRANSFER_REQUESTED
  });

  apiCaller('referalwithdraw/', 'post', formData, false, true, false).then(
    response => {
      dispatch({
        type: REFERRAL_TRANSFER_SUCCESS,
        response
      });
    }
  );
};

export const selectSideBarToShow = formData => dispatch => {
  dispatch({
    type: CLICKED_ON_TRANSFER,
    loadInitial: formData.loadInitial,
    currencyId: formData.currencyId,
    balance: formData.balance
  });
};
