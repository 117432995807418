import apiCaller from 'Utils/apiCallerNew';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const history = {};

export default {
  history: history,

  getBars: function(symbolInfo, resolution, from, to, first, limit) {
    let activeMarketValue = cookies.get('activeMarketValue') || '0';
    let activeCurrencyValue = cookies.get('activeCurrencyValue') || '1';

    // var split_symbol = symbolInfo.name.split(/[:/]/);
    const cryptoMarket = `${activeMarketValue}/${activeCurrencyValue}`;
    const url =
      resolution === 'D'
        ? `1440/${from}/${to}`
        : resolution === '360'
        ? `360/${from}/${to}`
        : resolution === '120'
        ? `120/${from}/${to}`
        : resolution === '30'
        ? `30/${from}/${to}`
        : resolution === '15'
        ? `15/${from}/${to}`
        : `5/${from}/${to}`;

    return apiCaller(
      `candlestick/${cryptoMarket}/${url}`,
      'get',
      '',
      false,
      false,
      false
    ).then(
      // return apiCaller(`chart/BTC${url}`, 'get', '', false, false, false).then(
      data => {
        if (data.err !== 0) {
          // console.log('CryptoCompare API error:', data.Message);
          return [];
        }
        var data = data.data.data;
        if (data.length) {
          // console.log(
          //   `Actually returned: ${new Date(
          //     data.TimeFrom * 1000
          //   ).toISOString()} - ${new Date(data.TimeTo * 1000).toISOString()}`
          // );
          var bars = data.map(el => {
            return {
              // time: el.time,
              time: new Date(el.opentime).getTime(),
              // time: el.time * 1000, //TradingView requires bar time in ms
              low: el.low,
              high: el.high,
              open: el.open,
              close: el.close,
              volume: el.volume
              // volume: el.volumefrom
            };
          });
          if (first) {
            var lastBar = bars[bars.length - 1];
            history[symbolInfo.name] = { lastBar: lastBar };
          }
          return bars;
        } else {
          return [];
        }
      }
    );
  }
};
