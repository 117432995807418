import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div className="row justify-content-sm-center">
        <div className="col-sm-10 col-md-8 col-lg-5" style={{ margin: '3rem' }}>
          <div>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/404.png`}
              alt="404"
            />
          </div>
          <div className="form-sec1 text-center">
            <h5>
              Uh-oh. Wrong place my friend-o. This resource is not available.
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
