const selectFavCurrency = coinId => {
  const favCoinArray =
    (localStorage.getItem('favCoinArray') &&
      JSON.parse(localStorage.getItem('favCoinArray'))) ||
    [];
  if (favCoinArray.indexOf(coinId) === -1) {
    favCoinArray.push(coinId);
    favCoinArray.sort(function(a, b) {
      return a - b;
    });
    localStorage.setItem('favCoinArray', JSON.stringify(favCoinArray));
  } else {
    favCoinArray.splice(favCoinArray.indexOf(coinId), 1);
    favCoinArray.sort(function(a, b) {
      return a - b;
    });
    localStorage.setItem('favCoinArray', JSON.stringify(favCoinArray));
  }
  return favCoinArray;
};

export default { selectFavCurrency };
