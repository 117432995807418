/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import _ from 'underscore';
import removeCookie from 'Utils/removeCookie';
import statusCheck from 'Utils/statusCheck';
import sort from 'Utils/sortData';

import { logout } from 'Modules/signin/signinReducer';

import { getOrderDetails, updateFalcon } from '../../dashboardReducer';
import {
  removeMyOpenOrderInList,
  getMyOrderHistory,
  getMyOpenOrders,
  getMyBalance,
  removeMyOpenOrderRequest
} from './myOrdersReducer';
import { selectedOpenOrderIdFunc } from 'Modules/openOrders/openOrdersReducer';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const cookies = new Cookies();
let email = cookies.get('email');
let code_2fa = cookies.get('code_2fa');
let sortType = '';
let priceCount = '';
let volumeCount = '';
let totalCount = '';

class MyOpenOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: '',
      myOrderHistoryLoader: false,
      myOpenOrdersLoader: false,
      orderList: [],
      orderListSort: [],
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: false,
      txnid: '',
      code: '',
      currency: 'BTC',
      getRemoveOrderResponse: {},
      selectedOrderObj: {},
      modal: false,
      coinSymbolsObj: this.props.coinSymbolsObj,
      data_table_height: this.props.data_table_height,
      selectedOrderId: this.props.selectedOrderId,
      sortingCol: ''
    };
    this.toggle = this.toggle.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.filterSelectedOrder = this.filterSelectedOrder.bind(this);
    this.manageCount = this.manageCount.bind(this);
  }

  componentDidMount() {
    this.props.getMyOpenOrders({
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue
    });
  }

  componentWillUnmount() {
    this.props.selectedOpenOrderIdFunc({ selectedOrderId: '' });
  }

  filterSelectedOrder(getMyOpenOrdersList) {
    const { selectedOrderId } = this.state;
    if (selectedOrderId) {
      var notSelectedOrders = getMyOpenOrdersList.filter(function(obj) {
        return obj.orderId !== selectedOrderId;
      });
      var selectedOrder = getMyOpenOrdersList.filter(function(obj) {
        return obj.orderId === selectedOrderId;
      });
      notSelectedOrders.unshift(selectedOrder[0]);
      // ('notSelectedOrders - ', notSelectedOrders);
      this.setState({
        orderListSort: notSelectedOrders
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data_table_height !== nextProps.data_table_height) {
      this.setState({ data_table_height: nextProps.data_table_height });
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    // Set Loader for Order History
    if (this.props.myOrderHistoryLoader !== nextProps.myOrderHistoryLoader) {
      this.setState({ myOrderHistoryLoader: nextProps.myOrderHistoryLoader });
    }

    if (this.props.myOpenOrdersLoader !== nextProps.myOpenOrdersLoader) {
      this.setState({ myOpenOrdersLoader: nextProps.myOpenOrdersLoader });
    }

    if (this.props.selectedOrderId !== nextProps.selectedOrderId) {
      this.setState({ selectedOrderId: nextProps.selectedOrderId });
    }

    if (
      this.props.getRemoveOrderResponse !== nextProps.getRemoveOrderResponse
    ) {
      if (nextProps.getRemoveOrderResponse.error === 0) {
        this.setState(
          {
            getRemoveOrderResponse: nextProps.getRemoveOrderResponse
            // message: nextProps.getRemoveOrderResponse.message
          },
          this.checkRemoveResponse
        );
      }
    }

    if (this.props.getMyOpenOrdersList !== nextProps.getMyOpenOrdersList) {
      if (nextProps.getMyOpenOrdersList.error === 0) {
        this.setState(
          {
            orderList: nextProps.getMyOpenOrdersList.data,
            orderListSort: nextProps.getMyOpenOrdersList.data,
            error: null
          },
          this.filterSelectedOrder(nextProps.getMyOpenOrdersList.data)
        );
      } else if (nextProps.getMyOpenOrdersList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          orderList: null,
          error: nextProps.getMyOpenOrdersList.message
        });
      }
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      setTimeout(() => {
        this.props.getMyOpenOrders({
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue
        });
      }, 100);
    }

    // if (this.props.coin !== nextProps.coin) {
    //   this.props.getMyOrderHistory({
    //     operation: 1,
    //     coin: 1
    //   });

    //   this.props.getMyOpenOrders({
    //     operation: 0,
    //     coin: 1
    //   });

    // }
  }

  hideModalCancelOrder() {
    setTimeout(() => {
      this.setState({
        message: false
      });
    }, 2000);
  }

  checkRemoveResponse() {
    this.hideModalCancelOrder();
    const { getRemoveOrderResponse, removeOrderId } = this.state;
    if (getRemoveOrderResponse.error === 0) {
      this.props.removeMyOpenOrderInList({ orderId: removeOrderId });
      this.props.getMyBalance({});
    }
  }

  manageCount(columnName, sortType) {
    columnName = columnName;
    switch (columnName) {
      case 'price':
        totalCount = 0;
        volumeCount = 0;
        priceCount = 1;
        break;
      case 'volume':
        priceCount = 0;
        totalCount = 0;
        volumeCount = 1;
        break;
      case 'total':
        priceCount = 0;
        volumeCount = 0;
        totalCount = 1;
        break;
      default:
        break;
    }
  }
  sortColumn(type, columnName) {
    const { orderList } = this.state;
    let orderListSort = [];

    this.manageCount(columnName, sortType);
    if (this.state.sortingCol != columnName) {
      this.setState({
        sortingCol: columnName
      });
      sortType = 'asc';
    } else {
      if (sortType == '' || sortType == 'desc') {
        sortType = 'asc';
      } else {
        sortType = 'desc';
      }
    }
    orderListSort = sort.sort(orderList, type, columnName, sortType);

    this.setState({
      orderListSort
    });
  }

  cancelOrder(obj) {
    this.setState(
      {
        removeOrderId: obj.id
      },
      this.props.removeMyOpenOrderRequest(obj)
    );
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  selectedOrderFunc(order) {
    this.setState(
      {
        selectedOrderObj: order
      },
      // this.toggle
      this.cancelOrder({
        type: order.type,
        id: order.orderId
      })
    );
  }

  render() {
    code_2fa =
      this.props.profile !== undefined ? this.props.profile.code_2f : code_2fa;
    const {
      error,
      myOpenOrdersLoader,
      coinSymbolsObj,
      orderList,
      orderListSort,
      selectedOrderObj,
      modal,
      selectedOrderId
    } = this.state;
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    if (error === 'Unable to authenticate') {
      return <div />;
    }

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (myOpenOrdersLoader) {
      return <div className="loading-o" />;
    }

    return (
      <div id="data-table" className="container-fluid openOrders">
        {orderListSort && orderListSort.length !== 0 ? (
          <table
            className="table data-table card-box table-fixed"
            style={{ marginBottom: '0px' }}
          >
            <thead>
              <tr>
                {/* <th scope="col">
                  <input type="checkbox" name="1" value="1" />
                </th> */}
                <th scope="col">Pair</th>
                <th scope="col">Type</th>
                <th
                  scope="col"
                  onClick={() => this.sortColumn('number', 'price')}
                  className="crsrPntr"
                >
                  Price{' '}
                  {priceCount == 1 ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  onClick={() => this.sortColumn('number', 'volume')}
                  className="crsrPntr"
                >
                  {`Amount(${this.props.activeCurrencySymbol}) `}
                  {volumeCount == 1 ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  onClick={() => this.sortColumn('number', 'total')}
                  className="crsrPntr"
                >
                  {`Total(${this.props.activeMarketSymbol}) `}
                  {totalCount == 1 ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th scope="col">Cancel</th>

                {/* <th scope="col" onClick={() => this.sortColumn('amount')} style={{cursor: 'pointer'}}>
									Amount(LSK)
									<i className="fa fa-caret-up ml-1" aria-hidden="true"></i>
								</th> */}
              </tr>
            </thead>
            <tbody style={{ height: this.state.data_table_height }}>
              {orderListSort.map((o, i) => (
                <tr
                  key={i}
                  className={
                    selectedOrderId === o.orderId
                      ? 'selected-table-row'
                      : undefined
                  }
                >
                  {/* <td>
                    <input type="checkbox" name="1" value="1" />
                  </td> */}
                  {coinSymbolsObj && (
                    <td>{`${coinSymbolsObj[o.marketId].coinsymbol}/${
                      coinSymbolsObj[o.coinId].coinsymbol
                    }`}</td>
                  )}

                  {/* <td className="negative">Sell</td> */}
                  <td
                    className={o.operation ? 'positive-text' : 'negative-text'}
                  >
                    {statusCheck.buySellStatusCheck(o.operation)}
                  </td>

                  {/* <td>{`${this.props.marketsCoinsListObj[o.market].marketdata[0].marketsymbol}/${this.props.marketsCoinsListObj[o.market].coins[o.currency].coinsymbol}`}</td> */}
                  {/* <td>{`${o.market}/${o.currency}`}</td> */}
                  <td>{o.price.toFixed(8)}</td>
                  <td>{o.volume.toFixed(8)}</td>
                  <td>{o.total.toFixed(8)}</td>

                  <td>
                    <div
                      className="button-cancel"
                      onClick={() => this.selectedOrderFunc(o)}
                    >
                      Cancel
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="noBal">No Open Orders</div>
        )}

        {this.state.message && (
          <div className="notification-box">
            {/* <div className="notification-title">Order </div> */}
            <p>{this.state.message}</p>
          </div>
        )}

        {modal && (
          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle} close={closeBtn}>
              Do you want to delete this order ?
            </ModalHeader>
            <ModalBody>
              <div className="col-md-12 faded">
                {`Pair: ${
                  this.props.marketsCoinsListObj[selectedOrderObj.marketId]
                    .marketdata[0].marketsymbol
                }/${
                  this.props.marketsCoinsListObj[selectedOrderObj.marketId]
                    .coins[selectedOrderObj.coinId].coinsymbol
                }`}
              </div>

              <div
                className={`${
                  selectedOrderObj.operation ? 'positive' : 'negative'
                } col-md-12 faded`}
              >
                {`Type: ${statusCheck.buySellStatusCheck(
                  selectedOrderObj.operation
                )}`}
              </div>

              <div className="col-md-12 faded">
                {`Price: ${selectedOrderObj.price.toFixed(8)}`}
              </div>

              <div className="col-md-12 faded">
                {`Amount(${
                  this.props.activeCurrencySymbol
                }): ${selectedOrderObj.volume.toFixed(8)}`}
              </div>

              <div className="col-md-12 faded">
                {`Total(${this.props.activeCurrencySymbol}): ${(
                  selectedOrderObj.price * selectedOrderObj.volume
                ).toFixed(8)}`}
              </div>

              <div className="col-md-12 faded">
                {`Price: ${selectedOrderObj.price.toFixed(8)}`}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary"
                onClick={() =>
                  this.cancelOrder({
                    type: selectedOrderObj.type,
                    id: selectedOrderObj.orderId
                  })
                }
              >
                Remove
              </button>{' '}
              <button className="btn btn-secondary" onClick={this.toggle}>
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

MyOpenOrders.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1,
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderDetails: formData => getOrderDetails(formData),
      getMyOrderHistory: formData => getMyOrderHistory(formData),
      getMyOpenOrders: formData => getMyOpenOrders(formData),
      changePage: page => push(page),
      updateFalcon: formData => updateFalcon(formData),
      removeMyOpenOrderInList: formData => removeMyOpenOrderInList(formData),
      removeMyOpenOrderRequest: formData => removeMyOpenOrderRequest(formData),
      selectedOpenOrderIdFunc: formData => selectedOpenOrderIdFunc(formData),
      getMyBalance: formData => getMyBalance(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    orderDetails: state.dashboard.orderDetails,
    myOrderHistoryLoader: state.myOrders.myOrderHistoryLoader,
    myOpenOrdersLoader: state.myOrders.myOpenOrdersLoader,
    profile: state.signin.profile,
    getMyOrderHistoryList: state.myOrders.getMyOrderHistoryList,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    getMyOpenOrdersList: state.myOrders.getMyOpenOrdersList,
    getRemoveOrderResponse: state.myOrders.getRemoveOrderResponse,
    coinSymbolsObj: state.app.coinSymbolsObj,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    data_table_height: state.dashboard.data_table_height,
    selectedOrderId: state.openOrders.selectedOrderId
  }),
  mapDispatchToProps
)(MyOpenOrders);
