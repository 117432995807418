/**
 * Main Transaction Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { activeTabUpdate } from './openOrdersReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';

import TradeHistory from './tradeHistory';
import WithdrawDepositHistory from 'Modules/withdrawdeposit/history';
import TradeAnalysis from './tradeAnalysis';
import WDSort from 'Modules/withdrawdeposit/withdrawDepositSideBar';

import ErrorBoundary from 'Modules/ErrorBoundary';
import _ from 'underscore';

class History extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      tradeHistoryLoader: true,
      getTradeHistoryList: [],
      searchData: [],
      coinSymbolsObj: {},
      error: null,
      message: '',
      copied: false,
      sidebar_main_height: window.innerHeight - 60
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('history');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }

    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (this.props.tradeHistoryLoader !== nextProps.tradeHistoryLoader) {
      this.setState({ tradeHistoryLoader: nextProps.tradeHistoryLoader });
    }

    //Handle The Response IF Recieved Transaction Data
    if (
      nextProps.getTradeHistoryList &&
      nextProps.getTradeHistoryList !== undefined &&
      this.props.getTradeHistoryList !== nextProps.getTradeHistoryList
    ) {
      this.setState({
        getTradeHistoryList: nextProps.getTradeHistoryList
      });
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }
  }

  render() {
    const {
      error,
      referralHistoryLoader,
      searchData,
      referralError,
      getReferralHistoryList,
      coinSymbolsObj,
      message,
      refCode,
      refEarnings,
      dropdownOpen
    } = this.state;

    return (
      <div className="row no-gutters">
        <div className="col-lg-9 container-one">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '1' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('1');
                }}
                active={(this.props.activeTab === '1').toString()}
                data-toggle="tab"
                role="tab"
                aria-selected={this.props.activeTab === '1'}
              >
                Trade History
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '2' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('2');
                }}
                active={(this.props.activeTab === '2').toString()}
                data-toggle="tab"
                role="tab"
                aria-selected={this.props.activeTab === '2'}
              >
                W/D History
              </a>
            </li>
          </ul>
          {this.props.activeTab === '1' && (
            <ErrorBoundary>
              <TradeHistory />
            </ErrorBoundary>
          )}

          {this.props.activeTab === '2' && (
            <ErrorBoundary>
              <WithdrawDepositHistory />
            </ErrorBoundary>
          )}
        </div>
        {this.props.activeTab === '1' && <TradeAnalysis />}
        {this.props.activeTab === '2' && (
          <WDSort style={{ height: this.state.sidebar_main_height }} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      activeTabUpdate: formData => activeTabUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    activeTab: state.openOrders.activeTab,
    coinSymbolsObj: state.app.coinSymbolsObj,
    isLoggedIn: state.signin.isLoggedIn
  }),
  mapDispatchToProps
)(History);
