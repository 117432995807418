import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import MarginTrading from './Margin-Trade';
import JobPortal from './Job-Portal';
import Arrows from './Arrows';
import Crypto from './Crpto';
import Loyalty from './Loyalty';
import 'fullpage.js/vendors/scrolloverflow';
/* import StartupModal from './StartupModal'; */

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
// const pluginWrapper = () => {
//   require('fullpage.js/vendors/scrolloverflow');
//   require('./statics/fullpage.scrollHorizontally.min');
// };

let wid = window.outerWidth;

const setScroll = fullpageApi => {
  if (fullpageApi) {
    if (wid > 768) {
      // fullpageApi.setAllowScrolling(true);
      // fullpageApi.setAutoScrolling(true);
      fullpageApi.setResponsive(false);
    } else {
      fullpageApi.setResponsive(true);
    }
  }
};

const enableScroll = fullpageApi => {
  // if (fullpageApi) {
  //   fullpageApi.setResponsive(true);
  //   fullpageApi.setResponsive(true);
  // }
};
const Fullpage = () => {
	const [isShowStartupModal, setIsShowStartupModal] = useState(true);
	if (isShowStartupModal) {
		return (
		<>
		<div className="App" id="app" style={{minHeight:'100vh', backgroundColor:'#2b5eef'}}>
			<div id="layout-container">
				<div id="fullpage">

					{/* First page starts */}
					<div
						id="home"
						className="d-flex w-100 h-100 mx-auto flex-column grow main-landing section test-color"
						data-anchor="Home"
					>
						{/* Navigation starts */}

						<nav
							id="navigation-mai"
							className="navbar navbar-expand-lg p-5 mb-auto animated fadeIn z-index"
						>
							<a className="navbar-brand" href="#">
								<img
									src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
									alt="Logo"
									style={{ height: '30px' }}
								/>
							</a>
							<button
								className="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarNavDropdown"
								aria-controls="navbarNavDropdown"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<i
									className="fa fa-navicon"
									style={{ fontSize: '35px', color: 'white' }}
								/>
							</button>
							<div
								className="collapse navbar-collapse "
								id="navbarNavDropdown"
							>
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										<a className="nav-link active" href="#Home">
											Home
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#Overview">
											Overview
										</a>
									</li>

									<li className="nav-item">
										<a className="nav-link" href="#Trading">
											Trading
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#Loyalty">
											Loyalty
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" href="#Careers">
											Work with us
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link not-faded" href="/login">
											Login
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link btn-signup ml-2 not-faded"
											href="/signup"
										>
											Sign up
										</a>
									</li>
								</ul>
							</div>
						</nav>
						{/* Navigation ends */}
						<main id="landing-who">
							<div className="container-fluid vertical-center">
								<div className="row">
									<div className="col-md-5 col-sm-12 align-self-center">
										<div className="branding-logo animated fadeIn delay-2">
											<img
												height="320"
												src={`${process.env.PUBLIC_URL}/assets/images/lines_logo.png`}
												className="ml-auto animated slideInDown img-fluid"
											/>
										</div>
									</div>
									<div className="col-md-4 col-sm-12 align-self-center center-aligned">
										<h1 className="pb-2 no-wrap">
											Your home for trading crypto.
										</h1>
										<a
											href="/markets"
											className="btn btn-markets"
											role="button"
										>
											View markets
										</a>
									</div>
								</div>
							</div>
						</main>
					</div>
					{/* First page ends */}
				</div>
			</div>
		</div>
		{/* <StartupModal onClose={() => setIsShowStartupModal(false)} /> */}
		</>
	);
}
	return (
  <>
    <ReactFullpage
      // pluginWrapper

      render={({ state, fullpageApi }) => {
        // console.log(fullpageApi);
        const disableScroll = modal => {
          if (fullpageApi && modal == true) {
            fullpageApi.setAllowScrolling(false);
            fullpageApi.setKeyboardScrolling(false);
          } else {
            fullpageApi.setAllowScrolling(true);
            fullpageApi.setKeyboardScrolling(true);
          }
        };

        setScroll(fullpageApi);

        return (
          <ReactFullpage.Wrapper>
            <div className="App" id="app">
              <div id="layout-container">
                <div id="fullpage">

                  {/* First page starts */}
                  <div
                    id="home"
                    className="d-flex w-100 h-100 mx-auto flex-column grow main-landing section"
                    data-anchor="Home"
                  >
                    {/* Navigation starts */}

                    <nav
                      id="navigation-mai"
                      className="navbar navbar-expand-lg p-5 mb-auto animated fadeIn z-index"
                    >
                      <a className="navbar-brand" href="#">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                          alt="Logo"
                          style={{ height: '30px' }}
                        />
                      </a>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <i
                          className="fa fa-navicon"
                          style={{ fontSize: '35px', color: 'white' }}
                        />
                      </button>
                      <div
                        className="collapse navbar-collapse "
                        id="navbarNavDropdown"
                      >
                        <ul className="navbar-nav ml-auto">
                          <li className="nav-item">
                            <a className="nav-link active" href="#Home">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#Overview">
                              Overview
                            </a>
                          </li>

                          <li className="nav-item">
                            <a className="nav-link" href="#Trading">
                              Trading
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#Loyalty">
                              Loyalty
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#Careers">
                              Work with us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link not-faded" href="/login">
                              Login
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn-signup ml-2 not-faded"
                              href="/signup"
                            >
                              Sign up
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    {/* Navigation ends */}
                    <main id="landing-who">
                      <div className="container-fluid vertical-center">
                        <div className="row">
                          <div className="col-md-5 col-sm-12 align-self-center">
                            <div className="branding-logo animated fadeIn delay-2">
                              <img
                                height="320"
                                src={`${process.env.PUBLIC_URL}/assets/images/lines_logo.png`}
                                className="ml-auto animated slideInDown img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12 align-self-center center-aligned">
                            <h1 className="pb-2 no-wrap">
                              Your home for trading crypto.
                            </h1>
                            <a
                              href="/markets"
                              className="btn btn-markets"
                              role="button"
                            >
                              View markets
                            </a>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                  {/* First page ends */}
                  {/* Second page starts */}
                  <div
                    id="crypto"
                    className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-2s section"
                    data-anchor="Overview"
                  >
                    <Crypto />
                  </div>
                  {/* Second page ends */}
                  {/* Third page starts */}
                  {/* Third page ends */}

                  {/* Third2 page starts */}
                  <div
                    className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s section "
                    data-anchor="Trading"
                    id="landing-products"
                  >
                    <MarginTrading id="trading" disableScroll={disableScroll} />
                    {/* Third2 page ends */}
                  </div>
                  {/* Fourth page starts */}
                  <div
                    className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s section"
                    data-anchor="Loyalty"
                    id="landing-products"
                  >
                    {/* <div className="space bg-light mb-auto" /> */}
                    <Loyalty disableScroll={disableScroll} />
                  </div>
                  {/* Fourth page ends */}
                  {/* Sixth page starts */}
                  <div
                    className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s  career-height section"
                    data-anchor="Careers"
                    id="landing-products"
                  >
                    <JobPortal />
                  </div>
                  {/* Sixth page ends */}
                </div>
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
    <Arrows />
  </>
);
		}

export default Fullpage;
