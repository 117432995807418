import apiCaller from 'Utils/apiCallerNew';
import _ from 'underscore';

const DEPOSIT_WITHDRAW_HIDE_ZERO_BALANCES =
  'DEPOSIT_WITHDRAW_HIDE_ZERO_BALANCES';
const DEPOSIT_WITHDRAW_HISTORY_SUCCESS = 'DEPOSIT_WITHDRAW_HISTORY_SUCCESS';
const DEPOSIT_WITHDRAW_HISTORY_REQUESTED = 'DEPOSIT_WITHDRAW_HISTORY_REQUESTED';
const WITHDRAW_HISTORY_SUCCESS = 'WITHDRAW_HISTORY_SUCCESS';
const WITHDRAW_HISTORY_REQUESTED = 'WITHDRAW_HISTORY_REQUESTED';
const TRADE_ACTIVE_DURATION_UPDATE = 'TRADE_ACTIVE_DURATION_UPDATE';
const DEPOSIT_WITHDRAW_FORM_VIEW = 'DEPOSIT_WITHDRAW_FORM_VIEW';
const AUTO_SUGGEST_COIN_ID_UPDATE = 'AUTO_SUGGEST_COIN_ID_UPDATE';
const TRANSACTION_ACTIVE_TAB_UPDATE = 'TRANSACTION_ACTIVE_TAB_UPDATE';
const TRANSFER_HISTORY_SUCCESS = 'TRANSFER_HISTORY_SUCCESS';
const TRANSFER_HISTORY_REQUESTED = 'TRANSFER_HISTORY_REQUESTED';
const TRANSFER_FORM_VIEW = 'TRANSFER_FORM_VIEW';
const ADDRESS_GENERATION_SUCCESS = 'ADDRESS_GENERATION_SUCCESS';
const ADDRESS_GENERATION_REQUESTED = 'ADDRESS_GENERATION_REQUESTED';
const ADDRESS_GENERATION_IN_PROGRESSS = 'ADDRESS_GENERATION_IN_PROGRESSS';
const SELECTED_CURRENCY_UPDATE = 'SELECTED_CURRENCY_UPDATE';
const COINS_REQUESTED = 'COINS_REQUESTED';
const COIN_REQUEST_SUCCESS = 'COIN_REQUEST_SUCCESS';
const WITHDRAWAL_REQUESTED = 'WITHDRAWAL_REQUESTED';
const WITHDRAWAL_SUCCESS = 'WITHDRAWAL_SUCCESS';
const WITHDRAW_LIMIT_REQUESTED = 'WITHDRAW_LIMIT_REQUESTED';
const WITHDRAW_LIMIT_SUCCESS = 'WITHDRAW_LIMIT_SUCCESS';
const WITHDRAW_DEPOSIT_AUTO_SUGGEST_COIN_ID_UPDATE =
  'WITHDRAW_DEPOSIT_AUTO_SUGGEST_COIN_ID_UPDATE';
const WITHDRAW_DEPOSIT_INPUT_TEXT = 'WITHDRAW_DEPOSIT_INPUT_TEXT';
const WITHDRAW_DEPOSIT_AUTO_SUGGEST_VALUE_UPDATE =
  'WITHDRAW_DEPOSIT_AUTO_SUGGEST_VALUE_UPDATE';
const HISTORY_AUTO_SUGGEST_COIN_ID_UPDATE =
  'HISTORY_AUTO_SUGGEST_COIN_ID_UPDATE';
const HISTORY_INPUT_TEXT = 'HISTORY_INPUT_TEXT';
const SEARCH_DATA_UPDATE = 'SEARCH_DATA_UPDATE';
const COUNT_UPDATE = 'COUNT_UPDATE';
const DATE_FILTER_UPDATE = 'DATE_FILTER_UPDATE';
const COIN_MARKET_PRICE = 'COIN_MARKET_PRICE';
const DW_SEARCH_VALUE_UPDATE = 'DW_SEARCH_VALUE_UPDATE';
const SORT_BY_FAV_UPDATE = 'SORT_BY_FAV_UPDATE';
const WITH_DEPOSIT_HISTORY_LIMIT_UPDATE = 'WITH_DEPOSIT_HISTORY_LIMIT_UPDATE';

const initialState = {
  getDepositWithdrawList: [],
  depositWithdrawLoader: true,
  getTransferHistoryList: [],
  transferHistoryLoader: true,
  coinLoader: true,
  addressLoader: true,
  activeTab: '1',
  hideZero: false,
  depositFormView: false,
  withdrawFormView: false,
  transferFormView: false,
  withdrawHistoryLoader: true,
  getWithDrawHistoryList: [],
  activeDuration: '',
  currencyId: '',
  addressData: {},
  coinreqResponse: {},
  withdrawalLoader: true,
  withdrawalResponse: {},
  avlBalance: '',
  currency: '',
  withdrawalLimitLoader: true,
  withdrawalLimitResponse: {},
  withdrawDepositInputText: '',
  searchData: [],
  coinMarketPriceData: [],
  searchValue: '',
  sortbyFav: false,
  limit: 20
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_WITHDRAW_HISTORY_REQUESTED:
      return {
        ...state,
        depositWithdrawLoader: true
      };
    case DEPOSIT_WITHDRAW_HISTORY_SUCCESS:
      return {
        ...state,
        depositWithdrawLoader: false,
        getDepositWithdrawList: action.response
      };
    case DEPOSIT_WITHDRAW_FORM_VIEW:
      return {
        ...state,
        depositFormView: action.depositFormView,
        withdrawFormView: action.withdrawFormView,
        currencyId: action.currencyId,
        avlBalance: action.avlBalance,
        currency: action.currency
      };
    case TRANSFER_HISTORY_REQUESTED:
      return {
        ...state,
        transferHistoryLoader: true
      };
    case TRANSFER_HISTORY_SUCCESS:
      return {
        ...state,
        transferHistoryLoader: false,
        getTransferHistoryList: action.response
      };
    case TRANSFER_FORM_VIEW:
      return {
        ...state,
        transferFormView: action.transferFormView
      };
    case AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        autosuggestCurrencyId: action.autosuggestCurrencyId
      };
    case TRANSACTION_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case TRADE_ACTIVE_DURATION_UPDATE:
      return {
        ...state,
        activeDuration: action.activeDuration
      };
    case WITHDRAW_HISTORY_REQUESTED:
      return {
        ...state,
        withdrawHistoryLoader: true
      };
    case WITHDRAW_HISTORY_SUCCESS:
      return {
        ...state,
        withdrawHistoryLoader: false,
        getWithDrawHistoryList: action.response
      };
    case ADDRESS_GENERATION_REQUESTED:
      return {
        ...state,
        addressLoader: true
      };
    case ADDRESS_GENERATION_IN_PROGRESSS:
      return {
        ...state,
        message: 'Loading...',
        addressLoader: false,
        addressData: action.response,
        currencyId: action.currencyId
      };
    case ADDRESS_GENERATION_SUCCESS:
      return {
        ...state,
        addressLoader: false,
        addressData: action.response,
        currencyId: action.currencyId
      };
    case SELECTED_CURRENCY_UPDATE:
      return {
        ...state,
        selectedCurrency: action.selectedCurrency
      };
    case COINS_REQUESTED:
      return {
        ...state,
        coinLoader: true
      };
    case COIN_REQUEST_SUCCESS:
      return {
        ...state,
        coinLoader: false,
        coinreqResponse: action.response
      };
    case WITHDRAWAL_REQUESTED:
      return {
        ...state,
        withdrawalLoader: true
      };
    case WITHDRAWAL_SUCCESS:
      return {
        ...state,
        withdrawalLoader: false,
        withdrawalResponse: action.response
      };
    case WITHDRAW_LIMIT_REQUESTED:
      return {
        ...state,
        withdrawalLimitLoader: true
      };
    case WITHDRAW_LIMIT_SUCCESS:
      return {
        ...state,
        withdrawalLimitLoader: false,
        withdrawalLimitResponse: action.response
      };
    case WITHDRAW_DEPOSIT_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        withdrawAutosuggestCurrencyId: action.withdrawAutosuggestCurrencyId
      };
    case WITHDRAW_DEPOSIT_INPUT_TEXT:
      return {
        ...state,
        withdrawDepositInputText: action.withdrawDepositInputText
      };
    case HISTORY_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        historyAutosuggestCurrencyId: action.historyAutosuggestCurrencyId
      };
    case HISTORY_INPUT_TEXT:
      return {
        ...state,
        historyDepositInputText: action.historyDepositInputText
      };
    case WITHDRAW_DEPOSIT_AUTO_SUGGEST_VALUE_UPDATE:
      return {
        ...state,
        withdrawAutosuggestValue: action.withdrawAutosuggestValue
      };
    case DEPOSIT_WITHDRAW_HIDE_ZERO_BALANCES:
      return {
        ...state,
        hideZero: action.hideZero
      };
    case SEARCH_DATA_UPDATE:
      return {
        ...state,
        searchData: action.searchData
      };
    case COUNT_UPDATE:
      return {
        ...state,
        count: action.count
      };
    case DATE_FILTER_UPDATE:
      return {
        ...state,
        dateTo: action.dateTo,
        dateFrom: action.dateFrom
      };
    case COIN_MARKET_PRICE:
      return {
        ...state,
        coinMarketPriceData: action.response
      };
    case DW_SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case SORT_BY_FAV_UPDATE:
      return {
        ...state,
        sortbyFav: action.sortbyFav
      };
    case WITH_DEPOSIT_HISTORY_LIMIT_UPDATE:
      return {
        ...state,
        limit: action.limit
      };
    default:
      return state;
  }
};

export const getDepositWithdraw = formData => dispatch => {
  dispatch({
    type: DEPOSIT_WITHDRAW_HISTORY_REQUESTED
  });
  apiCaller(`user/wallet/combinedbalance/`, 'get', '', false, true, false).then(
    response => {
      let index;
      if (response.error === 0) {
        response.data.map((obj, i) => {
          if (obj.coinsymbol === 'INR') {
            index = i;
            return;
          }
        });
        const newArray = _.without(response.data, response.data[index]);
        newArray.unshift(response.data[index]);
        response.data = newArray;
      }
      dispatch({
        type: DEPOSIT_WITHDRAW_HISTORY_SUCCESS,
        response
      });
    }
  );
};

export const depositWithdrawFormView = formData => dispatch => {
  dispatch({
    type: DEPOSIT_WITHDRAW_FORM_VIEW,
    depositFormView: formData.depositFormView,
    withdrawFormView: formData.withdrawFormView,
    currencyId: formData.currencyId,
    avlBalance: formData.avlBalance,
    currency: formData.currency
  });
};

export const getTransferHistory = formData => dispatch => {
  dispatch({
    type: TRANSFER_HISTORY_REQUESTED
  });
  // apiCaller(`liveorders/1/1`, 'get', '', false, true, false).then(
  apiCaller(`user/wallet/history/all`, 'get', '', false, true, false).then(
    response => {
      dispatch({
        type: TRANSFER_HISTORY_SUCCESS,
        response
      });
    }
  );
};

export const getAddress = formData => dispatch => {
  dispatch({
    type: ADDRESS_GENERATION_REQUESTED
  });
  // apiCaller(`liveorders/1/1`, 'get', '', false, true, false).then(

  var url = `user/address/fetch/` + `${formData.currencyId}`;
  apiCaller(url, 'get', '', false, true, false).then(response => {
    if ('address' in response) {
      dispatch({
        type: ADDRESS_GENERATION_SUCCESS,
        response,
        currencyId: formData.currencyId
      });
    } else {
      dispatch({
        type: ADDRESS_GENERATION_IN_PROGRESSS,
        response,
        currencyId: formData.currencyId
      });
    }
  });
};

export const sendRequestForCoins = formData => dispatch => {
  dispatch({
    type: COINS_REQUESTED
  });
  apiCaller('mail/requestCoin', 'post', formData, false, true, false).then(
    response => {
      dispatch({
        type: COIN_REQUEST_SUCCESS,
        response
      });
    }
  );
};

export const activeDurationUpdate = formData => dispatch => {
  dispatch({
    type: TRADE_ACTIVE_DURATION_UPDATE,
    activeDuration: formData
  });
};

export const transferFormView = formData => dispatch => {
  dispatch({
    type: DEPOSIT_WITHDRAW_FORM_VIEW,
    transferFormView: formData.transferFormView
  });
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: TRANSACTION_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};

export const initiateWithdraw = formData => dispatch => {
  dispatch({
    type: WITHDRAWAL_REQUESTED
  });
  apiCaller(`balancesync/withdraw`, 'post', formData, false, true, false).then(
    response => {
      dispatch({
        type: WITHDRAWAL_SUCCESS,
        response
      });
    }
  );
};

export const getWithdrawLimit = formData => dispatch => {
  dispatch({
    type: WITHDRAW_LIMIT_REQUESTED
  });
  apiCaller(
    `user/withdraw/limit/` + `${formData.currency}`,
    'get',
    '',
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: WITHDRAW_LIMIT_SUCCESS,
      response
    });
  });
};

export const getWithDrawHistory = formData => dispatch => {
  dispatch({
    type: WITHDRAW_HISTORY_REQUESTED
  });

  apiCaller(
    'user/transaction/history/',
    'post',
    formData,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: WITHDRAW_HISTORY_SUCCESS,
      response
    });
  });
};

export const autosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: AUTO_SUGGEST_COIN_ID_UPDATE,
    autosuggestCurrencyId: formData.autosuggestCurrencyId
  });
};

export const selectedCurrencyUpdate = formData => dispatch => {
  dispatch({
    type: SELECTED_CURRENCY_UPDATE,
    selectedCurrency: formData.selectedCurrency
  });
};

export const withdrawAutosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: WITHDRAW_DEPOSIT_AUTO_SUGGEST_COIN_ID_UPDATE,
    withdrawAutosuggestCurrencyId: formData.withdrawAutosuggestCurrencyId
  });
};

export const inputTextAllCoinsFunc = formData => dispatch => {
  dispatch({
    type: WITHDRAW_DEPOSIT_INPUT_TEXT,
    withdrawDepositInputText: formData.withdrawDepositInputText
  });
};

export const withdrawAutosuggestValueUpdate = formData => dispatch => {
  dispatch({
    type: WITHDRAW_DEPOSIT_AUTO_SUGGEST_VALUE_UPDATE,
    withdrawAutosuggestValue: formData.withdrawAutosuggestValue
  });
};

export const hideZeroBalances = formData => dispatch => {
  dispatch({
    type: DEPOSIT_WITHDRAW_HIDE_ZERO_BALANCES,
    hideZero: formData.hideZero
  });
};

export const historyAutosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: HISTORY_AUTO_SUGGEST_COIN_ID_UPDATE,
    historyAutosuggestCurrencyId: formData.historyAutosuggestCurrencyId
  });
};

export const historyInputTextFunc = formData => dispatch => {
  dispatch({
    type: HISTORY_INPUT_TEXT,
    historyDepositInputText: formData.historyDepositInputText
  });
};

export const setData = formData => dispatch => {
  dispatch({
    type: SEARCH_DATA_UPDATE,
    searchData: formData.searchData
  });
};
export const setCount = formData => dispatch => {
  dispatch({
    type: COUNT_UPDATE,
    count: formData.count
  });
};

export const setDates = formData => dispatch => {
  dispatch({
    type: COUNT_UPDATE,
    dateTo: formData.dateTo,
    dateFrom: formData.dateFrom
  });
};

export const coinMarketPrice = formData => dispatch => {
  apiCaller(
    `list/price/${formData.marketId}/${formData.coinId}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response => {
    dispatch({
      type: COIN_MARKET_PRICE,
      response
    });
  });
};

export const searchValueUpdate = formData => dispatch => {
  dispatch({
    type: DW_SEARCH_VALUE_UPDATE,
    searchValue: formData.searchValue
  });
};

export const sortByFavUpdate = formData => dispatch => {
  dispatch({
    type: SORT_BY_FAV_UPDATE,
    sortbyFav: formData.sortbyFav
  });
};

export const limitUpdate = formData => dispatch => {
  dispatch({
    type: WITH_DEPOSIT_HISTORY_LIMIT_UPDATE,
    limit: formData.limit
  });
};
