import apiCaller from 'Utils/apiCallerSignin';

const UPDATE_PASSWORD_RESPONSE = 'UPDATE_PASSWORD_RESPONSE';

const initialState = {
  updatePasswordResponse: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_RESPONSE:
      return {
        ...state,
        updatePasswordResponse: action.response
      };
    default:
      return state;
  }
};

export const updatePassword = formData => dispatch => {
  apiCaller('updatepassword', 'post', formData, false, false, false).then(
    response =>
      dispatch({
        type: UPDATE_PASSWORD_RESPONSE,
        response
      })
  );
};
