/**
 * Main AllMarket Component
 * Component
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { searchValueUpdate, coinStaticContentAPI } from './marketViewReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';
import SVG from 'react-inlinesvg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'reactstrap';

class SideBarCoinContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggleTooltip1 = this.toggleTooltip1.bind(this);
    this.toggleTooltip2 = this.toggleTooltip2.bind(this);
    this.state = {
      coinForCoinInfo: this.props.coinForCoinInfo,
      coinimageForCoinInfo: this.props.coinimageForCoinInfo,
      coinStaticContentData: this.props.coinStaticContentData,
      showCoinInfo: false,
      marketView: false,
      copied: false,
      toolTip1: false,
      toolTip2: false,
      tooltip1Text: 'Click to Copy',
      tooltip2Text: 'Click to Copy'
    };
  }

  toggleTooltip1() {
    this.setState({
      toolTip1: !this.state.toolTip1
    });
  }

  toggleTooltip2() {
    this.setState({
      toolTip2: !this.state.toolTip2
    });
  }
  componentDidMount() {
    const { coinForCoinInfo } = this.state;
    if (coinForCoinInfo) {
      this.props.coinStaticContentAPI({ currency: coinForCoinInfo });
    }

    // if (this.props.isLoggedIn !== true) {
    //   this.props.changePage('/login');
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showCoinInfo !== nextProps.showCoinInfo) {
      this.setState({ showCoinInfo: nextProps.showCoinInfo });
    }

    if (this.props.coinStaticContentData !== nextProps.coinStaticContentData) {
      this.setState({ coinStaticContentData: nextProps.coinStaticContentData });
    }

    // if (this.props.coinimageForCoinInfo !== nextProps.coinimageForCoinInfo) {
    //   this.setState({ coinimageForCoinInfo: nextProps.coinimageForCoinInfo });
    // }

    if (this.props.coinForCoinInfo !== nextProps.coinForCoinInfo) {
      this.setState({ coinForCoinInfo: nextProps.coinForCoinInfo });
      this.props.coinStaticContentAPI({
        currency: nextProps.coinForCoinInfo
      });
      this.setState({
        toolTip1: false,
        toolTip2: false,
        tooltip1Text: 'Click to Copy',
        tooltip2Text: 'Click to Copy'
      });
    }

    if (this.props.coinimageForCoinInfo !== nextProps.coinimageForCoinInfo) {
      if (nextProps.coinimageForCoinInfo !== null) {
        this.setState({ coinimageForCoinInfo: nextProps.coinimageForCoinInfo });
      }
    }
  }

  handleChange(event) {
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  render() {
    const {
      marketView,
      coinStaticContentData,
      coinimageForCoinInfo
    } = this.state;

    if (
      coinStaticContentData &&
      coinStaticContentData.data &&
      coinStaticContentData.data.length > 0
    ) {
      const data = coinStaticContentData.data[0];
      const urlFB = data['FaceBook'];
      const urlTW = data['Twitter'];
      const urlLiIn = data['LinkedIn'];
      const urlGh = data['GitHub'];
      const urlReddit = data['Reddit'];
      const urlYoutube = data['YouTube'];
      const urlStEx = data['StackExchange'];
      const urlTG = data['Telegram'];
      const urlEmail = data['Email'];
      const urlWebsite = data['Website'];

      return (
        <div className="col-lg-12">
          <div className="currency-info">
            <h1 className="title pt-2 pb-2">
              {`${data.Currency} `}
              <span className="currency">{`${data.Symbol} `}</span>
            </h1>
            <SVG src={coinimageForCoinInfo} className="svg-150" />
          </div>
          <div className="sidebar-form pl-3 pr-3">
            <div className="row">
              <div className="col-md-6 text-left">
                <div className="row">
                  <div className="col-md-12">
                    <label>Founder</label>
                  </div>
                  <div className="col-md-12">
                    {`${data['Founder/ \r\nFounding team'] || ''}`}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 text-right">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <label>Block time</label>
                  </div>
                  <div className="col-md-12">{`${data['Block time'] ||
                    ''}`}</div>
                </div>
              </div> */}
            </div>
            <div className="row no-gutters">
              <ul className="coin-feed">
                <li className="mt-4">
                  <h2 className="title">About</h2>
                  <p>{`${data['About'] || ''}`}</p>
                </li>
                {/* <li className="mt-4">
                  <h2 className="title">Status</h2>
                  <p>
                    {`${data['Status'] || 'In progres'}`} */}
                {/* Bitcoin is the world's first cryptocurrency, a form of electronic cash. It is the first decentralized digital currency: the system works without a central bank or single administrator.Bitcoin is the world's first cryptocurrency, a form of electronic cash. It is the first decentralized digital currency: the system works  */}
                {/* </p>
                </li> */}
                {urlWebsite != undefined ? (
                  <li className="mt-4">
                    <h2 className="title">Website</h2>
                    <a
                      href={`${urlWebsite}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      {urlWebsite}
                    </a>
                  </li>
                ) : (
                  <></>
                )}

                {urlEmail != undefined ? (
                  <li className="mt-4">
                    <h2 className="title">E-mail</h2>
                    <a target="_blank" rel="noopener noreferrer">
                      {' '}
                      {urlEmail} {}
                      <CopyToClipboard
                        text={urlEmail}
                        onCopy={() =>
                          this.setState({
                            copied: true,
                            tooltip2Text: 'Copied'
                          })
                        }
                      >
                        <i
                          className="fa fa-clipboard crsrPntr"
                          aria-hidden="true"
                          id="tooltip2"
                        />
                      </CopyToClipboard>
                      <Tooltip
                        placement="top"
                        isOpen={this.state.toolTip2}
                        target="tooltip2"
                        autohide={false}
                        toggle={this.toggleTooltip2}
                      >
                        {this.state.tooltip2Text}
                      </Tooltip>
                    </a>
                  </li>
                ) : (
                  <></>
                )}
                {urlFB == undefined &&
                urlTW == undefined &&
                urlLiIn == undefined &&
                urlTG == undefined &&
                urlGh == undefined &&
                urlReddit == undefined &&
                urlYoutube == undefined &&
                urlStEx == undefined ? (
                  <></>
                ) : (
                  <li className="mt-4">
                    <h2 className="title">Social</h2>
                    <div className="social-icons text-center">
                      {urlFB != undefined ? (
                        <a
                          href={`${urlFB}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook-f" />
                        </a>
                      ) : (
                        <></>
                      )}

                      {urlTW != undefined ? (
                        <a
                          href={`${urlTW}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-twitter" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {urlLiIn != undefined ? (
                        <a
                          href={`${urlLiIn}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-linkedin" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {urlTG != undefined ? (
                        <a
                          href={`${urlTG}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-telegram" />
                        </a>
                      ) : (
                        <></>
                      )}
                      {urlGh != undefined ? (
                        <a
                          href={`${urlGh}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-github" />
                        </a>
                      ) : (
                        <></>
                      )}

                      {urlReddit != undefined ? (
                        <a
                          href={`${urlReddit}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-reddit" />
                        </a>
                      ) : (
                        <></>
                      )}

                      {urlYoutube != undefined ? (
                        <a
                          href={`${urlYoutube}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-youtube" />
                        </a>
                      ) : (
                        <></>
                      )}

                      {urlStEx != undefined ? (
                        <a
                          href={`${urlStEx}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-stack-exchange" />
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      searchValueUpdate: formData => searchValueUpdate(formData),
      coinStaticContentAPI: formData => coinStaticContentAPI(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    coinForCoinInfo: state.marketView.coinForCoinInfo,
    coinimageForCoinInfo: state.marketView.coinimageForCoinInfo,
    searchValue: state.marketView.searchValue,
    showCoinInfo: state.marketView.showCoinInfo,
    coinStaticContentData: state.marketView.coinStaticContentData,
    currency: state.app.activeCurrency,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile
  }),
  mapDispatchToProps
)(SideBarCoinContent);
