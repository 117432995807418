/**
 * Main Dashboard Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TradingViewChart from './components/TradingViewChart';
import MarketDepthChart from './components/highStockChart/marketDepth/MarketDepth';

import LimitForms from './components/limitForm/limit';
import MarketForms from './components/marketForm/market';
import StopLimitForms from './components/stopLimitForm/stopLimit';

import MyOrderHistory from './components/myOrders/myOrderHistory';
import MyOpenOrders from './components/myOrders/myOpenOrders';
import MyOrderHistoryBalance from './components/myOrders/myOrderHistoryBalance';
import MyBalance from './components/myOrders/myBalance';

import AllCoins from './components/allCoins/allCoins';
import RecentTrades from './components/recentTrades/recentTrades';
import LiveOrders from './components/liveOrders/liveOrders';

import stream from './components/TradingViewChart/api/stream';

import {
  formActiveTabUpdate,
  balanceActiveTabUpdate,
  chartActiveTabUpdate,
  activeMarketCoin24HrPrice,
  socketFetchingFunc,
  changeUIHeightFunc
} from './dashboardReducer';
import {
  headerActiveMenuUpdate,
  marketCurrencyUpdate,
  activeMarketValueChange,
  activeCurrencyValueChange
} from 'App/appReducer';
import {
  updateMyOpenOrders,
  updateMyOrderHistory,
  getMyBalance
} from './components/myOrders/myOrdersReducer';
import { updateRecentTrades } from './components/recentTrades/recentTradesReducer';
import {
  updateSellOrders,
  updateBuyOrders
} from './components/liveOrders/liveOrdersReducer';
import {
  buyLimitInputRemotelyChange,
  sellLimitInputRemotelyChange
} from './components/limitForm/limitReducer';
import {
  buyMarketInputRemotelyChange,
  sellMarketInputRemotelyChange
} from './components/marketForm/marketReducer';
import {
  buyStopLimitInputRemotelyChange,
  sellStopLimitInputRemotelyChange
} from './components/stopLimitForm/stopLimitReducer';

import { TwitterTimelineEmbed } from 'react-twitter-embed';

import ErrorBoundary from 'Modules/ErrorBoundary';
import io from 'socket.io-client';
import _ from 'underscore';

import apiUrls from 'Utils/apiUrls';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const socketUrl =
  process.env.REACT_APP_SOCKET_URL || apiUrls.apiUrlsObj().socketApiUrl;

const socket = io(socketUrl);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.myLimitFormRef = React.createRef();
    this.myOpenOrderElement = React.createRef();

    this.toggle = this.toggle.bind(this);
    this.recentTradeToggle = this.recentTradeToggle.bind(this);
    this.formToggle = this.formToggle.bind(this);
    // this.changeDynamicHeight = this.changeDynamicHeight.bind(this);
    this.state = {
      activeTab: '2',
      recentTradeActiveTab: '2',
      marketsCoinsListObj: {},
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      activeMarketValue: this.props.activeMarketValue,
      activeMarketSymbol: this.props.activeMarketSymbol,
      activeCurrencyValue: this.props.activeCurrencyValue,
      activeCurrencySymbol: this.props.activeCurrencySymbol,
      analysis_tab_scroll_height: this.props.analysis_tab_scroll_height,
      isLoggedIn: this.props.isLoggedIn,
      coinSymbolsObj: {},
      coinIdtoSymbolsObj: this.props.coinIdtoSymbolsObj,
      coinSymbolstoIdObj: this.props.coinSymbolstoIdObj,
      getMyBalanceList: this.props.getMyBalanceList,
      activeMarketCoin24HrPriceData: this.props.activeMarketCoin24HrPriceData,
      activeCurrencyChecker: true,
      tv_graph_height: this.props.tv_graph_height,
      selectedOrderId: this.props.selectedOrderId,
      socketFetching: this.props.socketFetching,
      recentTradeList: this.props.getRecentTradeList.data,
      sidebar_main_height: this.props.sidebar_main_height,
      news_feed_height: this.props.news_feed_height,
      orderListLoaded: false,
      orderFee: '',
      nextSocketMessage: {}
    };
  }

  scrollToBottom = analysis_tab_scroll_height => {
    const height =
      analysis_tab_scroll_height || this.state.analysis_tab_scroll_height;
    const { selectedOrderId, orderListLoaded } = this.state;
    if (
      selectedOrderId.length &&
      this.myOpenOrderElement.current &&
      height &&
      orderListLoaded
    ) {
      window.scrollTo(0, height);
      this.setState({ orderListLoaded: false });
      // this.myOpenOrderElement.current.scrollIntoView({ behavior: 'smooth' });
      // setTimeout(() => {
      //   this.myOpenOrderElement.current = undefined;
      // }, 1000);
    }
  };

  componentDidUpdate() {
    //console.log('process.env.NODE_ENV - ', process.env.NODE_ENV);
    this.scrollToBottom();
  }

  componentDidMount() {
    document.body.id = 'dashboard-page';
    this.checkActiveMarketCoin24HrPriceData();
    this.scrollToBottom();
    this.props.headerActiveMenuUpdate('exchange');

    window.addEventListener('resize', () =>
      this.resize(this.props.limitFormHeight)
    );
    this.setState({
      orderFee: this.props.orderFee,
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      coinSymbolsObj: this.props.coinSymbolsObj
    });

    this.checkMarketCurrency();

    if (this.props.isLoggedIn) {
      this.props.getMyBalance({});
    }
    // this.setState({
    //   activeMarketValue: this.props.activeMarketValue,
    //   activeCurrencyValue: this.props.activeCurrencyValue,
    //   coinSymbolsObj: this.props.coinSymbolsObj,
    //   coinIdtoSymbolsObj: this.props.coinIdtoSymbolsObj,
    //   coinSymbolstoIdObj: this.props.coinSymbolstoIdObj
    // });

    setTimeout(() => {
      // if (
      //   this.state.activeMarketValue &&
      //   this.props.activeMarketValue &&
      //   this.state.activeMarketValue !== this.props.activeMarketValue
      // ) {
      //   var activeMarketValue = this.props.activeMarketValue;
      //   this.setState({ activeMarketValue: this.props.activeMarketValue });
      // } else {
      //   var activeMarketValue = this.state.activeMarketValue;
      // }

      // if (
      //   this.state.activeCurrencyValue &&
      //   this.props.activeCurrencyValue &&
      //   this.state.activeCurrencyValue !== this.props.activeCurrencyValue
      // ) {
      //   var activeCurrencyValue = this.props.activeCurrencyValue;
      //   this.setState({ activeCurrencyValue: this.props.activeCurrencyValue });
      // } else {
      //   var activeCurrencyValue = this.state.activeCurrencyValue;
      // }

      if (this.props.activeMarketValue && this.props.activeCurrencyValue) {
        this.props.activeMarketCoin24HrPrice({
          market: this.props.activeMarketValue,
          currency: this.props.activeCurrencyValue
        });
      }
    }, 100);

    this.socketWorkFunc();
  }

  checkMarketCurrency() {
    if (
      this.props.match.params.market &&
      this.props.match.params.currency &&
      _.size(this.state.coinSymbolstoIdObj) > 0
    ) {
      if (
        this.props.match.params.market.toUpperCase() in
          this.state.coinSymbolstoIdObj &&
        this.props.match.params.currency.toUpperCase() in
          this.state.coinSymbolstoIdObj
      ) {
        var activeMarketValue = this.state.coinSymbolstoIdObj[
          this.props.match.params.market.toUpperCase()
        ].coinId;
        var activeCurrencyValue = this.state.coinSymbolstoIdObj[
          this.props.match.params.currency.toUpperCase()
        ].coinId;
        cookies.set('activeMarketValue', activeMarketValue);
        cookies.set('activeCurrencyValue', activeCurrencyValue);
        cookies.set(
          'activeMarketSymbol',
          this.props.match.params.market.toUpperCase()
        );
        cookies.set(
          'activeCurrencySymbol',
          this.props.match.params.currency.toUpperCase()
        );
        this.setState(
          {
            activeMarketValue,
            activeCurrencyValue,
            activeMarketSymbol: this.props.match.params.market.toUpperCase(),
            activeCurrencySymbol: this.props.match.params.currency.toUpperCase(),
            activeCurrencyChecker: false
          },
          this.props.marketCurrencyUpdate({
            activeMarketValue,
            activeMarketSymbol: this.props.match.params.market.toUpperCase(),
            activeCurrencyValue,
            activeCurrencySymbol: this.props.match.params.currency.toUpperCase()
          })
        );
        this.props.activeMarketCoin24HrPrice({
          market: activeMarketValue,
          currency: activeCurrencyValue
        });
      } else {
        this.props.headerActiveMenuUpdate('');
        this.props.changePage('/404');
      }
    } else if (
      this.props.activeMarketValue >= 0 &&
      this.props.activeCurrencyValue >= 0 &&
      _.size(this.state.coinSymbolstoIdObj) > 0
    ) {
      cookies.set('activeMarketValue', this.props.activeMarketValue);
      cookies.set('activeCurrencyValue', this.props.activeCurrencyValue);
      this.setState({
        activeMarketValue: this.props.activeMarketValue,
        activeCurrencyValue: this.props.activeCurrencyValue,
        activeMarketSymbol: this.state.coinSymbolstoIdObj[
          this.props.activeMarketValue
        ].coinsymbol,
        activeCurrencySymbol: this.state.coinSymbolstoIdObj[
          this.props.activeCurrencyValue
        ].coinsymbol,
        activeCurrencyChecker: false
      });
      this.props.activeMarketCoin24HrPrice({
        market: this.props.activeMarketValue,
        currency: this.props.activeCurrencyValue
      });
    } else {
      this.setState({
        activeCurrencyChecker: true
      });
    }
  }

  socketWorkFunc() {
    setTimeout(() => {
      if (!this.state.socketFetching) {
        socket.on('broadcast', data => {
          this.setState({
            socketFetching: true
          });
          this.props.socketFetchingFunc({
            socketFetching: true
          });

          const { activeCurrencySymbol, activeMarketSymbol } = this.state;
          // console.log(
          //   'this.state.socketFetching - ',
          //   this.state.socketFetching
          // );

          if (
            data.message.marketId == cookies.get('activeMarketValue') &&
            data.message.coinId == cookies.get('activeCurrencyValue')
          ) {
            this.setState({ nextSocketMessage: data.message });
            if (data.message.status === 'new') {
              if (data.message.operation === 0) {
                this.props.updateSellOrders({
                  sellOrderUpdateObject: data.message,
                  action: 'sum'
                });
              }
              if (data.message.operation === 1) {
                this.props.updateBuyOrders({
                  buyOrderUpdateObject: data.message,
                  action: 'sum'
                });
              }
            } else if (data.message.status === 'executed') {
              stream.socketDataFunc(
                data.message,
                activeCurrencySymbol,
                activeMarketSymbol
              );
              this.props.updateRecentTrades({
                recentTradeUpdateObject: data.message
              });
              this.updateActiveMarketCoinPriceFunc(data.message.price);
              if (data.message.operation === 0) {
                this.props.updateBuyOrders({
                  buyOrderUpdateObject: data.message,
                  action: 'substract'
                });
              }
              if (data.message.operation === 1) {
                this.props.updateSellOrders({
                  sellOrderUpdateObject: data.message,
                  action: 'substract'
                });
              }
            } else if (data.message.status === 'cancelled') {
              if (data.message.operation === 0) {
                this.props.updateSellOrders({
                  sellOrderUpdateObject: data.message,
                  action: 'substract'
                });
              }
              if (data.message.operation === 1) {
                this.props.updateBuyOrders({
                  buyOrderUpdateObject: data.message,
                  action: 'substract'
                });
              }
            }
          } else {
            // console.log('abcdefgh');
          }
        });
      }
    }, 1000);
  }

  resize(limitFormHeight) {
    setTimeout(() => {
      this.changeDynamicHeight(limitFormHeight);
    }, 100);
  }

  componentWillUnmount() {
    // socket.close();
    document.title = 'OrderBook Exchange';
    document.body.id = undefined;
    window.removeEventListener('resize', this.resize);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  recentTradeToggle(tab) {
    if (this.state.recentTradeActiveTab !== tab) {
      this.setState({
        recentTradeActiveTab: tab
      });
    }
  }

  formToggle(tab) {
    if (this.props.formActiveTab !== tab) {
      this.props.formActiveTabUpdate(tab);
    }
  }

  balanceToggle(tab) {
    if (this.props.balanceActiveTab !== tab) {
      this.props.balanceActiveTabUpdate(tab);
    }
  }

  chartToggle(tab) {
    if (this.props.chartActiveTab !== tab) {
      this.props.chartActiveTabUpdate(tab);
    }
  }

  changeDynamicHeight(limitFormHeight) {
    const full_height = window.innerHeight - 60;
    const dashboard_main_height = full_height - 30;
    const sidebar_main_height = full_height - 20;
    const openOrdersFirstTabDataLimit = parseInt(
      (dashboard_main_height * 40) / 100 / 24.9
    );
    // const openOrdersFirstTabDataLimit = parseInt((full_height * 40) / 100 / 24.9 + 1);
    const calculator_height = limitFormHeight;
    const market_information_height = calculator_height;
    if (this.state.news_feed_height === 0) {
      var news_feed_height = window.innerHeight - 90;
    } else {
      var news_feed_height = this.state.news_feed_height;
    }
    const data_table_height = (parseInt(news_feed_height / 24.9) + 0) * 24.9;
    const analysis_tab_scroll_height = full_height - calculator_height;
    const graph_box_height = full_height - calculator_height - 110;
    var tv_graph_height = graph_box_height - 14;

    const full_width = window.outerWidth;
    if (full_width <= 768) {
      tv_graph_height = '45vh';
    }

    this.scrollToBottom(analysis_tab_scroll_height);

    this.props.changeUIHeightFunc({
      full_height,
      dashboard_main_height,
      sidebar_main_height,
      market_information_height,
      data_table_height,
      analysis_tab_scroll_height,
      news_feed_height,
      graph_box_height,
      tv_graph_height,
      openOrdersFirstTabDataLimit
    });

    this.setState({
      full_height,
      dashboard_main_height,
      sidebar_main_height,
      market_information_height,
      data_table_height,
      analysis_tab_scroll_height,
      news_feed_height,
      graph_box_height,
      tv_graph_height
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
      if (nextProps.isLoggedIn) {
        this.props.getMyBalance({});
      }
    }

    if (this.props.orderFee !== nextProps.orderFee) {
      this.setState({ orderFee: nextProps.orderFee });
    }

    if (this.props.limitFormHeight !== nextProps.limitFormHeight) {
      const limitFormHeight = nextProps.limitFormHeight;
      this.changeDynamicHeight(limitFormHeight);
    }

    if (this.props.getMyOpenOrdersList !== nextProps.getMyOpenOrdersList) {
      if (nextProps.getMyOpenOrdersList.error === 0) {
        this.setState({ orderListLoaded: true });
      }
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.coinIdtoSymbolsObj !== nextProps.coinIdtoSymbolsObj) {
      this.setState({ coinIdtoSymbolsObj: nextProps.coinIdtoSymbolsObj });
    }

    if (this.props.coinSymbolstoIdObj !== nextProps.coinSymbolstoIdObj) {
      this.setState(
        { coinSymbolstoIdObj: nextProps.coinSymbolstoIdObj },
        this.checkMarketCurrency
      );
    }

    if (this.props.selectedOrderId !== nextProps.selectedOrderId) {
      this.setState({ selectedOrderId: nextProps.selectedOrderId });
    }

    if (this.props.socketFetching !== nextProps.socketFetching) {
      this.setState({ socketFetching: nextProps.socketFetching });
    }

    if (this.props.getRecentTradeList !== nextProps.getRecentTradeList) {
      if (nextProps.getRecentTradeList.error === 0) {
        this.setState({
          recentTradeList: nextProps.getRecentTradeList.data,
          recentTradeScrollLoader: false,
          recentTradeError: null
        });
        this.lastPriceFunc(
          nextProps.getRecentTradeList.data[0] &&
            nextProps.getRecentTradeList.data[0].executionPrice
        );
      } else {
        this.setState({
          recentTradeList: null,
          recentTradeError: nextProps.getRecentTradeList.message,
          recentTradeScrollLoader: false
        });
      }
    }

    // if (
    //   nextProps.match.params.market &&
    //   this.props.match.params.market !== nextProps.match.params.market
    // ) {
    //   if (
    //     this.state.coinSymbolstoIdObj[
    //       nextProps.match.params.market.toUpperCase()
    //     ]
    //   ) {
    //     var activeMarketValue = this.state.coinSymbolstoIdObj[
    //       nextProps.match.params.market.toUpperCase()
    //     ];
    //     cookies.set('activeMarketValue', activeMarketValue);
    //     this.setState(
    //       {
    //         activeMarketValue,
    //         activeMarketSymbol: nextProps.match.params.market.toUpperCase()
    //         // activeCurrencyChecker: false
    //       },
    //       this.props.activeMarketValueChange({
    //         activeMarketValue,
    //         activeMarketSymbol: nextProps.match.params.market.toUpperCase()
    //       })
    //     );
    //   } else {
    //     this.props.headerActiveMenuUpdate('');
    //     this.props.changePage('/404');
    //   }
    // } else if (nextProps.match.params.market) {
    //   // this.setState({
    //   // 	activeCurrencyChecker: true
    //   // })
    // }

    // if (
    //   nextProps.match.params.currency &&
    //   this.props.match.params.currency !== nextProps.match.params.currency
    // ) {
    //   if (
    //     this.state.coinSymbolstoIdObj[
    //       nextProps.match.params.currency.toUpperCase()
    //     ]
    //   ) {
    //     var activeCurrencyValue = this.state.coinSymbolstoIdObj[
    //       nextProps.match.params.currency.toUpperCase()
    //     ];
    //     cookies.set('activeCurrencyValue', activeCurrencyValue);
    //     this.setState(
    //       {
    //         activeCurrencyValue,
    //         activeCurrencySymbol: nextProps.match.params.currency.toUpperCase()
    //         // activeCurrencyChecker: false
    //       },
    //       this.props.activeCurrencyValueChange({
    //         activeCurrencyValue,
    //         activeCurrencySymbol: nextProps.match.params.currency.toUpperCase()
    //       })
    //     );
    //   } else {
    //     this.props.headerActiveMenuUpdate('');
    //     this.props.changePage('/404');
    //   }
    // } else if (nextProps.match.params.currency) {
    //   // this.setState({
    //   // 	activeCurrencyChecker: true
    //   // })
    // }

    if (this.props.marketsCoinsListObj !== nextProps.marketsCoinsListObj) {
      this.setState({ marketsCoinsListObj: nextProps.marketsCoinsListObj });
    }

    if (this.props.coinsMarketsListObj !== nextProps.coinsMarketsListObj) {
      this.setState({ coinsMarketsListObj: nextProps.coinsMarketsListObj });
    }

    if (
      this.props.activeMarketCoin24HrPriceData !==
      nextProps.activeMarketCoin24HrPriceData
    ) {
      this.setState(
        {
          activeMarketCoin24HrPriceData: nextProps.activeMarketCoin24HrPriceData
        },
        this.checkActiveMarketCoin24HrPriceData
      );
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      this.setState({ activeMarketValue: nextProps.activeMarketValue });
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      this.setState({ activeCurrencyValue: nextProps.activeCurrencyValue });
    }

    if (this.props.activeMarketSymbol !== nextProps.activeMarketSymbol) {
      this.setState({ activeMarketSymbol: nextProps.activeMarketSymbol });
    }

    if (this.props.activeCurrencySymbol !== nextProps.activeCurrencySymbol) {
      this.setState({ activeCurrencySymbol: nextProps.activeCurrencySymbol });
    }

    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState({ myBalanceList: nextProps.getMyBalanceList });
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      var market = this.props.activeMarketValue;
      var currency = this.props.activeCurrencyValue;
      if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
        var market = nextProps.activeMarketValue;
      }
      if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
        var currency = nextProps.activeCurrencyValue;
      }
      this.props.activeMarketCoin24HrPrice({
        market,
        currency
      });

      this.props.buyLimitInputRemotelyChange({
        volumeBuy: '',
        bidBuy: '',
        totalBuy: '',
        marketBuy: market,
        currencyBuy: currency,
        operationBuy: ''
      });

      this.props.sellLimitInputRemotelyChange({
        volumeSell: '',
        bidSell: '',
        totalSell: '',
        marketSell: market,
        currencySell: currency,
        operationSell: ''
      });

      this.props.buyMarketInputRemotelyChange({
        volumeMarketBuy: ''
        // marketSell: market,
        // currencySell: currency,
        // operationSell: ''
      });

      this.props.sellMarketInputRemotelyChange({
        volumeMarketSell: ''
        // marketSell: market,
        // currencySell: currency,
        // operationSell: ''
      });

      this.props.buyStopLimitInputRemotelyChange({
        limitBuy: '',
        stopBuy: '',
        volumeStopBuy: '',
        totalStopBuy: '',
        marketBuy: market,
        currencyBuy: currency,
        operationBuy: ''
      });

      this.props.sellStopLimitInputRemotelyChange({
        stopSell: '',
        limitSell: '',
        marketSell: market,
        currencySell: currency,
        operationSell: '',
        volumeStopSell: '',
        totalStopSell: ''
      });
    }

    // if (
    //   this.props.activeMarketValue !== nextProps.activeMarketValue &&
    //   this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    // ) {
    //   setTimeout(() => {
    //     this.props.activeMarketCoin24HrPrice({
    //       market: nextProps.activeMarketValue,
    //       currency: nextProps.activeCurrencyValue
    //     });
    //   }, 100);
    // } else if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
    //   setTimeout(() => {
    //     this.props.activeMarketCoin24HrPrice({
    //       market: nextProps.activeMarketValue,
    //       currency: this.props.activeCurrencyValue
    //     });
    //   }, 100);
    // } else if (
    //   this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    // ) {
    //   setTimeout(() => {
    //     this.props.activeMarketCoin24HrPrice({
    //       market: this.props.activeMarketValue,
    //       currency: nextProps.activeCurrencyValue
    //     });
    //   }, 100);
    // }
  }

  lastPriceFunc(price) {
    var market = this.props.activeMarketSymbol;
    var currency = this.props.activeCurrencySymbol;
    const priceData = price ? `${price} |` : '';
    document.title = `${priceData} ${market}/${currency} | OrderBook Exchange`;
  }

  updateActiveMarketCoinPriceFunc(price) {
    const { activeMarketCoinPriceData } = this.state;
    if (activeMarketCoinPriceData) {
      const lastexecutedPrice = price;
      const twentyfourhourHigh =
        price > Number(activeMarketCoinPriceData.twentyfourhourHigh)
          ? price
          : activeMarketCoinPriceData.twentyfourhourHigh;
      const twentyfourhourLow =
        price < Number(activeMarketCoinPriceData.twentyfourhourLow)
          ? price
          : activeMarketCoinPriceData.twentyfourhourLow;
      this.setState({
        activeMarketCoinPriceData: {
          ...activeMarketCoinPriceData,
          lastexecutedPrice,
          twentyfourhourHigh,
          twentyfourhourLow
        }
      });
    }
  }

  checkActiveMarketCoin24HrPriceData() {
    const { activeMarketCoin24HrPriceData } = this.state;
    if (
      activeMarketCoin24HrPriceData &&
      activeMarketCoin24HrPriceData.error === 0
    ) {
      this.setState({
        activeMarketCoinPriceData: activeMarketCoin24HrPriceData.data[0]
      });
    }
  }

  marketChange(marketObj) {
    this.setState(
      {
        activeMarketValue: marketObj.marketId,
        activeMarketSymbol: marketObj.marketsymbol
      },
      this.props.activeMarketValueChange({
        activeMarketValue: marketObj.marketId,
        activeMarketSymbol: marketObj.marketsymbol
      })
    );
  }

  render() {
    const {
      isLoggedIn,
      orderFee,
      activeMarketCoinPriceData,
      activeCurrencyValue,
      activeCurrencySymbol,
      activeMarketSymbol,
      coinSymbolsObj,
      coinsMarketsListObj,
      activeCurrencyChecker
    } = this.state;

    if (activeCurrencyChecker) {
      return <div />;
    }

    if (!activeCurrencyChecker) {
      return (
        <div className="container-fluid">
          <div className="row no-gutters">
            <div
              id="dashboard-main"
              className="col-lg-7 pl-2 pr-1 mt-1"
              style={{ height: this.state.dashboard_main_height }}
            >
              <div id="market-select-menu" className="container-fluid">
                <div className="row no-gutters">
                  <div className="col-md-2 col-sm-6 market-select">
                    <ul className="nav nav-tabs mt-2 mb-2">
                      {_.size(coinsMarketsListObj) > 0 && (
                        <li className="nav-item">
                          {coinSymbolsObj &&
                            coinSymbolsObj[activeCurrencyValue] && (
                              <a className="nav-link market-coin">
                                {/* {`${
										coinSymbolsObj[activeCurrencyValue].coinname
									}(${activeCurrencySymbol})`} */}
                                {activeCurrencySymbol}
                              </a>
                            )}
                        </li>
                      )}

                      {_.size(coinsMarketsListObj) > 0 &&
                        coinsMarketsListObj[activeCurrencyValue] && (
                          <li className="nav-item dropdown show">
                            <a
                              className="nav-link dropdown-toggle crsrPntr"
                              role="button"
                              id="market-dropdown"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {activeMarketSymbol}
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              {_.keys(
                                coinsMarketsListObj[activeCurrencyValue].markets
                              ).map((o, i) => (
                                <a
                                  className="dropdown-item crsrPntr"
                                  key={i}
                                  onClick={() =>
                                    this.marketChange(
                                      coinsMarketsListObj[activeCurrencyValue]
                                        .markets[o]
                                    )
                                  }
                                >
                                  {
                                    coinsMarketsListObj[activeCurrencyValue]
                                      .markets[o].marketsymbol
                                  }
                                </a>
                              ))}
                            </div>
                          </li>
                        )}
                    </ul>
                  </div>

                  <div className="col-md-7 market-data">
                    <div className="row no-gutters dashboard-market-info">
                      <div className="col" align="left">
                        <div className="title">24H High</div>
                        {activeMarketCoinPriceData && (
                          <div className="data">
                            {activeMarketCoinPriceData.twentyfourhourHigh}{' '}
                            <span className="currency">
                              {activeMarketSymbol}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col" align="left">
                        <div className="title">24H Low</div>
                        {activeMarketCoinPriceData && (
                          <div className="data">
                            {activeMarketCoinPriceData.twentyfourhourLow}{' '}
                            <span className="currency">
                              {activeMarketSymbol}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col" align="left">
                        <div className="title">24H Volume</div>
                        {activeMarketCoinPriceData && (
                          <div className="data">
                            {parseFloat(
                              activeMarketCoinPriceData.twentyfourhourVolume
                            ).toFixed(8)}{' '}
                            <span className="currency">
                              {activeMarketSymbol}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col" align="left">
                        <div className="title">Last price</div>
                        {activeMarketCoinPriceData && (
                          <div className="data">
                            {parseFloat(
                              activeMarketCoinPriceData.lastexecutedPrice
                            ).toFixed(8)}{' '}
                            <span className="currency">
                              {activeMarketSymbol}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col" align="left">
                        <div className="title">24H Change</div>
                        {activeMarketCoinPriceData &&
                          activeMarketCoinPriceData.twentyfourhourChange && (
                            <div className="data">
                              <span
                                className={`${
                                  activeMarketCoinPriceData.twentyfourhourChange.includes(
                                    '-'
                                  )
                                    ? 'negative-text'
                                    : 'positive-text'
                                }`}
                              >
                                {activeMarketCoinPriceData.twentyfourhourChange.replace(
                                  '-',
                                  ''
                                )}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 action-switch ml-auto">
                    <ul className="nav nav-tabs mt-2 mb-2 graph-menu">
                      <li className="nav-item ml-auto">
                        <a
                          className={`crsrPntr nav-link
                            ${
                              this.props.chartActiveTab === 'tradingView'
                                ? ' active'
                                : ''
                            }`}
                          onClick={() => {
                            this.chartToggle('tradingView');
                          }}
                          active={(
                            this.props.chartActiveTab === 'tradingView'
                          ).toString()}
                          aria-selected={
                            this.props.chartActiveTab === 'tradingView'
                          }
                        >
                          Trading View
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`crsrPntr nav-link
                            ${
                              this.props.chartActiveTab === 'marketDepth'
                                ? ' active'
                                : ''
                            }`}
                          onClick={() => {
                            this.chartToggle('marketDepth');
                          }}
                          active={(
                            this.props.chartActiveTab === 'marketDepth'
                          ).toString()}
                          aria-selected={
                            this.props.chartActiveTab === 'marketDepth'
                          }
                        >
                          Depth
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div id="graph-box">
                <div className="h-100">
                  <div className="row no-gutters graph-container">
                    <div className="col-md-12">
                      {/* {this.props.chartActiveTab === 'marketDepth' && ( */}
                      {this.props.chartActiveTab === 'tradingView' && (
                        <ErrorBoundary>
                          <TradingViewChart
                            symbol={`${activeMarketSymbol}/${activeCurrencySymbol}`}
                            interval={cookies.get('selectedInterval') || '15'}
                            activeMarketValue={this.props.activeMarketValue}
                            activeCurrencyValue={this.props.activeCurrencyValue}
                            height={this.state.tv_graph_height}
                          />
                        </ErrorBoundary>
                      )}

                      {/* {this.props.chartActiveTab === 'tradingView' && ( */}
                      {this.props.chartActiveTab === 'marketDepth' && (
                        <ErrorBoundary>
                          <MarketDepthChart
                            symbol={`${activeMarketSymbol}/${activeCurrencySymbol}`}
                            activeMarketValue={this.props.activeMarketValue}
                            activeCurrencyValue={this.props.activeCurrencyValue}
                            activeMarketSymbol={this.props.activeMarketSymbol}
                            activeCurrencySymbol={
                              this.props.activeCurrencySymbol
                            }
                            height={this.state.tv_graph_height}
                            nextSocketMessage={this.state.nextSocketMessage}
                          />
                        </ErrorBoundary>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row no-gutters h-30 row-eq-height mt-1"
                ref={this.myOpenOrderElement}
              >
                <div className="col-lg-4 container-one pr-1">
                  <ul className="nav nav-switch">
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.props.balanceActiveTab === 'balance'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.balanceToggle('balance');
                        }}
                        active={(
                          this.props.balanceActiveTab === 'balance'
                        ).toString()}
                        aria-selected={
                          this.props.balanceActiveTab === 'balance'
                        }
                      >
                        Balance
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.props.balanceActiveTab === 'crypto'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.balanceToggle('crypto');
                        }}
                        active={(
                          this.props.balanceActiveTab === 'crypto'
                        ).toString()}
                        aria-selected={this.props.balanceActiveTab === 'crypto'}
                      >
                        Analysis
                        {/* {`${activeCurrencySymbol}/${activeMarketSymbol}`} */}
                      </a>
                    </li>
                  </ul>
                  {/* <!-- COIN/MARKET DATA -->
					<!-- LISK/BTC DATA --> */}

                  {isLoggedIn && this.props.balanceActiveTab === 'crypto' && (
                    <ErrorBoundary>
                      <MyOrderHistoryBalance
                        activeMarketValue={this.props.activeMarketValue}
                        activeMarketSymbol={this.props.activeMarketSymbol}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                        activeCurrencySymbol={this.props.activeCurrencySymbol}
                      />
                    </ErrorBoundary>
                  )}

                  {isLoggedIn && this.props.balanceActiveTab === 'balance' && (
                    <ErrorBoundary>
                      <MyBalance
                        activeMarketValue={this.props.activeMarketValue}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                      />
                    </ErrorBoundary>
                  )}

                  {!isLoggedIn && (
                    <div className="unlogged-action">
                      <Link to="/login" className="btn">
                        Login
                      </Link>
                      or
                      <Link to="/signup" className="btn">
                        Sign up
                      </Link>
                    </div>
                  )}
                </div>

                <div className="col-lg-8">
                  <ul className="nav nav-switch">
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.props.formActiveTab === 'limit'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.formToggle('limit');
                        }}
                        active={(
                          this.props.formActiveTab === 'limit'
                        ).toString()}
                        aria-selected={this.props.formActiveTab === 'limit'}
                      >
                        Limit
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.props.formActiveTab === 'market'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.formToggle('market');
                        }}
                        active={(
                          this.props.formActiveTab === 'market'
                        ).toString()}
                        aria-selected={this.props.formActiveTab === 'market'}
                      >
                        Market
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.props.formActiveTab === 'stopLimit'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.formToggle('stopLimit');
                        }}
                        active={(
                          this.props.formActiveTab === 'stopLimit'
                        ).toString()}
                        aria-selected={this.props.formActiveTab === 'stopLimit'}
                      >
                        Stop-loss
                      </a>
                    </li>
                    <li className="nav-item ml-auto">
                      <a className="nav-link" style={{ opacity: 0.6 }}>
                        <span className="fee">{`Fee- ${orderFee}`}</span>
                      </a>
                    </li>
                  </ul>

                  {/* <div className="row no-gutters calculator-container"> */}
                  {this.props.formActiveTab === 'limit' && (
                    // <ErrorBoundary>
                    <LimitForms
                      ref={this.myLimitFormRef}
                      activeMarketValue={this.props.activeMarketValue}
                      activeMarketSymbol={this.props.activeMarketSymbol}
                      activeCurrencyValue={this.props.activeCurrencyValue}
                      activeCurrencySymbol={this.props.activeCurrencySymbol}
                    />
                    // </ErrorBoundary>
                  )}

                  {this.props.formActiveTab === 'market' && (
                    <ErrorBoundary>
                      <MarketForms
                        activeMarketValue={this.props.activeMarketValue}
                        activeMarketSymbol={this.props.activeMarketSymbol}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                        activeCurrencySymbol={this.props.activeCurrencySymbol}
                      />
                    </ErrorBoundary>
                  )}

                  {this.props.formActiveTab === 'stopLimit' && (
                    <ErrorBoundary>
                      <StopLimitForms
                        activeMarketValue={this.props.activeMarketValue}
                        activeMarketSymbol={this.props.activeMarketSymbol}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                        activeCurrencySymbol={this.props.activeCurrencySymbol}
                      />
                    </ErrorBoundary>
                  )}

                  {/* {!isLoggedIn && (
                    <div className="unlogged-action">
                    <Link to="/login" className="btn">
                      Login
                    </Link>
                    or
                    <Link to="/login" className="btn">
                      Sign up
                    </Link>
                    </div>
										)} */}
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="col-lg-5 mt-1">
              <div
                id="sidebar-main"
                style={{
                  height: this.state.sidebar_main_height,
                  overflow: 'hidden'
                }}
                className="row no-gutters row-eq-height"
              >
                <ErrorBoundary>
                  <LiveOrders
                    profile={this.props.profile}
                    currency={this.props.currency}
                    activeMarketValue={this.props.activeMarketValue}
                    activeCurrencyValue={this.props.activeCurrencyValue}
                    activeMarketSymbol={this.props.activeMarketSymbol}
                    activeCurrencySymbol={this.props.activeCurrencySymbol}
                  />
                </ErrorBoundary>

                <div className="col-lg-6">
                  <ul className="nav nav-switch">
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.state.recentTradeActiveTab === '2'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.recentTradeToggle('2');
                        }}
                        active={(
                          this.state.recentTradeActiveTab === '2'
                        ).toString()}
                        aria-selected={this.state.recentTradeActiveTab === '2'}
                      >
                        Markets
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`crsrPntr nav-link
                          ${
                            this.state.recentTradeActiveTab === '1'
                              ? ' active'
                              : ''
                          }`}
                        onClick={() => {
                          this.recentTradeToggle('1');
                        }}
                        active={(
                          this.state.recentTradeActiveTab === '1'
                        ).toString()}
                        aria-selected={this.state.recentTradeActiveTab === '1'}
                      >
                        Recent trades
                      </a>
                    </li>
                  </ul>

                  {this.state.recentTradeActiveTab === '1' && (
                    <ErrorBoundary>
                      <RecentTrades
                        activeMarketValue={this.props.activeMarketValue}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                        activeMarketSymbol={this.props.activeMarketSymbol}
                        activeCurrencySymbol={this.props.activeCurrencySymbol}
                      />
                    </ErrorBoundary>
                  )}

                  {this.state.recentTradeActiveTab === '2' && (
                    <ErrorBoundary>
                      <AllCoins
                        activeMarketValue={this.props.activeMarketValue}
                        activeCurrencyValue={this.props.activeCurrencyValue}
                        activeMarketSymbol={this.props.activeMarketSymbol}
                        activeCurrencySymbol={this.props.activeCurrencySymbol}
                      />
                    </ErrorBoundary>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="orders" className="row no-gutters">
            <div
              className="col-md-7 pl-2 pr-1 mt-2"
              // ref={this.myOpenOrderElement}
            >
              <ul className="nav nav-switch">
                <li className="nav-item">
                  <a
                    className={`crsrPntr nav-link
											${this.state.activeTab === '2' ? ' active' : ''}`}
                    onClick={() => {
                      this.toggle('2');
                    }}
                    active={(this.state.activeTab === '2').toString()}
                    aria-selected={this.state.activeTab === '2'}
                  >
                    Open orders
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`crsrPntr nav-link
											${this.state.activeTab === '1' ? ' active' : ''}`}
                    onClick={() => {
                      this.toggle('1');
                    }}
                    active={(this.state.activeTab === '1').toString()}
                    aria-selected={this.state.activeTab === '1'}
                  >
                    My trades
                  </a>
                </li>
              </ul>

              {isLoggedIn && this.state.activeTab === '1' && (
                <ErrorBoundary>
                  <MyOrderHistory
                    activeMarketValue={this.props.activeMarketValue}
                    activeCurrencyValue={this.props.activeCurrencyValue}
                  />
                </ErrorBoundary>
              )}

              {isLoggedIn && this.state.activeTab === '2' && (
                <ErrorBoundary>
                  <MyOpenOrders
                    activeMarketValue={this.props.activeMarketValue}
                    activeMarketSymbol={this.props.activeMarketSymbol}
                    activeCurrencyValue={this.props.activeCurrencyValue}
                    activeCurrencySymbol={this.props.activeCurrencySymbol}
                  />
                </ErrorBoundary>
              )}

              {!isLoggedIn && (
                <div className="unlogged-action">
                  <Link to="/login" className="btn">
                    Login
                  </Link>
                  or
                  <Link to="/signup" className="btn">
                    Sign up
                  </Link>
                </div>
              )}
            </div>
            <div className="col-md-5 feed-box mt-2">
              <div className="row no-gutters">
                <div className="container-fluid">
                  <ul className="nav nav-switch">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        News and feed
                      </a>
                    </li>
                  </ul>
                  <ul className="news-feed" id="news-feed">
                    <TwitterTimelineEmbed
                      sourceType="list"
                      ownerScreenName=""
                      slug=""
                      url="https://twitter.com/ThroughBit/lists/public-throughbit"
                      transparent
                      noHeader
                      noFooter
                      // linkColor="#202035"
                      theme="dark"
                      options={{ height: this.state.news_feed_height - 5 }}
                    />
                    {/* <a
                      className="twitter-timeline"
                      data-width="100vh"
                      data-height={this.state.news_feed_height - 5}
                      data-theme="dark"
                      data-chrome="transparent nofooter"
                      data-link-color="#202035"
                      href="https://twitter.com/ThroughBit?ref_src=twsrc%5Etfw"
                    >
                      Tweets by ThroughBit
                    </a> */}
                  </ul>
                  {/* <ul className="news-feed" id="news-feed">
                    <li>
                      <div className="row">
                        <div className="col-md-10">
                          <h2 className="title">Bitcoin takes a leap</h2>
                        </div>
                        <div className="col-md-2 time">3 hrs</div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search...
                      </p>
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-10">
                          <h2 className="title">Top ICO 2018</h2>
                        </div>
                        <div className="col-md-2 time">3 hrs</div>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here', making it look like readable English. Many
                        desktop publishing packages and web page editors now use
                        Lorem Ipsum as their default model text, and a search...
                      </p>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div ref={this.myOpenOrderElement} ></div> */}
          {/* <!-- ^ dashboard-main ends here --> */}
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      formActiveTabUpdate: formData => formActiveTabUpdate(formData),
      balanceActiveTabUpdate: formData => balanceActiveTabUpdate(formData),
      chartActiveTabUpdate: formData => chartActiveTabUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      marketCurrencyUpdate: formData => marketCurrencyUpdate(formData),
      activeMarketValueChange: formData => activeMarketValueChange(formData),
      activeCurrencyValueChange: formData =>
        activeCurrencyValueChange(formData),
      updateMyOpenOrders: formData => updateMyOpenOrders(formData),
      updateMyOrderHistory: formData => updateMyOrderHistory(formData),
      updateSellOrders: formData => updateSellOrders(formData),
      updateBuyOrders: formData => updateBuyOrders(formData),
      updateRecentTrades: formData => updateRecentTrades(formData),
      getMyBalance: formData => getMyBalance(formData),
      activeMarketCoin24HrPrice: formData =>
        activeMarketCoin24HrPrice(formData),
      changeUIHeightFunc: formData => changeUIHeightFunc(formData),
      socketFetchingFunc: formData => socketFetchingFunc(formData),
      buyLimitInputRemotelyChange: formData =>
        buyLimitInputRemotelyChange(formData),
      sellLimitInputRemotelyChange: formData =>
        sellLimitInputRemotelyChange(formData),
      buyMarketInputRemotelyChange: formData =>
        buyMarketInputRemotelyChange(formData),
      sellMarketInputRemotelyChange: formData =>
        sellMarketInputRemotelyChange(formData),
      buyStopLimitInputRemotelyChange: formData =>
        buyStopLimitInputRemotelyChange(formData),
      sellStopLimitInputRemotelyChange: formData =>
        sellStopLimitInputRemotelyChange(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    currency: state.app.activeCurrency,
    orderFee: state.app.orderFee,
    formActiveTab: state.dashboard.formActiveTab,
    balanceActiveTab: state.dashboard.balanceActiveTab,
    chartActiveTab: state.dashboard.chartActiveTab,
    analysis_tab_scroll_height: state.dashboard.analysis_tab_scroll_height,
    getMyOpenOrdersList: state.myOrders.getMyOpenOrdersList,
    activeMarketCoin24HrPriceData:
      state.dashboard.activeMarketCoin24HrPriceData,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    coinsMarketsListObj: state.app.coinsMarketsListObj,
    coinSymbolsObj: state.app.coinSymbolsObj,
    coinIdtoSymbolsObj: state.app.coinIdtoSymbolsObj,
    coinSymbolstoIdObj: state.app.coinSymbolstoIdObj,
    activeMarketValue: state.app.activeMarketValue,
    activeCurrencyValue: state.app.activeCurrencyValue,
    activeMarketSymbol: state.app.activeMarketSymbol,
    activeCurrencySymbol: state.app.activeCurrencySymbol,
    getMyBalanceList: state.myOrders.getMyBalanceList,
    limitFormHeight: state.limitForms.limitFormHeight,
    tv_graph_height: state.dashboard.tv_graph_height,
    sidebar_main_height: state.dashboard.sidebar_main_height,
    news_feed_height: state.dashboard.news_feed_height,
    selectedOrderId: state.openOrders.selectedOrderId,
    socketFetching: state.dashboard.socketFetching
  }),
  mapDispatchToProps
)(Dashboard);
