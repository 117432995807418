/**
 * Settings Reducer
 * Reducer
 */

import apiCaller from 'Utils/apiCallerSignin';

const SETTINGS_ACTIVE_TAB_UPDATE = 'SETTINGS_ACTIVE_TAB_UPDATE';

const FORM_UPLOAD_REQUESTED = 'FORM_UPLOAS_REQUESTED';
const FORM_UPLOAD_RESPONSE = 'FORM_UPLOAS_RESPONSE';
const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS';
const ADD_BANK_DETAILS_REQUESTED = 'ADD_BANK_DETAILS_REQUESTED';
const GET_BANK_DETAILS = 'GET_BANK_DETAILS';
const GET_BANK_DETAILS_REQUESTED = 'GET_BANK_DETAILS_REQUESTED';

const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

const CHANGE_EMAIL_REQUESTED = 'CHANGE_EMAIL_REQUESTED';
const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';

const KYC_UPLOAD_REQUESTED = 'KYC_UPLOAD_REQUESTED';
const KYC_UPLOAD_SUCCESS = 'KYC_UPLOAD_SUCCESS';

const GET_KYC_DETAILS_REQUESTED = 'GET_KYC_DETAILS_REQUESTED';
const GET_KYC_DETAILS_SUCCESS = 'GET_KYC_DETAILS_SUCCESS';

const GENERATE_TWO_FA = 'GENERATE_TWO_FA';

const ENABLE_TWO_FA_REQUESTED = 'ENABLE_TWO_FA_REQUESTED';
const ENABLE_TWO_FA_SUCCESS = 'ENABLE_TWO_FA_SUCCESS';

const DISABLE_TWO_FA_REQUESTED = 'DISABLE_TWO_FA_REQUESTED';
const DISABLE_TWO_FA_SUCCESS = 'DISABLE_TWO_FA_SUCCESS';

const LOGIN_HISTORY_REQUESTED = 'LOGIN_HISTORY_REQUESTED';
const LOGIN_HISTORY_RESPONSE = 'LOGIN_HISTORY_RESPONSE';

const ADD_BANK_ACCOUNT_TB = 'ADD_BANK_ACCOUNT_TB';

const initialState = {
  isFormProcessing: false,
  formUploadResponse: {},
  bankDetailsResponse: {},
  changePwdResponse: {},
  changePwdLoader: false,
  changeEmailLoader: false,
  changeEmailResponse: {},
  kycDetailsResponse: {},
  kycUploadLoader: false,
  getTwoFaResponse: {},
  enableTwoFaResponse: {},
  disableTwoFaResponse: {},
  twoFaLoader: false,
  bankAccountTB: {}, // Bank account of throughbit
  activeTab: '2fa',
  loginHistoryLoader: false,
  loginHistoryResponse: []
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case FORM_UPLOAD_REQUESTED:
      return {
        ...state,
        isFormProcessing: true
      };

    case FORM_UPLOAD_RESPONSE:
      return {
        ...state,
        isFormProcessing: false,
        formUploadResponse: action.response
      };

    case GET_BANK_DETAILS_REQUESTED:
      return {
        ...state
      };
    case GET_BANK_DETAILS:
      return {
        ...state,
        isFormProcessing: false,
        bankDetailsResponse: action.response
      };
    case ADD_BANK_DETAILS_REQUESTED:
      return {
        ...state,
        isFormProcessing: true
      };
    case ADD_BANK_DETAILS:
      return {
        ...state,
        isFormProcessing: false,
        bankDetailsResponse: action.response
      };
    case CHANGE_PASSWORD_REQUESTED:
      return {
        ...state,
        changePwdLoader: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePwdLoader: false,
        changePwdResponse: action.response
      };
    case CHANGE_EMAIL_REQUESTED:
      return {
        ...state,
        changeEmailLoader: true
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        changeEmailLoader: false,
        changeEmailResponse: action.response
      };

    case KYC_UPLOAD_REQUESTED:
      return {
        ...state,
        kycUploadLoader: true,
        changeEmailLoader: true
      };
    case KYC_UPLOAD_SUCCESS:
      return {
        ...state,
        kycUploadLoader: false,
        kycDetailsResponse: action.response
      };
    case GET_KYC_DETAILS_REQUESTED:
      return {
        ...state
      };
    case GET_KYC_DETAILS_SUCCESS:
      return {
        ...state,
        kycDetailsResponse: action.response
      };
    case GENERATE_TWO_FA:
      return {
        ...state,
        getTwoFaResponse: action.response
      };
    case ENABLE_TWO_FA_REQUESTED:
      return {
        ...state,
        twoFaLoader: true
      };
    case ENABLE_TWO_FA_SUCCESS:
      return {
        ...state,
        twoFaLoader: false,
        enableTwoFaResponse: action.response
      };
    case DISABLE_TWO_FA_REQUESTED:
      return {
        ...state,
        twoFaLoader: true
      };
    case DISABLE_TWO_FA_SUCCESS:
      return {
        ...state,
        twoFaLoader: false,
        disableTwoFaResponse: action.response
      };

    case ADD_BANK_ACCOUNT_TB:
      return {
        ...state,
        bankAccountTB: action.response
      };
    case SETTINGS_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case LOGIN_HISTORY_REQUESTED:
      return {
        ...state,
        loginHistoryLoader: true
      };
    case LOGIN_HISTORY_RESPONSE:
      return {
        ...state,
        loginHistoryLoader: false,
        loginHistoryResponse: action.response
      };
    default:
      return state;
  }
};

/**
 *Get Bank Details
 *Action
 *@event
 */
export const getBankDetails = formData => dispatch => {
  dispatch({
    type: GET_BANK_DETAILS_REQUESTED
  });
  apiCaller('bank/viewaccount', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: GET_BANK_DETAILS,
        response
      })
  );
};

/**
 *Add Bank Details Action
 *@event
 */
export const addBankDetails = formData => dispatch => {
  dispatch({
    type: ADD_BANK_DETAILS_REQUESTED
  });
  apiCaller('bank', 'post', formData, false, true, false).then(response =>
    dispatch({
      type: ADD_BANK_DETAILS,
      response
    })
  );
};

/**
 *Change Password Action
 *@event
 */
export const changePassword = formData => dispatch => {
  dispatch({
    type: CHANGE_PASSWORD_REQUESTED
  });
  apiCaller('changepassword', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        response
      })
  );
};

/**
 *Change Email Action
 *@event
 */
export const changeEmail = formData => dispatch => {
  dispatch({
    type: CHANGE_EMAIL_REQUESTED
  });
  apiCaller('changeemail', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: CHANGE_EMAIL_SUCCESS,
        response
      })
  );
};

/**
 *get KycDetails Action
 *@event
 */
export const getKycDetails = formData => dispatch => {
  dispatch({
    type: GET_KYC_DETAILS_REQUESTED
  });
  apiCaller('kycform/view', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: GET_KYC_DETAILS_SUCCESS,
        response
      })
  );
};

/**
 *genrate 2Fa Action
 *@event
 */
export const generateTwoFa = formData => dispatch => {
  apiCaller('generate2FAKey', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: GENERATE_TWO_FA,
        response
      })
  );
};

/**
 *enable 2Fa Action
 *@event
 */
export const enableTwoFa = formData => dispatch => {
  dispatch({
    type: ENABLE_TWO_FA_REQUESTED
  });
  apiCaller('enable2FA', 'post', formData, false, true, false).then(response =>
    dispatch({
      type: ENABLE_TWO_FA_SUCCESS,
      response
    })
  );
};

/**
 *upload Kyc Details Action
 *@event
 */
export const uploadKycDetails = formData => dispatch => {
  dispatch({
    type: KYC_UPLOAD_REQUESTED
  });
  apiCaller('kycform', 'post', formData, false, true, true).then(response =>
    dispatch({
      type: KYC_UPLOAD_SUCCESS,
      response
    })
  );
};

/**
 *Disable 2Fa Action
 *@event
 */
export const disableTwoFa = formData => dispatch => {
  dispatch({
    type: DISABLE_TWO_FA_REQUESTED
  });
  apiCaller('disable2FA', 'post', formData, false, true, false).then(response =>
    dispatch({
      type: DISABLE_TWO_FA_SUCCESS,
      response
    })
  );
};

/**
 *enable 2Fa Action
 *@event
 */
export const submitForm = formData => dispatch => {
  dispatch({
    type: ENABLE_TWO_FA_REQUESTED
  });
  apiCaller('ga/enable_2fa', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: FORM_UPLOAD_RESPONSE,
        response
      })
  );
};

/**
 *enable 2Fa Action
 *@event
 */
export const loginHistoryReq = formData => dispatch => {
  dispatch({
    type: LOGIN_HISTORY_REQUESTED
  });
  apiCaller('loginhistory', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: LOGIN_HISTORY_RESPONSE,
        response
      })
  );
};

/**
 *Fetch bank account details of ThroughBit
 *@event
 */
export const fetchBankDetailsTB = formData => dispatch => {
  apiCaller('bank/getdetails', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: ADD_BANK_ACCOUNT_TB,
        response
      })
  );
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: SETTINGS_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};
