import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { generateTwoFa, enableTwoFa, disableTwoFa } from '../settingsReducer';
import { addProfile } from 'Modules/signin/signinReducer';

import { Popover, PopoverBody } from 'reactstrap';

const cookies = new Cookies();
let email = cookies.get('email');
let code_2fa = cookies.get('code_2fa');
const google2faReg = /(^\d{6}$)/;

/**
 * Represents a Security 2Fa Class.
 * @class extends React.Component
 */
class Security2FA extends React.Component {
  code_2fa = cookies.get('code_2fa');
  constructor(props) {
    super(props);
    this.state = {
      secret: '',
      qrCodeUrl: '',
      code: '',
      password: '',
      error: '',
      popOver2FAZeroOpen: false,
      popOver2FAOneOpen: false,
      code2FAOneMessage: '',
      responseMessage: '',
      disableTwoFaResponse: '',
      enableTwoFaResponse: '',
      // enabled: false,
      twoFaLoader: false,
      twoFaError: '',
      code_2fa: this.props.code_2fa
    };

    this.toggle2FAZeroPopOver = this.toggle2FAZeroPopOver.bind(this);
    this.toggle2FAOnePopOver = this.toggle2FAOnePopOver.bind(this);
    this.handleGetResponse = this.handleGetResponse.bind(this);
    this.handleEnableResponse = this.handleEnableResponse.bind(this);
    this.handleDisableResponse = this.handleDisableResponse.bind(this);
  }

  componentDidMount() {
    if (code_2fa === '0') {
      // Generate The Two Factor Secret Code And QrCode When Component Loads
      email =
        this.props.profile !== undefined ? this.props.profile.email : email;
      this.props.generateTwoFa({ email });
    }
  }

  componentWillReceiveProps(nextProps) {
    // Loadder
    if (this.props.twoFaLoader !== nextProps.twoFaLoader) {
      this.setState({ twoFaLoader: nextProps.twoFaLoader });
    }
    //handle 2fa enable Response
    if (
      this.props.enableTwoFaResponse !== undefined &&
      this.props.enableTwoFaResponse !== nextProps.enableTwoFaResponse
    ) {
      this.handleEnableResponse(nextProps.enableTwoFaResponse);
      this.setState(
        { enableTwoFaResponse: nextProps.enableTwoFaResponse },
        this.change2FAForm
      );
    }
    //handle 2fa disable Response
    if (
      this.props.disableTwoFaResponse !== undefined &&
      this.props.disableTwoFaResponse !== nextProps.disableTwoFaResponse
    ) {
      this.handleDisableResponse(nextProps.disableTwoFaResponse);
      this.setState({
        disableTwoFaResponse: nextProps.disableTwoFaResponse
        // error: nextProps.disableTwoFaResponse.message
      });
    }
    //handle 2fa get 2fa Response
    if (
      this.props.getTwoFaResponse !== undefined &&
      this.props.getTwoFaResponse !== nextProps.getTwoFaResponse
    ) {
      this.handleGetResponse(nextProps.getTwoFaResponse);
      this.setState({ getTwoFaResponse: nextProps.getTwoFaResponse });
    }
    if (nextProps.profile !== undefined) {
      email = email || nextProps.profile.email;
      code_2fa = code_2fa || nextProps.profile.code_2fa;
    }
  }

  toggle2FAZeroPopOver(e) {
    e.preventDefault();
    this.setState({
      popOver2FAZeroOpen: !this.state.popOver2FAZeroOpen
    });
  }

  toggle2FAOnePopOver(e, message) {
    e.preventDefault();
    this.setState({
      code2FAOneMessage: message,
      popOver2FAOneOpen: !this.state.popOver2FAOneOpen
    });
  }

  change2FAForm() {
    const { status } = this.state.enableTwoFaResponse;
    if (status === 1) {
      // setTimeout(() => {
      //   this.setState({ enabled: false });
      // }, 200);
    }
  }

  /**
   * Handle Get 2Fa Response
   * @function
   * @param {object} => response Data from the Api
   */

  handleGetResponse = response => {
    // if (response.status === 1) {
    //   this.setState({ error: '' });
    //   //When You call the addBankDetails Function on Success Update the response mesaage as success
    //   if (response.data) {
    //     this.setState({
    //       // enabled: true,
    //       qrCodeUrl: response.data.qrCodeUrl,
    //       secret: response.data.secret
    //     });
    //   }
    //   // Response Data will Come Only when You Call getBankDetails function
    // } else if (response.status === 2) {
    //   //If The Fields Are Required
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 19) {
    //   // If Bank Details Are Already Existed
    //   this.setState({ responseMessage: response.error });
    //   this.setState({ error: '' });
    // } else if (response.status === 21) {
    //   // If Token Expired
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 20) {
    //   // If unAuthorized Login
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 22) {
    //   // If Google Authenticator code already enabled
    //   // this.setState({ enabled: false });
    // }
    if (response.error == 0) {
      this.setState({ error: '' });
      if (response.data) {
        this.setState({
          // enabled: true,
          qrCodeUrl: response.data.qrCodeUrl,
          secret: response.data.secret
        });
      }
    } else {
      this.setState({
        responseMessage: '',
        error: response.error
      });
    }
  };

  /**
   * Handle 2Fa Enable	Response
   * @function
   * @param {object} => response Data from the Api
   */
  handleEnableResponse = response => {
    if (response.error == 0) {
      cookies.set('code_2fa', '1');
      this.setState({
        error: '',
        responseMessage: response.message,
        code: ''
      });
      this.props.addProfile({
        globalId: this.props.global_id,
        kyc_status: this.props.kyc_status,
        code_2fa: '1'
      });
    } else {
      this.setState({
        responseMessage: '',
        error: response.message
      });
    }
    // if (response.status === 1) {
    //   cookies.set('code_2fa', '1');
    //   //  code_2fa = '1';
    //   this.props.addProfile({
    //     globalId: this.props.global_id,
    //     kyc_status: this.props.kyc_status,
    //     code_2fa: '1'
    //   });
    //   this.setState({ error: '' });
    //   //When You call the addBankDetails Function on Success Update the response mesaage as success
    //   if (!response.data) {
    //     this.setState({ responseMessage: response.result });
    //     // this.setState({ enabled: true });
    //     this.setState({ code: '' });
    //   }
    //   // Response Data will Come Only when You Call getBankDetails function
    //   // So bellow code will only apply for getBank Details
    //   if (response.data) {
    //     this.setState({ responseMessage: '' });
    //   }
    // } else if (response.status === 2) {
    //   //If The Fields Are Required
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 19) {
    //   // If Bank Details Are Already Existed
    //   this.setState({ responseMessage: response.error });
    //   this.setState({ error: '' });
    // } else if (response.status === 21) {
    //   // If Token Expired
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 20) {
    //   // If unAuthorized Login
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 13) {
    //   // If unAuthorized Login
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 5) {
    //   // If unAuthorized Login
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // }
  };

  /**
   * Handle 2Fa disable	Response
   * @function
   * @param {object} => response Data from the Api
   */

  handleDisableResponse = response => {
    if (response.error === 0) {
      cookies.set('code_2fa', '0');
      //   code_2fa = '0';
      this.setState({
        code: '',
        password: '',
        // enabled: false,
        responseMessage: response.message,
        error: ''
      });
      this.props.addProfile({
        globalId: this.props.global_id,
        kyc_status: this.props.kyc_status,
        code_2fa: '0'
      });
      //When You call the addBankDetails Function on Success Update the response mesaage as success

      this.props.generateTwoFa({ email });
    } else {
      this.setState({
        responseMessage: '',
        error: response.message
      });
    }
    // if (response.status === 1) {
    //   cookies.set('code_2fa', '0');
    //   //   code_2fa = '0';
    //   this.props.addProfile({
    //     globalId: this.props.global_id,
    //     kyc_status: this.props.kyc_status,
    //     code_2fa: '0'
    //   });
    //   //When You call the addBankDetails Function on Success Update the response mesaage as success
    //   if (!response.data) {
    //     this.setState({
    //       code: '',
    //       password: '',
    //       // enabled: false,
    //       responseMessage: response.result,
    //       error: ''
    //     });
    //     this.props.generateTwoFa({ email });
    //   }
    // } else if (response.error === 1) {
    //   //If The Fields Are Required
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.message });
    // } else if (response.status === 19) {
    //   // If Bank Details Are Already Existed
    //   this.setState({ responseMessage: response.error });
    //   this.setState({ error: '' });
    // } else if (response.status === 21) {
    //   // If Token Expired
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 20) {
    //   // If unAuthorized Login
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 12) {
    //   // unAuthorized google auth code
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // } else if (response.status === 13) {
    //   // unAuthorized google auth code
    //   this.setState({ responseMessage: '' });
    //   this.setState({ error: response.error });
    // }
  };

  /**
   * Calls The Api Enable 2FA
   * @function
   * @param {object} => {email,secret,code,password}
   */
  handleEnable = e => {
    e.preventDefault();
    this.props.enableTwoFa({
      secret: this.state.secret,
      code: this.state.code,
      password: this.state.password
    });
  };

  /**
   * Calls The Api Disable 2FA
   * @function
   * @param {object} => {email,secret,code,password}
   */

  handleDisable = e => {
    e.preventDefault();
    this.props.disableTwoFa({
      code: this.state.code.toString()
    });
  };
  handle2Fa = e => {
    this.setState({ code: e.target.value });
    if (google2faReg.test(e.target.value)) {
      this.setState({ twoFaError: '' });
    } else {
      this.setState({ twoFaError: 'Please enter valid 2Fa Code' });
    }
  };
  render() {
    code_2fa =
      this.props.profile !== undefined ? this.props.profile.code_2fa : code_2fa;
    return (
      <div className="container-fluid mt-5 settings-form">
        {code_2fa === '0' && (
          <div className="row mb-5 ml-5 mr-5">
            <div className="col-lg-3 mt-5" align="center">
              <div className="qr-code">
                {/* <iframe
									title="qrcode"
									src={this.state.qrCodeUrl}
									width="200"
									height="200"
								/> */}
                <img src={this.state.qrCodeUrl} />
              </div>
              <div className="auth-key mt-4">{`16 Digit Key: ${this.state.secret}`}</div>
            </div>
            <div className="col-lg-9">
              <div className="title">Google authentication</div>
              <p className="mt-3">
                To ensure advanced security, it is advised to turn on Two-Factor
                Authentication (2-FA). Throughbit uses Google Authenticator for
                2-FA. In order to use 2-FA, scan the QR code to the right into
                the Google Authenticator app on your phone, or enter the
                16-digit key that appears under the QR code. Input the six-digit
                code provided by the Google Authenticator app and your account
                password below, then click "Enable 2-FA"
              </p>
              <div className="row no-gutters">
                <div className="col-lg-4">
                  <form>
                    <div className="form-group">
                      <label>Current password</label>
                      <input
                        className="form-control"
                        type="password"
                        id="password"
                        name="currentPass"
                        value={this.state.password}
                        onChange={e =>
                          this.setState({ password: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>2 FA Code</label>
                      <input
                        pattern="(\d{0,6})"
                        className="form-control"
                        type="text"
                        value={this.state.code}
                        autoComplete="off"
                        onChange={e =>
                          this.setState({
                            code: e.target.validity.valid
                              ? e.target.value
                              : this.state.code
                          })
                        }
                        name="code"
                        id="code"
                        placeholder="123456"
                      />
                    </div>

                    {/* {this.state.twoFaLoader ? (
                      <div className="loaderStyle">
                        <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-blue"
                        disabled={
                          this.state.password === '' || this.state.code === ''
                        }
                        onClick={this.handleEnable}
                      >
                        Enable
                      </button>
										)} */}

                    {(this.state.password === '' || this.state.code === '') && (
                      <>
                        <button
                          type="submit"
                          className="btn btn-blue"
                          id="Popover1"
                          onClick={this.toggle2FAZeroPopOver}
                        >
                          Enable
                        </button>
                        <Popover
                          placement="bottom"
                          isOpen={this.state.popOver2FAZeroOpen}
                          target="Popover1"
                          toggle={this.toggle2FAZeroPopOver}
                        >
                          <PopoverBody>
                            Please enter Password and 2FA code.
                          </PopoverBody>
                        </Popover>
                      </>
                    )}

                    {this.state.password !== '' &&
                      this.state.code !== '' &&
                      (!this.state.twoFaLoader ? (
                        <button
                          className="btn btn-blue"
                          onClick={this.handleEnable}
                        >
                          Enable
                        </button>
                      ) : (
                        <button className="btn btn-primary btn-block">
                          <i className="fa fa-spinner fa-spin fa-fw" />
                        </button>
                      ))}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {code_2fa === '1' && (
          <div className="row no-gutters justify-content-center settings-form">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="title">Disable 2FA</div>
              <form>
                <div className="form-group">
                  <label>Code</label>
                  <input
                    pattern="(\d{0,6})"
                    className="form-control"
                    type="text"
                    value={this.state.code}
                    autoComplete="off"
                    autoFocus
                    onChange={e =>
                      this.setState(
                        {
                          code: e.target.validity.valid
                            ? e.target.value
                            : this.state.code
                        },
                        () => {
                          if (this.state.code.length === 6) {
                            this.handleDisable(e);
                          }
                        }
                      )
                    }
                    name="code"
                    id="code"
                    placeholder="123456"
                  />
                </div>

                {/* {this.state.twoFaLoader ? (
                  <div className="loaderStyle">
                    <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                  </div>
                ) : (
                  <button
                    className="btn btn-blue"
                    onClick={this.handleDisable}
                    disabled={this.state.code.length !== 6}
                  >
                    Disable 2 FA
                  </button>
								)} */}

                {this.state.code === '' && (
                  <>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      id="Popover2"
                      onClick={e =>
                        this.toggle2FAOnePopOver(e, 'Please enter 2FA code.')
                      }
                    >
                      Disable 2 FA
                    </button>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popOver2FAOneOpen}
                      target="Popover2"
                      toggle={e => this.toggle2FAOnePopOver(e, '')}
                    >
                      <PopoverBody>{this.state.code2FAOneMessage}</PopoverBody>
                    </Popover>
                  </>
                )}

                {this.state.code !== '' && this.state.code.length < 6 && (
                  <>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      id="Popover2"
                      onClick={e =>
                        this.toggle2FAOnePopOver(
                          e,
                          'Please enter valid 2FA code.'
                        )
                      }
                    >
                      Disable 2 FA
                    </button>
                    <Popover
                      placement="bottom"
                      isOpen={this.state.popOver2FAOneOpen}
                      target="Popover2"
                      toggle={e => this.toggle2FAOnePopOver(e, '')}
                    >
                      <PopoverBody>{this.state.code2FAOneMessage}</PopoverBody>
                    </Popover>
                  </>
                )}

                {this.state.code.length === 6 &&
                  (!this.state.twoFaLoader ? (
                    <button
                      className="btn btn-blue"
                      onClick={this.handleDisable}
                    >
                      Disable 2 FA
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block">
                      <i className="fa fa-spinner fa-spin fa-fw" />
                    </button>
                  ))}
              </form>
            </div>
          </div>
        )}

        {/* error message and response messages */}
        {this.state.error !== '' ? (
          <div className="error-details">{this.state.error}</div>
        ) : null}
        {/* {this.state.responseMessage !== '' ? (
          <div className="acc-details">{this.state.responseMessage}</div>
        ) : null} */}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      generateTwoFa: formData => generateTwoFa(formData),
      changePage: page => push(page),
      enableTwoFa: formData => enableTwoFa(formData),
      disableTwoFa: formData => disableTwoFa(formData),
      addProfile: formData => addProfile(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    twoFaLoader: state.settings.twoFaLoader,
    enableTwoFaResponse: state.settings.enableTwoFaResponse,
    disableTwoFaResponse: state.settings.disableTwoFaResponse,
    getTwoFaResponse: state.settings.getTwoFaResponse,
    profile: state.signin.profile,
    global_id: state.signin.global_id,
    kyc_status: state.signin.kyc_status
  }),
  mapDispatchToProps
)(Security2FA);
