/**
 * Dashboard Reducer
 *Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerNew';

const BUY_LIMIT_ORDER_FORM = 'BUY_LIMIT_ORDER_FORM';
const BUY_LIMIT_ORDER_RESPONSE = 'BUY_LIMIT_ORDER_RESPONSE';
const HIDE_BUY_LIMIT_MODAL = 'HIDE_BUY_LIMIT_MODAL';

const SELL_LIMIT_ORDER_FORM = 'SELL_LIMIT_ORDER_FORM';
const SELL_LIMIT_ORDER_RESPONSE = 'SELL_LIMIT_ORDER_RESPONSE';
const HIDE_SELL_LIMIT_MODAL = 'HIDE_SELL_LIMIT_MODAL';

const LIMIT_FORM_HEIGHT = 'LIMIT_FORM_HEIGHT';

const initialState = {
  currencyBuy: 1,
  volumeBuy: '',
  bidBuy: '',
  operationBuy: 1,
  marketBuy: 0,
  totalBuy: '',

  currencySell: 1,
  volumeSell: '',
  bidSell: '',
  operationSell: 0,
  marketSell: 0,
  totalSell: '',

  buyFormModal: false,
  response: {},

  orderDetails: {},
  orderHistoryLoader: false,

  limitFormHeight: 0
};

/**
 * Reducer
 * @event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case BUY_LIMIT_ORDER_FORM:
      return {
        ...state,
        currencyBuy: action.currencyBuy,
        operationBuy: action.operationBuy,
        marketBuy: action.marketBuy,
        volumeBuy: action.volumeBuy,
        bidBuy: action.bidBuy,
        totalBuy: action.totalBuy
      };
    case BUY_LIMIT_ORDER_RESPONSE:
      return {
        ...state,
        buyResponse: action.response,
        buyFormModal: action.buyFormModal
      };
    case HIDE_BUY_LIMIT_MODAL:
      return {
        ...state,
        buyFormModal: action.buyFormModal
      };

    case SELL_LIMIT_ORDER_FORM:
      return {
        ...state,
        currencySell: action.currencySell,
        operationSell: action.operationSell,
        marketSell: action.marketSell,
        volumeSell: action.volumeSell,
        bidSell: action.bidSell,
        totalSell: action.totalSell
      };
    case SELL_LIMIT_ORDER_RESPONSE:
      return {
        ...state,
        sellResponse: action.response,
        sellFormModal: action.sellFormModal
      };
    case HIDE_SELL_LIMIT_MODAL:
      return {
        ...state,
        sellFormModal: action.sellFormModal
      };

    case LIMIT_FORM_HEIGHT:
      return {
        ...state,
        limitFormHeight: action.limitFormHeight
      };

    default:
      return state;
  }
};

export const buyLimitInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: BUY_LIMIT_ORDER_FORM,
    volumeBuy: formdata.volumeBuy,
    bidBuy: formdata.bidBuy,
    currencyBuy: formdata.currencyBuy,
    operationBuy: formdata.operationBuy,
    marketBuy: formdata.marketBuy,
    totalBuy: formdata.totalBuy
  });
};

export const addBuyOrder = formdata => dispatch => {
  apiCaller('order/limit/submit', 'post', formdata, false, true, false).then(
    response => {
      dispatch({
        type: BUY_LIMIT_ORDER_RESPONSE,
        response,
        buyFormModal: true
      });
    }
  );
};

export const hideBuyModal = () => dispatch => {
  dispatch({
    type: HIDE_BUY_LIMIT_MODAL,
    buyFormModal: false
  });
};

export const sellLimitInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: SELL_LIMIT_ORDER_FORM,
    volumeSell: formdata.volumeSell,
    bidSell: formdata.bidSell,
    currencySell: formdata.currencySell,
    operationSell: formdata.operationSell,
    marketSell: formdata.marketSell,
    totalSell: formdata.totalSell
  });
};

export const addSellOrder = formdata => dispatch => {
  apiCaller('order/limit/submit', 'post', formdata, false, true, false).then(
    response => {
      dispatch({
        type: SELL_LIMIT_ORDER_RESPONSE,
        response,
        sellFormModal: true
      });
    }
  );
};

export const hideSellModal = () => dispatch => {
  dispatch({
    type: HIDE_SELL_LIMIT_MODAL,
    sellFormModal: false
  });
};

export const limitFormHeightFunc = formData => dispatch => {
  dispatch({
    type: LIMIT_FORM_HEIGHT,
    limitFormHeight: formData.limitFormHeight
  });
};
