import React from 'react';
import NavBar from './navBar';

class ExpireLink extends React.Component {
  componentDidMount() {
    document.body.classList.add('text-center');
    document.body.id = 'notification-page';
  }
  componentWillUnmount() {
    document.body.classList.remove('text-center');
    document.body.id = undefined;
  }
  render() {
    return (
      <>
        <NavBar />
        <div
          className="jumbotron d-flex align-items-center"
          style={{ height: '85vh' }}
        >
          <div className="container text-center w-50">
            <h1
              className="cover-heading mb-3"
              style={{ color: 'orangered', fontSize: '40px' }}
            >
              This link is either invalid or expired.
            </h1>
          </div>
        </div>
      </>
    );
  }
}

export default ExpireLink;
