/**
 * Buy Crypto Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import _ from 'underscore';
import removeCookie from 'Utils/removeCookie';
import balanceFormat from 'Utils/balanceFormat';

import { logout } from 'Modules/signin/signinReducer';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  // PopoverHeader,
  PopoverBody
} from 'reactstrap';

import {
  addSellOrder,
  sellLimitInputRemotelyChange,
  hideSellModal
} from './limitReducer';
import {
  selectedCurrencyUpdate,
  activeTabUpdate
} from 'Modules/withdrawdeposit/withdrawDepositReducer';
import {
  getMyBalance,
  getMyOpenOrders,
  getMyOrderHistory,
  getMyTradeMetricsFunc
} from '../myOrders/myOrdersReducer';

const cookies = new Cookies();
let email = cookies.get('email');

/**
 * Represents a Buy Crypto Class.
 * @class extends React.Component
 */
class SellOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencySell: 1,
      marketSell: 0,
      volumeSell: this.props.volumeSell,
      bidSell: this.props.bidSell,
      operationSell: 0,
      totalSell: this.props.totalSell,

      sellFormModal: this.props.sellFormModal,
      success: false,
      message: false,
      errorResponse: false,
      confirm: false,
      orderid: '',
      txnid: '',
      myBalanceList: this.props.getMyBalanceList,
      sellResponse: {},
      reloadButton: false,
      modal: false,
      popoverOpen: false,
      isLoggedIn: this.props.isLoggedIn,
      myBalanceAmount: 0,
      activeCurrencyValue: this.props.activeCurrencyValue,
      activeCurrencySymbol: this.props.activeCurrencySymbol
    };
    this.toggle = this.toggle.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.currencyBalanceFormatFunc();
  }

  componentWillReceiveProps(nextProps) {
    //Handle The Response IF Recieved Balance Data
    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState(
        { myBalanceList: nextProps.getMyBalanceList },
        this.currencyBalanceFormatFunc
      );
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      this.setState(
        {
          bidSell: '',
          volumeSell: '',
          totalSell: ''
        },
        this.handleInputRemotely
      );
    }

    if (this.props.activeCurrencySymbol !== nextProps.activeCurrencySymbol) {
      this.setState(
        { activeCurrencySymbol: nextProps.activeCurrencySymbol },
        this.currencyBalanceFormatFunc
      );
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      this.setState(
        { activeCurrencyValue: nextProps.activeCurrencyValue },
        this.currencyBalanceFormatFunc
      );
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
    }

    if (this.props.bidSell !== nextProps.bidSell) {
      this.setState({ bidSell: nextProps.bidSell });
    }

    if (this.props.volumeSell !== nextProps.volumeSell) {
      this.setState({ volumeSell: nextProps.volumeSell });
    }

    if (this.props.totalSell !== nextProps.totalSell) {
      this.setState({ totalSell: nextProps.totalSell });
    }

    if (this.props.sellResponse !== nextProps.sellResponse) {
      this.setState(
        {
          reloadButton: false,
          sellResponse: nextProps.sellResponse,
          message: nextProps.sellResponse.message,
          errorResponse: nextProps.sellResponse.error
        },
        this.checkSellResponse
      );
    }

    // if (this.props.getBuyOrdersList !== nextProps.getBuyOrdersList) {
    //   if (
    //     nextProps.getBuyOrdersList.error === 0 &&
    //     nextProps.getBuyOrdersList.data
    //   ) {
    //     if (
    //       this.state.bidSell === '' &&
    //       this.state.volumeSell === '' &&
    //       this.state.totalSell === ''
    //     ) {
    //       this.props.sellLimitInputRemotelyChange({
    //         volumeSell: this.state.volumeSell,
    //         bidSell: parseFloat(
    //           nextProps.getBuyOrdersList.data[
    //             nextProps.getBuyOrdersList.data.length - 1
    //           ].price
    //         ),
    //         totalSell: this.state.totalSell
    //       });
    //     }
    //   }
    // }

    if (this.props.getRecentTradeList !== nextProps.getRecentTradeList) {
      if (
        nextProps.getRecentTradeList.error === 0 &&
        nextProps.getRecentTradeList.data[0]
      ) {
        if (
          this.state.bidSell === '' &&
          this.state.volumeSell === '' &&
          this.state.totalSell === ''
        ) {
          var lastSellObj = _.find(nextProps.getRecentTradeList.data, obj => {
            return obj.operation === 0;
          });
          if (lastSellObj) {
            this.props.sellLimitInputRemotelyChange({
              volumeSell: '',
              bidSell: parseFloat(lastSellObj.executionPrice),
              totalSell: ''
            });
          }
        }
      }
    }
  }

  handleInputChange = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.validity.valid ? e.target.value : this.state[name];
    this.setState(
      {
        [name]: value
      },
      this.handleTotalChange(name, value)
    );
  };

  handleAmountBalanceChange = volumeSell => {
    if (volumeSell) {
      this.setState(
        {
          volumeSell
        },
        this.handleTotalChange('volumeSell', volumeSell)
      );
    }
  };

  /**
   * Handles The Amount change
   * @function
   * @param {string} --> User Entered Amount
   */
  handleTotalChange = (name, value) => {
    var { bidSell, volumeSell, totalSell } = this.state;

    if (name === 'bidSell') {
      totalSell = Number(value * volumeSell).toFixed(8) || '';
    } else if (name === 'volumeSell') {
      totalSell = Number(bidSell * value).toFixed(8) || '';
    } else if (name === 'totalSell' && bidSell > 0) {
      volumeSell = Number(value / bidSell).toFixed(8) || '';
    }

    this.setState(
      {
        bidSell,
        volumeSell,
        totalSell
      },
      this.handleInputRemotely
    );
  };

  // /**
  //  * Handles The Amount change
  //  * @function
  //  * @param {string} --> User Entered Amount
  //  */
  // handleTotalChange = (name, value) => {
  //   const { bidSell, volumeSell, totalSell } = this.state;

  //   if (name === 'bidSell') {
  //     if (volumeSell !== '' && value !== '') {
  //       const totalSell = value * volumeSell;
  //       this.setState({ totalSell }, this.handleInputRemotely);
  //     } else if (volumeSell === '' && totalSell !== '' && value !== '') {
  //       const volumeSell = totalSell / value;
  //       this.setState({ volumeSell }, this.handleInputRemotely);
  //     } else if (volumeSell === '' && totalSell === '' && value !== '') {
  //       this.setState({ bidSell: value }, this.handleInputRemotely);
  //     } else if (value === '' || value === '0') {
  //       this.setState(
  //         {
  //           volumeSell: '',
  //           totalSell: ''
  //         },
  //         this.handleInputRemotely
  //       );
  //     } else {
  //       this.setState({ bidSell: value }, this.handleInputRemotely);
  //     }
  //   } else if (name === 'volumeSell') {
  //     if (bidSell !== '' && bidSell !== '0' && value !== '') {
  //       const totalSell = bidSell * value;
  //       this.setState({ totalSell }, this.handleInputRemotely);
  //     } else if (value === '' || value === '0') {
  //       this.setState({ totalSell: '' }, this.handleInputRemotely);
  //     } else {
  //       this.setState({ volumeSell: value }, this.handleInputRemotely);
  //     }
  //   } else if (name === 'totalSell') {
  //     if (bidSell !== '' && value !== '') {
  //       const volumeSell = value / bidSell;
  //       this.setState({ volumeSell }, this.handleInputRemotely);
  //     } else if (bidSell === '' || bidSell === '0') {
  //       this.setState({ volumeSell: '' }, this.handleInputRemotely);
  //     } else if (value === '' || value === '0') {
  //       this.setState({ totalSell: value }, this.handleInputRemotely);
  //     } else {
  //       this.setState({ totalSell: value }, this.handleInputRemotely);
  //     }
  //   } else {
  //     this.handleInputRemotely();
  //   }
  // };

  handleInputRemotely = () => {
    const {
      volumeSell,
      bidSell,
      // currencySell,
      operationSell,
      // marketSell,
      totalSell
    } = this.state;
    this.props.sellLimitInputRemotelyChange({
      volumeSell: volumeSell,
      bidSell: bidSell,
      marketSell: this.props.activeMarketValue,
      currencySell: this.props.activeCurrencyValue,
      // currencySell: currencySell,
      operationSell: operationSell,
      // marketSell: marketSell,
      totalSell: totalSell
    });
  };

  /**
   * Handles The Buy Confirm
   * @function
   * @param {object} --> Click event
   */

  handleSellForm = e => {
    e.preventDefault();
    this.setState({
      reloadButton: true
    });
    const {
      volumeSell,
      bidSell,
      // currencySell,
      operationSell
      // marketSell
    } = this.state;
    this.props.addSellOrder({
      volume: volumeSell,
      price: bidSell,
      marketId: this.props.activeMarketValue,
      coinId: this.props.activeCurrencyValue,
      // currency: currencySell,
      operation: operationSell
      // market: marketSell
    });
  };

  toggle() {
    this.setState(
      {
        modal: !this.state.modal
      },
      this.checkSellResponse
    );
  }

  hideModalSellResponse() {
    setTimeout(() => {
      this.setState({
        message: false,
        errorResponse: false
      });
    }, 2000);
  }

  checkSellResponse = () => {
    this.hideModalSellResponse();
    const { sellResponse } = this.state;
    if (sellResponse.success === 1) {
      this.props.getMyBalance({});
      this.props.getMyOpenOrders({
        market: this.props.activeMarketValue,
        coin: this.props.activeCurrencyValue
      });
      if (sellResponse.message === 'Order complete') {
        this.props.getMyOrderHistory({
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue
        });
        this.props.getMyTradeMetricsFunc({
          coinId: this.props.activeCurrencyValue,
          marketId: this.props.activeMarketValue
        });
      }
      // this.setState(
      //   {
      //     volumeSell: '',
      //     bidSell: '',
      //     totalSell: '',
      //     sellResponse: {}
      //   },
      //   this.handleInputRemotely
      // );
    } else if (sellResponse.error === 2) {
      this.props.logout({ email });
      removeCookie();
    } else {
      this.setState({
        sellResponse: {}
      });
    }
  };

  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  moveToDepositPage(state) {
    this.props.selectedCurrencyUpdate({
      selectedCurrency: state.selectedCurrency
    });
    this.props.activeTabUpdate('1');
    this.props.changePage({ pathname: '/withdrawDeposit', state });
    // this.props.changePage({ pathname: '/transaction', state });
  }

  currencyBalanceFormatFunc() {
    const {
      myBalanceList,
      activeCurrencyValue,
      activeCurrencySymbol
    } = this.state;
    if (_.size(myBalanceList) > 0 && myBalanceList[activeCurrencyValue]) {
      var myBalanceAmount = balanceFormat.balanceFormatFunc(
        myBalanceList[activeCurrencyValue].amount,
        activeCurrencySymbol
      );
      this.setState({ myBalanceAmount });
    } else {
      this.setState({ myBalanceAmount: 0 });
    }
  }

  render() {
    const {
      myBalanceList,
      sellResponse,
      isLoggedIn,
      myBalanceAmount
    } = this.state;
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    return (
      <div className="col-lg-6 card-box pt-3">
        <div className="row no-gutters">
          <div className="col-md-5 text-left">
            <div className="title">
              <span className="line-vertical negative-line" /> Sell
            </div>
          </div>
          <div className="col-md-7 text-right">
            {isLoggedIn ? (
              <div
                className="number crsrPntr"
                onClick={() =>
                  this.handleAmountBalanceChange(
                    myBalanceList[this.props.activeCurrencyValue] &&
                      myBalanceList[this.props.activeCurrencyValue].amount
                  )
                }
              >
                {myBalanceAmount}
                <span className="currency">{` ${this.props.activeCurrencySymbol}`}</span>{' '}
                <a>
                  <span
                    className="add-icon"
                    onClick={() =>
                      this.moveToDepositPage({
                        selectedCurrency: this.props.activeCurrencyValue
                      })
                    }
                  >
                    +
                  </span>
                </a>
              </div>
            ) : (
              <div className="number">
                0
                <span className="currency">{` ${this.props.activeCurrencySymbol}`}</span>{' '}
                <a>
                  <span className="add-icon">+</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="calculator-form">
          <div className="input-group mb-3">
            <input
              pattern="(\d{1,20})?(\.{1}\d{0,8})?"
              // pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="bidSell"
              id="PriceSell"
              step=".00000001"
              value={this.state.bidSell}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Price"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeMarketSymbol}
              </span>
            </div>
          </div>
          <div className="input-group mb-3">
            <input
              pattern="(\d{1,20})?(\.{1}\d{0,8})?"
              // pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="volumeSell"
              id="AmountSell"
              step=".00000001"
              value={this.state.volumeSell}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Amount"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeCurrencySymbol}
              </span>
            </div>
          </div>
          <div className="input-group mb-3 pb-9">
            <input
              pattern="(\d{1,20})?(\.{1}\d{0,8})?"
              // pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="totalSell"
              id="TotalSell"
              step=".00000001"
              value={this.state.totalSell}
              onChange={this.handleInputChange}
              // value={Intl.NumberFormat('en-IN').format(this.props.totalSell)}
              className="form-control"
              placeholder="Total"
              // disabled={true}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeMarketSymbol}
              </span>
            </div>
          </div>
        </div>
        {this.props.isLoggedIn && (
          <>
            {(this.state.bidSell === '' ||
              this.state.volumeSell === '' ||
              this.state.totalSell === '') && (
              <>
                <button
                  className="btn btn-secondary btn-buy btn-block"
                  id="Popover2"
                  onClick={this.togglePopOver}
                >
                  Sell
                </button>
                <Popover
                  placement="bottom"
                  isOpen={this.state.popoverOpen}
                  target="Popover2"
                  toggle={this.togglePopOver}
                >
                  <PopoverBody>
                    Please enter Price, Amount and Total value.
                  </PopoverBody>
                </Popover>
              </>
            )}

            {this.state.bidSell !== '' &&
              this.state.volumeSell !== '' &&
              this.state.totalSell !== '' &&
              (!this.state.reloadButton ? (
                <button
                  className="btn btn-secondary btn-buy btn-block"
                  onClick={this.handleSellForm}
                >
                  Sell
                </button>
              ) : (
                <button className="btn btn-secondary btn-block">
                  <i className="fa fa-spinner fa-spin fa-fw" />
                </button>
              ))}

            {this.state.message && (
              <div
                className={`notification-box ${
                  this.state.errorResponse
                    ? 'notification-box-error'
                    : 'notification-box-success'
                }`}
              >
                {/* <div className="notification-title">Order </div> */}
                <p>{this.state.message}</p>
              </div>
            )}

            <Modal isOpen={this.state.modal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} close={closeBtn}>
                Do you want to sell this ?
              </ModalHeader>
              <ModalBody>
                <div className="col-md-12 faded">
                  {`Price: ${this.state.bidSell} ${this.props.activeMarketSymbol}`}
                </div>

                <div className="col-md-12 faded">
                  {`Amount: ${this.state.volumeSell} ${this.props.activeCurrencySymbol}`}
                </div>

                <div className="col-md-12 faded">
                  {`Total: ${this.state.totalSell} ${this.props.activeMarketSymbol}`}
                </div>
              </ModalBody>
              <ModalFooter>
                {sellResponse && sellResponse.error && (
                  <div className="negative">{sellResponse.message}</div>
                )}
                {sellResponse && sellResponse.success === 1 && (
                  <div className="positive">{sellResponse.message}</div>
                )}{' '}
                {!this.state.reloadButton && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleSellForm}
                  >
                    Sell
                  </button>
                )}
                {this.state.reloadButton && (
                  <button className="btn btn-secondary">
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  </button>
                )}{' '}
                <button className="btn btn-secondary" onClick={this.toggle}>
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}

        {!this.props.isLoggedIn && (
          <>
            <button
              className="btn btn-secondary btn-buy btn-block"
              style={{ background: '#6c757d' }}
              id="NotLoginSellPopover"
              onClick={this.togglePopOver}
            >
              Sell
            </button>
            <Popover
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target="NotLoginSellPopover"
              toggle={this.togglePopOver}
            >
              <PopoverBody>Please Login</PopoverBody>
            </Popover>
          </>
        )}
      </div>
    );
  }
}

SellOrderForm.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1,
  activeCurrencySymbol: 'ETH'
};

// SellOrderForm.defaultProps = {
//   color: '#d7a22d',
//   currency: 'BTCINRF'
// };

const mapStateToProps = state => {
  return {
    currencySell: state.limitForms.currencySell,
    volumeSell: state.limitForms.volumeSell,
    bidSell: state.limitForms.bidSell,
    operationSell: state.limitForms.operationSell,
    marketSell: state.limitForms.marketSell,
    totalSell: state.limitForms.totalSell,
    sellResponse: state.limitForms.sellResponse,
    isLoggedIn: state.signin.isLoggedIn,
    sellFormModal: state.limitForms.sellFormModal,
    getBuyOrdersList: state.liveOrders.getBuyOrdersList,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    getMyBalanceList: state.myOrders.getMyBalanceList
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      addSellOrder: formData => addSellOrder(formData),
      hideSellModal: formData => hideSellModal(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      selectedCurrencyUpdate: formData => selectedCurrencyUpdate(formData),
      sellLimitInputRemotelyChange: formData =>
        sellLimitInputRemotelyChange(formData),
      getMyOpenOrders: formData => getMyOpenOrders(formData),
      getMyOrderHistory: formData => getMyOrderHistory(formData),
      getMyTradeMetricsFunc: formData => getMyTradeMetricsFunc(formData),
      getMyBalance: formData => getMyBalance(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellOrderForm);
