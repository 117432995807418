/**
 * Main Transaction Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import { activeTabUpdate } from './referralReducer';
// import { headerActiveMenuUpdate } from 'App/appReducer';

// import ReferralBonus from './refBonus';
// import HowItWorks from './howItWorks';

// import ErrorBoundary from 'Modules/ErrorBoundary';
import { getReferralHistory, referalTransfer } from './referralReducer';
import _ from 'underscore';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Button,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  // PopoverHeader,
  PopoverBody
} from 'reactstrap';
import Cookies from 'universal-cookie';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const cookies = new Cookies();
let userId = cookies.get('globalId');

class ReferralSideBar extends React.Component {
  constructor(props) {
    super(props);
    //this.toggle = this.toggle.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    //this.checkConditions = this.checkConditions.bind(this);
    // this.getHighestAsset = this.getHighestAsset.bind(this);
    this.handleTransfer = this.handleTransfer.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = {
      referralHistoryLoader: true,
      refTransferLoader: true,
      refTransferSuccess: '',
      getReferralHistoryList: [],
      searchData: [],
      coinSymbolsObj: {},
      error: '',
      message: '',
      refEarnings: [],
      copied: false,
      sidebar_main_height: window.innerHeight - 60,
      dropdownOpen: false,
      type: this.props.type,
      refCode: this.props.refCode,
      amount: this.props.balance,
      asset: '',
      modal: false,
      popoverOpen: false,
      selectedTo: { value: '', label: '' },
      selectedFrom: { value: '', label: '' }
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this._onSelectTo = this._onSelectTo.bind(this);
    this._onSelectFrom = this._onSelectFrom.bind(this);
    if (this.props.type === 'internal') {
      this.options = [
        { value: 'referal', label: 'Referal' },
        { value: 'stackbook', label: 'Stackbook' },
        { value: 'exchange', label: 'Exchange' }
      ];
    } else {
      this.optionsFrom = [{ value: 'referal', label: 'Referal' }];
      this.optionsTo = [{ value: 'exchange', label: 'Exchange' }];
    }
    this.defaultOptionFrom = this.optionsFrom[0];
    this.defaultOptionTo = this.optionsTo[0];
  }

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  toggleModal() {
    // this.props.getReferralHistory();
    this.setState({
      modal: !this.state.modal,
      error: ''
    });
  }
  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  componentDidMount() {
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  handleTransfer(type) {
    // const { refCode } = this.props;
    if (type === 'internal') {
      // this.props.internalTransfer();
    } else {
      this.props.referalTransfer({
        coinId: this.props.currencyId,
        amount: this.state.amount
      });
      this.setState({
        amount: '',
        error: ''
      });
    }
    this.toggleModal();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.refTransferResponse !== undefined &&
      this.props.refTransferResponse !== nextProps.refTransferResponse
    ) {
      if ('error' in nextProps.refTransferResponse) {
        this.setState({
          refTransferLoader: false,
          error: nextProps.refTransferResponse.message
        });
      } else {
        this.setState({
          refTransferLoader: false,
          refTransferSuccess: nextProps.refTransferResponse.message
        });
      }
    }

    if (this.props.type !== nextProps.type) {
      this.setState({
        type: nextProps.type
      });
    }
    if (this.props.refCode !== nextProps.refCode) {
      this.setState({
        refCode: nextProps.refCode
      });
    }
    if (this.props.currencyId !== nextProps.currencyId) {
      this.setState({
        currencyId: nextProps.currencyId,
        amount: '',
        error: ''
      });
    }

    if (this.props.balance !== nextProps.balance) {
      this.setState({
        amount: nextProps.balance
      });
    }
  }
  handleAmountChange = e => {
    this.setState({ amount: e.target.value });
  };
  handleAssetChange = e => {
    this.setState({ asset: e.target.value });
  };
  _onSelectTo(option) {
    this.setState({ selectedTo: option });
  }

  _onSelectFrom(option) {
    this.setState({ selectedFrom: option });
  }

  handleOk() {
    this.props.getReferralHistory();
    this.toggleModal();
  }

  render() {
    const { coinSymbolsObj, currencyId, type } = this.props;
    const {
      error,
      // message,
      // dropdownOpen,
      // refCode,
      refTransferSuccess
    } = this.state;

    return (
      <div
        id="sidebar-main"
        className="row no-gutters sidebar-content pl-3 pr-3"
        style={{ height: this.state.sidebar_main_height }}
      >
        <div className="col-lg-12">
          <div className="currency-info">
            <h1 className="title pt-2 pb-2">
              {currencyId &&
                _.keys(coinSymbolsObj).length !== 0 &&
                coinSymbolsObj[currencyId].coinsymbol}
              {!_.keys(coinSymbolsObj).length !== 0 && ''}
            </h1>
          </div>
          <div className="sidebar-form">
            {type === 'internal' ? (
              <div>
                <div className="form-group">
                  <label>From</label>
                  <Dropdown
                    options={this.options}
                    onChange={this._onSelectFrom}
                    value={this.state.selectedFrom}
                    placeholder="Select an option"
                  />
                </div>
                <div className="form-group">
                  <label>To</label>
                  <Dropdown
                    options={this.options}
                    onChange={this._onSelectTo}
                    value={this.state.selectedTo}
                    placeholder="Select an option"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="form-group">
                  <label>From</label>
                  <Dropdown
                    options={this.optionsFrom}
                    onChange={this._onSelectFrom}
                    value={this.optionsFrom[0]}
                    disabled
                    // placeholder={"Select an option"}
                  />
                  {/* <label>Referal</label> */}
                </div>
                <div className="form-group">
                  <label>To</label>
                  <Dropdown
                    options={this.optionsTo}
                    onChange={this._onSelectTo}
                    value={this.optionsTo[0]}
                    disabled
                    // placeholder="Select an option"
                  />
                  {/* <label>Exchange</label> */}
                </div>
              </div>
            )}
            <div className="form-group">
              <label>Asset</label>
              <input
                type="text"
                className="form-control"
                placeholder="Bitcoin"
                value={
                  currencyId &&
                  _.keys(coinSymbolsObj).length !== 0 &&
                  coinSymbolsObj[currencyId].coinsymbol
                }
                onChange={this.handleAssetChange}
                // readOnly
                disabled
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                type="text"
                className="form-control"
                value={this.state.amount}
                onChange={this.handleAmountChange}
              />
            </div>
          </div>
          <div>
            {this.state.amount === '' && (
              <div>
                <button
                  type="submit"
                  className="btn btn-blue"
                  id="Popover1"
                  onClick={this.togglePopOver}
                >
                  Transfer
                </button>
                <Popover
                  placement="bottom"
                  isOpen={this.state.popoverOpen}
                  target="Popover1"
                  toggle={this.togglePopOver}
                >
                  <PopoverBody>Please enter amount.</PopoverBody>
                </Popover>
              </div>
            )}

            {this.state.amount !== '' && (
              <button
                type="submit"
                className="btn btn-blue"
                onClick={() => this.handleTransfer(type)}
              >
                Transfer
              </button>
            )}
            {error !== '' && (
              <Modal
                isOpen={this.state.modal}
                toggle={this.toggleModal}
                className={this.props.className}
                centered
              >
                <ModalBody>
                  {refTransferSuccess !== '' ? refTransferSuccess : error}
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{ width: '100px' }}
                    color="primary"
                    onClick={this.handleOk}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // changePage: page => push(page),
      // searchValueUpdate: formData => searchValueUpdate(formData),
      getReferralHistory: formData => getReferralHistory(formData),
      // activeTabUpdate: formData => activeTabUpdate(formData),
      // headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
      referalTransfer: formData => referalTransfer(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    activeTab: state.referral.activeTab,
    coinSymbolsObj: state.app.coinSymbolsObj,
    currencyId: state.referral.currencyId,
    refTransferResponse: state.referral.refTransferResponse,
    balance: state.referral.balance
    // isLoggedIn: state.signin.isLoggedIn
  }),
  mapDispatchToProps
)(ReferralSideBar);
