import React from 'react';
import { push } from 'connected-react-router';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';
import {
  headerActiveMenuUpdate,
  allCoinMarketData,
  allCoinsSymbolData
} from 'App/appReducer';
import CurrencySummary from './currencySummary';
import {
  activeTabUpdate,
  getPortfolio,
  getTotalValInMarket
} from './portFolioReducer';
import Dropdown from 'react-dropdown';
import CurrencySummaryDetails from './currencySummaryDetails';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this.fetchCommonMarkets = this.fetchCommonMarkets.bind(this);
    this.fetchMarkets = this.fetchMarkets.bind(this);
    //  this.calTotalInMarkets=this.calTotalInMarkets.bind(this);
    this.state = {
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      selected: { value: 0, label: 'BTC' },
      listingMarketId: '0',
      portfolioList: [],
      marketsInDropdown: [{ value: 0, label: 'BTC' }],
      coinSymbolsObj: {},
      totalInMarket: '',
      totalValError: false,
      message: '',
      totalValResponse: {}
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  /* calTotalInMarkets(){
  const {portfolioList}=this.state;
  let a={ portfolioList.reduce(function(sum, o) {
    return (
      parseFloat(sum) +
      parseFloat(o.totalInMarket) 
    .toFixed(8));
  }, 0)};
  return a;

} */

  fetchMarkets() {
    const allMarketCoins = [];
  }
  fetchCommonMarkets() {
    const allMarketCoins = [];
    const { portfolioList, coinsMarketsListObj } = this.state;
    if (coinsMarketsListObj) {
      const mIds1 = [];
      let mIds = [];
      portfolioList.map((o, i) => {
        if (coinsMarketsListObj[o.coinId]) {
          const mIds2 = _.keys(coinsMarketsListObj[o.coinId].markets).map(
            key => {
              return parseInt(key);
            }
          );
          if (mIds.length === 0) {
            mIds = mIds2;
          } else {
            mIds = _.intersection(mIds, mIds2);
          }
          // mIds.push(_.keys(coinsMarketsListObj[o.coinId].markets))
        } else {
          mIds1.push(o.coinId);
        }
      });
      mIds = _.uniq(mIds.concat(mIds1));

      if (this.state.coinSymbolsObj && mIds.length > 0) {
        mIds.map((o, i) => {
          allMarketCoins.push({
            value: o,
            label: this.state.coinSymbolsObj[o].coinsymbol
          });
        });
        this.setState({
          marketsInDropdown: allMarketCoins
        });
      }
    }
  }
  componentDidMount() {
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    } else {
      this.props.headerActiveMenuUpdate('portfolio');
      this.setState({
        allMarketCoinsListObj: this.props.allMarketCoinsListObj,
        listingMarketId: this.props.activeMarketValue,
        coinSymbolsObj: this.props.coinSymbolsObj

        //coinsMarketsListObj: this.props.coinsMarketsListObj
      });
      this.props.getPortfolio({ marketId: this.state.listingMarketId });
    }
  }

  _onSelect(option) {
    this.setState({
      listingMarketId: option.value
    });
    this.setState({ selected: option });
    this.props.getTotalValInMarket({
      marketId: option.value
    });
    //this.props.getPortfolio({ marketId: option.value });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }
    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }
    if (this.props.coinsMarketsListObj !== nextProps.coinsMarketsListObj) {
      this.setState({ coinsMarketsListObj: nextProps.coinsMarketsListObj });
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
    if (this.props.marketId !== nextProps.marketId) {
      this.setState({
        listingMarketId: nextProps.marketId
      });
    }
    if (this.props.portfolioList !== nextProps.portfolioList) {
      if (nextProps.portfolioList.error === 0) {
        if (nextProps.portfolioList.data.coins.length !== 0) {
          this.setState({
            portfolioList: nextProps.portfolioList.data.coins,
            totalValueInMarket: nextProps.portfolioList.data.totalValueInMarket,
            error: null
          });
        } else {
          this.setState({
            portfolioList: [],
            totalValueInMarket: null,
            error: nextProps.portfolioList.message
          });
        }
      } else {
        this.setState({
          portfolioList: [],
          totalValueInMarket: null,
          error: nextProps.portfolioList.message
        });
      }
    }

    if (this.props.totalValResponse !== nextProps.totalValResponse) {
      if (nextProps.totalValResponse.error == 0) {
        this.setState({
          totalValResponse: nextProps.totalValResponse,
          totalValueInMarket: nextProps.totalValResponse.data,
          totalValError: false,
          message: ''
        });
      } else {
        this.setState({
          totalValueInMarket: '',
          totalValError: true,
          message: nextProps.totalValResponse.message
        });
      }
    }
    /*  if (
      nextProps.portfolioList &&
      nextProps.portfolioList.data != undefined &&
      this.props.portfolioList.data !== nextProps.portfolioList.data &&
      nextProps.portfolioList.error == 0
    ) {
      if (nextProps.portfolioList.data.length !== 0) {
        this.setState({
          portfolioList: nextProps.portfolioList.data,
          error: ''
        });
      } else {
        this.setState({
          portfolioList: [],
          error: ''
        });
      }
    } else if (nextProps.portfolioList.error == 1) {
      this.setState({
        portfolioList: [],
        error: nextProps.portfolioList.message
      });
    } */
  }

  render() {
    return (
      <div className="row no-gutters">
        <div className="col-lg-12 card-box container-one pl-2 pr-1 mt-1">
          {/*   <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '1' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('1');
                }}
                active={(this.props.activeTab === '1').toString()}
                aria-selected={this.props.activeTab === '1'}
              >
                Exchange
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '2' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('2');
                }}
                active={(this.props.activeTab === '2').toString()}
                aria-selected={this.props.activeTab === '2'}
              >
                Margin trading
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '3' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('3');
                }}
                active={(this.props.activeTab === '3').toString()}
                aria-selected={this.props.activeTab === '3'}
              >
                Stackbook
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '4' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('4');
                }}
                active={(this.props.activeTab === '4').toString()}
                aria-selected={this.props.activeTab === '4'}
              >
                Lending
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  this.props.activeTab === '5' ? 'nav-link active' : 'nav-link'
                }
                onClick={() => {
                  this.toggle('5');
                }}
                active={(this.props.activeTab === '5').toString()}
                aria-selected={this.props.activeTab === '5'}
              >
                Direct
              </a>
            </li>
          </ul> */}
          {this.state.portfolioList.length != 0 ? (
            <div className="actions-container mt-2">
              <div className="dropdown show mt-3 mb-3 ml-2">
                <span>
                  Total value{' '}
                  {this.state.totalValueInMarket !== null &&
                    parseFloat(this.state.totalValueInMarket).toFixed(8)}
                  {/* {this.state.portfolioList.reduce(function(sum, o) {
                    return (
                      parseFloat(sum) + parseFloat(o.totalInMarket)
                    ).toFixed(8);
                  }, 0)} */}
                </span>
                <Dropdown
                  options={this.state.allMarketCoinsListObj}
                  onChange={this._onSelect}
                  value={this.state.selected}
                  placeholder={this.state.selected}
                  className="portfolioRoot mt-1"
                  // controlClassName='portfolioControl'
                  // placeholderClassName='portfolioPlaceholder'
                  // menuClassName='portfolioMenu'
                />
              </div>
            </div>
          ) : (
            ''
          )}

          {/*  <div className="dropdown-menu" aria-labe lledby="dropdownMenuLink">
              {   <a className="dropdown-item" href="#">
                  Content
                </a> }
              </div> */}
          {this.props.activeTab === '1' && (
            <ErrorBoundary>
              <CurrencySummaryDetails
                portfolioList={this.state.portfolioList}
                marketId={this.state.listingMarketId}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
    );
  }
}
Portfolio.defaultProps = {
  currency: 'BTCINRF',
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH'
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      getPortfolio: formData => getPortfolio(formData),
      allCoinMarketData: formData => allCoinMarketData(formData),
      allCoinsSymbolData: formData => allCoinsSymbolData(formData),
      getTotalValInMarket: formData => getTotalValInMarket(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.portfolio.activeTab,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    coinsMarketsListObj: state.app.coinsMarketsListObj,
    portfolioLoader: state.portfolio.portfolioLoader,
    portfolioList: state.portfolio.portfolioList,
    coinSymbolsObj: state.app.coinSymbolsObj,
    marketId: state.portfolio.marketId,
    totalValLoader: state.portfolio.totalValLoader,
    totalValResponse: state.portfolio.totalValResponse
  }),
  mapDispatchToProps
)(Portfolio);
