/**
 * Main Deposit Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Popover,
  // PopoverHeader,
  PopoverBody
} from 'reactstrap';
import {
  getDataAverages,
  getFilterdedTradeData,
  setDates,
  setOperation,
  selectedMarketUpdate,
  tradeAnalysisAutosuggestCurrencyIdUpdateUpdate,
  setActivePage
} from './openOrdersReducer';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Autosuggest from './historyAutosuggest';
import _ from 'underscore';

class TradeAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketViewLoader: true,
      amount: '',
      getMarketViewList: {},
      searchData: {},
      marketCrptoArray: [],
      activeTab: 0,
      error: null,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      generatingAddress: false,
      addressResponseMessage: null,
      dataAnalysisLoader: true,
      address: '',
      addressData: {},
      selectedCurrency: this.props.selectedCurrency,
      coinLoader: true,
      coinReqSuccess: '',
      modal: false,
      popoverOpen: false,
      email: '',
      copied: false,
      selected: this.props.selected,
      // selected: { value: 'select', label: 'Select' },
      // selectedBuySell: { value: 'select', label: 'Select' },
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo,
      fromForDatePicker: this.props.fromForDatePicker,
      toForDatePicker: this.props.toForDatePicker,
      getDataAvg: [],
      tradeSearchDataLoader: true,
      tradeSearchDataList: {},
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      listingMarketId: this.props.selectedMarket,
      autoSuggestForMarketsCoinSymbol: this.props
        .autoSuggestForMarketsCoinSymbol,
      tradeAnalysisAutosuggestCurrencyIdUpdate: this.props
        .tradeAnalysisAutosuggestCurrencyIdUpdate,
      coinSymbolsObj: this.props.coinSymbolsObj,
      disabled: this.props.disabled,
      popoverOpen: false,
      selectedBuySell: this.props.selectedBuySell,
      operation: this.props.operation
    };
    //this.getAddressFunc = this.getAddressFunc.bind(this);
    //this.requestCoin = this.requestCoin.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this._onSelectBuySell = this._onSelectBuySell.bind(this);
    this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
    this.onChangeDateTo = this.onChangeDateTo.bind(this);
    this.onAnalysis = this.onAnalysis.bind(this);
    this.handleCoinChange = this.handleCoinChange.bind(this);
    /*   this.options = [
      { value: 'btc', label: 'BTC' },
      { value: 'eth', label: 'ETH' },
      { value: 'lsk', label: 'LSK' }
    ];*/
    this.optionsBuySell = [
      { value: 'buyAndSell', label: 'Buy & Sell' },
      { value: 'buy', label: 'Buy' },
      { value: 'sell', label: 'Sell' }
    ];
    //this.defaultOption = this.options[0];
    this.defaultOptionBuySell = this.optionsBuySell[0];
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  _onSelect(option) {
    this.props.selectedMarketUpdate({
      selectedMarket: option.value,
      selected: option,
      disabled: false
    });
    this.setState({ selected: option });
    // if(option.value>=0){
    //   this.setState({
    //     disabled: false
    //   })
    // }
  }
  /*  _onSelectBuySell(option) {
    this.setState({ selectedBuySell: option });
  } */

  _onSelectBuySell(option) {
    this.setState({
      selectedBuySell: option,
      operation: option.value == 'buy' ? 1 : option.value == 'sell' ? 0 : ''
    });
  }
  /* validateDate(date){
    if(moment(this.state.dateFrom).format('YYYY-MM-DD HH:mm').is)
  } */

  onChangeDateFrom = date =>
    this.setState({ dateFrom: date, fromForDatePicker: date });
  onChangeDateTo = date =>
    this.setState({ dateTo: date, toForDatePicker: date });

  onAnalysis() {
    const {
      dateFrom,
      dateTo,
      operation,
      listingMarketId,
      tradeAnalysisAutosuggestCurrencyIdUpdate,
      selectedBuySell
    } = this.state;
    const form = {};
    if (listingMarketId >= 0 && listingMarketId !== '') {
      form.marketId = listingMarketId;
    }
    if (listingMarketId >= 0 && listingMarketId !== '') {
      form.marketId = listingMarketId;
    }
    if (
      tradeAnalysisAutosuggestCurrencyIdUpdate >= 0 &&
      tradeAnalysisAutosuggestCurrencyIdUpdate !== ''
    ) {
      form.coinId = tradeAnalysisAutosuggestCurrencyIdUpdate;
    }
    if (operation !== '') {
      form.operation = operation;
    }
    if (dateFrom != null && dateTo != null) {
      this.props.setDates({
        dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD 00:00'),
        dateTo: moment(this.state.dateTo).format('YYYY-MM-DD 23:59'),
        fromForDatePicker: this.state.fromForDatePicker,
        toForDatePicker: this.state.toForDatePicker
      });
      form.start = moment(this.state.dateFrom).format('YYYY-MM-DD 00:00');
      form.end = moment(this.state.dateTo).format('YYYY-MM-DD 23:59');
    }
    form.skip = 0;
    form.limit = this.props.limit;
    this.props.setOperation({
      operation: operation,
      selectedBuySell: selectedBuySell
    });
    this.props.setActivePage({
      activePage: 1
    });
    this.props.getFilterdedTradeData(form);
    if (listingMarketId >= 0 && tradeAnalysisAutosuggestCurrencyIdUpdate >= 0) {
      if (dateFrom != null && dateTo != null) {
        this.props.getDataAverages({
          coinId: tradeAnalysisAutosuggestCurrencyIdUpdate,
          marketId: listingMarketId,
          start: moment(this.state.dateFrom).format('YYYY-MM-DD 00:00'),
          end: moment(this.state.dateTo).format('YYYY-MM-DD 23:59')
        });
      } else {
        this.props.getDataAverages({
          coinId: tradeAnalysisAutosuggestCurrencyIdUpdate,
          marketId: listingMarketId
        });
      }
    }
  }

  // componentWillUnmount(){
  //   this.props.tradeAnalysisAutosuggestCurrencyIdUpdateUpdate({
  //     tradeAnalysisAutosuggestCurrencyIdUpdate: '-1'
  //   });
  // }
  componentDidMount() {
    this.setState({
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      listingMarketId: this.props.selectedMarket
    });

    // this.props.getDataAverages({
    //   coinId: '',
    //   marketId: ''
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.dataAnalysisLoader !== nextProps.dataAnalysisLoader) {
      this.setState({ dataAnalysisLoader: nextProps.dataAnalysisLoader });
    }
    if (
      nextProps.getDataAvg !== undefined &&
      this.props.getDataAvg !== nextProps.getDataAvg
    ) {
      this.setState({
        getDataAvg: nextProps.getDataAvg.data
      });
    }

    if (this.props.tradeSearchDataLoader !== nextProps.tradeSearchDataLoader) {
      this.setState({ tradeSearchDataLoader: nextProps.tradeSearchDataLoader });
    }

    if (
      this.props.autoSuggestForMarketsCoinSymbol !==
      nextProps.autoSuggestForMarketsCoinSymbol
    ) {
      this.setState({
        autoSuggestForMarketsCoinSymbol:
          nextProps.autoSuggestForMarketsCoinSymbol
      });
    }

    if (
      nextProps.tradeSearchDataList !== undefined &&
      this.props.tradeSearchDataList !== nextProps.tradeSearchDataList
    ) {
      this.setState({
        tradeSearchDataList: nextProps.tradeSearchDataList
      });
    }

    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }

    if (this.props.selectedMarket !== nextProps.selectedMarket) {
      this.setState({
        listingMarketId: nextProps.selectedMarket,
        disabled: nextProps.disabled
      });
    }
    if (
      this.props.tradeAnalysisAutosuggestCurrencyIdUpdate !==
      nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate
    ) {
      this.setState({
        tradeAnalysisAutosuggestCurrencyIdUpdate:
          nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate,
        getDataAvg: []
      });
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.limit !== nextProps.limit) {
      this.setState({ limit: nextProps.limit });
    }

    if (this.props.operation !== nextProps.operation) {
      this.setState({ operation: nextProps.operation });
    }

    if (this.props.selected !== nextProps.selected) {
      this.setState({ selected: nextProps.selected });
    }

    if (this.props.selectedBuySell !== nextProps.selectedBuySell) {
      this.setState({ selectedBuySell: nextProps.selectedBuySell });
    }
    if (this.props.dateFrom !== nextProps.dateFrom) {
      this.setState({ dateFrom: nextProps.dateFrom });
    }

    if (this.props.dateTo !== nextProps.dateTo) {
      this.setState({ dateTo: nextProps.dateTo });
    }
    if (this.props.fromForDatePicker !== nextProps.fromForDatePicker) {
      this.setState({ fromForDatePicker: nextProps.fromForDatePicker });
    }

    if (this.props.toForDatePicker !== nextProps.toForDatePicker) {
      this.setState({ toForDatePicker: nextProps.toForDatePicker });
    }
  }

  handleCoinChange() {
    this.setState({
      selectedCoin: this.state.autoSuggestForMarketsCoinSymbol[
        this.state.listingMarketId
      ][this.state.coinId]
    });
  }

  render() {
    const {
      autoSuggestForMarketsCoinSymbol,
      getDataAvg,
      listingMarketId,
      tradeAnalysisAutosuggestCurrencyIdUpdate,
      coinSymbolsObj
    } = this.state;

    return (
      <div className="col-lg-3 mt-1">
        <div
          style={{ height: window.innerHeight - 130 }}
          id="sidebar-main"
          className="row no-gutters sidebar-content"
        >
          <div className="col-lg-12">
            <div className="currency-info">
              <h1 className="title pt-2 pb-2">Analysis</h1>
            </div>
            <div className="row no-gutters">
              <div className="col-md-12">
                {getDataAvg &&
                getDataAvg.length !== 0 &&
                tradeAnalysisAutosuggestCurrencyIdUpdate >= 0 ? (
                  _.keys(this.state.coinSymbolsObj).length > 0 && (
                    <table className="table info-table card-box mb-0">
                      {getDataAvg.map((o, i) => (
                        <tbody id="market-information" key={i}>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Total buys
                            </td>
                            <td className="col-md-6 text-right data">
                              {parseFloat(o.totalbuys).toFixed(8)}{' '}
                              <span className="currency">
                                {' '}
                                {`${this.state.coinSymbolsObj[tradeAnalysisAutosuggestCurrencyIdUpdate].coinsymbol}`}
                              </span>
                            </td>
                          </tr>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Avg buy price
                            </td>
                            <td className="col-md-6 text-right data">
                              {parseFloat(o.averagebuyprice).toFixed(8)}{' '}
                              <span className="currency">{`${this.state.coinSymbolsObj[listingMarketId].coinsymbol}`}</span>
                            </td>
                          </tr>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Total sell
                            </td>
                            <td className="col-md-6 text-right data">
                              {parseFloat(o.totalsells).toFixed(8)}{' '}
                              <span className="currency">{`${this.state.coinSymbolsObj[tradeAnalysisAutosuggestCurrencyIdUpdate].coinsymbol}`}</span>
                            </td>
                          </tr>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Avg sell price
                            </td>
                            <td className="col-md-6 text-right data">
                              {parseFloat(o.averagesellprice).toFixed(8)}{' '}
                              <span className="currency">{`${this.state.coinSymbolsObj[listingMarketId].coinsymbol}`}</span>
                            </td>
                          </tr>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Break even price
                            </td>
                            <td className="col-md-6 text-right data">
                              {o.breakevenprice}{' '}
                              <span className="currency">{`${this.state.coinSymbolsObj[listingMarketId].coinsymbol}`}</span>
                            </td>
                          </tr>
                          <tr className="row no-gutters">
                            <td className="col-md-6 text-left title">
                              Profit/Loss
                            </td>
                            <td
                              className={
                                o.profitloss >= 0
                                  ? 'col-md-6 text-right data positive-text'
                                  : 'col-md-6 text-right data negative-text'
                              }
                            >
                              {parseFloat(o.profitloss).toFixed(8)}{' '}
                              <span className="currency">{`${this.state.coinSymbolsObj[tradeAnalysisAutosuggestCurrencyIdUpdate].coinsymbol}`}</span>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  )
                ) : (
                  <table className="table info-table card-box">
                    <tbody id="market-information">
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">Total buys</td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">
                          Avg buy price
                        </td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">Total sell</td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">
                          Avg sell price
                        </td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">
                          Break even price
                        </td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                      <tr className="row no-gutters">
                        <td className="col-md-6 text-left title">
                          Profit/Loss
                        </td>
                        <td className="col-md-6 text-right data">
                          - <span className="currency" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="col-md-11 sidebar-element">
                <div className="row pt-2 mb-2">
                  <div className="col-md-6 text-left" id="TooltipExample">
                    {/* <label>From</label> */}
                    <DatePicker
                      // className="from-date"
                      onChange={this.onChangeDateFrom}
                      // value={this.state.dateFrom}
                      // todayButton={'ThroughBit'}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="From (DD/MM/YYYY)"
                      className="blackColor datepickerNew"
                      width="30%"
                      selected={this.state.fromForDatePicker}
                      isClearable={true}
                    />
                  </div>
                  <div
                    className="col-md-6 text-right"
                    id="datepicker-triangle-id"
                  >
                    <div id="TooltipExample">
                      {/* <label>To</label> */}
                      <DatePicker
                        onChange={this.onChangeDateTo}
                        // value={this.state.dateTo}
                        // todayButton={'ThroughBit'}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="To (DD/MM/YYYY)"
                        className="blackColor datepickerNew"
                        width="30%"
                        selected={this.state.toForDatePicker}
                        isClearable={true}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                            boundariesElement: 'viewport'
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-6 text-left">
                    <div className="row no-gutters">
                      <label>Choose pair:</label>
                    </div>
                    <div className="row">
                      {!this.state.disabled ? (
                        <div
                          className=" col-md-6 pr-0 .disabled"
                          disabled={this.state.disabled}
                        >
                          <div className="form-group">
                            {autoSuggestForMarketsCoinSymbol &&
                              listingMarketId !== '' &&
                              autoSuggestForMarketsCoinSymbol[
                                listingMarketId
                              ] && (
                                <Autosuggest
                                  reactMarketCoinSelect={
                                    autoSuggestForMarketsCoinSymbol[
                                      listingMarketId
                                    ]
                                  }
                                />
                              )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6 pr-0">
                          <div className="form-group">
                            <input
                              id="Popover1"
                              style={{
                                width: '80%',
                                height: 34
                              }}
                              onClick={this.togglePopOver}
                              value=""
                            />
                            <Popover
                              placement="bottom"
                              isOpen={this.state.popoverOpen}
                              target="Popover1"
                              toggle={this.togglePopOver}
                            >
                              <PopoverBody>
                                Please select Market first.
                              </PopoverBody>
                            </Popover>
                            {/* {autoSuggestForMarketsCoinSymbol &&
                        listingMarketId!=="" &&
                          autoSuggestForMarketsCoinSymbol[
                          listingMarketId
                          ] && (
                            <Autosuggest
                              reactMarketCoinSelect={
                                autoSuggestForMarketsCoinSymbol[
                                listingMarketId
                                ]
                              }
                            />
                          )}                       */}
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 pl-0">
                        <Dropdown
                          options={this.state.allMarketCoinsListObj}
                          onChange={this._onSelect}
                          value={this.state.selected}
                          placeholder={this.state.selected}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-right">
                    <div className="row no-gutters">
                      <label>Choose action</label>
                    </div>
                    <div className="form-group">
                      <Dropdown
                        options={this.optionsBuySell}
                        onChange={this._onSelectBuySell}
                        value={this.state.selectedBuySell}
                        placeholder={this.state.selectedBuySell}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-blue"
              onClick={this.onAnalysis}
            >
              Analyse
            </button>
          </div>
        </div>
      </div>
    );
  }
}
TradeAnalysis.defaultProps = {
  currency: 'BTCINRF',
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH'
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataAverages: formData => getDataAverages(formData),
      getFilterdedTradeData: formData => getFilterdedTradeData(formData),
      setDates: formData => setDates(formData),
      selectedMarketUpdate: formData => selectedMarketUpdate(formData),
      setOperation: formData => setOperation(formData),
      tradeAnalysisAutosuggestCurrencyIdUpdateUpdate: formData =>
        tradeAnalysisAutosuggestCurrencyIdUpdateUpdate(formData),
      setActivePage: formData => setActivePage(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    // marketViewLoader: state.marketView.marketViewLoader,
    // getMarketViewList: state.marketView.getMarketViewList,
    // searchValue: state.marketView.searchValue
    dataAnalysisLoader: state.openOrders.dataAnalysisLoader,
    // addressData: state.transactions.addressData,
    // selectedCurrency: state.transactions.selectedCurrency,
    // coinreqResponse: state.transactions.coinreqResponse,
    getDataAvg: state.openOrders.getDataAvg,
    tradeSearchDataList: state.openOrders.tradeSearchDataList,
    tradeSearchDataLoader: state.openOrders.tradeSearchDataLoader,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    autoSuggestForMarketsCoinSymbol: state.app.autoSuggestForMarketsCoinSymbol,
    tradeAnalysisAutosuggestCurrencyIdUpdate:
      state.openOrders.tradeAnalysisAutosuggestCurrencyIdUpdate,
    // coinId: state.openOrders.coinId,
    // marketId: state.openOrders.marketId,
    coinSymbolsObj: state.app.coinSymbolsObj,
    limit: state.openOrders.limit,
    selectedMarket: state.openOrders.selectedMarket,
    selected: state.openOrders.selected,
    disabled: state.openOrders.disabled,
    dateFrom: state.openOrders.dateFrom,
    dateTo: state.openOrders.dateTo,
    fromForDatePicker: state.openOrders.fromForDatePicker,
    toForDatePicker: state.openOrders.toForDatePicker,
    selectedBuySell: state.openOrders.selectedBuySell
  }),
  mapDispatchToProps
)(TradeAnalysis);
