import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatePassword } from './updatePasswordReducer';
import { push } from 'connected-react-router';
import NavBar from './navBar';
import PasswordUpdated from './passwordUpdated';

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      showMessage: false,
      message: '',
      error: '',
      pwdStrength: '',
      pwdStrengthClass: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.validate = this.validate.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const loc = window.location.href;
    // console.log('loc - ', loc);
    var pos = loc.indexOf('?');
    const hash = loc.substring(pos + 1 + 5);
    this.setState({ hash });
    // console.log('hash - ', hash);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.updatePasswordResponse !== nextProps.updatePasswordResponse
    ) {
      this.setState(
        { updatePasswordResponse: nextProps.updatePasswordResponse },
        this.updatePasswordResponseCheck
      );
    }
  }

  updatePasswordResponseCheck() {
    const { updatePasswordResponse } = this.state;
    if (updatePasswordResponse.error === 0) {
      this.setState({
        showMessage: true,
        message: updatePasswordResponse.message,
        error: ''
      });
    } else {
      // this.setState({ error: updatePasswordResponse.message });
      this.setState({
        showMessage: false,
        message: '',
        error: updatePasswordResponse.message
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validate()) {
      if (this.state.confirmPassword === this.state.password) {
        this.setState({ error: '' });
        this.setState({ showMessage: false, message: '' });
        this.props.updatePassword({
          hash: this.state.hash,
          password: this.state.password
        });
        this.props.changePage('/passwordUpdated');
      } else {
        this.setState({ showMessage: false, message: '' });
        this.setState({
          error: 'Password and Confirm password fields must be same'
        });
      }
    }
  }

  validate() {
    if (!this.state.password) {
      this.setState({ error: 'Please enter password' });
      return false;
    }

    if (this.state.password.length < 8) {
      this.setState({ error: 'Password should be at least 8 characters' });
      return false;
    }

    if (this.state.pwdStrength == 'Weak') {
      this.setState({
        error:
          'Password is weak.Password should at least contain one capital letter, one small letter and one numeric'
      });
      return false;
    }

    if (!this.state.confirmPassword) {
      this.setState({ error: 'Please enter confirm password' });
      return false;
    }

    this.setState({ error: '' });
    return true;
  }

  handlePassword(e) {
    this.setState({
      password: e.target.value,
      showMessage: false
    });

    this.passwordChanged(e);
    // if(this.state.password)
  }

  passwordChanged(e) {
    // let strongRegex = new RegExp(
    //   '^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
    //   'g'
    // );

    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})'
    );

    let mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

    // let mediumRegex = new RegExp(
    //   '^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
    //   'g'
    // );
    let enoughRegex = new RegExp('(?=.{6,}).*', 'g');

    var pwd = e.target;

    if (pwd.value.length == 0) {
      this.setState({
        pwdStrength: ''
      });
    } else if (false == enoughRegex.test(pwd.value) || pwd.value.length < 8) {
      this.setState({
        pwdStrength: 'Password should be at least 8 characters',
        pwdStrengthClass: 'blackColor'
      });
    } else if (strongRegex.test(pwd.value)) {
      this.setState({
        pwdStrength: 'Strong',
        pwdStrengthClass: 'greenColor'
      });
    } else if (mediumRegex.test(pwd.value)) {
      this.setState({
        pwdStrength: 'Medium',
        pwdStrengthClass: 'blueColor'
      });
    } else {
      this.setState({
        pwdStrength: 'Weak',
        pwdStrengthClass: 'redColor'
      });
    }
  }

  setPassword(e) {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      this.setState({ password: '' });
    }
  }
  setConfirmPassword(e) {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      this.setState({ confirmPassword: '' });
    }
  }
  toggle(changePageLocation) {
    this.props.changePage(changePageLocation);
  }

  render() {
    return (
      // <div className="container-fluid">
      <>
        <NavBar />
        <div
          className="jumbotron d-flex align-items-center"
          style={{ height: '85vh' }}
        >
          <div className="container text-center">
            <div className="row no-gutters justify-content-center settings-form">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="title">Update Password</div>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group mt-4 mb-4">
                    <input
                      className="form-control mb-2"
                      value={this.state.password}
                      onKeyDown={this.setPassword}
                      onChange={e => this.handlePassword(e)}
                      type="password"
                      name="Password"
                      placeholder="New password"
                      required
                      title={this.state.pwdStrength}
                    />

                    <input
                      className="form-control mt-4"
                      value={this.state.confirmPassword}
                      onKeyDown={this.setConfirmPassword}
                      onChange={e =>
                        this.setState({
                          confirmPassword: e.target.value,
                          showMessage: false
                        })
                      }
                      type="password"
                      name="Password"
                      placeholder="Confirm new password"
                      required
                    />

                    {this.state.error !== '' ? (
                      <div className="error" style={{ color: '#f3136a' }}>
                        {this.state.error}
                      </div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-blue"
                    onClick={this.handleSubmit}
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePassword: formData => updatePassword(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    updatePasswordResponse: state.updatePassword.updatePasswordResponse
  }),
  mapDispatchToProps
)(UpdatePassword);
