/**
 * Sign Up Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerNew';
import apiCallerSignIn from 'Utils/apiCallerSignin';

const SIGN_UP_REQUESTED = 'SIGN_UP_REQUESTED';
const ADD_REGISTER_RESPONSE = 'ADD_REGISTER_RESPONSE';
const GENERATE_CODE = 'GENERATE_CODE';

const initialState = {
  registerResponse: {},
  isFormProcessing: false,
  generatecodeResponse: ''
};

/**
 *@event
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_REQUESTED:
      return {
        ...state,
        isFormProcessing: true
      };
    case ADD_REGISTER_RESPONSE:
      return {
        ...state,
        isFormProcessing: false,
        registerResponse: action.response
      };
    case GENERATE_CODE:
      return {
        ...state,
        isFormProcessing: false,
        generatecodeResponse: action.response
      };
    default:
      return state;
  }
};
/**
 * Sign in Action
 *@event (form Data) => {email, name ,mobilnumber}
 */
export const signup = formData => dispatch => {
  dispatch({
    type: SIGN_UP_REQUESTED
  });

  apiCallerSignIn('signup', 'post', formData, true, false, false).then(
    response =>
      dispatch({
        type: ADD_REGISTER_RESPONSE,
        response
      })
  );
};

export const generateCode = () => dispatch => {
  apiCaller('assignreferal', 'get', '', false, false, false).then(response =>
    dispatch({
      type: GENERATE_CODE,
      response
    })
  );
};
