/**
 * Buy Crypto Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import _ from 'underscore';
import removeCookie from 'Utils/removeCookie';
import balanceFormat from 'Utils/balanceFormat';

import { logout } from 'Modules/signin/signinReducer';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap';

import {
  addBuyOrder,
  buyStopLimitInputRemotelyChange
} from './stopLimitReducer';
import { selectedCurrencyUpdate } from 'Modules/withdrawdeposit/withdrawDepositReducer';
import {
  getMyBalance,
  getMyOpenOrders,
  getMyOrderHistory,
  getMyTradeMetricsFunc
} from '../myOrders/myOrdersReducer';

const cookies = new Cookies();
let email = cookies.get('email');

/**
 * Represents a Buy Crypto Class.
 * @class extends React.Component
 */
class BuyOrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyBuy: 1,
      marketBuy: 0,
      operationBuy: 1,
      limitBuy: this.props.limitBuy,
      stopBuy: this.props.stopBuy,
      volumeStopBuy: this.props.volumeStopBuy,
      totalStopBuy: this.props.totalStopBuy,

      buyFormModal: this.props.buyFormModal,
      success: false,
      message: false,
      errorResponse: false,
      confirm: false,
      orderid: '',
      txnid: '',
      myBalanceList: this.props.getMyBalanceList,
      buyResponse: {},
      reloadButton: false,
      modal: false,
      popoverOpen: false,
      isLoggedIn: this.props.isLoggedIn,
      myBalanceAmount: 0,
      activeMarketValue: this.props.activeMarketValue,
      activeMarketSymbol: this.props.activeMarketSymbol
    };
    this.toggle = this.toggle.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
  }

  componentDidMount() {
    this.currencyBalanceFormatFunc();
  }

  componentWillReceiveProps(nextProps) {
    //Handle The Response IF Recieved Balance Data
    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState(
        { myBalanceList: nextProps.getMyBalanceList },
        this.currencyBalanceFormatFunc
      );
    }

    if (this.props.activeMarketSymbol !== nextProps.activeMarketSymbol) {
      this.setState(
        { activeMarketSymbol: nextProps.activeMarketSymbol },
        this.currencyBalanceFormatFunc
      );
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      this.setState(
        { activeMarketValue: nextProps.activeMarketValue },
        this.currencyBalanceFormatFunc
      );
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
    }

    if (this.props.stopBuy !== nextProps.stopBuy) {
      this.setState({ stopBuy: nextProps.stopBuy });
    }

    if (this.props.limitBuy !== nextProps.limitBuy) {
      this.setState({ limitBuy: nextProps.limitBuy });
    }

    if (this.props.volumeStopBuy !== nextProps.volumeStopBuy) {
      this.setState({ volumeStopBuy: nextProps.volumeStopBuy });
    }

    if (this.props.totalStopBuy !== nextProps.totalStopBuy) {
      this.setState({ totalStopBuy: nextProps.totalStopBuy });
    }

    if (this.props.buyResponse !== nextProps.buyResponse) {
      this.setState(
        {
          reloadButton: false,
          buyResponse: nextProps.buyResponse,
          message: nextProps.buyResponse.message,
          errorResponse: nextProps.buyResponse.error
        },
        this.checkBuyResponse
      );
    }
  }

  handleInputChange = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.validity.valid ? e.target.value : this.state[name];
    this.setState(
      {
        [name]: value
      },
      this.handleTotalChange(name, value)
    );
  };

  /**
   * Handles The Amount change
   * @function
   * @param {string} --> User Entered Amount
   */
  handleTotalChange = (name, value) => {
    var { limitBuy, volumeStopBuy, totalStopBuy } = this.state;

    if (name === 'limitBuy') {
      totalStopBuy = Number(value * volumeStopBuy).toFixed(8) || '';
    } else if (name === 'volumeStopBuy') {
      totalStopBuy = Number(limitBuy * value).toFixed(8) || '';
    } else if (name === 'totalStopBuy' && limitBuy > 0) {
      volumeStopBuy = Number(value / limitBuy).toFixed(8) || '';
    }

    this.setState(
      {
        limitBuy,
        volumeStopBuy,
        totalStopBuy
      },
      this.handleInputRemotely
    );
  };

  handleAmountBalanceChange = totalStopBuy => {
    if (totalStopBuy) {
      this.setState({ totalStopBuy }, this.handleInputRemotely);
    }
  };

  handleInputRemotely = () => {
    const {
      limitBuy,
      stopBuy,
      volumeStopBuy,
      totalStopBuy,
      currencyBuy,
      operationBuy,
      marketBuy
    } = this.state;
    this.props.buyStopLimitInputRemotelyChange({
      limitBuy: limitBuy,
      stopBuy: stopBuy,
      volumeStopBuy: volumeStopBuy,
      totalStopBuy: totalStopBuy,
      marketBuy: this.props.activeMarketValue,
      currencyBuy: this.props.activeCurrencyValue,
      // currencyBuy: currencyBuy,
      operationBuy: operationBuy
      // marketBuy: marketBuy,
    });
  };

  /**
   * Handles The Buy Confirm
   * @function
   * @param {object} --> Click event
   */

  handleBuyForm = e => {
    e.preventDefault();
    this.setState({
      reloadButton: true
    });
    const {
      limitBuy,
      stopBuy,
      volumeStopBuy,
      currencyBuy,
      operationBuy,
      marketBuy
    } = this.state;
    this.props.addBuyOrder({
      limit: limitBuy,
      stop: stopBuy,
      volume: volumeStopBuy,
      marketId: this.props.activeMarketValue,
      coinId: this.props.activeCurrencyValue,
      operation: operationBuy
      // currency: currencyBuy,
      // market: marketBuy
    });
  };

  toggle() {
    this.setState(
      {
        modal: !this.state.modal
      },
      this.checkBuyResponse
    );
  }

  hideModalBuyResponse() {
    setTimeout(() => {
      this.setState({
        message: false,
        errorResponse: false
      });
    }, 2000);
  }

  checkBuyResponse = () => {
    this.hideModalBuyResponse();
    const { buyResponse } = this.state;
    if (buyResponse.success === 1) {
      this.props.getMyBalance({});
      this.props.getMyOpenOrders({
        market: this.props.activeMarketValue,
        coin: this.props.activeCurrencyValue
      });
      if (buyResponse.message === 'Order complete') {
        this.props.getMyOrderHistory({
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue
        });
        this.props.getMyTradeMetricsFunc({
          coinId: this.props.activeCurrencyValue,
          marketId: this.props.activeMarketValue
        });
      }
      // this.setState(
      //   {
      //     limitBuy: '',
      //     stopBuy: '',
      //     volumeStopBuy: '',
      //     totalStopBuy: '',
      //     buyResponse: {}
      //   },
      //   this.handleInputRemotely
      // );
    } else if (buyResponse.error === 2) {
      this.props.logout({ email });
      removeCookie();
    } else {
      this.setState({
        buyResponse: {}
      });
    }
  };

  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  moveToDepositPage(state) {
    this.props.selectedCurrencyUpdate({
      selectedCurrency: state.selectedCurrency
    });
    this.props.changePage('/transaction');
  }

  currencyBalanceFormatFunc() {
    const { myBalanceList, activeMarketValue, activeMarketSymbol } = this.state;
    if (_.size(myBalanceList) > 0 && myBalanceList[activeMarketValue]) {
      var myBalanceAmount = balanceFormat.balanceFormatFunc(
        myBalanceList[activeMarketValue].amount,
        activeMarketSymbol
      );
      this.setState({ myBalanceAmount });
    } else {
      this.setState({ myBalanceAmount: 0 });
    }
  }

  render() {
    const {
      myBalanceList,
      buyResponse,
      isLoggedIn,
      myBalanceAmount
    } = this.state;
    const closeBtn = (
      <button className="close" onClick={this.toggle}>
        &times;
      </button>
    );

    return (
      <div className="col-lg-6 card-box pt-3 container-one col-lg-4 container-one pr-1">
        <div className="row no-gutters">
          <div className="col-md-5 text-left">
            <div className="title">
              <span className="line-vertical positive-line" /> Buy
            </div>
          </div>
          <div className="col-md-7 text-right">
            {isLoggedIn ? (
              <div
                className="number crsrPntr"
                onClick={() =>
                  this.handleAmountBalanceChange(
                    myBalanceList[this.props.activeMarketValue] &&
                      myBalanceList[this.props.activeMarketValue].amount
                  )
                }
              >
                {myBalanceAmount}
                <span className="currency">{` ${this.props.activeMarketSymbol} `}</span>
                <a>
                  <span
                    className="add-icon"
                    onClick={() =>
                      this.moveToDepositPage({
                        selectedCurrency: this.props.activeMarketValue
                      })
                    }
                  >
                    +
                  </span>
                </a>
              </div>
            ) : (
              <div className="number">
                0
                <span className="currency">{` ${this.props.activeMarketSymbol}`}</span>
                <a>
                  <span className="add-icon">+</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="calculator-form">
          <div className="input-group mb-1">
            <input
              pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="stopBuy"
              id="PriceBuy"
              // step=".00000001"
              value={this.state.stopBuy}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Stop loss at"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeMarketSymbol}
              </span>
            </div>
          </div>
          <div className="input-group mb-1">
            <input
              pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="limitBuy"
              id="AmountBuy"
              // step=".00000001"
              value={this.state.limitBuy}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Take profit at"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeMarketSymbol}
              </span>
            </div>
          </div>
          <div className="input-group mb-1">
            <input
              pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="volumeStopBuy"
              id="TotalBuy"
              step=".00000001"
              value={this.state.volumeStopBuy}
              onChange={this.handleInputChange}
              // value={Intl.NumberFormat('en-IN').format(this.props.volumeStopBuy)}
              className="form-control"
              // disabled={true}
              placeholder="Amount"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeCurrencySymbol}
              </span>
            </div>
          </div>
          <div className="input-group mb-1">
            <input
              pattern="(\d{1,4})?(\.{1}\d{0,8})?"
              autoComplete="off"
              type="text"
              name="totalStopBuy"
              id="TotalBuy"
              step=".00000001"
              value={this.state.totalStopBuy}
              onChange={this.handleInputChange}
              // value={Intl.NumberFormat('en-IN').format(this.props.totalStopBuy)}
              className="form-control"
              // disabled={true}
              placeholder="Total"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {this.props.activeMarketSymbol}
              </span>
            </div>
          </div>
        </div>
        {this.props.isLoggedIn && (
          <>
            {(this.state.stopBuy === '' ||
              this.state.limitBuy === '' ||
              this.state.volumeStopBuy === '') && (
              <>
                <button
                  className="btn btn-secondary btn-buy btn-block"
                  id="Popover1"
                  type="button"
                  onClick={this.togglePopOver}
                >
                  Buy
                </button>
                <Popover
                  placement="bottom"
                  isOpen={this.state.popoverOpen}
                  target="Popover1"
                  toggle={this.togglePopOver}
                >
                  <PopoverBody>
                    Please enter Price, Amount and Total value.
                  </PopoverBody>
                </Popover>
              </>
            )}

            {this.state.stopBuy !== '' &&
              this.state.limitBuy !== '' &&
              this.state.volumeStopBuy !== '' &&
              (!this.state.reloadButton ? (
                <button
                  className="btn btn-secondary btn-buy btn-block"
                  onClick={this.handleBuyForm}
                >
                  Buy
                </button>
              ) : (
                <button className="btn btn-secondary btn-block">
                  <i className="fa fa-spinner fa-spin fa-fw" />
                </button>
              ))}

            {this.state.message && (
              <div
                className={`notification-box ${
                  this.state.errorResponse
                    ? 'notification-box-error'
                    : 'notification-box-success'
                }`}
              >
                {/* <div className="notification-title">Order </div> */}
                <p>{this.state.message}</p>
              </div>
            )}

            <Modal isOpen={this.state.modal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle} close={closeBtn}>
                Do you want to buy this ?
              </ModalHeader>
              <ModalBody>
                <div className="col-md-12 faded">
                  {`Price: ${this.state.stopBuy} ${this.props.activeMarketSymbol}`}
                </div>

                <div className="col-md-12 faded">
                  {`Amount: ${this.state.limitBuy} ${this.props.activeMarketSymbol}`}
                </div>

                <div className="col-md-12 faded">
                  {`Total: ${this.state.volumeStopBuy} ${this.props.activeCurrencySymbol}`}
                </div>
              </ModalBody>
              <ModalFooter>
                {buyResponse && buyResponse.error && (
                  <div className="negative">{buyResponse.message}</div>
                )}
                {buyResponse && buyResponse.success && (
                  <div className="positive">{buyResponse.message}</div>
                )}{' '}
                {!this.state.reloadButton && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.handleBuyForm}
                  >
                    Buy
                  </button>
                )}
                {this.state.reloadButton && (
                  <button className="btn btn-secondary">
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  </button>
                )}{' '}
                <button className="btn btn-secondary" onClick={this.toggle}>
                  Cancel
                </button>
              </ModalFooter>
            </Modal>
          </>
        )}

        {!this.props.isLoggedIn && (
          <>
            <button
              className="btn btn-secondary btn-buy btn-block"
              type="button"
              style={{ background: '#6c757d' }}
              id="NotLoginBuyPopover"
              onClick={this.togglePopOver}
            >
              Buy
            </button>
            <Popover
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target="NotLoginBuyPopover"
              toggle={this.togglePopOver}
            >
              <PopoverBody>Please Login</PopoverBody>
            </Popover>
          </>
        )}
      </div>
    );
  }
}

BuyOrderForm.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1,
  activeCurrencySymbol: 'ETH'
};

const mapStateToProps = state => {
  return {
    currencyBuy: state.limitForms.currencyBuy,
    limitBuy: state.stopLimitForms.limitBuy,
    stopBuy: state.stopLimitForms.stopBuy,
    volumeStopBuy: state.stopLimitForms.volumeStopBuy,
    totalStopBuy: state.stopLimitForms.totalStopBuy,
    operationBuy: state.limitForms.operationBuy,
    marketBuy: state.limitForms.marketBuy,
    buyResponse: state.stopLimitForms.buyResponse,
    isLoggedIn: state.signin.isLoggedIn,
    buyFormModal: state.limitForms.buyFormModal,
    getMyBalanceList: state.myOrders.getMyBalanceList
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      addBuyOrder: formData => addBuyOrder(formData),
      buyStopLimitInputRemotelyChange: formData =>
        buyStopLimitInputRemotelyChange(formData),
      selectedCurrencyUpdate: formData => selectedCurrencyUpdate(formData),
      getMyOpenOrders: formData => getMyOpenOrders(formData),
      getMyOrderHistory: formData => getMyOrderHistory(formData),
      getMyTradeMetricsFunc: formData => getMyTradeMetricsFunc(formData),
      getMyBalance: formData => getMyBalance(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BuyOrderForm);
