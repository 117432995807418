/**
 * Sign in Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerSignin';

const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED';
const SIGNIN_OTP_FIRST_FORM_REQUESTED = 'SIGNIN_OTP_FIRST_FORM_REQUESTED';
const ADD_SIGNIN_SECOND_FORM_RESPONSE = 'ADD_SIGNIN_SECOND_FORM_RESPONSE';
const ADD_SIGNIN_OTP_FIRST_FORM_RESPONSE = 'ADD_SIGNIN_OTP_FIRST_FORM_RESPONSE';
const ADD_PROFILE = 'ADD_PROFILE';
const ADD_LOGOUT = 'ADD_LOGOUT';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const SESSION_MESSAGE_UPDATE = 'SESSION_MESSAGE_UPDATE';
const UPDATE_CODE_2FA = 'UPDATE_CODE_2FA';
const SIGNUP_MESSAGE = 'SIGNUP_MESSAGE';

const initialState = {
  isFormProcessing: false,
  signinSecondFormResponse: {},
  signinOTPFirstFormResponse: {},
  isLoggedIn: false,
  kyc_status: false,
  profile: {},
  forgotPasswordResponse: '',
  flag: false,
  signedUpResponse: ''
};

// Reducer

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_REQUESTED:
      return {
        ...state,
        isFormProcessing: true
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordResponse: action.response
      };

    case ADD_SIGNIN_SECOND_FORM_RESPONSE:
      return {
        ...state,
        isFormProcessing: false,
        signinSecondFormResponse: action.response
      };
    case SIGNIN_OTP_FIRST_FORM_REQUESTED:
      return {
        ...state
        // isFormProcessing: true
      };

    case ADD_SIGNIN_OTP_FIRST_FORM_RESPONSE:
      return {
        ...state,
        // isFormProcessing: false,
        signinOTPFirstFormResponse: action.response
      };
    case ADD_PROFILE:
      return {
        ...state,
        isLoggedIn: true,
        profile: action.profile,
        kyc_status: action.profile.kyc_status !== 0
      };
    case ADD_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        profile: {}
      };
    case SESSION_MESSAGE_UPDATE:
      return {
        ...state,
        flag: action.flag
      };
    case SIGNUP_MESSAGE:
      return {
        ...state,
        signedUpResponse: action.message
      };

    default:
      return state;
  }
};

/**
 *Sign IN Action
 *@event
 */
export const signinOTPFirstFormRequest = formData => dispatch => {
  dispatch({
    type: SIGNIN_OTP_FIRST_FORM_REQUESTED
  });
  apiCaller('authenticate', 'post', formData, false, false, false).then(
    response =>
      dispatch({
        type: ADD_SIGNIN_OTP_FIRST_FORM_RESPONSE,
        response
      })
  );
};

/**
 *Sign IN Action
 *@event
 */
export const signinSecondFormRequest = formData => dispatch => {
  dispatch({
    type: SIGNIN_REQUESTED
  });
  apiCaller('authotp', 'post', formData, false, false, false).then(response =>
    dispatch({
      type: ADD_SIGNIN_SECOND_FORM_RESPONSE,
      response
    })
  );
};

/**
 *Add Profiles Action
 *@event
 */
export const addProfile = profile => dispatch => {
  dispatch({
    type: ADD_PROFILE,
    profile
  });
};

/**
 * Add Logout action
 *@event
 @param {Object} formData - formData --> email
 */
export const logout = formData => dispatch => {
  apiCaller('logout', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_LOGOUT,
      response
    });
  });
};

export const forgotPassword = formData => dispatch => {
  apiCaller('forgotpassword', 'post', formData, false, false, false).then(
    response =>
      dispatch({
        type: FORGOT_PASSWORD,
        response
      })
  );
};

export const fromPWDChange = formData => dispatch => {
  dispatch({
    type: SESSION_MESSAGE_UPDATE,
    flag: formData.flag
  });
};

export const signedUp = formData => dispatch => {
  dispatch({
    type: SIGNUP_MESSAGE,
    message: formData.mes
  });
};
