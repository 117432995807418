/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

import { logout } from 'Modules/signin/signinReducer';

import statusCheck from 'Utils/statusCheck';
import _ from 'underscore';

import { getMyBalance } from './myOrdersReducer';

const cookies = new Cookies();
let email = cookies.get('email');
let code_2fa = cookies.get('code_2fa');

class MyBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myBalanceLoader: true,
      getMyBalanceList: {},
      myBalanceError: null,
      coinSymbolsObj: {},
      market_information_height: this.props.market_information_height
    };
  }

  componentDidMount() {
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });

    this.props.getMyBalance({});
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.market_information_height !==
      nextProps.market_information_height
    ) {
      this.setState({
        market_information_height: nextProps.market_information_height
      });
    }

    // Set Loader for Balance
    if (this.props.myBalanceLoader !== nextProps.myBalanceLoader) {
      this.setState({ myBalanceLoader: nextProps.myBalanceLoader });
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    //Handle The Response IF Recieved Balance Data
    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState({
        myBalanceList: nextProps.getMyBalanceList,
        error: null
      });
    }
  }

  render() {
    const { myBalanceLoader, coinSymbolsObj, myBalanceList } = this.state;
    return (
      <>
        {!myBalanceLoader && this.state.myBalanceError && (
          <div>{this.state.myBalanceError.toString()}</div>
        )}

        {!_.keys(coinSymbolsObj) && !_.keys(coinSymbolsObj).length > 0 && (
          <div>{coinSymbolsObj.toString() || 'No balance'}</div>
        )}

        {myBalanceLoader && <div className="loading-o" />}

        {!myBalanceLoader &&
          coinSymbolsObj &&
          _.keys(coinSymbolsObj).length !== 0 &&
          this.state.myBalanceError === null &&
          (myBalanceList && _.keys(myBalanceList).length !== 0 ? (
            <table className="table info-table card-box">
              <tbody
                id="market-information"
                style={{ height: this.state.market_information_height }}
              >
                {_.keys(myBalanceList).map((o, i) => (
                  <tr key={o} className="row no-gutters">
                    <td className="col-md-3 text-left title">
                      {coinSymbolsObj[o].coinsymbol}
                    </td>
                    <td className="col-md-9 text-right data">
                      {parseFloat(myBalanceList[o].amount).toFixed(8)}{' '}
                      <span className="currency">
                        {coinSymbolsObj[o].coinsymbol}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="noBal">
              You don't have a balance.
              <br /> <a href="https://otc.throughbit.com">Transfer</a> balance from OTC or
              <br /> <a href="/withdrawDeposit">Load up</a> your wallet to
              trade.
            </div>
          ))}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      getMyBalance: formData => getMyBalance(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    myBalanceLoader: state.myOrders.myBalanceLoader,
    coinSymbolsObj: state.app.coinSymbolsObj,
    getMyBalanceList: state.myOrders.getMyBalanceList,
    market_information_height: state.dashboard.market_information_height
  }),
  mapDispatchToProps
)(MyBalance);
