import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const PORTFOLIO_ACTIVE_TAB_UPDATE = 'PORTFOLIO_ACTIVE_TAB_UPDATE';
const PORTFOLIO_REQUESTED = 'PORTFOLIO_REQUESTED';
const PORTFOLIO_SUCCESS = 'PORTFOLIO_SUCCESS';
const COIN_MARKET_PRICE = 'COIN_MARKET_PRICE';
//const SELECTED_OPEN_ORDER_ID = 'SELECTED_OPEN_ORDER_ID';
const PORTFOLIO_TOTAL_VAL_REQUESTED = 'PORTFOLIO_TOTAL_VAL_REQUESTED';
const PORTFOLIO_TOTAL_VAL_SUCCESS = 'PORTFOLIO_TOTAL_VAL_SUCCESS';
const initialState = {
  activeTab: '1',
  marketId: '',
  portfolioLoader: true,
  portfolioList: [],
  coinMarketPriceData: [],
  totalValLoader: true,
  totalValResponse: {}
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case PORTFOLIO_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case PORTFOLIO_REQUESTED:
      return {
        ...state,
        portfolioLoader: true
      };
    case PORTFOLIO_SUCCESS:
      return {
        ...state,
        portfolioLoader: false,
        portfolioList: action.response,
        marketId: action.marketId
      };
    case COIN_MARKET_PRICE:
      return {
        ...state,
        coinMarketPriceData: action.response
      };
    case PORTFOLIO_TOTAL_VAL_REQUESTED:
      return {
        ...state,
        totalValLoader: true
      };
    case PORTFOLIO_TOTAL_VAL_SUCCESS:
      return {
        ...state,
        totalValLoader: false,
        totalValResponse: action.response
        //  marketId: action.marketId
      };
    default:
      return state;
  }
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: PORTFOLIO_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};

export const getPortfolio = formData => dispatch => {
  dispatch({
    type: PORTFOLIO_REQUESTED
  });
  var url = `user/wallet/portfolio/` + `${formData.marketId}`;
  apiCaller(url, 'get', '', false, true, false).then(response => {
    dispatch({
      type: PORTFOLIO_SUCCESS,
      response,
      marketId: formData.marketId
    });
  });
};

export const coinMarketPrice = formData => dispatch => {
  apiCaller(
    `list/price/${formData.marketId}/${formData.coinId}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response => {
    dispatch({
      type: COIN_MARKET_PRICE,
      response
    });
  });
};

export const getTotalValInMarket = formData => dispatch => {
  dispatch({
    type: PORTFOLIO_TOTAL_VAL_REQUESTED
  });
  var url = `user/wallet/totalmarketvalue/` + `${formData.marketId}`;
  apiCaller(url, 'get', '', false, true, false).then(response => {
    dispatch({
      type: PORTFOLIO_TOTAL_VAL_SUCCESS,
      response,
      marketId: formData.marketId
    });
  });
};
