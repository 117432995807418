import React, { Component } from 'react';
import './initial-idea.css';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Fullpage from './fullpage';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FooterModal from 'App/footerModal';
import 'App/footer.css';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const width = window.outerWidth;
const items = [
  {
    altText: 'Margin trading',
    caption:
      '  Margin trading is for such scenarios. A user may borrow upto 3x of their personal holdings for Margin trading. While  the returns are high, considering that there is more capital to trade with, the risks involved are high as well.'
  },
  {
    altText: 'Direct',
    caption:
      ' ThroughBit Direct is an exchange service for trading cryptocurrencies that allows users to trade directly amongst each other without having to placing an order in a seamless and secure transaction. Direct trades can be crypto-crypto or crypto-fiat. Direct is especially useful in 2 scenarios ',
    button: 'Know more'
  },
  {
    altText: 'Test Trade',
    caption:
      ' ‘Test trade’ brings a sense of calm and gamification to the otherwise high voltage cryptocurrency business. With ‘Test trade’, you can now practice trading, go by your instincts and place orders, and experiment all you like without the risk of losing any money. ‘Test trade’ is usually sponsored by a cryptocurrency. When a ‘Test trade’ window opens up, all one needs to do is claim their free ‘test’ tokens and start trading on the test exchange. A certain amount of ‘real’ tokens are set aside as prize money. At the end of the ‘test’ window, the prize money will be disbursed to the traders pro-rata to the profits they have made. There are no losses!'
  }
];

// {
//   src: exhange,
//   altText: 'Exchange',
//   caption:
//     'Margin refers to the amount of funds that the trader or investor must personally put up from his or her own resources,and can vary widely depending on the asset or instrument. Forinstance, currency futures typically need a margin thatamounts to a low single-digit percentage of the currencycontract’s value. A stock bought on margin generally requiresthe investor to supply 30% to 50% of the value of the purchase transaction. As a rule of thumb, the greater the volatility of the stock, the higher will be the margin requirement.'
// },
class MarginTrading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exchangewhite: false,
      margintradewhite: true,
      testtradewhite: false,
      directwhite: false,
      activeIndex: 0,
      modal: false,
      key: ''
    };
    this.child = React.createRef();
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  onExiting() {
    this.animating = true;
  }

  // disableScroll = () => {
  //   ReactFullpage.setAllowScrolling(false);
  // };

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.props.disableScroll(true);
    this.child.current.set('direct');
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  disableAll = () => {
    this.setState({
      exchangewhite: false,
      testtradewhite: false,
      margintradewhite: false,
      directwhite: false
    });
    return true;
  };

  handleTestTrade = () => {
    if (this.disableAll()) {
      this.setState({
        testtradewhite: true
      });
    }
  };

  handleMarginTrade = () => {
    if (this.disableAll()) {
      this.setState({
        margintradewhite: true
      });
    }
  };

  handleDirect = () => {
    if (this.disableAll()) {
      this.setState({
        directwhite: true
      });
    }
  };

  handleExchange = () => {
    if (this.disableAll()) {
      this.setState({
        exchangewhite: true
      });
    }
  };

  untoggle = () => {
    this.setState({
      modal: '',
      key: ''
    });
    this.props.disableScroll(false);
  };
  render() {
    const { activeIndex } = this.state;

    const slides = items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <div className="center-mob">
            <h4 className="f-800">{item.altText}</h4>
          </div>
          <div className="center-mob mt-5">
            <img src={item.src} alt={item.altText} />
          </div>

          <div className="center-mob">
            <p className=" w-break">{item.caption}</p>
          </div>
          <a
            className="pl-3 "
            href="#"
            style={{ color: '#fff' }}
            onBlur={this.untoggle}
            onClick={this.toggle}
          >
            <dt>{item.button}</dt>
          </a>
          {/* <CarouselCaption
            captionText={item.caption}
            captionHeader={item.altText}
          /> */}
        </CarouselItem>
      );
    });

    return (
      <>
        <FooterModal
          ref={this.child}
          untoggle={this.untoggle}
          modall={this.state.modal}
        />
        {width <= 768 ? (
          <div
            className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s"
            id="landing-products"
          >
            {/* <div className="space mb-auto bg-light" /> */}
            <main>
              <div
                className="row no-gutters row-eq-height bg-light"
                id="trading"
              >
                <div className="col-md-12 col-sm-12 align-self-center">
                  <div>
                    <h2 className="mt-3 d-flex justify-content-center f-800">
                      In the pipeline
                    </h2>
                    <Carousel
                      activeIndex={activeIndex}
                      next={this.next}
                      previous={this.previous}
                    >
                      <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={this.goToIndex}
                      />
                      {slides}
                      {/* <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}
                      /> */}
                    </Carousel>
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : null}

        {width > 768 ? (
          <div
            className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s"
            id="landing-products"
          >
            {/* <div className="space mb-auto" /> */}
            <main>
              <div className="row no-gutters row-eq-height" id="trade">
                <div className="col-md-4 side-animated">
                  <div className="container row h-100 py-3 align-items-center justify-content-center">
                    <div className="row no-gutters">
                      <div className="col-md-12">
                        <img
                          hidden={!this.state.testtradewhite}
                          src={`${process.env.PUBLIC_URL}/assets/images/test_trade_infinite.gif`}
                          className="animated  delay-2s"
                        />
                        <img
                          hidden={!this.state.margintradewhite}
                          src={`${process.env.PUBLIC_URL}/assets/images/margin_infinite.gif`}
                          className="animated  delay-2s"
                        />
                        <img
                          hidden={!this.state.exchangewhite}
                          src={`${process.env.PUBLIC_URL}/assets/images/exchange.gif`}
                          className="animated  delay-2s"
                        />
                        <img
                          hidden={!this.state.directwhite}
                          src={`${process.env.PUBLIC_URL}/assets/images/direct-ani.gif`}
                          className="animated  delay-2s"
                        />
                      </div>
                      {/* 		<div className="col-md-12 mt-5">
<a href="#" className="btn btn-markets" role="button">View markets</a>
</div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 align-self-center">
                  <div className="container products-desc w-65">
                    <h1>In the pipeline</h1>
                    <h3 hidden={!this.state.margintradewhite}>
                      Margin trading
                    </h3>
                    <h3 hidden={!this.state.testtradewhite}>Test Trade</h3>
                    <h3 hidden={!this.state.directwhite}>Direct</h3>
                    <p
                      className="mt-5 fix-height"
                      hidden={!this.state.margintradewhite}
                    >
                      Margin trading is for such scenarios. A user may borrow up
                      to 3x of their personal holdings for Margin trading. While
                      the returns are high, considering that there is more
                      capital to trade with, the risks involved are high as
                      well.
                    </p>
                    <p
                      className="mt-5 fix-height"
                      hidden={!this.state.testtradewhite}
                    >
                      ‘Test trade’ brings a sense of calm and gamification to
                      the otherwise high voltage cryptocurrency business. With
                      ‘Test trade’, you can now practice trading, go by your
                      instincts and place orders, and experiment all you like
                      without the risk of losing any money. ‘Test trade’ is
                      usually sponsored by a cryptocurrency. When a ‘Test trade’
                      window opens up, all one needs to do is claim their free
                      ‘test’ tokens and start trading on the test exchange. A
                      certain amount of ‘real’ tokens are set aside as prize
                      money. At the end of the ‘test’ window, the prize money
                      will be disbursed to the traders pro-rata to the profits
                      they have made. There are no losses!
                    </p>
                    <p
                      className="mt-5 fix-height"
                      hidden={!this.state.directwhite}
                    >
                      ThroughBit Direct is an exchange service for trading
                      cryptocurrencies that allows users to trade directly
                      amongst each other without having to placing an order in
                      the market. ThroughBit merely acts as the escrow, ensuring
                      a seamless and secure transaction. Direct trades can be
                      crypto-crypto or crypto-fiat. Direct is especially useful
                      in 2 scenarios:- {'   '}
                      <a
                        className="pl-3 "
                        href="#"
                        style={{ color: '#fff' }}
                        onClick={this.toggle}
                      >
                        <dt>Know More</dt>
                      </a>
                    </p>

                    {/* <a href="#">Know more</a> */}
                  </div>
                  <div className="container-fluid w-75 mt-5">
                    <ul className="row no-gutters prodcts-show horizontal">
                      {/* <li className="col-md-3">
                        <div>
                          <div
                            hidden={!this.state.exchangewhite}
                            className="product active"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/4.png`} />
                            <h2>Exchange</h2>
                          </div>
                          <div
                            hidden={this.state.exchangewhite}
                            onClick={this.handleExchange}
                            className="product"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/1-w.png`} />
                            <h2>Exchange</h2>
                          </div>
                        </div>
                      </li> */}

                      <li className="col-md-3 col-sm-3">
                        <div>
                          <div
                            hidden={!this.state.margintradewhite}
                            className="product active"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/1.png`} />
                            <h2>Margin Trade</h2>
                          </div>
                          <div
                            hidden={this.state.margintradewhite}
                            onClick={this.handleMarginTrade}
                            className="product"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/7.png`} />
                            <h2>Margin Trade</h2>
                          </div>
                        </div>
                      </li>

                      <li className="col-md-3 col-sm-3">
                        <div>
                          <div
                            hidden={!this.state.directwhite}
                            className="product active"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/direct_latest.png`} />
                            <h2>Direct</h2>
                          </div>
                          <div
                            hidden={this.state.directwhite}
                            onClick={this.handleDirect}
                            className="product"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/direct.png`} />
                            <h2>Direct</h2>
                          </div>
                        </div>
                      </li>

                      <li className="col-md-3 col-sm-3">
                        <div>
                          <div
                            hidden={!this.state.testtradewhite}
                            className="product active"
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/5.png`} />
                            <h2>Test Trade</h2>
                          </div>
                          <div
                            hidden={this.state.testtradewhite}
                            className="product"
                            onClick={this.handleTestTrade}
                          >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/3.png`} />
                            <h2>Test Trade</h2>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : null}
      </>
    );
  }
}

export default MarginTrading;
