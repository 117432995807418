import React, { useEffect, useState } from 'react';
import {
  headerActiveMenuUpdate
} from 'App/appReducer';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-dropdown';
import apiCaller from 'Utils/apiCallerNew';
const Transfer = () => {
  const dispatch = useDispatch();
  var [coinSelected, setCoinSelected] = useState('BTC')
  var [allBalance, setAllBalance] = useState()
  var [balance, setBalance] = useState(0)
  var [coinId, setCoinId] = useState(0)
  var [amount, setAmount] = useState('')
  var [message, setMessage] = useState('')
  var [error, setError] = useState(false)
  var coinList = ['BTC','ETH']


	useEffect(() => {
		dispatch(headerActiveMenuUpdate('transfer'));
    fetchBalance()
	}, []);

  useEffect(() => {
   if(allBalance){
      var bal = allBalance.find(o => o.coinId === coinId)
      if(bal && bal.amount)
        setBalance(bal.amount)
   }
      
  },[coinId, allBalance])
  const fetchBalance = () => {
    apiCaller(`user/wallet/balance/all`, 'get', '', false, true, false).then((apiRes) => {
      if(apiRes && !apiRes.error && apiRes.data){
        setAllBalance(apiRes.data)
      }
    })  
  }
  const onChangeDropdown = e => {
    setCoinSelected(e.value)
    setCoinId(coinList.findIndex(val => val === e.value))
  }
  const onchangeAmount = e => {
    setAmount(e.target.value)
  }
  const onTrnasferClick = () => {
    if(amount > 0){
      var data = {
        coinId: coinId,
        amount: amount
      }
      apiCaller(`balancesync/transfer/otc`, 'post', data, false, true, false).then((apiRes) => {
        if(apiRes){
          if(!apiRes.error){
            fetchBalance()
            setAmount('')
            setMessage(apiRes.message)
            setError(false)
          }
          else{
            setError(true)
            setAmount('')
            setMessage(apiRes.message)
          }
        }else{
          console.log("Error")
          console.log(apiRes)
        }
      })
    }
    else{
      setAmount('')
      setMessage('Amount must be greater than zero')
    }
  }

  return (
    <div className="row no-gutters transfer">
      <div className="col-lg-6 card-box mt-4 ">
        <div className="row-center mt-4">
          Transfer Balance
        </div>
        <div className="row-center">
          <div className="col-lg-8 d-flex mt-4">
              <div className="col-lg-4 name-box">OrderBook</div>
              <div className="col-lg-4 row-content-center">----{'>'}</div>
              <div className="col-lg-4 name-box">OTC</div>
          </div>
        </div>
        <div className="row-center">
          <div className="col-lg-8 d-flex mt-2 row-content-center">
              <div className="col-lg-4">
                <Dropdown
                  options={coinList}
                  onChange={onChangeDropdown}
                  value={coinSelected}
                  placeholder={coinSelected}
                  className="row"
                />
              </div>
              <div className="col-lg-4 row-content-center"></div>
              <div className="col-lg-4 name-box">
              <input
                pattern="(\d{1,4})?(\.{1}\d{0,8})?"
                autoComplete="off"
                type="number"
                name="amount"
                id="amount"
                value={amount}
                onChange={onchangeAmount}
                className="form-control"
                placeholder="Enter Amount"
              />
              </div>
          </div>
        </div>
        <div className="row-center">
          <div className="col-lg-8 d-flex mb-2 balance-row">
              <div className="col-lg-7 pl-0 available-bal">Available Balance: {balance} {coinSelected}</div>
              <div className="col-lg-5 pr-0 bal-fee">Transfer Fee: 0</div>
          </div>
        </div>
        <div className="row-center">
          <div className="col-lg-8 d-flex mt-4 mb-4">
            <button 
              className="btn btn-primary btn-buy btn-block"
              onClick={onTrnasferClick}>Transfer
            </button>
          </div>
        </div>
        {message && 
          <div className="row-center">
            {error ? 
              <div className="col-lg-8 d-flex mb-4 message error-msg">
                {message}
              </div>
              :
              <div className="col-lg-8 d-flex message mb-4">
                {message}
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
};
export default Transfer;
