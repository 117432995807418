/**
 * Security 2 Factor Component
 *@Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import Cookies from 'universal-cookie';
import { loginHistoryReq } from '../settingsReducer';
import _ from 'underscore';

/**
 * Represents a Security 2Fa Class.
 * @class extends React.Component
 */
class SecurityLoginHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      secret: '',
      qrCodeUrl: '',
      code: '',
      password: '',
      error: '',
      responseMessage: '',
      disableTwoFaResponse: '',
      enableTwoFaResponse: '',
      enabled: false,
      twoFaError: '',
      loginHistoryLoader: false,
      loginHistoryResponse: {},
      loginHistoryData: []
    };
  }

  componentDidMount() {
    this.props.loginHistoryReq();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loginHistoryLoader !== nextProps.loginHistoryLoader) {
      this.setState({ loginHistoryLoader: nextProps.loginHistoryLoader });
    }

    if (this.props.loginHistoryResponse !== nextProps.loginHistoryResponse) {
      if (nextProps.loginHistoryResponse.status === 1) {
        this.setState({
          loginHistoryData: nextProps.loginHistoryResponse.data
        });
      } else if (nextProps.loginHistoryResponse.error === 2) {
        this.props.changePage('/login');
      }
      this.setState({ loginHistoryResponse: nextProps.loginHistoryResponse });
    }
  }

  render() {
    const {
      loginHistoryLoader,
      // loginHistoryResponse,
      loginHistoryData
    } = this.state;
    if (loginHistoryLoader) {
      return <div>Loading...</div>;
    }

    if (!loginHistoryLoader && loginHistoryData.length === 0) {
      return <div>Loading...</div>;
    }

    if (!loginHistoryLoader && loginHistoryData.length > 0) {
      return (
        <div id="profile-container">
          {loginHistoryData && loginHistoryData.length !== 0 ? (
            <table className="table table-striped table-data content-cards">
              <thead>
                <tr>
                  <th scope="col faded">Date</th>
                  <th scope="col faded">Time</th>
                  <th scope="col faded">IP Addresss</th>
                  <th scope="col faded">Device type</th>
                  {/* <th scope="col faded">2 FA</th> */}
                </tr>
              </thead>
              <tbody>
                {_.keys(loginHistoryData).length > 0 &&
                  loginHistoryData.map((o, i) => (
                    <tr key={i}>
                      <td>{o.datetime.split(' ')[0]}</td>
                      <td>{o.datetime.split(' ')[1]}</td>
                      <td>{o.ip_addr}</td>
                      <td>{o.device_type}</td>
                      {/* <td className="positive">YES</td> */}
                    </tr>
                    // <tr key={i}>
                    // 	<td>23 July 2018</td>
                    // 	<td>14:24</td>
                    // 	<td>14.142.151.178</td>
                    // 	<td>Web</td>
                    // 	<td className="positive">YES</td>
                    // </tr>

                    // <tr>
                    // 	<td>23 July 2018</td>
                    // 	<td>14:24</td>
                    // 	<td>14.142.151.178</td>
                    // 	<td>Mobile</td>
                    // 	<td className="negative">NO</td>
                    // </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div>No Login History</div>
          )}
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      loginHistoryReq: formData => loginHistoryReq(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    loginHistoryLoader: state.settings.loginHistoryLoader,
    loginHistoryResponse: state.settings.loginHistoryResponse
  }),
  mapDispatchToProps
)(SecurityLoginHistory);
