/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { getOrderDetails } from '../../dashboardReducer';
import {
  getRecentTrades,
  getScrollRecentTrades,
  allMarketCoin24HrPriceList
} from './recentTradesReducer';

import _ from 'underscore';
import Dropdown from 'react-dropdown';

const cookies = new Cookies();
let email = cookies.get('email');

class RecentTrades extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // create a ref object
    this.state = {
      recentTradeActiveTab: '1',
      recentTradeLoader: false,
      recentTradeList: [],
      recentTradeError: null,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      openOrdersActiveTab: '1',
      recentTradesLimit: 50,
      sidebar_main_height: this.props.sidebar_main_height
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, true);

    this.setState({
      activeMarketValue: this.props.activeMarketValue
    });

    this.props.getRecentTrades({
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: this.state.recentTradesLimit
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    const {
      recentTradeList,
      divTableHeight,
      scrollHeight,
      sidebar_main_height,
      scrollTop
    } = this.state;
    // divTableHeight - clientHeight - 686
    // scrollTop - 930
    // scrollHeight - 1616

    if (divTableHeight) {
      const scrollTopNew = this.myRef.current.scrollTop;

      if (
        scrollTopNew > scrollTop &&
        scrollTopNew >= scrollHeight - divTableHeight - 50 &&
        recentTradeList.length > 0 &&
        recentTradeList.length % 50 === 0 &&
        !this.state.recentTradeScrollLoader
      ) {
        this.setState({
          recentTradeScrollLoader: true
        });
        setTimeout(() => {
          this.props.getScrollRecentTrades({
            market: this.props.activeMarketValue,
            coin: this.props.activeCurrencyValue,
            skip: '0',
            limit: this.state.recentTradesLimit + 50
          });
          this.setState({
            recentTradesLimit: this.state.recentTradesLimit + 50,
            scrollTop: scrollTopNew
          });
        }, 1000);
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.sidebar_main_height !== nextProps.sidebar_main_height) {
      this.setState({ sidebar_main_height: nextProps.sidebar_main_height });
    }

    if (this.props.recentTradeLoader !== nextProps.recentTradeLoader) {
      this.setState({ recentTradeLoader: nextProps.recentTradeLoader });
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      this.setState({ activeMarketValue: nextProps.activeMarketValue });
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      this.setState({ activeCurrencyValue: nextProps.activeCurrencyValue });
    }

    if (this.props.getRecentTradeList !== nextProps.getRecentTradeList) {
      if (nextProps.getRecentTradeList.error === 0) {
        this.setState({
          recentTradeList: nextProps.getRecentTradeList.data,
          recentTradeScrollLoader: false,
          recentTradeError: null
        });

        setTimeout(() => {
          if (this.myRef.current) {
            const divTableHeight = this.myRef.current.clientHeight;
            const scrollHeight = this.myRef.current.scrollHeight;
            const scrollTop = this.myRef.current.scrollTop;
            this.setState({ divTableHeight, scrollHeight, scrollTop });
          }
        }, 1000);
      } else {
        this.setState({
          recentTradeList: null,
          recentTradeError: nextProps.getRecentTradeList.message,
          recentTradeScrollLoader: false
        });
      }
    }

    // if (
    //   Array.isArray(nextProps.getRecentTradeList) &&
    //   nextProps.getRecentTradeList !== undefined &&
    //   this.props.getRecentTradeList !== nextProps.getRecentTradeList
    // ) {
    //   if (nextProps.getRecentTradeList.length !== 0) {
    //     this.setState({
    //       recentTradeList: nextProps.getRecentTradeList,
    //       recentTradeScrollLoader: false
    //     });

    //     setTimeout(() => {
    //       if (this.myRef.current) {
    //         const divTableHeight = this.myRef.current.clientHeight;
    //         const scrollHeight = this.myRef.current.scrollHeight;
    //         const scrollTop = this.myRef.current.scrollTop;
    //         this.setState({ divTableHeight, scrollHeight, scrollTop });
    //       }
    //     }, 1000);
    //   }
    // } else if (!Array.isArray(nextProps.getRecentTradeList)) {
    //   this.setState({ recentTradeError: nextProps.getRecentTradeList });
    // }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      setTimeout(() => {
        this.props.getRecentTrades({
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: this.state.recentTradesLimit
        });
      }, 100);
    }
  }

  render() {
    const { recentTradeLoader, recentTradeList, recentTradeError } = this.state;

    if (recentTradeError) {
      return <div>{recentTradeError.toString()}</div>;
    } else if (recentTradeLoader) {
      return <div className="loading-o" />;
    } else {
      return (
        <div className="container-fluid h-100 card-box recentTrade">
          {!recentTradeLoader &&
            recentTradeError === null &&
            (recentTradeList && recentTradeList.length !== 0 ? (
              <table className="table data-table table-fixed">
                <thead>
                  <tr>
                    <th scope="col faded">Price</th>
                    <th scope="col faded">{`Amount(${this.props.activeCurrencySymbol})`}</th>
                    <th scope="col faded">{`Total(${this.props.activeMarketSymbol})`}</th>
                  </tr>
                </thead>
                <tbody
                  style={{ height: this.state.sidebar_main_height - 93 }}
                  ref={this.myRef}
                >
                  {recentTradeList.map((o, i) => (
                    <tr key={i}>
                      <td
                        className={
                          o.operation ? 'positive-text' : 'negative-text'
                        }
                      >
                        {o.executionPrice}
                      </td>
                      <td>{o.volumeCoin}</td>
                      <td>{o.volumeMarket}</td>
                      {/* <td>{(o.volumecur * o.executionPrice).toFixed(5)}</td> */}
                    </tr>
                  ))}
                  {/* {recentTradeList.length % 50 === 0 && (
										<>
											<tr key="10000">
												<td colSpan="3">Loading...</td>
											</tr>
											<tr key="10001">
												<td colSpan="3">Loading...</td>
											</tr>
											<tr key="10010">
												<td colSpan="3">Loading...</td>
											</tr>
											<tr key="10100">
												<td colSpan="3">Loading...</td>
											</tr>
										</>
									)} */}
                  {recentTradeList.length % 50 === 0 && (
                    <tr key="10000">
                      <td colSpan="3">Loading...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div>No Recent Trades</div>
            ))}
        </div>
      );
    }
  }
}

RecentTrades.defaultProps = {
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRecentTrades: formData => getRecentTrades(formData),
      getScrollRecentTrades: formData => getScrollRecentTrades(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    recentTradeLoader: state.recentTrades.recentTradeLoader,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    sidebar_main_height: state.dashboard.sidebar_main_height
  }),
  mapDispatchToProps
)(RecentTrades);
