/**
 * Security Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { activeTabUpdate } from './settingsReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';

import Security2FA from './components/security2FA';
import SecurityEmail from './components/securityEmail';
import SecurityPassword from './components/securityPassword';
import Kyc from './components/kyc';
import SecurityLoginHistory from './components/securityLoginHistory';

import ErrorBoundary from 'Modules/ErrorBoundary';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Represents a Security Class.
 * @class extends React.Component
 */
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSecurityMenu: 'password',
      sidebar_main_height: window.innerHeight - 60
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('settings');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }
  }

  /**
   * Bank details Component
   *@function
   *@param {string} => Active menu
   */
  handleSecurityMenu = menu => e => {
    this.setState({ activeSecurityMenu: menu });
  };

  render() {
    return (
      <div className="row no-gutters row-eq-height">
        <div className="col-lg-9 container-one pl-2 pr-1 mt-1">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === '2fa' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('2fa');
                }}
                active={(this.props.activeTab === '2fa').toString()}
                aria-selected={this.props.activeTab === '2fa'}
              >
                2FA
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === 'email' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('email');
                }}
                active={(this.props.activeTab === 'email').toString()}
                aria-selected={this.props.activeTab === 'email'}
              >
                Email
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === 'password' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('password');
                }}
                active={(this.props.activeTab === 'password').toString()}
                aria-selected={this.props.activeTab === 'password'}
              >
                Password
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === 'kyc' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('kyc');
                }}
                active={(this.props.activeTab === 'password').toString()}
                aria-selected={this.props.activeTab === 'password'}
              >
                Kyc
              </a>
            </li>

            {/* <li className="nav-item">
                    <a
											className={`crsrPntr nav-link
												${this.props.activeTab === 'loginHistory'
													? ' active'
													: ''}`
											}
                      onClick={() => {
                        this.toggle('loginHistory');
                      }}
                      active={(
                        this.props.activeTab === 'loginHistory'
                      ).toString()}
                      aria-selected={this.props.activeTab === 'loginHistory'}
                    >
                      Login History
                    </a>
                  </li> */}
          </ul>

          {this.props.activeTab === '2fa' && (
            <ErrorBoundary>
              <Security2FA profile={this.props.profile} />
            </ErrorBoundary>
          )}

          {this.props.activeTab === 'email' && (
            <ErrorBoundary>
              <SecurityEmail />
            </ErrorBoundary>
          )}

          {this.props.activeTab === 'password' && (
            <ErrorBoundary>
              <SecurityPassword profile={this.props.profile} />
            </ErrorBoundary>
          )}

          {this.props.activeTab === 'loginHistory' && (
            <ErrorBoundary>
              <SecurityLoginHistory profile={this.props.profile} />
            </ErrorBoundary>
          )}
          {this.props.activeTab === 'kyc' && (
            <ErrorBoundary>
              <Kyc profile={this.props.profile} />
            </ErrorBoundary>
          )}
        </div>

        <div className="col-lg-3 mt-1">
          <div
            id="sidebar-main"
            style={{ height: this.state.sidebar_main_height }}
            className="row no-gutters sidebar-content justify-content-center"
          >
            <div className="col-lg-12">
              <span className="user-image">
                <img src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg" />
              </span>
              <h2 className="title">Name</h2>
              <h1 className="title pt-2 pb-2">{cookies.get('full_name')}</h1>
              <div className="row no-gutters justify-content-center">
                <div className="progress-vertical">
                  <div className="row pt-5 filled">
                    <span className="progress-line" />
                    <div className="col dot">
                      <h1 className="title">Email</h1>
                      <h2 className="title">Basic access</h2>
                    </div>
                  </div>
                  <div
                    className={`row pt-2 ${
                      cookies.get('phoneStatus') == 1 ? 'filled' : ''
                    }`}
                  >
                    <span className="progress-line" />
                    <div className="col dot">
                      <h1 className="title">Mobile number</h1>
                      <h2 className="title">24hr Upto 100 BTC</h2>
                    </div>
                  </div>
                  <div
                    className={`row pt-2 ${
                      cookies.get('kycStatus') == 1 ? 'filled' : ''
                    }`}
                  >
                    {/* <span className="progress-line" /> */}
                    <div className="col dot">
                      <h1 className="title">KYC</h1>
                      <h2 className="title">24hr W/D Upto 50 BTC</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      activeTabUpdate: formData => activeTabUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    activeTab: state.settings.activeTab,
    isFormProcessing: state.settings.isFormProcessing,
    isLoggedIn: state.signin.isLoggedIn
  }),
  mapDispatchToProps
)(Settings);
