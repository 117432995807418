import React, { Component } from 'react';
import './initial-idea.css';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Fullpage from './fullpage';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const loc = window.location.href;
const pos = loc.search('#');
const prev = loc.substr(0, pos + 1);
const result = loc.substr(pos + 1);
let wid = window.outerWidth;
class Arrows extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      bg: false,
      number: '',
      black: false,
      downArrow: false
    };
  }

  moveup = () => {
    let loc = window.location.href;
    let pos = loc.search('#');
    let prev = loc.substr(0, pos + 1);
    let res = loc.substr(pos + 1);
    switch (res) {
      case 'Home':
        window.location.href = prev + 'Home';

        break;
      case 'Overview':
        window.location.href = prev + 'Home';
        this.setState({
          number: '00000000'
        });
        break;
      case 'Trading':
        window.location.href = prev + 'Cryptocurrencies';
        this.setState({
          number: '00000001',

          bg: false
        });
        break;
      case 'Loyalty':
        window.location.href = prev + 'Trading';
        this.setState({
          number: '00000010'
        });
        break;
      case 'Careers':
        window.location.href = prev + 'Loyalty';
        this.setState({
          number: '00000011'
        });
    }
  };

  componentWillReceiveProps(nextProps) {}

  movedown = () => {
    let loc = window.location.href;
    let pos = loc.search('#');
    let prev = '';
    let res = '';
    if (pos >= 0) {
      prev = loc.substr(0, pos + 1);
      res = loc.substr(pos + 1);
    } else {
      prev = loc + '#';
    }
    switch (res) {
      case '':
        window.location.href = prev + 'Overview';
        this.setState({
          bg: false
        });
        break;
      case 'Home':
        window.location.href = prev + 'Overview';
        this.setState({
          bg: false
        });
        break;
      case 'Overview':
        window.location.href = prev + 'Trading';
        break;
      case 'Trading':
        window.location.href = prev + 'Loyalty';
        break;
      case 'Loyalty':
        window.location.href = prev + 'Careers';
        break;
      case 'Careers':
        window.location.href = prev + 'Careers';
    }
  };

  render() {
    const { match, location, history } = this.props;
    return (
      <>
        {wid > 768 ? (
          <div>
            {' '}
            {location.hash == '#Home' || location.hash == '' ? null : (
              <div className="arrowUp" onClick={this.moveup}>
                <i
                  className={
                    location.hash == '#Overview'
                      ? 'fa fa-chevron-up arrows bg-dark-color'
                      : 'fa fa-chevron-up arrows'
                  }
                >
                  {' '}
                </i>
              </div>
            )}
            {location.hash == '#Careers' ? null : (
              <div className="arrowDown " onClick={this.movedown}>
                {' '}
                <i
                  className={
                    location.hash == '#Overview'
                      ? 'fa fa-chevron-down arrows bg-dark-color'
                      : 'fa fa-chevron-down arrows'
                  }
                >
                  {' '}
                </i>
              </div>
            )}
            {/* <div
          className={
            location.hash == '#Cryptocurrencies' ? 'number1 bg' : 'number1'
          }
        >
          {this.props.location.hash == '#Home'
            ? '00000001'
            : this.props.location.hash == '#Cryptocurrencies'
            ? '00000010'
            : this.props.location.hash == '#Trading'
            ? '00000011'
            : this.props.location.hash == '#Loyalty'
            ? '00000100'
            : '00000101'} 
        </div> */}
          </div>
        ) : null}
      </>
    );
  }
}

export default withRouter(Arrows);
