/**
 * Main DepositWithdraw Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import statusCheck from 'Utils/statusCheck';

import { getTransferHistory, transferFormView } from './withdrawDepositReducer';
import ReferralSideBar from 'Modules/referral/referalSideBar';
import ErrorBoundary from 'Modules/ErrorBoundary';

class Transfer extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.showTransferForm = this.showTransferForm.bind(this);
    this.state = {
      getTransferHistoryLoader: true,
      getTransferHistoryList: [],
      searchData: [],
      marketCrptoArray: [],
      activeTab: 0,
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      transferError: null,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      type: 'internal'
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      // this.props.activeTabUpdate(tab);
    }
  }

  componentDidMount() {
    // if (this.props.isLoggedIn !== true) {
    //   this.props.changePage('/login');
    // }
    this.props.getTransferHistory();
  }

  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (
      this.props.getTransferHistoryLoader !== nextProps.getTransferHistoryLoader
    ) {
      this.setState({
        getTransferHistoryLoader: nextProps.getTransferHistoryLoader
      });
    }

    //   //Handle The Response IF Recieved Transaction Data
    if (
      Array.isArray(nextProps.getTransferHistoryList) &&
      nextProps.getTransferHistoryList !== undefined &&
      this.props.getTransferHistoryList !== nextProps.getTransferHistoryList
    ) {
      this.setState({
        getTransferHistoryList: nextProps.getTransferHistoryList,
        searchData: nextProps.getTransferHistoryList
      });
    } else if (!Array.isArray(nextProps.getTransferHistoryList)) {
      this.setState({
        transferError: nextProps.getTransferHistoryList
      });
    }
  }

  handleChange(event) {
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  showTransferForm() {
    this.props.depositWithdrawFormView({
      transferFormView: true
    });
  }

  render() {
    const {
      // error,
      getTransferHistoryLoader,
      searchData,
      transferError
      // getTransferHistoryList
    } = this.state;

    if (getTransferHistoryLoader) {
      return <div>Loading...</div>;
    }

    if (!getTransferHistoryLoader && transferError) {
      return <div>{transferError.toString()}</div>;
    }

    if (!getTransferHistoryLoader && transferError === null) {
      return (
        <div className="container-fluid card-box full-table">
          {searchData && searchData.length !== 0 ? (
            <table className="table data-table">
              <thead>
                <tr>
                  <th scope="col faded">Name</th>
                  <th scope="col faded">Symbol</th>
                  <th scope="col faded">Total balance</th>
                  <th scope="col faded">On orders</th>
                  <th scope="col faded">Value(BTC)</th>
                  <th scope="col faded">Deposits</th>
                  <th scope="col faded">Withdrawals</th>
                </tr>
                <th scope="col" className="faded">
                  Name
                </th>
                <th scope="col" className="faded">
                  Symbol
                </th>
                <th scope="col" className="faded">
                  On order
                </th>
                <th scope="col" className="faded">
                  Exchange
                </th>
                <th scope="col" className="faded">
                  Total balance
                </th>
                <th scope="col" className="faded">
                  Action
                </th>
              </thead>
              <tbody>
                {searchData.map((o, i) => (
                  <tr key={i}>
                    <td className="name">
                      {statusCheck.marketViewNameCheck(o.coin)}
                    </td>
                    <td>{o.coin.toUpperCase()}</td>
                    <td>{o.onOrder}</td>
                    <td />
                    <td>{o.balance}</td>
                    <td>
                      <div className="button-cancel">Transfer</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No My Order History</div>
          )}
          <ErrorBoundary>
            <ReferralSideBar type={this.state.type} />
          </ErrorBoundary>
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTransferHistory: formData => getTransferHistory(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    getTransferHistoryLoader: state.transactions.getTransferHistoryLoader,
    getTransferHistoryList: state.transactions.getTransferHistoryList
  }),
  mapDispatchToProps
)(Transfer);
