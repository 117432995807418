import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const ALL_MARKET_COIN_24_HR_PRICE_SUCCESS =
  'ALL_MARKET_COIN_24_HR_PRICE_SUCCESS';
const ALL_MARKET_COIN_24_HR_PRICE_REQUESTED =
  'ALL_MARKET_COIN_24_HR_PRICE_REQUESTED';
const OPEN_ORDER_ACTIVE_TAB_UPDATE = 'OPEN_ORDER_ACTIVE_TAB_UPDATE';
const ALL_COINS_INPUT_TEXT = 'ALL_COINS_INPUT_TEXT';
const ALL_COINS_AUTO_SUGGEST_COIN_ID_UPDATE =
  'ALL_COINS_AUTO_SUGGEST_COIN_ID_UPDATE';
const SORT_BY_CHANGE_UPDATE = 'SORT_BY_CHANGE_UPDATE';
const SORT_BY_VOLUME_UPDATE = 'SORT_BY_VOLUME_UPDATE';
const SORT_BY_NAME_UPDATE = 'SORT_BY_NAME_UPDATE';
const SORT_BY_FAV_UPDATE = 'SORT_BY_FAV_UPDATE';
const initialState = {
  recentTradeUpdateObject: {},
  activeTab: '1',
  allCoinsInputText: '',
  sortByChange: false,
  sortByName: false,
  sortByVolume: false,
  sortByFav: false
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_MARKET_COIN_24_HR_PRICE_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case ALL_MARKET_COIN_24_HR_PRICE_SUCCESS:
      return {
        ...state,
        marketCoin24HrPriceList: action.marketCoinPrice,
        marketViewLoader: false
      };
    case OPEN_ORDER_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case ALL_COINS_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        allCoinsAutosuggestCurrencyId: action.allCoinsAutosuggestCurrencyId
      };
    case ALL_COINS_INPUT_TEXT:
      return {
        ...state,
        allCoinsInputText: action.allCoinsInputText
      };
    case SORT_BY_CHANGE_UPDATE:
      return {
        ...state,
        sortByChange: action.sortByChange
      };
    case SORT_BY_NAME_UPDATE:
      return {
        ...state,
        sortByName: action.sortByName
      };
    case SORT_BY_VOLUME_UPDATE:
      return {
        ...state,
        sortByVolume: action.sortByVolume
      };
    case SORT_BY_FAV_UPDATE:
      return {
        ...state,
        sortByFav: action.sortByFav
      };
    default:
      return state;
  }
};

export const allMarketCoin24HrPriceList = formData => dispatch => {
  dispatch({
    type: ALL_MARKET_COIN_24_HR_PRICE_REQUESTED
  });
  apiCaller('list/price/', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        // var marketCoinPrice = {};
        // _.map(response, obj => {
        //   if (!marketCoinPrice[obj.marketId]) {
        //     marketCoinPrice[obj.marketId] = {};
        //   }
        // 	marketCoinPrice[obj.marketId][obj.coinId] = obj;
        // });

        dispatch({
          type: ALL_MARKET_COIN_24_HR_PRICE_SUCCESS,
          marketCoinPrice: response
        });
      }
    }
  );
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};

export const allCoinsAutosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: ALL_COINS_AUTO_SUGGEST_COIN_ID_UPDATE,
    allCoinsAutosuggestCurrencyId: formData.allCoinsAutosuggestCurrencyId
  });
};

export const inputTextAllCoinsFunc = formData => dispatch => {
  dispatch({
    type: ALL_COINS_INPUT_TEXT,
    allCoinsInputText: formData.allCoinsInputText
  });
};

// export const sortByChangeUpdate = formData => dispatch => {
//   dispatch({
//     type: SORT_BY_CHANGE_UPDATE,
//     sortByChange: formData.sortByChange
//   });
// };

// export const sortByFavUpdate = formData => dispatch => {
//   dispatch({
//     type: SORT_BY_FAV_UPDATE,
//     sortByFav: formData.sortByFav
//   });
// };

// export const sortByNameUpdate = formData => dispatch => {
//   dispatch({
//     type: SORT_BY_NAME_UPDATE,
//     sortByName: formData.sortByName
//   });
// };

// export const sortByVolumeUpdate = formData => dispatch => {
//   dispatch({
//     type: SORT_BY_VOLUME_UPDATE,
//     sortByVolume: formData.sortByVolume
//   });
// };
