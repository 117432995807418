import _ from 'underscore';
import apiCaller from 'Utils/apiCallerNew';

const CHANGE_HEADER_MENU = 'CHANGE_HEADER_MENU';
const ALL_MARKET_COIN_DATA = 'ALL_MARKET_COIN_DATA';
const ALL_COIN_MARKET_DATA = 'ALL_COIN_MARKET_DATA';
const ALL_COIN_SYMBOL_DATA = 'ALL_COIN_SYMBOL_DATA';
const MARKET_CURRENCY_UPDATE = 'MARKET_CURRENCY_UPDATE';
const MARKET_UPDATE = 'MARKET_UPDATE';
const CURRENCY_UPDATE = 'CURRENCY_UPDATE';
const ORDER_FEE = 'ORDER_FEE';
const ALL_MARKET_COIN_24_HR_PRICE = 'ALL_MARKET_COIN_24_HR_PRICE';
const ALL_MARKET_COIN_24_HR_PRICE_REQUESTED =
  'ALL_MARKET_COIN_24_HR_PRICE_REQUESTED';
const FEE_REQUESTED = 'FEE_REQUESTED';
const FEE_SUCCESS = 'FEE_SUCCESS';

/**
 * Represents Redux Store Initial Values
 */
const initialState = {
  headerActiveMenu: 'exchange',
  allMarketCoinsListObj: {},
  autoSuggestForMarkets: {},
  autoSuggestForMarketsCoinSymbol: {},
  marketsCoinsListObj: {},
  coinsMarketsListObj: {},
  coinSymbolsObj: {},
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH',
  marketViewLoader: true,
  feesLoader: true,
  coinIdtoSymbolsObj: {},
  coinSymbolstoIdObj: {},
  marketCoin24HrPriceList: [],
  orderFee: '',
  feesData: {}
};

/**
 * Reducer
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_HEADER_MENU:
      return {
        ...state,
        headerActiveMenu: action.headerActiveMenu
      };
    case ALL_MARKET_COIN_DATA:
      return {
        ...state,
        autoSuggestForMarkets: action.autoSuggestForMarkets,
        autoSuggestForMarketsCoinSymbol: action.autoSuggestForMarketsCoinSymbol,
        allMarketCoinsListObj: action.allMarketCoins,
        marketsCoinsListObj: action.marketData
      };
    case ALL_COIN_MARKET_DATA:
      return {
        ...state,
        coinsMarketsListObj: action.coinData
      };
    case ALL_COIN_SYMBOL_DATA:
      return {
        ...state,
        coinSymbolsObj: action.coinSymbols,
        coinIdtoSymbolsObj: action.coinIdtoSymbols,
        coinSymbolstoIdObj: action.coinSymbolstoId,
        reactSelectOptions: action.reactSelectOptions
      };
    case MARKET_CURRENCY_UPDATE:
      return {
        ...state,
        activeMarketValue: action.activeMarketValue,
        activeCurrencyValue: action.activeCurrencyValue,
        activeMarketSymbol: action.activeMarketSymbol,
        activeCurrencySymbol: action.activeCurrencySymbol
      };
    case MARKET_UPDATE:
      return {
        ...state,
        activeMarketValue: action.activeMarketValue,
        activeMarketSymbol: action.activeMarketSymbol
      };
    case CURRENCY_UPDATE:
      return {
        ...state,
        activeCurrencyValue: action.activeCurrencyValue,
        activeCurrencySymbol: action.activeCurrencySymbol
      };
    case ALL_MARKET_COIN_24_HR_PRICE_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case ALL_MARKET_COIN_24_HR_PRICE:
      return {
        ...state,
        marketCoin24HrPriceListObj: action.marketCoinPrice,
        marketCoin24HrPriceList: action.response.data,
        marketViewLoader: false
      };
    case ORDER_FEE:
      return {
        ...state,
        orderFee: action.orderFee
      };
    case FEE_REQUESTED:
      return {
        ...state,
        feesLoader: true
      };
    case FEE_SUCCESS:
      return {
        ...state,
        feesLoader: false,
        feesData: action.response
      };
    default:
      return state;
  }
};

export const headerActiveMenuUpdate = headerActiveMenu => dispatch => {
  dispatch({
    type: CHANGE_HEADER_MENU,
    headerActiveMenu
  });
};

export const allCoinsSymbolData = formData => dispatch => {
  apiCaller('list/coin', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        const coinIdtoSymbols = _.indexBy(response, 'coinId');
        const coinSymbolstoId = _.indexBy(response, 'coinsymbol');
        const coinSymbols = {};
        const reactSelectOptions = [];
        let text;
        response.map(coinObj => {
          text = `${coinObj.coinname} (${coinObj.coinsymbol})`;
          reactSelectOptions.push({
            ...coinObj,
            id: coinObj.coinId,
            text
          });

          coinSymbols[coinObj.coinId] = { ...coinObj };
        });

        dispatch({
          type: ALL_COIN_SYMBOL_DATA,
          coinSymbols,
          coinIdtoSymbols,
          coinSymbolstoId,
          reactSelectOptions
        });
      }
    }
  );
};

export const allMarketCoinData = formData => dispatch => {
  apiCaller('list/market/coin', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        const marketData = {};
        const allMarketCoins = [];
        const autoSuggestForMarkets = {};
        const autoSuggestForMarketsCoinSymbol = {};
        response.map(marketObj => {
          allMarketCoins.push({
            value: marketObj._id,
            label: marketObj.marketdata[0].marketsymbol
          });
          marketData[marketObj._id] = { ...marketObj };
          marketData[marketObj._id].coins = {};
          marketData[marketObj._id].coinsArray = [];

          autoSuggestForMarkets[marketObj._id] = [];
          autoSuggestForMarketsCoinSymbol[marketObj._id] = [];

          marketObj.coins.coindata.map(coinObj => {
            marketData[marketObj._id].coins[coinObj.coinId] = coinObj;
            marketData[marketObj._id].coinsArray.push(coinObj);

            const text = `${coinObj.coinname} (${coinObj.coinsymbol})`;
            autoSuggestForMarkets[marketObj._id].push({
              ...coinObj,
              id: coinObj.coinId,
              text
            });
            autoSuggestForMarketsCoinSymbol[marketObj._id].push({
              ...coinObj,
              id: coinObj.coinId,
              text: coinObj.coinsymbol,
              marketview_text: `${coinObj.coinname} (${coinObj.coinsymbol})`
            });
          });
        });

        dispatch({
          type: ALL_MARKET_COIN_DATA,
          autoSuggestForMarkets,
          autoSuggestForMarketsCoinSymbol,
          allMarketCoins,
          marketData
        });
      }
    }
  );
};

export const allCoinMarketData = formData => dispatch => {
  apiCaller('list/coin/market', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        const coinData = {};
        response.map(coinObj => {
          coinData[coinObj._id] = { ...coinObj };
          coinData[coinObj._id].markets = {};
          coinObj.markets.marketdata.map(marketObj => {
            coinData[coinObj._id].markets[marketObj.marketId] = marketObj;
          });
        });

        dispatch({
          type: ALL_COIN_MARKET_DATA,
          coinData
        });
      }
    }
  );
};

export const marketCurrencyUpdate = formData => dispatch => {
  dispatch({
    type: MARKET_CURRENCY_UPDATE,
    activeMarketValue: formData.activeMarketValue,
    activeCurrencyValue: formData.activeCurrencyValue,
    activeMarketSymbol: formData.activeMarketSymbol,
    activeCurrencySymbol: formData.activeCurrencySymbol
  });
};

export const activeMarketValueChange = formData => dispatch => {
  dispatch({
    type: MARKET_UPDATE,
    activeMarketValue: formData.activeMarketValue,
    activeMarketSymbol: formData.activeMarketSymbol
  });
};

export const activeCurrencyValueChange = formData => dispatch => {
  dispatch({
    type: CURRENCY_UPDATE,
    activeCurrencyValue: formData.activeCurrencyValue,
    activeCurrencySymbol: formData.activeCurrencySymbol
  });
};

export const allMarketCoin24HrPriceList = formData => dispatch => {
  dispatch({
    type: ALL_MARKET_COIN_24_HR_PRICE_REQUESTED
  });
  apiCaller('list/price/', 'get', formData, false, false, false).then(
    response => {
      if (response.error === 0) {
        const marketCoinPrice = {};
        _.map(response.data, obj => {
          if (!marketCoinPrice[obj.marketId]) {
            marketCoinPrice[obj.marketId] = {};
          }
          marketCoinPrice[obj.marketId][obj.coinId] = obj;
        });

        dispatch({
          type: ALL_MARKET_COIN_24_HR_PRICE,
          marketCoinPrice,
          response
        });
      }
    }
  );
};

export const orderFeeFunc = formData => dispatch => {
  apiCaller('order/fee', 'get', formData, false, false, false).then(
    response => {
      if (response.error === 0) {
        dispatch({
          type: ORDER_FEE,
          orderFee: response.fee
        });
      }
    }
  );
};

export const getFeesLimits = formData => dispatch => {
  dispatch({
    type: FEE_REQUESTED
  });
  apiCaller('limitfee', 'get', formData, false, false, false).then(response => {
    if (response.error === 0) {
      dispatch({
        type: FEE_SUCCESS,
        response
      });
    }
  });
};
