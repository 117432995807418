import React, { Component } from 'react';
import './initial-idea.css';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Fullpage from './fullpage';
import Arrows from './Arrows';

const loc = window.location.href;
const pos = loc.search('#');
const prev = loc.substr(0, pos + 1);
const res = loc.substr(pos + 1);
class Crypto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hid: false
    };
  }

  render() {
    return (
      <>
        <main
          id="landing-cryptocurrencies"
          // onMouseMove={this.props.enableScroll()}
          //onMouseEnter={this.props.enableScroll()}
          //onMouseLeave={this.props.enableScroll()}
        >
          <div className="space mb-auto" />
          <div className="row no-gutter text-center">
            <div className="col-md-12 col-sm-12">
              <h1 className="pb-2">Overview</h1>
            </div>
          </div>
          <div className="container pb-1r word-break scrollable">
            <div className="row no-gutter pd-2 ">
              <div className="col-md-6 col-sm-12 ">
                <div className="row no-gutters mt-5 ">
                  <div className="col-md-2 pr-2 ">
                    <img
                      className="crypto-icons"
                      src={`${process.env.PUBLIC_URL}/assets/images/-e-Layer 4.png`}
                    />
                  </div>
                  <div className="col-md-10 sm-text-center">
                    <h2>Smart token analysis</h2>
                    <p>
                      Analyse before trading. Learn about all supported
                      cryptocurrencies right and make informed decisions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row no-gutters mt-5">
                  <div className="col-md-2">
                    <img
                      className="crypto-icons"
                      src={`${process.env.PUBLIC_URL}/assets/images/-e-Layer 6.png`}
                    />
                  </div>
                  <div className="col-md-10 sm-text-center">
                    <h2>Every time we earn, you earn!</h2>
                    <p>
                      The ThroughBit Loyalty Program has been designed in a way
                      where every verified user stands a chance to earn referral
                      bonus, even if one hasn’t referred anyone. We use a
                      proprietary algorithm developed to ensure fair attribution
                      of referral codes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row no-gutter">
              <div className="col-md-6 col-sm-12">
                <div className="row no-gutters mt-5">
                  <div className="col-md-2">
                    <img
                      className="crypto-icons"
                      src={`${process.env.PUBLIC_URL}/assets/images/-e-0.png`}
                    />
                  </div>
                  <div className="col-md-10 sm-text-center">
                    <h2>Grow your wallet balance</h2>
                    <p>
                      We understand trading is not for the faint-hearted. To
                      help you and protect your trades, we are developing
                      various trading tools to help you make those crucial
                      trading calls.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="row no-gutters mt-5">
                  <div className="col-md-2">
                    <img
                      className="crypto-icons"
                      src={`${process.env.PUBLIC_URL}/assets/images/-e-Layer 7.png`}
                    />
                  </div>
                  <div className="col-md-10 sm-text-center">
                    <h2>Trade your way</h2>
                    <p>
                      How you use your funds is entirely your choice. Margin
                      trading, lending, peer-to-peer trading, decentralised
                      exchange- whatever is your choice, it’s on ThroughBit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Crypto;
