import React, { Component } from 'react';
import apiCaller from 'Utils/apiCallerNew';
import './index.css';
import Highcharts from 'highcharts/highstock';
import {
  HighchartsChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  Title,
  AreaSeries,
  Tooltip,
  Subtitle
} from 'react-jsx-highstock';
import _ from 'underscore';

class MarketDepth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      askData: [],
      bidData: [],
      dataLoader: true,
      symbol: this.props.symbol,
      activeMarketValue: this.props.activeMarketValue,
      activeCurrencyValue: this.props.activeCurrencyValue,
      nextSocketMessage: this.props.nextSocketMessage
    };
    this.fetchDepthData = this.fetchDepthData.bind(this);
  }

  componentDidMount() {
    this.fetchDepthData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      this.setState(
        {
          activeMarketValue: nextProps.activeMarketValue,
          activeCurrencyValue: nextProps.activeCurrencyValue,
          activeMarketSymbol: nextProps.activeMarketSymbol,
          activeCurrencySymbol: nextProps.activeCurrencySymbol,
          dataLoader: true
        },
        this.fetchDepthData
      );
    }

    if (this.props.symbol !== nextProps.symbol) {
      this.setState({ symbol: nextProps.symbol });
    }

    if (this.props.nextSocketMessage !== nextProps.nextSocketMessage) {
      this.setState({ nextSocketMessage: nextProps.nextSocketMessage });
      this.checkStatus(nextProps.nextSocketMessage);
    }
  }

  insertionSortAsk(items) {
    for (var i = 0; i < items.length; i++) {
      let value = items[i];
      // store the current item value so it can be placed right
      for (var j = i - 1; j > -1 && items[j][0] > value[0]; j--) {
        // loop through the items in the sorted array (the items from the current to the beginning)
        // copy each item to the next one
        items[j + 1] = items[j];
      }
      // the last item we've reached should now hold the value of the currently sorted item
      items[j + 1] = value;
    }
    return items;
  }

  insertionSortBid(items) {
    for (var i = 0; i < items.length; i++) {
      let value = items[i];
      // store the current item value so it can be placed right
      for (var j = i - 1; j > -1 && items[j][0] < value[0]; j--) {
        // loop through the items in the sorted array (the items from the current to the beginning)
        // copy each item to the next one
        items[j + 1] = items[j];
      }
      // the last item we've reached should now hold the value of the currently sorted item
      items[j + 1] = value;
    }
    return items;
  }

  arraySearch(data, element) {
    let arr = _.unzip(data)[0];
    if (arr === undefined) arr = [];
    const loc = arr.indexOf(element);
    return loc;
  }

  checkStatus(message) {
    var asks = [...this.state.askApiData];
    var bids = [...this.state.bidApiData];
    if (message.status === 'cancelled') {
      if (message.operation == true) {
        var loc = this.arraySearch(bids, message.price);
        bids[loc][1] = bids[loc][1] - message.volume;
        if (bids[loc][1] <= 0) {
          bids.splice(loc, 1);
        }
      } else {
        var loc = this.arraySearch(asks, message.price);
        asks[loc][1] = asks[loc][1] - message.volume;
        if (asks[loc][1] <= 0) {
          asks.splice(loc, 1);
        }
      }
    } else if (message.status === 'new') {
      if (message.operation == true) {
        var loc = this.arraySearch(bids, message.price);
        if (loc == -1) {
          bids.push([message.price, message.volume]);
        } else {
          bids[loc][1] = bids[loc][1] + message.volume;
        }
      } else {
        var loc = this.arraySearch(asks, message.price);
        if (loc == -1) {
          asks.push([message.price, message.volume]);
        } else {
          asks[loc][1] = asks[loc][1] + message.volume;
        }
      }
    } else if (message.status === 'executed') {
      if (message.operation == false) {
        var loc = this.arraySearch(bids, message.price);
        bids[loc][1] = bids[loc][1] - message.volume;
        if (bids[loc][1] <= 0) {
          bids.splice(loc, 1);
        }
      } else {
        var loc = this.arraySearch(asks, message.price);
        asks[loc][1] = asks[loc][1] - message.volume;
        if (asks[loc][1] <= 0) {
          asks.splice(loc, 1);
        }
      }
    }
    this.setState({
      askApiData: JSON.parse(JSON.stringify(asks)),
      bidApiData: JSON.parse(JSON.stringify(bids))
    });
    this.calculateData(asks, bids);
  }

  calculateData(askData1, bidData1) {
    const a = [...askData1];
    const b = [...bidData1];

    const askData = this.insertionSortAsk(a);
    const bidData = this.insertionSortBid(b);

    var askDepthTotal = 0;
    var bidDepthTotal = 0;

    for (var i = 0; i < askData.length; i++) {
      askDepthTotal += askData[i][1];
      askData[i][1] = askDepthTotal;
    }

    for (var j = 0; j < bidData.length; j++) {
      bidDepthTotal += bidData[j][1];
      bidData[j][1] = bidDepthTotal;
    }
    // console.warn('Ask Data, BidData', askData,bidData)
    this.setState({
      askData: [...askData],
      bidData: [...bidData],
      dataLoader: false
    });
  }

  fetchDepthData() {
    const { activeMarketValue, activeCurrencyValue } = this.state;

    apiCaller(
      `marketdepth/${activeMarketValue}/${activeCurrencyValue}`,
      'get',
      '',
      false,
      false,
      false
    )
      // fetch('https://api.liqui.io/api/3/depth/eth_btc')
      // .then(res => res.json())
      .then(data => {
        if (
          data[
            'data'
          ] /* &&
          data['data'].asks.length >= 1 &&
          data['data'].bids.length >= 1 */
        ) {
          var askData = data['data']['asks'];
          var bidData = data['data']['bids'];

          if (askData[0][0] == 0 && askData[0][1] == 0) {
            askData = [];
          }
          if (bidData[0][0] == 0 && bidData[0][1] == 0) {
            bidData = [];
          }
          this.setState({
            askApiData: JSON.parse(JSON.stringify(askData)),
            bidApiData: JSON.parse(JSON.stringify(bidData))
          });

          this.calculateData(askData, bidData);
        }
      });
  }

  render() {
    const { askData, bidData, symbol, dataLoader } = this.state;
    const { activeMarketSymbol, activeCurrencySymbol } = this.props;
    const initChartOptions = { height: this.props.height };
    const height = this.props.height;

    const plotOptions = {
      area: {
        // height: this.props.height,
        softThreshold: true,
        marker: {
          radius: 0
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 3
          }
        },
        threshold: null
      }
    };

    if (dataLoader) {
      return (
        <div style={{ height: height - 30 }}>
          <div className="loading-o" />
        </div>
      );
    } else if (askData.length == 0 && bidData.length == 0) {
      return (
        <div style={{ height: height }}>
          <div className="unlogged-action">No Data</div>
        </div>
      );
    } /* if (
      askData.length > 0 &&
      bidData.length > 0 &&
      askData[0][0] >= 0 &&
      askData[0][1] >= 0 &&
      bidData[0][0] >= 0 &&
      bidData[0][1] >= 0
    ) */ else {
      return (
        <HighchartsChart plotOptions={plotOptions} chart={initChartOptions}>
          <Chart zoomType="xy" height={height} />
          {/* <Subtitle style={{ color: '#FFFFFF' }}>
						{document.ontouchstart === undefined
							? 'Click and drag in the plot area to zoom in'
							: 'Pinch the chart to zoom in'}
					</Subtitle> */}
          <Tooltip
            valueDecimals={2}
            headerFormat="<table>"
            footerFormat="</table>"
            pointFormat={`<tr><td style='color: #000000;'>Price:</td><td style='text-align: right;color: #000000;'>{point.x}</td></tr><tr><td style='color: #000000;'>Sum (${activeCurrencySymbol}):</td><td style='text-align: right;color: #000000;'>{point.y}</td></tr><tr><td style='color: #000000;'>Sum (${activeMarketSymbol}):</td><td style='text-align: right;color: #000000;'>{point.x * point.y}</td></tr>`}
            shared
            useHTML
          />

          <XAxis
            labels={{ style: { color: '#4affcb' } }}
            type="logarithmic"
            width="50%"
            gridLineWidth={1}
          />

          <XAxis
            labels={{ style: { color: '#ff3774' } }}
            type="logarithmic"
            offset={0}
            left="50%"
            width="50%"
            gridLineWidth={1}
          />

          <YAxis gridLineWidth={1}>
            <AreaSeries
              name="Bids"
              data={bidData}
              xAxis={0}
              color="rgb(0,140,60)"
              fillColor="#4affcb66"
            />
            <AreaSeries
              name="Asks"
              data={askData}
              xAxis={1}
              color="rgb(177, 0, 0)"
              fillColor="#ff377466"
            />
          </YAxis>
        </HighchartsChart>
      );
    }
  }
}

export default withHighcharts(MarketDepth, Highcharts);
