const balanceFormatFunc = (amount, currencySymbol) => {
  var myBalanceAmount;
  var beforeDecimal = amount.toString().split('.')[0];
  var afterDecimal = amount.toString().split('.')[1];
  var totalLength = amount.toFixed(8).length + currencySymbol.length;
  if (totalLength > 14) {
    myBalanceAmount = amount
      .toFixed(14 - currencySymbol.length - beforeDecimal.length)
      .slice(0, 14 - currencySymbol.length);
  } else {
    myBalanceAmount = amount.toFixed(8);
  }
  return myBalanceAmount;
};

export default { balanceFormatFunc };
