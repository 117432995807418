import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatePassword } from './updatePasswordReducer';
import { push } from 'connected-react-router';
import NavBar from './navBar';

class PasswordUpdated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      message: '',
      error: '',
      time: {},
      seconds: 3
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  componentDidMount() {
    const loc = window.location.href;
    // console.log('loc - ', loc);
    var pos = loc.indexOf('?');
    const hash = loc.substring(pos + 1 + 5);
    this.setState({ hash });
    // console.log('hash - ', hash);

    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.updatePasswordResponse !== nextProps.updatePasswordResponse
    ) {
      this.setState(
        { updatePasswordResponse: nextProps.updatePasswordResponse },
        this.updatePasswordResponseCheck
      );
    }
  }

  updatePasswordResponseCheck() {
    const { updatePasswordResponse } = this.state;
    if (updatePasswordResponse.error === 0) {
      this.setState(
        {
          showMessage: true,
          message: updatePasswordResponse.message,
          error: ''
        },
        this.startTimer
      );
    } else {
      // this.setState({ error: updatePasswordResponse.message });
      this.setState({
        showMessage: false,
        message: '',
        error: updatePasswordResponse.message
      });
    }
  }

  handleLogin() {
    this.props.changePage('/login');
  }

  render() {
    return (
      <>
        <NavBar />
        <div
          className="jumbotron d-flex align-items-center"
          style={{ height: '85vh' }}
        >
          <div className="container text-center w-50">
            <div className="row no-gutters justify-content-center settings-form">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group mt-4 mb-4">
                  <h1 className="cover-heading mb-3" style={{ color: 'green' }}>
                    {this.state.showMessage ? this.state.message : null}
                  </h1>
                  <p className="lead">
                    You'll be automatically redirected to login page in{' '}
                    {this.state.time.s} seconds
                  </p>
                  {this.state.time.s == 0 ? this.handleLogin() : ''}
                  <button
                    type="submit"
                    className="btn btn-blue"
                    onClick={this.handleLogin}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    updatePasswordResponse: state.updatePassword.updatePasswordResponse
  }),
  mapDispatchToProps
)(PasswordUpdated);
