/**
 * Footer Component
 * Component
 */

import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap';
import 'App/footer.css';
import { getFeesLimits } from 'App/appReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class FooterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kyc: true,
      tnc: false,
      fnl: false,
      fnlData: '',
      cookies: false,
      warnings: false,
      referral: false,
      aboutUs: false,
      pp: false,
      team: false,
      loyalty: false,
      direct: false,
      modal: true,
      feesData: {}
    };
    this.unsetAll = this.unsetAll.bind(this);
    this.set = this.set.bind(this);
  }

  componentDidMount() {
    this.props.getFeesLimits();
  }

  componentWillReceiveProps(nextProps) {
    // if(this.props.pre != nextProps.pre){
    //   this.setState({modal:this.props.modall})
    //     this.set(nextProps.pre)
    // }else{
    //   this.setState({modal:this.props.modall})
    //   this.set(this.props.pre)
    // }

    if (this.props.pre) {
      // this.setState({ modal: this.props.modall });
      this.set(this.props.pre);
    }

    if (this.props.feesData !== nextProps.feesData) {
      if (nextProps.feesData.error == 0) {
        this.setState({
          feesData: nextProps.feesData.data
        });
      }
    }
  }

  unsetAll() {
    this.setState({
      kyc: false,
      tnc: false,
      fnl: false,
      cookies: false,
      warnings: false,
      referral: false,
      aboutUs: false,
      pp: false,
      team: false,
      loyalty: false,
      direct: false,
      contact: false
    });
  }

  set(key) {
    // document.getElementById("#footer").scrollIntoView()
    this.unsetAll();

    switch (key) {
      case 'kyc':
        this.setState({
          kyc: true
        });
        break;
      case 'tnc':
        this.setState({
          tnc: true
        });
        break;
      case 'warnings':
        this.setState({
          warnings: true
        });
        break;
      case 'fnl':
        this.setState({
          fnl: true
        });
        break;
      case 'cookies':
        this.setState({
          cookies: true
        });
        break;
      case 'referral':
        this.setState({
          referral: true
        });
        break;
      case 'aboutUs':
        this.setState({
          aboutUs: true
        });
        break;
      case 'pp':
        this.setState({
          pp: true
        });
        break;
      case 'team':
        this.setState({
          team: true
        });
        break;
      case 'loyalty':
        this.setState({
          loyalty: true
        });
        break;
      case 'direct':
        this.setState({
          direct: true
        });
        break;
      case 'contact':
        this.setState({
          contact: true
        });
        break;
    }
  }

  toggle = () => {
    // this.setState({
    //   modal: false
    // });

    this.props.untoggle();
  };

  render() {
    return (
      <>
        <Modal
          backdrop="static"
          id="footer"
          isOpen={this.props.modall}
          toggle={this.toggle}
          className={this.props.className}
          style={{ margin: '2rem' }}
        >
          <ModalHeader
            color="#fff"
            style={{ color: '#fff !important' }}
            toggle={this.toggle}
          />
          <ModalBody>
            <div>
              <div className="row no-gutters">
                <div className="col-lg-3 mt-1">
                  <div className="row no-gutters sidebar-content">
                    <div className="sidebar-sticky">
                      <nav
                        id="navigationBar"
                        className="navbar navbar-expand-lg"
                      >
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-toggle="collapse"
                          data-target="#footerDropDown"
                          aria-controls="footerDropDown"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <i
                            className="fa fa-navicon"
                            style={{ fontSize: '35px', color: 'white' }}
                          />
                          {/* <span className="navbar-toggler-icon"  /> */}
                        </button>
                        <div
                          className="collapse navbar-collapse z-index bg-footer "
                          id="footerDropDown"
                        >
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.kyc
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('kyc')}
                              >
                                AML/KYC
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.pp ? 'nav-link active' : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('pp')}
                              >
                                Privacy Policy
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.tnc
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('tnc')}
                              >
                                Terms &amp; Conditions
                              </a>
                            </li>
                            {/* <li className="nav-item">
                              <a
                                className={
                                  this.state.fnl
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('fnl')}
                              >
                                Fees &amp; Limits
                              </a>
                            </li> */}
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.referral
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('referral')}
                              >
                                Referral
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.aboutUs
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('aboutUs')}
                              >
                                About us
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.warnings
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('warnings')}
                              >
                                Risk &amp; warnings
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.cookies
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('cookies')}
                              >
                                Cookie Statement
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className={
                                  this.state.direct
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('direct')}
                              >
                                Direct
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.loyalty
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('loyalty')}
                              >
                                Loyalty
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.contact
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                href="#"
                                onClick={() => this.set('contact')}
                              >
                                Contact Us
                              </a>
                            </li>
                          </ul>
                        </div>
                      </nav>{' '}
                    </div>
                  </div>
                </div>
                {this.state.kyc ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>AML/KYC</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        We believe in being self-regulated and follow certain
                        customer identification procedures for opening of
                        accounts and monitoring transactions of suspicious
                        nature, for the purpose of reporting it to the
                        appropriate authority. We are committed to ensure that
                        our website or services are not used to facilitate money
                        laundering or the funding of terrorism or any other
                        criminal activities. To fulfill this goal, we have
                        designed our own know-your-customer/KYC and anti-money
                        laundering/AML information acquiring system. This is
                        done in order to safeguard our customers and ensure
                        continuous and clean barter.
                      </p>

                      <p>
                        As a part of the identification procedure, we need to
                        obtain certain customer identification documents from
                        every customer. These processes make us know who is
                        paying us the money and to whom we are sending it to,
                        without which our website and services can be used for
                        money laundering and other illicit purposes.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.direct ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Direct</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        ThroughBit Direct is an exchange service for trading
                        cryptocurrencies that allows users to trade directly
                        amongst each other without having to placing an order in
                        the market. ThroughBit merely acts as the escrow,
                        ensuring a seamless and secure transaction. Direct
                        trades can be crypto-crypto or crypto-fiat. Direct is
                        especially useful in 2 scenarios:- Scenario 1:- When a
                        user wants to trade between two cryptocurrencies,
                        neither of which are the ‘preliminary cryptocurrency’ in
                        a market. In such cases, the user will first have to
                        exchange their cryptocurrency for a primary
                        cryptocurrency and subsequently use the primary
                        cryptocurrency to acquire the desired cryptocurrency. In
                        such cases, the user ends up paying the transaction fee
                        twice. To avoid such cases, ThroughBit provides a ‘trade
                        wall’ on which the user can make their offer public and
                        transact. Example: If a user wants to exchange XLM for
                        ZRX, the user will first have to exchange the XLM for
                        BTC/ETH and then exchange the BTC/ETH for ZRX. With
                        Direct, the user may now put up an offer for directly
                        exchanging XLM for ZRX. Any other user looking to
                        exchange their ZRX for XLM can pick up this offer.
                        ThroughBit will vouch for the availability of the funds
                        with both the parties and release the funds to the
                        respective users on acceptance of the offer. Scenario
                        2:- When a user wishes to convert cryptocurrencies to
                        Fiat. Crypto-fiat conversion is a point of concern
                        globally. The ever changing regulatory climate only adds
                        to the woes. A critical aspect of using Fiat to trade in
                        cryptocurrencies is that there are only a few
                        cryptocurrencies that can be exchanged for Fiat. With
                        Direct, you can not only indulge in peer-to-peer crypto
                        trades, but also in crypto-fiat trades. This essentially
                        creates a case of having the maximum possible trading
                        pairs.ThroughBit holds the buyer’s cryptocurrency till
                        the seller confirms the receipt of the Fiat.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.loyalty ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Loyalty</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        “Loyalty is a two-way street. If we are asking it from
                        you, then you are getting it from us”.
                      </p>

                      <p>
                        At ThroughBit, we take Loyalty very seriously. The
                        ThroughBit Loyalty Program(TLP) has been designed to
                        give our users a sense of belonging and ownership. It
                        has been designed such that every time ThroughBit earns
                        something, the user who was instrumental in making this
                        earning happen will also stand to earn something. This
                        is our way of giving back. This is our way of being
                        loyal to you.
                      </p>
                      <p>
                        In the following few lines, you will be understand how
                        the program works.
                      </p>
                      <p>
                        TLP is a simple referral module where users who wish to
                        refer ThroughBit to any of their friends and family will
                        be rewarded with a certain portion of the transaction
                        fee that ThroughBit earns from the transactions that
                        these referred users conduct.
                      </p>
                      <p>
                        On signing up, completing KYC and getting the account
                        verified, every user will be given a unique ‘Referral
                        Code’, which the user can share to be used when a
                        ‘referred’ user signs up on ThroughBit. Subsequently, on
                        the first transaction the ‘referred’ user makes, the
                        user gets a 20% discount on the transaction fee and the
                        ‘referee’ stands to make 20% of the ‘after discount’
                        transaction fee. On every subsequent transaction carried
                        out by the ‘referred’ user the ‘referee’ stands to make
                        20% of the transaction fee.
                      </p>
                      <p>
                        There are no limits on how many users can be referred by
                        a single ‘referee’.
                      </p>
                      <p>
                        There may be times when a user is signing up on
                        ThroughBit, but doesn’t have a ‘Referral Code’ handy. To
                        avoid the user missing out on the discount on the first
                        transaction, we at ThroughBit, have developed a unique
                        program, ‘The Handshake’.
                      </p>
                      <p>
                        While signing up, if a user doesn’t have a “Referral
                        Code’, they will be allowed to generate a random ‘Code’
                        and use it to avail the ‘First Transaction Discount’.
                        The ‘Code’ may belong to any of our users and is
                        selected by a unique algorithm developed by us. Every
                        verified user on ThroughBit has a ‘Referral Code’. So,
                        if we have 10,000 users, there will be 10,000 codes.
                        When the algorithm in invoked to select a code at
                        random, it picks one of these 10,000 codes but like we
                        mentioned, we take loyalty seriously. If you are a user
                        that has brought others to our platform, then you must
                        have a higher chance of your code being picked at
                        random. So, for every ‘referred’ user of yours that
                        makes a transaction you get 1 ‘Probability Point’. Let
                        us assume that you have referred 5 users that have made
                        a transaction. This means that you have accumulated 5
                        ‘Probability Points’. Let us also assume that no other
                        user on the platform has refereed anyone. So, when a
                        user without a ‘Code’ invokes the algorithm, the chance
                        of your ‘Code’ being picked is 5 times higher than any
                        other code. This is our way of making sure everyone has
                        a chance at earning a part of our earnings, and the ones
                        that are making an effort to bring more users to the
                        platform have a higher chance.
                      </p>
                      <p>
                        To make you better understand how the earnings will be
                        disbursed, read how the ‘transaction fee’ is collected.
                      </p>
                      <p>
                        When a user makes a trade on ThroughBit, assume the user
                        is exchanging BTC for ETH, the fee on our end is
                        collected in terms of the asset being delivered. In this
                        case, ETH will be deducted from the final amount as
                        transaction fee. A percentage of the ETH that ThroughBit
                        collects will be credited to the person that referred
                        the user.
                      </p>
                      <p>
                        To put it in perspective, if a user referred by you puts
                        an order to buy 30ETH using 1 BTC and the order gets
                        executed, the user gets 29.955 ETH. 0.045 ETH is
                        ThroughBit’s transaction fee. 20% of this amount, 0.009
                        ETH, will be credited to you, the person that referred
                        the particular user. Before you realise, through your
                        referrals, we would have helped you create a neat little
                        portfolio for yourself. We intend to make our users an
                        integral part of our business and this is the first step
                        towards integrating all of you into our system.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.team ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Team</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        We don’t have a lot to tell about ourselves other than
                        the fact that we are working really hard to build
                        products that will put a smile on our users’ faces. We
                        come from varied backgrounds but have a love for
                        technology and simplified solutions in common.
                      </p>

                      <p>
                        Kuldeep N.B. - Software Engineer <br />
                        Arpit Gupta- Software Engineer <br />
                        Dipti Narwade- Software Engineer <br />
                        Chirag Jain- Software Engineer <br />
                        Sandeep Nair- Software Engineer
                        <br />
                        Nithya Rakesh- Trade Analyst <br />
                        Raghuram- Designer
                        <br />
                        Divyaranjan- Designer <br /> Kumaraswamy B.E. - Finance
                        & Operations <br /> Rahul Murali - Brand Manager <br />{' '}
                        Parth Soparna- Marketing Manager <br /> Dhaval Chauhan-
                        Security Analyst <br />
                        Anandaprabu Rajendran - Co-Founder <br /> Mohammed
                        Roshan - Co-Founder <br /> Abhishek Gopal - Co-Founder
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.pp ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Privacy Policy</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <h3>Date of Last Revision: 11 April 2018</h3>
                      <p>
                        Thank you for visiting ThroughBit Technologies Private
                        Limited (“ThroughBit” or “We” or “Us” or “Company”). We
                        recognise the importance of taking reasonable measures
                        to protect the security and privacy of information
                        derived from the use of our web platforms and mobile
                        platforms (including our website/ mobile site/ mobile
                        application etc). This document will, therefore, explain
                        our privacy practices, so you will understand how we
                        collect and use information or data that we obtain from
                        you.
                      </p>

                      <p>
                        This is a Privacy Policy Notice and Contract (“privacy
                        policy” or “this policy”) that informs and explains to
                        you the collection, use, storage, protection and sharing
                        of data or information that may be obtained through your
                        use of ThroughBit services through its website, web
                        application, mobile site, mobile application or by any
                        other electronic means (“services”).
                      </p>

                      <p>
                        This privacy policy is an electronic record in the form
                        of an electronic contract under the Information
                        Technology Act, 2000 (“the Act”) and the applicable
                        rules made under the Act. This policy does not require
                        any physical, electronic or digital signature.
                      </p>

                      <p>
                        This privacy policy, and our Terms and Conditions,
                        jointly and severally constitute a legally binding and
                        enforceable agreement between you and us. As you review
                        this policy, be sure to read it along with ThroughBit
                        Terms and Conditions, so you will understand the nature
                        of your transaction with us in entirety. The terms of
                        this privacy policy will be effective upon your
                        acceptance of them, either directly by clicking on the
                        “I Accept and Agree” button, or indirectly, by use of
                        this website or ThroughBit services. By using our web or
                        mobile platforms and ThroughBit’s services, you indicate
                        that you understand, agree and consent to this privacy
                        policy. We advise you to read this policy carefully. If
                        you do not agree with the terms of this privacy policy,
                        please do not use our services.
                      </p>

                      <h3>INFORMATION COLLECTION, USE AND SHARING</h3>
                      <p>
                        ThroughBit strives to ensure that the collection, use
                        and retention of information is limited to what is
                        necessary and optimal to provide you with our most
                        efficient service and to help us improve and develop our
                        services to cater to your usage requirements. We collect
                        and use two types of Information: Personally
                        Identifiable Information (PII) and Non-Personal
                        Information (NPI). Personally Identifiable Information
                        is any information that can be used to distinguish or
                        trace an individual’s identity. Non-Personal Information
                        is any information that does not directly lead to the
                        identification of a specific individual. “Information”
                        includes all data in its raw and unorganized form
                        derived from your use of our services.
                      </p>

                      <h3>
                        Collection, Use and Sharing of PII
                        <br />
                        When and Why Is PII Collected
                      </h3>
                      <p>
                        We collect most PII from you directly, when you register
                        with our website/mobile site/mobile App. During
                        registration, we ask for your identification and contact
                        details, such as your full name, postal address,
                        demographic details, email address, IP address, phone
                        number etc. We also collect PII when you register with
                        us on our other services and communication, such as
                        publications, subscriptions, e-mails, newsletters, RSS
                        Feeds, alerts, SMS text alert among other technologies
                        and communications. In addition, we may collect and
                        retain PII obtained when you send us comments,
                        questions, feedback, messages, job or internship
                        applications with resumes or when you enter into any
                        contract with us.
                      </p>

                      <p>
                        We are committed to taking all reasonable measures to
                        ensure that transactions through our services are
                        transparent and not used for money laundering or further
                        any illegal transaction. As part of this measure, we may
                        also ask for your PAN Card details and other relevant
                        customer identification proof. For more information,
                        please review our
                        <a href onClick={() => this.set('kyc')}>
                          AML/KYC details.{' '}
                        </a>
                      </p>

                      <p>
                        You are under no legal obligation to provide us any PII.
                        However, your refusal to provide PII details may prevent
                        you from using our services. By registering with our
                        website/mobile site/ mobile app and any and all of our
                        services, you consent to the collection, use and
                        disclosure of your PII as detailed in this policy.
                      </p>

                      <h3>How is PII Used</h3>
                      <p>
                        We use your PII to help us provide, develop and support
                        our services, so we can process transactions and
                        communicate with you easily, while consistently
                        improving, updating and developing our services based on
                        our increased understanding of your usage and
                        requirements. Chiefly, we use PII for the following,
                        among other, purposes:
                      </p>
                      <h3>a) Processing</h3>
                      <p>transactions that form part of our services;</p>
                      <h3>b) Communicating</h3>
                      <p>
                        with you by sending periodic emails, newsletters, SMS
                        with updates, promotions, company news, policy review
                        notices, updating you on our services and products and
                        sending you our marketing material or communication;
                      </p>

                      <h3>c) Developing and Improving </h3>
                      <p>
                        our services by customizing and measuring ThroughBit
                        Service and the content and layout of our Website and
                        applications;
                      </p>

                      <h3>d) Understanding </h3>
                      <p>
                        our users and usage of our services by analyzing,
                        generating statistics and measuring usage interests,
                        requirements and patterns, including user’s
                        requirements, user demographics, trends, usage patterns
                        and goals, website usage and traffic patterns.
                      </p>

                      <h3>e) Preventing</h3>
                      <p>illegal transactions or activities.</p>

                      <h3>f) Securing </h3>
                      <p>
                        our website/mobile site/mobile application and your
                        account and membership of our services.
                      </p>

                      <h3>g) Verifying </h3>
                      <p>
                        {' '}
                        your identity with third party electronic identification
                        providers.
                      </p>

                      <h3>How is PII Shared </h3>
                      <p>
                        As a matter of policy, we minimize sharing of
                        information and do not share PII with any third-party
                        for marketing or promotional purposes. We disclose PII
                        in the following manner:
                      </p>

                      <h3>Information in Public Domain: </h3>
                      <p>
                        PII on our website/mobile site/mobile app that is
                        visible to the public or other users cannot be
                        protected. We advise you to not provide such information
                        that you do not wish to disclose to other users or the
                        public.
                      </p>

                      <h3>Surveys:</h3>
                      <p>
                        To better understand what our users need and to help us
                        improve systematically, we may periodically conduct
                        surveys. Any Personal Information that you provide us
                        with in your participation of the survey will be used
                        solely to study, derive findings and make improvements
                        based on the survey. We will only share survey related
                        data and information to third parties in its anonymous,
                        non-personally-identifying or aggregated form.
                      </p>

                      <h3>Employees: </h3>
                      <p>
                        All stored PII will be disclosed to our team including
                        its employees, staff, administrators, customer service
                        personnel, consultants, contractors and affiliates. All
                        consultants or parties working on temporary engagements,
                        who have access to PII are bound by non-disclosure
                        agreements.
                      </p>

                      <h3>Legally Mandated Disclosure: </h3>
                      <p>
                        We will disclose PII if we are required to do so by any
                        legal mandate, such as a statutory requirement, or in
                        response to court summons or order. To protect the
                        safety and security of our services and to protect the
                        integrity of online transactions, we retain the
                        discretion to disclose PII as is reasonably required to
                        protect against fraud, theft or breach of rights of
                        other users, third parties or public. We also retain the
                        discretion to disclose PII as is reasonably and
                        proportionally required to prevent or counter any attack
                        or abuse of our service or other systems or in case of
                        any sign or breach of our or other systems to abuse the
                        systems or violate applicable laws.
                      </p>

                      <h3>Resolving Issues:: </h3>
                      <p>
                        When you face any error or troubleshoot or face any
                        issues using our services, we may access your account
                        information stored with us under some circumstances to
                        better understand the issue. Our representative, at such
                        time, will have access to your PII. However, at no such
                        time will any of our representatives have access to your
                        password.
                      </p>

                      <h3>Business or Structural Changes: </h3>
                      <p>
                        We reserve the right to disclose PII to any entity owned
                        or controlled by our Promoters and to transfer all PII
                        in our possession to related or resultant entities in
                        case of a merger, acquisition, bankruptcy or other sale
                        or alienation of any or all the Company’s assets.
                        Besides any legally mandated disclosure, the use and
                        disclosure of all transferred PII will be subject to
                        this policy. Upon any structural changes, if there is a
                        new privacy policy, you will be subject to such new
                        policy provided you are given notice of it and choose to
                        accept it. PII submitted or collected after a transfer,
                        however, may be subject to a new privacy policy adopted
                        by the successor organization.
                      </p>

                      <h3>Payment Gateways: </h3>
                      <p>
                        {' '}
                        You may use several online payment gateways to make
                        payment on our Site. This may involve the use of your
                        debit/credit card details or your online banking login
                        credentials. Most such gateways are owned and operated
                        by other third parties such as banks, credit/debit card
                        processing company, payment gateway, wallets, vouchers,
                        pre-paid cards etc. We may share required PII with such
                        third parties to provide such services, including your
                        name, residence and email address. Please note that
                        processing of payments or authorization in such
                        transactions is solely as per the relevant third party’s
                        policies and terms and conditions. ThroughBit does not
                        bear any responsibility or liability for any delay or
                        failure or deficiency in the payment processes involving
                        any third-party.
                      </p>

                      <h3>
                        Collection, Use and Sharing of Non-Personal Information
                        (NPI){' '}
                      </h3>
                      <p>
                        ThroughBit also collects a range of NPI through your use
                        of our services. Because of your device being connected
                        to the internet, our servers can automatically obtain
                        certain NPI. This includes information through your web
                        browser, device and networks and connections. We use
                        this information to measure usage trends, traffic and
                        better understand how our services can be developed and
                        tailored to your use. Depending on our requirements and
                        purpose, we may collect and use the following NPI:
                      </p>

                      <h3>Web Browser Information: </h3>
                      <p>
                        Web Browsers make certain information available to us by
                        your use of our services on such browsers. Depending on
                        the kind and level of information that is made available
                        by your web browser, we may collect data or information
                        on your Internet Protocol (IP) address, Internet Service
                        Provider (ISP), the operating system, details of the
                        frequency and duration of your access to our services
                        through our website/mobile site/mobile applications etc.
                      </p>
                      <h3>Web Tokens: </h3>
                      <p>
                        Our mobile and web platforms employ web tokens,
                        specifically JSON Web Tokens (JWTs). JWTs store a small
                        encoded text file on the user device that enables the
                        application to identify user access and specify
                        privileges. The use of web tokens allows us to adopt a
                        more flexible and scalable authentication and
                        authorization process. It also helps mitigate any
                        cross-site forgery attacks.
                      </p>
                      <h3>Google Web Analytics: </h3>
                      <p>
                        We employ Google Web Analytics Service through which we
                        use cookies. A Cookie is a small piece of data stored on
                        web browsers. Cookies receive information from devices
                        such as computers, phone, tablets etc., through web
                        browsers. We may collect, use and disclose Information
                        through cookies used with Google Web Analytics, and such
                        use is subject to this Policy. Further, under the Google
                        Web Analytics service, Google may collect your NPI.
                        Google’s use of this Non-Personal Information is subject
                        to the Google Privacy Policy. You may erase, delete or
                        block cookies from being stored on your device. You may
                        also customize your device or browser settings to alert
                        you when we attempt to send a cookie to your device or
                        browser, thereby retaining the option to accept or
                        reject on receipt of the alert. However, if you decide
                        not to accept Cookies, you may not be able to use any or
                        all of the features of the website/mobile site/mobile
                        application and thereby our services.
                      </p>

                      <h3>Web Beacons:</h3>
                      <p>
                        A Web Beacon is a file attached to a web page and is
                        usually not visible to the user. Web Beacons help us
                        understand how a web page of our website/mobile site is
                        loaded and viewed by our users. If you modify your
                        browser or device settings to decline all cookies, you
                        may also be disabling the functioning of a Web Beacon.
                        We do not permit third parties to use Web Beacons on our
                        website/mobile site.
                      </p>

                      <h3>How is NPI Used and Shared</h3>
                      <p>
                        We use NPI to help us develop and improve the design and
                        features of our services and enhance our users’
                        experience. NPI enables us to verify and authenticate
                        your log in credentials easily and without re-entry of
                        credentials on each session. We also gather information
                        on how users view and use the website/mobile site and
                        identify usage pattern and trends, so we can improve
                        features of our website/mobile site/mobile app by
                        tailoring them to user requirements and preferences. We
                        also use NPI to customize and personalize our services
                        to enhance your experience of using our website/mobile
                        site.
                      </p>
                      <h3>CONSENT/WAIVER</h3>
                      <p>
                        By your acceptance of this privacy policy either
                        directly or indirectly, you consent to the collection,
                        storage, use and sharing of any or all of your PII and
                        NPI by the Company as specified under this Privacy
                        Policy. You further agree that the disclosure, sharing
                        and transfer of your PII and NPI shall not cause any
                        wrongful loss to you or to any third party, or any
                        wrongful gain to us or to any third party.
                      </p>
                      <h3>SECURITY</h3>
                      <p>
                        We are committed to protecting all data and information
                        that is available to and stored with us. We maintain and
                        implement reasonable security practices and procedures
                        as prescribed by law. In addition, we take all
                        reasonable security measures to prevent any unauthorized
                        access, disclosure, breach or destruction of data. We
                        undertake periodic internal review of our data
                        collection, storage and security practices to make our
                        services safer and more secure. All PII stored in our
                        severs are protected with necessary encryption and other
                        electronic as well as physical safeguards to prevent any
                        unauthorized use or access. Despite such effective
                        measures, no security system is impenetrable. Therefore,
                        we cannot guarantee the absolute security of our
                        website/mobile site/mobile app and the information
                        stored in them. You assume all risk of loss or damage
                        arising from your voluntary disclosure of PII by using
                        our services.
                      </p>
                      <h3>POLICY REVISION</h3>
                      <p>
                        This Privacy Policy may be reviewed and updated
                        periodically. We will accordingly update the last
                        reviewed date above. When possible, we may send our
                        registered users e-mails or other correspondence
                        alerting you about modifications to the policy. However,
                        we advise you to regularly apprise yourself of any
                        updates to the policy. Your continued use of Website or
                        provision of data or information subsequent to changes
                        will imply your unconditional acceptance of such updates
                        to this Privacy Policy.
                      </p>

                      <h3>STATUTORY COMPLIANCE</h3>
                      <p>
                        The terms of this privacy policy and our privacy
                        practices comply with the provisions of the Information
                        Technology Act, 2000 and Information Technology
                        (Reasonable Security Practices and Procedures and
                        Sensitive Personal Data of Information) Rules, 2011. We
                        maintain and implement reasonable security practices and
                        procedures that do not constitute any offence under
                        Section 43-A of the Act. Therefore, the possession,
                        dealing or handling of any data or information that we
                        obtain from you or through your use of our services does
                        not attract Section 43-A, Section 72 and Section 72-A of
                        the Information Technology Act. By using our services,
                        you provide consent as required under section 43-A and
                        section 72-A of Information Technology Act, 2000.
                      </p>
                      <h3>CONTACT US</h3>
                      <p>
                        Any queries on this policy may be addressed to us at
                        support@throughbit.com.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.tnc ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Terms and Conditions</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <h3>Date of Last Revision: 11 April 2018</h3>
                      <p>
                        Please be informed of the following Terms and Conditions
                        and the Privacy Policy , which jointly and severally
                        constitute an agreement between you and Throughbit
                        Technologies Pvt. Ltd. (“the Company” or “We”) in
                        respect of the use of our web platforms and mobile
                        platforms (including website, mobile site, mobile
                        application etc). If you do not agree to any of the
                        clauses below, or in the Privacy Policy , please do not
                        use our web and mobile platforms. By using our web and
                        mobile platforms, you are consenting to the Terms and
                        Conditions and the Privacy Policy (“Agreement”) and are
                        agreeing to be bound by it. Please note that this
                        Agreement does not require signatures and by mere use of
                        the web and mobile platforms and / or by clicking on the
                        “I agree” button, you agree and consent to being bound
                        by this Agreement.
                      </p>

                      <p>
                        By accessing, browsing, creating an account or using our
                        web and mobile platforms in any manner whatsoever, you
                        are representing that you are an adult having the
                        capacity to enter into this Agreement or that you are a
                        minor acting with the knowledge and consent of your
                        parent / legal guardian who is bound by the Agreement.
                      </p>

                      <p>
                        Any information furnished by you to the Company may be
                        used by the Company and / or authorised third parties in
                        accordance with the Privacy Policy . You agree to update
                        and maintain the information from time to time and as
                        requested by the Company and / or authorised third
                        parties.
                      </p>
                      <p>
                        The web and mobile platforms and all content on the said
                        platforms including images, photographs, literature,
                        codes, articles, text of any kind, illustrations, video
                        and audio recordings, logo, any copyrightable material,
                        etc. are owned by or licenced to the Company and / or
                        authorised third parties. All content generated or
                        contributed by users or authorised third parties are
                        also deemed to be assigned to the Company without
                        limitation and deemed to be owned by the Company as its
                        proprietary content. The Company may modify, add,
                        change, amend or remove any content at any point of time
                        at its sole discretion, without notice and without any
                        liability whatsoever. Any unauthorised use or
                        dissemination of any content on our web and mobile
                        platforms is strictly prohibited and breach of this
                        condition in any manner whatsoever shall amount to a
                        grave breach of the Agreement, entitling the Company to
                        impose penalty and / or damages.
                      </p>

                      <p>
                        The content on our web and mobile platforms shall not be
                        construed as legal or financial advice. The law
                        pertaining to cryptocurrency and blockchain technology
                        is evolving, several aspects are unregulated, and we are
                        presenting the content to you as we understand it. We
                        encourage you to independently learn about the trade and
                        services being provided, which include the buying,
                        selling, transacting, transferring and storing of
                        cryptocurrencies, and assess the risk involved and your
                        capacity to undertake the risks and seek professional
                        legal and financial advice, if you deem it necessary.
                        You agree and understand that some of the risks may
                        include: (a) transactions pertaining to cryptocurrencies
                        being irreversible, if for any reason you wish to un-do
                        the transaction, it will not be possible; (b) if you
                        delete / lose your cryptocurrencies or your wallet and
                        thereby incur losses, it may not be possible to reverse
                        the occurrences; (c) cryptocurrency values are highly
                        volatile and the Company determines the price at which
                        it may buy or sell on its web and mobile platforms; (d)
                        the law pertaining to cryptocurrencies is evolving and
                        the transactions are largely unregulated. Further, it
                        may be that the laws of the country where you reside may
                        not permit you or deem it illegal to use the services
                        provided on our web and mobile platforms. (e) In India,
                        the regulators have advised that they have not given any
                        licence or authorisation to any company or entity to
                        operate such schemes or deal with any virtual currency
                        and have advised that users, holders, traders of virtual
                        currencies may note that these involve potential
                        financial, operational, legal, customer protection and
                        security related risks and those dealing with virtual
                        currencies will be doing so at their own risk. You agree
                        and understand that it is your responsibility to know
                        the laws of your country and make an informed decision
                        on whether or not to use the services provided on our
                        web and mobile platforms. Further, you understand and
                        agree that you are responsible to determine the tax,
                        cess, duty or charge of any nature or kind whatsoever
                        and pay the same to appropriate authorities and the
                        Company shall not be held liable to collect or remit the
                        same. The Company shall not be responsible for any loss
                        or damage that may occur by use of our web and mobile
                        platforms or due to any change or clarification or
                        modification of the law, or in relation to transacting
                        in cryptocurrencies, be it of any nature whatsoever. You
                        hereby waive all your rights to seek any kind of
                        compensation, penalty or damages from the Company.
                      </p>

                      <p>
                        The Company reserves rights to modify, amend, alter,
                        revise or change in whole or in part, the Agreement, as
                        it deems necessary, either prospectively or
                        retrospectively. You agree that it shall be your
                        obligation to peruse the Agreement from time to time,
                        which shall be made available on our web and mobile
                        platforms. You agree that no further notice is required
                        to be provided by the Company, other than posting the
                        revised Agreement on the web and mobile platforms.
                      </p>
                      <p>
                        You may be required to register / sign up / create an
                        account on the web and mobile platforms in order to gain
                        access to a part or whole of the website. The Company or
                        its authorised third parties, at the Company’s sole
                        discretion, may or may not allow you to register / sign
                        up / create an account and will require you to submit
                        necessary documents for verification and may
                        additionally require verification by other means such as
                        audio or video calls, physical address verification etc.
                        before you proceed to use the said platforms. If for any
                        reason, the Company perceives risk of impersonation by
                        you or apprehends that you may be indulging in illegal
                        activities, the Company may, at its discretion, notify
                        law enforcement officials or undertake any such measures
                        that are deemed necessary by it in the interest of
                        security. You hereby agree that you shall keep the
                        Company indemnified against all consequences and / or
                        losses of any nature whatsoever arising on account of
                        your actions or inactions.
                      </p>
                      <p>
                        You are solely responsible for maintaining the
                        confidentiality of any password you may use to access
                        the web and mobile platforms and any of its features,
                        and you agree not to transfer your password or user
                        name, or lend or otherwise transfer your use of or
                        access to the said platforms, to any third party. You
                        are fully responsible for all transactions and other
                        interactions with the web and mobile platforms that
                        occur in connection with your user name. You agree to
                        immediately notify the Company of any unauthorized use
                        of your password or user name or any other breach of
                        security related to your account, your user name or the
                        web and mobile platforms and to ensure that you “log
                        off” and exit from your account with the platforms (if
                        applicable) at the end of each session. The Company
                        shall not be liable for any loss or damage arising from
                        your failure to comply with the above.
                      </p>
                      <p>
                        The Company may, at its discretion, have the right to
                        block the web and mobile platforms or parts of it
                        without prior notice.
                      </p>
                      <p>
                        The web and mobile platforms are provided without
                        warranties of any kind, either express or implied. The
                        Company makes no representation or warranty as to the
                        accuracy, reliability, timeliness or completeness of any
                        material on or accessible through the web and mobile
                        platforms. Any reliance on or use of the content on the
                        web and mobile platforms shall be at your sole risk.
                      </p>
                      <p>
                        We take several measures to ensure that the access to
                        the web and mobile platforms is uninterrupted, error
                        free, secure and free of viruses and to maintain the
                        integrity, safety and security of the web and mobile
                        platforms. We shall continue to take all reasonable
                        measures to ensure the same. However, this shall not be
                        construed as an obligation on the part of the Company.
                        The Company shall in no way be held responsible / liable
                        for any unauthorised third party intrusions, hacking,
                        alterations and theft on the web and mobile platforms.
                        If you become aware of any suspicious, unauthorised
                        activity on or in respect of the web and mobile
                        platforms, please contact the Company immediately by
                        sending an email to info@throughbit.com.
                      </p>
                      <p>
                        “Force Majeure” events shall include all events that are
                        beyond the reasonable control of the Company, and
                        include events and occurrences such as earthquakes,
                        floods, storms, war, civil unrest, riots, acts of
                        terrorism, threats, civil commotion, fire, explosion,
                        epidemic, hacking, attacks including through computer
                        viruses, ransomware, theft of e-wallets, data breaches,
                        attacks on the Company’s servers, phishing, fraudulent
                        financial transactions over which the Company has no
                        control, any interruptions with internet services or
                        network provider services or any other disruptions, or
                        any law, government action, regulation, by any court or
                        of a regulatory authority and such other events. Please
                        note that the Company shall not be liable for any loss,
                        harm or damage which includes loss of money /
                        cryptocurrency from the wallet / account maintained by
                        the Company or its authorised third parties, that may
                        occur due to any Force Majeure event and / or any other
                        event of a similar nature and / or omissions and / or
                        commissions of any third party, and / or your
                        commissions and omissions, on which the Company has no
                        control.
                      </p>

                      <p>
                        The Company or its affiliates, subsidiaries, employees,
                        representatives, directors, agents or authorised third
                        parties shall, in no event, be liable for any indirect,
                        incidental, special or consequential damages arising out
                        of (a) this Agreement and / or the Privacy Policy, (b)
                        the web and mobile platforms, (c) your use of or
                        inability to use the web and mobile platforms, or (d)
                        the acts or omissions of independent parties. The
                        Company is not responsible for any unauthorized access
                        to or alteration of your submissions, transmissions or
                        data or for any material or data sent or received or not
                        sent or not received. The Company is not responsible or
                        liable for any illegal content or conduct of any other
                        party, or any defamatory or threatening or obscene or
                        offensive content or any infringement by a third party
                        of another's intellectual property, privacy or other
                        rights. You agree that any content downloaded or
                        otherwise obtained by you through the use of the web and
                        mobile platforms is done at your own risk and that you
                        will be solely responsible for any damage done to your
                        computer system or loss of data that results from the
                        download of such content. Without prejudice to the
                        above, in no event shall the Company’s total liability
                        to you for any damages, losses and causes of action
                        (whether in contract, tort or otherwise) arising from or
                        related to this Agreement, the Privacy Policy, the web
                        and mobile platforms, or your use of or inability to use
                        the web and mobile platforms exceed the amount paid by
                        you, if any, for using or accessing the web and mobile
                        platforms.
                      </p>
                      <p>
                        You hereby indemnify the Company in respect of any
                        claims, demands, damage, causes of action, costs and
                        expenses including legal fees arising from or related to
                        any of the following: (a) your use, inability to use, or
                        activities or transactions in connection with the web
                        and mobile platforms or the Company; (b) any violation
                        of this Agreement or Privacy Policy by you or through
                        any account you may have with the Company.
                      </p>

                      <p>
                        You agree that the Company may, at its sole discretion,
                        terminate your access to or use of the web and mobile
                        platforms, at any time and for any reason, including if
                        the Company believes that you have violated or acted
                        inconsistently with the terms of this Agreement or of
                        any law or policy. You agree that any termination of
                        your access to or use of the web and mobile platforms
                        may be effected without prior notice and that the
                        Company may immediately deactivate your account or
                        delete any user name and/or password used by or provided
                        to you, and all related information and files associated
                        therewith, and/or bar any further access to such
                        information or files. The Company shall not be liable
                        for any termination of your access to the web and mobile
                        platforms or to any related information, neither to you
                        nor to any third party, and shall not be obligated to
                        make such information available to you after any such
                        termination.
                      </p>
                      <p>
                        This Agreement shall be governed by the laws of India.
                        You agree that any dispute or claim arising out of or
                        relating to the Company or this Agreement or the Privacy
                        Policy shall be resolved by way of arbitration and the
                        same shall be governed by the Arbitration and
                        Conciliation Act, 1996 and any amendments thereof. The
                        arbitration proceedings shall be before a sole
                        Arbitrator and the Company alone shall have the right to
                        nominate and appoint the Arbitrator. The courts in
                        Bengaluru, Karnataka, India shall alone have
                        jurisdiction and you hereby consent and submit to the
                        jurisdiction of such courts.
                      </p>

                      <p>
                        If you have any grievance or complaint in relation to
                        any of the services provided by the Company including
                        the use of the web and mobile platforms, please send an
                        email to info@throughbit.com.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.referral ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Referral</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <h3>
                        Loyalty is a two-way street. If we are asking it from
                        you, then you are getting it from us.
                      </h3>
                      <p>
                        1. Get your 'Referral Code'- Sign up, get 'KYC verified'
                        to get your Referral Code. <br />
                        2. Invite users- Share your referral code with friends
                        and family.
                        <br />
                        3. First transaction- When 'a user that used your
                        referral code'(referred user) makes their first
                        transaction, they get a 20% discount on their first
                        transaction fee and you get 20% of the discounted
                        transaction fee.
                        <br />
                        4. Subsequent transactions- On every transaction made by
                        a 'referred user', you get 20% of their transaction fee.
                        <br />
                        5. Random referrals- If a user, while signing up,
                        doesn't have a referral code, a referral code belonging
                        to a random user will be generated.
                        <br />
                        6. Build a portfolio- We pass on your share of the
                        transaction fee in the form in which we collect it,
                        enabling you to have a diverse portfolio.
                        <br />
                        Read more to understand the 'The ThroughBit Referral
                        Program:
                        <br />
                        At ThroughBit, we take Loyalty very seriously. The
                        ThroughBit Loyalty Program(TLP) has been designed to
                        give our users a sense of belonging and ownership. It
                        has been designed such that every time ThroughBit earns
                        something, the user who was instrumental in making this
                        earning happen will also stand to earn something. This
                        is our way of giving back. This is our way of being
                        loyal to you.
                        <br />
                        In the following few lines, you will be understand how
                        the program works.
                        <br />
                        TLP is a simple referral module where users who wish to
                        refer ThroughBit to any of their friends and family will
                        be rewarded with a certain portion of the transaction
                        fee that ThroughBit earns from the transactions that
                        these referred users conduct.
                        <br />
                        On signing up, completing KYC and getting the account
                        verified, every user will be given a unique ‘Referral
                        Code’, which the user can share to be used when a
                        ‘referred’ user signs up on ThroughBit. Subsequently, on
                        the first transaction the ‘referred’ user makes, the
                        user gets a 20% discount on the transaction fee and the
                        ‘referee’ stands to make 20% of the ‘after discount’
                        transaction fee. On every subsequent transaction carried
                        out by the ‘referred’ user the ‘referee’ stands to make
                        20% of the transaction fee. There are no limits on how
                        many users can be referred by a single ‘referee’.
                        <br />
                        There may be times when a user is signing up on
                        ThroughBit, but doesn’t have a ‘Referral Code’ handy. To
                        avoid the user missing out on the discount on the first
                        transaction, we at ThroughBit, have developed a unique
                        program, ‘The Handshake’.
                        <br />
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.warnings ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Risk Warning</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        There are some things you need to understand before
                        getting started with crypto currencies.
                      </p>

                      <p>
                        Cryptographic solution transactions are irreversible.
                        The cryptographic solutions you buy will be sent to your
                        ThroughBit wallet or to your own cryptographic solutions
                        wallet provided. If you lose or delete your own wallet,
                        you will lose the cryptographic solutions wallet held in
                        it and we won't be able to help you restore them. Please
                        remember to backup and secure your wallet.
                      </p>

                      <p>
                        ThroughBit is registered as a private limited company
                        and we are striving hard to build something that you,
                        our users, will trust and cherish. ThroughBit is built
                        with the customer in mind and since our ideation phase
                        we have aimed at being as transparent as possible. In
                        addition, we are self regulated, even in places where no
                        regulations currently exists.
                      </p>
                      <p>
                        The Bitcoin and Ether markets are very volatile and
                        prone to bubbles and rapid price swings. Buying or
                        selling of any cryptographic solutions may be considered
                        a high risk activity. Proper and sound judgement should
                        be used in evaluating the risks associated. The prices
                        change over time so the cryptographic solutions you
                        acquire may be worth much lesser in the future. In fact,
                        they may even become completely worthless.
                      </p>

                      <p>
                        Cryptographic solutions are not backed by any entity.
                        ThroughBit does not solicit or make any representation
                        that cryptographic solutions are an investment vehicle.
                        Neither ThroughBit nor anyone else associated has an
                        obligation to buy back your Bitcoin or Ether in the
                        future. The decision to trade in cryptographic solutions
                        rests entirely on the user's own independent judgement.
                      </p>
                      <p>
                        Never spend the money that you cannot afford to lose.
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.contact ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Contact Us</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        You can email us at support@throughbit.com for any
                        queries. <br />
                        {/* Just FYI, this is where we work out of : <br />
                        <h6 className="bg-blue fw-600">
                          THROUGHBIT TECHNOLOGIES PRIVATE LIMITED
                        </h6>
                        201-204. Oxford Palazo, First floor, Rustambagh,
                        <br />
                        Kodihalli, Bangalore - 560017 */}
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.aboutUs ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>About us</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <h3>What is ThroughBit?</h3>
                      <p>
                        ThroughBit is a Blockchain startup based out of
                        Bangalore. We are starting off with a platform where you
                        can buy, sell, send, receive, and store and Bitcoin,
                        Ether and INRFalcon.
                      </p>
                      <h3>How is ThroughBit different from other platforms?</h3>
                      <p>
                        ThroughBit enables users to dive into the world of
                        Blockchain by simplifying the process to buy, sell,
                        send, receieve, and store Blockchain for the common man.
                        For the first time in India, you can now buy and sell
                        24x7 by using INRFalcon, a stable Blockchain solution
                        built for India. INRFalcon (INRF) ensures that you can
                        leverage the opportunities to buy and sell Blockchain
                        solutions instantaneously, with minimal interaction with
                        banking channels.
                      </p>

                      <h3>How is ThroughBit funded?</h3>
                      <p>
                        ThroughBit has always been bootstrapped. Our grand plans
                        for expansion and scaling into new geographies has now
                        come to fruition and we are excited as we bring you to
                        the first of many awe-inspiring products that are part
                        of our plans for scaling.
                      </p>

                      <h3>Why should I trust ThroughBit?</h3>
                      <p>
                        ThroughBit is registered as a private limited company
                        and we are striving hard to build something that you,
                        our users, will trust and cherish. ThroughBit is built
                        with the customer in mind and since our ideation phase
                        we have aimed at being as transparent as possible. In
                        addition, we are self regulated, even in places where no
                        regulations currently exists.
                      </p>
                      <p>
                        Our foremost focus is to serve you with utmost sincerity
                        and to gain and uphold the faith that is bestowed upon
                        us. All team members of ThroughBit are active on social
                        media and can be contacted for any clarifications.
                      </p>

                      <h3>Who are the people behind ThroughBit?</h3>
                      <p>
                        <a
                          href="#"
                          className="bg-blue"
                          onClick={() => this.set('team')}
                        >
                          Here’s{' '}
                        </a>
                        a look at us. Look us up on Facebook/Twitter/LinkedIn.
                        We could be friends :
                      </p>
                    </div>
                  </div>
                ) : null}

                {this.state.cookies ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Cookie Statement</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <p>
                        A cookie, also known as an HTTP cookie, web cookie, or
                        browser cookie is a small piece of data sent from a web
                        server and stored in a user’s web browser while the user
                        is browsing that website. In this regard, we use flask
                        cookies to promote account security, as well as both
                        session and persistent cookies. Any sensitive
                        information in these cookies are encoded so that only
                        ThroughBit can interpret the information stored on them.
                        The cookies are placed for the following purposes:-
                      </p>
                      <p>a) Recognize you as a ThroughBit customer,</p>
                      <p>
                        b) Collect information about your computer to mitigate
                        risk and help prevent fraud,
                      </p>
                      <p>
                        c) Customize your experience, content, and advertising,
                        and
                      </p>
                      <p>d) Measure promotional effectiveness.</p>
                    </div>
                  </div>
                ) : null}

                {this.state.fnl ? (
                  <div className="col-lg-9 container-one pl-1 pr-1 mt-1">
                    <div className="content-read-header">
                      <h2>Fees and Limit</h2>
                    </div>
                    <div className="container-fluid card-box full-table content-read p-5">
                      <h3>Trading fees</h3>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Markets</th>
                            <th>Buying</th>
                            <th>Selling</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.feesData['market'].map((o, i) => (
                            <tr>
                              <td>{o.marketsymbol}</td>
                              <td>{o.buyerfee}</td>
                              <td>{o.sellerfee}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      <h3>Deposit/Withdrawal fees</h3>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Token</th>
                            <th>Deposit Fee</th>
                            <th>Minimum withdrawal</th>
                            <th>Withdrawal Fee</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.feesData['coins'].map((o, i) => (
                            <tr>
                              <td>{o.coinsymbol} </td>
                              <td> {o.depositfee}</td>
                              <td>
                                {' '}
                                {o.minlimit} {o.coinsymbol}
                              </td>
                              <td>
                                {o.withdrawfee} {o.coinsymbol}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    feesData: state.app.feesData
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFeesLimits: formData => getFeesLimits(FormData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(FooterModal);
