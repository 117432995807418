import React from 'react';
import { push } from 'connected-react-router';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';
import { headerActiveMenuUpdate } from 'App/appReducer';

import { activeTabUpdate, getPortfolio } from './portFolioReducer';

class CurrencySummary extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetchMarketValue = this.fetchMarketValue.bind(this);
    this.fetchMarkets = this.fetchMarkets.bind(this);
    this.state = {
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      portfolioList: [],
      error: '',
      coinSymbolsObj: {},
      marketId: this.props.marketId
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  /*  componentWillMount() {
    this.props.headerActiveMenuUpdate('openOrders');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
  } */
  componentDidMount() {
    this.props.getPortfolio({ marketId: this.props.marketId });
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }

    if (this.props.portfolioLoader !== nextProps.portfolioLoader) {
      this.setState({
        portfolioLoader: nextProps.portfolioLoader
      });
    }
    if (this.props.marketId !== nextProps.marketId) {
      this.setState({
        marketId: nextProps.marketId
      });
      this.props.getPortfolio({ marketId: nextProps.marketId });
    }
    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
    if (this.props.portfolioList !== nextProps.portfolioList) {
      if (nextProps.portfolioList.error === 0) {
        if (nextProps.portfolioList.length !== 0) {
          this.setState({
            portfolioList: nextProps.portfolioList.data,
            error: null
          });
        } else {
          this.setState({
            portfolioList: [],
            error: nextProps.portfolioList.message
          });
        }
      } else {
        this.setState({
          portfolioList: [],
          error: nextProps.portfolioList.message
        });
      }
    }
    /*   if (
      nextProps.portfolioList &&
      nextProps.portfolioList.data != undefined &&
      this.props.portfolioList.data !== nextProps.portfolioList.data &&
      nextProps.portfolioList.error == 0
    ) {
      if (nextProps.portfolioList.data.length !== 0) {
        this.setState({
          portfolioList: nextProps.portfolioList.data,
          error: ''
        });
      } else {
        this.setState({
          portfolioList: [],
          error: ''
        });
      }
    } else if (nextProps.portfolioList.error == 1) {
      this.setState({
        portfolioList: [],
        error: nextProps.portfolioList.message
      });
    } */
  }
  fetchMarkets(o, coinSymbolsObj) {
    var a = '';
    // o.marketOrders.map((m, i) => (a = coinSymbolsObj[m.marketId].coinname));
    return a;
  }

  fetchMarketValue(o) {
    var a = '';
    //o.marketOrders.map((m, i) => (a = m.value));
    return a;
  }
  render() {
    const {
      portfolioList,
      portfolioLoader,
      error,
      coinSymbolsObj
    } = this.state;
    if (portfolioLoader) {
      return <div>Loading...</div>;
    }

    if (!portfolioLoader && error) {
      return <div>{error}</div>;
    }

    return (
      <div className="row no-gutters mt-2 ml-4 mr-4 coin-data-container">
        {portfolioList && portfolioList.length != 0 ? (
          _.keys(this.state.coinSymbolsObj).length > 0 &&
          portfolioList.map((o, i) => (
            <div
              className="col-lg-2 col-md-12 col-sm-12 coin-main center-text mr-2"
              key={i}
            >
              <div className="coin-line eth" />
              <div className="coin-head-details pt-2">
                <span className="star">
                  <span className="oi oi-star" />
                </span>
                <h2 className="title-h">
                  {`${this.state.coinSymbolsObj[o.coinId].coinname}`}
                  <span className="currency">
                    {`${this.state.coinSymbolsObj[o.coinId].coinsymbol}`}
                  </span>
                </h2>
              </div>
              <div className="row no-gutters">
                <div className="col-md-4">
                  <div className="coin-img" />
                </div>
                <div className="col-md-8">
                  <ul className="market-coin-info mt-4 mr-4">
                    <li className="row no-gutters">
                      <div className="col-6 text-left">
                        <h3 className="title-h">Available Balance</h3>
                      </div>
                      <div className="col-6 text-right number">
                        {parseFloat(o.balance).toFixed(8)}{' '}
                        <span className="currency">{`${
                          this.state.coinSymbolsObj[o.coinId].coinsymbol
                        }`}</span>
                      </div>
                    </li>
                    <li className="row no-gutters">
                      <div className="col-6 text-left">
                        <h3 className="title-h">Total balance</h3>
                      </div>
                      <div className="col-6 text-right number">
                        {parseFloat(o.total).toFixed(8)}{' '}
                        <span className="currency">{`${
                          this.state.coinSymbolsObj[o.coinId].coinsymbol
                        }`}</span>
                      </div>
                    </li>
                    <li className="row no-gutters">
                      <div className="col-6 text-left">
                        <h3 className="title-h">Value</h3>
                      </div>
                      <div className="col-6 text-right number">
                        {this.fetchMarketValue(o)}{' '}
                        <span className="currency">
                          {this.fetchMarkets(o, coinSymbolsObj)}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row no-gutters">
                <ul className="market-coin-info col-md-12 pl-4 pr-4">
                  <li className="row no-gutters">
                    <div className="col-6 text-left">
                      <h3 className="title-h">Last Price</h3>
                    </div>
                    <div className="col-6 text-right number">
                      76,900 <span className="currency">BTC</span>
                    </div>
                  </li>
                  <li className="row no-gutters">
                    <div className="col-6 text-left">
                      <h3 className="title-h">24hr BTC Vol</h3>
                    </div>
                    <div className="col-6 text-right number">
                      22,269,200 <span className="currency">BTC</span>
                    </div>
                  </li>
                  <li className="row no-gutters">
                    <div className="col-6 text-left">
                      <h3 className="title-h">24hr ETH Vol</h3>
                    </div>
                    <div className="col-6 text-right number">
                      22,269,200 <span className="currency">BTC</span>
                    </div>
                  </li>
                  <li className="row no-gutters">
                    <div className="col-6 text-left">
                      <h3 className="title-h">24hr High</h3>
                    </div>
                    <div className="col-6 text-right number">
                      79,132 <span className="currency">BTC</span>
                    </div>
                  </li>
                  <li className="row no-gutters">
                    <div className="col-6 text-left">
                      <h3 className="title-h">24hr Low</h3>
                    </div>
                    <div className="col-6 text-right number">
                      71,142 <span className="currency">BTC</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))
        ) : (
          <div>No portfolio</div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      getPortfolio: formData => getPortfolio(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.portfolio.activeTab,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    coinSymbolsObj: state.app.coinSymbolsObj,
    portfolioLoader: state.portfolio.portfolioLoader,
    portfolioList: state.portfolio.portfolioList
  }),
  mapDispatchToProps
)(CurrencySummary);
