import React from 'react';
import ReactDOM from 'react-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { activeTabUpdate } from '../settings/settingsReducer';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import Settings from 'Modules/settings/settings';
import DatePicker from 'react-datepicker';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import removeCookie from 'Utils/removeCookie';
import { headerActiveMenuUpdate } from 'App/appReducer';
import { logout } from 'Modules/signin/signinReducer';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Iframe from 'react-iframe';
import {
  generateOTPNumber,
  verifyOTPNumber,
  saveBasicInformation,
  saveCopies,
  getKycDetails
} from './kycReducer';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData
} from 'react-country-region-selector';
import Webcam from 'react-webcam';
import { Cropper } from 'react-image-cropper';

const cookies = new Cookies();

var email = cookies.get('email');

let gloabalId = cookies.get('gloabalId');

var previousData = {};

const zipcodeReg = /(^\d{6}$)/;

class Kyc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      addr1: '',
      addr2: '',
      phone: '',
      files: '',
      fileType: '',
      gender: '',
      dob: null,

      country: '',
      issuingCountry: '',
      city: '',
      state: '',

      pincode: '',
      otp: '',

      photoType: '', //passport, licence, govt issued,
      frontPhoto: '',
      backPhoto: '',
      otherPhoto: '',
      idNumber: '',

      title: 'Attention',

      kycUploadLoader: false,
      kycDetailsResponse: {},
      responseMessage: '',
      error: '',
      heading: 'ID Proof',

      showOTPBlock: false, //on click of next or back button
      showPhotoProofBlock: false, //next,back
      showBasicInfoBlock: true, //next,back

      showPhotoBlock: false, //on click of a passport or any photo icon
      showPhotoSelection: false, //when to show front back other button,
      showPhotoUploadButton: false,

      frontCopy: '',
      backCopy: '',
      otherCopy: '',

      showCamera: false,

      errors: false,

      OTPVerifyResponse: {},
      OTPSendResponse: {},

      numPages: null,
      pageNumber: 1,

      showCropper: false,
      photoSide: '',
      photoToDisplay: '',
      cropedPhoto: '',
      showSlider: true,
      buttonValue: 'Save and Continue',
      showModal: false,
      showMaxFileRestriction: false,
      readOnly: false,
      errorMessage: '',
      showOTPEnterField: false,
      showPhotoId: false,
      disbaleOTPFields: false,
      tooltipOpen: false,
      generateOTPButtonName: 'Generate OTP',
      reloadButton: false,
      prevphoto: ''
    };
    this.validateAllFields = this.validateAllFields.bind(this);
    this.checkPhotoStatus = this.checkPhotoStatus.bind(this);
    this.handlePassPort = this.handlePassPort.bind(this);
    this.handleAdhar = this.handleAdhar.bind(this);
    this.handleDrivingLicence = this.handleDrivingLicence.bind(this);

    this.selectCountry = this.selectCountry.bind(this);
    this.selectState = this.selectState.bind(this);
    this.onSelectNativeCountry = this.onSelectNativeCountry.bind(this);
    this.selectPhotoType = this.selectPhotoType.bind(this);
    this.clickPhoto = this.clickPhoto.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);

    this.onSelectGender = this.onSelectGender.bind(this);
    this.show = this.show.bind(this);
    this.showCropperImage = this.showCropperImage.bind(this);
    this.generateOTP = this.generateOTP.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.validate = this.validate.bind(this);

    this.crop = this.crop.bind(this);
    this.confirm = this.confirm.bind(this);
    this.close = this.close.bind(this);
    this.retake = this.retake.bind(this);
    this.saveBasicInfo = this.saveBasicInfo.bind(this);

    this.validateSingleField = this.validateSingleField.bind(this);
    this.validateWithPreviousData = this.validateWithPreviousData.bind(this);
    this.showPhotoUploadButtons = this.showPhotoUploadButtons.bind(this);
    this.saveCopies = this.saveCopies.bind(this);
    this.changeIDProof = this.changeIDProof.bind(this);

    this.checkIfPhotoExists = this.checkIfPhotoExists.bind(this);
    this.getKyc = this.getKyc.bind(this);
    this.reUpload = this.reUpload.bind(this);
    this.convertBase64ToFile = this.convertBase64ToFile.bind(this);
    this.imageToFile = this.imageToFile.bind(this);
  }

  getKyc() {
    this.props.getKycDetails();
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    } else {
      this.getKyc();
    }
  }

  convertBase64ToFile(url, filename, mimeType) {
    return fetch(url)
      .then(function(res) {
        return res.arrayBuffer();
      })
      .then(function(buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  imageToFile() {
    const that = this;

    if (this.state.frontPhoto && !this.state.frontCopy) {
      var ImageURL = this.state.frontPhoto;
      var block = ImageURL.split(';');
      // Get the content type of the image
      var contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      var i = contentType.indexOf('/');
      var extension = contentType.slice(i + 1);

      this.convertBase64ToFile(
        this.state.frontPhoto,
        'front.' + extension,
        contentType
      ).then(function(file) {
        that.setState({
          frontCopy: file
        });
      });
    }

    if (this.state.backPhoto && !this.state.backCopy) {
      var ImageURL = this.state.backPhoto;
      var block = ImageURL.split(';');
      // Get the content type of the image
      var contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      var i = contentType.indexOf('/');
      var extension = contentType.slice(i + 1);

      this.convertBase64ToFile(
        this.state.backPhoto,
        'back.' + extension,
        contentType
      ).then(function(file) {
        that.setState({
          backCopy: file
        });
      });
    }

    if (this.state.otherPhoto && !this.state.otherCopy) {
      var ImageURL = this.state.otherPhoto;
      var block = ImageURL.split(';');
      // Get the content type of the image
      var contentType = block[0].split(':')[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      var i = contentType.indexOf('/');
      var extension = contentType.slice(i + 1);
      this.convertBase64ToFile(
        this.state.otherPhoto,
        'other.' + extension,
        contentType
      ).then(function(file) {
        that.setState({
          otherCopy: file
        });
      });
    }
    if (this.state.frontCopy || this.state.other || this.state.backCopy) {
      return true;
    }
    return false;
  }

  saveCopies() {
    var type = '';
    if (this.state.photoType === 'PASSPORT') {
      type = 'PASSPORT';
    } else if (this.state.photoType === 'DRIVING_LICENCE') {
      type = 'DL';
    } else if (this.state.photoType === 'ADHAR_CARD') {
      type = 'ADHAR';
    }
    this.props.saveCopies({
      idissuedCountry: this.state.issuingCountry,
      idType: type,
      idNumber: this.state.idNumber,
      idFrontCopy: this.state.frontPhoto,
      idWithPhotoCopy: this.state.otherPhoto,
      idBackCopy: this.state.backPhoto
    });
  }

  validateSingleField(val1, val2) {
    if (val1 === val2 && !this.validateWithPreviousData()) {
      this.setState({
        buttonValue: 'Continue'
      });
    } else {
      this.setState({
        buttonValue: 'Save and Continue'
      });
    }
  }

  validateWithPreviousData() {
    if (
      previousData &&
      previousData.gender === this.state.gender &&
      previousData.dob === this.state.dob &&
      previousData.city === this.state.city &&
      previousData.state === this.state.state &&
      previousData.country === this.state.country &&
      previousData.addr1 === this.state.addr1 &&
      previousData.addr2 === this.state.addr2
    ) {
      return false;
    }
    return true;
  }

  saveBasicInfo() {
    previousData = { ...this.state };

    var date1 = this.state.dob.toDateString();
    var date = moment(date1).format('YYYY-MM-DD'); // 01/03/2017

    // if (date.indexOf('-') && date.indexOf('-') > -1) {
    //   date = date
    //     .split('-')
    //     .reverse()
    //     .join('-');
    // } else {
    //   date = date
    //     .split('/')
    //     .reverse()
    //     .join('/');
    // }

    this.props.saveBasicInfo({
      gender: this.state.gender.toUpperCase(),
      dob: date,
      countryOfResidence: this.state.country,
      addr: this.state.addr1,
      addr1: this.state.addr2,
      state: this.state.state,
      city: this.state.city,
      zipcode: this.state.pincode
    });
  }

  showPhotoUploadButtons(val) {
    this.setState({
      heading: 'Choose how to submit',
      showPhotoUploadButton: true,
      showPhotoSelection: false,
      photoSide: val
    });
  }

  close() {
    this.setState({
      showPhotoUploadButton: true,
      showCamera: false
    });
  }

  retake() {
    this.setState({ photoToDisplay: '', showCropper: false });
    this.clickPhoto();
  }

  reUpload() {
    this.setState({
      photoToDisplay: '',
      showPhotoUploadButton: true,
      showCropper: false
    });
  }

  confirm() {
    if (this.state.cropedPhoto === '') {
      switch (this.state.photoSide) {
        case 'FRONT':
          this.setState(
            {
              frontPhoto: this.state.photoToDisplay
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);
          break;
        case 'BACK':
          this.setState(
            {
              backPhoto: this.state.photoToDisplay
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);
          break;
        case 'FACE':
          this.setState(
            {
              otherPhoto: this.state.photoToDisplay
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);

          break;
      }
    } else {
      switch (this.state.photoSide) {
        case 'FRONT':
          this.setState(
            {
              frontPhoto: this.state.cropedPhoto,
              cropedPhoto: ''
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);
          break;
        case 'BACK':
          this.setState(
            {
              backPhoto: this.state.cropedPhoto,
              cropedPhoto: ''
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);
          break;
        case 'FACE':
          this.setState(
            {
              otherPhoto: this.state.cropedPhoto,
              cropedPhoto: ''
            },
            () => {
              this.imageToFile();
            }
          );
          this.selectPhotoType(this.state.photoType, this.state.title);

          break;
      }
    }
  }

  crop() {
    const src = this.cropper.crop();
    const values = this.cropper.values();

    this.setState({
      cropedPhoto: src
    });
  }

  showCropperImage() {
    this.setState({
      showCropper: true
    });
  }

  validateAllFields(val) {
    switch (val) {
      case 'BASIC_INFO':
        if (!this.state.country) {
          this.setState({ errorMessage: 'Please select the country' });
        }
        if (!this.state.state) {
          this.setState({ errorMessage: 'Please select the state' });
          return false;
        }
        if (!this.state.city) {
          this.setState({ errorMessage: 'Please enter the city' });
          return false;
        }
        if (!this.state.gender || this.state.gender == 'Gender') {
          this.setState({ errorMessage: 'Please select the gender' });
          return false;
        }
        if (!this.state.pincode) {
          this.setState({ errorMessage: 'Please enter the pincode' });
          return false;
        }
        if (!this.state.addr1) {
          this.setState({ errorMessage: 'Please enter the address 1 details' });
          return false;
        }
        if (!this.state.addr2) {
          this.setState({ errorMessage: 'Please enter the address 2 details' });
          return false;
        }
        if (!this.state.dob) {
          this.setState({ errorMessage: 'Please select the date of birth' });
          return false;
        }
        if (moment().diff(new Date(this.state.dob), 'years') < 18) {
          this.setState({ errorMessage: 'Age must be 18 years and above' });
          return false;
        }

        this.setState({
          errorMessage: ''
        });
        return true;
      case 'OTP':
        if (this.state.hideOTPVerificationPage) {
          this.setState({ errorMessage: '' });
          return true;
        }
        if (this.state.phone == '') {
          this.setState({
            errorMessage: 'Please enter the mobile number and verify it'
          });
          return false;
        } else if (
          this.state.OTPVerifyResponse.error == 0 ||
          this.state.OTPSendResponse.status == 1
        ) {
          this.setState({
            errorMessage: ''
          });
          return true;
        } else {
          this.setState({ errorMessage: this.state.OTPVerifyResponse.message });
          return false;
        }
      case 'PHOTO_SECTION':
        if (!this.state.frontPhoto) {
          this.setState({
            errorMessage:
              'Front photo not Uploaded. Please upload it and then proceed'
          });
          return false;
        }
        if (!this.state.otherPhoto) {
          this.setState({
            errorMessage:
              ' ID photo with face not Uploaded. Please upload it and then proceed'
          });
          return false;
        }
        if (!this.state.issuingCountry) {
          this.setState({
            errorMessage: 'Issuing country not selected'
          });
          return false;
        }
        if (!this.state.idNumber) {
          this.setState({
            errorMessage: 'Please enter ID number'
          });
          return false;
        }
        this.setState({
          errorMessage: ''
        });

        return true;
    }
  }

  validate(val) {
    switch (val) {
      case 'BASIC_INFO':
        if (
          this.state.country &&
          this.state.pincode &&
          this.state.gender &&
          this.state.gender != 'Gender' &&
          this.state.addr1 &&
          this.state.addr2 &&
          this.state.dob &&
          this.state.city &&
          this.state.state
        ) {
          return true;
        } else {
          this.setState({ errors: true });
          return false;
        }
      case 'OTP':
        if (this.state.OTPVerifyResponse) {
          return true;
        } else {
          return false;
        }
      case 'PHOTO_SECTION':
        if (
          this.state.frontPhoto &&
          this.state.otherPhoto &&
          this.state.issuingCountry &&
          this.state.idNumber
        ) {
          return true;
        } else {
          return false;
        }
    }
  }

  checkPhotoStatus() {
    if (
      this.state.frontPhoto ||
      this.state.backPhoto ||
      this.state.otherPhoto
    ) {
      // this.changeIDProof();
      return true;
    }
    return false;
  }

  checkIfPhotoExists(p1, p2) {
    if (
      this.state.frontPhoto ||
      this.state.backPhoto ||
      this.state.otherPhoto
    ) {
      this.setState({
        prevphoto: this.state.photoType,
        showModal: true
      });
      // this.changeIDProof();
    } else {
      this.selectPhotoType(p1, p2);
    }
  }

  selectPhotoType(photoType, photoTitle) {
    switch (photoType) {
      case 'PASSPORT':
        this.setState({
          heading: 'ID Proof',
          showPhotoSelection: true,
          showPhotoUploadButton: false,
          showCamera: false,
          showCropper: false,
          photoToDisplay: '',
          title: photoTitle,
          photoType: photoType,
          showSlider: false,
          showPhotoId: false
        });
        break;
      case 'DRIVING_LICENCE':
        this.setState({
          showPhotoSelection: true,
          showPhotoUploadButton: false,
          showCamera: false,
          showCropper: false,
          photoToDisplay: '',
          title: photoTitle,
          photoType: photoType,
          showSlider: false,
          showPhotoId: false
        });
        break;
      case 'ADHAR_CARD':
        this.setState({
          showSlider: false,
          showPhotoSelection: true,
          showPhotoUploadButton: false,
          showCamera: false,
          showCropper: false,
          photoToDisplay: '',
          title: photoTitle,
          photoType: photoType,
          showPhotoId: false
        });
    }
    // this.setState({
    //     showPhotoSelection: true,
    //     showPhotoUploadButton: false,
    //     showCamera: false,
    //     showCropper: false,
    //     photoToDisplay: "",
    //     title: photoTitle,
    //     photoType: photoType,
    //     showSlider: false
    //   });
  }

  clickPhoto() {
    this.setState({
      showPhotoUploadButton: false,
      showCamera: true
    });
  }

  uploadPhoto(e) {
    switch (this.state.photoType) {
      case 'PASSPORT':
        this.handlePassPort(e);
        break;

      case 'ADHAR_CARD':
        this.handleAdhar(e);
        break;

      case 'DRIVING_LICENCE':
        this.handleDrivingLicence(e);
        break;
    }
  }

  generateOTP() {
    this.props.generateOTPNumber({
      phone: this.state.phone.replace(/ +/g, '')
    });
  }

  verifyOTP() {
    this.props.verifyOTP({
      phone: this.state.phone,
      otp: this.state.otp
    });
  }

  show(val) {
    switch (val) {
      case 'NEXT':
        if (
          this.state.showBasicInfoBlock == true &&
          this.validateAllFields('BASIC_INFO')
        ) {
          this.saveBasicInfo();
        }
        // if (this.validateWithPreviousData()) {
        //     this.saveBasicInfo();
        else if (
          (this.state.showOTPBlock == true && this.validateAllFields('OTP')) ||
          (this.state.showOTPBlock == true &&
            this.state.hideOTPVerificationPage)
        ) {
          if (
            this.state.frontPhoto ||
            this.state.backPhoto ||
            this.state.otherPhoto
          ) {
            this.setState({
              showPhotoSelection: true,
              showSlider: false
            });
          }

          this.setState({
            showOTPBlock: false,
            heading: 'ID Proof',
            showPhotoProofBlock: true,
            buttonValue: 'Submit'
          });
        } else if (
          this.state.showPhotoProofBlock === true &&
          this.state.buttonValue === 'Submit' &&
          this.validateAllFields('PHOTO_SECTION')
        ) {
          this.setState({
            reloadButton: true
          });
          this.saveCopies();
        }

        break;

      case 'BACK':
        if (
          this.state.showBasicInfoBlock == false &&
          this.state.showOTPBlock == true
        ) {
          this.setState({
            showOTPBlock: false,
            showBasicInfoBlock: true,
            showPhotoProofBlock: false,
            buttonValue: 'Continue',
            errorMessage: ''
          });
        } else if (
          this.state.showOTPBlock == false &&
          this.state.showPhotoProofBlock == true
        ) {
          this.setState({
            showOTPBlock: true,
            showPhotoUploadButton: false,
            photoToDisplay: '',
            showSlider: true,
            showBasicInfoBlock: false,
            showPhotoProofBlock: false,
            buttonValue: 'Continue',
            errorMessage: ''
          });
        }
        break;
    }
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectState(val) {
    this.setState({ state: val });
  }

  onSelectNativeCountry(val) {
    if (
      this.state.frontPhoto ||
      this.state.backPhoto ||
      this.state.otherPhoto
    ) {
      this.setState({ showModal: true });
    } else {
      this.setState({
        issuingCountry: val,
        showSlider: false,
        showPhotoId: true,
        showPhotoSelection: false,
        showPhotoUploadButton: false,
        photoToDisplay: ''
      });
    }
    // this.state.selectedCountry = {
    //     // id: event.target.value,
    //     name: event.target.options[event.target.selectedIndex].text
    // };

    // this.state.issuingCountry = this.refs.country.selected.name; // {value,name}
  }

  onSelectGender(event) {
    this.state.gender = event.target.options[event.target.selectedIndex].text;
    this.validateSingleField(event.target.value, previousData.gender);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.basicInfoResponse !== nextProps.basicInfoResponse) {
      if (nextProps.basicInfoResponse.error == 2) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      }

      if (nextProps.basicInfoResponse.error != 0) {
        this.setState({ errorMessage: nextProps.basicInfoResponse.message });
      } else {
        this.setState({
          showOTPBlock: true,
          showBasicInfoBlock: false
        });
        if (this.props.kycDetailsResponse.data[0].verified_phone == 1) {
          this.setState({
            buttonValue: 'Continue'
          });
        } else {
          this.setState({
            buttonValue: 'Save and Continue'
          });
        }
      }
    }

    if (this.props.OTPSendResponse !== nextProps.OTPSendResponse) {
      if (nextProps.OTPSendResponse.error == 2) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      }

      if (
        nextProps.OTPSendResponse.error == 0 &&
        !nextProps.OTPSendResponse.status
      ) {
        this.setState({ OTPSendResponse: nextProps.OTPSendResponse });
        this.setState({ showOTPEnterField: true });
      } else if (
        nextProps.OTPSendResponse.error == 0 &&
        nextProps.OTPSendResponse.status == 1
      ) {
        this.setState(
          {
            OTPSendResponse: nextProps.OTPSendResponse,
            showOTPEnterField: false,
            errorMessage: '',
            generateOTPButtonName: '',
            hideOTPVerificationPage: true,
            disbaleOTPFields: true
          },
          () => {
            this.show('NEXT');
          }
        );
      } else {
        this.setState({
          errorMessage: nextProps.OTPSendResponse.message,
          showOTPEnterField: false
        });
      }
    }

    if (this.props.OTPVerifyResponse !== nextProps.OTPVerifyResponse) {
      if (nextProps.OTPVerifyResponse.error == 2) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      }

      this.setState({ OTPVerifyResponse: nextProps.OTPVerifyResponse });
      if (nextProps.OTPVerifyResponse.error == 0) {
        this.setState({
          showOTPEnterField: false,
          errorMessage: '',
          disbaleOTPFields: true
        });
      } else {
        this.setState({
          errorMessage: nextProps.OTPVerifyResponse.message,
          showOTPEnterField: true
        });
      }
    }

    if (this.props.kycDetailsResponse !== nextProps.kycDetailsResponse) {
      if (
        nextProps.kycDetailsResponse.error == 2 ||
        nextProps.kycDetailsResponse.error == 1 ||
        !nextProps.kycDetailsResponse
      ) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      } else {
        if (nextProps.kycDetailsResponse.data) {
          if (nextProps.kycDetailsResponse.data[0].verified_kyc == 2) {
            // this.setState({
            //   showBasicInfoBlock: true,
            //   showOTPBlock: false,
            //   showPhotoProofBlock: false,
            //   showPhotoBlock: false,

            //   readOnly: true
            // });
            if (this.state.reloadButton) {
              this.props.activeTabUpdate('kyc');
              this.props.changePage('/settings');
            }
          }

          if (nextProps.kycDetailsResponse.data[0].verified_kyc == 1) {
            this.props.changePage('/markets');
          }

          let date;
          if (nextProps.kycDetailsResponse.data[0].dob == '0000-00-00') {
            date = null;
          } else {
            date = new Date(nextProps.kycDetailsResponse.data[0].dob);
          }

          // if (date.indexOf('-') > -1) {
          //   date = date
          //     .split('-')
          //     .reverse()
          //     .join('-');
          // } else {
          //   date = date
          //     .split('/')
          //     .reverse()
          //     .join('/');
          // }

          this.setState({
            name: nextProps.kycDetailsResponse.data[0].full_name,
            pincode: nextProps.kycDetailsResponse.data[0].zipcode,
            gender: nextProps.kycDetailsResponse.data[0].gender,
            dob: date,
            country: nextProps.kycDetailsResponse.data[0].residencecountry,
            state: nextProps.kycDetailsResponse.data[0].state,
            city: nextProps.kycDetailsResponse.data[0].city,
            addr1: nextProps.kycDetailsResponse.data[0].addr,
            addr2: nextProps.kycDetailsResponse.data[0].addr
          });

          if (nextProps.kycDetailsResponse.data[0].verified_phone == 1) {
            this.setState({
              hideOTPVerificationPage: true,
              errorMessage: ''
            });
          }
        }
      }
    }

    if (this.props.copiesResponse !== nextProps.copiesResponse) {
      if (nextProps.copiesResponse.error == 2) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      }

      if (nextProps.copiesResponse.error == 0) {
        this.getKyc();
      } else {
        this.setState({ errorMessage: nextProps.copiesResponse.message });
      }
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }
  }

  componentWillMount() {}

  /**
   * Handle PassPort
   * @function
   * @param {object} => image file
   */
  handlePassPort(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    // if (file.size < 10000000) {
    this.setState({
      files: URL.createObjectURL(e.target.files[0]),
      fileType: e.target.files[0].type
    });

    reader.onloadend = () => {
      if (this.state.photoSide === 'FRONT') {
        this.setState({
          // frontPhoto: imageSrc,
          photoToDisplay: reader.result,

          showPhotoUploadButton: false
        });
      } else if (this.state.photoSide === 'FACE') {
        this.setState({
          // backPhoto: imageSrc,
          photoToDisplay: reader.result,
          showPhotoUploadButton: false
        });
      }
    };
    reader.readAsDataURL(file);
    // } else {
    //   this.setState({
    //     showMaxFileRestriction: true
    //   });
    // }
  }
  /**
   * Handle Driving Licence
   * @function
   * @param {object} => image file
   */
  handleAdhar(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size) {
      this.setState({
        fileType: e.target.files[0].type
      });

      reader.onloadend = () => {
        if (this.state.photoSide === 'FRONT') {
          this.setState({
            // frontPhoto: imageSrc,
            photoToDisplay: reader.result,
            showPhotoUploadButton: false
          });
        } else if (this.state.photoSide === 'FACE') {
          this.setState({
            // backPhoto: imageSrc,
            photoToDisplay: reader.result,
            showPhotoUploadButton: false
          });
        } else {
          this.setState({
            // backPhoto: imageSrc,
            photoToDisplay: reader.result,
            showPhotoUploadButton: false
          });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  /**
   * Handle Govt Issued
   * @function
   * @param {object} => image file
   */
  handleDrivingLicence(e) {
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file.size) {
      this.setState({
        fileType: e.target.files[0].type
      });

      reader.onloadend = () => {
        if (this.state.photoSide === 'FRONT') {
          this.setState({
            // frontPhoto: imageSrc,
            photoToDisplay: reader.result,
            showPhotoUploadButton: false
          });
        } else if (this.state.photoSide === 'FACE') {
          this.setState({
            // backPhoto: imageSrc,
            photoToDisplay: reader.result,
            showPhotoUploadButton: false
          });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  /**
   * Call The APi uploadKyc Details
   * @function
   * @param {object} => response Data from the Api
   */

  handleKycDetails = () => {
    this.props.uploadKycDetails({
      phone: this.state.phone,
      pan_num: this.state.pan_num,
      addr: this.state.addr,
      zipcode: this.state.pincode,
      user_photo: this.state.user_photo,
      pan_card_photo: this.state.pan_card_photo,
      address_proof_photo: this.state.address_proof_photo
    });
  };

  setRef = webcam => {
    this.webcam = webcam;
  };

  cancelModal = prev => {
    this.setState({
      photoType: prev,
      showModal: false
    });
  };

  setPhone = e => {
    if (e) {
      if (e.substring(0, 3) == '+91') {
        this.setState({
          phone: e,
          generateOTPButtonName: 'Generate OTP'
        });
      } else {
        this.setState({
          phone: e,
          generateOTPButtonName: 'Verify'
        });
      }
    }
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();

    if (this.state.photoSide === 'FRONT') {
      this.setState({
        // frontPhoto: imageSrc,
        photoToDisplay: imageSrc,
        showCamera: false
      });
    } else if (this.state.photoSide === 'BACK') {
      this.setState({
        // backPhoto: imageSrc,
        photoToDisplay: imageSrc,
        showCamera: false
      });
    } else {
      this.setState({
        // otherPhoto: imageSrc,
        photoToDisplay: imageSrc,
        showCamera: false
      });
    }
  };

  changeIDProof() {
    this.setState({
      showModal: false,
      heading: 'ID Proof',
      photoType: '',
      showSlider: true,
      showPhotoSelection: false,
      frontPhoto: '',
      backPhoto: '',
      otherPhoto: '',
      title: 'KYC'
    });
  }

  render() {
    const { country, state, issuingCountry } = this.state;

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user'
    };

    return (
      <div id="kyc-page">
        <div className="row no-gutters row-eq-height">
          <div className="col-lg-8 signin-left signin-left-height">
            {/* <div className="logo mt-2rem">
              <a>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt="Logo"
                  style={{ height: '25px', cursor: 'pointer' }}
                  // onClick={() => this.toggle('/')}
                  onClick={() => {
                    this.props.changePage('/markets');
                  }}
                />
              </a>
            </div> */}
            {/* Photo Type Buttons */}
            {!this.state.showSlider && this.state.showPhotoId && (
              <div className="idproof">
                {' '}
                <img src="../assets/images/id.png" />{' '}
              </div>
            )}
            {!this.state.showSlider ? (
              <div>
                {this.state.showPhotoSelection && (
                  <div className="idproof row">
                    <div className="col-md-4 idproof-col ">
                      <img
                        className="../assets/images/camera.png"
                        onClick={() => this.showPhotoUploadButtons('FRONT')}
                        style={{ width: '200px', height: '200px' }}
                        src="../assets/images/camera.png"
                      />

                      {this.state.photoType === 'PASSPORT' && (
                        <>
                          <h5>Passport Image - 1 </h5>
                          <p className="font-size-12">(Image of passport page with your name)</p>
                        </>
                      )}
                      {this.state.photoType === 'DRIVING_LICENCE' && (
                        <>
                          <h5>Driving Licence Image - 1</h5>
                          <p className="font-size-12">(Image of the side with your name and address on it)</p>
                        </>
                      )}
                      {this.state.photoType === 'ADHAR_CARD' && (
                        <h5>Aadhaar Card Front Photo</h5>
                      )}
                    </div>

                    {this.state.photoType === 'ADHAR_CARD' ? (
                      <div className="col-md-4 idproof-col">
                        <img
                          onClick={() => this.showPhotoUploadButtons('BACK')}
                          style={{ width: '200px', height: '200px' }}
                          src="../assets/images/camera.png"
                        />

                        {this.state.photoType === 'ADHAR_CARD' && (
                          <h5>Aadhaar Card Back Photo</h5>
                        )}
                      </div>
                    ) : null}

                    <div className="col-md-4 idproof-col">
                      <img
                        onClick={() => this.showPhotoUploadButtons('FACE')}
                        style={{ width: '200px', height: '200px' }}
                        src="../assets/images/camera.png"
                      />
                      {this.state.photoType === 'PASSPORT' && (
                        <>
                          <h5>Passport Image - 2</h5>
                          <p className="font-size-12">(A picture of you holding the passport to right of your face)</p>
                        </>
                      )}
                      {this.state.photoType === 'DRIVING_LICENCE' && (
                        <>
                          <h5>Driving Licence and Face Photo</h5>
                          <p className="font-size-12">(A picture of you holding the DL to right of your face)</p>
                        </>
                      )}
                      {this.state.photoType === 'ADHAR_CARD' && (
                        <>
                          <h5>Aadhaar Card and Face Photo</h5>
                          <p className="font-size-12">(A picture of you holding the Aadhaar to right of your face)</p>
                        </>
                      )}{' '}
                    </div>
                  </div>
                )}
                {/* Photo Type Buttons */}

                {this.state.showPhotoUploadButton ? (
                  <div className="mt-5">
                    <div className="mt-5">
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary w50 m-3"
                          value="CLICK PHOTO"
                          onClick={this.clickPhoto}
                        >
                          Click Image from device
                        </button>
                      </div>

                      <h6 className="d-flex justify-content-center m-3">OR</h6>

                      <div className="d-flex justify-content-center">
                        <label
                          htmlFor="upload-photo"
                          className="btn btn-default uploadFile w50 m-3"
                        >
                          Upload from device{' '}
                          <span className="glyphicon glyphicon-align-justify" />
                        </label>
                        <input
                          data-toggle="modal1"
                          data-target="#myMaxSizeModal"
                          id="upload-photo"
                          accept="application/pdf,image/png,image/gif,image/jpeg,image/jpg,application/msword"
                          className="d-flex"
                          type="file"
                          onChange={e => this.uploadPhoto(e)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.state.showCropper === true ? (
                  <div style={{ height: '300', width: '300' }}>
                    <Cropper
                      scaleX="30"
                      scaleY="30"
                      src={this.state.photoToDisplay}
                      ref={ref => {
                        this.cropper = ref;
                      }}
                      onChange={this.crop}
                    />
                  </div>
                ) : null}

                {this.state.photoToDisplay &&
                  !this.state.showCropper &&
                  this.state.fileType !== 'application/pdf' && (
                    <img
                      width="300px"
                      height="300px"
                      className="imgPosition"
                      src={this.state.photoToDisplay}
                    />
                  )}

                {this.state.photoToDisplay !== '' &&
                this.state.fileType !== '' &&
                this.state.fileType === 'application/pdf' ? (
                  <div>
                    <div className=" d-flex justify-content-center">
                      <Iframe
                        scrolling="no"
                        url={
                          this.state.fileType === 'application/pdf'
                            ? this.state.photoToDisplay
                            : ''
                        }
                        width="400px"
                        height="400px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        allowFullScreen
                      />
                    </div>
                  </div>
                ) : null}
                {this.state.photoToDisplay !== '' ? (
                  <div>
                    <i className="fas fa-hand-scissors" />
                    <div className="d-flex justify-content-center">
                      <button
                        hidden={
                          this.state.fileType === 'application/pdf' ||
                          this.state.showCropper
                        }
                        className="btn btn-primary m-2"
                        onClick={this.showCropperImage}
                      >
                        Crop
                      </button>
                      <button
                        className="btn btn-primary m-2"
                        onClick={this.confirm}
                      >
                        Next
                      </button>

                      <button
                        hidden={this.state.fileType === ''}
                        className="btn btn-primary m-2"
                        onClick={this.reUpload}
                      >
                        close
                      </button>

                      <button
                        hidden={this.state.fileType !== ''}
                        className="btn btn-primary m-2"
                        onClick={this.retake}
                      >
                        Retake
                      </button>
                    </div>
                  </div>
                ) : null}

                {this.state.showCamera ? (
                  <div>
                    {this.state.showCamera === true ? (
                      <div>
                        <Webcam
                          audio={false}
                          height={350}
                          ref={this.setRef}
                          screenshotFormat="image/jpeg"
                          width={750}
                          videoConstraints={videoConstraints}
                        />
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary m-2"
                            onClick={this.capture}
                          >
                            Capture
                          </button>
                          <button
                            className="btn btn-primary m-2"
                            onClick={this.close}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {/* Upload photo button */}
              </div>
            ) : (
              <div>
                {/* Slider */}
                {this.state.showSlider ||
                (!this.state.showPhotoSelection &&
                  !this.state.showPhotoUploadButton &&
                  !this.state.showCamera &&
                  !this.state.photoToDisplay) ? (
                  <div className="signin-slider">
                    <img
                      className="ob-icon-static"
                      src="../assets/images/kyc-main.png"
                      alt="First slide"
                    />
                    {/*<div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                       <ol className="carousel-indicators">
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={0}
                          className="active"
                        />
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={1}
                        />
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to={2}
                        />
                      </ol>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img
                            className=""
                            src="../assets/images/signin-carousel/Group.png"
                            alt="First slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src="../assets/images/signin-carousel/slide-2.png"
                            alt="Second slide"
                          />
                        </div>
                        <div className="carousel-item">
                          <img
                            className="d-block w-100"
                            src="../assets/images/signin-carousel/slide-3.png"
                            alt="Third slide"
                          />
                        </div> 
                      </div>
                    </div>*/}
                  </div>
                ) : null}
              </div>
            )}
            {/* Slider */}
            {(this.state.photoToDisplay &&
              !this.state.showCropper &&
              this.state.showPhotoProofBlock) ||
            (this.state.showPhotoUploadButton &&
              this.state.showPhotoProofBlock) ? (
              <div className="d-flex justify-content-center mt-5">
                <img
                  hidden={!(this.state.photoSide == 'FRONT')}
                  style={{ width: '300px', height: '200px' }}
                  src="../assets/images/id.png"
                />
                <img
                  hidden={!(this.state.photoSide == 'BACK')}
                  className="../assets/images/camera.png"
                  style={{ width: '300px', height: '200px' }}
                  src="../assets/images/id.png"
                />
                <img
                  hidden={!(this.state.photoSide == 'FACE')}
                  className="../assets/images/camera.png"
                  style={{ width: '300px', height: '200px' }}
                  src="../assets/images/id.png"
                />
              </div>
            ) : null}

            <div className="welcome-text kycWelcomeText">
              <h2>{this.state.title}</h2>
              <p>
                Upload the relevant image (should not exceed 500KB).
              </p>
            </div>
          </div>

          <div
            id="sidebar-main"
            className="col-lg-4 signin-right signin-right-height"
          >
            <div className="d-flex">
              <div className=" mr-auto">
                <div hidden={!this.state.showPhotoProofBlock}>
                  <h3 className="mt-2"> {this.state.heading}</h3>
                </div>
              </div>
              <div>
                <div
                  hidden={this.state.showBasicInfoBlock}
                  className="btn btn-link"
                  onClick={() => this.show('BACK')}
                >
                  Back
                </div>
              </div>
            </div>
            <div className="signin_right-main">
              {/* BASIC DETAILS */}
              {this.state.showBasicInfoBlock ? (
                <div>
                  <div className="row pt-4">
                    <div className="col-8">
                      <h5>Personal Information</h5>
                    </div>
                  </div>

                  <div className="basic-info row">
                    <input
                      readOnly
                      className={
                        this.state.errors === true && this.state.name === ''
                          ? 'error'
                          : ''
                      }
                      type="text"
                      name="Name"
                      onBlur={e =>
                        this.validateSingleField(
                          e.target.value,
                          previousData.name
                        )
                      }
                      placeholder="Full Name"
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                      required
                    />
                    <div className="dropdown gender-dropdown">
                      <select
                        disabled={this.state.readOnly}
                        // defaultOptionLabel={this.state.gender}
                        className={
                          this.state.errors === true &&
                          this.state.gender == 'Gender'
                            ? 'error'
                            : ''
                        }
                        value={
                          this.state.gender
                            ? this.state.gender.toLowerCase()
                            : ''
                        }
                        onChange={e => this.onSelectGender(e)}
                      >
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div
                      id="TooltipExample"
                      onMouseMove={() => this.setState({ tooltipOpen: true })}
                      onMouseLeave={() => this.setState({ tooltipOpen: false })}
                    >
                      <DatePicker
                        // todayButton={'ThroughBit'}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="DOB - DD/MM/YYYY"
                        className="blackColor datepickerNew"
                        width="100%"
                        disabled={this.state.readOnly}
                        onChange={date => this.setState({ dob: date })}
                        selected={this.state.dob}
                      />
                    </div>
                    {/* <Tooltip
                      placement="bottom"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipExample"
                    >
                      MM/DD/YYYY
                    </Tooltip> */}

                    {/* <div className="dob-picker">
                      <form method="post">
                        <input
                          readOnly={this.state.readOnly}
                          className={
                            this.state.errors === true && this.state.dob === ''
                              ? 'error form-control'
                              : 'form-control'
                          }
                          value={
                            this.state.dob == '00-00-0000' ? '' : this.state.dob
                          }
                          onChange={e => this.setState({ dob: e.target.value })}
                          id="date"
                          name="date"
                          placeholder="Date of Birth"
                          type="text"
                        />
                      </form>
                    </div> */}
                    <div className="dropdown country-dropdown">
                      {/* <Countries
                                        className={
                                            this.state.errors === true && this.state.country === ''
                                                ? 'error'
                                                : ''
                                        }
                                        style={{ color: 'none' }}
                                        ref="country"
                                        name="country"
                                        empty=" Country of Residence "

                                        onChange={e => this.onSelectCountry(e)}
                                    /> */}

                      <div>
                        <CountryDropdown
                          disabled={this.state.readOnly}
                          className={
                            this.state.errors === true &&
                            this.state.country === ''
                              ? 'error'
                              : ''
                          }
                          defaultOptionLabel="Country"
                          onBlur={e =>
                            this.validateSingleField(
                              e.target.value,
                              previousData.country
                            )
                          }
                          value={country}
                          onChange={val => this.selectCountry(val)}
                        />
                        <div />
                        <RegionDropdown
                          disabled={this.state.readOnly}
                          className={
                            this.state.errors === true &&
                            this.state.state === ''
                              ? 'error'
                              : ''
                          }
                          disableWhenEmpty={true}
                          blankOptionLabel="State"
                          defaultOptionLabel="State"
                          onBlur={e =>
                            this.validateSingleField(
                              e.target.value,
                              previousData.state
                            )
                          }
                          country={country}
                          value={state}
                          onChange={val => this.selectState(val)}
                        />
                      </div>

                      <div>
                        <input
                          readOnly={this.state.readOnly}
                          className={
                            this.state.errors === true && this.state.city === ''
                              ? 'error'
                              : ''
                          }
                          type="text"
                          onBlur={e =>
                            this.validateSingleField(
                              e.target.value,
                              previousData.city
                            )
                          }
                          placeholder="City"
                          value={this.state.city}
                          onChange={e =>
                            this.setState({ city: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>

                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      className={
                        this.state.errors === true && this.state.addr1 === ''
                          ? 'error form-control'
                          : 'form-control'
                      }
                      value={this.state.addr1}
                      onBlur={e =>
                        this.validateSingleField(
                          e.target.value,
                          previousData.addr1
                        )
                      }
                      onChange={e => this.setState({ addr1: e.target.value })}
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Address Line 1"
                    />

                    <input
                      readOnly={this.state.readOnly}
                      type="text"
                      className={
                        this.state.errors === true && this.state.addr2 === ''
                          ? 'error form-control '
                          : 'form-control '
                      }
                      value={this.state.addr2}
                      onBlur={e =>
                        this.validateSingleField(
                          e.target.value,
                          previousData.addr2
                        )
                      }
                      onChange={e => this.setState({ addr2: e.target.value })}
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Address Line 2"
                    />

                    <input
                      readOnly={this.state.readOnly}
                      className={
                        this.state.errors === true && this.state.pincode === ''
                          ? 'error pincode'
                          : 'pincode'
                      }
                      value={this.state.pincode == 0 ? '' : this.state.pincode}
                      onBlur={e =>
                        this.validateSingleField(
                          e.target.value,
                          previousData.pincode
                        )
                      }
                      onChange={e => this.setState({ pincode: e.target.value })}
                      type="text"
                      name="pincode"
                      placeholder="Pincode"
                      required
                    />
                  </div>
                </div>
              ) : null}
              {/* BASIC DETAILS */}
              {/* OTP DETAILS */}
              {this.state.showOTPBlock && this.state.hideOTPVerificationPage ? (
                <div
                  style={{ color: 'black' }}
                  className="vertical-center top-30 left-20"
                >
                  <i
                    className="fa fa-check-circle d-flex justify-content-center"
                    style={{ fontSize: '100px', color: 'green' }}
                  />
                  <div className="d-flex justify-content-center">
                    {' '}
                    Mobile No. Already verified please go to next page
                  </div>
                </div>
              ) : null}

              {this.state.showOTPBlock &&
              !this.state.hideOTPVerificationPage ? (
                <div>
                  <h3>Phone Number Verification</h3>
                  <div>{this.state.phone}</div>
                  <div className="phone-number">
                    <div className="send-otp">
                      <div>
                        {/* <label>{this.state.phone}</label> */}
                        <PhoneInput
                          disabled={this.state.disbaleOTPFields}
                          placeholder="Enter phone number"
                          value={this.state.phone}
                          onChange={phone => this.setPhone(phone)}
                        />

                        {/* <input
                        maxLength={13}
                        minLength={13}
                        type="tel"
                        id="phone"
                        className="form-control"
                        placeholder={
                          "Enter your mobile number with country code"
                        }
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        required
                        value={this.state.phone}
                        onChange={e => this.setState({ phone: e.target.value })}
                      /> */}
                      </div>
                      <input
                        hidden={
                          this.state.generateOTPButtonName == 'Verify' ||
                          this.state.generateOTPButtonName == ''
                        }
                        disabled={this.state.disbaleOTPFields}
                        //disabled={this.state.phone.length < 13}
                        className="btn btn-primary"
                        type="submit"
                        onClick={this.generateOTP}
                        value="Generate OTP"
                      />
                      {/* <input
                        hidden={
                          this.state.generateOTPButtonName == 'Generate OTP' ||
                          this.state.generateOTPButtonName == ''
                        }
                        disabled={this.state.disbaleOTPFields}
                        //disabled={this.state.phone.length < 13}
                        className="btn btn-primary"
                        type="submit"
                        value="Verify OTP"
                        onClick={this.generateOTP}
                      /> */}
                    </div>

                    {this.state.OTPSendResponse.error == 0 &&
                    this.state.showOTPEnterField ? (
                      <div className="verify-otp">
                        <label>Enter OTP</label>
                        <input
                          value={this.state.otp}
                          onChange={e => this.setState({ otp: e.target.value })}
                          type="tel"
                          id="phone"
                          className="form-control"
                          placeholder="Enter the 6 digit OTP sent to your phone number"
                          maxLength={6}
                          required
                        />

                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="VERIFY"
                          onClick={this.verifyOTP}
                        />
                        <div>
                          <button
                            // disabled={this.state.phone.length < 13}
                            className="btn btn-link"
                            onClick={this.generateOTP}
                          >
                            Resend OTP
                          </button>
                        </div>
                      </div>
                    ) : null}

                    {this.state.OTPVerifyResponse.error == 0 ? (
                      <h3 className="blueColor d-flex justify-content-center">
                        OTP Verified
                      </h3>
                    ) : null}

                    {/* {this.state.OTPSendResponse.error == 0 &&
                    this.state.OTPSendResponse.status == 1 ? (
                      <h3 className="blueColor d-flex justify-content-center">
                        OTP Verified
                      </h3>
                    ) : null} */}
                  </div>
                </div>
              ) : null}
              {/*OTP DETAILS */}
              {/*PHOTO DETAILS */}
              {this.state.showPhotoProofBlock ? (
                <div>
                  <div className="dropdown country-dropdown pt-5">
                    {/* <Countries
    style={{ color: 'none' }}
    ref="country"
    name="country"
    empty=" country "
    onChange={e => this.onSelectNativeCountry(e)}
/> */}

                    <CountryDropdown
                      value={issuingCountry}
                      onChange={val => this.onSelectNativeCountry(val)}
                    />
                  </div>
                  <p className="issuing-country">Choose issuing country</p>

                  {this.state.issuingCountry === 'India' ? (
                    <>
                      <h5 className="pb-5">Upload any one of the documents</h5>
                    </>
                  ) : null}
                  <div className="row pt-10">
                    {this.state.issuingCountry !== '' ? (
                      <div className="col-md-4">
                        <div
                          data-toggle="modal"
                          data-target="#myModal"
                          className="IDlogo  btn-primary"
                          onClick={() =>
                            this.checkIfPhotoExists('PASSPORT', 'Passport')
                          }
                        />
                        <p className="text-nowrap passportTextPos text-left cl-black pt-2">
                          Passport
                        </p>
                      </div>
                    ) : null}

                    {this.state.issuingCountry === 'India' ? (
                      <>
                        <div className="col-md-4">
                          <div
                            onClick={() =>
                              this.checkIfPhotoExists(
                                'DRIVING_LICENCE',
                                'Driving Licence'
                              )
                            }
                            data-toggle="modal"
                            data-target="#myModal"
                            className="IDlogo btn-primary"
                          />
                          <p className="text-nowrap DLTextPos text-left cl-black pt-2">
                            Driving License
                          </p>
                        </div>

                        <div className="col-md-4">
                          <div
                            onClick={() =>
                              this.checkIfPhotoExists(
                                'ADHAR_CARD',
                                'Aadhaar Card'
                              )
                            }
                            data-toggle="modal"
                            data-target="#myModal"
                            className="IDlogo btn-primary"
                          />
                          <p className="text-nowrap adharTextPos text-left cl-black pt-2">
                            Aadhaar Card
                          </p>
                        </div>
                      </>
                    ) : null}

                    {/* <div className="modal top-30" id="myModal">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4
                                className="modal-title fw-800"
                                style={{ color: '#fff' }}
                              >
                                Warning
                              </h4>
                            </div>
                     
                            <div
                              className="modal-body"
                              style={{ color: '#fff' }}
                            >
                              You will loose the data
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => this.cancelModal(this.state.prevphoto)}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={this.changeIDProof}
                                style={{ width: '100px' }}
                              >
                                OK
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}

                    <div>
                      <Modal
                        centered
                        isOpen={this.state.showModal && this.checkPhotoStatus()}
                        className={this.props.className}
                      >
                        <ModalHeader className="modal-title fw-800">
                          Warning
                        </ModalHeader>
                        <ModalBody>You will loose the data</ModalBody>
                        <ModalFooter>
                          <Button color="primary" onClick={this.changeIDProof}>
                            OK
                          </Button>{' '}
                          <Button
                            color="secondary"
                            onClick={() =>
                              this.cancelModal(this.state.prevphoto)
                            }
                          >
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>

                    {/* <div
                    hidden={
                      !this.state.photoType ||
                      this.state.issuingCountry !== "India"
                    }
                  >
                    <button
                      className="btn btn-link"
                      onClick={this.changeIDProof}
                    >
                      Change ID proof
                    </button>
                  </div> */}
                  </div>
                </div>
              ) : null}
              {/*PHOTO DETAILS */}
              {this.state.showPhotoProofBlock ? (
                <div className="row">
                  {this.state.title !== 'KYC' && (
                    <input
                      className={
                        this.state.errors === true && this.state.name === ''
                          ? 'attentionNumber'
                          : 'attentionNumber'
                      }
                      placeholder={this.state.title + ' No.'}
                      type="text"
                      value={this.state.idNumber}
                      onChange={e =>
                        this.setState({ idNumber: e.target.value })
                      }
                      required
                    />
                  )}
                </div>
              ) : null}

              {this.state.showPhotoProofBlock && (
                <div className="row">
                  <div
                    className="col-4 text-center"
                    hidden={!this.state.frontPhoto}
                  >
                    <img
                      hidden={this.state.fileType === 'application/pdf'}
                      className="IDPhoto"
                      src={this.state.frontPhoto}
                    />
                    <div hidden={this.state.fileType !== 'application/pdf'}>
                      <Iframe
                        hidden={this.state.fileType !== 'application/pdf'}
                        scrolling="no"
                        url={this.state.frontPhoto}
                        width="80px"
                        height="80px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        allowFullScreen
                      />
                    </div>
                    <p className="cl-black pt-2">Front photo</p>
                  </div>
                  <div
                    className="col-4 text-center"
                    hidden={!this.state.backPhoto}
                  >
                    <img
                      hidden={this.state.fileType === 'application/pdf'}
                      className="IDPhoto"
                      src={this.state.backPhoto}
                    />
                    <div hidden={this.state.fileType !== 'application/pdf'}>
                      <Iframe
                        scrolling="no"
                        url={this.state.backPhoto}
                        width="80px"
                        height="80px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        allowFullScreen
                      />
                    </div>
                    <p className="cl-black pt-2">Back photo</p>
                  </div>
                  <div
                    className="col-4 text-center"
                    hidden={!this.state.otherPhoto}
                  >
                    <img
                      hidden={this.state.fileType === 'application/pdf'}
                      className="IDPhoto"
                      src={this.state.otherPhoto}
                    />

                    <div hidden={this.state.fileType !== 'application/pdf'}>
                      <Iframe
                        scrolling="no"
                        url={this.state.otherPhoto}
                        width="80px"
                        height="80px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        allowFullScreen
                      />
                    </div>
                    <p className="cl-black pt-2"> Front with Face photo</p>
                  </div>
                </div>
              )}
            </div>

            <h5 hidden={!this.state.errorMessage} style={{ color: 'red' }}>
              {this.state.errorMessage}
            </h5>
            {/* Next Button */}
            <div className="d-flex">
              <button
                hidden={this.state.readOnly}
                className={
                  !this.state.reloadButton
                    ? 'btn btn-primary w-100'
                    : 'btn btn-primary w-100 reload-button'
                }
                type="submit"
                onClick={() => {
                  if (
                    this.state.generateOTPButtonName == 'Verify' &&
                    !this.state.hideOTPVerificationPage
                  ) {
                    this.generateOTP();
                  } else {
                    this.show('NEXT');
                  }
                }}
              >
                {!this.state.reloadButton ? (
                  this.state.buttonValue
                ) : (
                  <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                )}
              </button>

              {this.state.readOnly ? (
                <h4 style={{ color: 'black' }}>
                  Kyc details verified successfully
                </h4>
              ) : null}
            </div>
            {/* Next Button  */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      activeTabUpdate: formData => activeTabUpdate(formData),
      generateOTPNumber: formData => generateOTPNumber(formData),
      verifyOTP: formData => verifyOTPNumber(formData),
      saveBasicInfo: formData => saveBasicInformation(formData),
      saveCopies: formData => saveCopies(formData),
      getKycDetails: formData => getKycDetails(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    OTPSendResponse: state.kyc.OTPSendResponse,
    OTPVerifyResponse: state.kyc.OTPVerifyResponse,
    basicInfoResponse: state.kyc.basicInfoResponse,
    kycUploadLoader: state.settings.kycUploadLoader,
    kycDetailsResponse: state.kyc.kycDetailsResponse,
    copiesResponse: state.kyc.copiesResponse,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile
  }),
  mapDispatchToProps
)(Kyc);
