import * as React from 'react';
import './index.css';
import Datafeed from './api/';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
const cookies = new Cookies();

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: 'BTC/LSK',
    containerId: 'tv_chart_container',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {}
  };

  widget = null;

  componentDidMount() {
    this.callTVFunc();
  }

  callTVFunc(symbol = this.props.symbol) {
    // console.log('symbol :: ', symbol);
    const widgetOptions = {
      // https://github.com/tradingview/charting_library/wiki/Widget-Constructor
      // https://github.com/throughbit/exchange-ui/wiki/Widget-Constructor
      // https://pastebin.com/cPSyVA7j
      custom_css_url: '/charting_library/static/style.css',
      toolbar_bg: '#171429',
      loading_screen: {
        backgroundColor: '#171429',
        foregroundColor: '#555555'
      },
      // debug: true,
      debug: false,
      symbol: symbol,
      datafeed: Datafeed,
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || 'en',
      disabled_features: [
        // 'use_localstorage_for_settings',
        'header_interval_dialog_button',
        'header_settings',
        'header_saveload',
        'header_screenshot',
        // 'header_resolutions',
        // 'create_volume_indicator_by_default',
        // 'chart_scroll',
        // 'chart_zoom',
        'header_chart_type',
        'header_compare',
        'border_around_the_chart',
        // 'header_undo_redo',
        // 'header_indicators',
        'header_symbol_search',
        'symbol_search_hot_key'
      ],
      enabled_features: [
        'side_toolbar_in_fullscreen_mode',
        'keep_left_toolbar_visible_on_small_screens',
        'left_toolbar',
        // 'high_density_bars',
        // 'study_templates',
        'caption_buttons_text_if_possible'
        // 'narrow_chart_enabled',
      ],
      time_frames: [
        // { text: "5y", resolution: "6M", description: "5 Years", title: "5Yr" },
        // { text: "1y", resolution: "W", description: "1 Year", title: "3Yr" },
        // { text: "6m", resolution: "D", description: "6 Month", title: "6Month" },
        // { text: "3m", resolution: "D", description: "3 Month", title: "3Month" },
        // { text: "1m", resolution: "D", description: "1 Month", title: "1Month" },
        // { text: "5d", resolution: "5", description: "5 Days", title: "5Day" },
        // { text: "1d", resolution: "1", description: "1 Day", title: "1Day" },
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      // fullscreen: false,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      overrides: {
        'mainSeriesProperties.showCountdown': false,
        'mainSeriesProperties.visible': false,
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.priceLineWidth': 1,
        'mainSeriesProperties.lockScale': false,
        'mainSeriesProperties.minTick': 'default',

        'symbolWatermarkProperties.color': 'rgba(0, 0, 0, 0)',
        // "mainSeriesProperties.showCountdown": true,
        'paneProperties.background': '#171429',
        'paneProperties.vertGridProperties.color': 'rgba(0, 0, 0, 0)',
        'paneProperties.horzGridProperties.color': 'rgba(0, 0, 0, 0)',
        'symbolWatermarkProperties.transparency': 0,
        // "scalesProperties.textColor" : "#AAA",
        'mainSeriesProperties.candleStyle.wickUpColor': '#336854',
        'mainSeriesProperties.candleStyle.wickDownColor': '#7f323f'

        // supported values: large, medium, small, tiny
        // "volumePaneSize": "tiny",

        // Candles styles
        // "mainSeriesProperties.candleStyle.upColor": "#6ba583",
        // "mainSeriesProperties.candleStyle.downColor": "#d75442",
        // "mainSeriesProperties.candleStyle.drawWick": true,
        // "mainSeriesProperties.candleStyle.drawBorder": true,
        // "mainSeriesProperties.candleStyle.borderColor": "#378658",
        // "mainSeriesProperties.candleStyle.borderUpColor": "#225437",
        // "mainSeriesProperties.candleStyle.borderDownColor": "#5b1a13",
        // "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false
      }
      // drawings_access: {
      // 	type: 'black',
      // 	// tools: [{name: "Regression Trend"}]//todo: moje
      // 	tools: [{name: "Trend Line", grayed: true}, {name: "Trend Angle", grayed: true}] //todo: bb
      // },
      // favorites: {
      //   intervals: ['1H', '1D']
      // }
    };

    const widget = new window.TradingView.widget(widgetOptions);
    this.widget = widget;

    widget.onChartReady(() => {
      // console.log('Chart has loaded!');
      // const button = widget.createButton()
      // 	.attr('title', 'Click to show a notification popup')
      // 	.addClass('apply-common-tooltip')
      // 	.on('click', () => widget.showNoticeDialog({
      // 		title: 'Notification',
      // 		body: 'TradingView Charting Library API works correctly',
      // 		callback: () => {
      // console.log('Noticed!');
      // 		},
      // 	}));
      // button[0].innerHTML = 'Check API';
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      if (this.widget !== null) {
        this.widget = null;
      }

      cookies.set('activeMarketValue', nextProps.activeMarketValue);
      cookies.set('activeCurrencyValue', nextProps.activeCurrencyValue);

      this.callTVFunc(
        `${nextProps.activeMarketSymbol}/${nextProps.activeCurrencySymbol}`
      );
    }
  }

  componentWillUnmount() {
    if (this.widget !== null) {
      // this.widget.remove();
      this.widget = null;
    }
  }

  render() {
    return (
      <div id={this.props.containerId} style={{ height: this.props.height }} />
    );
  }
}
export default connect(state => ({
  activeMarketValue: state.app.activeMarketValue,
  activeCurrencyValue: state.app.activeCurrencyValue,
  activeMarketSymbol: state.app.activeMarketSymbol,
  activeCurrencySymbol: state.app.activeCurrencySymbol
}))(TVChartContainer);
