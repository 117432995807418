/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import {
  headerActiveMenuUpdate,
  marketCurrencyUpdate,
  activeMarketValueChange
} from 'App/appReducer';
import { getBuyOrders, getSellOrders } from './liveOrdersReducer';
import { getRecentTrades } from '../recentTrades/recentTradesReducer';

import {
  sellLimitInputRemotelyChange,
  buyLimitInputRemotelyChange
} from '../limitForm/limitReducer';
import {
  sellMarketInputRemotelyChange,
  buyMarketInputRemotelyChange
} from '../marketForm/marketReducer';
import {
  buyStopLimitInputRemotelyChange,
  sellStopLimitInputRemotelyChange
} from '../stopLimitForm/stopLimitReducer';
import _ from 'underscore';
import { UncontrolledTooltip } from 'reactstrap';

const cookies = new Cookies();
let email = cookies.get('email');

class LiveOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      orderDetails: '',
      buyOrderLoader: false,
      sellOrderLoader: false,
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      marketsCoinsListObj: {},
      searchDataObject: {},
      coinSymbolsObj: {},
      buyOrdersList: [],
      buyOrdersListReverse: [],
      buyOrdersError: null,
      sellOrdersList: [],
      sellOrdersError: null,
      recentTradeList: this.props.getRecentTradeList.data,
      recentTradeColor: 'grey-box',
      recentTradeError: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      liveOrdersActiveTab: '1',
      limit: this.props.openOrdersFirstTabDataLimit,
      openOrdersFirstTabDataLimit: this.props.openOrdersFirstTabDataLimit,
      recentTradesLimit: 50,
      coinSearchValue: '',
      coinIdtoSymbolsObj: {},
      priceGap: 0,
      sidebar_main_height: this.props.sidebar_main_height
    };
    this.liveOrdersToggle = this.liveOrdersToggle.bind(this);
  }

  liveOrdersToggle(tab, limit, operation) {
    if (this.state.liveOrdersActiveTab !== tab) {
      this.setState({
        liveOrdersActiveTab: tab
      });
    }
    // if (this.state.limit !== limit) {
    this.setState({ limit });
    if (operation === 'buy') {
      this.setState(
        {
          // buyOrderLoader: true,
          buyOrdersList: null
        },
        this.props.getBuyOrders({
          operation: 1,
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: limit
        })
      );
    } else if (operation === 'sell') {
      this.setState(
        {
          // sellOrderLoader: true,
          sellOrdersList: null
        },
        this.props.getSellOrders({
          operation: 0,
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: limit
        })
      );
    } else {
      this.setState(
        {
          // buyOrderLoader: true,
          buyOrdersList: null,
          // sellOrderLoader: true,
          sellOrdersList: null
        },
        this.callOpenOrders(limit)
      );
    }
    // }
  }

  callOpenOrders(limit) {
    const { recentTradeList } = this.state;
    this.props.getBuyOrders({
      operation: 1,
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: limit
    });
    this.props.getSellOrders({
      operation: 0,
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: limit
    });

    if (recentTradeList && recentTradeList.length === 0) {
      this.props.getRecentTrades({
        market: this.props.activeMarketValue,
        coin: this.props.activeCurrencyValue,
        skip: '0',
        limit: this.state.recentTradesLimit
      });
    }
  }

  setData() {
    this.setState({
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      marketsCoinsListObj: this.props.marketsCoinsListObj
    });

    this.props.getBuyOrders({
      operation: 1,
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: this.state.limit
    });

    this.props.getSellOrders({
      operation: 0,
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: this.state.limit
    });

    this.props.getRecentTrades({
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue,
      skip: '0',
      limit: this.state.recentTradesLimit
    });

    // this.props.changePage(
    //   `/${this.props.activeMarketSymbol}-${this.props.activeCurrencySymbol}`
    // );
  }

  componentDidMount() {
    this.setState({
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      activeMarketValue: this.props.activeMarketValue,
      coinIdtoSymbolsObj: this.props.coinIdtoSymbolsObj,
      coinSymbolsObj: this.props.coinSymbolsObj
    });

    this.setData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sidebar_main_height !== nextProps.sidebar_main_height) {
      this.setState({ sidebar_main_height: nextProps.sidebar_main_height });
    }

    if (
      this.props.openOrdersFirstTabDataLimit !==
      nextProps.openOrdersFirstTabDataLimit
    ) {
      this.setState(
        {
          openOrdersFirstTabDataLimit: nextProps.openOrdersFirstTabDataLimit,
          limit: nextProps.openOrdersFirstTabDataLimit
        },
        // this.liveOrdersToggle('1', nextProps.openOrdersFirstTabDataLimit)
        this.liveOrdersToggle('1', '50')
      );
    }

    if (this.props.buyOrderLoader !== nextProps.buyOrderLoader) {
      this.setState({ buyOrderLoader: nextProps.buyOrderLoader });
    }

    if (this.props.sellOrderLoader !== nextProps.sellOrderLoader) {
      this.setState({ sellOrderLoader: nextProps.sellOrderLoader });
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      this.setState({ activeMarketValue: nextProps.activeMarketValue });
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      this.setState({ activeCurrencyValue: nextProps.activeCurrencyValue });
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }

    if (this.props.marketsCoinsListObj !== nextProps.marketsCoinsListObj) {
      this.setState({
        marketsCoinsListObj: nextProps.marketsCoinsListObj
      });
    }

    if (this.props.getBuyOrdersList !== nextProps.getBuyOrdersList) {
      if (nextProps.getBuyOrdersList.error === 0) {
        if (
          nextProps.getBuyOrdersList.data.length >
          this.state.openOrdersFirstTabDataLimit
        ) {
          const buyOrdersLastRawsList = nextProps.getBuyOrdersList.data.slice(
            nextProps.getBuyOrdersList.data.length -
              this.state.openOrdersFirstTabDataLimit,
            nextProps.getBuyOrdersList.data.length
          );
          this.setState({
            buyOrdersList: nextProps.getBuyOrdersList.data,
            buyOrdersLastRawsList,
            buyOrdersError: null
          });
        } else {
          this.setState({
            buyOrdersList: nextProps.getBuyOrdersList.data,
            buyOrdersLastRawsList: nextProps.getBuyOrdersList.data,
            buyOrdersError: null
          });
        }

        if (this.state.liveOrdersActiveTab === '2') {
          const buyOrdersListReverse = [...nextProps.getBuyOrdersList.data];
          this.setState(
            {
              buyOrdersListReverse: buyOrdersListReverse.reverse()
            },
            this.checkPriceGap
          );
        } else {
          this.setState(
            {
              buyOrdersListReverse: nextProps.getBuyOrdersList.data
            },
            this.checkPriceGap
          );
        }
      } else {
        this.setState(
          {
            buyOrdersList: null,
            buyOrdersListReverse: null,
            buyOrdersError: nextProps.getBuyOrdersList.message
          },
          this.checkPriceGap
        );
      }
    }

    // //Handle The Response IF Recieved Transaction Data
    // if (
    //   Array.isArray(nextProps.getBuyOrdersList) &&
    //   nextProps.getBuyOrdersList !== undefined &&
    //   this.props.getBuyOrdersList !== nextProps.getBuyOrdersList
    // ) {
    //   if (nextProps.getBuyOrdersList.length !== 0) {
    //     this.setState(
    //       {
    //         buyOrdersList: nextProps.getBuyOrdersList
    //         // buyOrdersListReverse: nextProps.getBuyOrdersList.reverse()
    //       },
    //       this.checkPriceGap
    //     );
    //   } else {
    //     this.setState(
    //       {
    //         buyOrdersList: null
    //       },
    //       this.checkPriceGap
    //     );
    //   }
    // } else if (!Array.isArray(nextProps.getBuyOrdersList)) {
    //   this.setState(
    //     {
    //       buyOrdersList: null,
    //       buyOrdersError: nextProps.getBuyOrdersList
    //     },
    //     this.checkPriceGap
    //   );
    // }

    if (this.props.getSellOrdersList !== nextProps.getSellOrdersList) {
      if (nextProps.getSellOrdersList.error === 0) {
        this.setState(
          {
            sellOrdersList: nextProps.getSellOrdersList.data,
            sellOrdersError: null
          },
          this.checkPriceGap
        );
      } else {
        this.setState(
          {
            sellOrdersList: null,
            sellOrdersError: nextProps.getSellOrdersList.message
          },
          this.checkPriceGap
        );
      }
    }

    // if (
    //   Array.isArray(nextProps.getSellOrdersList) &&
    //   nextProps.getSellOrdersList !== undefined &&
    //   this.props.getSellOrdersList !== nextProps.getSellOrdersList
    // ) {
    //   if (nextProps.getSellOrdersList.length !== 0) {
    //     this.setState(
    //       {
    //         sellOrdersList: nextProps.getSellOrdersList
    //       },
    //       this.checkPriceGap
    //     );
    //   } else {
    //     this.setState(
    //       {
    //         sellOrdersList: null
    //       },
    //       this.checkPriceGap
    //     );
    //   }
    // } else if (!Array.isArray(nextProps.getSellOrdersList)) {
    //   this.setState(
    //     {
    //       sellOrdersList: null,
    //       sellOrdersError: nextProps.getSellOrdersList
    //     },
    //     this.checkPriceGap
    //   );
    // }

    if (this.props.getRecentTradeList !== nextProps.getRecentTradeList) {
      if (nextProps.getRecentTradeList.error === 0) {
        this.setState({
          recentTradeList: nextProps.getRecentTradeList.data,
          recentTradeScrollLoader: false,
          recentTradeError: null
        });
        this.checkRecentTradeColor(nextProps.getRecentTradeList.data);
      } else {
        this.setState({
          recentTradeList: null,
          recentTradeError: nextProps.getRecentTradeList.message,
          recentTradeScrollLoader: false
        });
      }
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      setTimeout(() => {
        this.props.getBuyOrders({
          operation: 1,
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: this.state.limit
        });

        this.props.getSellOrders({
          operation: 0,
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: this.state.limit
        });

        this.props.getRecentTrades({
          market: this.props.activeMarketValue,
          coin: this.props.activeCurrencyValue,
          skip: '0',
          limit: this.state.recentTradesLimit
        });
      }, 100);
    }
  }

  checkRecentTradeColor(recentTradeList) {
    var recentTradeColor = '';
    if (recentTradeList.length > 1) {
      const priceDifference =
        recentTradeList[0].executionPrice - recentTradeList[1].executionPrice;
      if (priceDifference >= 0) {
        recentTradeColor = 'positive-box';
      } else {
        recentTradeColor = 'negative-box';
      }
    } else if (recentTradeList.length > 0) {
      if (recentTradeList[0].operation) {
        recentTradeColor = 'positive-box';
      } else {
        recentTradeColor = 'negative-box';
      }
    } else {
      recentTradeColor = 'grey-box';
    }
    this.setState({ recentTradeColor });
  }

  checkPriceGap() {
    const { buyOrdersList, sellOrdersList } = this.state;
    if (
      buyOrdersList &&
      buyOrdersList.length > 0 &&
      sellOrdersList &&
      sellOrdersList.length > 0
    ) {
      const priceGap = Math.abs(
        sellOrdersList[0].price - buyOrdersList[buyOrdersList.length - 1].price
      );
      this.setState({ priceGap });
    } else if (sellOrdersList && sellOrdersList.length > 0) {
      const priceGap = sellOrdersList[0].price;
      this.setState({ priceGap });
    } else if (buyOrdersList && buyOrdersList.length > 0) {
      const priceGap = buyOrdersList[buyOrdersList.length - 1].price;
      this.setState({ priceGap });
    } else {
      this.setState({ priceGap: 0 });
    }
  }

  handleOpenDrawer = orderid => () => {
    this.setState({ showMessage: true, orderid });
  };

  buyRawDataUpdateForm(obj, i, tab) {
    if (this.props.formActiveTab === 'limit') {
      let { buyOrdersList, buyOrdersLastRawsList } = this.state;
      let limit = buyOrdersList.length;
      if (tab === '1') {
        buyOrdersList = buyOrdersLastRawsList;
        limit = buyOrdersLastRawsList.length;
      }
      var volumeSum = 0;
      var totalSum = 0;
      _.map(_.range(i, limit), index => {
        volumeSum = volumeSum + buyOrdersList[index].volume;
        totalSum =
          totalSum + buyOrdersList[index].price * buyOrdersList[index].volume;
      });
      this.props.sellLimitInputRemotelyChange({
        volumeSell: volumeSum.toFixed(8),
        bidSell: obj.price.toFixed(8),
        totalSell: totalSum.toFixed(8)
      });
    } else if (this.props.formActiveTab === 'market') {
      let { buyOrdersList, buyOrdersLastRawsList } = this.state;
      let limit = buyOrdersList.length;
      if (tab === '1') {
        buyOrdersList = buyOrdersLastRawsList;
        limit = buyOrdersLastRawsList.length;
      }
      var volumeSum = 0;
      _.map(_.range(i, limit), index => {
        volumeSum = volumeSum + buyOrdersList[index].volume;
      });
      this.props.sellMarketInputRemotelyChange({
        volumeMarketSell: volumeSum.toFixed(8)
      });
    } else if (this.props.formActiveTab === 'stopLimit') {
      this.props.sellStopLimitInputRemotelyChange({
        limitSell: obj.price.toFixed(8),
        stopSell: obj.price.toFixed(8)
      });
    }
  }

  sellRawDataUpdateForm(obj, i) {
    const { sellOrdersList } = this.state;
    if (this.props.formActiveTab === 'limit') {
      var volumeSum = 0;
      var totalSum = 0;
      _.map(_.range(i + 1), index => {
        volumeSum = volumeSum + sellOrdersList[index].volume;
        totalSum =
          totalSum + sellOrdersList[index].price * sellOrdersList[index].volume;
      });
      this.props.buyLimitInputRemotelyChange({
        volumeBuy: volumeSum.toFixed(8),
        bidBuy: obj.price.toFixed(8),
        totalBuy: totalSum.toFixed(8)
      });
    } else if (this.props.formActiveTab === 'market') {
      var totalSum = 0;
      _.map(_.range(i + 1), index => {
        totalSum =
          totalSum + sellOrdersList[index].price * sellOrdersList[index].volume;
      });
      this.props.buyMarketInputRemotelyChange({
        volumeMarketBuy: totalSum.toFixed(8)
      });
    } else if (this.props.formActiveTab === 'stopLimit') {
      this.props.buyStopLimitInputRemotelyChange({
        limitBuy: obj.price.toFixed(8),
        stopBuy: obj.price.toFixed(8)
      });
    }
  }

  render() {
    const {
      buyOrderLoader,
      sellOrderLoader,
      buyOrdersList,
      buyOrdersListReverse,
      buyOrdersLastRawsList,
      sellOrdersList,
      recentTradeList,
      recentTradeColor,
      marketsCoinsListObj,
      activeMarketValue,
      coinSymbolsObj,
      priceGap,
      openOrdersFirstTabDataLimit
    } = this.state;
    return (
      <div className="col-lg-6 container-one col-lg-4 container-one pr-1">
        <ul className="nav nav-switch">
          <li className="nav-item ml-auto">
            <a
              className={`crsrPntr 
                ${
                  this.state.liveOrdersActiveTab === '1'
                    ? 'nav-link active'
                    : 'nav-link'
                }`}
              onClick={() => {
                this.liveOrdersToggle('1', '50');
              }}
              // active={(
              //   this.state.liveOrdersActiveTab === '1'
              // ).toString()}
              aria-selected={this.state.liveOrdersActiveTab === '1'}
            >
              <img
                src="assets/images/buyandsell.png"
                alt="Buy-Sell Orders"
                id="tooltip-1"
              />
              <UncontrolledTooltip
                placement="top"
                target="tooltip-1"
                delay={{ hide: -1 }}
              >
                Buy-Sell Orders
              </UncontrolledTooltip>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`crsrPntr 
                ${
                  this.state.liveOrdersActiveTab === '2'
                    ? 'nav-link active'
                    : 'nav-link'
                }`}
              onClick={() => {
                this.liveOrdersToggle('2', '50', 'buy');
              }}
              // active={(
              //   this.state.liveOrdersActiveTab === '2'
              // ).toString()}
              aria-selected={this.state.liveOrdersActiveTab === '2'}
            >
              <img
                src="assets/images/buy.png"
                alt="Buy Orders"
                id="tooltip-2"
              />
              <UncontrolledTooltip
                placement="top"
                target="tooltip-2"
                delay={{ hide: -1 }}
              >
                Buy Orders
              </UncontrolledTooltip>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`crsrPntr 
                ${
                  this.state.liveOrdersActiveTab === '3'
                    ? 'nav-link active'
                    : 'nav-link'
                }`}
              onClick={() => {
                this.liveOrdersToggle('3', '50', 'sell');
              }}
              // active={(
              //   this.state.liveOrdersActiveTab === '3'
              // ).toString()}
              aria-selected={this.state.liveOrdersActiveTab === '3'}
            >
              <img
                src="assets/images/sell.png"
                alt="Sell Orders"
                id="tooltip-3"
              />
              <UncontrolledTooltip
                placement="top"
                target="tooltip-3"
                delay={{ hide: -1 }}
              >
                Sell Orders
              </UncontrolledTooltip>
            </a>
          </li>
        </ul>

        {this.state.liveOrdersActiveTab === '1' && (
          <>
            <div
              id="positive-table"
              className="container-fluid h-40 card-box"
              // style={{ minHeight: `${parseInt((this.state.sidebar_main_height * 40) / 100)}px !important` }}
            >
              {!buyOrderLoader && this.state.buyOrdersError && (
                <div>{this.state.buyOrdersError.toString()}</div>
              )}

              {buyOrderLoader && <div className="loading-o" />}

              {!buyOrderLoader && (
                <table className="table data-table">
                  <thead>
                    <tr>
                      <th scope="col faded">Price</th>
                      <th scope="col faded">{`Amount(${this.props.activeCurrencySymbol})`}</th>
                      <th scope="col faded">{`Total(${this.props.activeMarketSymbol})`}</th>
                    </tr>
                  </thead>

                  {!buyOrderLoader &&
                    this.state.buyOrdersError === null &&
                    (buyOrdersList && buyOrdersList.length !== 0 ? (
                      <tbody>
                        {buyOrdersLastRawsList.length <
                          openOrdersFirstTabDataLimit &&
                          _.range(
                            openOrdersFirstTabDataLimit -
                              buyOrdersLastRawsList.length
                          ).map(i => (
                            <tr key={i} className="removeborder">
                              <td />
                              <td />
                              <td />
                            </tr>
                          ))}
                        {buyOrdersLastRawsList.map((o, i) => (
                          <tr
                            key={o.price}
                            onClick={() => this.buyRawDataUpdateForm(o, i, '1')}
                            className={`crsrPntr ${o.className}`}
                          >
                            <td className="positive-text">
                              {o.price.toFixed(8)}
                            </td>
                            <td>{o.volume.toFixed(8)}</td>
                            <td>{(o.price * o.volume).toFixed(8)}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            <div className="noBal">No Buy Orders</div>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              )}
            </div>

            <div className={recentTradeColor}>
              <div className="row no-gutters">
                <div className="col-md-6 text-left">
                  <div className="title-price-box">Price Gap</div>
                  {priceGap.toFixed(8)}{' '}
                  <span className="currency">
                    {this.props.activeMarketSymbol}
                  </span>
                </div>
                <div className="col-md-6 text-right">
                  <div className="title-price-box">Last Price</div>
                  {recentTradeList &&
                    recentTradeList.length > 0 &&
                    recentTradeList[0].executionPrice}{' '}
                  {recentTradeList &&
                    recentTradeList.length === 0 &&
                    '0.00000 '}
                  <span className="currency">
                    {this.props.activeMarketSymbol}
                  </span>
                </div>
              </div>
            </div>

            <div
              id="negative-table"
              className="container-fluid h-40 card-box"
              style={{ height: '38vh' }}
            >
              {this.state.sellOrdersError && (
                <div>{this.state.sellOrdersError.toString()}</div>
              )}

              {sellOrderLoader && <div className="loading-o" />}

              {!sellOrderLoader &&
                this.state.sellOrdersError === null &&
                (this.state.sellOrdersList &&
                this.state.sellOrdersList.length !== 0 ? (
                  <table className="table data-table">
                    <thead className="table-hide-head">
                      <tr>
                        <th scope="col faded">Price</th>
                        <th scope="col faded">Amount(LSK)</th>
                        <th scope="col faded">Total(BTC)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sellOrdersList.map((o, i) => (
                        <tr
                          key={o.price}
                          onClick={() => this.sellRawDataUpdateForm(o, i)}
                          className={`crsrPntr ${o.className}`}
                        >
                          <td className="negative-text">
                            {o.price.toFixed(8)}
                          </td>
                          <td>{o.volume.toFixed(8)}</td>
                          <td>{(o.price * o.volume).toFixed(8)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="noBal">No Sell Orders</div>
                ))}
            </div>
          </>
        )}

        {this.state.liveOrdersActiveTab === '2' && (
          <div className="container-fluid h-100 card-box recentTrade">
            {!buyOrderLoader && this.state.buyOrdersError && (
              <div>{this.state.buyOrdersError.toString()}</div>
            )}

            {buyOrderLoader && <div className="loading-o" />}

            {!buyOrderLoader &&
              this.state.buyOrdersError === null &&
              (buyOrdersList && buyOrdersList.length !== 0 ? (
                <>
                  <table className="table data-table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col faded">Price</th>
                        <th scope="col faded">{`Amount(${this.props.activeCurrencySymbol})`}</th>
                        <th scope="col faded">{`Total(${this.props.activeMarketSymbol})`}</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ height: this.state.sidebar_main_height - 100 }}
                    >
                      {buyOrdersListReverse.map((o, i) => (
                        <tr
                          key={o.price}
                          className="crsrPntr"
                          onClick={() =>
                            this.buyRawDataUpdateForm(
                              o,
                              buyOrdersListReverse.length - i - 1,
                              '2'
                            )
                          }
                        >
                          <td className="positive-text">
                            {o.price.toFixed(8)}
                          </td>
                          <td>{o.volume.toFixed(8)}</td>
                          <td>{(o.price * o.volume).toFixed(8)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="noBal">No Buy Orders</div>
              ))}
          </div>
        )}

        {this.state.liveOrdersActiveTab === '3' && (
          <div className="container-fluid h-100 card-box recentTrade">
            {this.state.sellOrdersError && (
              <div>{this.state.sellOrdersError.toString()}</div>
            )}

            {sellOrderLoader && <div className="loading-o" />}

            {!sellOrderLoader &&
              this.state.sellOrdersError === null &&
              (this.state.sellOrdersList &&
              this.state.sellOrdersList.length !== 0 ? (
                <>
                  <table className="table data-table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col faded">Price</th>
                        <th scope="col faded">{`Amount(${this.props.activeCurrencySymbol})`}</th>
                        <th scope="col faded">{`Total(${this.props.activeMarketSymbol})`}</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ height: this.state.sidebar_main_height - 100 }}
                    >
                      {this.state.sellOrdersList.map((o, i) => (
                        <tr
                          key={o.price}
                          className="crsrPntr"
                          onClick={() => this.sellRawDataUpdateForm(o, i)}
                        >
                          <td className="negative-text">
                            {o.price.toFixed(8)}
                          </td>
                          <td>{o.volume.toFixed(8)}</td>
                          <td>{(o.price * o.volume).toFixed(8)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="noBal">No Sell Orders</div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

LiveOrders.defaultProps = {
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBuyOrders: formData => getBuyOrders(formData),
      getSellOrders: formData => getSellOrders(formData),
      getRecentTrades: formData => getRecentTrades(formData),
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      marketCurrencyUpdate: formData => marketCurrencyUpdate(formData),
      activeMarketValueChange: formData => activeMarketValueChange(formData),
      buyLimitInputRemotelyChange: formData =>
        buyLimitInputRemotelyChange(formData),
      sellLimitInputRemotelyChange: formData =>
        sellLimitInputRemotelyChange(formData),
      buyMarketInputRemotelyChange: formData =>
        buyMarketInputRemotelyChange(formData),
      sellMarketInputRemotelyChange: formData =>
        sellMarketInputRemotelyChange(formData),
      buyStopLimitInputRemotelyChange: formData =>
        buyStopLimitInputRemotelyChange(formData),
      sellStopLimitInputRemotelyChange: formData =>
        sellStopLimitInputRemotelyChange(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    orderDetails: state.dashboard.orderDetails,
    formActiveTab: state.dashboard.formActiveTab,
    buyOrderLoader: state.liveOrders.buyOrderLoader,
    sellOrderLoader: state.liveOrders.sellOrderLoader,
    getBuyOrdersList: state.liveOrders.getBuyOrdersList,
    getSellOrdersList: state.liveOrders.getSellOrdersList,
    profile: state.signin.profile,
    headerActiveMenu: state.app.headerActiveMenu,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    coinSymbolsObj: state.app.coinSymbolsObj,
    coinIdtoSymbolsObj: state.app.coinIdtoSymbolsObj,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    sidebar_main_height: state.dashboard.sidebar_main_height,
    openOrdersFirstTabDataLimit: state.dashboard.openOrdersFirstTabDataLimit
  }),
  mapDispatchToProps
)(LiveOrders);
