import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app/appReducer';
import signin from './signin/signinReducer';
import signup from './signup/signupReducer';
import kyc from './kyc/kycReducer';
import limitForms from './dashboard/components/limitForm/limitReducer';
import marketForms from './dashboard/components/marketForm/marketReducer';
import stopLimitForms from './dashboard/components/stopLimitForm/stopLimitReducer';
import allCoins from './dashboard/components/allCoins/allCoinsReducer';
import liveOrders from './dashboard/components/liveOrders/liveOrdersReducer';
import recentTrades from './dashboard/components/recentTrades/recentTradesReducer';
import myOrders from './dashboard/components/myOrders/myOrdersReducer';
import marketView from './marketView/marketViewReducer';
import transactions from './withdrawdeposit/withdrawDepositReducer';
import referral from './referral/referralReducer';
import dashboard from './dashboard/dashboardReducer';
import settings from './settings/settingsReducer';
import openOrders from './openOrders/openOrdersReducer';
// import tradeWithdrawals from './tradeHistory/tradeWithdrawReducer';
import portfolio from './portfolio/portFolioReducer';
import pagination from './paginationComponent/paginationReducer';
import jobPortal from 'Modules/landingPage/jobPortalReducer';
import updatePassword from './staticPages/updatePasswordReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    app,
    signin,
    signup,
    kyc,
    limitForms,
    marketForms,
    stopLimitForms,
    myOrders,
    marketView,
    transactions,
    referral,
    dashboard,
    settings,
    allCoins,
    liveOrders,
    recentTrades,
    openOrders,
    // tradeWithdrawals,
    portfolio,
    pagination,
    jobPortal,
    updatePassword
  });
