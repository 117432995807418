/**
 * Main DepositWithdraw Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class HowItWorks extends React.Component {
  render() {
    return (
      <div
        className="how-to-content content-cards mt-1"
        style={{ height: window.innerHeight - 140, overflowY: 'auto' }}
      >
        <h2 className="quote">
          “Loyalty is a two-way street. If we are asking it from you, then you
          are getting it from us”.
        </h2>
        <p>
          1. Get your 'Referral Code'- Sign up, get 'KYC verified' to get your
          Referral Code.
        </p>
        <p>
          2. Invite users- Share your referral code with friends and family.
        </p>
        <p>
          3. First transaction- When 'a user that used your referral
          code'(referred user) makes their first transaction, they get a 20%
          discount on their first transaction fee and you get 20% of the
          discounted transaction fee.
        </p>
        <p>
          4. Subsequent transactions- On every transaction made by a 'referred
          user', you get 20% of their transaction fee.
        </p>
        <p>
          5. Random referrals- If a user, while signing up, doesn't have a
          referral code, a referral code belonging to a random user will be
          generated.
        </p>
        <p>
          6. Build a portfolio- We pass on your share of the transaction fee in
          the form in which we collect it, enabling you to have a diverse
          portfolio.
        </p>

        <p>Read more to understand the 'The ThroughBit Referral Program:</p>

        <p>
          At ThroughBit, we take Loyalty very seriously. The ThroughBit Loyalty
          Program(TLP) has been designed to give our users a sense of belonging
          and ownership. It has been designed such that every time ThroughBit
          earns something, the user who was instrumental in making this earning
          happen will also stand to earn something. This is our way of giving
          back. This is our way of being loyal to you.
        </p>

        <p>
          In the following few lines, you will be understand how the program
          works.
        </p>

        <p>
          TLP is a simple referral module where users who wish to refer
          ThroughBit to any of their friends and family will be rewarded with a
          certain portion of the transaction fee that ThroughBit earns from the
          transactions that these referred users conduct.{' '}
        </p>

        <p>
          On signing up, completing KYC and getting the account verified, every
          user will be given a unique ‘Referral Code’, which the user can share
          to be used when a ‘referred’ user signs up on ThroughBit.
          Subsequently, on the first transaction the ‘referred’ user makes, the
          user gets a 20% discount on the transaction fee and the ‘referee’
          stands to make 20% of the ‘after discount’ transaction fee. On every
          subsequent transaction carried out by the ‘referred’ user the
          ‘referee’ stands to make 20% of the transaction fee. There are no
          limits on how many users can be referred by a single ‘referee’.
        </p>

        <p>
          There may be times when a user is signing up on ThroughBit, but
          doesn’t have a ‘Referral Code’ handy. To avoid the user missing out on
          the discount on the first transaction, we at ThroughBit, have
          developed a unique program, ‘The Handshake’.
        </p>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  state => ({}),
  mapDispatchToProps
)(HowItWorks);
