/**
 * Main App Component
 * Component
 */

import React from 'react';
import {
  Route,
  withRouter,
  // Link,
  // BrowserRouter as Router,
  Redirect,
  Switch
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';

import Header from 'App/header';
import Footer from 'App/footer';
import LandingPage from 'Modules/landingPage/App';
import Signin from 'Modules/signin/signin';
import Signup from 'Modules/signup/signup';
import Kyc from 'Modules/kyc/kyc';
import Dashboard from 'Modules/dashboard/dashboard';
import AllMarket from 'Modules/marketView/allMarket';
import Transaction from 'Modules/withdrawdeposit/transaction';
import Referral from 'Modules/referral/referral';
import Settings from 'Modules/settings/settings';
import NotFound from 'Modules/notFound/notFound';
import OpenOrders from 'Modules/openOrders/openOrders';
import History from 'Modules/openOrders/history';
import Portfolio from 'Modules/portfolio/portfolio';
import HighStockChart from 'Modules/dashboard/components/highStockChart/CandleStick/';
import WithdrawDeposit from 'Modules/withdrawdeposit/WithdrawDeposit';
import ConfirmEmail from 'Modules/staticPages/confirmEmail';
import UpdatePassword from 'Modules/staticPages/updatePassword';
import AlreadyVerified from 'Modules/staticPages/alreadyVerified';
import PasswordUpdated from 'Modules/staticPages/passwordUpdated';
import ExpireLink from 'Modules/staticPages/expireLink';
import Transfer from 'Modules/transfer';

import {
  allMarketCoinData,
  allCoinMarketData,
  allCoinsSymbolData,
  orderFeeFunc,
  allMarketCoin24HrPriceList
} from 'App/appReducer';
import { logout } from 'Modules/signin/signinReducer';

// import ScrollToTop from 'Modules/scrollToTop/scrollToTop';

const cookies = new Cookies();
let email = cookies.get('email');
let name = cookies.get('name');
let avatar = cookies.get('avatar');
let kyc_status = cookies.get('kyc');

/**
 * Represents a Root Componet.
 * @class
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: this.props.isLoggedIn,
      intervalId: 0,
      activeMarketSymbol: this.props.activeMarketSymbol,
      activeCurrencySymbol: this.props.activeCurrencySymbol
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn === true) {
      this.setState({ isLoggedIn: !!nextProps.kyc_status });
    }
    if (nextProps.profile !== undefined) {
      name = name || nextProps.profile.full_name;
      email = email || nextProps.profile.email;
      avatar = avatar || nextProps.profile.avatar;
      kyc_status = kyc_status || nextProps.profile.kyc_status;
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      cookies.set('activeMarketValue', nextProps.activeMarketValue);
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      cookies.set('activeCurrencyValue', nextProps.activeCurrencyValue);
    }

    if (this.props.activeMarketSymbol !== nextProps.activeMarketSymbol) {
      this.setState({ activeMarketSymbol: nextProps.activeMarketSymbol });
    }

    if (this.props.activeCurrencySymbol !== nextProps.activeCurrencySymbol) {
      this.setState({ activeCurrencySymbol: nextProps.activeCurrencySymbol });
    }
  }

  componentDidMount() {
    this.props.allMarketCoin24HrPriceList(); // Get All Market Coins Price List Object
    this.props.allMarketCoinData(); // Get All Market Coins List Object
    this.props.allCoinMarketData(); // Get All Coin Markets List Object
    this.props.allCoinsSymbolData(); // Get All Coins Symbol Object
    this.props.orderFeeFunc(); // Get order fee

    // Checks for JWT token expiry for every given time and if expired redirects to login page.
    // console.log('cookies.get(jwt) - ', cookies.get('jwt'));
    // console.log('this.props.isLoggedIn - ', this.props.isLoggedIn);
    this.setState({
      intervalId: setInterval(() => {
        cookies.get('jwt') === undefined &&
          this.props.isLoggedIn === true &&
          this.props.logout({ email });
      }, 5000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId); //Clear time interval
  }

  render() {
    return (
      // 	<ScrollToTop>
      <>
        <Switch>
          <Route exact path="/landingPage" component={LandingPage} />
          <Route exact path="/login" component={Signin} />
          <Route exact path="/signup/:referalCode?" component={Signup} />
          <Route exact path="/confirmemail" component={ConfirmEmail} />
          <Route exact path="/expirelink" component={ExpireLink} />
          <Route exact path="/updatepassword" component={UpdatePassword} />
          <Route exact path="/alreadyVerified" component={AlreadyVerified} />
          <Route exact path="/passwordUpdated" component={PasswordUpdated} />

          <Redirect
            exact
            from="/"
            to={this.state.isLoggedIn ? './markets' : './landingPage'}
          />

          <Route component={Header} />
        </Switch>

        <Switch>
          <Route
            sensitive
            path="/:market*-([a-z]):currency*/"
            render={props => (
              <Redirect to={`${props.location.pathname.toUpperCase()}`} />
            )}
          />
          <Route exact path="/:market-:currency" component={Dashboard} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/kyc" component={Kyc} />
          <Route exact path="/markets" component={AllMarket} />
          <Route exact path="/transfer" component={Transfer} />
          <Route exact path="/transaction" component={Transaction} />
          {/* <Route exact path="/stock" component={HighStockChart} /> */}

          <Route exact path="/referral" component={Referral} />
          <Route exact path="/settings" component={Settings} />

          <Route exact path="/openOrders" component={OpenOrders} />
          <Route exact path="/history" component={History} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/withdrawDeposit" component={WithdrawDeposit} />

          <Route exact path="/landingPage" />
          <Route exact path="/login" />
          <Route exact path="/signup/:referalCode?" />
          <Route exact path="/confirmemail" />
          <Route exact path="/expirelink" />
          <Route exact path="/updatepassword" />
          <Route exact path="/alreadyVerified" />
          <Route exact path="/passwordUpdated" />

          <Route component={NotFound} />
        </Switch>

        <Switch>
          <Route exact path="/landingPage" />
          <Route exact path="/login" />
          <Route exact path="/signup/:referalCode?" />
          <Route exact path="/confirmemail" />
          <Route exact path="/expirelink" />
          <Route exact path="/updatepassword" />
          <Route exact path="/alreadyVerified" />
          <Route exact path="/passwordUpdated" />

          <Route component={Footer} />
        </Switch>
      </>
      // 	</ScrollToTop>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      logout: formData => logout(formData),
      allMarketCoinData: formData => allMarketCoinData(formData),
      allCoinMarketData: formData => allCoinMarketData(formData),
      allCoinsSymbolData: formData => allCoinsSymbolData(formData),
      orderFeeFunc: formData => orderFeeFunc(formData),
      allMarketCoin24HrPriceList: formData =>
        allMarketCoin24HrPriceList(formData)
    },
    dispatch
  );

export default withRouter(
  connect(
    state => ({
      isLoggedIn: state.signin.isLoggedIn,
      kyc_status: state.signin.kyc_status,
      pathname: state.router.location.pathname,
      profile: state.signin.profile,
      activeMarketSymbol: state.app.activeMarketSymbol,
      activeCurrencySymbol: state.app.activeCurrencySymbol
    }),
    mapDispatchToProps
  )(App)
);
