/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import removeCookie from 'Utils/removeCookie';
import { getOrderDetails, updateFalcon } from '../../dashboardReducer';
import {
  getMyTradeMetricsFunc,
  getMyOrderHistory,
  getMyOpenOrders,
  myOrderHistoryBalanceFunc
} from './myOrdersReducer';

import { logout } from 'Modules/signin/signinReducer';
// import statusCheck from 'Utils/statusCheck';
import _ from 'underscore';
import tradeHistoryCalculateFunc from './tradeHistoryCalculateFunc';

const cookies = new Cookies();
let email = cookies.get('email');
let code_2fa = cookies.get('code_2fa');

class MyOrderHistoryBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: '',
      myOrderHistoryBalanceLoader: true,
      getMyOrderHistoryList: this.props.getMyOrderHistoryList,
      // myOrderHistoryBalanceCalcData: {
      //   totalBuy: '',
      //   avgBuyPrice: '',
      //   totalSell: '',
      //   avgSellPrice: '',
      //   breakEvenPrice: '',
      //   profitLoss: ''
      // },
      myOpenOrdersLoader: false,
      orderList: [],
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      myOrderHistoryResponse: this.props.myOrderHistoryResponse,
      myTradeMetricsLoader: this.props.myTradeMetricsLoader,
      getMyTradeMetricsList: this.props.getMyTradeMetricsList,
      market_information_height: this.props.market_information_height
    };
  }

  componentDidMount() {
    this.props.getMyTradeMetricsFunc({
      coinId: this.props.activeCurrencyValue,
      marketId: this.props.activeMarketValue
    });

    // if (
    //   !this.state.myOrderHistoryResponse &&
    //   Array.isArray(this.state.getMyOrderHistoryList)
    // ) {
    //   this.props.getMyOrderHistory();
    // } else {
    //   this.setState({
    //     myOrderHistoryBalanceCalcData: this.props.myOrderHistoryBalanceCalcData
    //   });
    // }
    // this.props.myOrderHistoryBalance({
    //   myOrderHistoryBalanceCalcData: this.props.myOrderHistoryBalanceCalcData,
    //   myOrderHistoryBalanceLoader: this.props.myOrderHistoryBalanceLoader
    // });
    // this.setState({
    //   myOrderHistoryBalanceCalcData: this.props.myOrderHistoryBalanceCalcData
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.market_information_height !==
      nextProps.market_information_height
    ) {
      this.setState({
        market_information_height: nextProps.market_information_height
      });
    }

    // Set Loader for Order History
    if (this.props.myOrderHistoryLoader !== nextProps.myOrderHistoryLoader) {
      this.setState({ myOrderHistoryLoader: nextProps.myOrderHistoryLoader });
    }

    if (this.props.myTradeMetricsLoader !== nextProps.myTradeMetricsLoader) {
      this.setState({ myTradeMetricsLoader: nextProps.myTradeMetricsLoader });
    }

    if (this.props.getMyTradeMetricsList !== nextProps.getMyTradeMetricsList) {
      if (
        nextProps.getMyTradeMetricsList.error === 0 &&
        nextProps.getMyTradeMetricsList.data.length > 0
      ) {
        this.setState({
          getMyTradeMetricsList: nextProps.getMyTradeMetricsList.data[0],
          error: null
        });
      } else if (nextProps.getMyTradeMetricsList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          getMyTradeMetricsList: null,
          error: nextProps.getMyTradeMetricsList.message
        });
      }
    }

    // if (this.props.getMyTradeMetricsList !== nextProps.getMyTradeMetricsList) {
    //   if (nextProps.getMyTradeMetricsList.success !== 0) {
    //     this.setState({
    //       error: '',
    //       getMyTradeMetricsList: nextProps.getMyTradeMetricsList
    //     });
    //   } else if (nextProps.getMyTradeMetricsList.success === 0) {
    //     this.setState({ error: nextProps.getMyTradeMetricsList.message });
    //   }
    // }

    if (
      this.props.myOrderHistoryResponse !== nextProps.myOrderHistoryResponse
    ) {
      this.setState({
        myOrderHistoryResponse: nextProps.myOrderHistoryResponse
      });
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      const activeMarketValue =
        nextProps.activeMarketValue || this.props.activeMarketValue;
      const activeCurrencyValue =
        nextProps.activeCurrencyValue || this.props.activeCurrencyValue;

      this.props.getMyTradeMetricsFunc({
        coinId: activeCurrencyValue,
        marketId: activeMarketValue
      });
    }

    if (this.props.getMyOrderHistoryList !== nextProps.getMyOrderHistoryList) {
      if (nextProps.getMyOrderHistoryList.error === 0) {
        if (nextProps.getMyOrderHistoryList.length !== 0) {
          this.myOrderHistoryCalculate(nextProps.getMyOrderHistoryList.data);
          this.setState({ orderList: nextProps.getMyOrderHistoryList.data });
        } else {
          this.setState({ orderList: nextProps.getMyOrderHistoryList.data });
        }
      } else if (nextProps.getMyOrderHistoryList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          getMyOrderHistoryList: null,
          error: nextProps.getMyOrderHistoryList.message
        });
      }
    }

    // Set Loader for Order History
    if (
      this.props.myOrderHistoryBalanceLoader !==
      nextProps.myOrderHistoryBalanceLoader
    ) {
      this.setState({
        myOrderHistoryBalanceLoader: nextProps.myOrderHistoryBalanceLoader
      });
    }
  }

  myOrderHistoryCalculate(data) {
    const myOrderHistoryBalanceCalcData = tradeHistoryCalculateFunc(data);

    this.setState({
      myOrderHistoryBalanceCalcData,
      myOrderHistoryBalanceLoader: false
    });

    this.props.myOrderHistoryBalanceFunc({
      myOrderHistoryBalanceCalcData,
      myOrderHistoryBalanceLoader: false
    });
  }

  render() {
    code_2fa =
      this.props.profile !== undefined ? this.props.profile.code_2f : code_2fa;
    const {
      error,
      myOrderHistoryResponse,
      myOrderHistoryBalanceLoader,
      getMyOrderHistoryList,
      myTradeMetricsLoader,
      getMyTradeMetricsList
    } = this.state;
    // const { myOrderHistoryBalanceLoader } = this.props;
    const { myOrderHistoryBalanceCalcData } = this.props;

    if (error === 'Unable to authenticate') {
      return <div />;
    }

    // if (getMyOrderHistoryList.length === 0 && myOrderHistoryResponse) {
    //   return <div>No Order History</div>;
    // }

    // if (myOrderHistoryResponse) {
    //   return <div>No Order History</div>;
    // }

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (myTradeMetricsLoader) {
      return <div className="loading-o" />;
    }

    if (!myTradeMetricsLoader) {
      const {
        totalbuys,
        averagebuyprice,
        totalsells,
        averagesellprice,
        breakevenprice,
        profitloss
      } = getMyTradeMetricsList;

      return (
        <table className="table info-table card-box">
          <tbody
            id="market-information"
            style={{ height: this.state.market_information_height }}
          >
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Total buy</td>
              <td className="col-md-7 text-right data">
                {totalbuys >= 0 && `${totalbuys.toFixed(8)} `}
                <span className="currency">
                  {this.props.activeCurrencySymbol}
                </span>
              </td>
            </tr>
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Total sell</td>
              <td className="col-md-7 text-right data">
                {totalsells >= 0 && `${totalsells.toFixed(8)} `}
                <span className="currency">
                  {this.props.activeCurrencySymbol}
                </span>
              </td>
            </tr>
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Avg buy price</td>
              <td className="col-md-7 text-right data">
                {averagebuyprice >= 0 && `${averagebuyprice.toFixed(8)} `}
                <span className="currency">
                  {this.props.activeMarketSymbol}
                </span>
              </td>
            </tr>
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Avg sell price</td>
              <td className="col-md-7 text-right data">
                {averagesellprice >= 0 && `${averagesellprice.toFixed(8)} `}
                <span className="currency">
                  {this.props.activeMarketSymbol}
                </span>
              </td>
            </tr>
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Break-even price</td>
              <td className="col-md-7 text-right data">
                {`${breakevenprice} `}
                <span className="currency">
                  {this.props.activeMarketSymbol}
                </span>
              </td>
            </tr>
            <tr className="row no-gutters">
              <td className="col-md-5 text-left title">Break-even price %</td>

              {profitloss ? (
                <td
                  className={`col-md-7 text-right data ${
                    profitloss.toString().includes('-')
                      ? 'negative-text'
                      : 'positive-text'
                  }`}
                >
                  {`${profitloss.toString().replace('-', '')} `}
                  <span
                    className={`currency ${
                      profitloss.toString().includes('-')
                        ? 'negative-text'
                        : 'positive-text'
                    }`}
                  >
                    {this.props.activeCurrencySymbol}
                  </span>
                </td>
              ) : (
                <td />
              )}
            </tr>
          </tbody>
        </table>
      );
    }
  }
}

MyOrderHistoryBalance.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1,
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderDetails: formData => getOrderDetails(formData),
      getMyTradeMetricsFunc: formData => getMyTradeMetricsFunc(formData),
      getMyOrderHistory: formData => getMyOrderHistory(formData),
      myOrderHistoryBalanceFunc: formData =>
        myOrderHistoryBalanceFunc(formData),
      getMyOpenOrders: formData => getMyOpenOrders(formData),
      changePage: page => push(page),
      updateFalcon: formData => updateFalcon(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    orderDetails: state.dashboard.orderDetails,
    myTradeMetricsLoader: state.myOrders.myTradeMetricsLoader,
    getMyTradeMetricsList: state.myOrders.getMyTradeMetricsList,
    myOpenOrdersLoader: state.myOrders.myOpenOrdersLoader,
    profile: state.signin.profile,
    getMyOrderHistoryList: state.myOrders.getMyOrderHistoryList,
    selectedMyOrderHistoryData: state.myOrders.selectedMyOrderHistoryData,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    getMyOpenOrdersList: state.myOrders.getMyOpenOrdersList,

    myOrderHistoryBalanceCalcData: state.myOrders.myOrderHistoryBalanceCalcData,
    myOrderHistoryBalanceLoader: state.myOrders.myOrderHistoryBalanceLoader,
    avgBuyPrice: state.myOrders.avgBuyPrice,
    totalSell: state.myOrders.totalSell,
    avgSellPrice: state.myOrders.avgSellPrice,
    breakEvenPrice: state.myOrders.breakEvenPrice,
    myOrderHistoryResponse: state.myOrders.myOrderHistoryResponse,
    // profitLoss: state.myOrders.profitLoss,
    market_information_height: state.dashboard.market_information_height
  }),
  mapDispatchToProps
)(MyOrderHistoryBalance);
