/**
 * Main Deposit Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
// import statusCheck from 'Utils/statusCheck';

import { getAddress, sendRequestForCoins } from './withdrawDepositReducer';
import {
  Button,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  // PopoverHeader,
  PopoverBody,
  UncontrolledTooltip,
  Tooltip
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cookies from 'universal-cookie';

// import { getMarketView } from './marketViewReducer';
const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const cookies = new Cookies();
let full_name = cookies.get('full_name');

class Deposit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketViewLoader: true,
      amount: '',
      getMarketViewList: {},
      searchData: {},
      marketCrptoArray: [],
      activeTab: 0,
      error: null,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      generatingAddress: false,
      addressResponseMessage: null,
      addressLoader: true,
      address: '',
      addressData: {},
      selectedCurrency: this.props.selectedCurrency,
      coinLoader: true,
      coinReqSuccess: '',
      modal: false,
      popoverOpen: false,
      email: '',
      copied: false,
      validEmail: false,
      notes: '',
      tooltipOpenReferraiCode2: false,
      copyStatus: 'Click to Copy'
    };
    this.getAddressFunc = this.getAddressFunc.bind(this);
    this.requestCoin = this.requestCoin.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.toggleTooltipReferraiCode2 = this.toggleTooltipReferraiCode2.bind(
      this
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleTooltipReferraiCode2() {
    this.setState({
      tooltipOpenReferraiCode2: !this.state.tooltipOpenReferraiCode2
    });
  }

  componentDidMount() {
    this.getAddressFunc();
  }

  callAgain() {
    setTimeout(this.getAddressFunc, 60000);
  }

  getAddressFunc() {
    const { selectedCurrency } = this.state;
    this.setState({
      generatingAddress: true
    });
    this.props.getAddress({ currencyId: selectedCurrency });
  }

  requestCoin() {
    const { selectedCurrency } = this.state;
    const { coinSymbolsObj } = this.props;

    this.props.sendRequestForCoins({
      email: this.state.email,
      full_name: full_name,
      amount: this.state.amount,
      currency: coinSymbolsObj[this.state.selectedCurrency].coinsymbol,
      remarks: this.state.notes,
      address: this.state.address
    });
    this.toggleModal();
  }

  validateEmail() {
    if (regexp.test(this.state.email)) {
      this.setState({
        validEmail: true
      });
    } else {
      this.setState({
        validEmail: false
      });
    }
  }
  handleAmountChange = e => {
    this.setState({ amount: e.target.value });
  };
  handleEmailChange = e => {
    this.setState({ email: e.target.value });
    this.validateEmail();
  };
  handleNotesChange = e => {
    this.setState({ notes: e.target.value });
  };
  toggleModal() {
    this.setState({
      modal: !this.state.modal,
      coinReqSuccess: ''
    });
  }
  togglePopOver() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.addressLoader !== nextProps.addressLoader) {
      this.setState({ addressLoader: nextProps.addressLoader });
    }

    if (this.props.selectedCurrency !== nextProps.selectedCurrency) {
      this.setState(
        {
          selectedCurrency: nextProps.selectedCurrency,
          generatingAddress: false,
          addressResponseMessage: null,
          address: '',
          amount: '',
          email: '',
          notes: '',
          copied: false,
          copyStatus: 'Click to Copy'
        },
        this.getAddressFunc
      );
    }

    if (
      nextProps.addressData !== undefined &&
      this.props.addressData !== nextProps.addressData
    ) {
      if (nextProps.addressData.message) {
        this.setState(
          {
            generatingAddress: false,
            addressResponseMessage: nextProps.addressData.message
          }
          // },
          // this.callAgain
        );
      } else {
        this.setState({
          generatingAddress: false,
          addressResponseMessage: null,
          address: nextProps.addressData.address
        });
      }
    }

    if (
      nextProps.coinreqResponse !== undefined &&
      this.props.coinreqResponse !== nextProps.coinreqResponse
    ) {
      if (nextProps.coinreqResponse.error == 0) {
        this.setState({
          coinLoader: false,
          coinReqSuccess: nextProps.coinreqResponse.message,
          email: '',
          amount: '',
          notes: ''
        });
      } else {
        this.setState({
          coinLoader: false,
          coinReqSuccess: nextProps.coinreqResponse.message
        });
      }
    }
  }

  render() {
    const {
      address,
      selectedCurrency,
      // coinLoader,
      coinReqSuccess
    } = this.state;
    const { coinSymbolsObj, style } = this.props;
    return (
      <div className="col-lg-3 mt-1">
        <div
          id="sidebar-main"
          style={{ height: window.innerHeight - 80 }}
          className="row no-gutters sidebar-content"
        >
          <div className="col-lg-12">
            <div className="currency-info" style={{ height: '210px' }}>
              <h1 className="title pt-2 pb-2">
                {selectedCurrency >= 0 &&
                  _.keys(coinSymbolsObj).length !== 0 &&
                  coinSymbolsObj[selectedCurrency].coinsymbol}{' '}
                {!_.keys(coinSymbolsObj).length !== 0 && ' '}
                {selectedCurrency >= 0 && _.keys(coinSymbolsObj).length !== 0 && (
                  <>
                    <span
                      className="i-info crsrPntr"
                      id="tooltip"
                      // data-toggle="tooltip"
                      // title={`Send only ${
                      //   coinSymbolsObj[selectedCurrency].coinsymbol
                      // } to this deposit address. Sending any other currency to this address may result in problems`}
                    >
                      i
                    </span>
                    <UncontrolledTooltip placement="bottom" target="tooltip">
                      {`Send only ${coinSymbolsObj[selectedCurrency].coinsymbol} to this deposit address. Sending any other currency to this address may result in problems`}
                    </UncontrolledTooltip>
                  </>
                )}
              </h1>

              {!this.state.generatingAddress &&
                !this.state.address &&
                !this.state.addressResponseMessage && (
                  <div className="generate-address mt-5 mb-5">
                    <a onClick={this.getAddressFunc} className="gen-address">
                      Generate address
                    </a>
                  </div>
                )}

              {this.state.generatingAddress &&
                !this.state.address &&
                !this.state.addressResponseMessage && (
                  <div className="generate-address mt-5 mb-5">
                    <div className="loading-o" />
                    {/* <a className="gen-address">Generating address</a> */}
                  </div>
                )}

              {this.state.addressResponseMessage && (
                <div className="generate-address mt-5 mb-5">
                  <a className="gen-address">{`${this.state.addressResponseMessage}`}</a>
                </div>
              )}

              {!this.state.generatingAddress && this.state.address && (
                <>
                  <div className="qr-code">
                    <img
                      src={`https://chart.googleapis.com/chart?chs=200x200&chld=M%7C0&cht=qr&chl=${address}`}
                    />
                  </div>
                  <h2 className="title">
                    Address{' '}
                    {coinSymbolsObj[selectedCurrency].coinsymbol === 'XRP' && (
                      <>
                        <span className="i-info crsrPntr" id="tooltip1">
                          i
                        </span>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="tooltip1"
                        >
                          {`Whatever appear before dt is your address, after that is your destination tag`}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </h2>
                  <CopyToClipboard
                    text={address}
                    onCopy={() =>
                      this.setState({
                        copied: true,
                        copyStatus: 'Copied.'
                      })
                    }
                  >
                    <div
                      className="user-address crsrPntr"
                      aria-hidden="true"
                      id="tooltipReferraiCode2"
                    >{`${address}  `}</div>
                  </CopyToClipboard>

                  <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpenReferraiCode2}
                    target="tooltipReferraiCode2"
                    autohide={false}
                    toggle={this.toggleTooltipReferraiCode2}
                  >
                    {this.state.copyStatus}
                  </Tooltip>
                </>
              )}
            </div>
            <div className="sidebar-form pl-3 pr-3">
              <div className="form-group">
                <label>Email ID</label>
                <input
                  type="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  autoComplete="none"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                {this.state.addressResponseMessage && (
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder={`${this.state.addressResponseMessage}`}
                    disabled
                  />
                )}

                {!this.state.addressResponseMessage &&
                  this.state.address === '' && (
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="Address"
                      disabled
                    />
                  )}

                {!this.state.generatingAddress && this.state.address && (
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    placeholder={`${this.state.address}`}
                    disabled
                  />
                )}
              </div>
              <div className="form-group">
                <label>Amount</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={this.handleAmountChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="note" className="h-25">
                  Remarks
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="1"
                  value={this.state.notes}
                  onChange={this.handleNotesChange}
                />
              </div>

              {/* {this.state.popoverOpen && !this.state.amount && (
                <div style={{ color: 'red' }}> Please enter amount. </div>
              )}
              {this.state.popoverOpen && !this.state.email && (
                <div style={{ color: 'red' }}> Please enter email. </div>
              )}
              {this.state.popoverOpen && !this.state.notes && (
                <div style={{ color: 'red' }}> Please enter remarks. </div>
              )} */}
            </div>
            <div>
              {(this.state.email === '' ||
                this.state.address === '' ||
                this.state.amount === '' ||
                this.state.notes === '' ||
                !this.state.validEmail) && (
                <div>
                  <button
                    className="btn btn-blue"
                    // style={{ background: '#6c757d' }}
                    id="Popover1"
                    onClick={this.togglePopOver}
                  >
                    Send A Request
                  </button>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target="Popover1"
                    toggle={this.togglePopOver}
                  >
                    <PopoverBody>
                      Please enter email ID, amount and remarks.
                    </PopoverBody>
                  </Popover>
                  {/* <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target="Popover1"
                    toggle={this.togglePopOver}
                  >
                    <PopoverBody>
                      Please enter Email, Amount and Address value.
                    </PopoverBody>
                  </Popover> */}
                </div>
              )}

              {this.state.validEmail &&
                this.state.email !== '' &&
                this.state.address !== '' &&
                this.state.notes !== '' &&
                this.state.amount !== '' && (
                  <button
                    type="submit"
                    className="btn btn-blue"
                    onClick={this.requestCoin}
                  >
                    Request to Deposit
                  </button>
                )}
              {coinReqSuccess !== '' && (
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggleModal}
                  className={this.props.className}
                  centered
                >
                  <ModalBody style={{ textAlign: 'center' }}>
                    {coinReqSuccess}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggleModal}>
                      Ok
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAddress: formData => getAddress(formData),
      sendRequestForCoins: formData => sendRequestForCoins(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    // marketViewLoader: state.marketView.marketViewLoader,
    // getMarketViewList: state.marketView.getMarketViewList,
    // searchValue: state.marketView.searchValue
    addressLoader: state.transactions.addressLoader,
    addressData: state.transactions.addressData,
    selectedCurrency: state.transactions.selectedCurrency,
    coinreqResponse: state.transactions.coinreqResponse
  }),
  mapDispatchToProps
)(Deposit);
