/**
 * Dashboard Reducer
 *Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerNew';

const BUY_MARKET_ORDER_FORM = 'BUY_MARKET_ORDER_FORM';
const BUY_MARKET_ORDER_RESPONSE = 'BUY_MARKET_ORDER_RESPONSE';
const HIDE_BUY_MARKET_MODAL = 'HIDE_BUY_MARKET_MODAL';

const SELL_MARKET_ORDER_FORM = 'SELL_MARKET_ORDER_FORM';
const SELL_MARKET_ORDER_RESPONSE = 'SELL_MARKET_ORDER_RESPONSE';
const HIDE_SELL_MARKET_MODAL = 'HIDE_SELL_MARKET_MODAL';

const initialState = {
  currencyBuy: 1,
  volumeMarketBuy: '',
  operationBuy: 1,
  marketBuy: 0,

  currencySell: 1,
  operationSell: 0,
  marketSell: 0,
  volumeMarketSell: '',

  // volume: '',
  // bid: '',
  // currency: 1,
  // operation: 1,

  buyFormModal: false,
  response: {},

  isGraphLoading: false,
  timePeriod: 0,
  orderDetails: {},
  orderHistoryLoader: false,
  buyFalcon: {},
  updateFalcon: {},
  sellFalcon: {},
  walletData: {},
  sendBTC: {},
  sendETH: {},
  sendLSK: {},
  sendINRF: {},
  buyTrade: {},
  sellTrade: {},
  mode: 'buy',
  addressGenerated: {},

  limitFormHeight: 0
};

/**
 * Reducer
 * @event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case BUY_MARKET_ORDER_FORM:
      return {
        ...state,
        currencyBuy: action.currencyBuy,
        operationBuy: action.operationBuy,
        marketBuy: action.marketBuy,
        volumeMarketBuy: action.volumeMarketBuy
      };
    case BUY_MARKET_ORDER_RESPONSE:
      return {
        ...state,
        buyResponse: action.response,
        buyFormModal: action.buyFormModal
      };
    case HIDE_BUY_MARKET_MODAL:
      return {
        ...state,
        buyFormModal: action.buyFormModal
      };

    case SELL_MARKET_ORDER_FORM:
      return {
        ...state,
        currencySell: action.currencySell,
        operationSell: action.operationSell,
        marketSell: action.marketSell,
        volumeMarketSell: action.volumeMarketSell
      };
    case SELL_MARKET_ORDER_RESPONSE:
      return {
        ...state,
        sellResponse: action.response,
        sellFormModal: action.sellFormModal
      };
    case HIDE_SELL_MARKET_MODAL:
      return {
        ...state,
        sellFormModal: action.sellFormModal
      };

    default:
      return state;
  }
};

export const buyMarketInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: BUY_MARKET_ORDER_FORM,
    volumeMarketBuy: formdata.volumeMarketBuy,
    currencyBuy: formdata.currencyBuy,
    operationBuy: formdata.operationBuy,
    marketBuy: formdata.marketBuy
  });
};

export const addBuyOrder = formdata => dispatch => {
  apiCaller('order/market/submit', 'post', formdata, false, true, false).then(
    response => {
      dispatch({
        type: BUY_MARKET_ORDER_RESPONSE,
        response,
        buyFormModal: true
      });
    }
  );
};

export const hideBuyModal = () => dispatch => {
  dispatch({
    type: HIDE_BUY_MARKET_MODAL,
    buyFormModal: false
  });
};

export const sellMarketInputRemotelyChange = formdata => dispatch => {
  dispatch({
    type: SELL_MARKET_ORDER_FORM,
    currencySell: formdata.currencySell,
    operationSell: formdata.operationSell,
    marketSell: formdata.marketSell,
    volumeMarketSell: formdata.volumeMarketSell
  });
};

export const addSellOrder = formdata => dispatch => {
  apiCaller('order/market/submit', 'post', formdata, false, true, false).then(
    response => {
      dispatch({
        type: SELL_MARKET_ORDER_RESPONSE,
        response,
        sellFormModal: true
      });
    }
  );
};

export const hideSellModal = () => dispatch => {
  dispatch({
    type: HIDE_SELL_MARKET_MODAL,
    sellFormModal: false
  });
};
