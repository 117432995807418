import React from 'react';
import { push } from 'connected-react-router';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import statusCheck from 'Utils/statusCheck';
import helpers from 'Utils/sortingHelper';
import Cookies from 'universal-cookie';
import { logout } from 'Modules/signin/signinReducer';
import moment from 'moment';

import removeCookie from 'Utils/removeCookie';
import { UncontrolledTooltip } from 'reactstrap';

import {
  activeTabUpdate,
  getOpenOrders,
  selectedOpenOrderIdFunc,
  openOrderAutosuggestCurrencyIdUpdate,
  setCount
} from './openOrdersReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';

const cookies = new Cookies();
let email = cookies.get('email');

class OrdersTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: '',
      openOrdersLoader: false,
      openOrdersListSort: [],
      error: '',
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      getRemoveOrderResponse: {},
      selectedOrderObj: {},
      modal: false,
      dummy: false,
      orderList: [],
      searchData: [],
      sortingCol: '',
      coinSymbolsObj: this.props.coinSymbolsObj,
      withdrawAutosuggestCurrencyId: this.props.withdrawAutosuggestCurrencyId,
      withdrawDepositInputText: this.props.withdrawDepositInputText,
      searchValue: this.props.searchValue
    };
    this.selectedOpenOrderFunc = this.selectedOpenOrderFunc.bind(this);
    this.loadChangePerc = this.loadChangePerc.bind(this);
  }

  componentDidMount() {
    this.props.getOpenOrders();
  }
  componentWillUnmount() {
    this.props.setCount({
      count: ''
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.openOrdersLoader !== nextProps.openOrdersLoader) {
      this.setState({ openOrdersLoader: nextProps.openOrdersLoader });
    }

    if (this.props.openOrdersList !== nextProps.openOrdersList) {
      if (
        nextProps.openOrdersList.error === 0 &&
        nextProps.openOrdersList.data.length != 0
      ) {
        this.setState(
          {
            orderList: nextProps.openOrdersList.data,
            searchData: nextProps.openOrdersList.data,
            openOrdersListSort: nextProps.openOrdersList.data,
            count: nextProps.openOrdersList.count,
            error: null
          },
          this.showDepositForm,
          this.showWithdrawForm
        );
        this.props.setCount({
          count: nextProps.openOrdersList.count
        });
      } else if (nextProps.openOrdersList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          orderList: [],
          searchData: [],
          openOrdersListSort: [],
          error: 'No open orders'
        });
      }
    }

    // if (
    //   this.props.withdrawAutosuggestCurrencyId !==
    //   nextProps.withdrawAutosuggestCurrencyId
    // ) {
    //   this.setState(
    //     {
    //       withdrawAutosuggestCurrencyId: nextProps.withdrawAutosuggestCurrencyId
    //     },
    //     this.handleSearchChange
    //   );
    // }

    // if (
    //   this.props.withdrawDepositInputText !== nextProps.withdrawDepositInputText
    // ) {
    //   this.setState(
    //     { withdrawDepositInputText: nextProps.withdrawDepositInputText },
    //     this.handleSearchChange
    //   );
    // }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
    if (this.props.searchValue != nextProps.searchValue) {
      this.setState({
        searchValue: nextProps.searchValue,
        searchData: this.searchInList(
          nextProps.searchValue,
          nextProps.openOrdersList.data
        )
      });
    }
  }
  searchInList(searchValue, data) {
    const {
      coinSymbolsObj,
      // hideZero,
      favSearchData,
      sortbyFavSearchData,
      // sortbyFav,
      searchData,
      walletData,
      noZeroBalData
    } = this.state;
    if (searchValue !== '') {
      var result = _.filter(searchData, o => {
        if (
          coinSymbolsObj[o.coinId].coinname
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1 ||
          coinSymbolsObj[o.coinId].coinsymbol
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) !== -1
        ) {
          return o;
        }
      });
      return result;
    } else {
      return data;
    }
  }
  handleSearchChange() {
    const {
      withdrawDepositInputText,
      orderList,
      withdrawAutosuggestCurrencyId
    } = this.state;
    if (Number.isInteger(withdrawAutosuggestCurrencyId)) {
      const searchData = _.filter(orderList, obj => {
        return (
          obj.coinId === withdrawAutosuggestCurrencyId ||
          obj.marketId === withdrawAutosuggestCurrencyId
        );
      });
      this.setState({ searchData });
    } else if (withdrawDepositInputText === '') {
      this.setState({
        searchData: orderList,
        coinSearchValue: withdrawDepositInputText,
        withdrawAutosuggestCurrencyId: null
      });
    } else {
      if (withdrawDepositInputText.indexOf('(') > -1) {
        const withdrawDepositInputText1 = withdrawDepositInputText
          .split('(')[0]
          .trim();
        this.setState(
          {
            coinSearchValue: withdrawDepositInputText1,
            withdrawAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      } else {
        this.setState(
          {
            coinSearchValue: withdrawDepositInputText,
            withdrawAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      }
    }
  }

  searchCoin() {
    const { coinSearchValue, orderList, coinSymbolsObj } = this.state;
    const searchData = _.filter(orderList, obj => {
      return (
        coinSymbolsObj[obj.coinId].coinname
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1 ||
        coinSymbolsObj[obj.coinId].coinsymbol
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1 ||
        coinSymbolsObj[obj.marketId].coinsymbol
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1 ||
        coinSymbolsObj[obj.marketId].coinsymbol
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1
      );
    });
    this.setState({ searchData });
  }

  selectedOpenOrderFunc(obj) {
    const { coinSymbolsObj } = this.state;
    const { orderId, marketId, coinId } = obj;
    const marketsymbol = coinSymbolsObj[marketId].coinsymbol;
    const coinsymbol = coinSymbolsObj[coinId].coinsymbol;
    this.props.selectedOpenOrderIdFunc({ selectedOrderId: orderId });
    this.props.headerActiveMenuUpdate('exchange');
    this.props.changePage(`/${marketsymbol}-${coinsymbol}`);
  }

  loadChangePerc(o) {
    if (o.changeAfterOrder != null && o.changeAfterOrder >= 0) {
      return '+' + o.changeAfterOrder + '%';
    } else if (o.changeAfterOrder < 0) {
      return o.changeAfterOrder + '%';
    } else {
      return '+0%';
    }
  }

  sortColumn(type, columnName) {
    var { sortingCol, searchData, sortType } = this.state;
    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    const orderListSort = helpers.sortColumn(
      searchData,
      type,
      sortingCol,
      sortType
    );
    this.setState({
      sortingCol,
      sortType,
      openOrdersListSort: orderListSort,
      searchData: orderListSort
    });
  }

  render() {
    const {
      error,
      openOrdersLoader,
      orderList,
      openOrdersListSort,
      searchData,
      sortingCol,
      sortType,
      coinSymbolsObj
    } = this.state;

    if (error) {
      return (
        <div className="container-fluid card-box open-orders mt-1">
          <div style={{ height: window.innerHeight - 180 }}>
            {error.toString()}
          </div>
        </div>
      );
    }

    if (openOrdersLoader) {
      return (
        <div style={{ height: window.innerHeight - 180 }}>
          <div className="loading-o" />
        </div>
      );
    }
    return (
      <div className="container-fluid card-box open-orders mt-1">
        {searchData && searchData.length !== 0 ? (
          <table className="table data-table table-fixed mb-0">
            <thead>
              <tr>
                <th scope="col" className="faded">
                  Pair
                </th>
                <th scope="col" className="faded" style={{ width: '5%' }}>
                  Type
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'price')}
                  style={{ width: '8%' }}
                >
                  Price{' '}
                  {sortingCol === 'price' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'volume')}
                >
                  Amount{' '}
                  {sortingCol === 'volume' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'total')}
                >
                  Total{' '}
                  {sortingCol === 'total' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('date', 'datetime')}
                  style={{ width: '14.27%' }}
                >
                  Order placed on{' '}
                  {sortingCol === 'datetime' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'priceWhenPlaced')}
                >
                  Price when user placed{' '}
                  {sortingCol === 'priceWhenPlaced' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'currentPrice')}
                  style={{ width: '8%' }}
                >
                  Price now{' '}
                  {sortingCol === 'currentPrice' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'changeAfterOrder')}
                >
                  Change since order placed{' '}
                  {sortingCol === 'changeAfterOrder' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() =>
                    this.sortColumn('number', 'changeTillExecution')
                  }
                >
                  Change till order execution{' '}
                  {sortingCol === 'changeTillExecution' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th scope="col" className="faded" />
              </tr>
            </thead>
            <tbody style={{ height: window.innerHeight - 180 }}>
              {searchData.map((o, i) => (
                <tr key={i}>
                  {coinSymbolsObj && (
                    <td>{`${coinSymbolsObj[o.coinId].coinsymbol}/${
                      coinSymbolsObj[o.marketId].coinsymbol
                    }`}</td>
                  )}
                  {/* {!this.props.marketsCoinsListObj && (
                    <td>{`${o.marketId}/${o.coinId}`}</td>
                  )} */}
                  <td
                    className={o.operation ? 'positive-text' : 'negative-text'}
                    style={{ width: '5%' }}
                  >
                    {statusCheck.buySellStatusCheck(o.operation)}
                  </td>

                  {/* <td>{`${this.props.marketsCoinsListObj[o.market].marketdata[0].marketsymbol}/${this.props.marketsCoinsListObj[o.market].coins[o.currency].coinsymbol}`}</td> */}
                  {/* <td>{`${o.market}/${o.currency}`}</td> */}
                  <td style={{ width: '8%' }}>
                    {o.price.toFixed(8)}{' '}
                    {/* <span className="currency">BTC</span> */}
                  </td>
                  <td>{o.volume.toFixed(8)}</td>
                  <td>{o.total.toFixed(8)}</td>
                  {/* <td>{o.datetime}</td> */}
                  <td style={{ width: '14.27%' }}>{`${moment(o.datetime)
                    .utc()
                    .format('HH:mm:ss')} GMT ${moment(o.datetime)
                    .utc()
                    .format('DD/MM/YYYY')}`}</td>
                  {/* <td /> */}
                  <td>{o.priceWhenPlaced.toFixed(8)}</td>
                  <td>{o.currentPrice.toFixed(8)}</td>
                  <td
                    className={
                      o.changeAfterOrder >= 0
                        ? 'positive-text'
                        : 'negative-text'
                    }
                  >
                    {this.loadChangePerc(o)}
                    {/* {o.changeAfterOrder !== null && o.changeAfterOrder >= 0
                        ? '+'
                        : '-'}
                      {o.changeAfterOrder} */}
                  </td>
                  <td
                    className={
                      o.changeTillExecution >= 0
                        ? 'positive-text'
                        : 'negative-text'
                    }
                  >
                    {o.changeTillExecution != null
                      ? o.changeTillExecution + '%'
                      : '0'}
                  </td>
                  <td>
                    {coinSymbolsObj &&
                      (coinSymbolsObj[o.coinId].coinstatus === 1 ? (
                        <div
                          className="button-cancel"
                          onClick={() => this.selectedOpenOrderFunc(o)}
                        >
                          Go to order
                        </div>
                      ) : (
                        <>
                          <div className="button-disable" id={`tooltip-${i}`}>
                            Go to order
                          </div>
                          <UncontrolledTooltip
                            placement="top"
                            target={`tooltip-${i}`}
                            delay={{ hide: -1 }}
                          >
                            Temporary disabled. for more details, Please contact
                            to support@throughbit.com
                          </UncontrolledTooltip>
                        </>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="noBal" style={{ height: window.innerHeight - 180 }}>
            No Open Orders
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      getOpenOrders: formData => getOpenOrders(formData),
      selectedOpenOrderIdFunc: formData => selectedOpenOrderIdFunc(formData),
      openOrderAutosuggestCurrencyIdUpdate: formData =>
        openOrderAutosuggestCurrencyIdUpdate(formData),
      logout: formData => logout(formData),
      setCount: formData => setCount(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    openOrdersLoader: state.openOrders.openOrdersLoader,
    openOrdersList: state.openOrders.openOrdersList,
    coinSymbolsObj: state.app.coinSymbolsObj,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    withdrawDepositInputText: state.transactions.withdrawDepositInputText,
    withdrawAutosuggestCurrencyId:
      state.transactions.withdrawAutosuggestCurrencyId,
    searchValue: state.openOrders.searchValue
  }),
  mapDispatchToProps
)(OrdersTab);
