/**
 * Main Transaction Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import removeCookie from 'Utils/removeCookie';
import { logout } from 'Modules/signin/signinReducer';

import { activeTabUpdate } from './referralReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';

import ReferralBonus from './refBonus';
import HowItWorks from './howItWorks';

import { getReferralHistory } from './referralReducer';
import _ from 'underscore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReferralSideBar from './referalSideBar';
import ErrorBoundary from 'Modules/ErrorBoundary';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share';
// import './Demo.css';

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let email = cookies.get('email');

class Referral extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleTooltipReferraiCode1 = this.toggleTooltipReferraiCode1.bind(
      this
    );
    this.toggleTooltipReferraiCode2 = this.toggleTooltipReferraiCode2.bind(
      this
    );
    this.toggleTooltipFacebookShare = this.toggleTooltipFacebookShare.bind(
      this
    );
    this.toggleTooltipTwitterShare = this.toggleTooltipTwitterShare.bind(this);

    this.handleChange = this.handleChange.bind(this);
    // this.checkConditions = this.checkConditions.bind(this);
    this.getHighestAsset = this.getHighestAsset.bind(this);
    this.state = {
      depositFormView: false,
      withdrawFormView: false,
      referralHistoryLoader: true,
      getReferralHistoryList: [],
      sidebar_main_height: window.innerHeight - 60,
      searchData: [],
      coinSymbolsObj: {},
      error: null,
      message: '',
      refCode: '',
      refEarnings: [],
      copied: false,
      loadInitial: true,
      dataObject: {},
      tooltipOpenReferraiCode1: false,
      tooltipOpenReferraiCode2: false,
      tooltipOpenFacebookShare: false,
      tooltipOpenTwitterShare: false,
      tooltip1Text: 'Click to Copy',
      tooltip2Text: 'Click to Copy'
    };
  }

  toggleTooltipReferraiCode1() {
    this.setState({
      tooltipOpenReferraiCode1: !this.state.tooltipOpenReferraiCode1
    });
  }

  toggleTooltipReferraiCode2() {
    this.setState({
      tooltipOpenReferraiCode2: !this.state.tooltipOpenReferraiCode2
    });
  }

  toggleTooltipFacebookShare() {
    this.setState({
      tooltipOpenFacebookShare: !this.state.tooltipOpenFacebookShare
    });
  }

  toggleTooltipTwitterShare() {
    this.setState({
      tooltipOpenTwitterShare: !this.state.tooltipOpenTwitterShare
    });
  }

  checkConditions() {
    const { getReferralHistoryList } = this.state;
    if (
      getReferralHistoryList.code === -1 ||
      getReferralHistoryList.error === 1
    ) {
      this.setState({
        showMessage: true,
        message: getReferralHistoryList.message
      });
    } else if (getReferralHistoryList.code === 1) {
      this.setState({
        refCode: getReferralHistoryList.data.referalId,
        getReferralHistoryList: getReferralHistoryList.data.balances,
        searchData: getReferralHistoryList.data.balances,
        refEarnings: getReferralHistoryList.referals,
        dataObject: getReferralHistoryList.data
      });
    } else {
      this.setState({
        showMessage: true,
        message: getReferralHistoryList.message,
        searchData: [],
        refCode: getReferralHistoryList.data.referalId,
        dataObject: getReferralHistoryList.data
      });
    }
  }
  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('referral');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    } else {
      this.props.getReferralHistory();
    }
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }
  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (this.props.referralHistoryLoader !== nextProps.referralHistoryLoader) {
      this.setState({ referralHistoryLoader: nextProps.referralHistoryLoader });
    }

    //Handle The Response IF Recieved Transaction Data
    if (
      nextProps.getReferralHistoryList &&
      nextProps.getReferralHistoryList !== undefined &&
      this.props.getReferralHistoryList !== nextProps.getReferralHistoryList
    ) {
      if (nextProps.getReferralHistoryList.error == 0) {
        if (nextProps.getReferralHistoryList.data.balances.length != 0) {
          this.setState({
            refCode: nextProps.getReferralHistoryList.data.referalId,
            getReferralHistoryList:
              nextProps.getReferralHistoryList.data.balances,
            searchData: nextProps.getReferralHistoryList.data.balances,
            refEarnings: nextProps.getReferralHistoryList.referals,
            dataObject: nextProps.getReferralHistoryList.data
          });
        } else {
          this.setState({
            showMessage: true,
            message: nextProps.getReferralHistoryList.message,
            searchData: [],
            refCode: nextProps.getReferralHistoryList.data.referalId,
            dataObject: nextProps.getReferralHistoryList.data
          });
        }
      } else if (nextProps.getReferralHistoryList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          showMessage: true,
          message: nextProps.getReferralHistoryList.message,
          searchData: [],
          refCode: ''
        });
      }
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }

    if (this.props.loadInitial !== nextProps.loadInitial) {
      this.setState({ loadInitial: nextProps.loadInitial });
    }
  }
  handleChange(event) {
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  getHighestAsset() {
    var max =
      this.state.searchData.length != 0
        ? _.max(this.state.searchData, function(o) {
            return o.amountRandom + o.amountByReferal;
          })
        : this.state.message;
    if (
      _.keys(this.state.coinSymbolsObj).length > 0 &&
      typeof max === 'object'
    ) {
      var maxAsset = this.state.coinSymbolsObj[max.coinId].coinsymbol;
    }
    return typeof max === 'object' ? maxAsset : max;
  }

  render() {
    const type = 'referral';
    const {
      error,
      referralHistoryLoader,
      searchData,
      referralError,
      getReferralHistoryList,
      coinSymbolsObj,
      message,
      refCode,
      refEarnings,
      loadInitial,
      dataObject
    } = this.state;
    const loc = window.location.href;
    var pos = loc.replace('/referral', '');

    const hash = loc.substring(pos + 1 + 5);
    const shareUrl = pos + '/signup/' + `${this.state.refCode}`;
    // const title = 'Throughbit';
    const title = `Use my code ‘${refCode}’ while signing up on ThroughBit and get 20% off on your first transaction fee.`;

    return (
      <div className="row no-gutters">
        <div className="col-lg-9 container-one pl-2 pr-1 mt-1">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === '1' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('1');
                }}
                active={(this.props.activeTab === '1').toString()}
                aria-selected={this.props.activeTab === '1'}
              >
                Referral Bonus
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.activeTab === '3' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('3');
                }}
                active={(this.props.activeTab === '3').toString()}
                aria-selected={this.props.activeTab === '3'}
              >
                How it works?
              </a>
            </li>
          </ul>
          {this.props.activeTab === '1' && (
            <ErrorBoundary>
              <ReferralBonus />
            </ErrorBoundary>
          )}

          {this.props.activeTab === '3' && (
            <ErrorBoundary>
              <HowItWorks />
            </ErrorBoundary>
          )}
        </div>

        <div className="col-lg-3 mt-1">
          {loadInitial ? (
            <div
              id="sidebar-main"
              className="row no-gutters sidebar-content"
              style={{ height: this.state.sidebar_main_height - 20 }}
            >
              <div className="col-lg-12" style={{ overflowX: 'hidden' }}>
                <div className="currency-info">
                  <h1 className="title pt-2 pb-2">Referral</h1>
                  <div className="image-wrapper mt-5 mb-5">
                    <img src="../assets/images/shield.svg" height="100px" />
                  </div>
                  <div className="rank mb-5">
                    <h2 className="title">
                      Probability{' '}
                      <span
                        className="i-info crsrPntr"
                        id="tooltip"
                        // data-toggle="tooltip"
                        // title={`Send only ${
                        //   coinSymbolsObj[selectedCurrency].coinsymbol
                        // } to this deposit address. Sending any other currency to this address may result in problems`}
                      >
                        i
                      </span>
                      <UncontrolledTooltip placement="bottom" target="tooltip">
                        The likelihood of your code being chosen at random.
                      </UncontrolledTooltip>
                    </h2>
                    <div className="numbers">
                      <span className="coin-name" />
                      {dataObject.probablityNumber}
                    </div>
                  </div>
                  <div className="sidebar-form">
                    <div className="row mt-2 pl-3 pr-3">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-12">
                            <label>All referrals</label>
                          </div>
                          <div className="col-md-12">
                            <span className="btc-text">
                              {refEarnings.length != 0 ? (
                                refEarnings.reduce(function(sum, o) {
                                  return sum + o.referalCount;
                                }, 0)
                              ) : (
                                <div>0</div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="row">
                          <div className="col-md-12">
                            <label>All earnings</label>
                          </div>
                          <div className="col-md-12">
                            {`${parseFloat(
                              dataObject.totalEarningsInBTC
                            ).toFixed(8)} `}
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 pl-3 pr-3">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Direct referral</label>
                          </div>
                          <div className="col-md-12">
                            {refEarnings.length != 0 ? (
                              refEarnings.map((o, i) => (
                                <div key={i}>
                                  {o.referalType == 1 ? (
                                    o.referalCount
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              ))
                            ) : (
                              <div>0</div>
                            )}{' '}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Earnings</label>
                          </div>
                          <div className="col-md-12">
                            {`${parseFloat(
                              dataObject.directEarningsInBTC
                            ).toFixed(8)} `}
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 pl-3 pr-3">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Random referrals</label>
                          </div>
                          <div className="col-md-12">
                            {refEarnings.length != 0 ? (
                              refEarnings.map((o, i) => (
                                <div key={i}>
                                  {o.referalType == 2 ? (
                                    o.referalCount
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              ))
                            ) : (
                              <div>0</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="row">
                          <div className="col-md-12">
                            <label>Earnings</label>
                          </div>
                          <div className="col-md-12">
                            {`${parseFloat(
                              dataObject.randomEarningsInBTC
                            ).toFixed(8)} `}
                            <span className="currency">BTC</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 pl-3 pr-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Referral code</label>

                          {/* <div className="col-md-12">
                            <label>{`${this.state.refCode}`}</label>
                          </div> */}
                          <p>
                            <CopyToClipboard
                              text={this.state.refCode}
                              onCopy={() =>
                                this.setState({
                                  copied: true,
                                  tooltip1Text: 'Copied.'
                                })
                              }
                            >
                              <span
                                style={{
                                  cursor: 'pointer',
                                  borderColor: 'rgba( 102, 123, 139, 1)'
                                }}
                                id="tooltipReferraiCode1"
                              >
                                {`${this.state.refCode}`}
                              </span>
                            </CopyToClipboard>
                          </p>

                          <Tooltip
                            placement="top"
                            isOpen={this.state.tooltipOpenReferraiCode1}
                            target="tooltipReferraiCode1"
                            autohide={false}
                            toggle={this.toggleTooltipReferraiCode1}
                          >
                            {this.state.tooltip1Text}
                          </Tooltip>
                          <ul className="social-icons">
                            <li>
                              <CopyToClipboard
                                text={this.state.refCode}
                                onCopy={() =>
                                  this.setState({
                                    copied: true,
                                    tooltip2Text: 'Copied.'
                                  })
                                }
                              >
                                <i
                                  className="fa fa-clipboard crsrPntr"
                                  aria-hidden="true"
                                  id="tooltipReferraiCode2"
                                />
                              </CopyToClipboard>

                              <Tooltip
                                placement="top"
                                isOpen={this.state.tooltipOpenReferraiCode2}
                                target="tooltipReferraiCode2"
                                autohide={false}
                                toggle={this.toggleTooltipReferraiCode2}
                              >
                                {this.state.tooltip2Text}
                              </Tooltip>
                            </li>
                            <li>
                              <div className="Demo__some-network">
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={title}
                                  className="Demo__some-network__share-button"
                                >
                                  <i
                                    className="fa fa-facebook-f crsrPntr"
                                    aria-hidden="true"
                                    id="tooltipFacebookShare"
                                  />
                                </FacebookShareButton>

                                <Tooltip
                                  placement="top"
                                  isOpen={this.state.tooltipOpenFacebookShare}
                                  target="tooltipFacebookShare"
                                  autohide={false}
                                  toggle={this.toggleTooltipFacebookShare}
                                >
                                  Share
                                </Tooltip>
                              </div>
                            </li>
                            <li>
                              <div className="Demo__some-network">
                                <TwitterShareButton
                                  url={shareUrl}
                                  title={title}
                                  className="Demo__some-network__share-button"
                                >
                                  <i
                                    className="fa fa-twitter crsrPntr"
                                    aria-hidden="true"
                                    id="tooltipTwitterShare"
                                  />
                                </TwitterShareButton>

                                <Tooltip
                                  placement="top"
                                  isOpen={this.state.tooltipOpenTwitterShare}
                                  target="tooltipTwitterShare"
                                  autohide={false}
                                  toggle={this.toggleTooltipTwitterShare}
                                >
                                  Share
                                </Tooltip>
                              </div>
                            </li>

                            {/* <li>
                              <div className="Demo__some-network">
                                <LinkedinShareButton
                                  url={shareUrl}
                                  windowWidth={750}
                                  windowHeight={600}
                                  className="Demo__some-network__share-button"
                                >
                                  <i
                                    className="fa fa-linkedin-square crsrPntr"
                                    aria-hidden="true"
                                  />
                                </LinkedinShareButton>
                              </div>
														</li> */}
                          </ul>
                        </div>
                      </div>

                      {/* <CopyToClipboard
                        text={this.state.refCode}
                        onCopy={() => this.setState({ copied: true })}
                      >
                        <button type="submit" className="btn btn-blue">
                          Share
                        </button>
                      </CopyToClipboard> */}
                      {/* {this.state.copied ? (
                        <span style={{ color: 'red' }}>Copied.</span>
                      ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ErrorBoundary>
              <ReferralSideBar type={type} refCode={this.state.refCode} />
            </ErrorBoundary>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      // searchValueUpdate: formData => searchValueUpdate(formData),
      getReferralHistory: formData => getReferralHistory(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      logout: formData => logout(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    activeTab: state.referral.activeTab,
    referralHistoryLoader: state.referral.referralHistoryLoader,
    getReferralHistoryList: state.referral.getReferralHistoryList,
    dummy: state.referral.dummy,
    coinSymbolsObj: state.app.coinSymbolsObj,
    isLoggedIn: state.signin.isLoggedIn,
    loadInitial: state.referral.loadInitial
  }),
  mapDispatchToProps
)(Referral);
