/**
 * Main MarketView Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import statusCheck from 'Utils/statusCheck';
import selectFavCurrency from 'Utils/favouriteCoins';
import SVG from 'react-inlinesvg';
import Autosuggest from './mvAutoSuggest';
import helpers from 'Utils/sortingHelper';

import removeCookie from 'Utils/removeCookie';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from 'reactstrap';

import {
  getMarketView,
  showCoinInfoUpdate,
  allMarketCoin24HrPriceList,
  marketActiveTabFunc,
  coinForCoinInfoFunc,
  setActiveTab,
  coinimageForCoinInfoFunc,
  marketViewAutosuggestCurrencyIdUpdate,
  hideZeroBalances,
  sortByFavouritesUpdate,
  searchValueUpdate
} from './marketViewReducer';
import {
  headerActiveMenuUpdate,
  marketCurrencyUpdate
} from 'App/appReducer';
import {
  buyLimitInputRemotelyChange,
  sellLimitInputRemotelyChange
} from 'Modules/dashboard/components/limitForm/limitReducer';
import {
  buyMarketInputRemotelyChange,
  sellMarketInputRemotelyChange
} from 'Modules/dashboard/components/marketForm/marketReducer';
import {
  buyStopLimitInputRemotelyChange,
  sellStopLimitInputRemotelyChange
} from 'Modules/dashboard/components/stopLimitForm/stopLimitReducer';

class MarketView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketViewLoader: true,
      getMarketViewList: {},
      //  marketsCoinsListObj: this.props.marketsCoinsListObj,
      marketCoin24HrPriceListObj: this.props.marketCoin24HrPriceListObj,
      searchData: this.props.marketsCoinsListObj,
      marketCrptoArray: [],
      activeTab: this.props.activeTab,
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      coinSymbolsObj: {},
      txnid: '',
      code: '',
      currency: 'BTC',
      getMyBalanceList: this.props.getMyBalanceList,
      marketActiveTab: this.props.marketActiveTab,
      coinForCoinInfo: this.props.coinForCoinInfo,
      coinimageForCoinInfo: this.props.coinimageForCoinInfo,
      reactSelectOptions: this.props.reactSelectOptions,
      marketViewAutosuggestCurrencyId: this.props
        .marketViewAutosuggestCurrencyId,
      autoSuggestForMarketsCoinSymbol: this.props
        .autoSuggestForMarketsCoinSymbol,
      favCoinArray:
        (localStorage.getItem('favCoinArray') &&
          JSON.parse(localStorage.getItem('favCoinArray'))) ||
        [],
      marketViewInputText: this.props.marketViewInputText,
      hideZero: this.props.hideZero,
      dropdownOpen: false,
      searchValue: '',
      sortbyFav: this.props.sortbyFav,
      favCoins: {},
      sortingCol: ''
    };
    this.sortFav = this.sortFav.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.marketsToggle = this.marketsToggle.bind(this);
    this.toggleHeaderActiveMenu = this.toggleHeaderActiveMenu.bind(this);
    this.hideZeroFunc = this.hideZeroFunc.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadCurrencyInfoOnPageChange = this.loadCurrencyInfoOnPageChange.bind(
      this
    );
    this.loadCurrencyInfo = this.loadCurrencyInfo.bind(this);
  }

  handleChange(event) {
    this.setState({
      searchValue: event.target.value
    });
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  getFavs(data) {
    const { marketsCoinsListObj } = this.state;

    const favFromDataObject = {};
    const finalDataObject = {};
    const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
    _.map(_.keys(data), marketKey => {
      favFromDataObject[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata
      };
      _.map(favArray, o => {
        _.filter(_.keys(data[marketKey].coins), coinId => {
          if (data[marketKey]['coins'][coinId].coinId === o) {
            favFromDataObject[marketKey]['coins'][coinId] =
              data[marketKey]['coins'][coinId];
          }
        });
      });
    });

    this.setState({
      favSearchData: favFromDataObject
    });
    const favCoins = {};
    _.map(_.keys(favFromDataObject), marketKey => {
      favCoins[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata
      };
      return _.map(_.keys(favFromDataObject[marketKey].coins), coinId => {
        return (favCoins[marketKey]['coins'][coinId] =
          favFromDataObject[marketKey]['coins'][coinId]);
      });
    });
    const dataCoins = {};
    _.map(_.keys(data), marketKey => {
      dataCoins[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata
      };
      return _.map(_.keys(data[marketKey].coins), coinId => {
        return (dataCoins[marketKey]['coins'][coinId] =
          data[marketKey]['coins'][coinId]);
      });
    });
  }
  getFavCoinsFirst(data) {
    const { marketsCoinsListObj } = this.state;

    const favFromDataObject = {};
    const finalDataObject = {};
    const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
    _.map(_.keys(data), marketKey => {
      favFromDataObject[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata,
        coinsArray: []
      };
      _.map(favArray, coinId => {
        _.filter(data[marketKey].coinsArray, o => {
          if (o.coinId === coinId) {
            favFromDataObject[marketKey]['coinsArray'].push(o);
          }
        });
      });
    });

    this.setState({
      favSearchData: favFromDataObject,
      data: data
    });
    const favCoins = {};
    _.map(_.keys(favFromDataObject), marketKey => {
      favCoins[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata,
        coinsArray: []
      };
      return _.map(favFromDataObject[marketKey].coinsArray, o => {
        return favCoins[marketKey]['coinsArray'].push(o);
      });
    });

    const difference = [];
    var favCoinKeys = [];
    var dataCoiKeys = [];

    _.map(_.keys(favCoins), marketKey => {
      _.map(favCoins[marketKey]['coinsArray'], o => {
        favCoinKeys.push(o.coinId);
      });
    });
    _.map(_.keys(data), marketKey => {
      _.map(data[marketKey]['coinsArray'], o => {
        dataCoiKeys.push(o.coinId);
      });
    });
    var favResult = favCoinKeys.map(function(x) {
      return parseInt(x);
    });
    var dataResult = dataCoiKeys.map(function(x) {
      return parseInt(x);
    });
    var diff;

    if (favResult.length > dataResult.length) {
      diff = _.difference(_.uniq(favResult), _.uniq(dataResult));
    } else {
      diff = _.difference(_.uniq(dataResult), _.uniq(favResult));
    }

    const differencCoins = {};
    _.map(_.keys(marketsCoinsListObj), marketKey => {
      differencCoins[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata,
        coinsArray: []
      };
      _.map(marketsCoinsListObj[marketKey]['coinsArray'], o => {
        _.map(diff, cId => {
          if (cId == o.coinId) {
            differencCoins[marketKey]['coinsArray'].push(o);
          }
        });
      });
    });
    //const mergedArray = [ ...array1, ...array2 ]
    const finalObject = {};
    _.map(_.keys(marketsCoinsListObj), marketKey => {
      finalObject[marketKey] = {
        coins: {},
        marketdata: marketsCoinsListObj[marketKey].marketdata,
        coinsArray: [
          ...favCoins[marketKey]['coinsArray'],
          ...differencCoins[marketKey]['coinsArray']
        ]
      };
    });

    this.setState({
      favCoins: favCoins,
      differencCoins: differencCoins,
      searchData: finalObject
    });

    return finalObject;
  }

  searchInList(hideZero, searchValue, sortbyFav, data) {
    const {
      favSearchData,
      sortbyFavSearchData,
      // sortbyFav,
      searchData,
      walletData,
      noZeroBalData,
      initData,
      getMyBalanceList,
      marketsCoinsListObj,
      activeTab
    } = this.state;
    const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
    const searchDataObject = {};

    if (searchValue !== '') {
      if (hideZero) {
        if (sortbyFav) {
          //searchValue not empty, hidezero=true, sortByFav =true
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                (o.coinname.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1 ||
                  o.coinsymbol
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1) &&
                o.coinId in getMyBalanceList &&
                'amount' in getMyBalanceList[o.coinId] &&
                Number(
                  parseFloat(getMyBalanceList[o.coinId].amount).toFixed(8)
                ) !== 0
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
              }
            });
          });
          var result = this.getFavCoinsFirst(searchDataObject);
          this.loadCurrencyInfo();
          return result;
        } else {
          //searchValue not empty, hidezero=true, sortByFav =false
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                (o.coinname.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1 ||
                  o.coinsymbol
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) > -1) &&
                o.coinId in getMyBalanceList &&
                'amount' in getMyBalanceList[o.coinId] &&
                Number(
                  parseFloat(getMyBalanceList[o.coinId].amount).toFixed(8)
                ) !== 0
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
              }
            });
          });
          return searchDataObject;
        }
      } else {
        if (sortbyFav) {
          //searchValue not empty, hidezero=false, sortByFav =true
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                o.coinname.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1 ||
                o.coinsymbol.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
              }
            });
          });
          var result = this.getFavCoinsFirst(searchDataObject);
          this.loadCurrencyInfo();
          return result;
        } else {
          //searchValue not empty, hidezero=false, sortByFav =false
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                o.coinname.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1 ||
                o.coinsymbol.toLowerCase().indexOf(searchValue.toLowerCase()) >
                  -1
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
              }
            });
          });
          return searchDataObject;
        }
      }
    } else {
      if (hideZero) {
        if (sortbyFav) {
          //searchValue  empty, hidezero=true, sortByFav =true
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                o.coinId in getMyBalanceList &&
                'amount' in getMyBalanceList[o.coinId] &&
                Number(
                  parseFloat(getMyBalanceList[o.coinId].amount).toFixed(8)
                ) !== 0
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
              }
            });
          });
          var result = this.getFavCoinsFirst(searchDataObject);
          this.loadCurrencyInfo();
          return result;
        } else {
          //searchValue  empty, hidezero=true, sortByFav =false
          _.map(_.keys(marketsCoinsListObj), marketKey => {
            searchDataObject[marketKey] = {
              coins: {},
              marketdata: marketsCoinsListObj[marketKey].marketdata,
              coinsArray: []
            };
            _.map(marketsCoinsListObj[marketKey].coinsArray, o => {
              if (
                o.coinId in getMyBalanceList &&
                'amount' in getMyBalanceList[o.coinId] &&
                Number(
                  parseFloat(getMyBalanceList[o.coinId].amount).toFixed(8)
                ) !== 0
              ) {
                searchDataObject[marketKey]['coinsArray'].push(o);
                //  marketsCoinsListObj[marketKey]['coinsArray'];
              }
            });
          });
          return searchDataObject;
        }
      } else {
        if (sortbyFav) {
          //searchValue  empty, hidezero=false, sortByFav =true {
          var result = this.getFavCoinsFirst(marketsCoinsListObj);
          this.loadCurrencyInfo();

          return result;
        } else {
          //searchValue  empty, hidezero=false, sortByFav =false {
          //return walletData;

          // this.setState({searchData: searchDataObject});
          return marketsCoinsListObj;
        }
      }
    }
  }

  toggle() {
    this.props.showCoinInfoUpdate(!this.props.showCoinInfo);
  }
  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  toggleTab(tab) {
    // this.coinInfoFunc({ coinsymbol: null, coinimage: null });
    if (this.state.activeTab !== tab) {
      this.props.setActiveTab({
        activeTab: tab
      });
      this.setState(
        {
          activeTab: tab
        },
        this.loadCurrencyInfo
      );
    }
  }

  marketsToggle(tab) {
    //this.loadCurrencyInfo()
    if (this.props.marketActiveTab !== tab) {
      this.props.marketActiveTabFunc({ marketActiveTab: tab });
      this.setState(
        {
          marketActiveTab: tab
        },
        this.loadCurrencyInfo
      );
    }
  }

  toggleHeaderActiveMenu(
    headerActiveMenu,
    changePageLocation,
    market,
    currency
  ) {
    const activeMarketSymbol = this.props.marketsCoinsListObj[market]
      .marketdata[0].marketsymbol;
    const activeCurrencySymbol = this.props.marketsCoinsListObj[market].coins[
      currency.coinId
    ].coinsymbol;
    this.props.marketCurrencyUpdate({
      activeMarketValue: market,
      activeCurrencyValue: currency.coinId,
      activeMarketSymbol,
      activeCurrencySymbol
    });
    this.props.headerActiveMenuUpdate(headerActiveMenu);

    this.props.buyLimitInputRemotelyChange({
      volumeBuy: '',
      bidBuy: '',
      totalBuy: '',
      marketBuy: market,
      currencyBuy: currency,
      operationBuy: ''
    });

    this.props.sellLimitInputRemotelyChange({
      volumeSell: '',
      bidSell: '',
      totalSell: '',
      marketSell: market,
      currencySell: currency,
      operationSell: ''
    });

    this.props.buyMarketInputRemotelyChange({
      volumeMarketBuy: ''
      // marketSell: market,
      // currencySell: currency,
      // operationSell: ''
    });

    this.props.sellMarketInputRemotelyChange({
      volumeMarketSell: ''
      // marketSell: market,
      // currencySell: currency,
      // operationSell: ''
    });

    this.props.buyStopLimitInputRemotelyChange({
      limitBuy: '',
      stopBuy: '',
      volumeStopBuy: '',
      totalStopBuy: '',
      marketBuy: market,
      currencyBuy: currency,
      operationBuy: ''
    });

    this.props.sellStopLimitInputRemotelyChange({
      stopSell: '',
      limitSell: '',
      marketSell: market,
      currencySell: currency,
      operationSell: '',
      volumeStopSell: '',
      totalStopSell: ''
    });

    this.props.changePage(`/${activeMarketSymbol}-${activeCurrencySymbol}`);
    // this.props.changePage(changePageLocation);
  }

  componentDidMount() {
    // this.props.allMarketCoin24HrPriceList(); // Get All Market Coins Price List Object
    // this.props.getMarketView();

    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj,

      marketsCoinsListObj: this.props.marketsCoinsListObj,
      // marketCoin24HrPriceListObj: this.props.marketCoin24HrPriceListObj,
      searchData: this.props.marketsCoinsListObj
    });

    // this.loadCurrencyInfo()
  }
  loadCurrencyInfoOnPageChange() {
    const { marketsCoinsListObj } = this.props;
    const { activeTab } = this.state;
    const activeTabCoins = _.values(marketsCoinsListObj[activeTab].coins);
    if (activeTabCoins[0] !== undefined) {
      this.coinInfoFunc(activeTabCoins[0]);
    }
  }
  loadCurrencyInfo() {
    const { marketsCoinsListObj } = this.props;
    const { favCoins, data, searchData, sortbyFav } = this.state;
    const { activeTab } = this.state;
    // if(_.size(_.keys(favCoins[activeTab]['coins']))>0){
    //   const activeTabCoins = _.values(favCoins[activeTab]['coins']);
    // }
    // else{
    //   const activeTabCoins = _.values(searchData[activeTab]['coins']);
    // }
    //const activeTabCoins = _.values(searchData[activeTab]['coins']);
    var activeTabCoins;
    if (sortbyFav) {
      if (_.size(favCoins[activeTab]['coinsArray']) > 0) {
        activeTabCoins = favCoins[activeTab]['coinsArray'];
      } else {
        activeTabCoins = data[activeTab]['coinsArray'];
      }
    } else {
      activeTabCoins = searchData[activeTab]['coinsArray'];
    }
    if (activeTabCoins[0] !== undefined && activeTabCoins.length !== 0) {
      this.coinInfoFunc(activeTabCoins[0]);
    } else {
      this.props.coinForCoinInfoFunc({ coinForCoinInfo: null });
      this.props.coinimageForCoinInfoFunc({ coinimageForCoinInfo: null });
    }
  }

  handleSearchChange() {
    const {
      marketViewInputText,
      walletData,
      marketViewAutosuggestCurrencyId,
      searchData,
      activeTab,
      marketsCoinsListObj
    } = this.state;
    if (
      Number.isInteger(marketViewAutosuggestCurrencyId) &&
      marketViewAutosuggestCurrencyId >= 0
    ) {
      const marketCrptoArray = _.keys(searchData);
      const newObj = {};
      _.map(marketCrptoArray, marketId => {
        newObj[marketId] = { coins: {}, marketdata: [] };
        const searchObj = searchData[marketId]['coins'];
        const searchMarketData = searchData[marketId]['marketdata'];

        const search_data = Object.keys(searchObj).map(i => searchObj[i]);

        var num;
        Object.keys(searchObj).forEach(key => {
          if (searchObj[key] === undefined) {
            delete searchObj[key];
          }
        });
        const sn = _.size(_.keys(searchObj));

        if (sn > 0) {
          const filteredSearchData = _.filter(search_data, obj => {
            return obj.coinId === marketViewAutosuggestCurrencyId;
          });

          num = Object.keys(searchObj).find(
            key => searchObj[key] === filteredSearchData[0]
          );
          // var finalObj = {
          //   [activeTab]: { coins: {}, marketdata: [] }
          // };
          const coins = {
            [num]: filteredSearchData[0]
          };
          newObj[marketId].coins = coins;
          newObj[marketId].marketdata = searchMarketData;
          this.setState({ searchData: newObj });
        } else {
          this.setState({ searchData: searchData });
        }
      });
    } else if (marketViewInputText === '') {
      this.setState({
        searchData: searchData,
        coinSearchValue: marketViewInputText,
        marketViewAutosuggestCurrencyId: -1
      });
    } else {
      if (marketViewInputText.indexOf('(') > -1) {
        const marketViewInputText1 = marketViewInputText.split('(')[0].trim();
        this.setState(
          {
            coinSearchValue: marketViewInputText1,
            marketViewAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      } else {
        this.setState(
          {
            coinSearchValue: marketViewInputText,
            marketViewAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      }
    }
    this.loadCurrencyInfo();
  }
  hideZeroFunc() {
    this.props.hideZeroBalances({
      hideZero: !this.state.hideZero
    });
  }

  sortFav() {
    this.props.sortByFavouritesUpdate({
      sortbyFav: !this.state.sortbyFav
    });
  }
  hideZeroBalOrders(hideZero) {
    const {
      marketViewAutosuggestCurrencyId,
      searchData,
      getMyBalanceList,
      activeTab
    } = this.state;

    // this.setState({
    //   hideZero: !this.state.hideZero
    // });
    this.props.hideZeroBalances({
      hideZero: hideZero
    });

    if (hideZero && marketViewAutosuggestCurrencyId == -1) {
      const searchList = Object.keys(searchData).map(i => searchData[i]);
      const marketCrptoArray = _.keys(searchData);
      const newObj = {};

      const result = _.filter(searchData[activeTab]['coins'], o => {
        newObj[activeTab] = { coins: {}, marketdata: [] };
        return (
          o.coinId in getMyBalanceList &&
          'amount' in getMyBalanceList[o.coinId] &&
          Number(parseFloat(getMyBalanceList[o.coinId].amount).toFixed(8)) !== 0
        );
      });
      const coinsObj = {};
      const c = result.map(o => {
        Object.assign(o, coinsObj);
      });
      this.setState({
        searchData: result
      });
    }
  }

  componentWillUnmount() {
    const { marketsCoinsListObj, activeTab } = this.state;
    this.props.hideZeroBalances({
      hideZero: false
    });
    this.props.sortByFavouritesUpdate({
      sortbyFav: false
    });
    this.props.searchValueUpdate({
      searchValue: ''
    });
    this.props.coinForCoinInfoFunc({
      coinForCoinInfo:
        marketsCoinsListObj[activeTab]['coinsArray'][0].coinsymbol
    });
    this.props.coinimageForCoinInfoFunc({
      coinimageForCoinInfo:
        marketsCoinsListObj[activeTab]['coinsArray'][0].coinimage
    });
  }
  searchCoin() {
    const { coinSearchValue, searchData, coinSymbolsObj } = this.state;
    const searchList = Object.keys(searchData).map(i => searchData[i]);

    const searchData1 = _.filter(searchData, obj => {
      return _.filter(obj['coins'], o => {
        return (
          coinSymbolsObj[o.coinId].coinname
            .toLowerCase()
            .indexOf(coinSearchValue.toLowerCase()) > -1 ||
          coinSymbolsObj[o.coinId].coinsymbol
            .toLowerCase()
            .indexOf(coinSearchValue.toLowerCase()) > -1
        );
      });
    });
    this.setState({ searchData: searchData1 });
  }
  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (this.props.marketViewLoader !== nextProps.marketViewLoader) {
      this.setState({ marketViewLoader: nextProps.marketViewLoader });
    }

    if (this.props.marketActiveTab !== nextProps.marketActiveTab) {
      this.setState(
        { marketActiveTab: nextProps.marketActiveTab },
        this.loadCurrencyInfo
      );
    }

    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState({ getMyBalanceList: nextProps.getMyBalanceList });
    }

    if (this.props.coinForCoinInfo !== nextProps.coinForCoinInfo) {
      if (nextProps.coinForCoinInfo !== null) {
        this.setState({ coinForCoinInfo: nextProps.coinForCoinInfo });
      }
    }

    if (this.props.coinimageForCoinInfo !== nextProps.coinimageForCoinInfo) {
      if (nextProps.coinimageForCoinInfo !== null) {
        this.setState({ coinimageForCoinInfo: nextProps.coinimageForCoinInfo });
      }
    }
    // }else{
    //   if(this.props.coinForCoinInfo==null  && nextProps.coinForCoinInfo==null) {
    //     this.setState({ coinForCoinInfo: nextProps.coinForCoinInfo });
    //     }
    // }

    if (this.props.activeTab !== nextProps.activeTab) {
      this.setState(
        {
          activeTab: nextProps.activeTab
        },
        this.loadCurrencyInfo
      );
    }

    if (
      this.props.autoSuggestForMarketsCoinSymbol !==
      nextProps.autoSuggestForMarketsCoinSymbol
    ) {
      this.setState({
        autoSuggestForMarketsCoinSymbol:
          nextProps.autoSuggestForMarketsCoinSymbol
      });
    }

    if (this.props.marketsCoinsListObj !== nextProps.marketsCoinsListObj) {
      this.setState(
        {
          marketsCoinsListObj: nextProps.marketsCoinsListObj,
          searchData: nextProps.marketsCoinsListObj,
          initData: nextProps.marketsCoinsListObj
        },
        this.loadCurrencyInfo
      );
    }

    if (
      this.props.marketCoin24HrPriceListObj !==
      nextProps.marketCoin24HrPriceListObj
    ) {
      this.setState({
        marketCoin24HrPriceListObj: nextProps.marketCoin24HrPriceListObj
      });
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.hideZero !== nextProps.hideZero) {
      //  this.hideZeroBalOrders(nextProps.hideZero);
      this.setState({
        hideZero: nextProps.hideZero,
        searchData: this.searchInList(
          nextProps.hideZero,
          this.state.searchValue,
          this.state.sortbyFav,
          nextProps.marketsCoinsListObj
        )
      });

      if (nextProps.hideZero) {
        this.props.hideZeroBalances({ hideZero: true });
      }
    }

    if (this.props.searchValue != nextProps.searchValue) {
      this.setState(
        {
          searchValue: nextProps.searchValue,
          searchData: this.searchInList(
            this.state.hideZero,
            nextProps.searchValue,
            this.state.sortbyFav,
            nextProps.marketsCoinsListObj
          )
        },
        this.loadCurrencyInfo
      );
    }

    if (this.props.sortbyFav != nextProps.sortbyFav) {
      this.setState(
        {
          sortbyFav: nextProps.sortbyFav,
          searchData: this.searchInList(
            this.state.hideZero,
            this.state.searchValue,
            nextProps.sortbyFav,
            nextProps.marketsCoinsListObj
          )
        },
        this.loadCurrencyInfo
      );
      if (nextProps.sortbyFav) {
        this.props.sortByFavouritesUpdate({ sortbyFav: true });
      }
    }
  }

  searchDataShow() {
    const { marketsCoinsListObj, searchValue } = this.state;
    if (searchValue !== '') {
      const searchData = {};
      _.each(_.keys(marketsCoinsListObj), function(crypto) {
        if (!searchData[crypto]) {
          searchData[crypto] = {};
        }
        searchData[crypto]['market'] = _.filter(
          marketsCoinsListObj[crypto]['market'],
          function(obj) {
            return (
              obj.coin.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
              statusCheck
                .marketViewNameCheck(obj.coin)
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
            );
          }
        );
      });
      this.setState({ searchData });
    } else {
      this.setState({ searchData: marketsCoinsListObj });
    }
  }

  selectFavCurrency(coinId) {
    const favCoinArray = selectFavCurrency.selectFavCurrency(coinId);
    this.setState({ favCoinArray });
  }

  coinInfoFunc(currency) {
    const coinsymbol = currency.coinsymbol;
    const coinimage = currency.coinimage;
    this.props.coinForCoinInfoFunc({ coinForCoinInfo: coinsymbol });
    this.props.coinimageForCoinInfoFunc({ coinimageForCoinInfo: coinimage });
  }
  sortBalanceColumn(type, columnName) {
    var {
      getMyBalanceList,
      marketsCoinsListObj,
      searchData,
      sortType,
      sortingCol,
      activeTab
    } = this.state;

    var list_for_sort = [];
    const balancesListSort = [];
    const temp = [];
    var init_ids = [];
    var ids = [];
    const sd = {};

    _.map(getMyBalanceList, o => {
      init_ids.push(o.coinId);
    });
    _.map(_.keys(searchData), marketKey => {
      list_for_sort[marketKey] = [];

      _.map(searchData[marketKey]['coinsArray'], obj => {
        var objBalance = {};

        if (
          getMyBalanceList &&
          _.size(getMyBalanceList) > 0 &&
          obj.coinId in getMyBalanceList &&
          'amount' in getMyBalanceList[obj.coinId]
        ) {
          objBalance.amount = getMyBalanceList[obj.coinId].amount.toFixed(8);
          objBalance.coinId = obj.coinId;
        } else {
          objBalance.amount = Number(0).toFixed(8);
          objBalance.coinId = obj.coinId;
        }

        list_for_sort[marketKey].push(objBalance);
      });
    });

    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    _.map(_.keys(list_for_sort), marketKey => {
      ids[marketKey] = [];
      balancesListSort[marketKey] = helpers.sortColumn(
        list_for_sort[marketKey],
        type,
        sortingCol,
        sortType
      );
      const searchDataObject = {};
      // balancesListSort.map(o =>{

      // })
      temp[marketKey] = _.filter(balancesListSort[marketKey], o => {
        return o.coinId !== activeTab;
      });

      _.map(temp[marketKey], o => {
        ids[marketKey].push(o.coinId);
      });
      _.map(_.keys(searchData), marketKey => {
        sd[marketKey] = {
          coins: {},
          marketdata: searchData[marketKey].marketdata,
          coinsArray: []
        };
        _.map(searchData[marketKey]['coinsArray'], obj => {
          _.map(ids[marketKey], o => {
            if (o == obj.coinId) {
              sd[marketKey]['coinsArray'].push(obj);
            }
          });
        });

        sd[marketKey]['coinsArray'].sort(
          (a, b) =>
            ids[marketKey].indexOf(a.coinId) - ids[marketKey].indexOf(b.coinId)
        );
      });
    });

    // const finalObj = {};
    // _.map(_.keys(marketsCoinsListObj), marketKey => {
    //   finalObj[marketKey] = {
    //     coins: {},
    //     marketdata: marketsCoinsListObj[marketKey].marketdata,
    //     coinsArray: [...sd[marketKey]['coinsArray']]
    //   };
    // });

    this.setState(
      {
        searchData: sd,
        sortingCol,
        sortType
      },
      this.loadCurrencyInfo
    );
  }
  sortColumn(type, columnName) {
    var {
      sortingCol,
      searchData,
      sortType,
      activeTab,
      marketCoin24HrPriceListObj
    } = this.state;
    const balancesListSort = [];
    var list_for_sort = [];
    const temp = [];
    var init_ids = [];
    var ids = [];
    const sd = {};

    _.map(_.keys(searchData), marketKey => {
      list_for_sort[marketKey] = [];

      _.map(searchData[marketKey]['coinsArray'], obj => {
        if (
          marketCoin24HrPriceListObj[marketKey] &&
          obj.coinId in marketCoin24HrPriceListObj[marketKey]
        ) {
          var o = marketCoin24HrPriceListObj[marketKey][obj.coinId];

          var idInPriceArray = {};

          idInPriceArray.lastexecutedPrice = o.lastexecutedPrice;
          idInPriceArray.twentyfourhourChange = o.twentyfourhourChange;
          idInPriceArray.twentyfourhourHigh = o.twentyfourhourHigh;
          idInPriceArray.twentyfourhourLow = o.twentyfourhourLow;
          idInPriceArray.twentyfourhourVolume = o.twentyfourhourVolume;
          idInPriceArray.coinId = o.coinId;
          idInPriceArray.marketId = o.marketId;
          list_for_sort[marketKey].push(idInPriceArray);
        } else {
          var idNotInPriceArray = {};
          idNotInPriceArray.lastexecutedPrice = Number(0).toFixed(8);
          idNotInPriceArray.twentyfourhourChange = Number(0).toFixed(8);
          idNotInPriceArray.twentyfourhourHigh = Number(0).toFixed(8);
          idNotInPriceArray.twentyfourhourLow = Number(0).toFixed(8);
          idNotInPriceArray.twentyfourhourVolume = Number(0).toFixed(8);
          idNotInPriceArray.coinId = obj.coinId;
          idNotInPriceArray.marketId = marketKey;
          list_for_sort[marketKey].push(idNotInPriceArray);
        }
      });
    });

    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );

    _.map(_.keys(marketCoin24HrPriceListObj), marketKey => {
      ids[marketKey] = [];
      balancesListSort[marketKey] = helpers.sortColumn(
        list_for_sort[marketKey],
        type,
        sortingCol,
        sortType
      );

      temp[marketKey] = _.filter(balancesListSort[marketKey], o => {
        return o.coinId !== activeTab;
      });

      _.map(temp[marketKey], o => {
        ids[marketKey].push(o.coinId);
      });
      _.map(_.keys(searchData), marketKey => {
        sd[marketKey] = {
          coins: {},
          marketdata: searchData[marketKey].marketdata,
          coinsArray: []
        };
        _.map(searchData[marketKey]['coinsArray'], obj => {
          _.map(ids[marketKey], o => {
            if (o == obj.coinId) {
              sd[marketKey]['coinsArray'].push(obj);
            }
          });
        });

        sd[marketKey]['coinsArray'].sort(
          (a, b) =>
            ids[marketKey].indexOf(a.coinId) - ids[marketKey].indexOf(b.coinId)
        );
      });
    });
    this.setState(
      {
        sortingCol,
        sortType,
        searchData: sd
      },
      this.loadCurrencyInfo
    );
  }
  getBalances(o) {
    const { getMyBalanceList } = this.state;
    if (this.props.isLoggedIn) {
      if (
        getMyBalanceList &&
        _.size(getMyBalanceList) > 0 &&
        o in getMyBalanceList &&
        'amount' in getMyBalanceList[o]
      ) {
        return getMyBalanceList[o].amount.toFixed(8);
      } else {
        return Number(0).toFixed(8);
      }
    } else {
      return 'NA';
    }
  }
  render() {
    const {
      error,
      marketViewLoader,
      marketActiveTab,
      searchData,
      activeTab,
      marketCoin24HrPriceListObj,
      getMyBalanceList,
      marketsCoinsListObj,
      coinForCoinInfo,
      coinSymbolsObj,
      autoSuggestForMarketsCoinSymbol,
      reactSelectOptions,
      sortingCol,
      sortType,
      sortbyFav,
      favCoins,
      differencCoins,
      data
    } = this.state;
    const marketCrptoArray = _.keys(searchData);
    if (error) {
      return <div>{error.toString()}</div>;
    }

    // if (marketViewLoader) {
    //   return <div>Loading...</div>;
    // }
    if (
      marketsCoinsListObj === undefined ||
      _.size(marketsCoinsListObj) === 0 ||
      marketCrptoArray.length === 0
    ) {
      return <div className="loading-o" />;
    }

    /* if (searchData.length !== 0) {
      this.loadCurrencyInfo();
    } */
    if (marketCrptoArray && marketCrptoArray.length > 0) {
      return (
        <>
          <ul className="nav nav-switch markets-menu">
            {marketCrptoArray && marketCrptoArray.length !== 0
              ? marketCrptoArray.map(o => (
                  <li className="nav-item" key={o}>
                    <a
                      className={`crsrPntr nav-link
												${activeTab === o ? ' active' : ''}`}
                      onClick={() => {
                        this.toggleTab(o);
                      }}
                      active={(activeTab === o).toString()}
                      aria-selected={activeTab === o}
                    >
                      {searchData[o].marketdata[0].marketsymbol}
                      {/* {o.toUpperCase()} */}
                    </a>
                  </li>
                ))
              : ''}

            <li className="nav-item ml-auto">
              <input
                className="form-control coin-input"
                type="search"
                placeholder="Search..."
                aria-label="Search"
                onChange={this.handleChange}
                style={{ cursor: 'auto' }}
              />
            </li>

            {this.props.isLoggedIn && (
              <li className="nav-item" style={{ padding: '0.4rem' }}>
                <Dropdown
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}
                >
                  <DropdownToggle>
                    {(!!this.state.hideZero || !!this.state.sortbyFav) && (
                      <span className="filter-status" />
                    )}
                    <i className="fa fa-filter" />
                  </DropdownToggle>
                  <DropdownMenu right className="mt-2" style={{ top: '5px' }}>
                    <DropdownItem className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="checkbox-filter"
                        name="zero"
                        checked={!!this.state.hideZero}
                        onChange={this.hideZeroFunc}
                      />{' '}
                      <label
                        className="pl-2 mb-0"
                        htmlFor="zero"
                        onClick={this.hideZeroFunc}
                      >
                        Hide zero balances
                      </label>
                    </DropdownItem>
                    <DropdownItem className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="checkbox-filter"
                        name="sortByFav"
                        checked={!!this.state.sortbyFav}
                        onChange={this.sortFav}
                      />{' '}
                      <label className="pl-2 mb-0" onClick={this.sortFav}>
                        Sort by favourites
                      </label>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
            {/* {autoSuggestForMarketsCoinSymbol &&
                autoSuggestForMarketsCoinSymbol[activeTab] && (
                  <Autosuggest
                    reactMarketCoinSelect={
                      autoSuggestForMarketsCoinSymbol[activeTab]
                    }
                  />
                )}
            </li> */}

            <li className="nav-item">
              <a
                className={`crsrPntr nav-link link-icon
									${marketActiveTab === 'assetView' ? ' active' : ''}`}
                onClick={() => {
                  this.marketsToggle('assetView');
                }}
                active={(marketActiveTab === 'assetView').toString()}
                aria-selected={marketActiveTab === 'assetView'}
              >
                <i className="fa fa-th-large" id="tooltip-1" />
                <UncontrolledTooltip
                  placement="top"
                  target="tooltip-1"
                  delay={{ hide: -1 }}
                >
                  Asset View
                </UncontrolledTooltip>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link link-icon
									${marketActiveTab === 'listView' ? ' active' : ''}`}
                onClick={() => {
                  this.marketsToggle('listView');
                }}
                active={(marketActiveTab === 'listView').toString()}
                aria-selected={marketActiveTab === 'listView'}
              >
                <i className="fa fa-th-list" id="tooltip-2" />
                <UncontrolledTooltip
                  placement="top"
                  target="tooltip-2"
                  delay={{ hide: -1 }}
                >
                  List View
                </UncontrolledTooltip>
              </a>
            </li>
          </ul>
          {/* <div>{searchData[activeTab]['coins'][1].coinId}</div> */}

          {activeTab >= 0 &&
          searchData &&
          searchData.length !== 0 &&
          marketCrptoArray &&
          marketCrptoArray.length !== 0 &&
          activeTab &&
          searchData[activeTab] &&
          searchData[activeTab]['coinsArray'] &&
          _.size(searchData[activeTab]['coinsArray']) > 0 ? (
            <div className="container-fluid card-box full-table allMarkets">
              <table
                className="table data-table table-fixed"
                style={{ marginBottom: '0px' }}
              >
                <thead>
                  <tr>
                    <th scope="col" className="faded" style={{ width: '3%' }} />
                    <th scope="col" className="faded" style={{ width: '12%' }}>
                      Name
                    </th>
                    <th scope="col" className="faded" style={{ width: '4%' }}>
                      Symbol
                    </th>
                    <th
                      scope="col"
                      className="faded crsrPntr"
                      onClick={() => this.sortBalanceColumn('number', 'amount')}
                      style={{
                        width: '16%',
                        textAlign: 'center'
                      }}
                    >
                      Balance{' '}
                      {sortingCol === 'amount' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                    <th
                      scope="col"
                      className="faded crsrPntr"
                      onClick={() =>
                        this.sortColumn('number', 'lastexecutedPrice')
                      }
                    >
                      {coinSymbolsObj &&
                        `Last price (${coinSymbolsObj[activeTab].coinsymbol}) `}
                      {sortingCol === 'lastexecutedPrice' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                    <th
                      scope="col"
                      className="faded crsrPntr"
                      onClick={() =>
                        this.sortColumn('number', 'twentyfourhourHigh')
                      }
                    >
                      {coinSymbolsObj &&
                        `24H High (${coinSymbolsObj[activeTab].coinsymbol}) `}
                      {sortingCol === 'twentyfourhourHigh' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                    <th
                      scope="col"
                      className="faded crsrPntr"
                      onClick={() =>
                        this.sortColumn('number', 'twentyfourhourLow')
                      }
                    >
                      {coinSymbolsObj &&
                        `24H Low (${coinSymbolsObj[activeTab].coinsymbol}) `}
                      {sortingCol === 'twentyfourhourLow' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                    <th
                      scope="col"
                      className="faded crsrPntr"
                      onClick={() =>
                        this.sortColumn('number', 'twentyfourhourVolume')
                      }
                    >
                      {coinSymbolsObj &&
                        `24H Volume (${coinSymbolsObj[activeTab].coinsymbol}) `}
                      {sortingCol === 'twentyfourhourVolume' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>

                    <th
                      scope="col"
                      className="faded crsrPntr"
                      style={{ width: '10%' }}
                      onClick={() =>
                        this.sortColumn('number', 'twentyfourhourChange')
                      }
                    >
                      24H Change{' '}
                      {sortingCol === 'twentyfourhourChange' ? (
                        sortType == 'asc' ? (
                          <i className=" fa fa-sort-asc" />
                        ) : (
                          <i className=" fa fa-sort-desc" />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                    <th scope="col" className="faded" style={{ width: '7%' }} />
                  </tr>
                </thead>
                {activeTab >= 0 &&
                  searchData &&
                  activeTab in searchData &&
                  'coinsArray' in searchData[activeTab] && (
                    <tbody style={{ height: 'calc(100vh - 166px)' }}>
                      {searchData[activeTab]['coinsArray'].map((o, i) => (
                        <tr
                          key={i}
                          className={`crsrPntr 
														${coinForCoinInfo === o.coinsymbol ? 'selected-table-row' : undefined}`}
                          onClick={() => this.coinInfoFunc(o)}
                        >
                          {/* {this.props.isLoggedIn && ( */}
                          <td style={{ width: '3%' }}>
                            <span
                              onClick={() => this.selectFavCurrency(o.coinId)}
                              className={
                                (
                                  JSON.parse(
                                    localStorage.getItem('favCoinArray')
                                  ) || []
                                ).indexOf(o.coinId) === -1
                                  ? 'oi oi-star faded'
                                  : 'oi oi-star'
                              }
                            />
                          </td>
                          {/* )} */}
                          <td
                            className="coin-img-small"
                            style={{ width: '12%' }}
                          >
                            <SVG src={o.coinimage} className="svg-15" />
                            {/* <img src="assets/images/coin-logos/lsk@2x.png" /> */}

                            {o.coinname}
                          </td>
                          <td style={{ width: '4%' }}>{o.coinsymbol}</td>

                          <td
                            className={
                              !this.state.isLoggedIn ? 'textCenter' : ''
                            }
                            style={{ width: '16%' }}
                          >
                            {this.getBalances(o.coinId)}{' '}
                            {_.size(getMyBalanceList) > 0 ? (
                              <span className="currency">
                                {o in searchData[activeTab]['coinsArray'] &&
                                  o.coinsymbol}
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          {marketCoin24HrPriceListObj &&
                          marketCoin24HrPriceListObj[activeTab] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId] ? (
                            <td>
                              {`${Number(
                                marketCoin24HrPriceListObj[activeTab][o.coinId]
                                  .lastexecutedPrice
                              ).toFixed(8)} `}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          ) : (
                            <td>
                              {Number(0).toFixed(8)}{' '}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          )}
                          {marketCoin24HrPriceListObj &&
                          marketCoin24HrPriceListObj[activeTab] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId] ? (
                            <td>
                              {`${Number(
                                marketCoin24HrPriceListObj[activeTab][o.coinId]
                                  .twentyfourhourHigh
                              ).toFixed(8)} `}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          ) : (
                            <td>
                              {Number(0).toFixed(8)}{' '}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          )}
                          {marketCoin24HrPriceListObj &&
                          marketCoin24HrPriceListObj[activeTab] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId] ? (
                            <td>
                              {`${Number(
                                marketCoin24HrPriceListObj[activeTab][o.coinId]
                                  .twentyfourhourLow
                              ).toFixed(8)} `}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          ) : (
                            <td>
                              {Number(0).toFixed(8)}{' '}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          )}
                          {marketCoin24HrPriceListObj &&
                          marketCoin24HrPriceListObj[activeTab] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId] ? (
                            <td>
                              {`${Number(
                                marketCoin24HrPriceListObj[activeTab][o.coinId]
                                  .twentyfourhourVolume
                              ).toFixed(8)} `}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          ) : (
                            <td>
                              {Number(0).toFixed(8)}{' '}
                              {/* <span className="currency">{o.coinsymbol}</span> */}
                            </td>
                          )}
                          {marketCoin24HrPriceListObj &&
                          marketCoin24HrPriceListObj[activeTab] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId] &&
                          marketCoin24HrPriceListObj[activeTab][o.coinId]
                            .twentyfourhourChange ? (
                            <td
                              className={`${
                                marketCoin24HrPriceListObj[activeTab][
                                  o.coinId
                                ].twentyfourhourChange.includes('-')
                                  ? 'negative-text'
                                  : 'positive-text'
                              }`}
                              style={{ width: '10%' }}
                            >
                              <span
                                className={`${
                                  marketCoin24HrPriceListObj[activeTab][
                                    o.coinId
                                  ].twentyfourhourChange.includes('-')
                                    ? 'change-minus'
                                    : 'change-plus'
                                }`}
                              />
                              {`${
                                marketCoin24HrPriceListObj[activeTab][
                                  o.coinId
                                ].twentyfourhourChange.indexOf('%') > -1
                                  ? marketCoin24HrPriceListObj[activeTab][
                                      o.coinId
                                    ].twentyfourhourChange.replace('-', '')
                                  : marketCoin24HrPriceListObj[activeTab][
                                      o.coinId
                                    ].twentyfourhourChange.replace('-', '') +
                                    '%'
                              }`}
                            </td>
                          ) : (
                            <td
                              className="positive-text"
                              style={{ width: '10%' }}
                            >
                              <span className="change-plus" />
                              0%
                            </td>
                          )}

                          <td className="button" style={{ width: '7%' }}>
                            <div
                              className="button-cancel"
                              onClick={() =>
                                this.toggleHeaderActiveMenu(
                                  'exchange',
                                  '/',
                                  activeTab,
                                  o
                                )
                              }
                            >
                              Trade
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
              </table>
            </div>
          ) : (
            <div style={{ height: 'calc(100vh - 166px)' }}>No data</div>
          )}
        </>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      getMarketView: formData => getMarketView(formData),
      showCoinInfoUpdate: formData => showCoinInfoUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      marketCurrencyUpdate: formData => marketCurrencyUpdate(formData),
      marketActiveTabFunc: formData => marketActiveTabFunc(formData),
      coinForCoinInfoFunc: formData => coinForCoinInfoFunc(formData),
      coinimageForCoinInfoFunc: formData => coinimageForCoinInfoFunc(formData),
      setActiveTab: formData => setActiveTab(formData),
      buyLimitInputRemotelyChange: formData =>
        buyLimitInputRemotelyChange(formData),
      sellLimitInputRemotelyChange: formData =>
        sellLimitInputRemotelyChange(formData),
      buyMarketInputRemotelyChange: formData =>
        buyMarketInputRemotelyChange(formData),
      sellMarketInputRemotelyChange: formData =>
        sellMarketInputRemotelyChange(formData),
      buyStopLimitInputRemotelyChange: formData =>
        buyStopLimitInputRemotelyChange(formData),
      sellStopLimitInputRemotelyChange: formData =>
        sellStopLimitInputRemotelyChange(formData),
      allMarketCoin24HrPriceList: formData =>
        allMarketCoin24HrPriceList(formData),
      marketViewAutosuggestCurrencyIdUpdate: formData =>
        marketViewAutosuggestCurrencyIdUpdate(formData),
      hideZeroBalances: formData => hideZeroBalances(formData),
      sortByFavouritesUpdate: formData => sortByFavouritesUpdate(formData),
      searchValueUpdate: formData => searchValueUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    coinForCoinInfo: state.marketView.coinForCoinInfo,
    coinimageForCoinInfo: state.marketView.coinimageForCoinInfo,
    marketActiveTab: state.marketView.marketActiveTab,
    marketViewLoader: state.marketView.marketViewLoader,
    getMarketViewList: state.marketView.getMarketViewList,
    searchValue: state.marketView.searchValue,
    showCoinInfo: state.marketView.showCoinInfo,
    activeTab: state.marketView.activeTab,
    marketCoin24HrPriceListObj: state.app.marketCoin24HrPriceListObj,
    headerActiveMenu: state.app.headerActiveMenu,
    getMyBalanceList: state.myOrders.getMyBalanceList,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    isLoggedIn: state.signin.isLoggedIn,
    marketViewInputText: state.marketView.marketViewInputText,
    autoSuggestForMarketsCoinSymbol: state.app.autoSuggestForMarketsCoinSymbol,
    marketViewAutosuggestCurrencyId:
      state.marketView.marketViewAutosuggestCurrencyId,
    coinSymbolsObj: state.app.coinSymbolsObj,
    hideZero: state.marketView.hideZero,
    sortbyFav: state.marketView.sortbyFav
  }),
  mapDispatchToProps
)(MarketView);
