/**
 * Footer Component
 * Component
 */

import React from 'react';
import { Link } from 'react-router-dom';
import 'App/footer.css';
import FooterModal from 'App/footerModal';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kyc: true,
      tnc: false,
      fnl: false,
      cookies: false,
      warnings: false,
      referral: false,
      aboutUs: false,
      pp: false,
      team: false,
      loyalty: false,
      direct: false,
      k: '',
      modal: false
    };
    this.child = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.unsetAll = this.unsetAll.bind(this);
    this.set = this.set.bind(this);
  }

  toggle(key) {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.child.current.set(key);
  }

  unsetAll() {
    this.setState({
      kyc: false,
      tnc: false,
      fnl: false,
      cookies: false,
      warnings: false,
      referral: false,
      aboutUs: false,
      pp: false,
      team: false,
      loyalty: false,
      direct: false
    });
  }

  set(key) {
    this.unsetAll();

    switch (key) {
      case 'kyc':
        this.setState({
          kyc: true
        });
        break;
      case 'tnc':
        this.setState({
          tnc: true
        });
        break;
      case 'warnings':
        this.setState({
          warnings: true
        });
        break;
      case 'fnl':
        this.setState({
          fnl: true
        });
        break;
      case 'cookies':
        this.setState({
          cookies: true
        });
        break;
      case 'referral':
        this.setState({
          referral: true
        });
        break;
      case 'aboutUs':
        this.setState({
          aboutUs: true
        });
        break;
      case 'pp':
        this.setState({
          pp: true
        });
        break;
      case 'team':
        this.setState({
          team: true
        });
        break;
      case 'loyalty':
        this.setState({
          loyalty: true
        });
        break;
      case 'direct':
        this.setState({
          direct: true
        });
        break;
    }
  }

  untoggle = () => {
    this.setState({
      modal: false
    });
  };
  render() {
    return (
      <>
        <div className="container">
          <footer className="row">
            <div className="col-sm-3 col-md-3">
              <ul>
                <li>
                  <p onClick={() => this.toggle('kyc')}>AML/KYC</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('warnings')}>Risk Warning</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('pp')}>Privacy policy</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-md-3">
              <ul>
                <li>
                  <p onClick={() => this.toggle('contact')}>Contact Us</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('referral')}>Referral</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('aboutUs')}>About Us</p>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-md-3">
              <ul>
                <li>
                  <p onClick={() => this.toggle('fnl')}>Fees &amp; Limits</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('cookies')}>Cookie Statement</p>
                </li>
                <li>
                  <p onClick={() => this.toggle('tnc')}>
                    Terms &amp; conditions
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-sm-3 col-md-3">
              <p className="logo text-center">
                <a href="/">
                  <img
                    width="200px"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  />
                </a>
              </p>
              <div className="social-icons text-center">
                <a href="https://www.facebook.com/throughbit" target="_blank">
                  <i className="fa fa-facebook-f" />
                </a>
                <a href="https://www.twitter.com/throughbit" target="_blank">
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/throughbit"
                  target="_blank"
                >
                  <i className="fa fa-linkedin-square" />
                </a>

                <a href="https://telegram.me/throughbit" target="_blank">
                  <i className="fa fa-telegram" />
                </a>
                <a href="https://blog.throughbit.com/" target="_blank">
                  <i className="fa fa-medium" />
                </a>
              </div>
            </div>
          </footer>
        </div>
        <FooterModal
          modall={this.state.modal}
          ref={this.child}
          untoggle={this.untoggle}
        />
      </>
    );
  }
}
export default Footer;
