/**
 * Main Transaction Component
 * Component
 */

import React from 'react';
// import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Transfer from './transfer';
import {
  searchValueUpdate,
  hideZeroBalances
} from 'Modules/marketView/marketViewReducer';
import { activeTabUpdate } from './withdrawDepositReducer';
import Deposit from './deposit';
import Withdraw from './withdraw';
import DepositWithdraw from './open';

import ErrorBoundary from 'Modules/ErrorBoundary';

import { headerActiveMenuUpdate } from 'App/appReducer';
import Autosuggest from 'Modules/autosuggest/autosuggest';

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideZero = this.hideZero.bind(this);
    this.state = {
      depositFormView: this.props.depositFormView,
      withdrawFormView: this.props.withdrawFormView,
      searchValue: '',
      hideZero: false,
      coinSymbolsObj: {},
      autoSuggestData: [],
      reactSelectOptions: this.props.reactSelectOptions,
      sidebar_main_height: window.innerHeight - 60
    };
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('transaction');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.depositFormView !== nextProps.depositFormView) {
      this.setState({ depositFormView: nextProps.depositFormView });
    }

    if (this.props.withdrawFormView !== nextProps.withdrawFormView) {
      this.setState({ withdrawFormView: nextProps.withdrawFormView });
    }

    if (this.props.reactSelectOptions !== nextProps.reactSelectOptions) {
      this.setState({ reactSelectOptions: nextProps.reactSelectOptions });
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  handleChange(event) {
    this.setState({
      searchValue: event.target.value
    });
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  hideZero() {
    this.setState({
      hideZero: !this.state.hideZero
    });
    this.props.hideZeroBalances({ hideZero: !this.state.hideZero });
  }

  render() {
    const {
      depositFormView,
      withdrawFormView,
      checked,
      searchValue,
      hideZero,
      coinSymbolsObj,
      reactSelectOptions
    } = this.state;

    const { activeTab } = this.props;
    return (
      <div className="row no-gutters">
        <div className="col-lg-9 container-one">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={activeTab === '1' ? 'nav-link active' : 'nav-link'}
                onClick={() => {
                  this.toggle('1');
                }}
                active={(activeTab === '1').toString()}
                data-toggle="tab"
                role="tab"
                aria-selected={activeTab === '1'}
              >
                Deposits/Withdrawals
              </a>
            </li>
            {/*  <li className="nav-item">
              <a
                className={activeTab === '2' ? 'nav-link active' : 'nav-link'}
                onClick={() => {
                  this.toggle('2');
                }}
                active={(activeTab === '2').toString()}
                data-toggle="tab"
                role="tab"
                aria-selected={activeTab === '2'}
              >
                Transfer
              </a>
            </li> */}
          </ul>
          <input
            type="checkbox"
            className="checkbox-filter"
            name="zero"
            value="Hide zero balances"
            checked={checked}
            onChange={this.hideZero}
          />{' '}
          <label htmlFor="zero">Hide zero balances</label>
          {/* <form className="form-inline">
            <input
              className="form-control search-bar-head mr-sm-2"
              type="search"
              placeholder="Search..."
              aria-label="Search"
              onChange={this.handleChange}
            />
          </form> */}
          {reactSelectOptions && <Autosuggest />}
          {activeTab === '1' && (
            <ErrorBoundary>
              <DepositWithdraw searchValue={searchValue} hideZero={hideZero} />
            </ErrorBoundary>
          )}
          {/*   {activeTab === '2' && (
            <ErrorBoundary>
              <Transfer />
            </ErrorBoundary>
          )} */}
        </div>

        {depositFormView && (
          <Deposit
            coinSymbolsObj={coinSymbolsObj}
            style={{ height: this.state.sidebar_main_height }}
          />
        )}
        {withdrawFormView && (
          <Withdraw style={{ height: this.state.sidebar_main_height }} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      searchValueUpdate: formData => searchValueUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      hideZeroBalances: formData => hideZeroBalances(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    depositFormView: state.transactions.depositFormView,
    withdrawFormView: state.transactions.withdrawFormView,
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.transactions.activeTab,
    coinSymbolsObj: state.app.coinSymbolsObj,
    reactSelectOptions: state.app.reactSelectOptions
  }),
  mapDispatchToProps
)(Transaction);
