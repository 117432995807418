import _ from 'underscore';

const sort = (data, type, columnName, sort) => {
  let sortData;
  if (type === 'string') {
    sortData = _.sortBy(data, o => o[columnName].toLowerCase());
  } else if (type === 'number') {
    sortData = _.sortBy(data, columnName);
  } else if (type === 'date') {
    sortData = _.sortBy(data, o => new Date(o[columnName]).getTime());
  }
  if (sort === 'desc') {
    sortData = sortData.reverse();
  }
  return sortData;
};

export default { sort };
