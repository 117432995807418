import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Fullpage from './fullpage';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

let wid = window.outerWidth;

class LandingPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {}

  componentWillMount() {
    // console.log(this.props.location.pathname);
    if (this.props.location.pathname == '/landingPage') {
      Array.prototype.forEach.call(
        document.querySelectorAll(
          'link[rel=stylesheet][href~="assets/css/style.css"]'
        ),
        function(element) {
          try {
            element.parentNode.removeChild(element);
          } catch (err) {}
        }
      );
    } else {
      window.location.reload();
    }
  }

  /* componentWillUnmount() {
    window.location.reload();
  } */
  componentDidMount() {
    const screenWidth = window.outerWidth;
    if (screenWidth <= 768) {
      //document.html.classList.add('media-ge-768');
      document.body.classList.add('media-ge-768');
    }
  }

  componentWillUnmount() {
    //document.html.classList.remove('media-ge-768');
    document.body.classList.remove('media-ge-768');
  }

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Helmet>{' '}
        <Fullpage wid={wid} />
      </HelmetProvider>
    );
  }
}

export default withRouter(LandingPage);
