import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './initial-idea.css';
import ReactDOM from 'react-dom';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import { upLoadCareerInfo, fetchJobList } from './jobPortalReducer';
import 'pure-react-carousel/dist/react-carousel.es.css';
import apiCallerNew from 'Utils/apiCallerNew';
import { numberFormat } from 'highcharts';
import Arrows from './Arrows';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

const loc = window.location.href;
const pos = loc.search('#');
const prev = loc.substr(0, pos + 1);
const res = loc.substr(pos + 1);
class JobPortal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      domain: '',
      message: '',
      success: false,
      emessage: '',
      apply: false,
      ShowTeam: false,
      ShowCareer: true,
      viewGraphicDesignerJob: false,
      reader: {},
      hideArrow: false,
      careerDataUploadResponse: {},
      careerDataUplodLoader: true,
      jobListLoader: true,
      collapse: false,
      index: '-1',
      buttonName: 'View',
      default: true,
      showCareerIcon: false,
      more: 3,
      less: 0,
      readerLength: '',
      noJobsEmessage: ''
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidMount() {
    this.props.fetchJobList();
    // let options = {
    //   headers: { 'Content-Type': 'application/json' },
    //   method: 'GET'
    // };
    // fetch('https://zaqapi.throughbit.com/job/list', options)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then(response => {
    //     this.setState({ reader: response }, () => {
    //       console.log(this.state.reader);
    //     });
    //   });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.careerDataUplodLoader !== nextProps.careerDataUplodLoader) {
      this.setState({ careerDataUplodLoader: nextProps.careerDataUplodLoader });
    }
    if (this.props.jobListLoader !== nextProps.jobListLoader) {
      this.setState({ jobListLoader: nextProps.jobListLoader });
    }
    if (
      nextProps.careerDataUploadResponse !== undefined &&
      this.props.careerDataUploadResponse !==
        nextProps.careerDataUploadResponse &&
      this.props.careerDataUploadResponse !== nextProps.careerDataUploadResponse
    ) {
      this.setState({
        emessage: nextProps.careerDataUploadResponse.message,
        name: '',
        email: '',
        domain: '',
        message: ''
      });
    }

    if (
      nextProps.reader != undefined &&
      nextProps.reader.data &&
      nextProps.reader.data.length != 0 &&
      this.props.reader !== nextProps.reader &&
      this.props.reader !== nextProps.reader
    ) {
      this.setState({
        reader: nextProps.reader,
        readerLength: nextProps.reader.data.length
      });
    } else {
      this.setState({
        noJobsEmessage:
          "Right now, we are firing on all cylinders. We have a well-settled team, but we would love to explore the possibility of working with you. Fill the form on the left of the screen and let's get in touch."
      });
    }
  }

  toggleCollapse(i) {
    if (i === this.state.index) {
      this.setState(state => ({
        collapse: false,
        index: '-1',
        default: true,
        apply: false,
        showCareer: false,
        domain: '',
        showCareerIcon: false
      }));
    }
    if (i != this.state.index) {
      this.setState(state => ({
        collapse: true,
        apply: false,
        index: i,
        default: false,
        showCareerIcon: true
      }));
    }
  }

  handleSubmit = () => {
    if (
      this.state.email &&
      this.state.name &&
      this.state.domain &&
      this.state.message
    ) {
      this.props.upLoadCareerInfo({
        email: this.state.email,
        full_name: this.state.name,
        position: this.state.domain,
        description: this.state.message
      });
    } else {
      if (!this.state.name) {
        this.setState({
          emessage: ' Please enter your name'
        });
      } else if (!this.state.email) {
        this.setState({
          emessage: ' Please select your email address'
        });
      } else if (!this.state.domain) {
        this.setState({
          emessage: ' Please enter your domain'
        });
      } else if (!this.state.message) {
        this.setState({
          emessage: ' Please enter message'
        });
      }
    }
    //fetch('http://hyferex.com/mail/career', {
    // fetch('https://zaqapi.throughbit.com/mail/career', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     email: this.state.email,
    //     full_name: this.state.name,
    //     position: this.state.domain,
    //     description: this.state.message
    //   })
    // })
    //   .then(res => res.json())
    //   .then(
    //     result => {
    //       this.setState({
    //         success: true,
    //         apply: false,
    //         isLoaded: true,
    //         items: result.items
    //       });
    //     },

    //     error => {
    //       this.setState({
    //         emessage: 'Something went wrong.Please come back later.',
    //         isLoaded: true,
    //         error
    //       });
    //     }
    //   );
  };

  setMore = () => {
    switch ((this.state.less, this.state.more)) {
      case (0, 3):
        this.setState({
          more: this.state.more + 3,
          less: this.state.less + 3
        });
    }
  };

  setLess = () => {
    switch ((this.state.less, this.state.more)) {
      case (3, 6):
        this.setState({
          more: this.state.more - 3,
          less: this.state.less - 3
        });
    }
  };

  render() {
    return (
      <>
        <main>
          <div className="row no-gutters row-eq-height bg-light" id="careers">
            <div className="col-md-4 side-animated bg-dark" id="job">
              <div className="container row h-100 py-3 align-items-center justify-content-center webkit-full-height">
                <div className="row no-gutters w-75">
                  {this.state.showTeam ? (
                    <>
                      <div className="col-md-12 member-data">
                        <img src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29" />
                      </div>
                      <div className="col-md-6 mt-5">
                        <h2 className="name">Pikachu</h2>
                        <h3>Head detective</h3>
                      </div>
                      <div className="col-md-6 mt-5">
                        <ul className="social-icons">
                          <li className="ml-auto">
                            <a href="#">
                              <i className="fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-medium" aria-hidden="true" />
                            </a>
                          </li>
                          <a href="#" />
                          <li>
                            <a href="#" />
                            <a href="#">
                              <i className="fa fa-linkedin-square" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p className="col-md-12 member-bio">
                        Nullam quis varius metus. Nam efficitur malesuada metus
                        quis egestas. Donec pretium mollis tellus, sit amet
                        feugiat odio porta sit amet. Nulla rhoncus venenatis
                        libero vehicula interdum. Cras nibh dolor, suscipit
                        convallis congue non, tincidunt quis quam. Sed convallis
                        dolor sit amet metus facilisis consequat.
                      </p>
                    </>
                  ) : null}

                  {this.state.default ? (
                    <>
                      <div className="col-md-12">
                        <h2 className="name pt-1r">
                          Let's create a job for you
                        </h2>
                        <div className="form-group mt-5">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={this.state.name}
                            aria-describedby="emailHelp"
                            onChange={e =>
                              this.setState({ name: e.target.value })
                            }
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group mt-5">
                          <input
                            id="email"
                            type="email"
                            value={this.state.email}
                            className="form-control"
                            placeholder="Email ID"
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group mt-5">
                          <input
                            id="domain"
                            type="text"
                            value={this.state.domain}
                            className="form-control"
                            placeholder="Position you are applying for"
                            onChange={e =>
                              this.setState({ domain: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group mt-5">
                          <textarea
                            id="message"
                            className="form-control"
                            rows={2}
                            value={this.state.message}
                            placeholder="Message"
                            defaultValue={''}
                            onChange={e =>
                              this.setState({ message: e.target.value })
                            }
                          />
                        </div>
                        <p>{this.state.emessage}</p>
                        <button
                          type="submit"
                          id="btn"
                          className="btn btn-shot mt-5"
                          onClick={this.handleSubmit}
                        >
                          Take a shot
                        </button>
                      </div>

                      <div className="col-md-12 mt-10">
                        <ul className="social-icons a-r">
                          <li className="ml-auto">
                            <a
                              href="https://www.facebook.com/throughbit"
                              target="_blank"
                            >
                              <i className="fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.twitter.com/throughbit"
                              target="_blank"
                            >
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://blog.throughbit.com/"
                              target="_blank"
                            >
                              <i className="fa fa-medium" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/throughbit"
                              target="_blank"
                            >
                              <i className="fa fa-linkedin-square" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}

                  {this.state.showCareerIcon ? (
                    <>
                      <div className="col-md-12F">
                        <img
                          className="careerIcon"
                          src={`${process.env.PUBLIC_URL}/assets/images/career.png`}
                        />
                      </div>
                    </>
                  ) : null}

                  {this.state.apply && this.state.ShowCareer ? (
                    <>
                      <div className="col-md-12">
                        <h2 className="name">Awww! you like us?</h2>
                        <div className="form-group mt-5">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            aria-describedby="emailHelp"
                            onChange={e =>
                              this.setState({ name: e.target.value })
                            }
                            placeholder="Name"
                          />
                        </div>
                        <div className="form-group mt-5">
                          <input
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Email ID"
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group mt-5">
                          <input
                            id="email"
                            type="email"
                            value={this.state.domain}
                            className="form-control"
                            placeholder="Email ID"
                            readOnly={true}
                          />
                        </div>

                        <div className="form-group mt-5">
                          <textarea
                            id="message"
                            className="form-control"
                            rows={2}
                            placeholder="Message"
                            defaultValue={''}
                            onChange={e =>
                              this.setState({ message: e.target.value })
                            }
                          />
                        </div>
                        <p>{this.state.emessage}</p>
                        <button
                          type="submit"
                          id="btn"
                          className="btn btn-shot mt-5"
                          onClick={this.handleSubmit}
                        >
                          Send feelers
                        </button>
                      </div>

                      <div className="col-md-12 mt-10">
                        <ul className="social-icons a-r">
                          <li className="ml-auto">
                            <a href="https://www.facebook.com/throughbit">
                              <i className="fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.twitter.com/throughbit">
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="https://blog.throughbit.com/">
                              <i className="fa fa-medium" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/company/throughbit">
                              <i className="fa fa-linkedin-square" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}

                  {this.state.success ? (
                    <>
                      {' '}
                      <div className="col-md-12 a-c">
                        <h2 className="name mb-3">{this.state.emessage}</h2>
                        <h3>
                          Now, choose one of the 4 corners to exit the page.
                        </h3>
                      </div>
                      <div className="col-md-12 mt-10">
                        <ul className="social-icons a-r">
                          <li className="ml-auto">
                            <a href="https://www.facebook.com/throughbit">
                              <i className="fa fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.twitter.com/throughbit">
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="https://blog.throughbit.com/">
                              <i className="fa fa-medium" aria-hidden="true" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/company/throughbit">
                              <i className="fa fa-linkedin-square" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 align-self-center">
              <div className="container products-desc w-75">
                <ul className="nav team">
                  {/* <li className="nav-item" onClick={ () => this.setState({ShowTeam :true,ShowCareer :false})}>
                    <a className="nav-link active" href="#">Team</a>
                  </li> */}

                  <li
                    className="nav-item"
                    onClick={() =>
                      this.setState({
                        ShowCareer: true,
                        ShowTeam: false,
                        success: false,
                        apply: false
                      })
                    }
                  >
                    <h1 className="nav-link active" href="#">
                      Career
                    </h1>
                  </li>
                </ul>

                {this.state.ShowTeam ? (
                  <CarouselProvider
                    visibleSlides="5"
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    totalSlides={15}
                  >
                    <Slider>
                      <Slide index={0}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={1}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={2}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={3}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={4}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={5}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={6}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={7}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={8}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={9}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={10}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={11}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={12}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={13}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                      <Slide index={14}>
                        {' '}
                        <img
                          height="320"
                          width="160"
                          src="https://scontent.fblr2-1.fna.fbcdn.net/v/t31.0-8/14976773_1272996269442375_4849067708166651277_o.jpg?_nc_cat=109&_nc_ht=scontent.fblr2-1.fna&oh=d454a7144bb0f9ef2953177618b94262&oe=5CF08A29"
                        />
                      </Slide>
                    </Slider>
                  </CarouselProvider>
                ) : null}

                {this.state.ShowCareer ? (
                  <ul className="container openings mt-5">
                    {this.state.reader.data
                      ? this.state.reader.data.map((data, index) => (
                          <div
                            key={index}
                            hidden={
                              !(
                                index < this.state.more &&
                                index >= this.state.less
                              )
                            }
                          >
                            <li className="opening row">
                              <div className="col-md-9 name">
                                <img
                                  height="32"
                                  className=" pr-5"
                                  src={data.icon}
                                />
                                {data.title}
                              </div>
                              <div className="col-md-2">
                                <div className="time">{data.position}</div>
                              </div>
                              <div className="col-md-1 pt-1">
                                <a
                                  href="#Careers"
                                  className="view-button"
                                  onClick={() => this.toggleCollapse(index)}
                                >
                                  {this.state.buttonName}
                                </a>
                              </div>
                            </li>

                            <Collapse
                              isOpen={
                                this.state.collapse && this.state.index == index
                              }
                            >
                              <div
                                id={index}
                                className="col-md-12 description mb-5"
                              >
                                <h2 className="name"> {data.title}</h2>
                                <h5>
                                  Experience required {' - '}
                                  {data.experience}
                                </h5>
                                <p className="mt-2">{data.description}</p>
                                <button
                                  onClick={() =>
                                    this.setState({
                                      apply: true,
                                      domain: data.title,
                                      showCareerIcon: false
                                    })
                                  }
                                  className="btn btn-shot mt-5"
                                >
                                  Apply
                                </button>
                              </div>{' '}
                            </Collapse>
                          </div>
                        ))
                      : null}
                    {this.state.readerLength > 3 ? (
                      <div className="d-flex justify-content-between">
                        <button
                          hidden={this.state.readerLength >= this.state.more}
                          className="btn btn-shot mt-5 bg-white-color"
                          onClick={() => this.setLess()}
                        >
                          Previous
                        </button>
                        <button
                          hidden={this.state.readerLength <= this.state.more}
                          className="btn btn-shot mt-5 bg-white-color"
                          onClick={() => this.setMore()}
                        >
                          Next
                        </button>
                      </div>
                    ) : null}

                    <div className="mt-5">{this.state.noJobsEmessage}</div>

                    {/* <li className="opening row">
                    <div className="col-md-9 name">
                      <i className="fa fa-picture-o" />
                      Graphic Designer
                    </div>
                    <div className="col-md-2">
                      <div className="time">Internship</div>
                    </div>
                    <div className="col-md-1" onClick={() => this.setState({viewGraphicDesignerJob:true,showCareer:false})}>
                      <a href="#" className="view-button" data-toggle="collapse" data-target="#GD" onClick={() => this.setState({apply:false})}>View</a>
                    </div>
                  </li> */}
                    {/* <div id="GD" className="col-md-12 description mt-5 collapse">
                  <h2 className="name">Graphic Designer</h2>
                  <p className="mt-2">Suspendisse laoreet consequat dui eu aliquam. Suspendisse venenatis purus a luctus gravida. Donec placerat odio elit, quis dictum magna egestas bibendum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent et orci venenatis, ultrices enim vel, porta metus. Curabitur vehicula eget ipsum sit amet accumsan. Nulla pretium iaculis ullamcorper. Pellentesque dignissim sollicitudin mauris sollicitudin semper. Sed vel orci at metus volutpat mattis. Mauris egestas mauris elit, non aliquet est ullamcorper vel. Donec rutrum laoreet nunc ut dapibus. Curabitur eget velit ut sem elementum semper. Nullam maximus quis magna et faucibus. Nullam eu elementum leo.</p>
                  <button onClick={() => this.setState({apply:true})} className="btn btn-shot mt-5">Apply</button>
                </div> */}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      upLoadCareerInfo: formData => upLoadCareerInfo(formData),
      fetchJobList: formData => fetchJobList(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    careerDataUplodLoader: state.jobPortal.careerDataUplodLoader,
    careerDataUploadResponse: state.jobPortal.careerDataUploadResponse,
    reader: state.jobPortal.reader,
    jobListLoader: state.jobPortal.jobListLoader
  }),
  mapDispatchToProps
)(JobPortal);

// <div
//   className="d-flex justify-content-center w-100 h-100 mx-auto flex-column features animated fadeIn delay-0s section"
//   data-anchor="section6"
//   id="landing-products"
// >
{
  /*  <div className="space bg-light mb-auto" /> */
}
/* <main>
          <div className="row no-gutters row-eq-height bg-light">
            <div className="col-md-4 side-animated bg-dark">
              <div
                id="jobForm"
                className="container row h-100 py-3 align-items-center justify-content-center"
              >
                <div className="row no-gutters w-75">
                  <div hidden={!this.state.success} className="col-md-12">
                    <h2 className="name mb-3">Jusy is out. verdict soon!</h2>
                  </div>
                  <div hidden={this.state.success} className="col-md-12">
                    <h2 className="name">Let's create a job for you</h2>
                    <div className="form-group mt-5">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        aria-describedby="emailHelp"
                        onChange={e => this.setState({ name: e.target.value })}
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group mt-5">
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Email ID"
                        onChange={e => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className="form-group mt-5">
                      <select
                        name="domain"
                        id="domain"
                        onChange={e =>
                          this.setState({ domain: e.target.value })
                        }
                      >
                        <option value="Engineering">Engineering</option>
                        <option value="Design">Design</option>
                        <option value="Marketing">Marketing</option>
                      </select>
                    </div>
                    <div className="form-group mt-5">
                      <textarea
                        id="message"
                        className="form-control"
                        rows={2}
                        placeholder="Message"
                        defaultValue={''}
                        onChange={e =>
                          this.setState({ message: e.target.value })
                        }
                      />
                    </div>
                    <p>{this.state.emessage}</p>
                    <button
                      type="submit"
                      id="btn"
                      className="btn btn-shot mt-5"
                      onClick={this.handleSubmit}
                    >
                      Take a shot
                    </button>
                  </div>
                  <div className="col-md-12 mt-10">
                    <ul className="social-icons a-r">
                      <li className="ml-auto">
                        <a href="https://www.facebook.com/throughbit">
                          <i className="fa fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/throughbit">
                          <i className="fa fa-twitter" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.medium.com/company/throughbit">
                          <i className="fa fa-medium" aria-hidden="true" />
                        </a>
                      </li>
                      <a href="#" />
                      <li>
                        <a href="https://www.linkedin.com/company/throughbit">
                          <i className="fa fa-linkedin-square" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="container products-desc w-75">
                <ul className="nav team">
                  {/* <li className="nav-item">
<a className="nav-link"  href="#section5">Team</a>
</li> */
/* <li className="nav-item">
                    <a className="nav-link active fs-3" href="#section6">
                      Career
                    </a>
                  </li>
                </ul> */
/* <ul className="container openings mt-5">
<li className="opening row">
    <div className="col-md-9 name">
    <i className="fa fa-code"></i>
    Frontend Developer
    </div>
    <div className="col-md-2">
    <div className="time">Full time</div>
    </div>
    <div className="col-md-1">
    <a id="frontEndDeveloperJobForm" className="view-button"  href="#">
        View
    </a>
    </div>
</li>
<li className="opening row">
    <div className="col-md-9 name">
    <i className="fa fa-picture-o"></i>
    Graphic Designer
    </div>
    <div className="col-md-2">
    <div className="time">Internship</div>
    </div>
    <div className="col-md-1">
    <a href="#section8" className="view-button">
        View
    </a>
    </div>
</li>
</ul> */
/* </div>
            </div>
          </div>
        </main> */
