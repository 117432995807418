import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const RECENT_TRADE_SCROLL_SUCCESS = 'RECENT_TRADE_SCROLL_SUCCESS';
const RECENT_TRADE_SUCCESS = 'RECENT_TRADE_SUCCESS';
const RECENT_TRADE_REQUESTED = 'RECENT_TRADE_REQUESTED';
const UPDATE_RECENT_TRADE = 'UPDATE_RECENT_TRADE';
const ALL_MARKET_COIN_24_HR_PRICE_SUCCESS =
  'ALL_MARKET_COIN_24_HR_PRICE_SUCCESS';
const ALL_MARKET_COIN_24_HR_PRICE_REQUESTED =
  'ALL_MARKET_COIN_24_HR_PRICE_REQUESTED';
const OPEN_ORDER_ACTIVE_TAB_UPDATE = 'OPEN_ORDER_ACTIVE_TAB_UPDATE';
const initialState = {
  getRecentTradeList: { data: [] },
  recentTradeUpdateObject: {},
  activeTab: '1'
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case ALL_MARKET_COIN_24_HR_PRICE_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case ALL_MARKET_COIN_24_HR_PRICE_SUCCESS:
      return {
        ...state,
        marketCoin24HrPriceList: action.marketCoinPrice,
        marketViewLoader: false
      };
    case RECENT_TRADE_REQUESTED:
      return {
        ...state,
        recentTradeLoader: true
      };
    case RECENT_TRADE_SUCCESS:
      return {
        ...state,
        recentTradeLoader: false,
        getRecentTradeList: action.response
      };
    case RECENT_TRADE_SCROLL_SUCCESS:
      return {
        ...state,
        getRecentTradeList: action.response
      };

    case UPDATE_RECENT_TRADE:
      const recentTradeList = state.getRecentTradeList.data;

      recentTradeList.unshift({
        executionPrice: action.jsonObj.recentTradeUpdateObject.price.toFixed(8),
        operation: action.jsonObj.recentTradeUpdateObject.operation,
        volumeCoin: action.jsonObj.recentTradeUpdateObject.volume.toFixed(8),
        volumeMarket: (
          action.jsonObj.recentTradeUpdateObject.price *
          action.jsonObj.recentTradeUpdateObject.volume
        ).toFixed(8)
      });

      return {
        ...state,
        getRecentTradeList: {
          ...state.getRecentTradeList,
          data: recentTradeList
        }
        // getRecentTradeList: recentTradeList
      };
    case OPEN_ORDER_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    default:
      return state;
  }
};

export const getRecentTrades = formData => dispatch => {
  dispatch({
    type: RECENT_TRADE_REQUESTED
  });
  // apiCaller(`liveorders/executed/1`, 'get', '', false, true, false).then(
  apiCaller(
    `order/executed/${formData.market}/${formData.coin}/${formData.skip}/${formData.limit}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response =>
    dispatch({
      type: RECENT_TRADE_SUCCESS,
      response
    })
  );
};

export const getScrollRecentTrades = formData => dispatch => {
  apiCaller(
    `order/executed/${formData.market}/${formData.coin}/${formData.skip}/${formData.limit}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response =>
    dispatch({
      type: RECENT_TRADE_SCROLL_SUCCESS,
      response
    })
  );
};

export const updateRecentTrades = jsonObj => dispatch => {
  dispatch({
    type: UPDATE_RECENT_TRADE,
    jsonObj
  });
};

export const allMarketCoin24HrPriceList = formData => dispatch => {
  dispatch({
    type: ALL_MARKET_COIN_24_HR_PRICE_REQUESTED
  });
  apiCaller('list/price/', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        // var marketCoinPrice = {};
        // _.map(response, obj => {
        //   if (!marketCoinPrice[obj.marketId]) {
        //     marketCoinPrice[obj.marketId] = {};
        //   }
        // 	marketCoinPrice[obj.marketId][obj.coinId] = obj;
        // });

        dispatch({
          type: ALL_MARKET_COIN_24_HR_PRICE_SUCCESS,
          marketCoinPrice: response
        });
      }
    }
  );
};
export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};
