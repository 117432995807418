/**
 * Buy Crypto Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import Cookies from 'universal-cookie';

// import { logout } from 'Modules/signin/signinReducer';

import BuyForms from './buy';
import SellForms from './sell';

import ErrorBoundary from 'Modules/ErrorBoundary';

/**
 * Represents a Buy Crypto Class.
 * @class extends React.Component
 */
class MarketForms extends React.Component {
  render() {
    return (
      <div className="row no-gutters calculator-container">
        <ErrorBoundary>
          <BuyForms
            activeMarketValue={this.props.activeMarketValue}
            activeMarketSymbol={this.props.activeMarketSymbol}
            activeCurrencyValue={this.props.activeCurrencyValue}
            activeCurrencySymbol={this.props.activeCurrencySymbol}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <SellForms
            activeMarketValue={this.props.activeMarketValue}
            activeMarketSymbol={this.props.activeMarketSymbol}
            activeCurrencyValue={this.props.activeCurrencyValue}
            activeCurrencySymbol={this.props.activeCurrencySymbol}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     // currencyBuy: state.orderForms.currencyBuy,
//     // volumeBuy: state.orderForms.volumeBuy,
//     // bidBuy: state.orderForms.bidBuy,
//     // operationBuy: state.orderForms.operationBuy,
//     // totalBuy: state.orderForms.totalBuy,
//     // response: state.orderForms.response,
//     // buyFormModal: state.orderForms.buyFormModal
//   };
// };

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       // changePage: page => push(page)
//     },
//     dispatch
//   );

export default connect()(MarketForms);
// mapStateToProps,
// mapDispatchToProps
