import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const CAREER_INFO_UPLOAD_REQUESTED = 'CAREER_INFO_UPLOAD_REQUESTED';
const CAREER_INFO_UPLOAD_SUCCESS = 'CAREER_INFO_UPLOAD_SUCCESS';
const JOB_LIST_REQUESTED = 'JOB_LIST_REQUESTED';
const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS';
const initialState = {
  careerDataUplodLoader: true,
  carrerDataUplodResponse: {},
  reader: {},
  jobListLoader: true
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case CAREER_INFO_UPLOAD_REQUESTED:
      return {
        ...state,
        careerDataUplodLoader: true
      };
    case CAREER_INFO_UPLOAD_SUCCESS:
      return {
        ...state,
        portfcareerDataUplodLoaderolioLoader: false,
        careerDataUploadResponse: action.response
      };
    case CAREER_INFO_UPLOAD_REQUESTED:
      return {
        ...state,
        jobListLoader: true
      };
    case JOB_LIST_SUCCESS:
      return {
        ...state,
        jobListLoader: false,
        reader: action.response
      };
    default:
      return state;
  }
};

export const upLoadCareerInfo = formData => dispatch => {
  dispatch({
    type: CAREER_INFO_UPLOAD_REQUESTED
  });
  var url = `mail/career`;
  apiCaller(url, 'post', formData, false, true, false).then(response => {
    dispatch({
      type: CAREER_INFO_UPLOAD_SUCCESS,
      response
    });
  });
};
export const fetchJobList = formData => dispatch => {
  dispatch({
    type: JOB_LIST_REQUESTED
  });
  var url = `job/list`;
  apiCaller(url, 'get', '', false, true, false).then(response => {
    dispatch({
      type: JOB_LIST_SUCCESS,
      response
    });
  });
};
