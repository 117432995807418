import React, { Component } from 'react';
import './initial-idea.css';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Fullpage from './fullpage';
import FooterModal from 'App/footerModal';
import 'App/footer.css';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

let wid = window.outerWidth;
// console.log('wid is' + wid);
class Loyalty extends Component {
  constructor(props) {
    super(props);

    this.state = { modal1: false, key1: '' };
    this.child = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal1: !prevState.modal
    }));
    this.props.disableScroll(true);
    this.child.current.set('loyalty');
  }

  untoggle = () => {
    this.setState(prevState => ({
      modal1: '',
      key1: ''
    }));
    this.props.disableScroll(false);
  };

  render() {
    if (wid > 768) {
      return (
        <>
          <FooterModal
            untoggle={this.untoggle}
            ref={this.child}
            modall={this.state.modal1}
          />
          <main>
            <div className="row no-gutters row-eq-height bg-light">
              <div
                className="col-md-8 col-sm-12 align-self-center "
                id="loyalty"
              >
                <div className="container products-desc w-75">
                  <h1 className="pt-1r">Loyalty</h1>
                  <p className="mt-5 ">
                    “Loyalty is a two-way street. If we are asking it from you,
                    then you are getting it from us”.
                  </p>
                  <p className="mt-2">
                    At ThroughBit, we take Loyalty very seriously. The
                    ThroughBit Loyalty Program(TLP) has been designed to give
                    our users a sense of belonging and ownership. It has been
                    designed such that every time ThroughBit earns something,
                    the user who was instrumental in making this earning happen
                    will also stand to earn something. This is our way of giving
                    back. This is our way of being loyal to you.
                  </p>
                  <p className="mt-2">
                    In the following few lines, you will be understand how the
                    program works.
                  </p>
                  <p className="mt-2 ">
                    TLP is a simple referral module where users who wish to
                    refer ThroughBit to any of their friends and family will be
                    rewarded with a certain portion of the transaction fee that
                    ThroughBit earns from the transactions that these referred
                    users conduct.{' '}
                  </p>

                  <p className="mt-2">
                    On signing up, completing KYC and getting the account
                    verified, every user will be given a unique ‘Referral Code’,
                    which the user can share to be used when a ‘referred’ user
                    signs up on ThroughBit. Subsequently, on the first
                    transaction the ‘referred’ user makes, the user gets a 20%
                    discount on the transaction fee and the ‘referee’ stands to
                    make 20% of the ‘after discount’ transaction fee. On every
                    subsequent transaction carried out by the ‘referred’ user
                    the ‘referee’ stands to make 20% of the transaction fee.{' '}
                  </p>
                  <p className="mt-2 ">
                    There are no limits on how many users can be referred by a
                    single ‘referee’.
                  </p>
                  <a
                    className="pr-5"
                    href="#"
                    style={{ color: '#fff' }}
                    onClick={this.toggle}
                  >
                    <dt>Know More</dt>
                  </a>
                </div>
              </div>
              <div className="col-md-4 side-animated bg-dark">
                <div className="container row h-100 py-3 align-items-center justify-content-center">
                  <div className="row no-gutters">
                    <div className="col-md-12">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/-e-Rectangle 17 copy 3.png`}
                      />
                    </div>

                    {/* 		<div className="col-md-12 mt-5">
		<a href="#" className="btn btn-markets" role="button">View markets</a>
		</div> */}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    } else {
      return (
        <>
          <FooterModal
            ref={this.child}
            untoggle={this.untoggle}
            modall={this.state.modal}
          />
          <main>
            <div className="row no-gutters row-eq-height bg-light" id="loyalty">
              <div className="col-md-8 col-sm-12 align-self-center  webkit-height">
                <div className="container products-desc ">
                  <h1 className="pt-1r">Loyalty</h1>
                  <p className="mt-5 ">
                    Loyalty is a two-way street. If we are asking it from you,
                    then you are getting it from us.
                  </p>
                  <p>
                    At ThroughBit, we take Loyalty very seriously. The
                    ThroughBit Loyalty Program(TLP) has been designed to give
                    our users a sense of belonging and ownership. It has been
                    designed such that every time ThroughBit earns something,
                    the user who was instrumental in making this earning happen
                    will also stand to earn something. This is our way of giving
                    back. This is our way of being loyal to you.
                  </p>

                  <a
                    className="pr-5"
                    href="#"
                    style={{ color: '#fff' }}
                    onClick={this.toggle}
                  >
                    <dt>Know More</dt>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </>
      );
    }
  }
}

export default Loyalty;
