import React from 'react';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import ErrorBoundary from 'Modules/ErrorBoundary';
import helpers from 'Utils/sortingHelper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UncontrolledTooltip } from 'reactstrap';

import {
  // selectedCurrencyUpdate,
  getWithDrawHistory,
  autosuggestCurrencyIdUpdate,
  limitUpdate,
  setData,
  setCount
} from './withdrawDepositReducer';
import moment from 'moment';
import Pagination from 'Modules/paginationComponent/pagination';

class WithdrawDepositHistory extends React.Component {
  constructor(props) {
    super(props);
    //this.toggle = this.toggle.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.state = {
      getWithDrawHistoryList: [],
      searchData: [],
      withdrawHistoryLoader: true,
      error: '',
      showMessage: false,
      message: '',
      txnid: '',
      currency: 'BTC',
      coinSymbolsObj: this.props.coinSymbolsObj,
      selectedCurrencyIdToShow: '',
      selectedCurrency: this.props.selectedCurrency,
      autosuggestCurrencyId: this.props.autosuggestCurrencyId,
      windowHeight: window.innerHeight - 120,
      sortingCol: ''
    };
  }

  onChangePage(pager) {
    this.props.getWithDrawHistory({
      operation: 'combined',
      skip: pager.startIndex,
      limit: this.props.limit
      /*  "start": "2018-01-20",
     "end": "2019-12-30",
     "coinId": "1" */
    });
    // update state with new page of items
    /*   this.setState({
       pageOfItems: pageOfItems ,
       searchData:pageOfItems
    }); */
  }
  componentDidMount() {
    const limit = parseInt((this.state.windowHeight * 0.6) / 1 / 24.9);
    this.props.limitUpdate({ limit });
    this.props.getWithDrawHistory({
      operation: 'combined',
      skip: 0,
      limit: limit
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.withdrawHistoryLoader !== nextProps.withdrawHistoryLoader) {
      this.setState({ withdrawHistoryLoader: nextProps.withdrawHistoryLoader });
    }

    if (this.props.limit !== nextProps.limit) {
      this.setState({ limit: nextProps.limit });
    }

    if (
      this.props.getWithDrawHistoryList !== nextProps.getWithDrawHistoryList
    ) {
      if (nextProps.getWithDrawHistoryList.error === 0) {
        if (nextProps.getWithDrawHistoryList.length !== 0) {
          this.setState({
            withDrawHistoryList: nextProps.getWithDrawHistoryList.data,
            searchData: nextProps.getWithDrawHistoryList.data,
            count: nextProps.getWithDrawHistoryList.count,
            error: null
          });
          this.props.setCount({
            count: nextProps.getWithDrawHistoryList.count
          });
        } else {
          this.setState({
            withDrawHistoryList: [],
            searchData: [],
            message: 'No Withdrawals',
            error: null
          });
        }
      } else {
        this.setState({
          withDrawHistoryList: null,
          searchData: null,
          error: nextProps.getWithDrawHistoryList.message
        });
      }
    }

    // if (
    //   Array.isArray(nextProps.getWithDrawHistoryList) &&
    //   nextProps.getWithDrawHistoryList !== undefined &&
    //   this.props.getWithDrawHistoryList !== nextProps.getWithDrawHistoryList
    // ) {
    //   if (nextProps.getWithDrawHistoryList.length !== 0) {
    //     this.setState({
    //       withDrawHistoryList: nextProps.getWithDrawHistoryList,
    //       searchData: nextProps.getWithDrawHistoryList
    //     });
    //   } else {
    //     this.setState({
    //       withDrawHistoryList: [],
    //       searchData: [],
    //       message: 'No Withdrawals'
    //     });
    //   }
    // } else if (
    //   !Array.isArray(nextProps.getWithDrawHistoryList) &&
    //   !nextProps.getWithDrawHistoryList.error
    // ) {
    //   this.setState({ error: nextProps.getWithDrawHistoryList });
    // } else if (
    //   !Array.isArray(nextProps.getWithDrawHistoryList) &&
    //   nextProps.getWithDrawHistoryList.error
    // ) {
    //   if (nextProps.getWithDrawHistoryList.error === 2) {
    //   }
    //   this.setState({ error: nextProps.getWithDrawHistoryList.message });
    // }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.selectedCurrency !== nextProps.selectedCurrency) {
      this.setState({ selectedCurrency: nextProps.selectedCurrency });
    }

    /* if (this.props.autosuggestCurrencyId !== nextProps.autosuggestCurrencyId) {
      this.setState(
        {
          autosuggestCurrencyId: nextProps.autosuggestCurrencyId
        },
        this.changeSelectedCurrencyData
      );
    } */
    /*   if (this.props.searchValue != nextProps.searchValue) {
      this.setState({
        searchData: this.searchInList(
          nextProps.getWithDrawHistoryList,
          nextProps.searchValue
        )
      });
		} */

    if (this.props.autosuggestCurrencyId !== nextProps.autosuggestCurrencyId) {
      this.setState({
        autosuggestCurrencyId: nextProps.autosuggestCurrencyId
      });
    }
  }
  sortColumn(type, columnName) {
    var { sortingCol, searchData, sortType } = this.state;
    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    const wdHistoryListSort = helpers.sortColumn(
      searchData,
      type,
      sortingCol,
      sortType
    );
    this.setState({
      sortingCol,
      sortType,
      searchData: wdHistoryListSort
    });
  }
  changeSelectedCurrencyData() {
    const { autosuggestCurrencyId, withDrawHistoryList } = this.state;
    if (autosuggestCurrencyId >= 0) {
      const searchData = _.filter(withDrawHistoryList, obj => {
        if (obj.coinId === autosuggestCurrencyId) {
          return obj;
        }
      });
      this.setState({ searchData: searchData });
    } else {
      this.setState({ searchData: withDrawHistoryList });
    }
  }
  componentWillUnmount() {
    this.props.autosuggestCurrencyIdUpdate({
      autosuggestCurrencyId: ''
    });
    this.props.setCount({
      count: ''
    });
  }

  render() {
    const {
      error,
      withdrawHistoryLoader,
      withDrawHistoryList,
      selectedCurrencyIdToShow,
      coinSymbolsObj,
      searchData,
      sortingCol,
      sortType
    } = this.state;

    this.props.setData({
      searchData: searchData
    });
    if (error === 'Unable to authenticate') {
      return <div>{error}</div>;
    }

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (withdrawHistoryLoader) {
      return <div className="loading-o" />;
    }
    return (
      <div
        className="container-fluid card-box mt-1 wd-history"
        style={{ height: window.innerHeight - 175 }}
      >
        {searchData && searchData.length !== 0 ? (
          <table className="table data-table table-fixed mb-0">
            <thead>
              <tr>
                <th scope="col" className="faded">
                  Name
                </th>
                <th scope="col" className="faded" style={{ width: '5%' }}>
                  Symbol
                </th>
                <th scope="col" className="faded">
                  Type
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'amount')}
                >
                  Amount{' '}
                  {sortingCol === 'amount' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'withdrawBTCValue')}
                >
                  Value in BTC{' '}
                  {sortingCol === 'withdrawBTCValue' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th scope="col" className="faded">
                  Fee
                </th>
                <th scope="col" className="faded">
                  Txn. Id
                </th>
                <th scope="col" className="faded">
                  Status
                </th>
                <th
                  scope="col"
                  className="faded"
                  onClick={() => this.sortColumn('date', 'transactionTime')}
                  style={{ width: '17.22%', cursor: 'pointer' }}
                >
                  Date{' '}
                  {sortingCol === 'transactionTime' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
              </tr>
            </thead>
            <tbody style={{ height: window.innerHeight - 220 }}>
              {_.keys(this.state.coinSymbolsObj).length > 0 &&
                searchData.map((o, i) => (
                  <tr
                    key={i}
                    className={
                      parseInt(selectedCurrencyIdToShow) === o.coinId
                        ? 'selected-table-row'
                        : undefined
                    }
                  >
                    <td className="coin-name">
                      {`${coinSymbolsObj[o.coinId].coinname}`}
                    </td>
                    <td style={{ width: '5%' }}>
                      {`${coinSymbolsObj[o.coinId].coinsymbol}`}
                    </td>
                    <td
                      className={
                        o.type == 'deposit' ? 'negative-text' : 'positive-text'
                      }
                    >
                      {o.type}
                    </td>
                    <td>{parseFloat(o.amount).toFixed(8)}</td>
                    <td>{parseFloat(o.BTCValue).toFixed(8)}</td>
                    <td>
                      {o.fee ? parseFloat(o.fee).toFixed(8) : '0.00000000'}{' '}
                      <span className="currency">
                        {`${this.state.coinSymbolsObj[o.coinId].coinsymbol}`}{' '}
                      </span>
                    </td>
                    <td>
                      <div id={`tooltip-${i}`}>
                        {o.transactionId
                          ? o.transactionId.substring(0, 6) + '... '
                          : 'waiting'}

                        {o.transactionId && (
                          <CopyToClipboard
                            text={o.transactionId}
                            onCopy={() => this.setState({ copied: true })}
                          >
                            <i
                              className="fa fa-clipboard crsrPntr"
                              aria-hidden="true"
                            />
                          </CopyToClipboard>
                        )}
                      </div>
                      {o.transactionId && (
                        <UncontrolledTooltip
                          placement="top"
                          target={`tooltip-${i}`}
                          delay={{ hide: -1 }}
                        >
                          {o.transactionId}
                        </UncontrolledTooltip>
                      )}
                    </td>
                    <td
                      className={o.complete ? 'completed-text' : 'pending-text'}
                    >
                      {o.complete ? 'Completed' : 'Pending'}
                    </td>
                    <td style={{ width: '17.22%' }}>{`${moment(
                      o.transactionTime
                    )
                      .utc()
                      .format('HH:mm:ss')} GMT ${moment(o.transactionTime)
                      .utc()
                      .format('DD/MM/YYYY')}`}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div>No withdrawals or deposits.</div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getWithDrawHistory: formData => getWithDrawHistory(formData),
      setData: formData => setData(formData),
      setCount: formData => setCount(formData),
      limitUpdate: formData => limitUpdate(formData),
      autosuggestCurrencyIdUpdate: formData =>
        autosuggestCurrencyIdUpdate(formData)
    },
    dispatch
  );
export default connect(
  state => ({
    withdrawHistoryLoader: state.transactions.withdrawHistoryLoader,
    getWithDrawHistoryList: state.transactions.getWithDrawHistoryList,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    activeTab: state.transactions.activeTab,
    coinSymbolsObj: state.app.coinSymbolsObj,
    isLoggedIn: state.signin.isLoggedIn,
    autosuggestCurrencyId: state.transactions.autosuggestCurrencyId,
    selectedCurrency: state.transactions.selectedCurrency,
    limit: state.transactions.limit
  }),
  mapDispatchToProps
)(WithdrawDepositHistory);
