/**
 * Change Password Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { changePassword } from '../settingsReducer';
import { logout, fromPWDChange } from 'Modules/signin/signinReducer';
import removeCookie from 'Utils/removeCookie';
import { Popover, PopoverBody } from 'reactstrap';

const cookies = new Cookies();
let email = cookies.get('email');

const newPassPattern = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/; // Minimum eight characters, at least one letter and one number
// const newPassPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}/;  // Minimum eight characters, at least one uppercase letter, one lowercase letter and one number

/**
 * Represents a SecurityPassword Class.
 * @class extends React.Component
 */

class SecurityPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      passPatternValid: true,
      error: '',
      popOverOpen: false,
      responseMessage: '',
      changePwdLoader: false,
      changePwdResponse: ''
    };
    this.handleResponse = this.handleResponse.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
  }

  togglePopOver(e) {
    e.preventDefault();
    this.setState({
      popOverOpen: !this.state.popOverOpen
    });
  }

  componentWillReceiveProps(nextProps) {
    //loader
    if (this.props.changePwdLoader !== nextProps.changePwdLoader) {
      this.setState({ changePwdLoader: nextProps.changePwdLoader });
    }
    // handle Response
    if (
      this.props.changePwdResponse !== undefined &&
      this.props.changePwdResponse !== nextProps.changePwdResponse
    ) {
      this.handleResponse(nextProps.changePwdResponse);
      this.setState({ changePwdResponse: nextProps.changePwdResponse });
    }

    if (nextProps.profile !== undefined) {
      email = email || nextProps.profile.email;
    }
  }
  // componentWillUnmount(){
  //   this.props.fromPWDChange({
  //     flag:false
  //   });
  // }
  /**
   * Handle Change password Chnage Response
   * @function
   * @param {object} => response Data from the Api
   */

  handleResponse = response => {
    if (response.error === 0) {
      removeCookie();
      this.props.logout({ email });
      //When You call the ChangePassword Function on Success Update the response message as success and set the jwt
      this.props.fromPWDChange({
        flag: true
      });
      this.setState({ error: '' });
      this.setState(
        { responseMessage: response.message },
        this.redirectToLogin
      );
    } else {
      // If unAuthorized Login
      this.setState({ responseMessage: '' });
      this.setState({ error: response.message });
    }
  };

  redirectToLogin() {
    setTimeout(() => {
      if (this.props.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }, 2000);
  }

  /**
   * Calls Change Passwrod Api
   * @function
   * @param {object} => {email, oldPassword, newpassword}
   */
  handleChangePassword = e => {
    e.preventDefault();
    // if (newPassPattern.test(this.state.newPassword)) {
    if (this.state.newPassword === this.state.confirmPassword) {
      if (this.state.newPassword !== this.state.currentPassword) {
        this.props.changePassword({
          old_password: this.state.currentPassword,
          new_password: this.state.newPassword
        });
      } else {
        this.setState({
          error: 'New Pasword has to be diffrent from Old Password'
        });
      }
    } else {
      this.setState({ error: 'New and confirm password do not match' });
    }
    // } else {
    // 	this.setState({ passPatternValid: false });
    // }
  };

  render() {
    return (
      <div className="container-fluid mt-5">
        <div className="row no-gutters justify-content-center settings-form">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="title">Change Password</div>
            <form
              onSubmit={
                this.state.currentPassword !== '' &&
                this.state.newPassword !== '' &&
                this.state.confirmPassword !== '' &&
                !this.state.changePwdLoader
                  ? this.handleChangePassword
                  : undefined
              }
            >
              <div className="form-group">
                <label />
                <input
                  className="form-control"
                  type="password"
                  // id="currentPassword"
                  placeholder="Current password"
                  value={this.state.currentPassword}
                  onChange={e =>
                    this.setState({ currentPassword: e.target.value })
                  }
                  // name="currentPassword"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label />
                <input
                  className="form-control"
                  type="password"
                  // id="newPassword"
                  placeholder="New password"
                  value={this.state.newPassword}
                  onChange={e => this.setState({ newPassword: e.target.value })}
                  // name="newPassword"
                  autoComplete="off"
                  required={true}
                />

                {!this.state.passPatternValid ? (
                  <div className="error-details">
                    <p style={{ color: '#f3136a' }}>
                      Password should contain minimum 8 characters & at least
                      one uppercase letter, one lowercase letter and one number
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label />
                <input
                  className="form-control"
                  type="password"
                  // id="confirmPassword"
                  placeholder="Confirm password"
                  value={this.state.confirmPassword}
                  onChange={e =>
                    this.setState({ confirmPassword: e.target.value })
                  }
                  // name="confirmPassword"
                  autoComplete="off"
                  required={true}
                />

                {this.state.error !== '' ? (
                  <div className="error-details">{this.state.error}</div>
                ) : null}

                {this.state.responseMessage !== '' ? (
                  <div className="acc-details">
                    {this.state.responseMessage}
                  </div>
                ) : null}
              </div>

              {/* <button
                type="submit"
                className="btn btn-blue"
                onSubmit={this.handleChangePassword}
                disabled={
                  this.state.currentPassword === '' ||
                  this.state.newPassword === '' ||
                  this.state.confirmPassword === ''
                }
              >
                Change
              </button> */}

              {(this.state.currentPassword === '' ||
                this.state.newPassword === '' ||
                this.state.confirmPassword === '') && (
                <>
                  <button
                    type="submit"
                    className="btn btn-blue"
                    id="Popover1"
                    onClick={this.togglePopOver}
                  >
                    Change
                  </button>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.popOverOpen}
                    target="Popover1"
                    toggle={this.togglePopOver}
                  >
                    <PopoverBody>
                      Please enter Current Password, New Password and Confirm
                      Password.
                    </PopoverBody>
                  </Popover>
                </>
              )}

              {this.state.currentPassword !== '' &&
                this.state.newPassword !== '' &&
                this.state.confirmPassword !== '' &&
                (!this.state.changePwdLoader ? (
                  <button
                    className="btn btn-blue"
                    onSubmit={this.handleChangePassword}
                  >
                    Change
                  </button>
                ) : (
                  <button className="btn btn-primary btn-block">
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  </button>
                ))}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword: formData => changePassword(formData),
      changePage: page => push(page),
      logout: formData => logout(formData),
      fromPWDChange: formData => fromPWDChange(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    changePwdLoader: state.settings.changePwdLoader,
    changePwdResponse: state.settings.changePwdResponse
  }),
  mapDispatchToProps
)(SecurityPassword);
