import React from 'react';
import { push } from 'connected-react-router';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';
import { headerActiveMenuUpdate, allCoinsSymbolData } from 'App/appReducer';
import Dropdown from 'react-dropdown';
import SVG from 'react-inlinesvg';

import { activeTabUpdate, coinMarketPrice } from './portFolioReducer';

class CurrencySummaryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetchMarketValue = this.fetchMarketValue.bind(this);
    this.fetchcoinMarkets = this.fetchcoinMarkets.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this.state = {
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      portfolioList: this.props.portfolioList,
      portfolioLoader: this.props.portfolioLoader,
      coinMarketPriceData: this.props.coinMarketPriceData,
      coinSymbolsObj: this.props.coinSymbolsObj,
      marketId: this.props.marketId,
      error: '',
      marketsInDropdown: {},
      // selected: { value: 0, label: 'BTC' },
      marketCoinPriceValue: {}
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  /*  componentWillMount() {
    this.props.headerActiveMenuUpdate('openOrders');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
  } */
  componentDidMount() {
    // this.setState({
    //   coinSymbolsObj: this.props.coinSymbolsObj,
    //   portfolioList: this.props.portfolioList
    // }, this.fetchcoinMarkets);
    this.fetchcoinMarkets();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }

    if (this.props.portfolioLoader !== nextProps.portfolioLoader) {
      this.setState({
        portfolioLoader: nextProps.portfolioLoader
      });
    }
    if (this.props.marketId !== nextProps.marketId) {
      this.setState({ marketId: nextProps.marketId });
    }
    if (this.props.coinsMarketsListObj !== nextProps.coinsMarketsListObj) {
      this.setState(
        { coinsMarketsListObj: nextProps.coinsMarketsListObj },
        this.fetchcoinMarkets
      );
    }
    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }
    if (this.props.coinMarketPriceData !== nextProps.coinMarketPriceData) {
      this.setState(
        { coinMarketPriceData: nextProps.coinMarketPriceData },
        this.coinPriceDataFunc
      );
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState(
        { coinSymbolsObj: nextProps.coinSymbolsObj },
        this.fetchcoinMarkets
      );
    }
    if (this.props.portfolioList !== nextProps.portfolioList) {
      if (
        nextProps.portfolioList.error === 0 &&
        nextProps.portfolioList.data.coins.length != 0
      ) {
        this.setState(
          { portfolioList: nextProps.portfolioList.data.coins },
          this.fetchcoinMarkets
        );
      } else if (nextProps.portfolioList.error === 2) {
        // } else if (nextProps.portfolioList.error === -1) {
        this.props.changePage('/login');
      } else {
        this.setState({
          message: nextProps.portfolioList.message,
          portfolioList: []
        });
      }
    }
  }

  _onSelect(option, o) {
    const coinId = o.coinId;
    const marketId = option.value;
    this.state.selected[coinId] = option;

    if (marketId !== coinId) {
      this.props.coinMarketPrice({ marketId, coinId });
    } else {
      const { marketCoinPriceValue } = this.state;
      delete marketCoinPriceValue[coinId];
      this.setState({ marketCoinPriceValue });
    }
  }

  coinPriceDataFunc() {
    const { coinMarketPriceData, marketCoinPriceValue } = this.state;
    if (coinMarketPriceData.error === 0) {
      const marketId = coinMarketPriceData.data[0].marketId;
      const coinId = coinMarketPriceData.data[0].coinId;
      marketCoinPriceValue[coinId] = {
        balance: coinMarketPriceData.data[0].lastexecutedPrice
      };
      this.setState({ marketCoinPriceValue });
    }
  }

  /*  fetchMarkets(o, coinSymbolsObj) {
    var a = '';
    o.marketOrders.map((m, i) => 
    (a = (m.marketId) ? coinSymbolsObj[m.marketId].coinsymbol)
    );
    return a;
  } */
  /*  fetchMarkets(coinId) {
    const marketsForCurrency = [];
    let mids=[];
    const dropdownItems = [];
    const { coinsMarketsListObj, coinSymbolsObj } = this.state;
    if (coinsMarketsListObj) {
     mids= parseInt(_.keys(coinsMarketsListObj[coinId].markets));
      marketsForCurrency.push(mids);
    }
    if (coinSymbolsObj && marketsForCurrency.length > 0) {
      marketsForCurrency.map((m, i) => {
        dropdownItems.push({
          value:marketsForCurrency[m],
          label: coinSymbolsObj[marketsForCurrency[m]].coinsymbol
        });
      }
      );
      this.setState({
        marketsInDropdown: dropdownItems
      });
    }

    return this.state.marketsInDropdown;
  } */

  fetchcoinMarkets() {
    const { portfolioList, coinsMarketsListObj, coinSymbolsObj } = this.state;
    if (
      coinSymbolsObj &&
      _.keys(coinSymbolsObj).length > 0 &&
      coinsMarketsListObj &&
      portfolioList &&
      portfolioList.length > 0
    ) {
      const dropdownItems = {};
      const { marketId } = this.props;
      const selected = {};
      portfolioList.map(obj => {
        const coinId = obj.coinId;
        selected[coinId] = {
          value: marketId,
          label: coinSymbolsObj[marketId].coinsymbol
        };

        let marketsForCurrency = [];
        dropdownItems[coinId] = [];
        if (coinsMarketsListObj[coinId]) {
          marketsForCurrency = _.keys(coinsMarketsListObj[coinId].markets);
        } else {
          marketsForCurrency.push(coinId);
        }
        if (coinSymbolsObj && marketsForCurrency.length > 0) {
          marketsForCurrency.map(m => {
            dropdownItems[coinId].push({
              value: parseInt(m),
              label: coinSymbolsObj[m].coinsymbol
            });
          });
        }
      });
      this.setState({
        marketsInDropdown: dropdownItems,
        selected
      });
    }
  }
  fetchMarketValue(o) {
    var a = '';
    o.marketOrders.map(m => {
      a = m.value;
    });
    return a == '' ? 0 : a;
  }
  render() {
    const {
      portfolioList,
      portfolioLoader,
      error,
      coinSymbolsObj,
      marketId,
      marketCoinPriceValue,
      selected,
      message
    } = this.state;
    if (portfolioLoader) {
      return (
        <div style={{ height: window.innerHeight - 170 }}>
          <div className="loading-o" />
        </div>
      );
    }

    if (!portfolioLoader && message) {
      return <div style={{ height: window.innerHeight - 170 }}>{message}</div>;
    }

    return (
      <div style={{ height: window.innerHeight - 170, overflowY: 'scroll' }}>
        <div className="row no-gutters mt-2 coin-data-container ml-1 mr-1 pt-2 pb-2">
          {portfolioList && portfolioList.length > 0 ? (
            _.keys(this.state.coinSymbolsObj).length > 0 &&
            portfolioList.map((o, i) => (
              <div
                className="col-lg-2 col-md-12 col-sm-12 coin-main center-text mr-2"
                key={i}
              >
                <div
                  className="coin-line"
                  style={{
                    background: `${
                      this.state.coinSymbolsObj[o.coinId].coincolor
                    }`
                  }}
                />
                <div className="coin-head-details pt-2">
                  <h2 className="title-h">
                    <div className="col-md-12 coin-img-small">
                      {/* <img src="../assets/images/coin-logos/lsk@2x.png" />{' '} */}
                      <SVG
                        src={this.state.coinSymbolsObj[o.coinId].coinimage}
                        className="svg-15"
                      />
                      {`${this.state.coinSymbolsObj[o.coinId].coinname}`}
                      <span className="currency">
                        {' '}
                        {`${this.state.coinSymbolsObj[o.coinId].coinsymbol}`}
                      </span>
                    </div>
                  </h2>
                  <div className="row no-gutters">
                    <ul className="market-coin-info col-md-12 pl-3 pr-3">
                      <li className="row no-gutters">
                        <div className="col-4 text-left">
                          <h3 className="title-h">Available Balance</h3>
                        </div>
                        <div className="col-8 text-right number">
                          {' '}
                          {parseFloat(o.balance).toFixed(8)}{' '}
                          <span className="currency">{`${
                            this.state.coinSymbolsObj[o.coinId].coinsymbol
                          }`}</span>
                        </div>
                      </li>
                      <li className="row no-gutters">
                        <div className="col-4 text-left">
                          <h3 className="title-h">Total Balance</h3>
                        </div>
                        <div className="col-8 text-right number">
                          {parseFloat(o.total).toFixed(8)}{' '}
                          <span className="currency">{`${
                            this.state.coinSymbolsObj[o.coinId].coinsymbol
                          }`}</span>
                        </div>
                      </li>
                      <li className="row no-gutters">
                        <div className="col-4 text-left">
                          <h3 className="title-h">Value</h3>
                        </div>
                        <div className="col-8 text-right number">
                          {marketCoinPriceValue &&
                          marketCoinPriceValue[o.coinId]
                            ? parseFloat(
                                marketCoinPriceValue[o.coinId].balance *
                                  o.balance
                              ).toFixed(8)
                            : parseFloat(o.totalInMarket).toFixed(8)}{' '}
                          {selected && (
                            <Dropdown
                              options={this.state.marketsInDropdown[o.coinId]}
                              onChange={option => this._onSelect(option, o)}
                              value={selected[o.coinId]}
                              placeholder={selected[o.coinId]}
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                    {o.marketOrders && o.marketOrders.length > 0 ? (
                      <ul className="market-coin-info col-md-12 pl-3 pr-3">
                        <li className="row no-gutters title-card">
                          <div className="col-4 text-left">Market</div>
                          <div className="col-8 text-right number">
                            On orders
                          </div>
                        </li>
                        {o.marketOrders.map((m, j) => (
                          <li className="row no-gutters title-card">
                            <div className="col-5 text-left">
                              <span className="market-line btc" />
                              <h3 className="title-h">
                                {`${
                                  this.state.coinSymbolsObj[m.marketId]
                                    .coinsymbol
                                }`}{' '}
                                Market
                              </h3>
                            </div>
                            <div className="col-6 text-right number">
                              {Number(parseFloat(m.value).toFixed(8))}{' '}
                              <span className="currency">{`${
                                this.state.coinSymbolsObj[m.marketId].coinsymbol
                              }`}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="col-4 text-left">
                        <h3 className="title-h">No balance on orders</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No Portfolio</div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      allCoinsSymbolData: formData => allCoinsSymbolData(formData),
      coinMarketPrice: formData => coinMarketPrice(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.portfolio.activeTab,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    coinSymbolsObj: state.app.coinSymbolsObj,
    portfolioLoader: state.portfolio.portfolioLoader,
    portfolioList: state.portfolio.portfolioList,
    coinMarketPriceData: state.portfolio.coinMarketPriceData,
    coinsMarketsListObj: state.app.coinsMarketsListObj
  }),
  mapDispatchToProps
)(CurrencySummaryDetails);
