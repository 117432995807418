import React from 'react';
import _ from 'underscore';
import statusCheck from 'Utils/statusCheck';

export default function tradeHistoryCalculateFunc(data) {
  const calculateData = {};
  _.each(data, obj => {
    const type = statusCheck.buySellStatusCheck(obj.operation);
    if (!calculateData[type]) {
      calculateData[type] = {
        volumeMarket: 0,
        volumeCurrency: 0,
        totalMarket: 0,
        executionBid: 0
      };
    }
    calculateData[type].volumeMarket =
      calculateData[type].volumeMarket + obj.volumeMarket;
    calculateData[type].volumeCurrency =
      calculateData[type].volumeCurrency + obj.volumeCoin;
    calculateData[type].totalMarket =
      calculateData[type].totalMarket + obj.volumeMarket * obj.volumeCoin;
    calculateData[type].executionBid =
      calculateData[type].executionBid + obj.price;
  });

  if (
    calculateData.Buy &&
    calculateData.Sell &&
    calculateData.Buy.volumeCurrency > calculateData.Sell.volumeCurrency
  ) {
    var breakEvenPrice = parseFloat(
      (
        (calculateData.Buy.volumeMarket - calculateData.Sell.volumeMarket) /
        (calculateData.Buy.volumeCurrency - calculateData.Sell.volumeCurrency)
      ).toFixed(8)
    );
  } else {
    var breakEvenPrice = 'N/A';
  }

  const myOrderHistoryBalanceCalcData = {
    totalBuy:
      (calculateData.Buy &&
        parseFloat(calculateData.Buy.volumeCurrency.toFixed(8))) ||
      '0',
    avgBuyPrice:
      (calculateData.Buy &&
        parseFloat(
          (
            calculateData.Buy.volumeMarket / calculateData.Buy.volumeCurrency
          ).toFixed(8)
        )) ||
      '0',
    totalSell:
      (calculateData.Sell &&
        parseFloat(calculateData.Sell.volumeCurrency.toFixed(8))) ||
      '0',
    avgSellPrice:
      (calculateData.Sell &&
        parseFloat(
          (
            calculateData.Sell.volumeMarket / calculateData.Sell.volumeCurrency
          ).toFixed(8)
        )) ||
      '0',
    breakEvenPrice: breakEvenPrice,
    profitLoss:
      (calculateData.Buy &&
        calculateData.Sell &&
        parseFloat(
          (
            calculateData.Sell.volumeMarket - calculateData.Buy.volumeMarket
          ).toFixed(8)
        )) ||
      '0'
  };

  return myOrderHistoryBalanceCalcData;
}
