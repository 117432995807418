import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

const cookie = props => {
  const cookies = new Cookies();
  let email = cookies.get('email');
  let code_2fa = cookies.get('code_2fa');

  const remove = () => {
    window.jwt = undefined;
    // cookies.remove('jwt', {
    //   maxAge: 10800,
    //   path: '/',
    //   secure: true,
    //   httpOnly: true,
    //   sameSite: 'strict'
    // });
    cookies.remove('jwt');
    cookies.remove('email');
    cookies.remove('full_name');
    cookies.remove('code_2fa');
    cookies.remove('avatar');
    cookies.remove('kyc');
    cookies.remove('globalId');
    cookies.remove('kycStatus');
    cookies.remove('phoneStatus');
    cookies.remove('referalCheck');
    cookies.remove('referalId');
    cookies.remove('referredId');

    // console.log('cookies' + JSON.stringify(cookies));
  };

  return <div>{remove()}</div>;
};
export default cookie;
