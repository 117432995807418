/**
 * Main AllMarket Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { searchValueUpdate, coinForCoinInfoFunc } from './marketViewReducer';
import { headerActiveMenuUpdate } from 'App/appReducer';
import {
  getMyBalance,
  myBalanceZeroLoggedOut
} from 'Modules/dashboard/components/myOrders/myOrdersReducer';

import MarketView from './marketView';
import AssetView from './assetView';
import SideBarCoinContent from './sideBarCoinContent';

import ErrorBoundary from 'Modules/ErrorBoundary';

class AllMarket extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      marketActiveTab: this.props.marketActiveTab,
      // coinForCoinInfo: this.props.coinForCoinInfo,
      showCoinInfo: false,
      marketView: true
    };
  }

  componentDidMount() {
    this.props.headerActiveMenuUpdate('markets');
    //this.props.coinForCoinInfoFunc({ coinForCoinInfo: null });
    // if (this.props.isLoggedIn !== true) {
    //   this.props.changePage('/login');
    // }

    if (this.props.isLoggedIn) {
      this.props.getMyBalance();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showCoinInfo !== nextProps.showCoinInfo) {
      this.setState({ showCoinInfo: nextProps.showCoinInfo });
    }

    if (this.props.marketActiveTab !== nextProps.marketActiveTab) {
      this.setState({ marketActiveTab: nextProps.marketActiveTab });
    }

    // if (
    //   this.props.coinForCoinInfo !== nextProps.coinForCoinInfo &&
    //   nextProps.coinForCoinInfo !== null
    // ) {
    //   this.setState({ coinForCoinInfo: nextProps.coinForCoinInfo });
    // }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.myBalanceZeroLoggedOut();
      } else {
        this.props.getMyBalance();
      }
    }

    // if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
    //   if (nextProps.isLoggedIn !== true) {
    //     this.props.changePage('/login');
    //   }
    // }
  }

  handleChange(event) {
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  render() {
    const { marketView, marketActiveTab } = this.state;
    return (
      <div className="row no-gutters">
        <div className="col-lg-9 mt-1 container-one pl-2 pr-1">
          {/* <div className="container-fluid">
            <div className="row no-gutters">
              <div className="col-lg-12">
                <a href="#" className="mr-auto coin-request">
                  Request coin
                </a>
              </div>
            </div>
          </div> */}

          {marketActiveTab === 'listView' && (
            <ErrorBoundary>
              <MarketView />
            </ErrorBoundary>
          )}

          {marketActiveTab === 'assetView' && (
            <ErrorBoundary>
              <AssetView />
            </ErrorBoundary>
          )}
        </div>
        <div className="col-lg-3 mt-1">
          <div id="sidebar-main" className="row no-gutters sidebar-content">
            {/* {coinForCoinInfo && ( */}
            <ErrorBoundary>
              <SideBarCoinContent />
            </ErrorBoundary>
            {/* )} */}
          </div>
        </div>
      </div>

      // <!-- Market view ends -->
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      getMyBalance: formData => getMyBalance(formData),
      myBalanceZeroLoggedOut: formData => myBalanceZeroLoggedOut(formData),
      searchValueUpdate: formData => searchValueUpdate(formData),
      coinForCoinInfoFunc: formData => coinForCoinInfoFunc(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    coinForCoinInfo: state.marketView.coinForCoinInfo,
    marketActiveTab: state.marketView.marketActiveTab,
    searchValue: state.marketView.searchValue,
    showCoinInfo: state.marketView.showCoinInfo,
    currency: state.app.activeCurrency,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile
  }),
  mapDispatchToProps
)(AllMarket);
