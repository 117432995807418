import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const MARKET_VIEW_SUCCESS = 'MARKET_VIEW_SUCCESS';
const MARKET_VIEW_REQUESTED = 'MARKET_VIEW_REQUESTED';
const SEARCH_VALUE_UPDATE = 'SEARCH_VALUE_UPDATE';
const SHOW_COIN_INFO = 'SHOW_COIN_INFO';
const ALL_MARKET_COIN_24_HR_PRICE = 'ALL_MARKET_COIN_24_HR_PRICE';
const ALL_MARKET_COIN_24_HR_PRICE_REQUESTED =
  'ALL_MARKET_COIN_24_HR_PRICE_REQUESTED';
const HIDE_ZERO_BALANCES = 'HIDE_ZERO_BALANCES';
const MARKET_ACTIVE_TAB = 'MARKET_ACTIVE_TAB';
const COIN_FOR_SIDEBAR_CONTENT = 'COIN_FOR_SIDEBAR_CONTENT';
const COIN_IMAGE_FOR_SIDEBAR_CONTENT = 'COIN_IMAGE_FOR_SIDEBAR_CONTENT';
const COIN_STATIC_CONTENT_API_REQUESTED = 'COIN_STATIC_CONTENT_API_REQUESTED';
const COIN_STATIC_CONTENT_API_SUCCESS = 'COIN_STATIC_CONTENT_API_SUCCESS';
const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
const MARKETVIEW_AUTO_SUGGEST_COIN_ID_UPDATE =
  'MARKETVIEW_AUTO_SUGGEST_COIN_ID_UPDATE';
const MARKET_VIEW_INPUT_TEXT = 'MARKET_VIEW_INPUT_TEXT';
const MARKETVIEW_AUTO_SUGGEST_VALUE_UPDATE =
  'MARKETVIEW_AUTO_SUGGEST_VALUE_UPDATE';
const MARKETVIEW_HIDE_ZERO_BALANCES = 'MARKETVIEW_HIDE_ZERO_BALANCES';
const MARKETVIEW_SORT_BY_FAV_UPDATE = 'MARKETVIEW_SORT_BY_FAV_UPDATE';
const MARKETVIEW_SEARCH_VALUE_UPDATE = 'MARKETVIEW_SEARCH_VALUE_UPDATE';
const HIDE_ZERO_BALANCES_FOR_ASSETVIEW = 'HIDE_ZERO_BALANCES_FOR_ASSETVIEW';
const ASSETVIEW_SEARCH_VALUE_UPDATE = 'ASSETVIEW_SEARCH_VALUE_UPDATE';

const initialState = {
  getMarketViewList: {},
  marketViewLoader: true,
  coinStaticContentLoader: false,
  showCoinInfo: false,
  marketCoin24HrPriceListObj: {},
  searchValue: '',
  // marketActiveTab: 'assetView',
  marketActiveTab: 'listView',
  coinForCoinInfo: 'ETH',
  coinimageForCoinInfo:
    'https://throughbit.s3.amazonaws.com/img/coin/eth1557328917.svg',
  activeTab: '0',
  marketViewInputText: '',
  hideZero: false,
  marketViewAutosuggestCurrencyId: -1,
  searchValue: '',
  sortbyFav: false,
  searchValueAssetView: '',
  hideZeroAssetView: false
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case MARKET_VIEW_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case MARKET_VIEW_SUCCESS:
      return {
        ...state,
        marketViewLoader: false,
        getMarketViewList: action.response
      };
    case SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case HIDE_ZERO_BALANCES:
      return {
        ...state,
        hideZero: action.hideZero
      };
    case HIDE_ZERO_BALANCES_FOR_ASSETVIEW:
      return {
        ...state,
        hideZeroAssetView: action.hideZeroAssetView
      };
    case SHOW_COIN_INFO:
      return {
        ...state,
        showCoinInfo: action.showCoinInfo
      };
    case ALL_MARKET_COIN_24_HR_PRICE_REQUESTED:
      return {
        ...state,
        marketViewLoader: true
      };
    case ALL_MARKET_COIN_24_HR_PRICE:
      return {
        ...state,
        marketCoin24HrPriceListObj: action.marketCoinPrice,
        marketViewLoader: false
      };
    case MARKET_ACTIVE_TAB:
      return {
        ...state,
        marketActiveTab: action.marketActiveTab
      };
    case COIN_FOR_SIDEBAR_CONTENT:
      return {
        ...state,
        coinForCoinInfo: action.coinForCoinInfo
      };
    case COIN_IMAGE_FOR_SIDEBAR_CONTENT:
      return {
        ...state,
        coinimageForCoinInfo: action.coinimageForCoinInfo
      };
    case COIN_STATIC_CONTENT_API_REQUESTED:
      return {
        ...state,
        coinStaticContentLoader: true
      };
    case COIN_STATIC_CONTENT_API_SUCCESS:
      return {
        ...state,
        coinStaticContentLoader: false,
        coinStaticContentData: action.response
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case MARKETVIEW_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        marketViewAutosuggestCurrencyId: action.marketViewAutosuggestCurrencyId
      };
    case MARKETVIEW_AUTO_SUGGEST_VALUE_UPDATE:
      return {
        ...state,
        mvAutosuggestValue: action.mvAutosuggestValue
      };
    case MARKET_VIEW_INPUT_TEXT:
      return {
        ...state,
        marketViewInputText: action.marketViewInputText
      };
    case MARKETVIEW_HIDE_ZERO_BALANCES:
      return {
        ...state,
        hideZero: action.hideZero
      };
    case MARKETVIEW_SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case MARKETVIEW_SORT_BY_FAV_UPDATE:
      return {
        ...state,
        sortbyFav: action.sortbyFav
      };
    case ASSETVIEW_SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValueAssetView: action.searchValueAssetView
      };
    default:
      return state;
  }
};
export const marketViewAutosuggestValueUpdate = formData => dispatch => {
  dispatch({
    type: MARKETVIEW_AUTO_SUGGEST_VALUE_UPDATE,
    mvAutosuggestValue: formData.mvAutosuggestValue
  });
};
export const inputTextAllCoinsFunc = formData => dispatch => {
  dispatch({
    type: MARKET_VIEW_INPUT_TEXT,
    marketViewInputText: formData.marketViewInputText
  });
};
export const getMarketView = formData => dispatch => {
  dispatch({
    type: MARKET_VIEW_REQUESTED
  });
  // apiCaller(`liveorders/1/1`, 'get', '', false, true, false).then(
  apiCaller(`chart/market/all`, 'get', '', false, false, false).then(
    response => {
      dispatch({
        type: MARKET_VIEW_SUCCESS,
        response
      });
    }
  );
};

// export const searchValueUpdate = formData => dispatch => {
//   dispatch({
//     type: SEARCH_VALUE_UPDATE,
//     searchValue: formData.searchValue
//   });
// };

export const showCoinInfoUpdate = formData => dispatch => {
  dispatch({
    type: SHOW_COIN_INFO,
    showCoinInfo: formData
  });
};

export const hideZeroBalances = formData => dispatch => {
  dispatch({
    type: HIDE_ZERO_BALANCES,
    hideZero: formData.hideZero
  });
};

export const hideZeroBalancesForAssetView = formData => dispatch => {
  dispatch({
    type: HIDE_ZERO_BALANCES_FOR_ASSETVIEW,
    hideZeroAssetView: formData.hideZeroAssetView
  });
};

export const allMarketCoin24HrPriceList = formData => dispatch => {
  dispatch({
    type: ALL_MARKET_COIN_24_HR_PRICE_REQUESTED
  });
  apiCaller('list/price/', 'get', formData, false, false, false).then(
    response => {
      if (response && Array.isArray(response)) {
        var marketCoinPrice = {};
        _.map(response, obj => {
          if (!marketCoinPrice[obj.marketId]) {
            marketCoinPrice[obj.marketId] = {};
          }
          marketCoinPrice[obj.marketId][obj.coinId] = obj;
        });

        dispatch({
          type: ALL_MARKET_COIN_24_HR_PRICE,
          marketCoinPrice
        });
      }
    }
  );
};

export const marketActiveTabFunc = formData => dispatch => {
  dispatch({
    type: MARKET_ACTIVE_TAB,
    marketActiveTab: formData.marketActiveTab
  });
};

export const coinForCoinInfoFunc = formData => dispatch => {
  dispatch({
    type: COIN_FOR_SIDEBAR_CONTENT,
    coinForCoinInfo: formData.coinForCoinInfo
  });
};

export const coinimageForCoinInfoFunc = formData => dispatch => {
  dispatch({
    type: COIN_IMAGE_FOR_SIDEBAR_CONTENT,
    coinimageForCoinInfo: formData.coinimageForCoinInfo
  });
};

export const coinStaticContentAPI = formData => dispatch => {
  dispatch({
    type: COIN_STATIC_CONTENT_API_REQUESTED
  });
  apiCaller(`content/data`, 'post', formData, false, false, false).then(
    response => {
      dispatch({
        type: COIN_STATIC_CONTENT_API_SUCCESS,
        response
      });
    }
  );
};

export const setActiveTab = formData => dispatch => {
  dispatch({
    type: SET_ACTIVE_TAB,
    activeTab: formData.activeTab
  });
};

export const marketViewAutosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: MARKETVIEW_AUTO_SUGGEST_COIN_ID_UPDATE,
    marketViewAutosuggestCurrencyId: formData.marketViewAutosuggestCurrencyId
  });
};

// export const hideZeroBalancesForMarketView = formData => dispatch => {
//   dispatch({
//     type: MARKETVIEW_HIDE_ZERO_BALANCES,
//     hideZero: formData.hideZero
//   });
// };

export const searchValueUpdate = formData => dispatch => {
  dispatch({
    type: MARKETVIEW_SEARCH_VALUE_UPDATE,
    searchValue: formData.searchValue
  });
};
export const searchValueUpdateForAssetView = formData => dispatch => {
  dispatch({
    type: ASSETVIEW_SEARCH_VALUE_UPDATE,
    searchValueAssetView: formData.searchValueAssetView
  });
};
export const sortByFavouritesUpdate = formData => dispatch => {
  dispatch({
    type: MARKETVIEW_SORT_BY_FAV_UPDATE,
    sortbyFav: formData.sortbyFav
  });
};
