/**
 *Sign In Component
 *Component
 */

import React from 'react';
import { push, goBack } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

import removeCookie from 'Utils/removeCookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _ from 'underscore';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  // PopoverHeader,
  PopoverBody
} from 'reactstrap';
import {
  signinSecondFormRequest,
  signinOTPFirstFormRequest,
  addProfile,
  forgotPassword,
  fromPWDChange
} from './signinReducer';

import {
  Route,
  withRouter,
  BrowserRouter as Router,
  Redirect,
  Switch
} from 'react-router-dom';

const cookies = new Cookies();

const mobileOtpReg = /(^\d{6}$)/;

const recaptchaRef = React.createRef();
/**
 * Represents a Sign in Class.
 * @class extends React.Component
 */
class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormProcessing: false,
      email: '',
      password: '',
      code: '',
      codeRequired: false,
      showMessage: false,
      message: '',
      sessionMessage: 'Your session has expired. You need to login again.',
      sessionExpired: true,
      loginFormShow: true,
      otpFormShow: false,
      reloadButton: false,
      otptoken: '',
      otpError: '',
      emailToConfirm: '',
      flag: false,
      modal: false,
      success: false,
      isLoggedIn: this.props.isLoggedIn,
      code_2fa: this.props.code_2fa,
      signupMessage: this.props.signedUpResponse
    };
    this.forgotPassword = this.forgotPassword.bind(this);
    this.setPassword = this.setPassword.bind(this);
  }

  forgotPassword() {
    if (this.state.emailToConfirm) {
      this.props.forgotPassword({
        email: this.state.emailToConfirm
      });
    } else {
      this.setState({
        modal: true,
        message: 'Please enter the email'
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('media-ge-768');
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentDidMount() {
    const screenWidth = window.outerWidth;
    if (screenWidth <= 768) {
      document.body.classList.add('media-ge-768');
    }
    const { isLoggedIn } = this.state;
    if (isLoggedIn) {
      this.props.changePage('/markets');
    } else {
      const email = cookies.get('email');
      this.setState({
        sessionExpired: email !== undefined ? true : false
      });

      //Remove cookies after session message display
      removeCookie();
    }
  }

  componentWillReceiveProps(nextProps) {
    //loader
    if (this.props.isFormProcessing !== nextProps.isFormProcessing) {
      this.setState({ isFormProcessing: nextProps.isFormProcessing });
    }

    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
    }

    if (this.props.code_2fa !== nextProps.code_2fa) {
      this.setState({ code_2fa: nextProps.code_2fa });
    }
    // Handle Signin Response
    if (
      nextProps.signinOTPFirstFormResponse &&
      Object.keys(nextProps.signinOTPFirstFormResponse).length !== undefined &&
      this.props.signinOTPFirstFormResponse !==
        nextProps.signinOTPFirstFormResponse
    ) {
      this.handleOTPFirstFormResponse(nextProps.signinOTPFirstFormResponse);
      this.setState({
        reloadButton: false,
        signinOTPFirstFormResponse: nextProps.signinOTPFirstFormResponse
      });
    }

    // Handle Signin Response
    if (
      _.size(nextProps.signinSecondFormResponse) !== undefined &&
      this.props.signinSecondFormResponse !== nextProps.signinSecondFormResponse
    ) {
      this.handleSecondFormResponse(nextProps.signinSecondFormResponse);
      this.setState({
        reloadButton: false,
        signinSecondFormResponse: nextProps.signinSecondFormResponse
      });
    }

    if (this.props.flag !== nextProps.flag) {
      this.setState({
        flag: nextProps.flag
      });
    }
    if (this.props.signedUpResponse !== nextProps.signedUpResponse) {
      this.setState({
        signupMessage: nextProps.signedUpResponse
      });
    }
    // If Logged In Successfully Go To Login Page
    // if (nextProps.isLoggedIn == true) {
    //	 this.props.changePage('/');
    // }

    if (
      this.props.forgotPasswordResponse !== nextProps.forgotPasswordResponse
    ) {
      this.setState(
        {
          modal: true
        },
        () => {
          if (nextProps.forgotPasswordResponse.error == 1) {
            this.setState({
              success: false,
              message: nextProps.forgotPasswordResponse.message
            });
          } else {
            this.setState({
              success: true,
              message: nextProps.forgotPasswordResponse.message
            });
          }
        }
      );
    }
  }

  checkSecondFormShowTime() {
    this.timeout = setTimeout(() => {
      this.setState({
        loginFormShow: true,
        otpFormShow: false,
        message: '',
        showMessage: false,
        otpError: '',
        otptoken: ''
      });
      this.timeout = null;
      // }, 13000);
    }, 60000 * 3);
  }

  handleOTPFirstFormResponse = response => {
    let message = '';
    // let codeRequired = false;
    if (response.error == 1) {
      message = response.message;
      // codeRequired = true;
      this.setState({
        showMessage: true,
        message,
        // codeRequired: codeRequired,
        sessionExpired: false
      });
    } else if (response.error == 0 && response.status == 0) {
      const { token, message } = response;
      // window.jwt = token;
      // cookies.set('jwt', token);
      // cookies.set('message', message);
      // cookies.set('full_name', data[0].full_name, { path: '/' });
      if (message == 'Require OTP') {
        this.checkSecondFormShowTime();
        this.setState({
          message: '',
          token,
          loginFormShow: false,
          otpFormShow: true
        });
      }
    } else if (response.status == 1) {
      const { data } = response;
      window.jwt = data[0].jwt;
      const jwtObj = {
        maxAge: 10800,
        path: '/'
      };
      if (window.location.hostname !== 'localhost') {
        // jwtObj.sameSite = 'strict';
        // jwtObj.secure = true;
        // jwtObj.httpOnly = true;
      }
      cookies.set('email', this.state.email);
      cookies.set('jwt', data[0].jwt, jwtObj);
      cookies.set('globalId', data[0].global_id, { path: '/' });
      cookies.set('kycStatus', data[0].kyc_status, { path: '/' });
      cookies.set('phoneStatus', data[0].phone_status, { path: '/' });
      cookies.set('code_2fa', data[0].code_2fa, { path: '/' });
      cookies.set('full_name', data[0].full_name, { path: '/' });

      this.props.addProfile({
        globalId: data[0].global_id,
        kyc_status: data[0].kyc_status,
        code_2fa: data[0].code_2fa
      });
      if (data[0].kyc_status == 0) {
        this.props.changePage('/kyc');
      } else {
        this.props.changePage('/markets');
      }

      // this.props.goBack();
      // this.props.changePage(
      //   '/BTC-ETH'
      //   // response.data[0].kyc_status == 0 ? 'kyc' : 'dashboard'
      // );
    }
  };

  /**
   * Handle Response
   * @function
   * @param {response} => response Data from the Api
   */

  handleSecondFormResponse = response => {
    let message = '';
    // let codeRequired = false;
    if (response.status == 1) {
      const { data } = response;
      window.jwt = data[0].jwt;
      const jwtObj = {
        maxAge: 10800,
        path: '/'
      };
      if (window.location.hostname !== 'localhost') {
        // jwtObj.sameSite = 'strict';
        // jwtObj.secure = true;
        // jwtObj.httpOnly = true;
      }
      cookies.set('email', this.state.email);
      cookies.set('jwt', data[0].jwt, jwtObj);
      cookies.set('globalId', data[0].global_id, { path: '/' });
      cookies.set('kycStatus', data[0].kyc_status, { path: '/' });
      cookies.set('phoneStatus', data[0].phone_status, { path: '/' });
      cookies.set('referalCheck', data[0].referalCheck, { path: '/' });
      cookies.set('referalId', data[0].referalId, { path: '/' });
      cookies.set('referredId', data[0].referredId, { path: '/' });
      cookies.set('code_2fa', data[0].code_2fa, { path: '/' });
      cookies.set('full_name', data[0].full_name, { path: '/' });
      this.props.addProfile({
        globalId: data[0].global_id,
        kyc_status: data[0].kyc_status,
        code_2fa: data[0].code_2fa
      });
      if (cookies.cookies.kycStatus != 1) {
        this.props.changePage(
          '/kyc',
          response.data[0].kyc_status == 0 ? 'kyc' : 'markets'
        );
      } else {
        this.props.changePage(
          '/markets',
          response.data[0].kyc_status == 0 ? 'kyc' : 'markets'
        );
      }
    } else if (response.error == 1) {
      message = response.message;
      // codeRequired = true;
    } else {
      message = response.error;
    }
    this.setState({
      showMessage: true,
      message,
      otpError: '',
      // codeRequired: codeRequired,
      sessionExpired: false
    });
  };

  /**
   * Call The Api For Sign In Action
   * @function
   * @param {object} => {enail, password}
   */

  handleSigninSecondForm = e => {
    e.preventDefault();
    // e.persist();
    if (this.state.otptoken.length === 6) {
      this.setState({
        reloadButton: true
      });
      if (mobileOtpReg.test(this.state.otptoken)) {
        this.setState({ otpError: '' });
        this.props.signinSecondFormRequest({
          token: this.state.token,
          otptoken: this.state.otptoken
        });
      } else {
        this.setState({
          otpError: 'Please enter valid OTP Code',
          message: '',
          showMessage: false
        });
      }
    } else {
      this.setState({
        otpError: 'Please enter valid OTP Code',
        message: '',
        showMessage: false
      });
    }
  };

  handleSigninOTPFirstForm = () => {
    this.setState({
      reloadButton: true
    });
    this.props.signinOTPFirstFormRequest({
      email: this.state.email,
      password: this.state.password
    });
  };

  handleGoogleAuth = e => {
    this.setState(
      {
        otptoken: e.target.validity.valid ? e.target.value : this.state.otptoken
      },
      () => {
        if (this.state.otptoken.length === 6) {
          this.handleSigninSecondForm(e);
        }
      }
    );
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  setPassword(e) {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      this.setState({ password: '' });
    }
  }

  render() {
    const { loginFormShow, otpFormShow } = this.state;
    //Your session has expired. You need to login again.' 'Password changed successfully. Please login again.'

    return (
      <HelmetProvider>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Helmet>
        <div id="kyc-page" className="vh">
          <div className="row no-gutters row-eq-height">
            <div className="col-lg-8 signin-left">
              <div className="logo mt-2rem">
                <a>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt="Logo"
                    style={{ height: '30px', cursor: 'pointer' }}
                    onClick={() => this.props.changePage('/landingPage')}
                    // onClick={() => this.toggle('/')}
                    // onClick={() => {
                    //   this.props.changePage(
                    //     `/${this.props.activeMarketSymbol}-${
                    //       this.props.activeCurrencySymbol
                    //     }`
                    //   );
                    // }}
                  />
                </a>
              </div>

              <div className="signin-slider">
                <img
                  className="ob-icon-static"
                  src={`${process.env.PUBLIC_URL}/assets/images/signin-carousel/Group.png`}
                  alt="First slide"
                />
                {/* <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                 <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="0"
                    className="active"
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="1"
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to="2"
                  />
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className=""
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/signin-carousel/Group.png`}
                      alt="First slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/signin-carousel/Group.png`}
                      alt="Second slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/images/signin-carousel/Group.png`}
                      alt="Third slide"
                    />
                  </div> 
                </div>
              </div>*/}
              </div>
              <div className="welcome-text">
                <h2>Welcome Back!</h2>
                <p>Enter your Email Id and Password to get started.</p>
              </div>
            </div>
            {/* <!-- Sidebar --> */}
            <div
              id="sidebar-main"
              className="col-lg-4 col-sm-12 signin-right body-height"
            >
              <h3 className="mb-5 mt-2rem">Login</h3>

              {loginFormShow && (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    recaptchaRef.current.execute();
                  }}
                >
                  {this.state.sessionExpired && (
                    <div className="text-center">
                      <p style={{ color: '#f3136a' }}>
                        {this.props.flag
                          ? 'Password changed successfully.'
                          : this.state.sessionMessage}
                      </p>
                    </div>
                  )}

                  <div className="signin-form row">
                    <input
                      className="form-control"
                      type="email"
                      required
                      name="Email ID"
                      placeholder="Email ID"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />

                    <input
                      className="form-control"
                      type="password"
                      required
                      name="Password"
                      autoComplete="on"
                      placeholder="Password"
                      value={this.state.password}
                      // onKeyDown={this.setPassword}
                      onChange={e =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Lez9ZcaAAAAAIqhpzlqi8EiGCLkkxuDYf5c9vQ8"
                    onChange={this.handleSigninOTPFirstForm}
                  />
                  {this.state.showMessage && (
                    <div className="error-details">
                      <p style={{ color: '#f3136a' }}>{this.state.message}</p>
                    </div>
                  )}

                  {!this.state.reloadButton && (
                    <div className="signin-button row mt-5 pt-1">
                      <input
                        className="btn btn-primary"
                        type="submit"
                        value="SIGN IN"
                      />
                    </div>
                  )}

                  {this.state.reloadButton && (
                    <div className="signin-button row mt-5 pt-1">
                      <button
                        className="btn btn-primary reload-button"
                        style={{ padding: '0px' }}
                      >
                        <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                      </button>
                    </div>
                  )}
                </form>
              )}

              <button
                hidden={!this.state.loginFormShow}
                className="btn btn-link float-right forgotpwdPos"
                data-toggle="modal"
                data-target="#myModal"
                onClick={() =>
                  this.setState({
                    success: false,
                    message: '',
                    emailToConfirm: ''
                  })
                }
              >
                Forgot Password?
              </button>

              {/* // <!-- The Modal --> */}
              <div className="modal top-30" id="myModal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <!-- Modal Header -->  */}
                    {!this.state.success && (
                      <div className="modal-header">
                        <h4
                          className="modal-title cl-white"
                          style={{ color: 'black' }}
                        >
                          Enter your Email
                        </h4>

                        <button
                          type="button"
                          className="close cross-button"
                          data-dismiss="modal"
                          onClick={() => this.setState({ emailToConfirm: '' })}
                        >
                          <span aria-hidden="true" className="cross-button">
                            &times;
                          </span>
                        </button>
                      </div>
                    )}

                    {/* <!-- Modal body -->  */}
                    {!this.state.success && (
                      <div className="modal-body cl-white-on-focus">
                        <input
                          className="bb-white cl-white"
                          required
                          placeholder="Enter Email id for verification"
                          type="email"
                          value={this.state.emailToConfirm}
                          onChange={e =>
                            this.setState({
                              emailToConfirm: e.target.value
                            })
                          }
                        />
                        <p style={{ color: 'red' }}>{this.state.message}</p>
                      </div>
                    )}

                    {this.state.success && (
                      <div className="modal-body">{this.state.message}</div>
                    )}

                    {/* <!-- Modal footer --> */}

                    <div className="modal-footer">
                      {!this.state.success && (
                        <button
                          className="btn btn-primary"
                          onClick={this.forgotPassword}
                        >
                          Confirm
                        </button>
                      )}
                      {this.state.success && (
                        <button
                          className="btn btn-primary"
                          data-dismiss="modal"
                          style={{ width: '100px' }}
                        >
                          OK
                        </button>
                      )}
                      {/* {!this.state.success && <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => this.setState({ emailToConfirm: '' })}
                    >
                      Cancel
                    </button>} */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <Modal
              isOpen={this.state.modal}
              toggle={this.toggleModal}
              className={this.props.className}
            >
              <ModalBody>{this.state.message}</ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleModal}>
                  ok
                </Button>
              </ModalFooter>
            </Modal> */}

              {otpFormShow && (
                <form onSubmit={this.handleSigninSecondForm}>
                  <div className="signin-form row">
                    <input
                      pattern="(\d{0,6})"
                      // required
                      className="form-control"
                      autoComplete="off"
                      type="text"
                      name="otptoken"
                      placeholder="Enter 6 digit Google Authenticator Code"
                      autoFocus
                      value={this.state.otptoken}
                      // onChange={e =>
                      //   // this.setState({ otptoken: e.target.value })
                      //   this.setState({
                      //     otptoken: e.target.validity.valid
                      //       ? e.target.value
                      //       : this.state.otptoken
                      //   })
                      // }

                      onChange={e => this.handleGoogleAuth(e)}
                    />
                  </div>

                  {this.state.otpError !== '' && (
                    <div className="error-details">
                      <p style={{ color: '#f3136a' }}>{this.state.otpError}</p>
                    </div>
                  )}
                  {this.state.showMessage && (
                    <div className="error-details">
                      <p style={{ color: '#f3136a' }}>{this.state.message}</p>
                    </div>
                  )}

                  {!this.state.reloadButton && (
                    <div className="signin-button row mt-5 pt-1">
                      <input
                        className="btn btn-primary"
                        type="submit"
                        value="SIGN IN"
                      />
                    </div>
                  )}

                  {/* {!this.state.reloadButton && (
                    <div className="signin-button row">
                      <input
                        className="btn btn-primary"
                        type="submit"
                        value="Sign in"
                      />
                    </div>
                  )} */}

                  {this.state.reloadButton && (
                    <div className="signin-button row mt-5 pt-1">
                      <button
                        className="btn btn-primary reload-button"
                        style={{ padding: '0px' }}
                      >
                        <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                      </button>
                    </div>
                  )}
                </form>
              )}
              <div hidden={this.state.otpFormShow} className="d-flex ">
                {/* <p hidden={this.state.otpFormShow} style={{ color: 'black' }}>
                Create an account{' '}
              </p> */}
                <p>""</p>
                <Link
                  hidden={this.state.otpFormShow}
                  className="signupLinkPos"
                  to="/signup"
                >
                  Sign Up
                </Link>
              </div>

              <div className="text-center pt-5">
                <p style={{ color: 'green' }}>{this.state.signupMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signinOTPFirstFormRequest: formData =>
        signinOTPFirstFormRequest(formData),
      signinSecondFormRequest: formData => signinSecondFormRequest(formData),
      changePage: page => push(page),
      goBack: page => goBack(page),
      addProfile: profile => addProfile(profile),
      forgotPassword: formData => forgotPassword(formData),
      fromPWDChange: formData => fromPWDChange(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isFormProcessing: state.signin.isFormProcessing,
    signinSecondFormResponse: state.signin.signinSecondFormResponse,
    forgotPasswordResponse: state.signin.forgotPasswordResponse,
    signinOTPFirstFormResponse: state.signin.signinOTPFirstFormResponse,
    activeMarketSymbol: state.app.activeMarketSymbol,
    activeCurrencySymbol: state.app.activeCurrencySymbol,
    isLoggedIn: state.signin.isLoggedIn,
    flag: state.signin.flag,
    signedUpResponse: state.signin.signedUpResponse
  }),
  mapDispatchToProps
)(Signin);
