/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

import {
  headerActiveMenuUpdate,
  marketCurrencyUpdate,
  activeMarketValueChange
} from 'App/appReducer';

import _ from 'underscore';
import Dropdown from 'react-dropdown';
// import Autosuggest from './autosuggest';

import selectFavCurrency from 'Utils/favouriteCoins';
import {
  Dropdown as RSDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
const cookies = new Cookies();
let email = cookies.get('email');

class AllCoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      orderDetails: '',
      orderHistoryLoader: false,
      marketViewLoader: this.props.marketViewLoader,
      allMarketCoinsListObj: this.props.allMarketCoinsListObj,
      marketsCoinsListObj: this.props.marketsCoinsListObj,
      marketCoin24HrPriceList: this.props.marketCoin24HrPriceList,
      marketCoin24HrPriceListObj: {},
      searchDataObject: {},
      searchData: {},
      coinSymbolsObj: {},
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      openOrdersActiveTab: '1',
      listingMarketId: '0',
      coinSearchValue: '',
      coinIdtoSymbolsObj: {},
      favCoinArray:
        (localStorage.getItem('favCoinArray') &&
          JSON.parse(localStorage.getItem('favCoinArray'))) ||
        [],
      selected: {
        value: this.props.activeMarketValue,
        label: this.props.activeMarketSymbol
      },
      sidebar_main_height: this.props.sidebar_main_height,
      allCoinsInputText: this.props.allCoinsInputText,
      autoSuggestForMarkets: this.props.autoSuggestForMarkets,
      sortByChange: this.props.sortByChange,
      sortByFav: this.props.sortByFav,
      sortByName: this.props.sortByName,
      sortByVolume: this.props.sortByVolume,
      dropdownOpen: false,
      selectedOption: '',
      searchValue: ''
    };
    this.toggleHeaderActiveMenu = this.toggleHeaderActiveMenu.bind(this);
    this.changeCurrency = this.changeCurrency.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this._onSelect = this._onSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.sortData = this.sortData.bind(this);
    this.sortbyOption = this.sortbyOption.bind(this);
  }

  _onSelect(option) {
    this.setState({
      selected: option,
      listingMarketId: option.value
    });
  }
  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  toggleHeaderActiveMenu(headerActiveMenu, changePageLocation) {
    this.props.changePage(changePageLocation);
    this.props.headerActiveMenuUpdate(headerActiveMenu);
  }

  componentDidMount() {
    this.handleSearchChange();
    this.setState(
      {
        activeMarketValue: this.props.activeMarketValue,
        listingMarketId: this.props.activeMarketValue,
        coinIdtoSymbolsObj: this.props.coinIdtoSymbolsObj,
        coinSymbolsObj: this.props.coinSymbolsObj,
        allMarketCoinsListObj: this.props.allMarketCoinsListObj,
        marketsCoinsListObj: this.props.marketsCoinsListObj,
        marketCoin24HrPriceListObj: this.props.marketCoin24HrPriceListObj,
        searchDataObject: this.props.marketCoin24HrPriceListObj,
        searchData: this.props.marketCoin24HrPriceListObj
      },
      this.priceData24HrCoinName
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sidebar_main_height !== nextProps.sidebar_main_height) {
      this.setState({ sidebar_main_height: nextProps.sidebar_main_height });
    }

    if (this.props.allCoinsInputText !== nextProps.allCoinsInputText) {
      this.setState(
        { allCoinsInputText: nextProps.allCoinsInputText },
        this.handleSearchChange
      );
    }

    if (this.props.autoSuggestForMarkets !== nextProps.autoSuggestForMarkets) {
      this.setState({ autoSuggestForMarkets: nextProps.autoSuggestForMarkets });
    }

    // Set Loader for Order History
    if (this.props.orderHistoryLoader !== nextProps.orderHistoryLoader) {
      this.setState({ orderHistoryLoader: nextProps.orderHistoryLoader });
    }

    if (this.props.marketViewLoader !== nextProps.marketViewLoader) {
      this.setState({ marketViewLoader: nextProps.marketViewLoader });
    }

    if (this.props.activeMarketValue !== nextProps.activeMarketValue) {
      this.setState({ activeMarketValue: nextProps.activeMarketValue });
    }

    if (this.props.activeCurrencyValue !== nextProps.activeCurrencyValue) {
      this.setState({ activeCurrencyValue: nextProps.activeCurrencyValue });
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.coinIdtoSymbolsObj !== nextProps.coinIdtoSymbolsObj) {
      this.setState(
        {
          coinIdtoSymbolsObj: nextProps.coinIdtoSymbolsObj
        },
        this.priceData24HrCoinName
      );
    }

    if (
      this.props.marketCoin24HrPriceList !== nextProps.marketCoin24HrPriceList
    ) {
      this.setState(
        {
          marketCoin24HrPriceList: nextProps.marketCoin24HrPriceList
        },
        this.priceData24HrCoinName
      );
    }

    if (this.props.allMarketCoinsListObj !== nextProps.allMarketCoinsListObj) {
      this.setState({ allMarketCoinsListObj: nextProps.allMarketCoinsListObj });
    }

    if (this.props.marketsCoinsListObj !== nextProps.marketsCoinsListObj) {
      this.setState(
        {
          marketsCoinsListObj: nextProps.marketsCoinsListObj
        },
        this.priceData24HrCoinName
      );
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      setTimeout(() => {
        this.props.changePage(
          `/${this.props.activeMarketSymbol}-${this.props.activeCurrencySymbol}`
        );
      }, 100);
    }
    // if (this.props.sortByName !== nextProps.hideZero) {
    //   //  this.hideZeroBalOrders(nextProps.hideZero);
    //   this.setState({
    //     sortByName: nextProps.sortByName,
    //     searchData: this.searchInList(
    //       nextProps.hideZero,
    //       this.state.searchValue,
    //       this.state.sortByName,
    //       nextProps.marketsCoinsListObj
    //     )
    //   });

    //   if (nextProps.hideZero) {
    //     this.props.hideZeroBalances({ hideZero: true });
    //   }
    // }
  }

  priceData24HrCoinName() {
    const {
      coinIdtoSymbolsObj,
      marketCoin24HrPriceList,
      marketsCoinsListObj
    } = this.state;
    if (
      _.size(coinIdtoSymbolsObj) > 0 &&
      _.size(marketCoin24HrPriceList) > 0 &&
      _.size(marketsCoinsListObj) > 0
    ) {
      var marketCoinPrice = {};

      _.map(_.keys(marketsCoinsListObj), marketId => {
        if (!marketCoinPrice[marketId]) {
          marketCoinPrice[marketId] = {};
        }
        _.map(_.keys(marketsCoinsListObj[marketId]['coins']), coinId => {
          marketCoinPrice[marketId][coinId] =
            marketsCoinsListObj[marketId]['coins'][coinId];
        });
      });

      _.map(marketCoin24HrPriceList, obj => {
        if (
          marketCoinPrice[obj.marketId] &&
          // marketsCoinsListObj[obj.marketId] &&
          marketsCoinsListObj[obj.marketId]['coins'][obj.coinId]
        ) {
          marketCoinPrice[obj.marketId][obj.coinId] = {
            ...obj,
            ...marketCoinPrice[obj.marketId][obj.coinId]
          };
        }
      });

      this.setState({
        marketCoin24HrPriceListObj: marketCoinPrice,
        searchDataObject: marketCoinPrice,
        searchData: marketCoinPrice,
        marketViewLoader: false
      });
    } else {
      const marketCoins = {};
      _.map(_.keys(marketsCoinsListObj), marketId => {
        if (!marketCoins[marketId]) {
          marketCoins[marketId] = {};
        }
        _.map(_.keys(marketsCoinsListObj[marketId]['coins']), coinId => {
          marketCoins[marketId][coinId] =
            marketsCoinsListObj[marketId]['coins'][coinId];
        });
      });
      this.setState({
        marketCoin24HrPriceListObj: marketCoins,
        searchDataObject: marketCoins,
        searchData: marketCoins,
        marketViewLoader: false
      });
    }
  }

  changeCurrency(market, currency) {
    cookies.set('activeMarketValue', market);
    cookies.set('activeCurrencyValue', currency);
    cookies.set(
      'activeMarketSymbol',
      this.state.coinSymbolsObj[market].coinsymbol
    );
    cookies.set(
      'activeCurrencySymbol',
      this.state.coinSymbolsObj[currency].coinsymbol
    );

    this.props.changePage(
      `/${this.state.coinSymbolsObj[market].coinsymbol}-${this.state.coinSymbolsObj[currency].coinsymbol}`
    );

    this.props.marketCurrencyUpdate({
      activeMarketValue: market,
      activeCurrencyValue: currency,
      activeMarketSymbol: this.state.coinSymbolsObj[market].coinsymbol,
      activeCurrencySymbol: this.state.coinSymbolsObj[currency].coinsymbol
    });
  }

  listingMarketChange(marketId) {
    this.setState({
      listingMarketId: marketId
    });
  }
  handleOptionChange(value) {
    this.setState({
      selectedOption: value
    });
    //this.sortData('e.target.value',this.state.searchValue)
    this.sortData(value, this.state.searchValue);
  }
  sortbyOption(sortOption) {
    const { searchDataObject } = this.state;
    var searchData = {};

    switch (sortOption) {
      case 'Sort by name':
        _.map(_.keys(searchDataObject), marketKey => {
          searchData[marketKey] = {};
          _.map(_.keys(searchDataObject[marketKey]), o => {
            searchData[marketKey] = _.sortBy(
              _.values(searchDataObject[marketKey]),
              'coinname'
            );
            // this.sortOn(searchData[marketKey], 'coinname')
          });
        });
        this.setState({
          searchDataObject: searchData,
          searchObjectAfterSort: searchData
        });
        break;
      case 'Sort by volume':
        _.map(_.keys(searchDataObject), marketKey => {
          searchData[marketKey] = {};
          _.map(_.keys(searchDataObject[marketKey]), o => {
            searchData[marketKey] = _.sortBy(
              _.values(searchDataObject[marketKey]),
              'twentyfourhourVolume'
            );
            // this.sortOn(searchData[marketKey], 'twentyfourhourVolume')
          });
        });
        this.setState({
          searchDataObject: searchData,
          searchObjectAfterSort: searchData
        });
        break;

      case 'Sort by change':
        _.map(_.keys(searchDataObject), marketKey => {
          searchData[marketKey] = {};
          _.map(_.keys(searchDataObject[marketKey]), o => {
            searchData[marketKey] = _.sortBy(
              _.values(searchDataObject[marketKey]),
              'twentyfourhourChange'
            );
            //   this.sortOn(searchData[marketKey], 'twentyfourhourChange')
          });
        });
        this.setState({
          searchDataObject: searchData,
          searchObjectAfterSort: searchData
        });
        break;

      case 'Sort by favourites':
        const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
        _.map(_.keys(searchDataObject), marketKey => {
          searchData[marketKey] = {};
          _.map(searchDataObject[marketKey], o => {
            _.map(favArray, i => {
              if (o.coinId == i) {
                o['fav'] = 1;
              }
            });

            // searchData[marketKey] = _.sortBy(_.values(searchDataObject[marketKey]),'twentyfourhourChange');
            //   this.sortOn(searchData[marketKey], 'twentyfourhourChange')
          });
          _.map(_.keys(searchDataObject[marketKey]), o => {
            searchData[marketKey] = _.sortBy(
              _.values(searchDataObject[marketKey]),
              'fav'
            );
            //   this.sortOn(searchData[marketKey], 'twentyfourhourChange')
          });
        });
        this.setState({
          searchDataObject: searchData,
          searchObjectAfterSort: searchData
        });
        break;
      default:
        break;
    }
  }

  sortOn(data, property) {
    data.sort(function(a, b) {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  handleSearchChange() {
    const { allCoinsInputText } = this.state;
    if (allCoinsInputText === '') {
      this.setState({
        searchDataObject: this.state.marketCoin24HrPriceListObj,
        coinSearchValue: allCoinsInputText
      });
    } else {
      if (allCoinsInputText.indexOf('(') > -1) {
        const allCoinsInputText1 = allCoinsInputText.split('(')[0].trim();
        this.setState({ coinSearchValue: allCoinsInputText1 }, this.searchCoin);
      } else {
        this.setState({ coinSearchValue: allCoinsInputText }, this.searchCoin);
      }
    }
  }

  searchCoin() {
    const {
      marketCoin24HrPriceListObj,
      listingMarketId,
      coinSearchValue
      // coinIdtoSymbolsObj
    } = this.state;

    const searchDataArray = _.filter(
      _.keys(marketCoin24HrPriceListObj[listingMarketId]),
      key => {
        var obj = marketCoin24HrPriceListObj[listingMarketId][key];

        return (
          obj.coinname.toLowerCase().indexOf(coinSearchValue.toLowerCase()) >
            -1 ||
          obj.coinsymbol.toLowerCase().indexOf(coinSearchValue.toLowerCase()) >
            -1
        );
      }
    );
    const searchDataObject = {};
    searchDataObject[listingMarketId] = {};
    _.map(searchDataArray, key => {
      searchDataObject[listingMarketId][key] =
        marketCoin24HrPriceListObj[listingMarketId][key];
    });
    this.setState({ searchDataObject });
  }

  selectFavCurrency(coinId) {
    const favCoinArray = selectFavCurrency.selectFavCurrency(coinId);
    this.setState({ favCoinArray });
  }

  handleChange(event) {
    const searchValue = event.target.value;
    this.setState({
      searchValue: event.target.value
    });
    this.sortData(this.state.selectedOption, event.target.value);
  }

  sortData(sortOption, searchText) {
    const { marketCoin24HrPriceListObj, searchDataObject } = this.state;

    if (searchText == '') {
      if (sortOption == '') {
        // empty search, no sortOption
        // this.setState({
        //   searchDataObject:searchDataObject
        // })
        this.setState({
          searchDataObject: marketCoin24HrPriceListObj
        });
      } else {
        // empty search, sortOption present
        this.setState(
          {
            searchDataObject: marketCoin24HrPriceListObj
          },
          () => {
            this.sortbyOption(sortOption);
          }
        );
      }
    } else {
      if (sortOption == '') {
        // not empty search, no sortOption
        const searchDataObject = {};
        _.map(_.keys(marketCoin24HrPriceListObj), marketKey => {
          searchDataObject[marketKey] = {};
          _.map(_.keys(marketCoin24HrPriceListObj[marketKey]), coinId => {
            if (
              marketCoin24HrPriceListObj[marketKey][coinId].coinname
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1 ||
              marketCoin24HrPriceListObj[marketKey][coinId].coinsymbol
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
            ) {
              searchDataObject[marketKey][coinId] =
                marketCoin24HrPriceListObj[marketKey][coinId];
            }
          });
        });
        this.setState({ searchDataObject });
      } else {
        // not empty search, sortOption present

        _.map(_.keys(marketCoin24HrPriceListObj), marketKey => {
          searchDataObject[marketKey] = {};
          _.map(_.keys(marketCoin24HrPriceListObj[marketKey]), coinId => {
            if (
              marketCoin24HrPriceListObj[marketKey][coinId].coinname
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1 ||
              marketCoin24HrPriceListObj[marketKey][coinId].coinsymbol
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
            ) {
              searchDataObject[marketKey][coinId] =
                marketCoin24HrPriceListObj[marketKey][coinId];
            }
          });
        });
        this.setState({ searchDataObject }, () => {
          this.sortbyOption(sortOption);
        });
      }
    }
  }
  render() {
    const {
      marketsCoinsListObj,
      marketCoin24HrPriceListObj,
      autoSuggestForMarkets,
      searchDataObject,
      marketViewLoader,
      activeMarketValue,
      listingMarketId,
      coinSymbolsObj
    } = this.state;

    if (marketViewLoader || !coinSymbolsObj) {
      return <div className="loading-o" />;
    }

    return (
      <>
        <div className="row no-gutters mt-1 mb-1">
          <div className="col-md-6">
            {/* {autoSuggestForMarkets &&
              autoSuggestForMarkets[listingMarketId] && (
                <Autosuggest
                  reactMarketCoinSelect={autoSuggestForMarkets[listingMarketId]}
                />
							)} */}

            <input
              className="form-control coin-input"
              type="search"
              placeholder="Search..."
              aria-label="Search"
              onChange={this.handleChange}
              style={{ cursor: 'auto' }}
            />
          </div>
          {_.keys(marketsCoinsListObj).length > 0 && (
            <div className="col-md-4 pl-1" id="market-select-menu">
              {/* <Dropdown
								options={this.state.allMarketCoinsListObj}
								onChange={this._onSelect}
								value={this.state.selected}
								placeholder={this.state.selected}
							/> */}
              <div className="nav-item dropdown show">
                <a
                  className="nav-link dropdown-toggle crsrPntr"
                  role="button"
                  id="market-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.selected.label}
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  {this.state.allMarketCoinsListObj.map((o, i) => (
                    <a
                      className="dropdown-item crsrPntr"
                      key={i}
                      onClick={() => this._onSelect(o)}
                    >
                      {o.label}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-2">
            <div className="nav-item show">
              {/* <a className="nav-link dropdown-toggle" role="button" id="market-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{cursor: "pointer; padding: 0.5rem 0.8rem;"}}><i className="fa fa-filter"></i></a>
                 <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item" style={{cursor: "pointer"}}><input type="checkbox" name="vehicle1" value="Bike" /> Sort by Volumee</a>
                    <a className="dropdown-item" style={{cursor: "pointer"}}><input type="checkbox" name="vehicle1" value="Bike" /> Sort by name</a>
                    <a className="dropdown-item" style={{cursor: "pointer"}}><input type="checkbox" name="vehicle1" value="Bike" /> Sort by favourites</a>
                    <a className="dropdown-item" style={{cursor: "pointer"}}><input type="checkbox" name="vehicle1" value="Bike" /> Sort by change</a>
                </div> */}

              <RSDropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
              >
                <DropdownToggle>
                  {this.state.selectedOption !== '' && (
                    <span
                      className="filter-status"
                      style={{ left: '27px', top: '2px' }}
                    />
                  )}
                  <i className="fa fa-filter" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="sort-coins-dashboard">
                    <label
                      onClick={() => this.handleOptionChange('Sort by name')}
                    >
                      <input
                        type="radio"
                        value="Sort by name"
                        checked={this.state.selectedOption === 'Sort by name'}
                        onChange={() => this.handleOptionChange('Sort by name')}
                      />{' '}
                      <span className="pl-2">Sort by name</span>
                    </label>
                  </DropdownItem>
                  <DropdownItem className="sort-coins-dashboard">
                    <label
                      onClick={() => this.handleOptionChange('Sort by volume')}
                    >
                      <input
                        type="radio"
                        value="Sort by volume"
                        checked={this.state.selectedOption === 'Sort by volume'}
                        onChange={() =>
                          this.handleOptionChange('Sort by volume')
                        }
                      />{' '}
                      <span className="pl-2">Sort by volume</span>
                    </label>
                  </DropdownItem>

                  <DropdownItem className="sort-coins-dashboard">
                    <label
                      onClick={() => this.handleOptionChange('Sort by change')}
                    >
                      <input
                        type="radio"
                        value="Sort by change"
                        checked={this.state.selectedOption === 'Sort by change'}
                        onChange={() =>
                          this.handleOptionChange('Sort by change')
                        }
                      />{' '}
                      <span className="pl-2">Sort by change</span>
                    </label>
                  </DropdownItem>

                  <DropdownItem className="sort-coins-dashboard">
                    <label
                      onClick={() =>
                        this.handleOptionChange('Sort by favourites')
                      }
                    >
                      <input
                        type="radio"
                        value="Sort by favourites"
                        checked={
                          this.state.selectedOption === 'Sort by favourites'
                        }
                        onChange={() =>
                          this.handleOptionChange('Sort by favourites')
                        }
                      />{' '}
                      <span className="pl-2">Sort by favourites</span>
                    </label>
                  </DropdownItem>

                  {/* <input
                        type="radio"
                        name="sortByName"
                        value="true"
                        checked={!!this.state.sortByName}
                        onChange={this.handleNameSort}
                      />{' '}
                      <label htmlFor="zero" onClick={this.handleNameSort}>
                        Sort by name
                      </label>
                    </DropdownItem>
                    <DropdownItem>
                      <input
                        type="radio"
                        className="checkbox-filter"
                        name="sortByVol"
                        checked={!!this.state.sortByVolume}
                        onChange={this.handleVolumeSort}
                      />{' '}
                      <label onClick={this.handleVolumeSort}>Sort by volume</label>
                    </DropdownItem>
                    <DropdownItem>
                      <input
                        type="radio"
                        className="checkbox-filter"
                        name="sortByChange"
                        checked={!!this.state.sortByChange}
                        onChange={this.handleChangeSort}
                      />{' '}
                      <label onClick={this.handleChangeSort}>Sort by change</label>
                    </DropdownItem>
                    <DropdownItem>
                      <input
                        type="radio"
                        className="checkbox-filter"
                        name="sortByFav"
                        checked={!!this.state.sortbyFav}
                        onChange={this.handleFavSort}
                      />{' '}
                      <label onClick={this.handleFavSort}>Sort by favourites</label> */}
                  {/* </DropdownItem> */}
                </DropdownMenu>
              </RSDropdown>
            </div>
          </div>
        </div>

        <div
          className="container-fluid card-box"
          style={{ height: this.state.sidebar_main_height - 92 }}
        >
          <ul className="markets">
            {searchDataObject &&
              searchDataObject[listingMarketId] &&
              _.keys(searchDataObject[listingMarketId]).length > 0 &&
              _.values(searchDataObject[listingMarketId]).map((o, i) => (
                <li
                  key={i}
                  className={
                    listingMarketId === this.props.activeMarketValue &&
                    o.coinId === this.props.activeCurrencyValue
                      ? 'container selected-market'
                      : 'container'
                  }
                  // onClick={() => this.changeCurrency(listingMarketId, o.coinId)}
                >
                  <div className="row no-gutters">
                    <div className="col-md-12 market-head">
                      <div
                        className="coin-line"
                        style={{
                          background: `${
                            // marketsCoinsListObj[listingMarketId]['coins'][o.coinId]
                            //   .coincolor
                            o.coincolor
                          }`
                        }}
                      />
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="title-h">
                              {o.coinname}{' '}
                              <span className="currency">
                                {/* {coinSymbolsObj[o.coinId].coinsymbol} */}
                                {o.coinsymbol}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-2 fav-icon crsrPntr">
                            <span
                              onClick={() =>
                                this.selectFavCurrency(
                                  // searchDataObject[listingMarketId][o.coinId]
                                  o.coinId
                                )
                              }
                              className={
                                (
                                  JSON.parse(
                                    localStorage.getItem('favCoinArray')
                                  ) || []
                                ).indexOf(
                                  // searchDataObject[listingMarketId][o.coinId]
                                  o.coinId
                                ) === -1
                                  ? 'oi oi-star disabled'
                                  : 'oi oi-star'
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="container crsrPntr"
                    onClick={() =>
                      this.changeCurrency(listingMarketId, o.coinId)
                    }
                  >
                    <div className="row no-gutters">
                      <div className="col-md-4" align="left">
                        <div className="title">Last price</div>
                        <div className="data">
                          {/* {`${searchDataObject[listingMarketId][o.lastexecutedPrice]
                             || 0} `} */}
                          {`${o.lastexecutedPrice || 0} `}

                          <span className="currency"> 
                            {coinSymbolsObj[listingMarketId].coinsymbol}
                            {/* o.coinsymbol */}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4" align="center">
                        <div className="title">24H Volume</div>
                        <div className="data">
                          {`${o.twentyfourhourVolume || 0} `}
                          <span className="currency">
                            {coinSymbolsObj[listingMarketId].coinsymbol}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4" align="right">
                        <div className="title">24H Change</div>

                        {o.twentyfourhourChange ? (
                          <div
                            className={
                              o.twentyfourhourChange.includes('-')
                                ? 'data negative-text'
                                : 'data positive-text'
                            }
                          >
                            <img
                              src={
                                o.twentyfourhourChange.includes('-')
                                  ? 'assets/images/change_minus.png'
                                  : 'assets/images/change_plus.png'
                              }
                              className="minus-image"
                            />
                            {`${o.twentyfourhourChange
                              .replace('+', '')
                              .replace('-', '')
                              .replace('%', '')}%`}
                          </div>
                        ) : (
                          <div className="data positive-text">
                            <img
                              src="assets/images/change_plus.png"
                              className="minus-image"
                            />
                            {'0%'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  }
}

AllCoins.defaultProps = {
  activeMarketValue: 0,
  activeCurrencyValue: 1,
  activeMarketSymbol: 'BTC',
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      marketCurrencyUpdate: formData => marketCurrencyUpdate(formData),
      activeMarketValueChange: formData => activeMarketValueChange(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    marketViewLoader: state.app.marketViewLoader,
    orderDetails: state.dashboard.orderDetails,
    orderHistoryLoader: state.dashboard.orderHistoryLoader,
    profile: state.signin.profile,
    headerActiveMenu: state.app.headerActiveMenu,
    coinSymbolsObj: state.app.coinSymbolsObj,
    coinIdtoSymbolsObj: state.app.coinIdtoSymbolsObj,
    marketCoin24HrPriceList: state.app.marketCoin24HrPriceList,
    allMarketCoinsListObj: state.app.allMarketCoinsListObj,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    autoSuggestForMarkets: state.app.autoSuggestForMarkets,
    allCoinsInputText: state.allCoins.allCoinsInputText,
    sidebar_main_height: state.dashboard.sidebar_main_height
  }),
  mapDispatchToProps
)(AllCoins);
