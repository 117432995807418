/**
 * Main AssetView Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'underscore';
import statusCheck from 'Utils/statusCheck';
import selectFavCurrency from 'Utils/favouriteCoins';
import InfiniteScroll from 'react-infinite-scroller';
import SVG from 'react-inlinesvg';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from 'reactstrap';

import {
  getMarketView,
  showCoinInfoUpdate,
  allMarketCoin24HrPriceList,
  marketActiveTabFunc,
  coinForCoinInfoFunc,
  setActiveTab,
  coinimageForCoinInfoFunc,
  hideZeroBalancesForAssetView,
  searchValueUpdateForAssetView
} from './marketViewReducer';
import {
  headerActiveMenuUpdate,
  marketCurrencyUpdate
} from 'App/appReducer';
import {
  buyLimitInputRemotelyChange,
  sellLimitInputRemotelyChange
} from 'Modules/dashboard/components/limitForm/limitReducer';
import {
  buyMarketInputRemotelyChange,
  sellMarketInputRemotelyChange
} from 'Modules/dashboard/components/marketForm/marketReducer';
import {
  buyStopLimitInputRemotelyChange,
  sellStopLimitInputRemotelyChange
} from 'Modules/dashboard/components/stopLimitForm/stopLimitReducer';

// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

class AssetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketViewLoader: true,
      getMarketViewList: {},
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      searchData: this.props.coinsMarketsListObj,
      marketCoin24HrPriceListObj: this.props.marketCoin24HrPriceListObj,
      marketCoin24HrPriceList: this.props.marketCoin24HrPriceList,
      getMyBalanceList: this.props.getMyBalanceList,
      coinCrptoArray: [],
      coinCrptoLoadArray: [],
      loadArray: true,
      activeTab: 0,
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      marketActiveTab: this.props.marketActiveTab,
      currency: 'BTC',
      hideZeroAssetView: this.props.hideZeroAssetView,
      searchValueAssetView: this.props.searchValueAssetView,
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.loadMoreFunc = this.loadMoreFunc.bind(this);
    this.marketsToggle = this.marketsToggle.bind(this);
    this.hideZeroFunc = this.hideZeroFunc.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleHeaderActiveMenu = this.toggleHeaderActiveMenu.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.props.showCoinInfoUpdate(!this.props.showCoinInfo);
  }
  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  marketsToggle(tab) {
    // this.coinInfoFunc({ coinsymbol: null, coinimage: null });
    // this.loadCurrencyInfo()
    if (this.state.marketActiveTab !== tab) {
      this.props.marketActiveTabFunc({ marketActiveTab: tab });
      this.setState({
        marketActiveTab: tab
      });
    }
    //  this.props.coinForCoinInfoFunc({ coinForCoinInfo: null });
  }

  toggleHeaderActiveMenu(
    headerActiveMenu,
    changePageLocation,
    market,
    currency,
    activeMarketSymbol,
    activeCurrencySymbol
  ) {
    // const activeMarketSymbol = this.props.coinsMarketsListObj[market]
    //   .marketdata[ 0].marketsymbol;
    // const activeCurrencySymbol = this.props.coinsMarketsListObj[market].coins[
    //   currency
    // ].coinsymbol;
    this.props.marketCurrencyUpdate({
      activeMarketValue: market,
      activeCurrencyValue: currency,
      activeMarketSymbol,
      activeCurrencySymbol
    });
    this.props.headerActiveMenuUpdate(headerActiveMenu);

    this.props.buyLimitInputRemotelyChange({
      volumeBuy: '',
      bidBuy: '',
      totalBuy: '',
      marketBuy: market,
      currencyBuy: currency,
      operationBuy: ''
    });

    this.props.sellLimitInputRemotelyChange({
      volumeSell: '',
      bidSell: '',
      totalSell: '',
      marketSell: market,
      currencySell: currency,
      operationSell: ''
    });

    this.props.buyMarketInputRemotelyChange({
      volumeMarketBuy: ''
      // marketSell: market,
      // currencySell: currency,
      // operationSell: ''
    });

    this.props.sellMarketInputRemotelyChange({
      volumeMarketSell: ''
      // marketSell: market,
      // currencySell: currency,
      // operationSell: ''
    });

    this.props.buyStopLimitInputRemotelyChange({
      limitBuy: '',
      stopBuy: '',
      volumeStopBuy: '',
      totalStopBuy: '',
      marketBuy: market,
      currencyBuy: currency,
      operationBuy: ''
    });

    this.props.sellStopLimitInputRemotelyChange({
      stopSell: '',
      limitSell: '',
      marketSell: market,
      currencySell: currency,
      operationSell: '',
      volumeStopSell: '',
      totalStopSell: ''
    });

    this.props.changePage(`/${activeMarketSymbol}-${activeCurrencySymbol}`);
    // this.props.changePage(changePageLocation);
  }

  componentDidMount() {
    // this.props.allMarketCoin24HrPriceList(); // Get All Market Coins Price List Object
    // this.props.getMarketView();
    this.setState({
      coinsMarketsListObj: this.props.coinsMarketsListObj,
      marketCoin24HrPriceListObj: this.props.marketCoin24HrPriceListObj,
      searchData: this.props.coinsMarketsListObj
    });
    this.priceData24HrCoinName();
    this.loadCurrencyInfo();
  }
  loadCurrencyInfo() {
    const keys_markets = _.keys(this.state.searchData);
    if (keys_markets.length !== 0) {
      this.coinInfoFunc(this.state.searchData[keys_markets[0]].coindata[0]);
    } else {
      this.props.coinForCoinInfoFunc({ coinForCoinInfo: null });
      this.props.coinimageForCoinInfoFunc({ coinimageForCoinInfo: null });
    }
  }
  componentDidUpdate() {
    // this.priceData24HrCoinName();
  }

  componentWillReceiveProps(nextProps) {
    // Set Loader for Order History
    if (this.props.marketViewLoader !== nextProps.marketViewLoader) {
      this.setState({ marketViewLoader: nextProps.marketViewLoader });
    }

    if (this.props.marketActiveTab !== nextProps.marketActiveTab) {
      this.setState({ marketActiveTab: nextProps.marketActiveTab });
    }

    if (this.props.getMyBalanceList !== nextProps.getMyBalanceList) {
      this.setState({ getMyBalanceList: nextProps.getMyBalanceList });
    }
    if (this.props.marketActiveTab !== nextProps.marketActiveTab) {
      this.setState(
        { marketActiveTab: nextProps.marketActiveTab },
        this.loadCurrencyInfo
      );
    }
    //Handle The Response IF Recieved Transaction Data
    // if (
    //   nextProps.getMarketViewList !== undefined &&
    //   this.props.getMarketViewList !== nextProps.getMarketViewList
    // ) {
    //   this.setState({
    //     getMarketViewList: nextProps.getMarketViewList,
    //     searchData: nextProps.getMarketViewList
    //   });
    // }

    if (
      // nextProps.searchValue !== '' &&
      this.props.searchValueAssetView !== nextProps.searchValueAssetView
    ) {
      this.setState(
        {
          searchValueAssetView: nextProps.searchValueAssetView
        },
        this.searchInList(
          nextProps.searchValueAssetView,
          this.state.hideZeroAssetView
        )
      );
    }

    if (this.props.coinsMarketsListObj !== nextProps.coinsMarketsListObj) {
      this.setState(
        {
          coinsMarketsListObj: nextProps.coinsMarketsListObj,
          searchData: nextProps.coinsMarketsListObj
        },
        this.priceData24HrCoinName,
        this.loadCurrencyInfo
      );
    }

    if (
      this.props.marketCoin24HrPriceList !== nextProps.marketCoin24HrPriceList
    ) {
      this.setState(
        {
          marketCoin24HrPriceList: nextProps.marketCoin24HrPriceList
        },
        this.priceData24HrCoinName
      );
    }

    if (this.props.hideZeroAssetView !== nextProps.hideZeroAssetView) {
      //  this.hideZeroBalOrders(nextProps.hideZeroAssetView);
      this.setState(
        {
          hideZeroAssetView: nextProps.hideZeroAssetView
        },
        this.searchInList(
          this.state.searchValueAssetView,
          nextProps.hideZeroAssetView
        )
      );

      if (nextProps.hideZeroAssetView) {
        this.props.hideZeroBalancesForAssetView({ hideZeroAssetView: true });
      }
    }
  }

  priceData24HrCoinName() {
    const { marketCoin24HrPriceList, coinsMarketsListObj } = this.state;
    if (
      _.size(marketCoin24HrPriceList) > 0 &&
      _.size(coinsMarketsListObj) > 0
    ) {
      const coinMarketPrice = {};

      _.map(_.keys(coinsMarketsListObj), coinId => {
        if (!coinMarketPrice[coinId]) {
          coinMarketPrice[coinId] = {};
        }
        coinMarketPrice[coinId] = coinsMarketsListObj[coinId];
        // _.map(_.keys(coinsMarketsListObj[coinId]['markets']), marketId => {
        // coinMarketPrice[coinId]['markets'][marketId] = coinsMarketsListObj[coinId]['markets'][marketId];
        // });
      });

      if (_.size(coinMarketPrice) > 0) {
        _.map(marketCoin24HrPriceList, obj => {
          if (coinMarketPrice[obj.coinId]) {
            coinMarketPrice[obj.coinId]['markets'][obj.marketId] = {
              ...obj,
              ...coinMarketPrice[obj.coinId]['markets'][obj.marketId]
            };
          }
        });
      }

      const coinCrptoLoadArray = _.keys(coinMarketPrice);
      // const coinCrptoLoadArray = _.keys(coinMarketPrice).slice(0, 2);

      this.setState({
        marketCoin24HrPriceListObj: coinMarketPrice,
        searchDataObject: coinMarketPrice,
        coinCrptoArray: _.keys(coinMarketPrice),
        coinCrptoLoadArray: coinCrptoLoadArray,
        marketViewLoader: false
      });
    } else {
      const coinMarket = {};

      _.map(_.keys(coinsMarketsListObj), coinId => {
        if (!coinMarket[coinId]) {
          coinMarket[coinId] = {};
        }
        coinMarket[coinId] = coinsMarketsListObj[coinId];
        // _.map(_.keys(coinsMarketsListObj[coinId]['markets']), marketId => {
        // coinMarketPrice[coinId]['markets'][marketId] = coinsMarketsListObj[coinId]['markets'][marketId];
        // });
      });
      // const coinCrptoLoadArray = _.keys(coinMarketPrice).slice(0, 2);

      this.setState({
        marketCoin24HrPriceListObj: coinMarket,
        searchDataObject: coinMarket,
        coinCrptoArray: _.keys(coinMarket),
        coinCrptoLoadArray: _.keys(coinMarket),
        marketViewLoader: false
      });
    }
  }

  loadMoreFunc() {
    const { coinCrptoLoadArray, coinCrptoArray } = this.state;
    if (coinCrptoArray > coinCrptoLoadArray) {
      const coinCrptoLoadArrayDup = coinCrptoArray.slice(
        0,
        coinCrptoLoadArray.length + 2
      );
      this.setState({ coinCrptoLoadArray: coinCrptoLoadArrayDup });
    } else if (coinCrptoArray.length > 0 && coinCrptoLoadArray.length > 0) {
      this.setState({ loadArray: false });
    }
  }

  searchInList(searchText, hideZeroAssetView) {
    const {
      coinsMarketsListObj,
      searchValueAssetView,
      searchDataObject,
      marketCoin24HrPriceListObj,
      getMyBalanceList
    } = this.state;
    const searchData = {};
    var coinCrptoLoadArray;
    if (searchText !== '') {
      if (hideZeroAssetView) {
        _.map(_.keys(coinsMarketsListObj), coinKey => {
          // searchData[coinKey]['markets']= searchDataObject[coinKey]['markets']
          if (
            (coinsMarketsListObj[coinKey]['coindata'][0].coinname
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1 ||
              coinsMarketsListObj[coinKey]['coindata'][0].coinsymbol
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1) &&
            coinKey in getMyBalanceList &&
            'amount' in getMyBalanceList[coinKey] &&
            Number(parseFloat(getMyBalanceList[coinKey].amount).toFixed(8)) !==
              0
          ) {
            searchData[coinKey] = coinsMarketsListObj[coinKey];
          }
        });
        coinCrptoLoadArray = _.keys(searchData);

        this.setState(
          {
            searchDataObject: searchData,
            coinCrptoLoadArray: coinCrptoLoadArray,
            searchData: searchData
          },
          this.loadCurrencyInfo
        );
      } else {
        _.map(_.keys(coinsMarketsListObj), coinKey => {
          // searchData[coinKey]['markets']= searchDataObject[coinKey]['markets']
          if (
            coinsMarketsListObj[coinKey]['coindata'][0].coinname
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1 ||
            coinsMarketsListObj[coinKey]['coindata'][0].coinsymbol
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) > -1
          ) {
            searchData[coinKey] = coinsMarketsListObj[coinKey];
          }
        });
        coinCrptoLoadArray = _.keys(searchData);

        this.setState(
          {
            searchDataObject: searchData,
            coinCrptoLoadArray: coinCrptoLoadArray,
            searchData: searchData
          },
          this.loadCurrencyInfo
        );
      }
    } else {
      if (hideZeroAssetView) {
        _.map(_.keys(coinsMarketsListObj), coinKey => {
          if (
            coinKey in getMyBalanceList &&
            'amount' in getMyBalanceList[coinKey] &&
            Number(parseFloat(getMyBalanceList[coinKey].amount).toFixed(8)) !==
              0
          ) {
            searchData[coinKey] = coinsMarketsListObj[coinKey];
          }
        });

        coinCrptoLoadArray = _.keys(searchData);
        this.setState(
          {
            searchDataObject: searchData,
            coinCrptoLoadArray: coinCrptoLoadArray,
            searchData: searchData
          },
          this.loadCurrencyInfo
        );
      } else {
        const coinCrptoLoadArray = _.keys(marketCoin24HrPriceListObj);

        this.setState(
          {
            searchDataObject: marketCoin24HrPriceListObj,
            coinCrptoLoadArray: coinCrptoLoadArray,
            searchData: marketCoin24HrPriceListObj
          },
          this.loadCurrencyInfo
        );
      }
    }
    // if (searchText !== '') {

    //   });
    //   this.setState({ searchData });
    // } else {
    //   this.setState({ searchData: coinsMarketsListObj });
    // }
  }

  selectFavCurrency(coinId) {
    const favCoinArray = selectFavCurrency.selectFavCurrency(coinId);
    this.setState({ favCoinArray });
  }

  coinInfoFunc(currency) {
    const coinsymbol = currency.coinsymbol;
    const coinimage = currency.coinimage;
    this.props.coinForCoinInfoFunc({ coinForCoinInfo: coinsymbol });
    this.props.coinimageForCoinInfoFunc({ coinimageForCoinInfo: coinimage });
  }

  getBalances(o) {
    const { searchDataObject, getMyBalanceList } = this.state;
    if (this.props.isLoggedIn) {
      return (
        <span className="balance-number">
          {getMyBalanceList[o] ? getMyBalanceList[o].amount.toFixed(8) : 0}{' '}
          <span className="currency">
            {searchDataObject[o]['coindata'][0].coinsymbol}
          </span>{' '}
        </span>
      );
    } else {
      return 'NA';
    }
  }

  hideZeroFunc() {
    this.props.hideZeroBalancesForAssetView({
      hideZeroAssetView: !this.state.hideZeroAssetView
    });
  }
  handleChange(e) {
    this.props.searchValueUpdateForAssetView({
      searchValueAssetView: e.target.value
    });
  }
  componentWillUnmount() {
    const { marketsCoinsListObj, activeTab } = this.props;

    this.props.hideZeroBalancesForAssetView({
      hideZeroAssetView: false
    });

    this.props.coinForCoinInfoFunc({
      coinForCoinInfo:
        marketsCoinsListObj[activeTab]['coinsArray'][0].coinsymbol
    });
    this.props.coinimageForCoinInfoFunc({
      coinimageForCoinInfo:
        marketsCoinsListObj[activeTab]['coinsArray'][0].coinimage
    });
  }
  render() {
    const {
      error,
      marketViewLoader,
      // searchData,
      // activeTab,
      // marketCoin24HrPriceListObj,
      coinCrptoLoadArray,
      searchDataObject,
      getMyBalanceList,
      marketActiveTab,
      loadArray
    } = this.state;
    const coinCrptoArray = _.keys(searchDataObject);
    if (error) {
      return <div>{error.toString()}</div>;
    }
    if (marketViewLoader) {
      return <div className="loading-o" />;
    }
    // if (marketViewLoader) {
    //   return <div>Loading...</div>;
    // }

    return (
      <>
        <ul className="nav nav-switch markets-menu">
          <li className="nav-item ml-auto">
            <input
              className="form-control coin-input"
              type="search"
              placeholder="Search..."
              aria-label="Search"
              onChange={this.handleChange}
              style={{ cursor: 'auto' }}
            />
          </li>
          {this.props.isLoggedIn && (
            <li className="nav-item" style={{ padding: '0.4rem' }}>
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}
              >
                <DropdownToggle>
                  {!!this.state.hideZeroAssetView && (
                    <span className="filter-status" />
                  )}
                  <i className="fa fa-filter" />
                </DropdownToggle>
                <DropdownMenu right className="mt-2">
                  <DropdownItem className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="checkbox-filter"
                      name="zero"
                      checked={!!this.state.hideZeroAssetView}
                      onChange={this.hideZeroFunc}
                    />{' '}
                    <label
                      className="pl-2 mb-0"
                      htmlFor="zero"
                      onClick={this.hideZeroFunc}
                    >
                      Hide zero balances
                    </label>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          )}

          <li className="nav-item">
            <a
              className={`crsrPntr nav-link link-icon
								${marketActiveTab === 'assetView' ? ' active' : ''}`}
              onClick={() => {
                this.marketsToggle('assetView');
              }}
              active={(marketActiveTab === 'assetView').toString()}
              aria-selected={marketActiveTab === 'assetView'}
            >
              <i className="fa fa-th-large" id="tooltip-1" />
              <UncontrolledTooltip
                placement="top"
                target="tooltip-1"
                delay={{ hide: -1 }}
              >
                Asset View
              </UncontrolledTooltip>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`crsrPntr nav-link link-icon
								${marketActiveTab === 'listView' ? ' active' : ''}`}
              onClick={() => {
                this.marketsToggle('listView');
              }}
              active={(marketActiveTab === 'listView').toString()}
              aria-selected={marketActiveTab === 'listView'}
            >
              <i className="fa fa-th-list" id="tooltip-2" />
              <UncontrolledTooltip
                placement="top"
                target="tooltip-2"
                delay={{ hide: -1 }}
              >
                List View
              </UncontrolledTooltip>
            </a>
          </li>
        </ul>
        {/* <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMoreFunc}
          hasMore={loadArray}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        > */}
        <div style={{ height: 'calc(100vh - 130px)', overflowY: 'auto' }}>
          {coinCrptoLoadArray &&
            coinCrptoLoadArray.length !== 0 &&
            coinCrptoLoadArray.map((o, i) => (
              <div
                className="row no-gutters mt-2 coin-data-container ml-1 mr-1 pt-2 pb-2"
                key={i}
              >
                <div
                  className="col-md-12 coin-img-small crsrPntr"
                  onClick={() =>
                    this.coinInfoFunc(searchDataObject[o]['coindata'][0])
                  }
                >
                  {/* <img src="assets/images/coin-logos/lsk@2x.png" /> */}
                  <SVG
                    src={searchDataObject[o]['coindata'][0].coinimage}
                    className="svg-15"
                  />
                  {`${searchDataObject[o]['coindata'][0].coinname} `}{' '}
                  <span className="currency">
                    {searchDataObject[o]['coindata'][0].coinsymbol}
                  </span>{' '}
                  <span className="balance-coin ml-2">Balance:</span>{' '}
                  {this.getBalances(o)}
                  {/*  {this.props.isLoggedIn ? (getMyBalanceList[o] ?
                      (getMyBalanceList[o].amount.toFixed(8)) :
                      0 )                    
                    <span className="currency">
                      {searchDataObject[o]['coindata'][0].coinsymbol}
                    </span> : 'NA'} */}
                </div>

                {_.keys(searchDataObject[o]['markets']).map(
                  (marketObj, j) =>
                    searchDataObject[o]['markets'][marketObj].marketname && (
                      <div
                        className="col-lg-3 col-md-12 col-sm-12 coin-markets pl-2 pr-2 crsrPntr"
                        key={j}
                        onClick={() =>
                          this.toggleHeaderActiveMenu(
                            'exchange',
                            '/',
                            searchDataObject[o]['markets'][marketObj].marketId,
                            o,
                            searchDataObject[o]['markets'][marketObj]
                              .marketsymbol,
                            searchDataObject[o]['coindata'][0].coinsymbol
                          )
                        }
                      >
                        <h2 className="title-h">
                          {/* <div className="coin-dot btc"></div> */}
                          <div
                            className="coin-dot"
                            style={{
                              background: `${searchDataObject[o]['markets'][marketObj].marketcolor}`
                            }}
                          />
                          {` ${searchDataObject[o]['markets'][marketObj].marketname} `}
                          <span className="currency">
                            {
                              searchDataObject[o]['markets'][marketObj]
                                .marketsymbol
                            }
                          </span>
                          {/* <div className="change positive-text">12%</div> */}
                          {searchDataObject[o]['markets'][marketObj]
                            .twentyfourhourChange && (
                            <div
                              className={`${
                                searchDataObject[o]['markets'][
                                  marketObj
                                ].twentyfourhourChange.includes('-')
                                  ? 'change negative-text'
                                  : 'change positive-text'
                              }`}
                            >
                              <span
                                className={`${
                                  searchDataObject[o]['markets'][
                                    marketObj
                                  ].twentyfourhourChange.includes('-')
                                    ? 'change-minus'
                                    : 'change-plus'
                                }`}
                              />
                              {`${
                                searchDataObject[o]['markets'][
                                  marketObj
                                ].twentyfourhourChange.indexOf('%') > -1
                                  ? searchDataObject[o]['markets'][
                                      marketObj
                                    ].twentyfourhourChange.replace('-', '')
                                  : searchDataObject[o]['markets'][
                                      marketObj
                                    ].twentyfourhourChange.replace('-', '') +
                                    '%'
                              }`}
                            </div>
                          )}
                        </h2>
                        <ul className="market-coin-info">
                          <li className="row no-gutters">
                            <div className="col-6 text-left">
                              <h3 className="title-h">Last Price</h3>
                            </div>
                            <div className="col-6 text-right number">
                              {`${searchDataObject[o]['markets'][marketObj]
                                .lastexecutedPrice || 0} `}{' '}
                              <span className="currency">
                                {
                                  searchDataObject[o]['markets'][marketObj]
                                    .marketsymbol
                                }
                              </span>
                            </div>
                          </li>
                          <li className="row no-gutters">
                            <div className="col-6 text-left">
                              <h3 className="title-h">24hr Volume</h3>
                            </div>
                            <div className="col-6 text-right number">
                              {`${searchDataObject[o]['markets'][marketObj]
                                .twentyfourhourVolume || 0} `}{' '}
                              <span className="currency">
                                {
                                  searchDataObject[o]['markets'][marketObj]
                                    .marketsymbol
                                }
                              </span>
                            </div>
                          </li>
                          <li className="row no-gutters">
                            <div className="col-6 text-left">
                              <h3 className="title-h">24hr High</h3>
                            </div>
                            <div className="col-6 text-right number">
                              {`${searchDataObject[o]['markets'][marketObj]
                                .twentyfourhourHigh || 0} `}
                              <span className="currency">
                                {
                                  searchDataObject[o]['markets'][marketObj]
                                    .marketsymbol
                                }
                              </span>
                            </div>
                          </li>
                          <li className="row no-gutters">
                            <div className="col-6 text-left">
                              <h3 className="title-h">24hr Low</h3>
                            </div>
                            <div className="col-6 text-right number">
                              {`${searchDataObject[o]['markets'][marketObj]
                                .twentyfourhourLow || 0} `}
                              <span className="currency">
                                {
                                  searchDataObject[o]['markets'][marketObj]
                                    .marketsymbol
                                }
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
        {/* </InfiniteScroll> */}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      getMarketView: formData => getMarketView(formData),
      showCoinInfoUpdate: formData => showCoinInfoUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      marketCurrencyUpdate: formData => marketCurrencyUpdate(formData),
      marketActiveTabFunc: formData => marketActiveTabFunc(formData),
      coinForCoinInfoFunc: formData => coinForCoinInfoFunc(formData),
      setActiveTab: formData => setActiveTab(formData),
      buyLimitInputRemotelyChange: formData =>
        buyLimitInputRemotelyChange(formData),
      sellLimitInputRemotelyChange: formData =>
        sellLimitInputRemotelyChange(formData),
      buyMarketInputRemotelyChange: formData =>
        buyMarketInputRemotelyChange(formData),
      sellMarketInputRemotelyChange: formData =>
        sellMarketInputRemotelyChange(formData),
      buyStopLimitInputRemotelyChange: formData =>
        buyStopLimitInputRemotelyChange(formData),
      sellStopLimitInputRemotelyChange: formData =>
        sellStopLimitInputRemotelyChange(formData),
      allMarketCoin24HrPriceList: formData =>
        allMarketCoin24HrPriceList(formData),
      hideZeroBalancesForAssetView: formData =>
        hideZeroBalancesForAssetView(formData),
      coinimageForCoinInfoFunc: formData => coinimageForCoinInfoFunc(formData),
      searchValueUpdateForAssetView: formData =>
        searchValueUpdateForAssetView(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    marketActiveTab: state.marketView.marketActiveTab,
    marketViewLoader: state.marketView.marketViewLoader,
    getMarketViewList: state.marketView.getMarketViewList,
    searchValueAssetView: state.marketView.searchValueAssetView,
    showCoinInfo: state.marketView.showCoinInfo,
    marketCoin24HrPriceListObj: state.app.marketCoin24HrPriceListObj,
    marketCoin24HrPriceList: state.app.marketCoin24HrPriceList,
    headerActiveMenu: state.app.headerActiveMenu,
    getMyBalanceList: state.myOrders.getMyBalanceList,
    coinsMarketsListObj: state.app.coinsMarketsListObj,
    isLoggedIn: state.signin.isLoggedIn,
    hideZeroAssetView: state.marketView.hideZeroAssetView,
    searchValueAssetView: state.marketView.searchValueAssetView,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    activeTab: state.marketView.activeTab
  }),
  mapDispatchToProps
)(AssetView);
