import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentPage } from './paginationReducer';
import { setCount } from 'Modules/withdrawdeposit/withdrawDepositReducer';

class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pager: {},
      initialPage: 1,
      currentPage: this.props.currentPage
    };
  }

  componentWillMount() {
    this.setPage(this.props.currentPage);
  }

  componentWillUnmount() {
    this.props.setCurrentPage({
      page: this.state.initialPage
    });
  }
  setPage(page) {
    this.props.setCurrentPage({
      page: page
    });

    var count = this.props.count;
    var pager = this.state.pager;
    if (page < 1 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(count, page);

    // get new page of items from items array

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    if (this.props.currentPage != page) {
      this.props.onChangePage(pager);
    }
  }

  getPager(totalItems, currentPage, pageSize) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    var pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  render() {
    var pager = this.state.pager;
    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }
    return (
      <ul className="pagination justify-content-center">
        <li className={pager.currentPage === 1 ? 'pageDisabled' : ''}>
          <a onClick={() => this.setPage(1)}>First</a>
        </li>
        <li className={pager.currentPage === 1 ? 'pageDisabled' : ''}>
          <a onClick={() => this.setPage(pager.currentPage - 1)}>Previous</a>
        </li>
        {pager.pages.map((page, index) => (
          <li
            key={index}
            className={pager.currentPage === page ? 'page-item' : ''}
          >
            <a onClick={() => this.setPage(page)}>{page}</a>
          </li>
        ))}
        <li
          className={
            pager.currentPage === pager.totalPages ? 'pageDisabled' : ''
          }
        >
          <a onClick={() => this.setPage(pager.currentPage + 1)}>Next</a>
        </li>
        <li
          className={
            pager.currentPage === pager.totalPages ? 'pageDisabled' : ''
          }
        >
          <a onClick={() => this.setPage(pager.totalPages)}>Last</a>
        </li>
      </ul>
    );
  }
}

/* Pagination.propTypes = {
    items: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number   
}; */
Pagination.defaultProps = {
  initialPage: 1
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentPage: formData => setCurrentPage(formData),
      setCount: formData => setCount(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    // marketViewLoader: state.marketView.marketViewLoader,
    // getMarketViewList: state.marketView.getMarketViewList,
    currentPage: state.pagination.currentPage
  }),
  mapDispatchToProps
)(Pagination);
