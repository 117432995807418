/**
 * Buy Crypto Component
 * Component
 */

import React from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import Cookies from 'universal-cookie';

// import { logout } from 'Modules/signin/signinReducer';

import BuyForms from './buy';
import SellForms from './sell';

import ErrorBoundary from 'Modules/ErrorBoundary';

import { limitFormHeightFunc } from './limitReducer';

/**
 * Represents a Buy Crypto Class.
 * @class extends React.Component
 */
class LimitForm extends React.Component {
  constructor(props) {
    super(props);
    this.myLimitFormRef = React.createRef();
  }

  componentDidMount() {
    const limitFormHeight = this.myLimitFormRef.current.clientHeight;
    this.props.limitFormHeightFunc({ limitFormHeight });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.limitFormHeight !== nextProps.limitFormHeight) {
      this.setState({ limitFormHeight: nextProps.limitFormHeight });
    }
  }

  render() {
    return (
      <div
        ref={this.myLimitFormRef}
        className="row no-gutters calculator-container"
      >
        <ErrorBoundary>
          <BuyForms
            activeMarketValue={this.props.activeMarketValue}
            activeMarketSymbol={this.props.activeMarketSymbol}
            activeCurrencyValue={this.props.activeCurrencyValue}
            activeCurrencySymbol={this.props.activeCurrencySymbol}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <SellForms
            activeMarketValue={this.props.activeMarketValue}
            activeMarketSymbol={this.props.activeMarketSymbol}
            activeCurrencyValue={this.props.activeCurrencyValue}
            activeCurrencySymbol={this.props.activeCurrencySymbol}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

LimitForm.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1
};

const mapStateToProps = state => {
  return {
    limitFormHeight: state.limitForms.limitFormHeight
    // currencyBuy: state.orderForms.currencyBuy,
    // volumeBuy: state.orderForms.volumeBuy,
    // bidBuy: state.orderForms.bidBuy,
    // operationBuy: state.orderForms.operationBuy,
    // totalBuy: state.orderForms.totalBuy,
    // response: state.orderForms.response,
    // buyFormModal: state.orderForms.buyFormModal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // changePage: page => push(page)
      limitFormHeightFunc: formData => limitFormHeightFunc(formData)
    },
    dispatch
  );

// export default connect()(LimitForm);
// mapStateToProps,
// mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(LimitForm);
