import React from 'react';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';

import statusCheck from 'Utils/statusCheck';
import {
  activeTabUpdate,
  getWithDrawHistory,
  activeDurationUpdate,
  autosuggestCurrencyIdUpdate,
  setDates
} from './withdrawDepositReducer';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Autosuggest from './historyAutosuggest';
import moment from 'moment';

class WDSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getWDHistoryList: [],
      getWithdrawList: [],
      getDepositList: [],
      wdHistoryLoader: true,
      error: '',
      showMessage: false,
      message: '',
      txnid: '',
      currency: 'BTC',
      dateFrom: null,
      dateTo: null,
      reactSelectOptions: this.props.reactSelectOptions,
      sidebar_main_height: window.innerHeight - 60,
      historyAutosuggestCurrencyId: this.props.historyAutosuggestCurrencyId,
      flag: true
    };
    this.toggle = this.toggle.bind(this);
    this.toggleDuration = this.toggleDuration.bind(this);
    this.calculateDuration = this.calculateDuration.bind(this);
    this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
    this.onChangeDateTo = this.onChangeDateTo.bind(this);
    this.startAnalysis = this.startAnalysis.bind(this);
  }

  onChangeDateFrom(date) {
    this.setState({ dateFrom: date });
  }

  onChangeDateTo(date) {
    this.setState({ dateTo: date });
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  toggleDuration(activeDuration) {
    if (this.props.activeDuration !== activeDuration) {
      this.props.activeDurationUpdate(activeDuration);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.reactSelectOptions !== nextProps.reactSelectOptions) {
      this.setState({ reactSelectOptions: nextProps.reactSelectOptions });
    }

    if (
      this.props.historyAutosuggestCurrencyId !==
      nextProps.historyAutosuggestCurrencyId
    ) {
      this.setState({
        historyAutosuggestCurrencyId: nextProps.historyAutosuggestCurrencyId
      });
    }
  }

  calculateDuration(type, duration) {
    this.setState({
      dateFrom: new Date(
        moment()
          .subtract(duration, type)
          .format('YYYY-MM-DD 00:00')
      ),
      dateTo: new Date(moment().format('YYYY-MM-DD hh:mm'))
    });
  }

  startAnalysis() {
    const { dateFrom, dateTo, historyAutosuggestCurrencyId } = this.state;
    /*   if (dateFrom == null || dateTo == null) {
      this.props.getWithDrawHistory({
        operation: 'combined',
        coinId: this.props.autosuggestCurrencyId
      });
    } else {
      this.props.getWithDrawHistory({
        operation: 'combined',
        start: this.state.dateFrom,
        end: this.state.dateTo,
        coinId: this.props.autosuggestCurrencyId
      });
    }
    /* this.props.autosuggestCurrencyIdUpdate({
      autosuggestCurrencyId: this.props.autosuggestCurrencyId
    }); */

    const form = {};
    if (historyAutosuggestCurrencyId >= 0) {
      form.coinId = historyAutosuggestCurrencyId;
    }

    if (dateFrom != null && dateTo != null) {
      this.props.setDates({
        dateTo: moment(this.state.dateFrom).format('YYYY-MM-DD 00:00'),
        dateTo: moment(this.state.dateTo).format('YYYY-MM-DD 23:59')
      });
      form.start = moment(this.state.dateFrom).format('YYYY-MM-DD 00:00');
      form.end = moment(this.state.dateTo).format('YYYY-MM-DD 23:59');
    }
    form.operation = 'combined';
    form.skip = 0;
    form.limit = this.props.limit;
    this.props.getWithDrawHistory(form);
  }
  render() {
    const { dateFrom, dateTo, flag } = this.state;
    const { activeDuration, style } = this.props;
    const { reactSelectOptions } = this.state;

    return (
      <div className="col-lg-3 mt-1">
        <div
          style={{ height: window.innerHeight - 120 }}
          id="sidebar-main"
          className="row no-gutters sidebar-content"
        >
          <div className="col-lg-12">
            <div className="currency-info">
              <h1 className="title pt-2 pb-2">Sort</h1>
            </div>
            <div className="row no-gutters">
              <div className="col-lg-11">
                <ul className="nav time-duration">
                  <li className="nav-item mr-1">
                    <a
                      className={`crsrPntr nav-link
												${activeDuration === '1' ? ' active' : ''}`}
                      onClick={() => this.calculateDuration('days', 1)}
                      active={(activeDuration === '1').toString()}
                      data-toggle="tab"
                      aria-selected={activeDuration === '1'}
                    >
                      1 D
                    </a>
                  </li>
                  <li className="nav-item mr-1">
                    <a
                      className={`crsrPntr nav-link
												${activeDuration === '2' ? ' active' : ''}`}
                      onClick={() => this.calculateDuration('weeks', 1)}
                      active={(activeDuration === '2').toString()}
                      data-toggle="tab"
                      aria-selected={activeDuration === '2'}
                    >
                      1 W
                    </a>
                  </li>
                  <li className="nav-item mr-1">
                    <a
                      className={`crsrPntr nav-link
												${activeDuration === '3' ? ' active' : ''}`}
                      onClick={() => this.calculateDuration('months', 1)}
                      active={(activeDuration === '3').toString()}
                      data-toggle="tab"
                      aria-selected={activeDuration === '3'}
                    >
                      1 M
                    </a>
                  </li>
                  <li className="nav-item mr-1">
                    <a
                      className={`crsrPntr nav-link
												${activeDuration === '4' ? ' active' : ''}`}
                      onClick={() => this.calculateDuration('months', 3)}
                      active={(activeDuration === '4').toString()}
                      data-toggle="tab"
                      aria-selected={activeDuration === '4'}
                    >
                      3 M
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`crsrPntr nav-link
												${activeDuration === '5' ? ' active' : ''}`}
                      onClick={() => this.calculateDuration('months', 6)}
                      active={(activeDuration === '5').toString()}
                      data-toggle="tab"
                      aria-selected={activeDuration === '5'}
                    >
                      6 M
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-11 sidebar-element">
                <div className="row pt-2">
                  <div className="col-md-12 text-left">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group" id="TooltipExample">
                          {/* <label>From </label> */}
                          <DatePicker
                            onChange={this.onChangeDateFrom}
                            // value={this.state.dateFrom}
                            // todayButton={'ThroughBit'}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="From (DD/MM/YYYY)"
                            className="blackColor datepickerNew"
                            width="30%"
                            selected={this.state.dateFrom}
                            isClearable={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6" id="datepicker-triangle-id">
                        <div id="TooltipExample">
                          {/* <label>To </label> */}
                          <DatePicker
                            onChange={this.onChangeDateTo}
                            // value={this.state.dateTo}
                            // todayButton={'ThroughBit'}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="To (DD/MM/YYYY)"
                            className="blackColor datepickerNew"
                            width="30%"
                            selected={this.state.dateTo}
                            isClearable={true}
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                boundariesElement: 'viewport'
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-12 text-right">
                    <div className="row no-gutters">
                      <label>Choose Asset</label>
                    </div>
                    <div className="form-group">
                      <Autosuggest reactMarketCoinSelect={reactSelectOptions} />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-blue not-fixed"
                onClick={this.startAnalysis}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      activeTabUpdate: formData => activeTabUpdate(formData),
      getWithDrawHistory: formData => getWithDrawHistory(formData),
      activeDurationUpdate: formData => activeDurationUpdate(formData),
      setDates: formData => setDates(formData),

      autosuggestCurrencyIdUpdate: formData =>
        autosuggestCurrencyIdUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    tradeHistoryLoader: state.transactions.tradeHistoryLoader,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    activeTab: state.transactions.activeTab,
    activeDuration: state.transactions.activeDuration,
    reactSelectOptions: state.app.reactSelectOptions,
    autosuggestCurrencyId: state.transactions.autosuggestCurrencyId,
    historyAutosuggestCurrencyId:
      state.transactions.historyAutosuggestCurrencyId,
    limit: state.transactions.limit
    // data_table_height: state.dashboard.data_table_height
  }),
  mapDispatchToProps
)(WDSort);
