/**
 *Sign Up Component
 *Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { signup, generateCode } from './signupReducer';
import { signedUp } from 'Modules/signin/signinReducer';
import FooterModal from 'App/footerModal';
import { Button } from 'reactstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const cookies = new Cookies();

/**
 * Represents a Sign Up Class.
 * @class extends React.Component
 */

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: cookies.get('jwt') !== null ? cookies.get('jwt') : null,
      agreed: false,
      modelOpen: false,

      agree: false,
      fullName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      referalCode: this.props.match.params.referalCode,
      showMessage: false,
      message: '',
      error: '',
      pwdStrength: '',
      pwdStrengthClass: '',
      registerResponse: '',
      referal_status: '',
      referalCopy: '',
      privacyPolicy: false,
      risks: false,
      modal: false,
      key: ''
    };
    this.child = React.createRef();
    this.generateCode = this.generateCode.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSignupForm = this.handleSignupForm.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.validate = this.validate.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setConfirmPassword = this.setConfirmPassword.bind(this);
    this.disable = this.disable.bind(this);
  }

  disable() {
    if (
      this.state.fullName &&
      this.state.lastName &&
      this.state.email &&
      this.state.password &&
      this.state.confirmPassword &&
      this.state.referalCode
    ) {
      return false;
    }
    return true;
  }

  validate() {
    if (!this.state.fullName) {
      this.setState({ error: 'Please enter your First Name' });
      return false;
    }
    if (!this.state.lastName) {
      this.setState({ error: 'Please enter your Last Name' });
      return false;
    }
    if (this.state.fullName.length < 3) {
      this.setState({ error: 'First Name can not be less than 3 characters' });
      return false;
    }
    if (!this.state.email) {
      this.setState({ error: 'Please enter your email' });
      return false;
    }
    if (!this.state.password) {
      this.setState({ error: 'Please enter password' });
      return false;
    }

    if (this.state.password.length < 8) {
      this.setState({ error: 'Password should be at least 8 characters' });
      return false;
    }

    if (this.state.pwdStrength == 'Weak') {
      this.setState({
        error:
          'Password is weak.Password should at least contain one capital letter, one small letter and one numeric'
      });
      return false;
    }

    if (!this.state.confirmPassword) {
      this.setState({ error: 'Please enter confirm password' });
      return false;
    }
    if (!this.state.referalCode) {
      this.setState({ error: 'Please enter referral code' });
      return false;
    }
    if (this.state.referalCode.length < 10) {
      this.setState({
        error: 'Referral Code can not be less than 10 characters'
      });
      return false;
    }

    this.setState({ error: '' });
    return true;
  }

  handlePassword(e) {
    this.setState({
      password: e.target.value,
      showMessage: false
    });

    this.passwordChanged(e);
    // if(this.state.password)
  }

  generateCode() {
    this.props.generateCode({});
  }

  componentWillUnmount() {
    document.body.classList.remove('media-ge-768');
  }
  componentDidMount() {
    const screenWidth = window.outerWidth;
    if (screenWidth <= 768) {
      document.body.classList.add('media-ge-768');
    }
  }

  componentWillReceiveProps(nextProps) {
    // IF Already Loged In GO to Dashboard
    if (nextProps.isLoggedIn === true) {
      this.props.changePage('/markets');
    }
    if (
      this.props.generatecodeResponse !== undefined &&
      this.props.generatecodeResponse !== nextProps.generatecodeResponse
    ) {
      this.setState({
        referalCode: nextProps.generatecodeResponse.data.referalId,
        referalCopy: nextProps.generatecodeResponse.data.referalId,
        referal_status: 2
      });
    }
    if (
      this.props.registerResponse !== undefined &&
      this.props.registerResponse !== nextProps.registerResponse
    ) {
      this.handleResponse(nextProps.registerResponse);
    }
    if (this.state.isFormProcessing !== nextProps.isFormProcessing) {
      this.setState({ isFormProcessing: nextProps.isFormProcessing });
    }
  }
  handleResponse = response => {
    let message = '';

    if (!response.error) {
      message = response.result;
      this.props.signedUp({
        mes: 'Signed up successfully. Please verify your email.'
      });
      this.props.changePage('/login');
    } else if (response.error > 0) {
      this.setState({
        error: response.message
      });
      message = response.message;
      // $("#mySModal").modal("hide");
    }
    this.setState({
      registerResponse: response,
      showMessage: true,
      message
    });
  };
  handleOpenTermsAndConditions = e => {
    e.preventDefault();
    this.setState({ modelOpen: true });
  };

  handleCloseTermsAndConditions = () => {
    this.setState({ modelOpen: false });
  };

  /**
   *Handle Sign In APi
   *@function
   */

  passwordChanged(e) {
    // let strongRegex = new RegExp(
    //   '^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
    //   'g'
    // );

    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})'
    );

    let mediumRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

    // let mediumRegex = new RegExp(
    //   '^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
    //   'g'
    // );
    let enoughRegex = new RegExp('(?=.{6,}).*', 'g');

    var pwd = e.target;

    if (pwd.value.length == 0) {
      this.setState({
        pwdStrength: ''
      });
    } else if (false == enoughRegex.test(pwd.value) || pwd.value.length < 8) {
      this.setState({
        pwdStrength: 'Password should be at least 8 characters',
        pwdStrengthClass: 'blackColor'
      });
    } else if (strongRegex.test(pwd.value)) {
      this.setState({
        pwdStrength: 'Strong',
        pwdStrengthClass: 'greenColor'
      });
    } else if (mediumRegex.test(pwd.value)) {
      this.setState({
        pwdStrength: 'Medium',
        pwdStrengthClass: 'blueColor'
      });
    } else {
      this.setState({
        pwdStrength: 'Weak',
        pwdStrengthClass: 'redColor'
      });
    }
  }

  handleSignupForm() {
    if (this.validate()) {
      if (this.state.confirmPassword === this.state.password) {
        if (this.state.agree) {
          // e.preventDefault();
          // if (this.state.privacyPolicy) {
          if (this.state.risks) {
            this.setState({ error: '' });
            this.setState({ showMessage: false, message: '' });
            console.log('risk');
            this.state.agree &&
              this.props.signup({
                full_name: this.state.fullName + ' ' + this.state.lastName,
                email: this.state.email,
                password: this.state.password,
                referee: this.state.referalCode,
                referal_status: this.state.referal_status
              });
          } else {
            this.setState({ showMessage: false, message: '' });
            this.setState({ error: 'Please do agree Risks involved' });
          }
          // } else {
          //   this.setState({ showMessage: false, message: '' });
          //   this.setState({ error: 'Please do agree Privacy Policies' });
          // }
        } else {
          // e.preventDefault();
          this.setState({ showMessage: false, message: '' });
          this.setState({ error: 'Please do agree Terms and Conditions' });
        }
      } else {
        this.setState({ showMessage: false, message: '' });
        this.setState({
          error: 'Password and Confirm password fields must be same'
        });
      }
    }
  }

  setPassword(e) {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      this.setState({ password: '' });
    }
  }
  setConfirmPassword(e) {
    if (e.key == 'Backspace' || e.key == 'Delete') {
      this.setState({ confirmPassword: '' });
    }
  }
  restrict = e => {
    if ((e.keyCode >= 65 && e.keyCode <= 90) || e.keyCode == 190) {
      return true;
    } else {
      e.preventDefault();
    }
  };
  toggle(k) {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.child.current.set(k);
  }

  untoggle = () => {
    this.setState(prevState => ({
      modal: '',
      key: ''
    }));
  };

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <meta
            property="og:title"
            content={`Use my code ‘${this.state.referalCode}’ while signing up on ThroughBit and get 20% off on your first transaction fee.`}
          />
          <meta
            property="og:description"
            content={`Use my code ‘${this.state.referalCode}’ while signing up on ThroughBit and get 20% off on your first transaction fee.`}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Helmet>
        <FooterModal
          untoggle={this.untoggle}
          ref={this.child}
          modall={this.state.modal}
        />

        <div id="kyc-page" className="vh">
          <div className="row no-gutters row-eq-height ">
            <div className="col-lg-8 signin-left">
              <div className="logo mt-2rem">
                <a>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt="Logo"
                    style={{ height: '30px', cursor: 'pointer' }}
                    // onClick={() => this.toggle('/')}
                    onClick={() => {
                      this.props.changePage('/landingPage');
                    }}
                  />
                </a>
              </div>
              <div className="signin-slider">
                <img
                  className="ob-icon-static"
                  src={`${process.env.PUBLIC_URL}/assets/images/signin-carousel/Group.png`}
                  alt="First slide"
                />
                {/*<div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={0}
                    className="active"
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={1}
                  />
                  <li
                    data-target="#carouselExampleIndicators"
                    data-slide-to={2}
                  />
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      className=""
                      src="../assets/images/signin-carousel/Group.png"
                      alt="First slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src="../assets/images/signin-carousel/slide-2.png"
                      alt="Second slide"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      className="d-block w-100"
                      src="../assets/images/signin-carousel/slide-3.png"
                      alt="Third slide"
                    />
                  </div> 
                </div>
              </div>*/}
              </div>
              <div className="welcome-text">
                <h2>Go ahead,</h2>
                <p> a fantastic experience awaits you.</p>
              </div>
            </div>
            {/* Sidebar */}
            <div
              id="sidebar-main"
              className="col-lg-4  col-sm-12 signin-right body-height"
            >
              <h3 className="mt-2rem mb-5">Sign Up</h3>
              <div className="signup-form">
                <input
                  value={this.state.fullName}
                  onChange={e =>
                    this.setState({
                      fullName: e.target.value,
                      showMessage: false
                    })
                  }
                  type="text"
                  name="First Name"
                  placeholder="First Name"
                  required
                />

                <input
                  value={this.state.lastName}
                  onChange={e =>
                    this.setState({
                      lastName: e.target.value,
                      showMessage: false
                    })
                  }
                  type="text"
                  name="Last Name"
                  placeholder="Last Name"
                  required
                />

                <input
                  value={this.state.email}
                  onChange={e =>
                    this.setState({ email: e.target.value, showMessage: false })
                  }
                  type="text"
                  name="Email ID"
                  placeholder="Email ID"
                  required
                />
                <input
                  value={this.state.password}
                  onKeyDown={this.setPassword}
                  onChange={e => this.handlePassword(e)}
                  type="password"
                  name="Password"
                  placeholder="Password"
                  required
                  title={this.state.pwdStrength}
                />

                <p className={this.state.pwdStrengthClass}>
                  {this.state.pwdStrength}
                </p>

                <input
                  value={this.state.confirmPassword}
                  onKeyDown={this.setConfirmPassword}
                  onChange={e =>
                    this.setState({
                      confirmPassword: e.target.value,
                      showMessage: false
                    })
                  }
                  type="password"
                  name="Password"
                  placeholder="Confirm Password"
                  required
                />

                <p
                  hidden={!this.state.referalCode}
                  className="btn btn-link generate generateCodePosition mt-3"
                  onClick={this.generateCode}
                />

                <p
                  hidden={this.state.referalCode}
                  className="btn btn-link generate generateCodePosition"
                  onClick={this.generateCode}
                >
                  Generate
                </p>
                <input
                  maxLength="10"
                  value={this.state.referalCode}
                  onChange={e =>
                    this.setState({
                      referalCode: e.target.value,
                      referal_status: 1
                    })
                  }
                  type="text"
                  name="Referral Code"
                  className="referral-input"
                  placeholder="Referral Code"
                  required
                />
              </div>

              {this.state.error !== '' ? (
                <div className="error" style={{ color: '#f3136a' }}>
                  {this.state.error}
                </div>
              ) : null}

              <div className="form-check terms-checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value={this.state.agree}
                  id="exampleCheck1"
                  onChange={() => this.setState({ agree: !this.state.agree })}
                />
                <div
                  className="form-check-label fw-500"
                  htmlFor="exampleCheck1"
                >
                  I agree to the
                  <a href="#" onClick={() => this.toggle('tnc')}>
                    <span className="fw-500 link"> Terms and Conditions </span>
                  </a>
                  {/* <Button
                    href="#"
                    color="link"
                    onClick={() => this.toggle('tnc')}
                  >
                    <dt className="fw-500">Terms and Conditions</dt>
                  </Button> */}
                  and the
                  <a href="#" onClick={() => this.toggle('pp')}>
                    <span className="fw-500 link"> Privacy Policy</span>
                  </a>
                  {/* <Button
                    href="#"
                    color="link"
                    onClick={() => this.toggle('pp')}
                  >
                    <dt className="fw-500">Privacy Policy</dt>
                  </Button> */}
                </div>
              </div>

              <div className="form-check terms-checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value={this.state.risks}
                  id="exampleCheck1"
                  onChange={() => this.setState({ risks: !this.state.risks })}
                />
                <div
                  className="form-check-label fw-500"
                  htmlFor="exampleCheck1"
                >
                  I have read and fully understand the
                  <a href="#" onClick={() => this.toggle('warnings')}>
                    <span className="fw-500 link"> Risks involved </span>
                  </a>
                  {/* <Button
                    color="link"
                    href="#"
                    onClick={() => this.toggle('warnings')}
                  >
                    <dt className="fw-500"> Risks involved</dt>
                  </Button> */}
                  in crypto currencies
                </div>
              </div>
              {/* // <!-- The Modal --> */}
              <div className="modal" id="mySModal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <!-- Modal Header -->  */}
                    <div className="modal-header">
                      <h4 className="modal-title" style={{ color: 'black' }}>
                        Signed Up
                      </h4>
                    </div>

                    {/* <!-- Modal body -->  */}
                    <div className="modal-body" style={{ color: 'black' }}>
                      Signed up successfully . Please verify your email .
                    </div>
                    {/* <!-- Modal footer --> */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* // <!-- The Modal --> */}
              <div className="modal" id="myModal">
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                      <h4 className="modal-title" style={{ color: 'black' }}>
                        Terms and Conditions
                      </h4>
                    </div>
                    {/*         
        <!-- Modal body --> */}
                    <div className="modal-body" style={{ color: 'black' }}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </div>

                    {/* <!-- Modal footer --> */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="signin-button row">
                <button
                  disabled={this.disable()}
                  // data-toggle="modal"
                  // data-target="#mySModal"
                  onClick={this.handleSignupForm}
                  className="btn btn-primary"
                  defaultValue="SIGN UP"
                >
                  SIGN UP
                </button>
              </div>

              <div className="d-flex ">
                {/* <p className="mt-4" style={{ color: 'black' }}>
                Already have an account{' '}
              </p> */}
                <p className="mt-4">""</p>
                <Link className="mt-4 signupLinkPos" to="/login">
                  Login
                </Link>
              </div>
              {/* <p className="mt-4 signupLinkPos">
              Already a member? <a href="/login"> Log In</a>
            </p> */}
            </div>
            {/* Sidebar ends */}
          </div>
        </div>
      </HelmetProvider>

      // <div className="row justify-content-sm-center">
      //   <div className="col-sm-10 col-md-8 col-lg-5">
      //     <div className="text-center">
      //       <h5>Sign up to start trading</h5>
      //     </div>
      //     <form onSubmit={this.handleSignupForm}>
      //       <div className="form-sec1">
      //         <div className="form-group">
      //           <label>Full Name</label>
      //           <input
      //             className="form-control"
      //             type="text"
      //             name="full_name"
      //             placeholder="Satoshi Nakomoto"
      //             value={this.state.fullName}
      //             onChange={e =>
      //               this.setState({
      //                 fullName: e.target.value,
      //                 showMessage: false
      //               })
      //             }
      //             required
      //           />
      //         </div>
      //         <div className="form-group">
      //           <label>Email</label>
      //           <input
      //             className="form-control"
      //             type="email"
      //             name="email"
      //             placeholder="satoshi@domain.com"
      //             value={this.state.email}
      //             onChange={e =>
      //               this.setState({ email: e.target.value, showMessage: false })
      //             }
      //             required
      //           />
      //         </div>
      //         <div className="form-group">
      //           <label>Password</label>
      //           <input
      //             className="form-control"
      //             type="password"
      //             name="password"
      //             placeholder="password"
      //             value={this.state.password}
      //             onChange={e =>
      //               this.setState({
      //                 password: e.target.value,
      //                 showMessage: false
      //               })
      //             }
      //             required
      //           />
      //         </div>
      //         <div className="form-group">
      //           <label>Confirm Password</label>
      //           <input
      //             className="form-control"
      //             type="password"
      //             name="confirm_password"
      //             placeholder="password"
      //             value={this.state.confirmPassword}
      //             onChange={e =>
      //               this.setState({
      //                 confirmPassword: e.target.value,
      //                 showMessage: false
      //               })
      //             }
      //             required
      //           />
      //         </div>
      //         <p className="forgot-password forgot-password1">
      //           By signing up, you agree to the{' '}
      //           <button
      //             onClick={this.handleOpenTermsAndConditions}
      //             className="link"
      //           >
      //             Terms & Conditions
      //           </button>
      //           .
      //         </p>
      //         {this.state.error !== '' ? (
      //           <div className="error-details">{this.state.error}</div>
      //         ) : null}
      //         {this.state.showMessage && <div>{this.state.message}</div>}
      //         <div className="form-group last-form-group">
      //           <button
      //             className="submit-button"
      //             type="submit"
      //             onSubmit={this.handleSignupForm}
      //           >
      //             Create an account
      //           </button>
      //         </div>
      //         <p style={{ fontSize: 13 }}>
      //           <strong>
      //             Already have an account?{' '}
      //             <Link to="/login" className="small-button">
      //               Sign In
      //             </Link>
      //           </strong>
      //         </p>
      //       </div>
      //     </form>
      //   </div>
      //   {this.state.modelOpen && (
      //     <div id="overlay">
      //       <div className="popup" style={{ height: '25em', overflow: 'auto' }}>
      //         <span
      //           className="close"
      //           onClick={this.handleCloseTermsAndConditions}
      //         >
      //           X
      //         </span>
      //         <h4>Sign up to start trading</h4>
      //         <p>
      //           At vero eos et accusamus et iusto odio dignissimos ducimus qui
      //           blanditiis praesentium voluptatum deleniti atque corrupti quos
      //           dolores et quas molestias excepturi sint occaecati cupiditate
      //           non provident, similique sunt in culpa qui officia deserunt
      //           mollitia animi, id est laborum et dolorum fuga. Et harum quidem
      //           rerum facilis est et expedita distinctio. Nam libero tempore,
      //           cum soluta nobis est eligendi optio cumque nihil impedit quo
      //           minus id quod maxime placeat facere possimus, omnis voluptas
      //           assumenda est, omnis dolor repellendus.
      //         </p>
      //         <p>
      //           At vero eos et accusamus et iusto odio dignissimos ducimus qui
      //           blanditiis praesentium voluptatum deleniti atque corrupti quos
      //           dolores et quas molestias excepturi sint occaecati cupiditate
      //           non provident, similique sunt in culpa qui officia deserunt
      //           mollitia animi, id est laborum et dolorum fuga. Et harum quidem
      //           rerum facilis est et expedita distinctio. Nam libero tempore,
      //           cum soluta nobis est eligendi optio cumque nihil impedit quo
      //           minus id quod maxime placeat facere possimus, omnis voluptas
      //           assumenda est, omnis dolor repellendus. Temporibus autem
      //           quibusdam et aut officiis debitis aut rerum necessitatibus saepe
      //           eveniet ut et voluptates repudiandae sint et molestiae non
      //           recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
      //           ut aut reiciendis voluptatibus maiores alias consequatur aut
      //           perferendis doloribus asperiores repellat
      //         </p>
      //         <div className="agree">
      //           <input
      //             type="checkbox"
      //             id="agree"
      //             value={this.state.agree}
      //             checked={this.state.agree}
      //             onChange={e => this.setState({ agree: !this.state.agree })}
      //           />
      //           <label for="agree">
      //             <span>Agree</span>
      //           </label>
      //         </div>
      //       </div>
      //     </div>
      //   )}
      // </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signup: formData => signup(formData),
      changePage: page => push(page),
      generateCode: () => generateCode(),
      signedUp: formData => signedUp(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isFormProcessing: state.signup.isFormProcessing,
    registerResponse: state.signup.registerResponse,
    generatecodeResponse: state.signup.generatecodeResponse,
    isLoggedIn: state.signin.isLoggedIn
  }),
  mapDispatchToProps
)(Signup);
