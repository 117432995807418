/**
 * Change Email Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { changeEmail } from '../settingsReducer';
import { Popover, PopoverBody } from 'reactstrap';

const cookies = new Cookies();
let email = cookies.get('email');
const emailReg = /\S+@\S+\.\S+/;

/**
 * Represents a Security Email Class.
 * @class extends React.Component
 */
class SecurityEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      new_email: '',
      error: '',
      popOverOpen: false,
      responseMessage: '',
      changeEmailLoader: false,
      changeEmailResponse: ''
    };
    this.handleResponse = this.handleResponse.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  togglePopOver(e) {
    e.preventDefault();
    this.setState({
      popOverOpen: !this.state.popOverOpen
    });
  }

  componentWillReceiveProps(nextProps) {
    // loader
    if (this.props.changeEmailLoader !== nextProps.changeEmailLoader) {
      this.setState({ changeEmailLoader: nextProps.changeEmailLoader });
    }
    // Handle Response Data
    if (
      this.props.changeEmailResponse !== undefined &&
      this.props.changeEmailResponse !== nextProps.changeEmailResponse
    ) {
      this.handleResponse(nextProps.changeEmailResponse);
      this.setState({ changeEmailResponse: nextProps.changeEmailResponse });
    }
    // if (nextProps.profile !== undefined) {
    //   email = email || nextProps.profile.email;
    // }
  }

  redirectToLogin() {
    setTimeout(() => {
      if (this.props.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }, 2000);
  }

  /**
   * Handle Change Email Response
   * @function
   * @param {object} => response Data from the Api
   */

  handleResponse = response => {
    if (response.error === 0) {
      const jwtObj = {
        maxAge: 10800,
        path: '/'
      };
      if (window.location.hostname !== 'localhost') {
        // jwtObj.sameSite = 'strict';
        // jwtObj.secure = true;
        // jwtObj.httpOnly = true;
      }
      //When You call the ChangePassword Function on Success Update the response mesaage as success and set the jwt
      cookies.set('jwt', response.jwt, jwtObj);
      // cookies.set('jwt', response.jwt);
      this.setState({ responseMessage: response.message, error: '' });
    } else {
      // If unAuthorized Login
      this.setState({ responseMessage: '' });
      this.setState({ error: response.message });
    }
  };

  /**
   * Calls The APi for Chnage Email address
   * @function
   * @param {object} => response Data from the Api
   */

  handleChangeEmail = e => {
    e.preventDefault();
    this.props.changeEmail({
      // email: this.state.email,
      new_email: this.state.new_email,
      password: this.state.password
    });
  };

  render() {
    return (
      <div className="container-fluid mt-5">
        <div className="row no-gutters justify-content-center settings-form">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="title">Change Email</div>
            <form
              onSubmit={
                this.state.email !== '' &&
                this.state.new_email !== '' &&
                this.state.password !== '' &&
                !this.state.changeEmailLoader
                  ? this.handleChangeEmail
                  : undefined
              }
            >
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  placeholder="abc@abc.com"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  name="email1"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label>New Email</label>
                <input
                  className="form-control"
                  type="email"
                  id="new_email"
                  placeholder="abc@abc.com"
                  value={this.state.new_email}
                  onChange={e => this.setState({ new_email: e.target.value })}
                  name="new_email"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  placeholder="******"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                  name="password1"
                  required={true}
                />

                {this.state.error !== '' ? (
                  <div className="error-details">{this.state.error}</div>
                ) : null}

                {this.state.responseMessage !== '' ? (
                  <div className="acc-details">
                    {/* {this.props.changePage('confirmEmail')} */}

                    {this.state.responseMessage}
                  </div>
                ) : null}
              </div>

              {/* <button
                type="submit"
                className="btn btn-blue"
                disabled={
                  this.state.email === '' ||
                  this.state.new_email === '' ||
                  this.state.password === ''
                }
                onSubmit={this.handleChangeEmail}
              >
                Confirm
              </button> */}

              {(this.state.email === '' ||
                this.state.new_email === '' ||
                this.state.password === '') && (
                <>
                  <button
                    type="submit"
                    className="btn btn-blue"
                    id="Popover1"
                    onClick={this.togglePopOver}
                  >
                    Confirm
                  </button>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.popOverOpen}
                    target="Popover1"
                    toggle={this.togglePopOver}
                  >
                    <PopoverBody>
                      Please enter Email, New Email and Password.
                    </PopoverBody>
                  </Popover>
                </>
              )}

              {this.state.email !== '' &&
                this.state.new_email !== '' &&
                this.state.password !== '' &&
                (!this.state.changeEmailLoader ? (
                  <button
                    className="btn btn-blue"
                    onSubmit={this.handleChangeEmail}
                  >
                    Confirm
                  </button>
                ) : (
                  <button className="btn btn-primary btn-block">
                    <i className="fa fa-spinner fa-spin fa-fw" />
                  </button>
                ))}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeEmail: formData => changeEmail(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    changeEmailLoader: state.settings.changeEmailLoader,
    changeEmailResponse: state.settings.changeEmailResponse
  }),
  mapDispatchToProps
)(SecurityEmail);
