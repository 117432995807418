import React, { Component } from 'react';
import { render } from 'react-dom';
import MyChart from './MyChart';
import './style.css';

class App extends Component {
  state = {
    ohlc: [],
    volume: [],
    plotOptions: {
      series: {
        dataGrouping: {
          forced: true,
          units: [['month', [1]]]
        }
      }
    }
  };

  componentDidMount() {
    fetch(
      'https://rawcdn.githack.com/highcharts/highcharts/1a37c045270770251ac68b963614ebcca9b0f5d8/samples/data/aapl-ohlcv.json'
    )
      .then(res => {
        if (!res.ok) {
          return [];
        }
        return res.json();
      })
      .then(data => {
        // split the data set into ohlc and volume
        const ohlc = [];
        const volume = [];

        data.forEach(point => {
          ohlc.push([
            point[0], // the date
            point[1], // open
            point[2], // high
            point[3], // low
            point[4] // close
          ]);

          volume.push([
            point[0], // the date
            point[5] // the volume
          ]);
        });

        this.setState({
          ohlc,
          volume
        });
      });
  }

  changeDataGrouping = (unit, count) => {
    const plotOptions = {
      series: {
        dataGrouping: {
          forced: true,
          units: [[unit, [count]]]
        }
      }
    };
    this.setState({ plotOptions });
  };

  handleUpdate = e => {
    const chart = e.target;
    chart.navigationBindings.update();
  };

  render() {
    const { ohlc, volume } = this.state;
    if (ohlc.length === 0) return null;

    return (
      <div className="app">
        Candlesticks:{' '}
        <button onClick={() => this.changeDataGrouping('day', 1)}>1-Day</button>
        <button onClick={() => this.changeDataGrouping('day', 3)}>3-Day</button>
        <button onClick={() => this.changeDataGrouping('week', 1)}>
          1-Week
        </button>
        <button onClick={() => this.changeDataGrouping('month', 1)}>
          1-Month
        </button>
        <MyChart
          ohlc={ohlc}
          volume={volume}
          onUpdate={this.handleUpdate}
          plotOptions={this.state.plotOptions}
        />
      </div>
    );
  }
}

export default App;
