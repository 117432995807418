import React from 'react';
import Highcharts from 'highcharts/highstock';
import addIndicatorsAllModule from 'highcharts/indicators/indicators-all';
import addDragPanesModule from 'highcharts/modules/drag-panes';
import addAnnotationsAdvancedModule from 'highcharts/modules/annotations-advanced';
import addPriceIndicatorModule from 'highcharts/modules/price-indicator';
import addFullScreenModule from 'highcharts/modules/full-screen';
import addStockToolsModule from 'highcharts/modules/stock-tools';
import addHighchartsMore from 'highcharts/highcharts-more';
import addBoostModule from 'highcharts/modules/boost';
import addMapModule from 'highcharts/modules/map';
import {
  HighchartsStockChart,
  Chart,
  withHighcharts,
  XAxis,
  YAxis,
  MapNavigation,
  CandlestickSeries,
  ColumnSeries,
  Navigator,
  RangeSelector,
  Tooltip
} from 'react-jsx-highstock';
import DateRangePickers from 'react-jsx-highstock-datepickers';
import 'react-jsx-highstock-datepickers/dist/index.css';

addIndicatorsAllModule(Highcharts);
addDragPanesModule(Highcharts);
addAnnotationsAdvancedModule(Highcharts);
addPriceIndicatorModule(Highcharts);
addFullScreenModule(Highcharts);
// addStockToolsModule(Highcharts);
addHighchartsMore(Highcharts);
addBoostModule(Highcharts);
addMapModule(Highcharts);

class MyChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plotOptions: this.props.plotOptions
    };
  }

  tooltipPositioner(width, height, point) {
    const chart = this.chart;
    let position = {};

    if (point.isHeader) {
      position = {
        x: Math.max(
          // Left side limit
          chart.plotLeft,
          Math.min(
            point.plotX + chart.plotLeft - width / 2,
            // Right side limit
            chart.chartWidth - width - chart.marginRight
          )
        ),
        y: point.plotY
      };
    } else {
      position = {
        x: point.series.chart.plotLeft,
        y: point.series.yAxis.top - chart.plotTop
      };
    }

    return position;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.plotOptions !== prevProps.plotOptions) {
      this.setState({ plotOptions: this.props.plotOptions });
    }
  }

  getChart = chart => {
    this.chart = chart;
  };

  render() {
    return (
      <HighchartsStockChart
        className="chart-overflow"
        plotOptions={this.state.plotOptions}
        callback={this.getChart}
      >
        <Chart
          onUpdate={this.props.onUpdate}
          // zoomType='xy'
          panning
        />

        {/* <MapNavigation enableMouseWheelZoom /> */}

        <RangeSelector>
          <RangeSelector.Button count={1} type="day">
            1d
          </RangeSelector.Button>
          <RangeSelector.Button count={7} type="day">
            7d
          </RangeSelector.Button>
          <RangeSelector.Button count={1} type="month">
            1m
          </RangeSelector.Button>
          <RangeSelector.Button type="all">All</RangeSelector.Button>
          {/* <RangeSelector.Input boxBorderColor="#7cb5ec" /> */}
        </RangeSelector>

        <Tooltip
          split
          shape="square"
          headerShape="callout"
          borderWidth={0}
          shadow={false}
          positioner={this.tooltipPositioner}
        />

        <XAxis crosshair>
          <XAxis.Title>Time</XAxis.Title>
        </XAxis>

        <YAxis
          opposite
          labels={{
            align: 'left'
          }}
          height="80%"
          resize={{
            enabled: true
          }}
        >
          <CandlestickSeries
            id="ohlc"
            name="AAPL Stock Price"
            data={this.props.ohlc}
          />
        </YAxis>

        <YAxis
          opposite
          labels={{
            align: 'left'
          }}
          top="80%"
          height="20%"
          offset={0}
        >
          <ColumnSeries id="vol" name="AAPL Volume" data={this.props.volume} />
        </YAxis>

        <DateRangePickers axisId="xAxis" />

        <Navigator>
          <Navigator.Series seriesId="ohlc" />
        </Navigator>
      </HighchartsStockChart>
    );
  }
}

export default withHighcharts(MyChart, Highcharts);
