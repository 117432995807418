import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavBar from './navBar';

class ConfirmEmail extends React.Component {
  componentDidMount() {
    document.body.classList.add('text-center');
    document.body.id = 'notification-page';
  }
  componentWillUnmount() {
    document.body.classList.remove('text-center');
    document.body.id = undefined;
  }
  render() {
    return (
      <>
        <NavBar />
        <div
          className="jumbotron d-flex align-items-center"
          style={{ height: '85vh' }}
        >
          <div className="container text-center w-50">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/confirm.png`}
              className="icon-notification mb-3"
            />
            <h1 className="cover-heading mb-3">Email verified</h1>
            <p className="lead">Your email has been successfully verified.</p>
            <button
              className="btn btn-primary btn-buy btn-block w-50"
              onClick={() => this.props.changePage('login')}
              style={{ margin: '0 auto' }}
            >
              Login
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({}),
  mapDispatchToProps
)(ConfirmEmail);
