import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import Cookies from 'universal-cookie';
import createRootReducer from './modules';

export const history = createHistory();
const cookies = new Cookies();

const initialState = {
  app: {
    orderFee: '',
    activeMarketValue: 0,
    activeCurrencyValue: 1,
    activeMarketSymbol: 'BTC',
    activeCurrencySymbol: 'ETH'
  },
  signin: {
    isLoggedIn: cookies.get('jwt') != null,
    isFormProcessing: false,
    signinResponse: {},
    kyc_status: cookies.get('kyc') != null
  }
};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
