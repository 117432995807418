/**
 * Dashboard Reducer
 *Reducer
 *@event
 */

import apiCaller from 'Utils/apiCallerNew';

const ORDER_HISTORY_REQUESTED = 'ORDER_HISTORY_REQUESTED';
const ORDER_BUY_REQUESTED = 'ORDER_BUY_REQUESTED';
const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
const ORDER_BUY_SUCCESS = 'ORDER_BUY_SUCCESS';
const ADD_BUY_FALCON = 'ADD_BUY_FALCON';
const ADD_SELL_FALCON = 'ADD_SELL_FALCON';
const GET_WALLET = 'GET_WALLET';
const ADD_SEND_BTC = 'ADD_SEND_BTC';
const ADD_SEND_ETH = 'ADD_SEND_ETH';
const ADD_SEND_LSK = 'ADD_SEND_LSK';
const ADD_SEND_INRF = 'ADD_SEND_INRF';
const ADD_BUY_TRADE = 'ADD_BUY_TRADE';
const ADD_SELL_TRADE = 'ADD_SELL_TRADE';
const ADD_UPDATE_FALCON = 'ADD_UPDATE_FALCON';
const ADD_BUY_SELL_MODE = 'ADD_BUY_SELL_MODE';
const ADD_ADDRESS_GENERATION = 'ADD_ADDRESS_GENERATION';
const FORM_ACTIVE_TAB_UPDATE = 'FORM_ACTIVE_TAB_UPDATE';
const BALANCE_ACTIVE_TAB_UPDATE = 'BALANCE_ACTIVE_TAB_UPDATE';
const CHART_ACTIVE_TAB_UPDATE = 'CHART_ACTIVE_TAB_UPDATE';
const ACTIVE_MARKET_COIN_24_HR_PRICE = 'ACTIVE_MARKET_COIN_24_HR_PRICE';
const SOCKET_FETCHING = 'SOCKET_FETCHING';
const CHANGE_UI_HEIGHT = 'CHANGE_UI_HEIGHT';

const initialState = {
  isGraphLoading: false,
  timePeriod: 0,
  orderDetails: {},
  orderHistoryLoader: false,
  buyFalcon: {},
  updateFalcon: {},
  sellFalcon: {},
  walletData: {},
  sendBTC: {},
  sendETH: {},
  sendLSK: {},
  sendINRF: {},
  buyTrade: {},
  sellTrade: {},
  mode: 'buy',
  addressGenerated: {},
  formActiveTab: 'limit',
  balanceActiveTab: 'balance',
  chartActiveTab: 'tradingView',
  activeMarketCoin24HrPriceData: [],
  openOrdersFirstTabDataLimit: '9',
  sidebar_main_height: 0,
  news_feed_height: 0,
  socketFetching: false
};

/**
 * Reducer
 * @event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_HISTORY_REQUESTED:
      return {
        ...state,
        orderHistoryLoader: true
      };
    case ORDER_BUY_REQUESTED:
      return {
        ...state,
        orderHistoryLoader: true
      };
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistoryLoader: false,
        orderDetails: action.response
      };
    case ORDER_BUY_SUCCESS:
      return {
        ...state,
        orderHistoryLoader: false,
        orderDetails: action.response
      };
    case ADD_BUY_FALCON:
      return {
        ...state,
        buyFalcon: action.response
      };
    case ADD_SELL_FALCON:
      return {
        ...state,
        sellFalcon: action.response
      };
    case GET_WALLET:
      return {
        ...state,
        walletData: action.response
      };
    case ADD_SEND_BTC:
      return {
        ...state,
        sendBTC: action.response
      };
    case ADD_SEND_ETH:
      return {
        ...state,
        sendETH: action.response
      };
    case ADD_SEND_LSK:
      return {
        ...state,
        sendLSK: action.response
      };
    case ADD_SEND_INRF:
      return {
        ...state,
        sendINRF: action.response
      };
    case ADD_BUY_TRADE:
      return {
        ...state,
        buyTrade: action.response
      };
    case ADD_SELL_TRADE:
      return {
        ...state,
        sellTrade: action.response
      };
    case ADD_UPDATE_FALCON:
      return {
        ...state,
        updateFalcon: action.response
      };

    case ADD_BUY_SELL_MODE:
      return {
        ...state,
        mode: action.mode
      };

    case ADD_ADDRESS_GENERATION:
      return {
        ...state,
        addressGenerated: {
          ...action.response,
          currency: action.currency
        }
      };
    case FORM_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        formActiveTab: action.formActiveTab
      };
    case BALANCE_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        balanceActiveTab: action.balanceActiveTab
      };
    case CHART_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        chartActiveTab: action.chartActiveTab
      };
    case ACTIVE_MARKET_COIN_24_HR_PRICE:
      return {
        ...state,
        activeMarketCoin24HrPriceData: action.response
      };
    case SOCKET_FETCHING:
      return {
        ...state,
        socketFetching: action.socketFetching
      };
    case CHANGE_UI_HEIGHT:
      return {
        ...state,
        full_height: action.full_height,
        dashboard_main_height: action.dashboard_main_height,
        sidebar_main_height: action.sidebar_main_height,
        market_information_height: action.market_information_height,
        data_table_height: action.data_table_height,
        analysis_tab_scroll_height: action.analysis_tab_scroll_height,
        news_feed_height: action.news_feed_height,
        graph_box_height: action.graph_box_height,
        tv_graph_height: action.tv_graph_height,
        openOrdersFirstTabDataLimit: action.openOrdersFirstTabDataLimit
      };
    default:
      return state;
  }
};

/**
 * dashboard  Actions
 *@Actions
 */

/**
 * Calls the Api Get Order Details and Dispath the Redux Store
 *@event
 */
export const getOrderDetails = formData => dispatch => {
  dispatch({
    type: ORDER_HISTORY_REQUESTED
  });
  apiCaller('buy/orderList', 'post', formData, false, true, false).then(
    response =>
      dispatch({
        type: ORDER_HISTORY_SUCCESS,
        response
      })
  );
};

export const getBuyOrders = formData => dispatch => {
  dispatch({
    type: ORDER_BUY_REQUESTED
  });
  apiCaller(
    `liveorders/${formData.operation}/${formData.coin}`,
    'get',
    '',
    false,
    true,
    false
  ).then(response =>
    dispatch({
      type: ORDER_BUY_SUCCESS,
      response
    })
  );
};

/**
 * Calls the Api for buying Falcon currency
 * @event
 * @param {Object} formData - formData --> Email and Amount
 */

export const buyFalcon = formdata => dispatch => {
  apiCaller('buy/inrf', 'post', formdata, false, true, false).then(response => {
    dispatch({
      type: ADD_BUY_FALCON,
      response
    });
  });
};

/**
 * Calls the Api for selling Falcon currency
 * @event
 * @param {Object} formData - formData --> Email and Amount
 */

export const sellFalcon = formData => dispatch => {
  apiCaller('sell/inrf', 'post', formData, false, true, false).then(
    response => [
      dispatch({
        type: ADD_SELL_FALCON,
        response
      })
    ]
  );
};

/**
 * Calls the Api for selling Falcon currency
 * @event
 * @param {Object} formData - formData --> Email
 */

export const getWalletDetails = formData => dispatch => {
  apiCaller('balance', 'post', formData).then(response =>
    dispatch({
      type: GET_WALLET,
      response
    })
  );
};

/**
 * Calls the Api for sending BTC
 * @event
 * @param {Object} formData - formData --> email, amount, rec_address, code
 */

export const sendBTC = formData => dispatch => {
  apiCaller('send/btc', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_SEND_BTC,
      response
    });
  });
};

/**
 * Calls the Api for sending ETH
 * @event
 * @param {Object} formData - formData --> email, amount, rec_address, code
 */

export const sendETH = formData => dispatch => {
  apiCaller('send/eth', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_SEND_ETH,
      response
    });
  });
};

/**
 * Calls the Api for sending LSK
 * @event
 * @param {Object} formData - formData --> email, amount, rec_address, code
 */

export const sendLSK = formData => dispatch => {
  apiCaller('send/lsk', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_SEND_LSK,
      response
    });
  });
};

/**
 * Calls the Api for sending INRF
 * @event
 * @param {Object} formData - formData --> email, amount, rec_address, code
 */

export const sendINRF = formData => dispatch => {
  apiCaller('send/inrf', 'post', formData, false, true, false).then(
    response => {
      dispatch({
        type: ADD_SEND_INRF,
        response
      });
    }
  );
};

/**
 * Calls the Api for Buy Trade
 * @event
 * @param {Object} formData - formData --> email, amount, currency, parent_currency, code
 */
export const buyTrade = formData => dispatch => {
  apiCaller('buy', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_BUY_TRADE,
      response
    });
  });
};

/**
 * Calls the Api for Sell Trade
 * @event
 * @param {Object} formData - formData --> email, amount, currency, parent_currency, code
 */
export const sellTrade = formData => dispatch => {
  apiCaller('sell', 'post', formData, false, true, false).then(response => {
    dispatch({
      type: ADD_SELL_TRADE,
      response
    });
  });
};

/**
 * Calls API for Updating Falcon Buy Referance number
 * @event
 * @param {Object} formData - formData --> orderid, txnid, email, code
 */

export const updateFalcon = formData => dispatch => {
  apiCaller('buy/updateTxnId', 'post', formData, false, true, false).then(
    response => {
      dispatch({
        type: ADD_UPDATE_FALCON,
        response
      });
    }
  );
};

/**
 * Changes app mode to buy or sell
 * @event
 * @param {String} mode - mode --> buy | sell
 */

export const changeBuySellMode = mode => dispatch => {
  dispatch({
    type: ADD_BUY_SELL_MODE,
    mode
  });
};

/**
 * @event
 * @param {Object} formData - formData --> Email|Currency
 */

export const generateAddress = formData => dispatch => {
  apiCaller(
    `address/generate/${formData.currency}`,
    'post',
    formData,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: ADD_ADDRESS_GENERATION,
      response,
      currency: formData.currency
    });
  });
};

export const formActiveTabUpdate = formData => dispatch => {
  dispatch({
    type: FORM_ACTIVE_TAB_UPDATE,
    formActiveTab: formData
  });
};

export const balanceActiveTabUpdate = formData => dispatch => {
  dispatch({
    type: BALANCE_ACTIVE_TAB_UPDATE,
    balanceActiveTab: formData
  });
};

export const chartActiveTabUpdate = formData => dispatch => {
  dispatch({
    type: CHART_ACTIVE_TAB_UPDATE,
    chartActiveTab: formData
  });
};

export const activeMarketCoin24HrPrice = formData => dispatch => {
  apiCaller(
    `list/price/${formData.market}/${formData.currency}`,
    'get',
    '',
    false,
    false,
    false
  ).then(response => {
    dispatch({
      type: ACTIVE_MARKET_COIN_24_HR_PRICE,
      response
    });
  });
};

export const changeUIHeightFunc = formData => dispatch => {
  dispatch({
    type: CHANGE_UI_HEIGHT,
    full_height: formData.full_height,
    dashboard_main_height: formData.dashboard_main_height,
    sidebar_main_height: formData.sidebar_main_height,
    market_information_height: formData.market_information_height,
    data_table_height: formData.data_table_height,
    analysis_tab_scroll_height: formData.analysis_tab_scroll_height,
    news_feed_height: formData.news_feed_height,
    graph_box_height: formData.graph_box_height,
    tv_graph_height: formData.tv_graph_height,
    openOrdersFirstTabDataLimit: formData.openOrdersFirstTabDataLimit
  });
};

export const socketFetchingFunc = formData => dispatch => {
  dispatch({
    type: SOCKET_FETCHING,
    socketFetching: formData.socketFetching
  });
};
