import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactAutosuggest from 'react-autosuggest';
import {
  withdrawAutosuggestCurrencyIdUpdate,
  inputTextAllCoinsFunc
} from './withdrawDepositReducer';

const theme = {
  container: {
    position: 'relative'
  },
  input: {
    width: '90%',
    height: 33,
    padding: '10px 20px',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid #aaa',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#ccc'
  },
  inputFocused: {
    outline: 'none'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 180,
    border: '1px solid #aaa',
    backgroundColor: '#ccc',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: 200,
    overflowY: 'auto'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  }
};

class Autosuggest extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      reactSelectOptions: this.props.reactSelectOptions,
      value: '',
      suggestions: [],
      placeholder: 'Type currency name'
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.reactMarketCoinSelect) {
        this.setState({
          reactSelectOptions: this.props.reactMarketCoinSelect,
          placeholder: ''
          // suggestions: this.props.reactMarketCoinSelect
        });
      }
    }, 100);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.reactSelectOptions !== nextProps.reactSelectOptions) {
      this.setState({
        reactSelectOptions: nextProps.reactSelectOptions,
        placeholder: ''
        // suggestions: nextProps.reactSelectOptions
      });
    }

    if (this.props.reactMarketCoinSelect !== nextProps.reactMarketCoinSelect) {
      this.setState({
        reactSelectOptions: nextProps.reactMarketCoinSelect,
        placeholder: ''
        // suggestions: nextProps.reactMarketCoinSelect
      });
    }

    if (
      nextProps.withdrawAutosuggestValue &&
      this.props.withdrawAutosuggestValue !== nextProps.withdrawAutosuggestValue
    ) {
      this.setState(
        { value: '' },
        this.props.inputTextAllCoinsFunc({ withdrawDepositInputText: '' }),
        this.props.withdrawAutosuggestCurrencyIdUpdate({
          withdrawAutosuggestCurrencyId: '-1'
        })
      );
    }
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    this.props.inputTextAllCoinsFunc({ withdrawDepositInputText: value });
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? this.state.reactSelectOptions
      : this.state.reactSelectOptions.filter(obj => {
          return (
            obj.coinname.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 ||
            obj.coinsymbol.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
          );
        });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => {
    this.props.withdrawAutosuggestCurrencyIdUpdate({
      withdrawAutosuggestCurrencyId: suggestion.id
    });
    return suggestion.text;
  };

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => <div>{suggestion.text}</div>;

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    if (newValue === '') {
      this.props.withdrawAutosuggestCurrencyIdUpdate({
        withdrawAutosuggestCurrencyId: '-1'
      });
    }
  };

  render() {
    const { value, suggestions, placeholder } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <ReactAutosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        alwaysRenderSuggestions={true}
      />
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inputTextAllCoinsFunc: formData => inputTextAllCoinsFunc(formData),
      withdrawAutosuggestCurrencyIdUpdate: formData =>
        withdrawAutosuggestCurrencyIdUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    reactSelectOptions: state.app.reactSelectOptions,
    withdrawAutosuggestValue: state.transactions.withdrawAutosuggestValue
  }),
  mapDispatchToProps
)(Autosuggest);
