const CURRENT_PAGE_UPDATE = 'CURRENT_PAGE_UPDATE';
const initialState = { currentPage: 1 };

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_PAGE_UPDATE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    default:
      return state;
  }
};

export const setCurrentPage = formData => dispatch => {
  dispatch({
    type: CURRENT_PAGE_UPDATE,
    currentPage: formData.page
  });
};
