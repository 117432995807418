/**
 * Main Transaction Component
 * Component
 */

import React from 'react';
// import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Transfer from './transfer';
import {
  activeTabUpdate,
  hideZeroBalances,
  getWithDrawHistory,
  searchValueUpdate,
  limitUpdate,
  sortByFavUpdate
} from './withdrawDepositReducer';
import Deposit from './deposit';
import Withdraw from './withdraw';
import DepositWithdraw from './open';
import WithdrawDepositHistory from './history';
import WDSort from './withdrawDepositSideBar';
import bodyTags from 'Utils/bodyTags';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import ErrorBoundary from 'Modules/ErrorBoundary';
import Pagination from 'react-js-pagination';
import { headerActiveMenuUpdate } from 'App/appReducer';
import Autosuggest from './openAutosuggest';

class WithdrawDeposit extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hideZeroFunc = this.hideZeroFunc.bind(this);
    this.sortFav = this.sortFav.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      depositFormView: this.props.depositFormView,
      withdrawFormView: this.props.withdrawFormView,
      hideZero: this.props.hideZero,
      coinSymbolsObj: {},
      autoSuggestData: [],
      reactSelectOptions: this.props.reactSelectOptions,
      sidebar_main_height: window.innerHeight,
      dropdownOpen: false,
      searchData: this.props.searchData,
      count: this.props.count,
      activePage: 1,
      searchValue: '',
      sortbyFav: this.props.sortbyFav
    };
  }
  handleChange(event) {
    this.setState({
      searchValue: event.target.value
    });
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }

  componentDidMount() {
    const limit = parseInt(((window.innerHeight - 120) * 0.6) / 1 / 24.9);
    this.setState({ limit });
    this.props.limitUpdate({ limit });
    bodyTags.addBodyTag();
    this.props.headerActiveMenuUpdate('withdrawal/deposits');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  componentWillUnmount() {
    bodyTags.removeBodyTag();

    this.setState({ activePage: 1 });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.depositFormView !== nextProps.depositFormView) {
      this.setState({ depositFormView: nextProps.depositFormView });
    }

    if (this.props.withdrawFormView !== nextProps.withdrawFormView) {
      this.setState({ withdrawFormView: nextProps.withdrawFormView });
    }

    if (this.props.reactSelectOptions !== nextProps.reactSelectOptions) {
      this.setState({ reactSelectOptions: nextProps.reactSelectOptions });
    }

    if (this.props.hideZero !== nextProps.hideZero) {
      this.setState({ hideZero: nextProps.hideZero });
    }

    if (this.props.sortbyFav !== nextProps.sortbyFav) {
      this.setState({ sortbyFav: nextProps.sortbyFav });
    }
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
    if (this.props.searchData !== nextProps.searchData) {
      this.setState({ searchData: nextProps.searchData });
    }

    if (this.props.count !== nextProps.count) {
      this.setState({ count: nextProps.count });
    }

    if (this.props.limit !== nextProps.limit) {
      this.setState({ limit: nextProps.limit });
    }
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    this.props.getWithDrawHistory({
      end: this.props.dateTo,
      start: this.props.dateFrom,
      operation: 'combined',
      skip: (pageNumber - 1) * this.state.limit,
      limit: this.state.limit
      /*  "start": "2018-01-20",
     "end": "2019-12-30",
     "coinId": "1" */
    });
  }
  onChangePage(pager) {
    this.props.getWithDrawHistory({
      operation: 'combined',
      skip: pager.startIndex,
      limit: parseInt(((window.innerHeight - 120) * 0.6) / 1 / 24.9)
      /*  "start": "2018-01-20",
     "end": "2019-12-30",
     "coinId": "1" */
    });
    // update state with new page of items
    /*   this.setState({
       pageOfItems: pageOfItems ,
       searchData:pageOfItems
    }); */
  }
  hideZeroFunc() {
    this.props.hideZeroBalances({ hideZero: !this.state.hideZero });
    // this.setState({
    //   hideZero: !this.state.hideZero
    // });
  }
  sortFav() {
    // const favArray=localStorage.getItem('favCoinArray');
    // this.setState({
    //   sortbyFav: !this.state.sortbyFav
    // });
    this.props.sortByFavUpdate({
      sortbyFav: !this.state.sortbyFav
    });
  }
  render() {
    const {
      depositFormView,
      withdrawFormView,
      checked,
      hideZero,
      coinSymbolsObj,
      reactSelectOptions,
      searchValue,
      sortbyFav
    } = this.state;

    const { activeTab } = this.props;
    const { limit } = this.state;
    return (
      <div className="row no-gutters">
        <div className="col-lg-9 container-one pl-2 pr-1 mt-1">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${activeTab === '1' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('1');
                }}
                active={(activeTab === '1').toString()}
                aria-selected={activeTab === '1'}
              >
                Withdrawals/Deposits
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${activeTab === '2' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('2');
                }}
                active={(activeTab === '2').toString()}
                aria-selected={activeTab === '2'}
              >
                Withdraw/Deposit History
              </a>
            </li>
            <li className="nav-item ml-auto">
              {/* {activeTab === '1' && reactSelectOptions && <Autosuggest />} */}
              {/* <a className="nav-link link-icon" href="#">
                <i className="fa fa-search" />
              </a> */}
              {activeTab === '1' && (
                <input
                  className="form-control coin-input"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={this.handleChange}
                  style={{ cursor: 'auto' }}
                />
              )}
            </li>

            <li className="nav-item">
              {activeTab === '1' && (
                <Dropdown
                  className="m-2"
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggleDropdown}
                >
                  <DropdownToggle>
                    {(!!this.state.hideZero || !!this.state.sortbyFav) && (
                      <span className="filter-status" />
                    )}
                    <i className="fa fa-filter" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="checkbox-filter"
                        name="zero"
                        checked={!!this.state.hideZero}
                        onChange={this.hideZeroFunc}
                      />{' '}
                      <label
                        htmlFor="zero"
                        className="pl-2 mb-0"
                        onClick={this.hideZeroFunc}
                      >
                        Hide zero balances
                      </label>
                    </DropdownItem>
                    <DropdownItem className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="checkbox-filter"
                        name="sortByFav"
                        checked={!!this.state.sortbyFav}
                        onChange={this.sortFav}
                      />{' '}
                      <label className="pl-2 mb-0" onClick={this.sortFav}>
                        Sort by favourites
                      </label>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </li>
          </ul>
          {/* {activeTab === '1' && (
            <input
              type="checkbox"
              className="checkbox-filter"
              name="zero"
              checked={!!this.state.hideZero}
              onChange={this.hideZeroFunc}
            />
          )}
          {activeTab === '1' && (
            <label htmlFor="zero" onClick={this.hideZeroFunc}>
              Hide zero balances
            </label>
          )} */}

          {/* {activeTab === '1' && reactSelectOptions && <Autosuggest />} */}

          {activeTab === '1' && (
            <ErrorBoundary>
              <DepositWithdraw
                searchValue={searchValue}
                sortbyFav={sortbyFav}
              />
            </ErrorBoundary>
          )}
          {activeTab === '2' && (
            <ErrorBoundary>
              <>
                <WithdrawDepositHistory />
                {this.props.count > limit && (
                  <nav aria-label="Page navigation example">
                    {/*   <Pagination
                      items={this.props.searchData}
                      count={this.props.count}
                      onChangePage={this.onChangePage}
                    /> */}
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={limit}
                      totalItemsCount={this.props.count}
                      pageRangeDisplayed={7}
                      onChange={this.handlePageChange}
                    />
                  </nav>
                )}
              </>
            </ErrorBoundary>
          )}
        </div>

        {activeTab === '1' && depositFormView && (
          <Deposit
            coinSymbolsObj={coinSymbolsObj}
            style={{ height: this.state.sidebar_main_height }}
          />
        )}
        {activeTab === '1' && withdrawFormView && (
          <Withdraw style={{ height: this.state.sidebar_main_height }} />
        )}

        {this.props.activeTab === '2' && (
          <WDSort style={{ height: this.state.sidebar_main_height }} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      searchValueUpdate: formData => searchValueUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      hideZeroBalances: formData => hideZeroBalances(formData),
      getWithDrawHistory: formData => getWithDrawHistory(formData),
      limitUpdate: formData => limitUpdate(formData),
      sortByFavUpdate: formData => sortByFavUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    depositFormView: state.transactions.depositFormView,
    withdrawFormView: state.transactions.withdrawFormView,
    hideZero: state.transactions.hideZero,
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.transactions.activeTab,
    coinSymbolsObj: state.app.coinSymbolsObj,
    reactSelectOptions: state.app.reactSelectOptions,
    count: state.transactions.count,
    searchData: state.transactions.searchData,
    dateFrom: state.transactions.dateFrom,
    dateTo: state.transactions.dateTo,
    sortbyFav: state.transactions.sortbyFav,
    limit: state.transactions.limit
  }),
  mapDispatchToProps
)(WithdrawDeposit);
