import _ from 'underscore';
import apiCaller from 'Utils/apiCallerNew';
import statusCheck from 'Utils/statusCheck';

const MY_ORDER_HISTORY_REQUESTED = 'MY_ORDER_HISTORY_REQUESTED';
const MY_ORDER_HISTORY_SUCCESS = 'MY_ORDER_HISTORY_SUCCESS';
const MY_OPEN_ORDERS_REQUESTED = 'MY_OPEN_ORDERS_REQUESTED';
const MY_OPEN_ORDERS_SUCCESS = 'MY_OPEN_ORDERS_SUCCESS';
const MY_BALANCE_REQUESTED = 'MY_BALANCE_REQUESTED';
const MY_BALANCE_SUCCESS = 'MY_BALANCE_SUCCESS';
const UPDATE_MY_OPEN_ORDERS = 'UPDATE_MY_OPEN_ORDERS';
const UPDATE_MY_ORDER_HISTORY = 'UPDATE_MY_ORDER_HISTORY';
const MY_ZERO_BALANCE = 'MY_ZERO_BALANCE';
const REMOVE_MY_OPEN_ORDER_REQUEST = 'REMOVE_MY_OPEN_ORDER_REQUEST';
const REMOVE_MY_OPEN_ORDER_IN_LIST = 'REMOVE_MY_OPEN_ORDER_IN_LIST';
const MY_TRADE_METRICS_REQUESTED = 'MY_TRADE_METRICS_REQUESTED';
const MY_TRADE_METRICS_SUCCESS = 'MY_TRADE_METRICS_SUCCESS';

const MY_ORDER_HISTORY_BALANCE = 'MY_ORDER_HISTORY_BALANCE';
const SELECTED_MY_ORDER_HISTORY_DATA = 'SELECTED_MY_ORDER_HISTORY_DATA';

const initialState = {
  getMyOpenOrdersList: [],
  getMyBalanceList: {},
  getMyOrderHistoryList: [],
  selectedMyOrderHistoryData: [],
  totalBuy: '',
  avgBuyPrice: '',
  totalSell: '',
  avgSellPrice: '',
  breakEvenPrice: '',
  profitLoss: '',
  myBalanceLoader: true,
  // myOrderHistoryBalanceCalcData: {
  //   totalBuy: '',
  //   avgBuyPrice: '',
  //   totalSell: '',
  //   avgSellPrice: '',
  //   breakEvenPrice: '',
  //   profitLoss: ''
  // },
  myOrderHistoryResponse: false,
  myOrderHistoryBalanceLoader: true,
  getRemoveOrderResponse: {},
  getMyTradeMetricsList: {},
  myTradeMetricsLoader: true
};

const updateMyOpenOrderFunc = (
  getMyOpenOrdersList,
  myOpenOrderUpdateObject
) => {
  const newMyOpenOrdersList = getMyOpenOrdersList.map(data => {
    if (
      myOpenOrderUpdateObject &&
      data.operation ===
        statusCheck.buySellStatusCheck(myOpenOrderUpdateObject.operation) &&
      data.bid === myOpenOrderUpdateObject.bid
    ) {
      return { ...data, volume: data.volume + myOpenOrderUpdateObject.volume };
    } else {
      return data;
    }
  });
  return newMyOpenOrdersList;
};

const updateMyOrderHistoryFunc = (
  getMyOrderHistoryList,
  myOrderHistoryUpdateObject
) => {
  return getMyOrderHistoryList.map(data => {
    if (
      myOrderHistoryUpdateObject &&
      statusCheck.buySellStatusCheck(data.operation) ===
        myOrderHistoryUpdateObject.operation &&
      data.executionBid === myOrderHistoryUpdateObject.bid
    ) {
      return {
        ...data,
        volume: data.volume + myOrderHistoryUpdateObject.volume
      };
    }
  });
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case MY_TRADE_METRICS_REQUESTED:
      return {
        ...state,
        myTradeMetricsLoader: true
      };
    case MY_TRADE_METRICS_SUCCESS:
      return {
        ...state,
        myTradeMetricsLoader: false,
        getMyTradeMetricsList: action.response
      };
    case MY_ORDER_HISTORY_REQUESTED:
      return {
        ...state,
        myOrderHistoryLoader: true
      };
    case MY_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        myOrderHistoryLoader: false,
        myOrderHistoryResponse: true,
        getMyOrderHistoryList: action.response
      };
    case MY_OPEN_ORDERS_REQUESTED:
      return {
        ...state,
        myOpenOrdersLoader: true
      };
    case MY_OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        myOpenOrdersLoader: false,
        getMyOpenOrdersList: action.response
      };
    case MY_BALANCE_REQUESTED:
      return {
        ...state,
        myBalanceLoader: true
      };
    case MY_BALANCE_SUCCESS:
      const balanceList = {};
      if (action.response.error === 0) {
        action.response.data.map(obj => {
          balanceList[obj.coinId] = { ...obj };
        });
        return {
          ...state,
          myBalanceLoader: false,
          getMyBalanceList: balanceList
        };
      }
    case MY_ORDER_HISTORY_BALANCE:
      return {
        ...state,
        myOrderHistoryBalanceCalcData: action.myOrderHistoryBalanceCalcData,
        myOrderHistoryBalanceLoader: false
      };
    case SELECTED_MY_ORDER_HISTORY_DATA:
      return {
        ...state,
        selectedMyOrderHistoryData: action.selectedMyOrderHistoryData
      };
    case MY_ZERO_BALANCE:
      return {
        ...state,
        getMyBalanceList: action.getMyBalanceList
      };
    case UPDATE_MY_OPEN_ORDERS: {
      updateMyOpenOrderFunc(
        state.getMyOpenOrdersList,
        action.myOpenOrderUpdateObject.myOpenOrderUpdateObject
      );
      // return {
      //   ...state,
      //   getMyOpenOrdersList: {...state.getMyOpenOrdersList, ...action.myOpenOrderUpdateObject}
      // };
    }
    case UPDATE_MY_ORDER_HISTORY: {
      updateMyOrderHistoryFunc(
        state.getMyOrderHistoryList,
        action.myOrderHistoryUpdateObject
      );
      // return {
      //   ...state,
      //   getMyOrderHistoryList: {...state.getMyOrderHistoryList, ...action.myOrderHistoryUpdateObject}
      // };
    }
    case REMOVE_MY_OPEN_ORDER_REQUEST:
      return {
        ...state,
        getRemoveOrderResponse: action.response
      };
    case REMOVE_MY_OPEN_ORDER_IN_LIST:
      const newMyOpenOrdersList = _.reject(
        state.getMyOpenOrdersList.data,
        obj => {
          return obj.orderId === action.jsonObj.orderId;
        }
      );
      return {
        ...state,
        getMyOpenOrdersList: {
          ...state.getMyOpenOrdersList,
          data: newMyOpenOrdersList
        }
      };
    default:
      return state;
  }
};

export const selectedMyOrderHistory = formdata => dispatch => {
  dispatch({
    type: SELECTED_MY_ORDER_HISTORY_DATA,

    selectedMyOrderHistoryData: formdata.selectedMyOrderHistoryData
  });
};

export const myOrderHistoryBalanceFunc = formdata => dispatch => {
  dispatch({
    type: MY_ORDER_HISTORY_BALANCE,
    myOrderHistoryBalanceCalcData: formdata.myOrderHistoryBalanceCalcData,
    myOrderHistoryBalanceLoader: formdata.myOrderHistoryBalanceLoader
  });
};

export const getMyTradeMetricsFunc = formData => dispatch => {
  dispatch({
    type: MY_TRADE_METRICS_REQUESTED
  });
  apiCaller(
    'user/metrics/averages/',
    'post',
    formData,
    false,
    true,
    false
  ).then(response =>
    dispatch({
      type: MY_TRADE_METRICS_SUCCESS,
      response
    })
  );
};

export const getMyOrderHistory = formData => dispatch => {
  dispatch({
    type: MY_ORDER_HISTORY_REQUESTED
  });
  apiCaller(
    `user/order/limit/executed/${formData.market}/${formData.coin}/0/0`,
    // `user/order/limit/executed/0/0/0/0`,
    'get',
    '',
    false,
    true,
    false
  ).then(response =>
    dispatch({
      type: MY_ORDER_HISTORY_SUCCESS,
      response
    })
  );
};

export const getMyOpenOrders = formData => dispatch => {
  dispatch({
    type: MY_OPEN_ORDERS_REQUESTED
  });
  apiCaller(
    `user/order/combined/active/${formData.market}/${formData.coin}/0/0`,
    'get',
    '',
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: MY_OPEN_ORDERS_SUCCESS,
      response
    });
  });
};

export const getMyBalance = () => dispatch => {
  dispatch({
    type: MY_BALANCE_REQUESTED
  });
  apiCaller(`user/wallet/balance/all`, 'get', '', false, true, false).then(
    response => {
      dispatch({
        type: MY_BALANCE_SUCCESS,
        response
      });
    }
  );
};

export const myBalanceZeroLoggedOut = () => dispatch => {
  dispatch({
    type: MY_ZERO_BALANCE,
    getMyBalanceList: {}
  });
};

export const updateMyOpenOrders = myOpenOrderUpdateObject => dispatch => {
  dispatch({
    type: UPDATE_MY_OPEN_ORDERS,
    myOpenOrderUpdateObject
  });
};

export const updateMyOrderHistory = myOrderHistoryUpdateObject => dispatch => {
  dispatch({
    type: UPDATE_MY_ORDER_HISTORY,
    myOrderHistoryUpdateObject
  });
};

export const removeMyOpenOrderRequest = formData => dispatch => {
  apiCaller(
    `user/order/${formData.type}/${formData.id}`,
    'delete',
    '',
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: REMOVE_MY_OPEN_ORDER_REQUEST,
      response
    });
  });
};

export const removeMyOpenOrderInList = jsonObj => dispatch => {
  dispatch({
    type: REMOVE_MY_OPEN_ORDER_IN_LIST,
    jsonObj
  });
};
