import apiCaller from 'Utils/apiCallerNew';

import _ from 'underscore';

const OPEN_ORDER_ACTIVE_TAB_UPDATE = 'OPEN_ORDER_ACTIVE_TAB_UPDATE';
const OPEN_ORDERS_REQUESTED = 'OPEN_ORDERS_REQUESTED';
const OPEN_ORDERS_SUCCESS = 'OPEN_ORDERS_SUCCESS';
const SELECTED_OPEN_ORDER_ID = 'SELECTED_OPEN_ORDER_ID';
const OPEN_ORDER_AUTO_SUGGEST_COIN_ID_UPDATE =
  'OPEN_ORDER_AUTO_SUGGEST_COIN_ID_UPDATE';
const OPEN_ORDER_INPUT_TEXT = 'OPEN_ORDER_INPUT_TEXT';

const TRADE_HISTORY_SUCCESS = 'TRADE_HISTORY_SUCCESS';
const TRADE_HISTORY_REQUESTED = 'TRADE_HISTORY_REQUESTED';
//const DEPOSIT_WITHDRAW_FORM_VIEW = 'DEPOSIT_WITHDRAW_FORM_VIEW';
const OPEN_ORDER_SEARCH_VALUE_UPDATE = 'OPEN_ORDER_SEARCH_VALUE_UPDATE';
const AUTO_SUGGEST_COIN_ID_UPDATE = 'AUTO_SUGGEST_COIN_ID_UPDATE';
const DATA_AVERAGES_REQUESTED = 'DATA_AVERAGES_REQUESTED';
const DATA_AVERAGES_SUCCESS = 'DATA_AVERAGES_SUCCESS';
const TRADE_SEARCH_DATA_REQUESTED = 'TRADE_SEARCH_DATA_REQUESTED';
const TRADE_SEARCH_DATA_SUCCESS = 'TRADE_SEARCH_DATA_SUCCESS';
const TRADE_ANALYSIS_AUTO_SUGGEST_COIN_ID_UPDATE =
  'TRADE_ANALYSIS_AUTO_SUGGEST_COIN_ID_UPDATE';
const OPEN_ORDER_COUNT_UPDATE = 'OPEN_ORDER_COUNT_UPDATE';
const DATE_FILTER_UPDATE = 'DATE_FILTER_UPDATE';
const OPEN_ORDERS_HISTORY_LIMIT_UPDATE = 'OPEN_ORDERS_HISTORY_LIMIT_UPDATE';
const BUY_SELL_OPERATION_UPDATE = 'BUY_SELL_OPERATION_UPDATE';
const SELECTED_MARKET_UPDATE = 'SELECTED_MARKET_UPDATE';
const ACTIVE_PAGE_UPDATE = 'ACTIVE_PAGE_UPDATE';
const SELECTED_COIN_UPDATE = 'SELECTED_COIN_UPDATE';
const initialState = {
  activeTab: '1',
  openOrdersList: [],
  selectedOrderId: '',
  getTradeHistoryList: [],
  tradeHistoryLoader: true,
  searchValue: '',
  dataAnalysisLoader: true,
  getDataAvg: {},
  tradeSearchDataLoader: true,
  tradeSearchDataList: [],
  searchValue: '',
  limit: 20,
  selectedMarket: '',
  activePage: 1,
  selected: { value: 'select', label: 'Market' },
  selectedBuySell: { value: 'select', label: 'Select' },
  operation: '',
  disabled: true,
  tradeAnalysisAutosuggestCurrencyIdUpdate: '-1',
  coinSelect: {
    id: '',
    text: ''
  }
};

/**
 * Order History Reducer
 *Reducer
 *@event
 */

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ORDER_ACTIVE_TAB_UPDATE:
      return {
        ...state,
        activeTab: action.activeTab
      };
    case OPEN_ORDERS_REQUESTED:
      return {
        ...state,
        openOrdersLoader: true
      };
    case OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        openOrdersLoader: false,
        openOrdersList: action.response,
        dummy: true
      };
    case SELECTED_OPEN_ORDER_ID:
      return {
        ...state,
        selectedOrderId: action.selectedOrderId
      };
    case OPEN_ORDER_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        withdrawAutosuggestCurrencyId: action.withdrawAutosuggestCurrencyId
      };
    case OPEN_ORDER_INPUT_TEXT:
      return {
        ...state,
        withdrawDepositInputText: action.withdrawDepositInputText
      };
    case TRADE_HISTORY_REQUESTED:
      return {
        ...state,
        tradeHistoryLoader: true
      };
    case TRADE_HISTORY_SUCCESS:
      return {
        ...state,
        tradeHistoryLoader: false,
        getTradeHistoryList: action.response,
        dummy: true
      };

    case OPEN_ORDER_SEARCH_VALUE_UPDATE:
      return {
        ...state,
        searchValue: action.searchValue
      };
    case AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        autosuggestCurrencyId: action.autosuggestCurrencyId
      };
    case DATA_AVERAGES_REQUESTED:
      return {
        ...state,
        dataAnalysisLoader: true
      };
    case DATA_AVERAGES_SUCCESS:
      return {
        ...state,
        dataAnalysisLoader: false,
        getDataAvg: action.response,
        coinId: action.coinId,
        marketId: action.marketId
      };
    case TRADE_SEARCH_DATA_REQUESTED:
      return {
        ...state,
        tradeSearchDataLoader: true
      };
    case TRADE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        tradeSearchDataLoader: false,
        tradeSearchDataList: action.response
      };
    case TRADE_ANALYSIS_AUTO_SUGGEST_COIN_ID_UPDATE:
      return {
        ...state,
        tradeAnalysisAutosuggestCurrencyIdUpdate:
          action.tradeAnalysisAutosuggestCurrencyIdUpdate
      };
    case OPEN_ORDER_COUNT_UPDATE:
      return {
        ...state,
        count: action.count
      };
    case OPEN_ORDERS_HISTORY_LIMIT_UPDATE:
      return {
        ...state,
        limit: action.limit
      };
    case DATE_FILTER_UPDATE:
      return {
        ...state,
        dateTo: action.dateTo,
        dateFrom: action.dateFrom,
        fromForDatePicker: action.fromForDatePicker,
        toForDatePicker: action.toForDatePicker
      };
    case BUY_SELL_OPERATION_UPDATE:
      return {
        ...state,
        operation: action.operation,
        selectedBuySell: action.selectedBuySell
      };
    case SELECTED_MARKET_UPDATE:
      return {
        ...state,
        selectedMarket: action.selectedMarket,
        selected: action.selected,
        disabled: action.disabled
      };
    case ACTIVE_PAGE_UPDATE:
      return {
        ...state,
        activePage: action.activePage
      };
    case SELECTED_COIN_UPDATE:
      return {
        ...state,
        coinSelect: action.coinSelect
      };
    default:
      return state;
  }
};

export const activeTabUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_ACTIVE_TAB_UPDATE,
    activeTab: formData
  });
};

export const getOpenOrders = formData => dispatch => {
  dispatch({
    type: OPEN_ORDERS_REQUESTED
  });
  apiCaller(`user/order/combined/active/`, 'get', '', false, true, false).then(
    response => {
      dispatch({
        type: OPEN_ORDERS_SUCCESS,
        response
      });
    }
  );
};

export const limitUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDERS_HISTORY_LIMIT_UPDATE,
    limit: formData.limit
  });
};

export const selectedOpenOrderIdFunc = formData => dispatch => {
  dispatch({
    type: SELECTED_OPEN_ORDER_ID,
    selectedOrderId: formData.selectedOrderId
  });
};

export const openOrderAutosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_AUTO_SUGGEST_COIN_ID_UPDATE,
    withdrawAutosuggestCurrencyId: formData.withdrawAutosuggestCurrencyId
  });
};

export const inputTextAllCoinsFunc = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_INPUT_TEXT,
    withdrawDepositInputText: formData.withdrawDepositInputText
  });
};

export const getTradeHistory = formData => dispatch => {
  dispatch({
    type: TRADE_HISTORY_REQUESTED
  });

  apiCaller(`user/order/limit/executed/`, 'get', '', false, true, false).then(
    response => {
      dispatch({
        type: TRADE_HISTORY_SUCCESS,
        response
      });
    }
  );
};

export const searchValueUpdate = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_SEARCH_VALUE_UPDATE,
    searchValue: formData.searchValue
  });
};

export const autosuggestCurrencyIdUpdate = formData => dispatch => {
  dispatch({
    type: AUTO_SUGGEST_COIN_ID_UPDATE,
    autosuggestCurrencyId: formData.autosuggestCurrencyId
  });
};

export const getDataAverages = formData => dispatch => {
  dispatch({
    type: DATA_AVERAGES_REQUESTED
  });

  apiCaller(
    `user/metrics/averages/`,
    'post',
    formData,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: DATA_AVERAGES_SUCCESS,
      coinId: formData.coinId,
      marketId: formData.marketId,
      response
    });
  });
};

export const getFilterdedTradeData = formData => dispatch => {
  dispatch({
    type: TRADE_SEARCH_DATA_REQUESTED
  });

  apiCaller(
    `user/order/executedorder/search`,
    'post',
    formData,
    false,
    true,
    false
  ).then(response => {
    dispatch({
      type: TRADE_SEARCH_DATA_SUCCESS,
      response
    });
  });
};

export const tradeAnalysisAutosuggestCurrencyIdUpdateUpdate = formData => dispatch => {
  dispatch({
    type: TRADE_ANALYSIS_AUTO_SUGGEST_COIN_ID_UPDATE,
    tradeAnalysisAutosuggestCurrencyIdUpdate:
      formData.tradeAnalysisAutosuggestCurrencyIdUpdate
  });
};

export const setCount = formData => dispatch => {
  dispatch({
    type: OPEN_ORDER_COUNT_UPDATE,
    count: formData.count
  });
};

export const setDates = formData => dispatch => {
  dispatch({
    type: DATE_FILTER_UPDATE,
    dateTo: formData.dateTo,
    dateFrom: formData.dateFrom,
    fromForDatePicker: formData.fromForDatePicker,
    toForDatePicker: formData.toForDatePicker
  });
};

export const setOperation = formData => dispatch => {
  dispatch({
    type: BUY_SELL_OPERATION_UPDATE,
    operation: formData.operation,
    selectedBuySell: formData.selectedBuySell
  });
};
export const selectedMarketUpdate = formData => dispatch => {
  dispatch({
    type: SELECTED_MARKET_UPDATE,
    selectedMarket: formData.selectedMarket,
    selected: formData.selected,
    disabled: formData.disabled
  });
};

export const setActivePage = formData => dispatch => {
  dispatch({
    type: ACTIVE_PAGE_UPDATE,
    activePage: formData.activePage
  });
};

export const selectedCoinUpdate = formData => dispatch => {
  dispatch({
    type: SELECTED_COIN_UPDATE,
    coinSelect: formData.coinSelect
  });
};
