/**
 * Header Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { logout } from 'Modules/signin/signinReducer';
import removeCookie from 'Utils/removeCookie';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import {
  selectedCurrencyUpdate,
  depositWithdrawFormView
} from 'Modules/withdrawdeposit/withdrawDepositReducer';

const cookies = new Cookies();
let email = cookies.get('email');

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.linkToLogin = this.linkToLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {
      dropdownOpen: false
      // headerActiveMenu: ''
    };
  }

  // componentDidMount() {
  // if (this.props.isLoggedIn !== true) {
  //   this.props.changePage('/login');
  // }
  // }

  // componentWillReceiveProps(nextProps) {
  // if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
  //   if (nextProps.isLoggedIn !== true) {
  //     this.props.changePage('/login');
  //   }
  // }
  // }

  toggle(changePageLocation) {
    this.props.changePage(changePageLocation);
  }

  toggleDropDown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  /**
   * Logs out user
   * @function
   */
  handleLogout(e) {
    e.preventDefault();
    removeCookie();
    this.removeUsersSelectedData();
    this.props.logout({ email }, this.toggle('/landingPage'));
  }

  removeUsersSelectedData() {
    this.props.selectedCurrencyUpdate({ selectedCurrency: undefined });
    this.props.depositWithdrawFormView({
      depositFormView: false,
      withdrawFormView: false,
      currencyId: undefined
    });
  }

  linkToLogin() {
    this.props.changePage('/login');
  }

  render() {
    return (
      <nav id="navigation-main" className="navbar navbar-expand-sm">
        <a className="navbar-brand">
          <img
            src={'/assets/images/logo.png'}
            // src={`${process.env.PUBLIC_URL}/static/media/logo.fd74436a.png`}
            alt="Logo"
            style={{ height: '30px', cursor: 'pointer' }}
            onClick={() => {
              this.props.isLoggedIn
                ? this.toggle(
                    `/${this.props.activeMarketSymbol}-${this.props.activeCurrencySymbol}`
                  )
                : this.toggle(`/landingPage`);
            }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i
            className="fa fa-navicon"
            style={{ fontSize: '35px', color: 'white' }}
          />
          {/* <span className="navbar-toggler-icon" /> */}
        </button>

        <div
          className="collapse navbar-collapse"
          style={{ zIndex: 9999999 }}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto d-flex align-items-center">
            
					{this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className={`crsrPntr nav-link
										${this.props.headerActiveMenu === 'transfer' ? ' active' : ''}`}
                  onClick={() => {
                    this.toggle('/transfer');
                  }}
                >
                  Transfer
                </a>
              </li>
            )}
            <li className="nav-item">
                <a
                  href="https://otc.throughbit.com"
                  className={`crsrPntr nav-link
										${this.props.headerActiveMenu === 'transfer' ? ' active' : ''}`}
                  /* onClick={() => {
                    this.toggle('/transfer');
                  }} */
                >
                  OTC
                </a>
              </li>

            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.headerActiveMenu === 'exchange' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle(
                    `/${this.props.activeMarketSymbol}-${this.props.activeCurrencySymbol}`
                  );
                }}
              >
                Exchange
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${this.props.headerActiveMenu === 'markets' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('/markets');
                }}
              >
                All Markets
              </a>
            </li>

            {this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className={`crsrPntr nav-link
										${this.props.headerActiveMenu === 'referral' ? ' active' : ''}`}
                  onClick={() => {
                    this.toggle('/referral');
                  }}
                >
                  Referral
                </a>
              </li>
            )}

            {this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className={`crsrPntr nav-link
                  ${
                    this.props.headerActiveMenu === 'openOrders'
                      ? ' active'
                      : ''
                  }`}
                  onClick={() => {
                    this.toggle('/openOrders');
                  }}
                >
                  Orders
                </a>
              </li>
            )}
            {this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className={`crsrPntr nav-link
										${this.props.headerActiveMenu === 'withdrawal/deposits' ? ' active' : ''}`}
                  onClick={() => {
                    this.toggle('/withdrawDeposit');
                  }}
                >
                  Withdrawals/Deposits
                </a>
              </li>
            )}
            {this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className={`crsrPntr nav-link
										${this.props.headerActiveMenu === 'portfolio' ? ' active' : ''}`}
                  onClick={() => {
                    this.toggle('/portfolio');
                  }}
                >
                  Portfolio
                </a>
              </li>
            )}

            {this.props.isLoggedIn && (
              <li className="nav-item">
                <a
                  className="crsrPntr nav-link"
                  onClick={e => this.handleLogout(e)}
                >
                  Logout
                </a>
              </li>
            )}

            {!this.props.isLoggedIn && (
              <li className="nav-item">
                <a className="crsrPntr nav-link" onClick={this.linkToLogin}>
                  Login
                </a>
              </li>
            )}

            {this.props.isLoggedIn && (
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropDown}
              >
                <DropdownToggle
                  caret
                  onClick={() => {
                    this.setState({ dropdownOpen: !this.state.dropdownOpen });
                  }}
                  className="d-flex align-items-center"
                >
                  <span className="user-image">
                    {/* <img
                      src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg"
                      height="25px;"
                      alt=""
                    /> */}
                    {/* <i className="fa fa-caret-down" style={{fontSize:"15px",paddingLeft:"5px"}}></i> */}
                  </span>
                </DropdownToggle>
                <DropdownMenu className="position-left">
                  <DropdownItem
                    className="crsrPntr"
                    onClick={() => {
                      this.toggle('/settings');
                    }}
                  >
                    Settings
                  </DropdownItem>
                  <DropdownItem
                    className="crsrPntr"
                    onClick={() => {
                      this.toggle('/kyc');
                    }}
                  >
                    Kyc
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

{
  /* <li className="nav-item dropdown">
                <a
                  className="crsrPntr nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-image">
                    <img
                      src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg"
                      height="25px;"
                      alt=""
                    />
                  </span>
                </a>


                <div
                  className="dropdown-menu position-left"
                  aria-labelledby="navbarDropdown"
                > */
}
{
  /* <a className="dropdown-item">Profile</a> */
}
{
  /* <a
                    role="presentation"
                    className="dropdown-item"
                    onClick={() => {
                      this.toggle('/settings');
                    }}
                  >
                    Settings
                  </a>
                  <a
                    className="dropdown-item crsrPntr"
                    onClick={() => {
                      this.toggle('/kyc');
                    }}
                  >
                    Kyc
                  </a> */
}
{
  /* <a className="dropdown-item">Balances</a>
                  <a className="dropdown-item">Blockchain</a> */
}
{
  /* </div>
              </li> */
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout: formData => logout(formData),
      selectedCurrencyUpdate: formData => selectedCurrencyUpdate(formData),
      depositWithdrawFormView: formData => depositWithdrawFormView(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    activeMarketSymbol: state.app.activeMarketSymbol,
    activeCurrencySymbol: state.app.activeCurrencySymbol,
    headerActiveMenu: state.app.headerActiveMenu,
    isLoggedIn: state.signin.isLoggedIn,
    profile: state.signin.profile
  }),
  mapDispatchToProps
)(Header);
