/**
 * Change Password Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { getKycDetails } from 'Modules/kyc/kycReducer';
import removeCookie from 'Utils/removeCookie';

const cookies = new Cookies();
let email = cookies.get('email');

/**
 * Represents a SecurityPassword Class.
 * @class extends React.Component
 */

class Kyc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: '',
      addr1: '',
      addr2: '',
      phone: '',
      files: '',
      fileType: '',
      gender: '',
      dob: null,

      country: '',
      issuingCountry: '',
      city: '',
      state: '',

      verified_kyc: '',

      zipcode: ''
    };
    this.getKyc = this.getKyc.bind(this);
    this.handleKyc = this.handleKyc.bind(this);
  }

  getKyc() {
    this.props.getKycDetails();
  }

  componentDidMount() {
    this.getKyc();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.kycDetailsResponse !== nextProps.kycDetailsResponse) {
      if (nextProps.kycDetailsResponse.error == 2) {
        this.props.logout({ email });
        removeCookie();
        this.props.changePage('/login');
      } else if (nextProps.kycDetailsResponse.data) {
        var data = nextProps.kycDetailsResponse.data[0];
        this.setState({
          loading: false,
          addr: data.addr,

          addr1: data.addr1,

          city: data.city,

          countryCode: data.country_code,

          dob: data.dob,

          name: data.full_name,

          gender: data.gender,

          issuedCountry: data.idissuedcountry,

          id: data.idnumber,

          idType: data.idtype,

          phone: data.phone,

          residenceCountry: data.residencecountry,

          state: data.state,

          verified_kyc: data.verified_kyc,

          zipcode: data.zipcode
        });
      }
    }
  }

  handleKyc() {
    this.props.changePage('/kyc');
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <div className="loading-o" />;
    } else {
      return (
        <div className="container-fluid mt-5">
          <div className="row no-gutters justify-content-center settings-form">
            <div className="col-lg-4 col-md-6 col-sm-12">
              {this.state.verified_kyc == '1' && (
                <p className="fw-800">Kyc Details Verified Successfully</p>
              )}

              {this.state.verified_kyc == '2' && (
                <p className="fw-800">
                  Kyc Details Uploaded. You will get a mail once verified by our
                  team.
                </p>
              )}
              <div className="title">KYC Details</div>

              <div className="form-group">
                <label>Full Name</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Full Name"
                  value={this.state.name}
                  name="name"
                />
              </div>
              <div className="form-group">
                <label>DOB</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="DOB"
                  value={this.state.dob}
                  name="dob"
                />
              </div>
              <div className="form-group">
                <label>Gender</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Gender"
                  value={this.state.gender || ''}
                  name="gender"
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Address"
                  value={this.state.addr}
                  name="addr"
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Address"
                  value={this.state.addr1 || ''}
                  name="addr1"
                />
              </div>
              <div className="form-group">
                <label>City</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="City"
                  value={this.state.city}
                  name="city"
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-10" />

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label>State</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="State"
                  value={this.state.state}
                  name="state"
                />
              </div>
              <div className="form-group">
                <label>Residence Country</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Residence Country"
                  value={this.state.residenceCountry}
                  name="residenceCountry"
                />
              </div>
              <div className="form-group">
                <label>ID Type</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="ID Type"
                  value={this.state.idType || ''}
                  name="idType"
                />
              </div>
              <div className="form-group">
                <label>ID Number</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="ID Number"
                  value={this.state.id || ''}
                  name="id"
                />
              </div>
              <div className="form-group">
                <label>ID Issued Country</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Issued Country"
                  value={this.state.issuedCountry}
                  name="issuedCountry"
                />
              </div>
              <div className="form-group">
                <label>Phone</label>
                <input
                  className="form-control"
                  type="text"
                  // readOnly
                  disabled
                  placeholder="Phone"
                  value={
                    '+' + this.state.countryCode + ' - ' + this.state.phone
                  }
                  name="phone"
                />
              </div>

              {this.state.verified_kyc !== '1' && (
                <button className="btn btn-blue" onClick={this.handleKyc}>
                  Change
                </button>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      getKycDetails: formData => getKycDetails(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    changePwdLoader: state.settings.changePwdLoader,
    changePwdResponse: state.settings.changePwdResponse,
    kycDetailsResponse: state.kyc.kycDetailsResponse
  }),
  mapDispatchToProps
)(Kyc);
