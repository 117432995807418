/**
 * Transaction History Component
 * @Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { logout } from 'Modules/signin/signinReducer';
import { getOrderDetails, updateFalcon } from '../../dashboardReducer';
import {
  getMyOrderHistory,
  getMyOpenOrders,
  myOrderHistoryBalanceFunc,
  selectedMyOrderHistory,
  updateMyOrderHistory
} from './myOrdersReducer';

import removeCookie from 'Utils/removeCookie';
import statusCheck from 'Utils/statusCheck';
import tradeHistoryCalculateFunc from './tradeHistoryCalculateFunc';
import _ from 'underscore';

const cookies = new Cookies();
let email = cookies.get('email');
let code_2fa = cookies.get('code_2fa');

class MyOrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: '',
      myOrderHistoryLoader: false,
      myOpenOrdersLoader: false,
      orderList: [],
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      data_table_height: this.props.data_table_height
    };
  }

  componentDidMount() {
    // socket.on('broadcast', data => {
    //   if (data.message.status === 'executed') {
    //     this.props.updateMyOrderHistory({
    //       myOrderHistoryUpdateObject: data.message
    //     });
    //   }
    // });

    this.props.getMyOrderHistory({
      market: this.props.activeMarketValue,
      coin: this.props.activeCurrencyValue
    });

    // this.props.getMyOrderHistory();
  }

  myOrderHistoryCalculate(data) {
    const myOrderHistoryBalanceCalcData = tradeHistoryCalculateFunc(data);

    this.props.myOrderHistoryBalanceFunc({
      myOrderHistoryBalanceCalcData,
      myOrderHistoryBalanceLoader: false
    });
  }

  myOrderHistoryListSortFunc(
    activeMarketValue,
    activeCurrencyValue,
    getMyOrderHistoryList
  ) {
    if (!getMyOrderHistoryList) {
      getMyOrderHistoryList = this.props.getMyOrderHistoryList;
    }

    const orderListSortByMarket = _.sortBy(
      getMyOrderHistoryList.data,
      'market'
    );
    const orderListActiveCurrency = [];
    const orderListNonActiveCurrency = [];

    orderListSortByMarket.map(obj => {
      if (
        obj.market === activeMarketValue &&
        obj.currency === activeCurrencyValue
      ) {
        orderListActiveCurrency.push(obj);
      } else {
        orderListNonActiveCurrency.push(obj);
      }
    });
    const orderListSort = orderListActiveCurrency.concat(
      orderListNonActiveCurrency
    );
    this.setState({ orderList: orderListSort });
    // this.setState({ orderListSort: orderListSort[0] });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data_table_height !== nextProps.data_table_height) {
      this.setState({ data_table_height: nextProps.data_table_height });
    }

    // Set Loader for Order History
    if (this.props.myOrderHistoryLoader !== nextProps.myOrderHistoryLoader) {
      this.setState({ myOrderHistoryLoader: nextProps.myOrderHistoryLoader });
    }

    if (
      this.props.activeMarketValue !== nextProps.activeMarketValue ||
      this.props.activeCurrencyValue !== nextProps.activeCurrencyValue
    ) {
      const activeMarketValue =
        nextProps.activeMarketValue || this.props.activeMarketValue;
      const activeCurrencyValue =
        nextProps.activeCurrencyValue || this.props.activeCurrencyValue;

      this.props.getMyOrderHistory({
        market: activeMarketValue,
        coin: activeCurrencyValue
      });

      // this.myOrderHistoryListSortFunc(activeMarketValue, activeCurrencyValue);
    }

    if (this.props.getMyOrderHistoryList !== nextProps.getMyOrderHistoryList) {
      if (nextProps.getMyOrderHistoryList.error === 0) {
        // this.myOrderHistoryCalculate(nextProps.getMyOrderHistoryList.data);

        // this.myOrderHistoryListSortFunc(
        //   this.props.activeMarketValue,
        //   this.props.activeCurrencyValue,
        //   nextProps.getMyOrderHistoryList.data
        // );

        this.setState({
          getMyOrderHistoryList: nextProps.getMyOrderHistoryList.data,
          orderList: nextProps.getMyOrderHistoryList.data,
          error: null
        });
      } else if (nextProps.getMyOrderHistoryList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          getMyOrderHistoryList: null,
          error: nextProps.getMyOrderHistoryList.message
        });
      }
    }

    //Handle The Response IF Recieved Transaction Data
    // if (
    //   nextProps.getMyOpenOrdersList !== undefined &&
    //   this.props.getMyOpenOrdersList !== nextProps.getMyOpenOrdersList
    // ) {
    //   if (nextProps.getMyOpenOrdersList.status === 1) {
    //     this.setState({ orderList: nextProps.getMyOpenOrdersList.data.orders });
    //   }
    // }

    // if (this.props.coin !== nextProps.coin) {
    //   this.props.getMyOrderHistory({
    //     operation: 1,
    //     coin: 1
    //   });

    //   this.props.getMyOpenOrders({
    //     operation: 0,
    //     coin: 1
    //   });

    // }
  }

  orderHistoryTableClick(e, i) {
    const { selectedMyOrderHistoryData, getMyOrderHistoryList } = this.props;
    if (e.target.checked)
      selectedMyOrderHistoryData.push(getMyOrderHistoryList[i]);
    else selectedMyOrderHistoryData.pop(getMyOrderHistoryList[i]);
    this.myOrderHistoryCalculate(selectedMyOrderHistoryData);

    this.props.selectedMyOrderHistory({
      selectedMyOrderHistoryData
    });
  }

  render() {
    code_2fa =
      this.props.profile !== undefined ? this.props.profile.code_2f : code_2fa;
    const { error, myOrderHistoryLoader } = this.state;

    // const {
    //   totalBuy,
    //   avgBuyPrice,
    //   totalSell,
    //   avgSellPrice,
    //   breakEvenPrice,
    //   profitLoss
    // } = this.props;

    if (error === 'Unable to authenticate') {
      return <div />;
    }

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (myOrderHistoryLoader) {
      return <div className="loading-o" />;
    }

    return (
      <div id="data-table" className="container-fluid orderHistory">
        {this.state.orderList && this.state.orderList.length !== 0 ? (
          <table
            className="table data-table card-box table-fixed"
            style={{ marginBottom: '0px' }}
          >
            <thead>
              <tr>
                {/* <th scope="col faded" /> */}
                {/* <th scope="col">Date</th>
								<th scope="col">Type</th>
								<th scope="col">Price</th>
								<th scope="col">{`Total ${this.props.activeCurrencySymbol} sold`}</th>
								<th scope="col">Fee</th>
								<th scope="col">{`Total ${this.props.activeMarketSymbol} spent`}</th> */}

                <th scope="col faded" style={{ width: '24.66%' }}>
                  Date
                </th>
                <th scope="col faded">Pair</th>
                <th scope="col faded" style={{ width: '13.46%' }}>
                  Type
                </th>
                <th scope="col faded">Price</th>
                <th scope="col faded">Amount</th>
                <th scope="col faded">Total</th>
                {/* <th scope="col faded">
									{`Amount(${this.props.activeCurrencySymbol})`}
								</th> */}
                {/* <th scope="col faded">
									{`Total(${this.props.activeMarketSymbol})`}
								</th> */}
                {/* <th scope="col faded">Date</th> */}
              </tr>
            </thead>
            <tbody style={{ height: this.state.data_table_height }}>
              {this.state.orderList.map((o, i) => (
                <tr key={i}>
                  {/* <td>
                    <div
                      className="form-check form-check-inline"
                      onClick={e => this.orderHistoryTableClick(e, i)}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`inlineCheckbox${i}`}
                        value={`option${i}`}
                      />
                    </div>
									</td> */}
                  {/* <td>{o.price.toFixed(8)}</td> */}
                  <td style={{ width: '24.66%' }}>{o.datetime}</td>
                  {this.props.marketsCoinsListObj &&
                    this.props.marketsCoinsListObj[o.marketId] && (
                      <td>{`${
                        this.props.marketsCoinsListObj[o.marketId].marketdata[0]
                          .marketsymbol
                      }/${
                        this.props.marketsCoinsListObj[o.marketId].coins[
                          o.coinId
                        ].coinsymbol
                      }`}</td>
                    )}
                  {!this.props.marketsCoinsListObj && (
                    <td>{`${o.marketId}/${o.coinId}`}</td>
                  )}
                  <td
                    className={o.operation ? 'positive-text' : 'negative-text'}
                    style={{ width: '13.46%' }}
                  >
                    {statusCheck.buySellStatusCheck(o.operation)}
                  </td>
                  <td>{o.price && o.price.toFixed(8)}</td>
                  <td>{o.volumeCoin && o.volumeCoin.toFixed(8)}</td>
                  <td>{o.volumeMarket && o.volumeMarket.toFixed(8)}</td>
                  {/* <td>{o.fee}{' '}<span className="currency">{this.props.activeMarketSymbol}</span></td> */}
                  {/* <td>{o.total.toFixed(8)}</td> */}
                  {/* <td>{o.volumebtc}</td> */}
                  {/* <td>{o.volumealt}</td> */}
                  {/* <td>{o.volumebtc * o.volumealt}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="noBal">No Order History</div>
        )}
      </div>
    );
  }
}

MyOrderHistory.defaultProps = {
  activeMarketValue: 0,
  activeMarketSymbol: 'BTC',
  activeCurrencyValue: 1,
  activeCurrencySymbol: 'ETH'
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderDetails: formData => getOrderDetails(formData),
      getMyOrderHistory: formData => getMyOrderHistory(formData),
      myOrderHistoryBalanceFunc: formData =>
        myOrderHistoryBalanceFunc(formData),
      selectedMyOrderHistory: formData => selectedMyOrderHistory(formData),
      getMyOpenOrders: formData => getMyOpenOrders(formData),
      changePage: page => push(page),
      updateFalcon: formData => updateFalcon(formData),
      updateMyOrderHistory: formData => updateMyOrderHistory(formData),
      logout: formData => logout(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    orderDetails: state.dashboard.orderDetails,
    myOrderHistoryLoader: state.myOrders.myOrderHistoryLoader,
    myOpenOrdersLoader: state.myOrders.myOpenOrdersLoader,
    profile: state.signin.profile,
    getMyOrderHistoryList: state.myOrders.getMyOrderHistoryList,
    selectedMyOrderHistoryData: state.myOrders.selectedMyOrderHistoryData,
    getRecentTradeList: state.recentTrades.getRecentTradeList,
    getMyOpenOrdersList: state.myOrders.getMyOpenOrdersList,

    // totalBuy: state.myOrders.totalBuy,
    // avgBuyPrice: state.myOrders.avgBuyPrice,
    // totalSell: state.myOrders.totalSell,
    // avgSellPrice: state.myOrders.avgSellPrice,
    // breakEvenPrice: state.myOrders.breakEvenPrice,
    // profitLoss: state.myOrders.profitLoss,

    myOrderHistoryBalanceCalcData: state.myOrders.myOrderHistoryBalanceCalcData,

    marketsCoinsListObj: state.app.marketsCoinsListObj,
    data_table_height: state.dashboard.data_table_height
  }),
  mapDispatchToProps
)(MyOrderHistory);
