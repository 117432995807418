import React from 'react';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';
import helpers from 'Utils/sortingHelper';

import {
  activeTabUpdate,
  getFilterdedTradeData,
  limitUpdate,
  setCount,
  tradeAnalysisAutosuggestCurrencyIdUpdateUpdate
} from './openOrdersReducer';
import statusCheck from 'Utils/statusCheck';
import moment from 'moment';
import { stat } from 'fs';

class TradeHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tradeSearchDataList: [],
      tradeSearchDataLoader: true,
      error: '',
      showMessage: false,
      message: '',
      txnid: '',
      currency: 'BTC',
      searchData: [],
      windowHeight: window.innerHeight - 120,
      coinSymbolsObj: this.props.coinSymbolsObj,
      operation: this.props.operation,
      sortingCol: '',
      activePage: this.props.activePage,
      tradeAnalysisAutosuggestCurrencyIdUpdate: this.props
        .tradeAnalysisAutosuggestCurrencyIdUpdate,
      selectedMarket: this.props.selectedMarket
    };
  }
  // componentWillUnmount(){
  //   this.props.tradeAnalysisAutosuggestCurrencyIdUpdateUpdate({
  //     tradeAnalysisAutosuggestCurrencyIdUpdate: '-1'
  //   });
  // }
  componentDidMount() {
    const limit = parseInt((this.state.windowHeight * 0.6) / 1 / 24.9);
    this.props.limitUpdate({ limit });
    const form = {};
    if (this.state.selectedMarket !== '') {
      form.marketId = this.state.selectedMarket;
    }
    if (
      this.state.tradeAnalysisAutosuggestCurrencyIdUpdate >= 0 &&
      this.state.tradeAnalysisAutosuggestCurrencyIdUpdate !== ''
    ) {
      form.coinId = this.state.tradeAnalysisAutosuggestCurrencyIdUpdate;
    }
    if (this.state.operation !== '') {
      form.operation = this.state.operation;
    }
    if (this.props.dateFrom != null && this.props.dateTo != null) {
      form.start = moment(this.props.dateFrom).format('YYYY-MM-DD 00:00');
      form.end = moment(this.props.dateTo).format('YYYY-MM-DD 23:59');
    }
    form.skip = (this.state.activePage - 1) * limit;
    form.limit = limit;
    // var coin = this.state.tradeAnalysisAutosuggestCurrencyIdUpdate>=0 ? this.state.tradeAnalysisAutosuggestCurrencyIdUpdate : '';
    // var end = this.props.dateTo !== null ? this.props.dateTo : '';
    // var start = this.props.dateFrom !== null ? this.props.dateFrom : '';
    // var marketId= this.state.selectedMarket >=0 ? this.state.selectedMarket : '';
    // var operation= this.state.operation == 0 || this.state.operation ==1 ? this.state.operation : '';

    this.props.getFilterdedTradeData(
      form
      // end: end,
      // start: start,
      // skip:  (this.state.activePage - 1) * limit,
      // limit: limit,
      // operation: operation,
      // coinId: coin,
      // marketId:marketId
    );
  }
  sortColumn(type, columnName) {
    var { sortingCol, searchData, sortType } = this.state;
    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    const tradeListSort = helpers.sortColumn(
      searchData,
      type,
      sortingCol,
      sortType
    );
    this.setState({
      sortingCol,
      sortType,
      searchData: tradeListSort
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tradeSearchDataLoader !== nextProps.tradeSearchDataLoader) {
      this.setState({ tradeSearchDataLoader: nextProps.tradeSearchDataLoader });
    }

    if (this.props.limit !== nextProps.limit) {
      this.setState({ limit: nextProps.limit });
    }
    if (this.props.selectedMarket !== nextProps.selectedMarket) {
      this.setState({ selectedMarket: nextProps.selectedMarket });
    }
    if (
      this.props.tradeAnalysisAutosuggestCurrencyIdUpdate !==
      nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate
    ) {
      this.setState({
        tradeAnalysisAutosuggestCurrencyIdUpdate:
          nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate
      });
    }
    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }
    if (this.props.dateFrom !== nextProps.dateFrom) {
      this.setState({ dateFrom: nextProps.dateFrom });
    }
    if (this.props.dateTo !== nextProps.dateTo) {
      this.setState({ dateTo: nextProps.dateTo });
    }
    if (this.props.operation !== nextProps.openOrders) {
      this.setState({ operation: nextProps.operation });
    }
    if (this.props.activePage !== nextProps.activePage) {
      this.setState({ activePage: nextProps.activePage });
    }
    if (this.props.tradeSearchDataList !== nextProps.tradeSearchDataList) {
      if (nextProps.tradeSearchDataList.error === 0) {
        this.setState({
          tradeHistoryList: nextProps.tradeSearchDataList.data,
          searchData: nextProps.tradeSearchDataList.data,
          error: null,
          count: nextProps.tradeSearchDataList.count
        });
        this.props.setCount({
          count: nextProps.tradeSearchDataList.count
        });
      } else {
        this.setState({
          tradeHistoryList: [],
          searchData: [],
          error: nextProps.tradeSearchDataList.message
        });
      }

      if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
        if (nextProps.isLoggedIn !== true) {
          this.props.changePage('/login');
        }
      }
    }

    // if (
    //   Array.isArray(nextProps.tradeSearchDataList) &&
    //   nextProps.tradeSearchDataList !== undefined &&
    //   this.props.tradeSearchDataList !== nextProps.tradeSearchDataList
    // ) {
    //   if (nextProps.tradeSearchDataList.length !== 0) {
    //     this.setState({
    //       tradeHistoryList: nextProps.tradeSearchDataList,
    //       error: ''
    //     });
    //   }
    // } else if (!Array.isArray(nextProps.tradeSearchDataList)) {
    //   this.setState({
    //     error: nextProps.tradeSearchDataList.message,
    //     tradeHistoryList: []
    //   });
    // } else if (
    //   !Array.isArray(nextProps.tradeSearchDataList) &&
    //   nextProps.tradeSearchDataList.success
    // ) {
    //   this.setState({ error: nextProps.tradeSearchDataList.message });
    // }
  }

  render() {
    const {
      error,
      tradeSearchDataLoader,
      tradeHistoryList,
      searchData,
      sortingCol,
      sortType,
      coinSymbolsObj
    } = this.state;
    if (error === 'Unable to authenticate') {
      return <div>{error}</div>;
    }

    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (tradeSearchDataLoader) {
      return <div className="loading-o" />;
    }
    return (
      <div
        className="container-fluid card-box trade-history"
        style={{ height: window.innerHeight - 135 }}
      >
        {searchData && searchData.length !== 0 ? (
          <table className="table data-table table-fixed mb-0">
            <thead>
              <tr>
                <th scope="col" className="faded" style={{ width: '9%' }}>
                  Pair
                </th>
                {/*   <th scope="col" className="faded">
                  Scheme
                </th> */}
                <th scope="col" className="faded" style={{ width: '9%' }}>
                  Type
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'price')}
                >
                  Price{' '}
                  {sortingCol === 'price' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'volumeCoin')}
                >
                  Total sold/received{' '}
                  {sortingCol === 'volumeCoin' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th scope="col" className="faded">
                  Fee
                </th>
                <th
                  scope="col"
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('number', 'volumeMarket')}
                >
                  Total received/spent{' '}
                  {sortingCol === 'volumeMarket' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
                <th
                  scope="col"
                  style={{ width: '24.84%' }}
                  className="faded crsrPntr"
                  onClick={() => this.sortColumn('date', 'datetime')}
                >
                  Time{' '}
                  {sortingCol === 'datetime' ? (
                    sortType == 'asc' ? (
                      <i className=" fa fa-sort-asc" />
                    ) : (
                      <i className=" fa fa-sort-desc" />
                    )
                  ) : (
                    ''
                  )}
                </th>
              </tr>
            </thead>
            <tbody style={{ height: window.innerHeight - 220 }}>
              {searchData.map((o, i) => (
                <tr key={i}>
                  {coinSymbolsObj && (
                    <td style={{ width: '9%' }}>{`${
                      coinSymbolsObj[o.coinId].coinsymbol
                    }/${coinSymbolsObj[o.marketId].coinsymbol}`}</td>
                  )}
                  {!this.props.marketsCoinsListObj && <td />}
                  <td
                    className={o.operation ? 'positive-text' : 'negative-text'}
                    style={{ width: '9%' }}
                  >
                    {statusCheck.buySellStatusCheck(o.operation)}
                  </td>
                  {coinSymbolsObj && (
                    <td>
                      {o.price.toFixed(8)}{' '}
                      <span className="currency">
                        {`${coinSymbolsObj[o.marketId].coinsymbol}`}
                      </span>
                    </td>
                  )}
                  {coinSymbolsObj && (
                    <td>
                      {o.volumeCoin.toFixed(8)}{' '}
                      <span className="currency">
                        {`${coinSymbolsObj[o.coinId].coinsymbol}`}
                      </span>
                    </td>
                  )}
                  {coinSymbolsObj && (
                    <td>
                      {o.fee.toFixed(8)}{' '}
                      <span className="currency">
                        {o.operation
                          ? coinSymbolsObj[o.coinId].coinsymbol
                          : coinSymbolsObj[o.marketId].coinsymbol}
                      </span>
                    </td>
                  )}

                  {coinSymbolsObj && (
                    <td>
                      {o.volumeMarket.toFixed(8)}{' '}
                      <span className="currency">
                        {/* {o.operation
                          ? coinSymbolsObj[o.coinId].coinsymbol
                          : coinSymbolsObj[o.marketId].coinsymbol} */}
                          {coinSymbolsObj[o.marketId].coinsymbol}
                      </span>
                    </td>
                  )}
                  <td style={{ width: '24.85%' }}>{`${moment(o.datetime)
                    .utc()
                    .format('HH:mm:ss')} GMT ${moment(o.datetime)
                    .utc()
                    .format('DD/MM/YYYY')}`}</td>
                  {/* <td>{o.datetime}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No Trade History</div>
        )}
        {/*  <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item disabled">
              <a className="page-link" href="#" tabIndex="-1">
                Previous
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFilterdedTradeData: formData => getFilterdedTradeData(formData),
      limitUpdate: formData => limitUpdate(formData),
      setCount: formData => setCount(formData),
      tradeAnalysisAutosuggestCurrencyIdUpdateUpdate: formData =>
        tradeAnalysisAutosuggestCurrencyIdUpdateUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    tradeSearchDataLoader: state.openOrders.tradeSearchDataLoader,
    tradeSearchDataList: state.openOrders.tradeSearchDataList,
    marketsCoinsListObj: state.app.marketsCoinsListObj,
    coinSymbolsObj: state.app.coinSymbolsObj,
    activeTab: state.openOrders.activeTab,
    dateFrom: state.openOrders.dateFrom,
    dateTo: state.openOrders.dateTo,
    data_table_height: state.dashboard.data_table_height,
    limit: state.openOrders.limit,
    operation: state.openOrders.operation,
    activePage: state.openOrders.activePage,
    selectedMarket: state.openOrders.selectedMarket,
    tradeAnalysisAutosuggestCurrencyIdUpdate:
      state.openOrders.tradeAnalysisAutosuggestCurrencyIdUpdate
  }),
  mapDispatchToProps
)(TradeHistory);
