// api/stream.js
import historyProvider from './historyProvider.js';

// keep track of subscriptions
var _subs = [];

export default {
  subscribeBars: function(symbolInfo, resolution, updateCb, uid, resetCache) {
    _subs = [];
    // console.log("Enter to subscribeBars")
    const channelString = createChannelString(symbolInfo);
    // socket.emit('SubAdd', { subs: [channelString] });
    // console.log("channelString - ",channelString)
    var newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name].lastBar,
      listener: updateCb
    };
    // console.log('newSub - ', newSub);
    _subs.push(newSub);
  },
  unsubscribeBars: function(uid) {
    var subIndex = _subs.findIndex(e => e.uid === uid);
    if (subIndex === -1) {
      // console.log("No subscription found for ",uid)
      return;
    }
    var sub = _subs[subIndex];
    // socket.emit('SubRemove', { subs: [sub.channelString] });
    _subs.splice(subIndex, 1);
  },
  socketDataFunc: function(sData, coin, market) {
    // console.log('sData :: ', sData);
    // console.log('Market coin - ', market, coin);
    // console.log('_Sub - ', _subs);
    const data = {
      // sub_type: parseInt(_data[0],10),
      // exchange: _data[1],
      to_sym: market,
      from_sym: coin,
      // trade_id: '12',
      ts: sData.time,
      volume: parseFloat(sData.volume),
      price: parseFloat(sData.price)
    };
    const channelString = `${data.to_sym}~${data.from_sym}`;
    //  const channelString = `${data.sub_type}~${data.exchange}~${data.to_sym}~${data.from_sym}`
    const sub = _subs.find(e => e.channelString === channelString);
    // console.log('sub :: ', sub);
    // console.log('sub :: ', {...sub});
    if (sub) {
      // disregard the initial catchup snapshot of trades for already closed candles
      if (data.ts < sub.lastBar.time) {
        return;
      }
      // console.log('Sub data - ', sub);
      var _lastBar = updateBar(data, sub);
      // send the most recent bar back to TV's realtimeUpdate callback
      sub.listener(_lastBar);
      // update our own record of lastBar
      sub.lastBar = _lastBar;
      // console.log('updated sub :: ', {...sub});
    }
  }
};

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  var lastBar = sub.lastBar;
  let resolution = sub.resolution;
  if (resolution.includes('D')) {
    // 1 day in minutes === 1440
    resolution = 1440;
  } else if (resolution.includes('W')) {
    // 1 week in minutes === 10080
    resolution = 10080;
  }
  var coeff = resolution * 60;
  // console.log({coeff})
  var rounded = Math.floor(Math.round(data.ts / 1000) / coeff) * coeff;
  var lastBarSec = lastBar.time / 1000;
  var _lastBar;
  if (rounded > lastBarSec * 1000) {
    // console.log('Create new candle');
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: rounded * 1000,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: data.volume
    };
  } else {
    // console.log('Update the current candle');
    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }

    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar = lastBar;
  }
  return _lastBar;
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
  var channel = symbolInfo.name.split(/[:/]/);
  // const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
  const to = channel[1];
  const from = channel[0];
  // subscribe to the CryptoCompare trade channel for the pair and exchange
  return `${from}~${to}`;
  // return `0~${exchange}~${from}~${to}`
}
