/**
 * Main DepositWithdraw Component
 * Component
 */

import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import statusCheck from 'Utils/statusCheck';
import _ from 'underscore';
import Cookies from 'universal-cookie';
import { logout } from 'Modules/signin/signinReducer';
import helpers from 'Utils/sortingHelper';
import selectFavCurrency from 'Utils/favouriteCoins';

import removeCookie from 'Utils/removeCookie';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Input,
  Label,
  Form,
  FormGroup
} from 'reactstrap';
import apiCaller from 'Utils/apiCallerNew';

import {
  getDepositWithdraw,
  depositWithdrawFormView,
  selectedCurrencyUpdate,
  withdrawAutosuggestCurrencyIdUpdate,
  withdrawAutosuggestValueUpdate,
  hideZeroBalances,
  sortByFavUpdate
} from './withdrawDepositReducer';
import SVG from 'react-inlinesvg';

const cookies = new Cookies();
let email = cookies.get('email');

class DepositWithdraw extends React.Component {
  constructor(props) {
    super(props);
    this.showDepositForm = this.showDepositForm.bind(this);
    this.showWithdrawForm = this.showWithdrawForm.bind(this);
    this.addressGeneratorConfirm = this.addressGeneratorConfirm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      depositWithdrawLoader: true,
      // getDepositWithdrawList: [
      //   {
      //     coin: 'btc',
      //     balance: '63.0000',
      //     onOrder: '63.0000',
      //     value: '63.0000'
      //   }
      // ],
      walletData: [],
      coinSymbolsObj: {},
      searchData: [],
      marketCrptoArray: [],
      error: null,
      orderCount: 0,
      fetchDocs: '',
      showMessage: false,
      depositWithdrawError: null,
      message: '',
      txnid: '',
      code: '',
      currency: 'BTC',
      coinId: '',
      selectedCurrencyIdDeposit: '',
      currencyId: '',
      selectedCurrency: this.props.selectedCurrency,
      hideZero: this.props.hideZero,
      withdrawAutosuggestCurrencyId: this.props.withdrawAutosuggestCurrencyId,
      withdrawDepositInputText: this.props.withdrawDepositInputText,
      sortingCol: '',
      searchValue: this.props.searchValue,
      sortbyFav: this.props.sortbyFav,
      addressInProgress: this.props.addressInProgress,
      addressCode: this.props.addressCode,
      addressCurrencyId: this.props.addressCurrencyId,
      favCoinArray:
        (localStorage.getItem('favCoinArray') &&
          JSON.parse(localStorage.getItem('favCoinArray'))) ||
        [],
      sortbyFavSearchData: [],
      depositModalShow: false,
      modal: false
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getDepositWithdraw({});
    }

    if (
      this.props.selectedCurrency &&
      parseInt(this.props.selectedCurrency) >= 0
    ) {
      this.setState({
        selectedCurrencyIdDeposit: this.props.selectedCurrency
      });
    }

    this.setState({
      coinSymbolsObj: this.props.coinSymbolsObj
    });
  }

  componentWillUnmount() {
    this.props.hideZeroBalances({
      hideZero: false
    });
    this.props.sortByFavUpdate({
      sortbyFav: false
    });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  hideZeroBalOrder(hideZero, list) {
    const {
      walletData,
      withdrawAutosuggestCurrencyId,
      searchValue,
      searchData,
      coinSymbolsObj
    } = this.state;
    if (searchValue == '') {
      if (hideZero) {
        var result = _.filter(searchData, o => {
          if (Number(parseFloat(o.balance).toFixed(8)) !== 0) {
            return o;
          }
        });
        this.setState({
          searchData: result,
          noZeroBalData: result
        });
      } else {
        this.setState({
          searchData: list
        });
      }
    } else {
      if (hideZero) {
        var result1 = _.filter(searchData, o => {
          if (
            (coinSymbolsObj[o.coinId].coinname
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) !== -1 ||
              coinSymbolsObj[o.coinId].coinsymbol
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1) &&
            Number(parseFloat(o.balance).toFixed(8)) !== 0
          ) {
            return o;
          }
        });
        this.setState({
          searchData: result1
        });
      } else {
        var result = _.filter(searchData, o => {
          if (
            coinSymbolsObj[o.coinId].coinname
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) !== -1 ||
            coinSymbolsObj[o.coinId].coinsymbol
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) !== -1
          ) {
            return o;
          }
        });
        this.setState({
          searchData: result
        });
      }
    }
  }

  hideZeroBalOrders(hideZero) {
    const {
      walletData,
      withdrawAutosuggestCurrencyId,
      withdrawDepositInputText
    } = this.state;
    if (hideZero && withdrawDepositInputText == '') {
      var result = _.filter(walletData, o => {
        if (Number(parseFloat(o.balance).toFixed(8)) !== 0) {
          return o;
        }
      });
      this.setState({
        searchData: result
      });
      // return result;
    } else if (hideZero && withdrawDepositInputText !== '') {
      var result = _.filter(walletData, o => {
        if (
          Number(parseFloat(o.balance).toFixed(8)) !== 0 &&
          o.coinId == withdrawAutosuggestCurrencyId
        ) {
          return o;
        }
      });
      this.setState({
        searchData: result
      });
    } else {
      this.handleSearchChange();
    }
    // } else return walletData;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
      if (nextProps.isLoggedIn) {
        this.props.getDepositWithdraw({});
      }
    }

    if (this.props.selectedCurrency !== nextProps.selectedCurrency) {
      this.setState({ selectedCurrency: nextProps.selectedCurrency });
    }

    if (
      this.props.withdrawAutosuggestCurrencyId !==
      nextProps.withdrawAutosuggestCurrencyId
    ) {
      this.setState(
        {
          withdrawAutosuggestCurrencyId: nextProps.withdrawAutosuggestCurrencyId
        },
        this.handleSearchChange
      );
    }

    if (
      this.props.withdrawDepositInputText !== nextProps.withdrawDepositInputText
    ) {
      this.setState(
        { withdrawDepositInputText: nextProps.withdrawDepositInputText },
        this.handleSearchChange
      );
    }

    if (this.props.depositWithdrawLoader !== nextProps.depositWithdrawLoader) {
      this.setState({
        depositWithdrawLoader: nextProps.depositWithdrawLoader
      });
    }

    if (
      this.props.getDepositWithdrawList !== nextProps.getDepositWithdrawList
    ) {
      if (nextProps.getDepositWithdrawList.error === 0) {
        this.setState(
          {
            Addressfee: nextProps.getDepositWithdrawList.Addressfee,
            walletData: nextProps.getDepositWithdrawList.data,
            searchData: nextProps.getDepositWithdrawList.data,
            depositWithdrawError: null
          },
          this.showDepositForm,
          this.showWithdrawForm
        );
      } else if (nextProps.getDepositWithdrawList.error === 2) {
        this.props.logout({ email });
        removeCookie();
      } else {
        this.setState({
          walletData: [],
          searchData: [],
          depositWithdrawError: nextProps.getDepositWithdrawList.message
        });
      }
    }

    if (this.props.hideZero !== nextProps.hideZero) {
      this.setState({
        hideZero: nextProps.hideZero,
        searchData: this.searchInList(
          nextProps.hideZero,
          this.state.searchValue,
          this.state.sortbyFav,
          nextProps.getDepositWithdrawList.data
        )
      });
      // this.hideZeroBalOrder(
      //   nextProps.hideZero,
      //   nextProps.getDepositWithdrawList.data
      // );

      if (nextProps.hideZero) {
        this.props.hideZeroBalances({ hideZero: true });
      } else {
        this.props.withdrawAutosuggestValueUpdate({
          withdrawAutosuggestValue: true
        });
      }
    }

    if (this.props.coinSymbolsObj !== nextProps.coinSymbolsObj) {
      this.setState({ coinSymbolsObj: nextProps.coinSymbolsObj });
    }

    if (this.props.searchValue != nextProps.searchValue) {
      this.setState({
        searchValue: nextProps.searchValue,
        searchData: this.searchInList(
          this.state.hideZero,
          nextProps.searchValue,
          this.state.sortbyFav,
          nextProps.getDepositWithdrawList.data
        )
      });
    }

    if (this.props.sortbyFav != nextProps.sortbyFav) {
      this.setState({
        sortbyFav: nextProps.sortbyFav,
        searchData: this.searchInList(
          this.state.hideZero,
          this.state.searchValue,
          nextProps.sortbyFav,
          nextProps.getDepositWithdrawList.data
        )
      });
      if (nextProps.sortbyFav) {
        this.props.sortByFavUpdate({ sortbyFav: true });
      }
    }
  }

  getFavCoinsFirst(data) {
    const {
      coinSymbolsObj,
      hideZero,
      sortbyFavSearchData,
      sortbyFav,
      searchData,
      walletData,
      noZeroBalData
    } = this.state;
    const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
    const favSearchData = _.compact(
      _.map(favArray, o => {
        const searchObj = _.filter(data, obj => {
          return obj.coinId === o;
        });
        if (searchObj.length > 0) {
          return searchObj[0];
        } else {
          return;
        }
      })
    );
    this.setState({
      favSearchData: favSearchData
    });
    // if(searchData.length!==0){
    var differenceData = [favSearchData, data]
      .sort((a, b) => b.length - a.length)
      .reduce((a, b) => a.filter(o => !b.some(v => v.coinId === o.coinId)));
    var displayData = _.unique(favSearchData.concat(differenceData));
    return displayData;
  }

  searchInList(hideZero, searchValue, sortbyFav, data) {
    const {
      coinSymbolsObj,
      // hideZero,
      favSearchData,
      sortbyFavSearchData,
      // sortbyFav,
      searchData,
      walletData,
      noZeroBalData
    } = this.state;
    const favArray = JSON.parse(localStorage.getItem('favCoinArray'));
    if (searchValue !== '') {
      if (hideZero) {
        if (sortbyFav) {
          //searchValue not empty, hidezero=true, sortByFav =true
          var result = _.filter(data, o => {
            if (
              (coinSymbolsObj[o.coinId].coinname
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1 ||
                coinSymbolsObj[o.coinId].coinsymbol
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) !== -1) &&
              Number(parseFloat(o.balance).toFixed(8)) !== 0
            ) {
              return o;
            }
          });
          var favData = this.getFavCoinsFirst(result);
          return favData;
        } else {
          //searchValue not empty, hidezero=true, sortByFav =false
          var result1 = _.filter(data, o => {
            if (
              (coinSymbolsObj[o.coinId].coinname
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1 ||
                coinSymbolsObj[o.coinId].coinsymbol
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase()) !== -1) &&
              Number(parseFloat(o.balance).toFixed(8)) !== 0
            ) {
              return o;
            }
          });
          return result1;
        }
      } else {
        if (sortbyFav) {
          //searchValue not empty, hidezero=false, sortByFav =true
          var favData = this.getFavCoinsFirst(data);

          var result = _.filter(favData, o => {
            if (
              coinSymbolsObj[o.coinId].coinname
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1 ||
              coinSymbolsObj[o.coinId].coinsymbol
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1
            ) {
              return o;
            }
          });

          // var favData=this.getFavCoinsFirst(result);

          return result;
        } else {
          //searchValue not empty, hidezero=false, sortByFav =false
          var result = _.filter(data, o => {
            if (
              coinSymbolsObj[o.coinId].coinname
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1 ||
              coinSymbolsObj[o.coinId].coinsymbol
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) !== -1
            ) {
              return o;
            }
          });

          return result;
        }
      }
    } else {
      if (hideZero) {
        if (sortbyFav) {
          //searchValue  empty, hidezero=true, sortByFav =true
          var result = _.filter(data, o => {
            if (Number(parseFloat(o.balance).toFixed(8)) !== 0) {
              return o;
            }
          });

          var favData = this.getFavCoinsFirst(result);
          return favData;
        } else {
          //searchValue  empty, hidezero=true, sortByFav =false
          var result = _.filter(data, o => {
            if (Number(parseFloat(o.balance).toFixed(8)) !== 0) {
              return o;
            }
          });

          return result;
        }
      } else {
        if (sortbyFav) {
          //searchValue  empty, hidezero=false, sortByFav =true
          var favData = this.getFavCoinsFirst(data);

          return favData;
        } else {
          //searchValue  empty, hidezero=false, sortByFav =false {
          return walletData;
        }
      }
    }
  }

  handleSearchChange() {
    const {
      withdrawDepositInputText,
      walletData,
      withdrawAutosuggestCurrencyId
    } = this.state;
    // this.props.hideZeroBalances({ hideZero: false });
    if (
      Number.isInteger(withdrawAutosuggestCurrencyId) &&
      withdrawAutosuggestCurrencyId >= 0 &&
      this.props.hideZero
    ) {
      const searchData = _.filter(walletData, obj => {
        return (
          obj.coinId === withdrawAutosuggestCurrencyId &&
          Number(parseFloat(obj.balance).toFixed(8)) !== 0
        );
      });
      this.setState({ searchData });
    } else if (withdrawDepositInputText === '') {
      this.setState({
        searchData: walletData,
        coinSearchValue: withdrawDepositInputText,
        withdrawAutosuggestCurrencyId: null
      });
    } else {
      if (withdrawDepositInputText.indexOf('(') > -1) {
        const withdrawDepositInputText1 = withdrawDepositInputText
          .split('(')[0]
          .trim();
        this.setState(
          {
            coinSearchValue: withdrawDepositInputText1,
            withdrawAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      } else {
        this.props.selectedCurrencyUpdate({ selectedCurrency: '' });
        this.setState({
          selectedCurrencyIdDeposit: ''
        });
        this.props.depositWithdrawFormView({
          depositFormView: false,
          withdrawFormView: false,
          currencyId: ''
        });

        this.setState(
          {
            coinSearchValue: withdrawDepositInputText,
            withdrawAutosuggestCurrencyId: null
          },
          this.searchCoin
        );
      }
    }
    // this.props.withdrawAutosuggestValueUpdate({
    //   withdrawAutosuggestValue: false
    // });
  }

  searchCoin() {
    const { coinSearchValue, walletData, coinSymbolsObj } = this.state;
    const searchData = _.filter(walletData, obj => {
      return (
        coinSymbolsObj[obj.coinId].coinname
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1 ||
        coinSymbolsObj[obj.coinId].coinsymbol
          .toLowerCase()
          .indexOf(coinSearchValue.toLowerCase()) > -1
      );
    });
    this.setState({ searchData });
  }

  addressGeneratorConfirm() {
    this.toggle();
    this.setState({
      depositModalShow: false,
      addressGenerateFeeMessage: ''
    });
    this.selectedCurrencyFunc(this.state.addressGenerateCurrencyId);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  showDepositForm(currencyId, iserc20) {
    if (iserc20 && currencyId >= 0) {
      var url = `user/address/fetch/` + `${currencyId}`;
      apiCaller(url, 'get', '', false, true, false).then(response => {
        if ('address' in response) {
          this.selectedCurrencyFunc(currencyId);
        } else {
          /* if (response.message === 'Address generation in progress') {
            this.timeout = setTimeout(() => {
              this.showDepositForm(currencyId, iserc20);
              this.timeout = null;
            }, 25000);
          } */
          /* this.toggle();
          this.setState({
            addressGenerateCurrencyId: currencyId,
            depositModalShow: true,
            addressGenerateFeeMessage: `A one time fee of ${this.state.Addressfee} ETH will be charged for creation of ERC20 addresses. This amout shall be deducted when you make your first ERC20 deposit of purchase`
          }); */
          this.showDepositForm(currencyId, iserc20);
        }
      });
    } else if (currencyId >= 0) {
      this.selectedCurrencyFunc(currencyId);
    } else if (this.state.selectedCurrency >= 0) {
      this.selectedCurrencyFunc(this.state.selectedCurrency);
    }
  }

  selectedCurrencyFunc(currencyId) {
    this.props.selectedCurrencyUpdate({ selectedCurrency: currencyId });
    this.setState({
      selectedCurrencyIdDeposit: currencyId
    });
    this.props.depositWithdrawFormView({
      depositFormView: true,
      withdrawFormView: false,
      currencyId: currencyId
    });
  }

  showWithdrawForm(currencyId, avlBalance, coinSymbol) {
    if (currencyId >= 0) {
      this.props.selectedCurrencyUpdate({ selectedCurrency: currencyId });
      this.setState({
        selectedCurrencyIdDeposit: currencyId
      });
      this.props.depositWithdrawFormView({
        depositFormView: false,
        withdrawFormView: true,
        currencyId: currencyId,
        avlBalance: avlBalance,
        currency: coinSymbol
      });
    } else if (this.state.selectedCurrency >= 0) {
      this.props.selectedCurrencyUpdate({
        selectedCurrency: this.state.selectedCurrency
      });
      this.setState({
        selectedCurrencyIdDeposit: this.state.selectedCurrency
      });
      this.props.depositWithdrawFormView({
        depositFormView: false,
        withdrawFormView: true,
        currencyId: this.state.selectedCurrency,
        avlBalance: avlBalance,
        currency: coinSymbol
      });
    }
  }
  sortColumn(type, columnName) {
    var { sortingCol, searchData, sortType } = this.state;
    var { sortingCol, sortType } = helpers.getCurrentSortColumnAndSortType(
      sortingCol,
      columnName,
      sortType
    );
    const balancesListSort = helpers.sortColumn(
      searchData,
      type,
      sortingCol,
      sortType
    );
    this.setState({
      sortingCol,
      sortType,
      searchData: balancesListSort
    });
  }

  selectFavCurrency(coinId) {
    const favCoinArray = selectFavCurrency.selectFavCurrency(coinId);
    this.setState({ favCoinArray });
  }

  render() {
    const {
      // error,
      depositWithdrawLoader,
      searchData,
      depositWithdrawError,
      // walletData,
      // coinSymbolsObj,
      selectedCurrencyIdDeposit,
      depositModalShow,
      sortingCol,
      sortType
    } = this.state;
    // const { selectedCurrency } = this.props;
    if (depositWithdrawLoader) {
      return (
        <div style={{ height: window.innerHeight - 180 }}>
          <div className="loading-o" />
        </div>
      );
    }

    if (!depositWithdrawLoader && depositWithdrawError) {
      if (depositWithdrawError == 'Unable to authenticate') {
        this.props.changePage('/login');
        return <div />;
      } else {
        return <div>{depositWithdrawError}</div>;
      }
    }

    if (!depositWithdrawLoader && depositWithdrawError === null) {
      return (
        <div className="container-fluid card-box mt-1 wd-page" id="openOrders">
          {searchData && searchData.length !== 0 ? (
            <table className="table data-table table-fixed mb-0">
              <thead>
                <tr>
                  <th scope="col" className="faded" style={{ width: '3%' }} />
                  <th scope="col" className="faded" style={{ width: '12.5%' }}>
                    Name
                  </th>
                  <th scope="col" className="faded" style={{ width: '5.5%' }}>
                    Symbol
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'totalBalance')}
                  >
                    Total balance{' '}
                    {sortingCol === 'totalBalance' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'onOrder')}
                  >
                    On Order{' '}
                    {sortingCol === 'onOrder' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'balance')}
                  >
                    Available Balance{' '}
                    {sortingCol === 'balance' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                  <th
                    scope="col"
                    className="faded crsrPntr"
                    onClick={() => this.sortColumn('number', 'valueInBTC')}
                  >
                    Value
                    <span className="currency">(BTC)</span>{' '}
                    {sortingCol === 'valueInBTC' ? (
                      sortType == 'asc' ? (
                        <i className=" fa fa-sort-asc" />
                      ) : (
                        <i className=" fa fa-sort-desc" />
                      )
                    ) : (
                      ''
                    )}
                  </th>
                  <th
                    scope="col"
                    className="faded"
                    style={{ width: '12.5%' }}
                  />
                  <th
                    scope="col"
                    className="faded"
                    style={{ width: '12.5%' }}
                  />
                </tr>
              </thead>
              <tbody style={{ height: window.innerHeight - 180 }}>
                {_.keys(this.state.coinSymbolsObj).length > 0 &&
                  searchData.map((o, i) => ( o && 
                    <tr
                      key={i}
                      className={
                        parseInt(selectedCurrencyIdDeposit) === o.coinId
                          ? 'selected-table-row'
                          : undefined
                      }
                    >
                      <td style={{ width: '3%' }}>
                        <span
                          onClick={() =>
                            this.selectFavCurrency(
                              o.coinId
                              // searchData[marketCrptoArray[activeTab]][
                              //   'coins'
                              // ][o].coinId
                            )
                          }
                          className={`crsrPntr oi oi-star
                            ${
                              (
                                JSON.parse(
                                  localStorage.getItem('favCoinArray')
                                ) || []
                              ).indexOf(
                                o.coinId
                                // o
                                // searchData[marketCrptoArray[activeTab]][
                                //   'coins'
                                // ][o].coinId
                              ) === -1
                                ? ' faded'
                                : ''
                            }`}
                        />{' '}
                      </td>

                      <td
                        className="coin-name"
                        style={{ textAlign: 'left', width: '12.5%' }}
                      >
                        <SVG src={o.coinimage} className="svg-15" />
                        {`${o.coinname}`}
                      </td>
                      <td style={{ width: '5.5%' }}>{`${o.coinsymbol}`}</td>
                      <td>
                        {' '}
                        {parseFloat(o.totalBalance).toFixed(8)}{' '}
                        {/* <span className="currency">
                          {' '}
                          {`${o.coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {parseFloat(o.onOrder).toFixed(8)}{' '}
                        {/*  <span className="currency">
                          {' '}
                          {`${o.coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {parseFloat(o.balance).toFixed(8)}{' '}
                        {/* <span className="currency">
                          {' '}
                          {`${o.coinsymbol.toUpperCase()}`}
                        </span> */}
                      </td>
                      <td>
                        {parseFloat(o.valueInBTC).toFixed(8)}{' '}
                        {/* <span className="currency">BTC</span> */}
                      </td>

                      <td style={{ width: '12.5%' }}>
                        {o.coinstatus === 1 ? (
                          <div
                            className="button-cancel"
                            onClick={() =>
                              this.showWithdrawForm(
                                `${o.coinId}`,
                                `${o.balance}`,
                                `${o.coinsymbol}`
                              )
                            }
                          >
                            Request To Withdraw
                          </div>
                        ) : (
                          <>
                            <div
                              className="button-disable"
                              id={`tooltipWithdraw-${i}`}
                            >
                              Request To Withdraw
                            </div>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltipWithdraw-${i}`}
                              delay={{ hide: -1 }}
                            >
                              Temporary disabled. for more details, Please
                              contact to support@throughbit.com
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                      <td style={{ width: '12.5%' }}>
                        {o.coinstatus === 1 ? (
                          o.iserc20 ? (
                            <div
                              className="button-cancel"
                              onClick={() =>
                                this.showDepositForm(o.coinId, o.iserc20)
                              }
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Deposit
                            </div>
                          ) : (
                            <div
                              className="button-cancel"
                              onClick={() => this.showDepositForm(o.coinId)}
                            >
                              Deposit
                            </div>
                          )
                        ) : (
                          <>
                            <div
                              className="button-disable"
                              id={`tooltipDeposit-${i}`}
                            >
                              Deposit
                            </div>
                            <UncontrolledTooltip
                              placement="top"
                              target={`tooltipDeposit-${i}`}
                              delay={{ hide: -1 }}
                            >
                              Temporary disabled. for more details, Please
                              contact to support@throughbit.com
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>

                      {/* <td>
                        <button
                          className="btn btn-trade"
                          onClick={this.showWithdrawForm}
                        >
                          Withdraw
                        </button>
                      </td> */}
                      {/* <td>
                        <button
                          className="btn btn-trade deposits"
                          onClick={() =>
                            this.showDepositForm(`${o.coinId}`)
                          }
                        >
                          Deposit
                        </button>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div style={{ height: window.innerHeight - 180 }}>No Currency</div>
          )}

          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            backdrop="static"
            centered={true}
          >
            <ModalHeader toggle={this.toggle} />
            <ModalBody>
              <p className="pb-3" style={{ fontSize: '1rem' }}>
                {this.state.addressGenerateFeeMessage}
              </p>
            </ModalBody>
            <ModalFooter className="modalFooterButton">
              <Button
                style={{ width: '100px' }}
                color="primary"
                onClick={this.addressGeneratorConfirm}
              >
                OK
              </Button>{' '}
              <Button
                style={{ width: '100px' }}
                color="secondary"
                onClick={this.toggle}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>

          {/* <div className="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="ModalTitle" aria-hidden="true" data-backdrop="static">
				{depositModalShow &&
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content center-stuff">

								<div className="modal-header">
									<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({depositModalShow: false})}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								
								<div className="modal-body">
									<p className="pb-3" style={{fontSize: '1rem'}}>{this.state.addressGenerateFeeMessage}</p>
								</div>

								<div className="modal-footer">
									<button
										className="btn btn-primary"
										data-dismiss="modal"
										onClick={this.addressGeneratorConfirm}
									>
										OK
									</button>
									
									<button
										className="btn btn-primary"
										data-dismiss="modal"
										onClick={() => this.setState({depositModalShow: false})}
									>
										Cancel
									</button>
								</div>

							</div>
						</div>
					}
					</div> */}
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDepositWithdraw: formData => getDepositWithdraw(formData),
      depositWithdrawFormView: formData => depositWithdrawFormView(formData),
      selectedCurrencyUpdate: formData => selectedCurrencyUpdate(formData),
      withdrawAutosuggestValueUpdate: formData =>
        withdrawAutosuggestValueUpdate(formData),
      withdrawAutosuggestCurrencyIdUpdate: formData =>
        withdrawAutosuggestCurrencyIdUpdate(formData),
      hideZeroBalances: formData => hideZeroBalances(formData),
      sortByFavUpdate: formData => sortByFavUpdate(formData),
      logout: formData => logout(formData),
      changePage: page => push(page)
    },
    dispatch
  );

export default connect(
  state => ({
    depositWithdrawLoader: state.transactions.depositWithdrawLoader,
    getDepositWithdrawList: state.transactions.getDepositWithdrawList,
    hideZero: state.transactions.hideZero,
    isLoggedIn: state.signin.isLoggedIn,
    coinSymbolsObj: state.app.coinSymbolsObj,
    selectedCurrency: state.transactions.selectedCurrency,
    withdrawDepositInputText: state.transactions.withdrawDepositInputText,
    withdrawAutosuggestCurrencyId:
      state.transactions.withdrawAutosuggestCurrencyId,
    sortbyFav: state.transactions.sortbyFav,
    addressInProgress: state.transactions.addressInProgress,
    addressCurrencyId: state.transactions.addressCurrencyId,
    addressCode: state.transactions.addressCode
  }),
  mapDispatchToProps
)(DepositWithdraw);
