import React from 'react';
import { push } from 'connected-react-router';
import _ from 'underscore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'Modules/ErrorBoundary';
import OrdersTab from './ordersTab';
import { headerActiveMenuUpdate } from 'App/appReducer';
import TradeHistory from './tradeHistory';
import TradeAnalysis from './tradeAnalysis';
import Autosuggest from './openAutosuggest';
import Pagination from 'react-js-pagination';
import {
  getFilterdedTradeData,
  getOpenOrders,
  limitUpdate,
  searchValueUpdate,
  setActivePage,
  selectedMarketUpdate,
  setDates,
  setOperation,
  tradeAnalysisAutosuggestCurrencyIdUpdateUpdate,
  selectedCoinUpdate
} from './openOrdersReducer';

import { activeTabUpdate } from './openOrdersReducer';
import moment from 'moment';

class OpenOrders extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      sidebar_main_height: window.innerHeight - 60,
      activePage: this.props.activePage,
      count: this.props.count,
      searchValue: '',
      operation: this.props.operation
    };
  }

  toggle(tab) {
    if (this.props.activeTab !== tab) {
      this.props.activeTabUpdate(tab);
    }
  }
  handleChange(event) {
    this.setState({
      searchValue: event.target.value
    });
    this.props.searchValueUpdate({ searchValue: event.target.value });
  }
  // componentWillUnmount(){
  //   this.setState({ activePage: 1 });

  // }
  componentDidMount() {
    const limit = parseInt(((window.innerHeight - 120) * 0.6) / 1 / 24.9);
    this.setState({ limit });
    this.props.limitUpdate({ limit });
    this.props.headerActiveMenuUpdate('openOrders');
    if (this.props.isLoggedIn !== true) {
      this.props.changePage('/login');
    }
  }
  handlePageChangeOpenOrders(pageNumber) {
    this.setState({ activePage: pageNumber });

    this.setState({ activePage: pageNumber });
    this.props.getOpenOrders();
  }
  handlePageChange(pageNumber) {
    // this.setState({ activePage: pageNumber });
    this.props.setActivePage({
      activePage: pageNumber
    });
    var coin = '';
    // if(this.state.tradeAnalysisAutosuggestCurrencyIdUpdate >=0){
    //     coin = this.state.tradeAnalysisAutosuggestCurrencyIdUpdate
    // }

    const form = {};
    if (this.state.selectedMarket >= 0 || this.state.selectedMarket !== '') {
      form.marketId = this.state.selectedMarket;
    }
    if (
      this.state.tradeAnalysisAutosuggestCurrencyIdUpdate >= 0 ||
      this.state.tradeAnalysisAutosuggestCurrencyIdUpdate !== ''
    ) {
      form.coinId = this.state.tradeAnalysisAutosuggestCurrencyIdUpdate;
    }
    if (this.state.operation !== '') {
      form.operation = this.state.operation;
    }
    if (this.state.dateFrom != null && this.state.dateTo != null) {
      form.start = moment(this.state.dateFrom).format('YYYY-MM-DD 00:00');
      form.end = moment(this.state.dateTo).format('YYYY-MM-DD 23:59');
    }
    form.skip = (pageNumber - 1) * this.state.limit;
    form.limit = this.state.limit;
    this.props.getFilterdedTradeData(form);
    //   {
    //   end: this.state.dateTo,
    //   start: this.state.dateFrom,
    //   skip: (pageNumber - 1) * this.state.limit,
    //   limit: this.state.limit,
    //   coinId: coin,
    //   marketId: this.state.selectedMarket,
    //   operation: this.state.operation
    // });
  }

  componentWillUnmount() {
    this.props.setActivePage({
      activePage: 1
    });
    this.props.selectedMarketUpdate({
      selectedMarket: '',
      selected: { value: 'select', label: 'Market' },
      disabled: true
    });
    this.props.setDates({
      dateFrom: null,
      dateTo: null
    });
    this.props.setOperation({
      operation: ''
    });
    this.props.limitUpdate({ limit: '' });
    console.log('demmm');
    this.props.tradeAnalysisAutosuggestCurrencyIdUpdateUpdate({
      tradeAnalysisAutosuggestCurrencyIdUpdate: '-1'
    });

    this.props.selectedCoinUpdate({
      coinSelect: {
        id: '',
        text: ''
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      if (nextProps.isLoggedIn !== true) {
        this.props.changePage('/login');
      }
    }
    if (this.props.count !== nextProps.count) {
      this.setState({ count: nextProps.count });
    }
    if (this.props.coinId !== nextProps.coinId) {
      this.setState({ coinId: nextProps.coinId });
    }
    if (this.props.marketId !== nextProps.marketId) {
      this.setState({ marketId: nextProps.marketId });
    }
    if (this.props.dateFrom !== nextProps.dateFrom) {
      this.setState({ dateFrom: nextProps.dateFrom });
    }
    if (this.props.dateTo !== nextProps.dateTo) {
      this.setState({ dateTo: nextProps.dateTo });
    }
    if (this.props.limit !== nextProps.limit) {
      this.setState({ limit: nextProps.limit });
    }

    if (this.props.activePage !== nextProps.activePage) {
      this.setState({ activePage: nextProps.activePage });
    }
    if (this.props.operation !== nextProps.operation) {
      this.setState({ operation: nextProps.operation });
    }
    if (this.props.selectedMarket !== nextProps.selectedMarket) {
      this.setState({
        selectedMarket: nextProps.selectedMarket
      });
    }
    if (
      this.props.tradeAnalysisAutosuggestCurrencyIdUpdate !==
      nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate
    ) {
      this.setState({
        tradeAnalysisAutosuggestCurrencyIdUpdate:
          nextProps.tradeAnalysisAutosuggestCurrencyIdUpdate
      });
    }
    if (this.props.coinSelect !== nextProps.coinSelect) {
      this.setState({
        value: nextProps.coinSelect.text
      });
    }
  }

  render() {
    const { activeTab } = this.props;
    const { limit } = this.state;
    return (
      <div className="row no-gutters">
        <div className="col-lg-12 container-one pl-2 pr-1 mt-1">
          <ul className="nav nav-secondary nav-switch">
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${activeTab === '1' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('1');
                }}
                active={(activeTab === '1').toString()}
                aria-selected={activeTab === '1'}
              >
                Open Orders
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`crsrPntr nav-link
									${activeTab === '2' ? ' active' : ''}`}
                onClick={() => {
                  this.toggle('2');
                }}
                active={(activeTab === '2').toString()}
                aria-selected={activeTab === '2'}
              >
                Trade History
              </a>
            </li>
            {activeTab === '1' && this.props.count > 0 && (
              <li className="nav-item ml-auto">
                {/* <Autosuggest /> */}
                <input
                  className="form-control coin-input"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={this.handleChange}
                  style={{ cursor: 'auto' }}
                />
                {/* <a className="nav-link link-icon" href="#" /> */}
              </li>
            )}
            {/*  <li className="nav-item">
              <a className="nav-link link-icon" href="#" />
            </li> */}
          </ul>

          {activeTab === '1' && (
            <ErrorBoundary>
              <OrdersTab />
              {/*   {this.props.count && this.props.count>15 && (
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={15}
                totalItemsCount={this.props.count}
                pageRangeDisplayed={7}
                onChange={this.handlePageChangeOpenOrders}
              />
               )} */}
            </ErrorBoundary>
          )}
        </div>

        {this.props.activeTab === '2' && (
          <ErrorBoundary>
            <div className="col-lg-9 container-one pl-2 pr-1 mt-1">
              <TradeHistory />
              {this.props.count > limit && (
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={limit}
                  totalItemsCount={this.props.count}
                  pageRangeDisplayed={7}
                  onChange={this.handlePageChange}
                />
              )}
            </div>
          </ErrorBoundary>
        )}
        {this.props.activeTab === '2' && (
          <TradeAnalysis style={{ height: this.state.sidebar_main_height }} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: page => push(page),
      headerActiveMenuUpdate: formData => headerActiveMenuUpdate(formData),
      activeTabUpdate: formData => activeTabUpdate(formData),
      limitUpdate: formData => limitUpdate(formData),
      getFilterdedTradeData: formData => getFilterdedTradeData(formData),
      searchValueUpdate: formData => searchValueUpdate(formData),
      setActivePage: formData => setActivePage(formData),
      selectedMarketUpdate: formData => selectedMarketUpdate(formData),
      setDates: formData => setDates(formData),
      setOperation: formData => setOperation(formData),
      tradeAnalysisAutosuggestCurrencyIdUpdateUpdate: formData =>
        tradeAnalysisAutosuggestCurrencyIdUpdateUpdate(formData),
      selectedCoinUpdate: formData => selectedCoinUpdate(formData)
    },
    dispatch
  );

export default connect(
  state => ({
    isLoggedIn: state.signin.isLoggedIn,
    activeTab: state.openOrders.activeTab,
    count: state.openOrders.count,
    coinId: state.openOrders.coinId,
    marketId: state.openOrders.marketId,
    limit: state.openOrders.limit,
    operation: state.openOrders.operation,
    selectedMarket: state.openOrders.selectedMarket,
    tradeAnalysisAutosuggestCurrencyIdUpdate:
      state.openOrders.tradeAnalysisAutosuggestCurrencyIdUpdate,
    dateFrom: state.openOrders.dateFrom,
    dateTo: state.openOrders.dateTo,
    activePage: state.openOrders.activePage,
    coinSelect: state.openOrders.coinSelect
  }),
  mapDispatchToProps
)(OpenOrders);
