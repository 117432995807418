/**
 * Global API Caller function
 * @function
 * @param {RequestBody} => {formData for The APi}
 */

import Cookies from 'universal-cookie';
import apiUrls from './apiUrls';

const cookies = new Cookies();

export const API_URL =
  process.env.REACT_APP_SIGNIN_API_URL || apiUrls.apiUrlsObj().signInApiUrl;

const APP_ID = 'site_I8xAe15WfY';
const APP_SIGNATURE = 'OlKTU0hEUh8rATbygAT8MZSKNUR90HUVD+f83Zxdogw=';

export default async function apiCaller(
  endpoint,
  method = 'get',
  body,
  keys = false,
  authorization = true,
  isForm = false
) {
  let headers = {
    // Accept: 'application/json, */*'
    'Content-Type': 'application/json'
  };
  if (authorization) {
    headers = {
      ...headers,
      Authorization: `Bearer ${cookies.get('jwt') || window.jwt} ${cookies.get(
        'globalId'
      )}`
      // Authorization: `${cookies.get('jwt') || window.jwt}`
    };
  }
  let options = {
    headers,
    method
  };

  const data = keys
    ? { ...body, app_id: APP_ID, app_signature: APP_SIGNATURE }
    : { ...body };

  if (method !== 'get' && !isForm) {
    options = {
      ...options,
      body: JSON.stringify(data)
    };
  }
  if (isForm) {
    const form = new FormData();
    Object.keys(body).forEach(key => {
      form.append(key, body[key]);
    });
    options = {
      ...options,
      body: form
    };
  }
  try {
    const res = await fetch(`${API_URL}/${endpoint}`, options);
    const response = await res
      .json()
      .then(data => ({ ...data, statusCode: res.status }));
    return response;
  } catch (error) {
    console.log('error - ', error);
    return error;
  }
}
