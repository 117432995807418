const addBodyTag = () => {
  // document.body.classList.add('no-overflow');
  document.body.className = 'no-overflow';
  document.body.id = 'dashboard-main';
};

const removeBodyTag = () => {
  // document.body.classList.remove('no-overflow');
  document.body.className = '';
  document.body.id = '';
};

export default { addBodyTag, removeBodyTag };
