import _ from 'underscore';

const helpers = {
  sort: function(data, type, columnName, sort) {
    let sortData;
    if (type === 'string') {
      sortData = _.sortBy(data, o => o[columnName].toLowerCase());
    } else if (type === 'number') {
      sortData = _.sortBy(data, o => parseFloat(o[columnName]));
    } else if (type === 'date') {
      sortData = _.sortBy(data, o => new Date(o[columnName]).getTime());
    }
    if (sort === 'desc') {
      sortData = sortData.reverse();
    }
    return sortData;
  },
  getCurrentSortColumn: function(sortingCol, columnName) {
    if (sortingCol != columnName) {
      return columnName;
    } else {
      return sortingCol;
    }
  },
  getCurrentSortType: function(sortingCol, columnName, sortType) {
    if (sortingCol != columnName) {
      sortType = 'asc';
    } else {
      if (sortType == '' || sortType == 'desc') {
        sortType = 'asc';
      } else {
        sortType = 'desc';
      }
    }
    return sortType;
  },
  getCurrentSortColumnAndSortType: function(sortingCol, columnName, sortType) {
    if (sortingCol === columnName) {
      if (sortType === 'asc') {
        sortType = 'desc';
      } else {
        sortType = 'asc';
      }
    } else {
      sortingCol = columnName;
      sortType = 'asc';
    }
    return { sortingCol, sortType };
  },
  sortColumn: function(orderList, type, columnName, sortType) {
    let orderListSort = [];
    orderListSort = this.sort(orderList, type, columnName, sortType);
    return orderListSort;
  }
};

export default helpers;
